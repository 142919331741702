import {
  IAppApprover,
  IAppApproverGuid,
} from "../../bulkPayment/bulkPaymentStepper/IAppModels";
import { PayeeInData } from "./PayeeModal";

export interface IAppBasic {
  appName: string;
  appType: "Same Bank" | "Different Bank" | "International" | "Wallet";
}
export interface ICreateAppData {
  appBasic: IAppBasic;
}
export interface IAppAccountType {
  acctype: string;
}
export interface IAppAccNumber {
  accNumber: string;
}
export interface IAppCompanyName {
  companyName: string;
}
export interface IAppFirstName {
  firstname: string;
}
export interface IAppLastName {
  lastname: string;
}
export interface IAppPhone {
  phone: string;
}
export interface IAppCusId {
  cusId: string;
}
export interface IAppNickName {
  nickname: string;
}
export interface IAppCurrency {
  currency: string;
}
export interface IAppPayeeLimit {
  payeelimit: string;
}

//
export interface IAppName {
  name: string;
}
export interface IAppDiffAccountType {
  diffaccType: string;
}
export interface IAppDiffCompanyName {
  diffcompanyName: string;
}
export interface IAppDiffFirstName {
  difffirstname: string;
}
export interface IAppDiffLastName {
  difflastname: string;
}
export interface IAppDiffNickName {
  diffnickname: string;
}
export interface IAppBank {
  bank: string;
}
export interface IAppDiffAccNumber {
  diffAccNumber: string;
}
export interface IAppConfirmAccNumber {
  confirmdiffAccNumber: string;
}
export interface IAppBranchCode {
  branchCode: string;
}
export interface IAppBIC {
  BIC: string;
}
export interface IAppDiffCurrency {
  diffCurrency: string;
}
export interface IAppDiffPayeeLimit {
  diffPayeeLimit: string;
}
//

export interface IAppCountry {
  country: string;
}
export interface IAppIntAccountType {
  intaccType: string;
}
export interface IAppIntCompanyName {
  intcompanyName: string;
}
export interface IAppIntFirstName {
  intfirstname: string;
}
export interface IAppIntLastName {
  intlastname: string;
}
export interface IAppIntDOB {
  intDob: string;
}
export interface IAppIntIDNum {
  intIdNum: string;
}
export interface IAppIntIsdCode {
  intIsdCode: string;
}
export interface IAppIntPhone {
  intPhone: string;
}
export interface IAppIntName {
  intName: string;
}
export interface IAppIntNickName {
  intNickName: string;
}
export interface IAppIntAccNumber {
  intAccNumber: string;
}
export interface IAppIntIdType {
  intIdType: string;
}
export interface IAppIntBIC {
  intBic: string;
}
export interface IAppIntCurrency {
  IntCurrency: string;
}
export interface IAppIntBank {
  intBank: string;
}
export interface IAppIntFundType {
  intfundType: number;
}

export interface IAppIntAddress {
  intAddress: string;
}
export interface IAppIntCity {
  intCity: string;
}
export interface IAppIntState {
  intState: string;
}
export interface IAppIntCountry {
  intCountry: string;
}
export interface IAppIntPayeeLimit {
  intPayeeLimit: string;
}
//

export interface IAppWalletCountry {
  walletCountry: string;
}
export interface IAppWalletPhone {
  walletPhone: string;
}
export interface IAppWalletAccountType {
  walletaccType: string;
}
export interface IAppWalletCompanyName {
  walletcompanyName: string;
}
export interface IAppWalletFirstName {
  walletfirstname: string;
}
export interface IAppWalletLastName {
  walletlastname: string;
}
export interface IAppWalletNickName {
  walletNickName: string;
}
export interface IAppWalletCurrency {
  walletCurrency: string;
}
export interface IAppIntWalletPayeeLimit {
  walletPayeeLimit: string;
}
export interface IAppPin {
  pin: string;
}

export interface ICreateAppData {
  appBasic: IAppBasic;
  accType: IAppAccountType;
  companyName: IAppCompanyName;
  firstname: IAppFirstName;
  lastname: IAppLastName;
  phone: IAppPhone;
  cusId: IAppCusId;
  nickname: IAppNickName;
  currency: IAppCurrency;
  payeelimit: IAppPayeeLimit;
  name: IAppName;
  diffnickname: IAppDiffNickName;
  bank: IAppBank;
  accNumber: IAppAccNumber;
  diffaccNumber: IAppDiffAccNumber;
  confirmAccNumber: IAppConfirmAccNumber;
  branchCode: IAppBranchCode;
  BIC: IAppBIC;
  diffcurrency: IAppDiffCurrency;
  diffAccType: IAppDiffAccountType;
  diffFirstName: IAppDiffFirstName;
  diffLastName: IAppDiffLastName;
  diffCompanyName: IAppDiffCompanyName;
  diffPayeeLimit: IAppDiffPayeeLimit;
  country: IAppCountry;
  intAccType: IAppIntAccountType;
  intFirstName: IAppIntFirstName;
  intLastName: IAppIntLastName;
  intCompanyName: IAppIntCompanyName;
  intDOB: IAppIntDOB;
  intIsdCode: IAppIntIsdCode;
  intPhone: IAppIntPhone;
  intName: IAppIntName;
  intNickName: IAppIntNickName;
  intAccNumber: IAppIntAccNumber;
  intIdNum: IAppIntIDNum;
  intBIC: IAppIntBIC;
  intFundType: IAppIntFundType;
  IntCurrency: IAppIntCurrency;
  intBank: IAppIntBank;
  intAddress: IAppIntAddress;
  intState: IAppIntState;
  intCity: IAppIntCity;
  intCountry: IAppIntCountry;
  intIdType: IAppIntIdType;
  intPayeeLimit: IAppIntPayeeLimit;
  walletCountry: IAppWalletCountry;
  walletPhone: IAppWalletPhone;
  walletAccType: IAppWalletAccountType;
  walletFirstName: IAppWalletFirstName;
  walletLastName: IAppWalletLastName;
  walletCompanyName: IAppWalletCompanyName;
  walletNickName: IAppWalletNickName;
  walletCurrency: IAppWalletCurrency;
  walletPayeeLimit: IAppIntWalletPayeeLimit;
  approverName: IAppApprover;
  approverGuid: IAppApproverGuid;
  pin: IAppPin;
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: { appName: "", appType: "Same Bank" },
  accType: { acctype: "" },
  accNumber: { accNumber: "" },
  companyName: { companyName: "" },
  firstname: { firstname: "" },
  lastname: { lastname: "" },
  phone: { phone: "" },
  cusId: { cusId: "" },
  nickname: { nickname: "" },
  currency: { currency: "" },
  payeelimit: { payeelimit: "" },
  name: { name: "" },
  diffnickname: { diffnickname: "" },
  bank: { bank: "" },
  diffaccNumber: { diffAccNumber: "" },
  confirmAccNumber: { confirmdiffAccNumber: "" },
  branchCode: { branchCode: "" },
  BIC: { BIC: "" },
  diffcurrency: { diffCurrency: "" },
  diffAccType: { diffaccType: "" },
  diffCompanyName: { diffcompanyName: "" },
  diffFirstName: { difffirstname: "" },
  diffLastName: { difflastname: "" },
  diffPayeeLimit: { diffPayeeLimit: "" },
  country: { country: "IN" },
  intIsdCode: { intIsdCode: "" },
  intPhone: { intPhone: "" },
  intName: { intName: "" },
  intAccType: { intaccType: "RETAIL" },
  intCompanyName: { intcompanyName: "" },
  intFirstName: { intfirstname: "" },
  intLastName: { intlastname: "" },
  intDOB: { intDob: "" },
  intIdType: { intIdType: "" },
  intNickName: { intNickName: "" },
  intAccNumber: { intAccNumber: "" },
  intIdNum: { intIdNum: "" },
  intBIC: { intBic: "" },
  IntCurrency: { IntCurrency: "" },
  intFundType: { intfundType: 0 },
  intBank: { intBank: "" },
  intAddress: { intAddress: "" },
  intCity: { intCity: "" },
  intState: { intState: "" },
  intCountry: { intCountry: "" },
  intPayeeLimit: { intPayeeLimit: "" },
  walletCountry: { walletCountry: "" },
  walletPhone: { walletPhone: "" },
  walletAccType: { walletaccType: "" },
  walletCompanyName: { walletcompanyName: "" },
  walletFirstName: { walletfirstname: "" },
  walletLastName: { walletlastname: "" },
  walletNickName: { walletNickName: "" },
  walletCurrency: { walletCurrency: "" },
  walletPayeeLimit: { walletPayeeLimit: "" },
  approverName: { approverName: "" },
  approverGuid: { approverGuid: "" },
  pin: { pin: "" },
};

export type StepProps = {
  data: ICreateAppData;
  phone: string;
  setPhone: (phone: string) => void;
  payeeData: PayeeInData[] | null;
  setPayeeData: (payeeData: PayeeInData[]) => void;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
};
