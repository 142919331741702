import { useState, useEffect, ChangeEvent, useRef } from "react";
import { closeQR, getQRCodePayments } from "./QrApi";
import { ViewQR } from "./QRInterface";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { QRModal } from "./QRModal";
import Icon from "../../../_metronic/assets/Icons/Group.png";
import Swal from "sweetalert2";
import { CreateAppModal } from "./qrStepper/CreateAppModal";
import { formatAmount } from "../../interface/Interface";
import QrImg from "../../../_metronic/assets/Icons/qrImg.png";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";

export interface AccountStmnt {
  txnPGReferenceNumber: string;
  amount: number;
  txnPGStatus: string;
}

export interface statData {
  paylinkIssuedAmount: number;
  paylinkPaidAmount: number;
  qrIssuedAmount: number;
  qrPaidAmount: number;
}

export const ApiData = {
  api: (): void => {},
};

export default function QRDashboard() {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [shouldWrap, setShouldWrap] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [totalQR, setTotalQR] = useState(0);
  const [data, setData] = useState<ViewQR[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [storedEvent, setStoredEvent] = useState<any>(null);
  const confirmModal = useRef<HTMLDivElement | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [statistics, setStatistics] = useState<statData | null>(null);
  const [selectedData, setSelectedData] = useState<ViewQR>({
    qrCodeName: "",
    usage: "",
    fixedAmount: "",
    amount: "",
    qrCodeid: "",
    qrCodeUrl: "",
    status: "",
    currency: "",
    createdDate: "",
    paymentCount: 0,
    totalPaymentAmount: 0,
    qrCodeDescription: "",
    qrPayments: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Filter data based on search query
    const searchData = data?.filter(
      (qr) =>
        qr.amount?.toString().includes(searchQuery.toLowerCase()) ||
        qr.currency?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        qr.qrCodeName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Filter search data further based on selected tab
    if (selectedTab === "All") {
      setFilteredTransactions(searchData);
    } else {
      const filteredData = searchData?.filter((transaction) =>
        selectedTab === "Active"
          ? transaction.status === "active"
          : transaction.status === "closed"
      );
      setFilteredTransactions(filteredData);
    }
  }, [data, searchQuery, selectedTab]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (confirmModal.current) {
      const modal = new Modal(confirmModal.current);
      confirmModal.current.addEventListener(
        "hidden.bs.modal",
        handleCloseModal
      );
      return () => {
        confirmModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleConfirmationModal = (event: React.MouseEvent<HTMLElement>) => {
    // setStoredEvent(event);

    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      html: `Are you sure you want to disable the QR code?</br> <b>Note:</b> Once disabled cannot be enabled again`,
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        handleCloseQR(event);
      }
    });
    // const modalElement = document.getElementById("confirm_modal");
    // const modal = new Modal(modalElement as Element);
    // modal.show();
  };

  useEffect(() => {
    setTotalQR(activeData?.length);
  }, [data]);

  const handleCloseModal = () => {
    const modalElement = confirmModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const getQrCodes = async () => {
    try {
      const url = `${API_URL}/payment/viewQRCodes`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setData(data?.message?.qrCodeModels);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    ApiData.api = getQrCodes;
    getQrCodes();
  }, []);

  const handleRequestClick = async (event: React.MouseEvent<HTMLElement>) => {
    const modalElement = document.getElementById("qr_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    const target = event.target as HTMLElement;
    const rowElement = target.closest("tr");
    if (rowElement) {
      const rowIndex = rowElement.getAttribute("data-index");
      if (rowIndex !== null) {
        const selectedItem = currentData[parseInt(rowIndex, 10)];

        const qrData = await getQRCodePayments(
          API_URL,
          token,
          selectedItem.qrCodeid
        );

        setSelectedData(qrData[0]);
      }
    }
  };

  const handleCloseQR = async (event?: React.MouseEvent<HTMLElement>) => {
    const finalEvent = event || storedEvent;
    const target = finalEvent.target as HTMLElement;
    const rowElement = target.closest("tr");
    if (rowElement) {
      const rowIndex = rowElement.getAttribute("data-index");
      if (rowIndex !== null) {
        const selectedItem = currentData[parseInt(rowIndex, 10)];

        // Only proceed if the selected item has the 'single_use' usage.
        if (
          selectedItem?.usage !== "single_use" &&
          selectedItem?.status !== "active"
        ) {
          return; // Exit the function early
        }

        const qrData = await closeQR(API_URL, token, selectedItem.qrCodeid);
        if (qrData?.status?.statusCode === 0) {
          getQrCodes();
        }
      }
    }
  };
  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const activeData = data?.filter((item) => item.status === "active");
  const inactiveData = data?.filter((item) => item.status === "closed");
  const currentActiveData = activeData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const currentInactiveData = inactiveData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      handlePageChange(1);
      setFilteredTransactions(data);
    } else if (tab === "Active") {
      handlePageChange(1);
      const filteredData = data.filter((transaction) =>
        tab === "Active" ? transaction.status === "active" : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Inactive") {
      handlePageChange(1);
      const filteredData = data.filter((transaction) =>
        tab === "Inactive" ? transaction.status === "closed" : ""
      );
      setFilteredTransactions(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const getQRStatistics = async () => {
    try {
      const url = `${API_URL}/payment/getPaymentStatistics`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setStatistics(data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQRStatistics();
  }, []);

  return (
    <>
      <h4 className="mb-5">QR Dashboard</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div
            className={`d-flex justify-content-between gap-8 ${
              window.innerWidth < 13 * 96 ? "flex-wrap" : "flex-nowrap"
            } `}
            style={{
              paddingTop: "30px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div
              className="card shadow-sm d-flex flex-grow-1 p-3 bg-gray-300"
              style={{ maxWidth: "450px", minWidth: "450px" }}
            >
              <div className="card-body p-5 d-flex flex-row justify-content-between">
                <div className="d-flex flex-column gap-5 ">
                  <div>
                    <h4
                      className="p-0 m-0 text-dark mb-5 mt-7"
                      style={{ fontWeight: "bold" }}
                    >
                      Active QRs{" "}
                      <span
                        className="text-dark-200"
                        style={{ fontWeight: "normal" }}
                      ></span>
                    </h4>
                    <h1 className="p-0 m-0 text-dark mb-2">{totalQR}</h1>
                  </div>
                </div>
                <div>
                  <img
                    src={QrImg}
                    alt="Qr Img"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div
              className="card shadow-sm d-flex flex-grow-1 "
              style={{ width: "100%" }}
            >
              <div className="card-body p-5 flex-grow-1 p-0 m-0">
                <div className="d-flex justify-content-between mb-3 flex-grow-1">
                  <h4 className="p-0 m-0">QR Life Time</h4>
                </div>
                <br />
                <div className="  w-100 d-flex flex-row gap-7">
                  <div className=" card shadow-sm bg-info w-100  p-10 ">
                    <div className="d-flex justify-content-start">
                      <h1 className="text-light fs-2x fw-bolder">
                        {" "}
                        {data?.length}
                      </h1>
                      <br />
                      <br />
                    </div>
                    <div className="d-flex justify-content-start">
                      <p className="text-light fs-7 "> Total QR Transactions</p>
                    </div>
                  </div>
                  <div className=" card shadow-sm bg-primary w-100  p-10 ">
                    <div className="d-flex justify-content-start">
                      <h1 className="text-light fs-2x fw-bolder">
                        {statistics?.qrPaidAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        <span className="fs-9">({baseCurrency})</span>
                      </h1>
                      <br />
                      <br />
                    </div>
                    <div className="d-flex justify-content-start">
                      <p className="text-light fs-7"> Total Collections</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="p-5">
            <div className="py-2 card shadow-sm p-5">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                    <li className="nav-item" key={1}>
                      <a
                        className="nav-link text-active-gray-800 active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_1"
                        onClick={() => {
                          handleTabClick("All");
                        }}
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item" key={2}>
                      <a
                        className="nav-link text-active-gray-800"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_2"
                        onClick={() => {
                          handleTabClick("Active");
                        }}
                      >
                        Active
                      </a>
                    </li>
                    <li className="nav-item" key={3}>
                      <a
                        className="nav-link text-active-gray-800 text-nowrap"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_3"
                        onClick={() => {
                          // setTotalQR(inactiveData?.length);
                          handleTabClick("Inactive");
                        }}
                      >
                        Closed
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-1 align-items-center p-3 gap-10 me-5">
                  <form autoComplete="off">
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        type="text"
                        data-kt-docs-table-filter="search"
                        className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                        placeholder="Search QR"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </form>
                  <Authorize hasAnyPermission={["MENU_QR_CODE|ADD"]}>
                    <button
                      className="btn fs-7 p-3"
                      onClick={() => setShowCreateAppModal(true)}
                    >
                      Create QR
                    </button>
                  </Authorize>
                </div>
              </div>

              <div className="tab-content " id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="kt_tab_pane_1"
                  role="tabpanel"
                >
                  <>
                    <div className="card p-2">
                      <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">QR Name</th>
                            {/* <th className="min-w-100px p-5">Payment</th> */}
                            <th className="min-w-100px p-5 text-nowrap">
                              Amount
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentData && currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                              <tr
                                className="cursor-pointer"
                                key={index}
                                data-index={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform =
                                  //   "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                                onClick={handleRequestClick}
                              >
                                <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                                  {item?.qrCodeName}
                                </td>

                                {/* <td className="p-5 text-gray-600">
                              {item?.usage === "multiple_use"
                                ? "Multiple Payments"
                                : "Single Payment"}
                            </td> */}
                                <td className="p-5 text-primary">
                                  {item?.currency} {formatAmount(item?.amount)}
                                </td>
                                <td className="p-5 ">
                                  {item?.status === "active" ? (
                                    <p className="text-success"> Active</p>
                                  ) : (
                                    <p className="text-danger"> Closed</p>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_2"
                  role="tabpanel"
                >
                  <>
                    <div className="card p-2">
                      <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">QR Name</th>
                            {/* <th className="min-w-100px p-5">Payment</th> */}
                            <th className="min-w-100px p-5 text-nowrap">
                              Amount
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentData && currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                              <tr
                                key={index}
                                data-index={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform =
                                  //   "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                                onClick={handleRequestClick}
                                style={{ cursor: "pointer" }}
                              >
                                <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                                  {item?.qrCodeName}
                                </td>

                                {/* <td className="p-5 text-gray-600">
                              {item?.usage === "multiple_use"
                                ? "Multiple Payments"
                                : "Single Payment"}
                            </td> */}
                                <td className="p-5 text-primary">
                                  {item?.currency} {formatAmount(item?.amount)}
                                </td>
                                <td className="p-5">
                                  {item?.status === "active" ? (
                                    <p className="text-success"> Active</p>
                                  ) : (
                                    <p className="text-danger"> Closed</p>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_3"
                  role="tabpanel"
                >
                  <>
                    <div className="card p-2">
                      <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">QR Name</th>
                            {/* <th className="min-w-100px p-5">Payment</th> */}
                            <th className="min-w-100px p-5 text-nowrap">
                              Amount
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentData && currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                              <tr
                                key={index}
                                data-index={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform =
                                  //   "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                                onClick={handleRequestClick}
                                style={{ cursor: "pointer" }}
                              >
                                <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                                  {item?.qrCodeName}
                                </td>

                                {/* <td className="p-5 text-gray-600">
                              {item?.usage === "multiple_use"
                                ? "Multiple Payments"
                                : "Single Payment"}
                            </td> */}
                                <td className="p-5 text-primary">
                                  {item?.currency} {formatAmount(item?.amount)}
                                </td>
                                <td className="p-5 ">
                                  {item?.status === "active" ? (
                                    <p className="text-success"> Active</p>
                                  ) : (
                                    <p className="text-danger"> Closed</p>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="p-5">
                <ul className="pagination">
                  <li
                    className={`page-item double-arrow ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={0}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                    >
                      <i className="previous"></i>
                      <i className="previous"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item previous ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={1}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="previous"></i>
                    </a>
                  </li>
                  {renderPageNumbers()}
                  <li
                    className={`page-item next ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={2}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="next"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item double-arrow ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={3}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() =>
                        handlePageChange(
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                        )
                      }
                    >
                      <i className="next"></i>
                      <i className="next"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="p-5 d-flex align-items-center">
                <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                  Total: {filteredTransactions?.length}&nbsp;&nbsp;
                </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QRModal data={selectedData} getQrCodes={getQrCodes} />
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
    </>
  );
}
