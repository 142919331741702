export function AccountDeactivation() {
    return (
        <>

            <div className="card-body py-15 py-lg-20 text-center text-nowrap">
                {/* <!--begin::Logo--> */}
                <h1 className="fw-bold fs-2q text-gray-800 mb-7">Your account is deactivated</h1>
                {/* <!--end::Logo--> */}
                {/* <!--begin::Message--> */}
                <div className="fw-semibold fs-4 text-muted mb-15">
                    Plan your blog post by choosing a topic creating
                    <br />an outline and checking facts.
                </div>
                {/* <!--end::Message--> */}
                {/* <!--begin::Action--> */}
                <div className="text-center">
                    <a href="../../demo1/dist/index.html" className="btn btn-lg btn fw-bold">Go to Home Page</a>
                </div>
                {/* <!--end::Action--> */}
                
            </div>
            <div className="mb-10">
								{/* <img src="assets/media/auth/ok.png" className="mw-100 mh-300px theme-light-show" alt="" /> */}
								<img src="https://img.freepik.com/premium-vector/no-data-concept-illustration_86047-486.jpg?w=1380" className="mw-100 mh-300px" alt="" />
							</div>


        </>
    )
}