import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  findBusinessCategory,
  getUserDetails,
  updateDetails,
  updateUser,
} from "./Api";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  useFormik,
} from "formik";
import * as Yup from "yup";
import {
  BusinessFormValues,
  CategoryModal,
  Message,
  Payload,
} from "./Interface";
import dayjs from "dayjs";
import { KTIcon, KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";
import { Receipt, Session } from "../TeamBanking/ViewUser";
import { Document, User } from "../TeamBanking/TeamListing";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ApiCalling = {
  api: (): void => {},
};

const EditProfile = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [img, setImg] = useState<string | undefined>("");
  const [doc, setDoc] = useState<string | undefined>("");
  const [userDetails, setUserDetails] = useState<Message>();
  const [idNumber, setIdNumber] = useState("");

  const [isIDTypeEditing, setIsTypeIdEditing] = useState(false);
  const [isIDEditing, setIsIdEditing] = useState(false);
  const [isIDExpExpiring, setIsIDExpExpiring] = useState(false);
  const [isIDDocEditing, setIsIDDocEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const validationSchema = Yup.object({
    idType: Yup.string()
      .required("ID Proof Type is required")
      .test(
        "is-different-idType",
        "ID Proof Type must be different",
        function (value) {
          const { reapplyParams } = userDetails || {};
          if (reapplyParams?.idType && Object.keys(reapplyParams).length > 0) {
            // Ensure the return type is boolean
            return value !== userDetails?.idType;
          }
          return true; // Allow bypass if not reapply
        }
      ),

    idNumber: Yup.string()
      .required("ID Number is required")
      .test(
        "is-different-idNumber",
        "ID Number must be different",
        function (value) {
          const { reapplyParams } = userDetails || {};
          if (
            reapplyParams?.idNumber &&
            Object.keys(reapplyParams).length > 0
          ) {
            return value !== userDetails?.idNumber;
          }
          return true; // Bypass if not reapply
        }
      ),

    expiryDate: Yup.date()
      .required("Expiry Date is required")
      .typeError("Invalid date format")
      .test(
        "is-different-expiryDate",
        "Expiry Date must be different",
        function (value) {
          const { reapplyParams } = userDetails || {};
          if (
            reapplyParams?.idExpiryDate &&
            Object.keys(reapplyParams).length > 0
          ) {
            return (
              value &&
              dayjs(value).format("YYYY-MM-DD") !==
                dayjs(userDetails?.idExpiryDate).format("YYYY-MM-DD")
            );
          }
          return true; // Bypass if not reapply
        }
      ),

    document: Yup.mixed()
      // .required("Document must be provided")
      .test(
        "is-different-document",
        "Document must be different",
        function (value) {
          const { reapplyParams } = userDetails || {};
          if (
            reapplyParams?.idProofDocument &&
            Object.keys(reapplyParams).length > 0
          ) {
            return (
              value instanceof File &&
              value?.name !== userDetails?.idProofDocument?.documentName
            );
          }
          return true; // Bypass if not reapply
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      idType: userDetails?.idType || "",
      idNumber: userDetails?.idNumber || "",
      expiryDate: userDetails?.idExpiryDate || "",
      document: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      setLoading(true);
      setSubmitting(true);
      try {
        const formData = new FormData();

        if (values.idType !== userDetails?.idType) {
          formData.append("idType", values.idType);
          formData.append("reapplyParams[idType]", values.idType);
        }

        if (values.idNumber !== userDetails?.idNumber) {
          formData.append("idNumber", values.idNumber);
          formData.append("reapplyParams[idNumber]", values.idNumber);
        }

        if (
          values.expiryDate &&
          values.expiryDate !== userDetails?.idExpiryDate
        ) {
          formData.append("idExpiryDate", values.expiryDate);
          formData.append("reapplyParams[idExpiryDate]", values.expiryDate);
        }

        if (values.document) {
          formData.append("file", values.document);
          formData.append("reapplyParams[idProofDocument]", "document");
        }

        formData.append("kycId", userDetails?.kycId || "");

        await updateDetails(
          formData,
          token,
          `${API_URL}`,
          "kycUpdateUserData"
        ).then(async (res) => {
          console.log(res);

          if (res?.status?.statusCode === 0) {
            Swal.fire({
              icon: "success",
              title: "Confirmation",
              text: "Your profile will be updated after the review. We typically take between 24 to 72 hours to provide an update via SMS and email.",
            });
          }
          const data = await getUserDetails(API_URL, token);
          setUserDetails(data);
        });
      } catch (error) {
        toast("Error updating details");
      } finally {
        setIsTypeIdEditing(false);
        setIsIdEditing(false);
        setIsIDExpExpiring(false);
        setIsIDDocEditing(false);
        setLoading(false);
        setSubmitting(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        formik.resetForm();
      }
    },
  });

  console.log(formik.values);

  const handleIdTypeEditClick = () => {
    setIsTypeIdEditing((prev) => !prev);
  };

  const handleIdEditClick = () => {
    setIsIdEditing((prev) => !prev);
  };
  const handleIdExpEditClick = () => {
    setIsIDExpExpiring((prev) => !prev);
  };
  const handleIdDocEditClick = () => {
    setIsIDDocEditing((prev) => !prev);
  };

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        const startIndex = base64String.indexOf(",") + 1; // Find the index of the comma and move one position forward
        resolve(base64String.substring(startIndex));
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [selectedTab, setSelectedTab] = useState<string>("User");
  const [sessions, setSessions] = useState<Session[]>([]);
  const [count, setCount] = useState(0);

  const fetchUserSessions = async (phone: string) => {
    try {
      const url = `${API_URL}/getSessionLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        searchCriteriaList: [
          {
            propertyName: "appType",
            propertyValue: "SME",
          },
          {
            propertyName: "userName",
            propertyValue: phone,
          },
        ],
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        skipPagination: false,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) {
        setSessions(data.results);
        setCount(data.count as number);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedTab === "Session")
      fetchUserSessions(userDetails?.isdCode + "-" + userDetails?.mobile);
    // if (selectedTab === "Activity") fetchUserActivity(phone);
  }, [currentPage, itemsPerPage]);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);
  };

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(count / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      {" "}
      <h4 className="mb-5">Profile Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div className="p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className="nav-link text-active-gray-800 active"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      onClick={() => {
                        handleTabClick("User");
                      }}
                    >
                      Profile
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      onClick={() => {
                        fetchUserSessions(
                          userDetails?.isdCode + "-" + userDetails?.mobile
                        );
                        handleTabClick("Session");
                      }}
                    >
                      Sessions
                    </a>
                  </li>
                  {/* <li className="nav-item" key={3}>
 <a
 className="nav-link text-active-gray-800 text-nowrap"
 data-bs-toggle="tab"
 href="#kt_tab_pane_3"
 onClick={() => {
 fetchUserActivity(phone);
 handleTabClick("Activity");
 }}
 >
 Logs
 </a>
 </li> */}
                </ul>
              </div>
            </div>

            <div className="tab-content " id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <form onSubmit={formik.handleSubmit} className="fs-8">
                  <div className="py-5" style={{ overflowX: "scroll" }}>
                    <div className="card p-5 mb-5 d-flex flex-column">
                      <div className="d-flex flex-row gap-20 align-items-center mb-5">
                        <div className="d-flex flex-column p-3 gap-2 ">
                          <div
                            className="card shadow-sm"
                            style={{ maxHeight: "150px", maxWidth: "150px" }}
                          >
                            <div
                              className="image-input image-input-outline overlay overflow-hidden shadow"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="overlay-wrapper">
                                <img
                                  src={userDetails?.selfiDocument?.documentPath}
                                  style={{
                                    borderRadius: "10px",
                                    objectFit: "fill",
                                    height: "150px",
                                    width: "150px",
                                  }}
                                />
                              </div>
                              <div className="overlay-layer bg-dark bg-opacity-50 align-items-center">
                                <div
                                  className="btn btn-icon bg-light border border-dark"
                                  style={{ height: "30px", width: "30px" }}
                                  onClick={() => {
                                    setImg(
                                      userDetails?.selfiDocument?.documentPath
                                    );
                                    handleViewClick();
                                  }}
                                >
                                  <i className="bi bi-eye fs-5 text-dark"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card rounded p-3">
                            <div className="fw-bold fs-7">
                              DOB : {userDetails?.dob?.split("T")[0]}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row ms-5 mt-5">
                          <div style={{ width: "400px" }}>
                            <div className="fw-bold fs-7">First Name</div>
                            <div className="fs-8">{userDetails?.firstName}</div>
                          </div>
                          <div style={{ width: "400px" }}>
                            <div className="fw-bold fs-7">Last Name</div>
                            <div className="fs-8">{userDetails?.lastName}</div>
                          </div>
                          <div style={{ width: "355px" }}>
                            <div className="fw-bold fs-7">Nationality</div>
                            <div className="fs-8">{userDetails?.country}</div>
                          </div>
                        </div>
                        <br />
                        <div className="d-flex flex-row ms-5 mt-5">
                          <div style={{ width: "400px" }}>
                            <div className="d-flex flex-row gap-1">
                              <p className="fw-bold fs-7 m-0">Mobile Number</p>
                              <p
                                className="fs-8 m-0"
                                style={{
                                  fontStyle: "italic",
                                }}
                              >
                                (Registered number)
                              </p>
                            </div>
                            <div className="fs-8">
                              {userDetails?.isdCode}-{userDetails?.mobile}
                            </div>
                          </div>
                          <div style={{ width: "400px" }}>
                            <div className="d-flex flex-row gap-1">
                              <p className="fw-bold fs-7 m-0">Email ID</p>
                              <p
                                className="fs-8 m-0"
                                style={{
                                  fontStyle: "italic",
                                }}
                              >
                                (Registered ID)
                              </p>
                            </div>
                            <div className="fs-8">{userDetails?.email}</div>
                          </div>
                          <div style={{ width: "355px" }}>
                            <div className="fw-bold fs-7">
                              ID Proof Type{" "}
                              {typeof userDetails?.reapplyParams?.idType ===
                              "string" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-add-money">
                                      {userDetails?.reapplyParams?.idType}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                            </div>

                            {isIDTypeEditing ? (
                              <div className="input-group">
                                <select
                                  name="idType"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.idType}
                                  className={`form-control ${
                                    formik.errors.idType &&
                                    formik.touched.idType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  style={{ maxWidth: "200px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="NATIONAL_ID">NID</option>
                                  <option value="NATIONAL_ID_NO_PHOTO">
                                    Passport
                                  </option>
                                  {/* Add more options as necessary */}
                                </select>
                              </div>
                            ) : (
                              <>
                                {userDetails?.idType}{" "}
                                <i
                                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark ms-2"
                                  onClick={handleIdTypeEditClick}
                                ></i>
                              </>
                            )}
                            {formik.errors.idType && formik.touched.idType && (
                              <div className="text-danger">
                                {formik.errors.idType}
                              </div>
                            )}
                          </div>
                        </div>
                        <br />
                        <div className="d-flex flex-row ms-5 mt-5 mb-5">
                          <div style={{ width: "400px" }}>
                            <div className="fw-bold fs-7">
                              ID Number{" "}
                              {typeof userDetails?.reapplyParams?.idNumber ===
                              "string" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-add-money">
                                      {userDetails?.reapplyParams?.idNumber}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                            </div>

                            {isIDEditing ? (
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="idNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.idNumber}
                                  className={`form-control ${
                                    formik.errors.idNumber &&
                                    formik.touched.idNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  style={{ maxWidth: "200px" }}
                                  max={15}
                                />
                              </div>
                            ) : (
                              <>
                                {userDetails?.idNumber}{" "}
                                <i
                                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark ms-2"
                                  onClick={handleIdEditClick}
                                ></i>
                              </>
                            )}
                            {formik.errors.idNumber &&
                              formik.touched.idNumber && (
                                <div className="text-danger">
                                  {formik.errors.idNumber}
                                </div>
                              )}
                          </div>
                          <div style={{ width: "400px" }}>
                            <div className="fw-bold fs-7">
                              Expiry Date{" "}
                              {typeof userDetails?.reapplyParams
                                ?.idExpiryDate === "string" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-add-money">
                                      {userDetails?.reapplyParams?.idExpiryDate}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                            </div>

                            {isIDExpExpiring ? (
                              <div className="input-group">
                                <input
                                  type="date"
                                  name="expiryDate"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.expiryDate}
                                  className={`form-control ${
                                    formik.errors.expiryDate &&
                                    formik.touched.expiryDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  style={{ maxWidth: "200px" }}
                                  min={new Date().toISOString().split("T")[0]}
                                />
                              </div>
                            ) : (
                              <>
                                {userDetails?.idExpiryDate}{" "}
                                <i
                                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark"
                                  onClick={handleIdExpEditClick}
                                ></i>
                              </>
                            )}
                            {formik.errors.expiryDate &&
                              formik.touched.expiryDate && (
                                <div className="text-danger">
                                  {formik.errors.expiryDate}
                                </div>
                              )}
                          </div>

                          <div style={{ width: "355px" }}>
                            <div className="fw-bold fs-7">
                              ID Document{" "}
                              {typeof userDetails?.reapplyParams
                                ?.idProofDocument === "string" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-add-money">
                                      {
                                        userDetails?.reapplyParams
                                          ?.idProofDocument
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                            </div>

                            {isIDDocEditing ? (
                              <div className="input-group">
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  name="document"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files?.[0];
                                    formik?.setFieldValue("document", file);
                                  }}
                                  onBlur={formik.handleBlur}
                                  // value={formik.values.document}
                                  className={`form-control ${
                                    formik.errors.document &&
                                    formik.touched.document
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  style={{ maxWidth: "200px" }}
                                />
                              </div>
                            ) : (
                              <div className="fs-8 text-primary fw-bold d-flex align-items-center cursor-pointer">
                                <span
                                  onClick={() => {
                                    if (
                                      userDetails?.idProofDocument
                                        .documentExtention === "pdf"
                                    ) {
                                      setDoc(
                                        userDetails?.idProofDocument
                                          .documentPath
                                      );
                                      handleDocViewClick();
                                    } else {
                                      setImg(
                                        userDetails?.idProofDocument
                                          .documentPath
                                      );
                                      handleViewClick();
                                    }
                                  }}
                                >
                                  {" "}
                                  {
                                    userDetails?.idProofDocument.documentName
                                  }{" "}
                                </span>

                                <i
                                  className="bi bi-pencil-square fs-8 cursor-pointer mt-1 text-dark ms-2"
                                  onClick={handleIdDocEditClick}
                                ></i>
                              </div>
                            )}
                            {formik.errors.document &&
                              formik.touched.document && (
                                <div className="text-danger">
                                  {formik.errors.document}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn ms-10 mb-5"
                      disabled={
                        formik.isSubmitting ||
                        loading ||
                        (formik.values.idType === userDetails?.idType &&
                          formik.values.idNumber === userDetails?.idNumber &&
                          formik.values.expiryDate ===
                            userDetails?.idExpiryDate &&
                          formik.values.document === undefined)
                      }
                    >
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <div>Update</div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="card pt-4 mb-6 mb-xl-9">
                  {/* <div className="card-header border-0">
 <div className="card-title">
 <h2>Login Sessions</h2>
 </div>

 <div className="card-toolbar">
 <button
 type="button"
 className="btn btn-sm btn-flex btn-light-primary"
 id="kt_modal_sign_out_sesions"
 >
 <i className="ki-duotone ki-entrance-right fs-3">
 <span className="path1"></span>
 <span className="path2"></span>
 </i>
 Sign out all sessions
 </button>
 </div>
 </div> */}

                  <div className="card-body pt-0 pb-5">
                    <div style={{ overflowX: "scroll" }}>
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-150px">Username</th>
                            <th className="min-w-125px">Date</th>
                            <th className="min-w-125px">Time</th>
                            <th className="min-w-125px">Channel</th>
                            {/* <th className="min-w-125px">Session ID</th> */}
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {sessions?.length === 0 ? (
                            <tr>
                              <td colSpan={7} className="text-center">
                                No data found.
                              </td>
                            </tr>
                          ) : (
                            sessions?.map((session, index) => (
                              <tr>
                                <td>{session?.userName}</td>
                                <td>
                                  {session?.createdDate.substring(
                                    0,
                                    session?.createdDate.lastIndexOf(" ")
                                  )}
                                </td>
                                <td>
                                  {session?.createdDate.substring(
                                    session?.createdDate.lastIndexOf(" ") + 1
                                  )}
                                </td>
                                <td>{session?.channel}</td>

                                {/* <td className="text-start">
                                  <p
                                    className={`mb-1 cursor-pointer text-primary`}
                                  >
                                    {session?.sessionId}
                                  </p>
                                </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedTab !== "User" && (
              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage === Math.ceil(count / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow ${
                        currentPage === Math.ceil(count / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(Math.ceil(count / itemsPerPage))
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {count}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="modal" tabIndex={-1} id="image_modal">
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="modal-body justify-content-center d-flex">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <img
                    className="image"
                    src={img}
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pdf_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
            <div
              className="modal-content"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <div className="modal-header" id="kt_modal_add_user_header">
                <h2 className="fw-bold">PDF Viewer</h2>

                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>

              <div className="modal-body px-15">
                <embed
                  src={`${doc}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
