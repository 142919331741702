import React, {useState} from 'react'
import styled from 'styled-components'

const Content = styled.div`
  padding: 1em;
`

const Summary = styled.summary`
  ::marker {
    display: none;
  }
`
type OptionProps = {
  children: string
  label: string
}

export function Option({label, children}: OptionProps) {
  const [isOpen, setOpen] = useState(false)
  return (
    <React.Fragment>
      <Summary onClick={() => setOpen(!isOpen)}>
        <div className={`d-flex p-3 ${isOpen ? 'bg-secondary' : ''}`}>
          <div className='d-flex m-2 align-items-center'>
            <i className={`bi bi-${isOpen ? 'dash-square' : 'plus-square'} fs-4 bg-secondary`}></i>
          </div>
          <div className={`m-2 fs-5 ${isOpen ? 'text-primary' : ''}`}> {label} </div>
        </div>
      </Summary>
      {isOpen && <Content className='fs-5 text-gray-600'>{children}</Content>}
    </React.Fragment>
  )
}

const AccordionWrapper = styled.div`
  summary::moz-details-marker {
    display: none;
  }
  summary::webkit-details-marker {
    display: none;
  }
`

export const AccordionItem = styled.details.attrs((props) => ({
  open: props.open || false,
}))`
  background-color: white;
  font-style: inherit;

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
    content: '';
  }
`

type AccordionProps = {
  children: React.ReactNode
}
export function Accordion({children}: AccordionProps) {
  return <AccordionWrapper>{children}</AccordionWrapper>
}
