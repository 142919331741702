export interface IAppBasic {
  appName: string;
  appType: "OPEN" | "SET_LIMIT" | "ON_CREDIT" | "SCHEDULED" | "LATER";
}
export interface IAppLimit {
  limit: string;
}
export interface IAppIcon {
  icon: string;
}
export interface IAppPercentage {
  percentage: string;
}
export interface IAppFrequency {
  frequency: string;
}

export interface IAppAmount {
  amount: string;
}

export interface ICreateAppData {
  appBasic: IAppBasic;
  appBasi: IAppBasic;
  appLimit: IAppLimit;
  appIcon: IAppIcon;
  appPercentage: IAppPercentage;
  appFrequency: IAppFrequency;
  appAmount: IAppAmount;
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: { appName: "", appType: "OPEN" },
  appBasi: { appName: "", appType: "SCHEDULED" },
  appLimit: { limit: "" },
  appIcon: { icon: "" },
  appPercentage: { percentage: "" },
  appFrequency: { frequency: "" },
  appAmount: { amount: "" },
};

export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
  setHasError:any;
  
};
