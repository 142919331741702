import React, { FC, useEffect, useState } from "react";
import { getAccounts } from "../../paybill/PayBillApi";
import { Accounts } from "../../../interface/Interface";
import { StepProps } from "../IAppModels";

const Step2 = ({ data, updateData, hasError, reset }: StepProps) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [selectedRadio, setSelectedRadio] = useState(-1);

  async function fetchAccounts() {
    try {
      const account: Accounts[] = await getAccounts(API_URL, token);
      setAccounts(account);
    } catch (error) {
      console.log("Error fetching account data:", error);
    }
  }

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    setSelectedRadio(-1);
  }, [reset]);

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark mb-10">Account Info</h2>

        <div
          className="mb-0 fv-row px-5"
          style={{ height: "330px", overflowY: "auto" }}
        >
          <div className="mb-0">
            {accounts.map((acc, index) => (
              <label
                className="d-flex flex-stack mb-5 cursor-pointer"
                key={index}
              >
                <span className="d-flex align-items-center me-2">
                  <span className="d-flex flex-column">
                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">
                      {acc.accountNumber} ({acc.currency})
                    </span>
                    <span className="fs-6 fw-bold text-gray-400">
                      {acc.accountType}
                    </span>
                  </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="accountPlan"
                    value={acc.accountNumber}
                    onChange={() => {
                      setSelectedRadio(index);
                      updateData({
                        account: {
                          account: acc.accountNumber,
                        },
                        currency: {
                          currency: acc.currency,
                        },
                      });
                    }}
                    checked={selectedRadio === index}
                  />
                </span>
              </label>
            ))}
          </div>
          {hasError && !data.account.account && (
            <p className="text-danger">Select one account</p>
          )}
        </div>
      </div>
    </div>
  );
};

export { Step2 };
