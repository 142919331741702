export interface IAppBasic {
  category: string;
}
export interface IAppName {
  name: string;
}

export interface IAppFileData {
  fileData: string;
}

export interface IAppDescription {
  description: string;
}

export interface IAppSubmit {
  submit: () => void;
}

export interface ICreateAppData {
  category: IAppBasic;
  name: IAppName;
  fileData: IAppFileData;
  description: IAppDescription;
  submit: IAppSubmit;
}

export const defaultCreateAppData: ICreateAppData = {
  category: { category: "Manual" },
  name: { name: "" },
  description: { description: "" },
  fileData: { fileData: "" },
  submit: { submit: () => {} },
};

export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
};
