import React, { ChangeEvent, useRef } from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Formik, FormikProps, FormikValues } from "formik";
import { Carousel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import OTPInput from "react-otp-input";
import PinInput from "react-pin-input";
import Swal from "sweetalert2";
import { getAccounts } from "../paybill/PayBillApi";
import { Accounts } from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Xfin from "../../../_metronic/assets/Icons/xfin.png";
import jsPDF from "jspdf";
import { VerifyData } from "../PayeeManagement/PayeeManagement";
import withReactContent from "sweetalert2-react-content";

type ApproveTxnsResponse = {
  status: {
    statusCode: number;
  };
  message: {
    receipts: boolean | Receipts;
  };
};

type ReceiptData = {
  [key: string]: string;
};

type Receipts = {
  [key: string]: ReceiptData | Receipts;
};

type Response = {
  receipts: Receipts;
  scheduleTxn: boolean;
};

type NormalizedReceipts = {
  [key: string]: ReceiptData;
};

interface FileData {
  name: string;
  accountNumber: string;
  amount: string;
}

interface FileUploadResponse {
  sum: number;
}

interface BulkData {
  name: string;
  description: string;
  fileUploadId: number;
  fileUploadGuid: string;
  fileName: string;
  uploadedDate: string;
  successCount: number;
  failCount: number;
  totalCount: number;
  fileUploadStatus: number;
  fileDataList: FileData[];
  amount: number;
  fileUploadResponse: FileUploadResponse;
  initiatedTxn: boolean;
}

export interface ApproverTxn {
  userId: string;
  tenantId: string;
  businessGuid: string;
  txnHolderId: string;
  initiatorGuid: string;
  approverGuid: string;
  txnType: number;
  requestJson: string;
  functionCode: string;
  approverName: string;
  initiatorName: string;
  currency: string;
  beneficiaryName: string;
  amount: number;
  expiryDate: string;
  status: number;
  createdDate: string;
  createdDateString: string;
  expiryDateString: string;
  receipts: Record<string, any>;
  scheduleTxn: boolean;
  fileUploadId: number;
}

interface Receipt {
  "Debit Amount": string;
  "Bill Amount": string;
  "Service Charge": string;
  "Biller Name": string;
  Date: string;
  Time: string;
  "Transaction Id": string;
  Type: string;
  Status: string;
}

const Approvals = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const details = sessionStorage.getItem("userDetails");
  const userDetails = details ? JSON.parse(details) : null;

  const role = sessionStorage.getItem("role");
  const roleName = role ? JSON.parse(role) : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const [approvalTxns, setApprovalTxns] = useState<ApproverTxn[]>([]);
  const [bulk, setBulk] = useState<BulkData>({
    name: "",
    description: "",
    fileUploadId: 0,
    fileUploadGuid: "",
    fileName: "",
    uploadedDate: "",
    successCount: 0,
    failCount: 0,
    totalCount: 0,
    fileUploadStatus: 0,
    fileDataList: [],
    amount: 0,
    fileUploadResponse: {
      sum: 0,
    },
    initiatedTxn: false,
  });
  const [filteredTransactions, setFilteredTransactions] =
    useState(approvalTxns);
  const [selectedTab, setSelectedTab] = useState<string>("Approvals");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const OTPRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  let ele = useRef<PinInput | null>(null);
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const [errMsg, setErrMsg] = useState("");
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [req, setReq] = useState("");
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  const [receipt, setReceipt] = useState<Receipts>({});
  const [verifydata, setVerifyData] = useState<VerifyData[]>([]);
  const [filteredVerifydata, setFilteredVerifyData] = useState<VerifyData[]>(
    []
  );
  const [reason, setReason] = useState("");

  const [account, setAccount] = useState<Accounts>();
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [payAmount, setPayAmount] = useState(0);
  const [holderGuid, setHolderGuid] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isScheduleTxn, setIsScheduleTxn] = useState("false");
  const [balanceErr, setBalanceErr] = useState("");
  const [limitErr, setLimitErr] = useState("");

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  useEffect(() => {
    setPayAmount(0);
    setBalanceErr("");
    setLimitErr("");
    setHolderGuid([]);
  }, [account]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  useEffect(() => {
    if (OTPRef.current) {
      const modal = new Modal(OTPRef.current);

      OTPRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
        ele?.current?.focus();
      });

      OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
      return () => {
        OTPRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleOTPCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAccounts(API_URL, token);
      setAccounts(result);
      setAccount(result[0]);
      setSelectedAccount(result[0].accountNumber);
      setSelectedCurrency(result[0].currency);
    };
    verifyPayeeList();
    getApproverTxns();
    fetchData();
  }, []);

  const handleBulkDetails = () => {
    const modalElement = document.getElementById("bulk_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    const account = e.target.value;
    const acc = accounts.find(
      (acc) => acc.accountNumber === account.split(" ")[0]
    );
    setAccount(acc);
    setSelectedAccount(account.split(" ")[0]);
    setSelectedCurrency(account.split(" ")[1]);
  };

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    setReq("");
    formikRef.current?.resetForm();
    ele.current?.clear();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const getBulkTxns = async (fileId: number) => {
    try {
      const url = `${API_URL}/bulk/readFileDataAsList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        fileUploadId: fileId,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setBulk(data?.message);
        handleBulkDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyPayeeList = async () => {
    try {
      const url = `${API_URL}/team/getApproverPayeeTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({ searchCriteriaList: [] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setVerifyData(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getApproverTxns = async () => {
    try {
      const url = `${API_URL}/team/getApproverTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        searchCriteriaList: [
          // {
          //   propertyName: "isScheduleTxn",
          //   propertyValue: isScheduleTxn,
          // },
        ],
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovalTxns(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approveTxns = async () => {
    try {
      const url = `${API_URL}/team/approveTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        holderGuidList: holderGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        getApproverTxns();
        verifyPayeeList();
        setPayAmount(0);
        setHolderGuid([]);
        if (typeof data.message.receipts === "object") {
          setReceipt(data.message.receipts);
        } else {
          // Handle the case where receipts is not an object
          console.error("Receipts is not an object", data.message.receipts);
        }
        handleOTPCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",

          text:
            selectedTab === "Payee"
              ? "Payee Approved"
              : selectedTab === "Scheduled"
              ? "Scheduled transfer has been approved"
              : "Transaction successful",
          showConfirmButton: true,
          confirmButtonColor: "#007bff",
          confirmButtonText:
            selectedTab === "Approvals" ? "View E-Receipt" : "Ok",
          cancelButtonColor: "#9fa6b2",
          cancelButtonText: "Close",
          allowEscapeKey: true,
          allowEnterKey: true, // Text for the cancel button
        }).then((result) => {
          if (result.isConfirmed && selectedTab === "Approvals") {
            handleEReceipt();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectTxns = async () => {
    try {
      const url = `${API_URL}/team/rejectItemsForApproval`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        rejectionMsg: reason,
        holderGuidList: holderGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        getApproverTxns();
        verifyPayeeList();
        setPayAmount(0);
        setHolderGuid([]);
        handleOTPCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text:
            selectedTab === "Payee"
              ? "Payee rejected successfully"
              : "Transaction rejected successfully",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleTabClick = (tab: string) => {
    setHolderGuid([]);
    setPayAmount(0);
    setSelectedTab(tab);
    setCurrentPage(1);

    if (tab === "Approvals") {
      setFilteredTransactions(
        approvalTxns?.filter(
          (app) =>
            (app.status === 1 || app.status === 5) &&
            app.currency === baseCurrency &&
            !app.scheduleTxn
        )
      );
    } else if (tab === "Scheduled") {
      setFilteredTransactions(
        approvalTxns?.filter(
          (app) =>
            (app.status === 1 || app.status === 5) &&
            app.currency === baseCurrency &&
            app.scheduleTxn
        )
      );
    } else if (tab === "payee") {
      setFilteredVerifyData(
        verifydata?.filter((app) => app.status === 1 || app.status === 5)
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const currentVerifyData = verifydata
    ?.filter((data) => data.status === 1)
    ?.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (selectedTab === "Approvals") {
      setFilteredTransactions(
        approvalTxns?.filter(
          (txn) =>
            (txn.status === 1 || txn.status === 5) &&
            txn.currency === selectedCurrency &&
            !txn.scheduleTxn
        )
      );
    }
    if (selectedTab === "Scheduled") {
      setFilteredTransactions(
        approvalTxns?.filter(
          (txn) =>
            (txn.status === 1 || txn.status === 5) &&
            txn.currency === selectedCurrency &&
            txn.scheduleTxn
        )
      );
    }
  }, [approvalTxns, selectedCurrency]);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const OTPModalOpen = () => {
    const modalElement = document.getElementById("OTP_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSelect = (selectedIndex: number, e: any) => {
    // setHide(true);

    // Ensure selectedIndex is within bounds
    if (selectedIndex >= 0) {
      setSelectedIndex(selectedIndex);
      // setSelectedCard(cards[selectedIndex]);
    }
  };

  const handleRadioClick = (id: string, amount: number) => {
    setHolderGuid([id]);
    setPayAmount(amount);
  };

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    holderGuid: string,
    amount: number
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setPayAmount((prev) => prev + amount);
      setHolderGuid((prev) => [...prev, holderGuid]);
    } else {
      setPayAmount((prev) => prev - amount);
      setHolderGuid((prev) => prev?.filter((guid) => guid !== holderGuid));
    }
  };

  const handleSelectAll = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;
    console.log(target.checked);
    setSelectAll(target.checked);

    if (target.checked) {
      if (selectedTab !== "Payee") {
        // Sum all amounts from filteredTransactions
        const totalAmount = currentData.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        // Get all holderGuids from filteredTransactions
        const allHolderGuids = currentData.map(
          (transaction) => transaction.txnHolderId
        );
        setPayAmount(totalAmount);
        setHolderGuid(allHolderGuids);
      } else {
        const allHolderGuids = currentVerifyData.map(
          (transaction) => transaction.txnHolderId
        );
        setHolderGuid(allHolderGuids);
      }
    } else {
      setPayAmount(0);
      setHolderGuid([]);
    }
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const downloadPDF = (receipt: ReceiptData) => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 10;
        const lineHeight = 10;
        const keyWidth = 50;
        doc.text("Receipt", 105, yPosition, { align: "center" });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");
        // Dynamically add data from the billData object
        Object.entries(receipt).forEach(([key, value], index) => {
          const keyText = `${capitalizeFirstLetter(key)}:`;
          const valueText = value.toString(); // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);

        doc.save(`Approval_Receipt.pdf`);
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (selectedTab !== "Payee") {
      if (holderGuid.length === currentData.length && holderGuid.length !== 0) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      if (account) {
        if (payAmount > account.balance)
          setBalanceErr("selected amount is greater than balance");
        else setBalanceErr("");
      }

      if (
        userDetails?.userDetails?.limitType === "2" &&
        selectedCurrency === "ZAR" &&
        payAmount > +userDetails?.userDetails?.zarLimit
      )
        setLimitErr("selected amount is greater than limit");
      else if (
        userDetails?.userDetails?.limitType === "2" &&
        selectedCurrency === "USD" &&
        payAmount > +userDetails?.userDetails?.usdLimit
      )
        setLimitErr("selected amount is greater than limit");
      else setLimitErr("");
    } else {
      if (
        holderGuid.length === currentVerifyData.length &&
        holderGuid.length !== 0
      ) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [holderGuid, currentData.length, currentVerifyData.length]);

  const handleRejectClick = async () => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Enter your reason for rejection",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      preConfirm: (inputValue) => {
        if (!inputValue) {
          Swal.showValidationMessage("You need to write reason!");
        }
        return inputValue;
      },
    });

    if (text) {
      console.log(text);

      setReason(text);
      OTPModalOpen();
    }
  };

  function normalizeReceipts(receipts: Receipts): NormalizedReceipts {
    const normalized: NormalizedReceipts = {};

    Object.entries(receipts).forEach(([outerKey, outerValue]) => {
      if (typeof outerValue === "object" && !Array.isArray(outerValue)) {
        Object.entries(outerValue).forEach(([innerKey, innerValue]) => {
          if (typeof innerValue === "object" && !Array.isArray(innerValue)) {
            normalized[innerKey] = innerValue;
          } else {
            normalized[outerKey] = outerValue as ReceiptData;
          }
        });
      }
    });

    return normalized;
  }

  function extractDate(dateString: string) {
    const parts = dateString.split(",");
    if (parts.length >= 2) {
      return parts[0] + "," + parts[1];
    }
    return dateString; // Return the original string if it doesn't have enough commas
  }

  return (
    <>
      <style>
        {`
        .black-icon {
        color: black;
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
        margin-bottom: 60px; 
        filter: invert(100%);
        background-size: 20px 20px;
        }
      `}
      </style>
      <h4 className="mb-5">Approvals</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          {userDetails?.userDetails?.authority?.includes("1") ||
          userDetails?.userDetails?.authority?.includes("3") ||
          userDetails?.userDetails === undefined ? (
            <div className="p-5">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                    <li className="nav-item" key={1}>
                      <a
                        className="nav-link text-active-gray-800 active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_1"
                        onClick={() => {
                          handleTabClick("Approvals");
                          setIsScheduleTxn("false");
                        }}
                      >
                        Transaction Approval{" "}
                        {approvalTxns?.filter(
                          (app) => app.status === 1 && !app.scheduleTxn
                        ).length > 0 ? (
                          <span className="bullet bullet-dot bg-danger h-6px w-6px mb-3 me-2" />
                        ) : null}
                      </a>
                    </li>
                    <li className="nav-item" key={2}>
                      <a
                        className="nav-link text-active-gray-800"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_2"
                        onClick={() => {
                          handleTabClick("Scheduled");
                          setIsScheduleTxn("true");
                        }}
                      >
                        Scheduled Transaction Approval{" "}
                        {approvalTxns?.filter(
                          (app) => app.status === 1 && app.scheduleTxn
                        ).length > 0 ? (
                          <span className="bullet bullet-dot bg-danger h-6px w-6px mb-3 me-2" />
                        ) : null}
                      </a>
                    </li>
                    <li className="nav-item" key={3}>
                      <a
                        className="nav-link text-active-gray-800"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_3"
                        onClick={() => {
                          handleTabClick("Payee");
                          setIsScheduleTxn("false");
                        }}
                      >
                        Payee Approval{" "}
                        {verifydata?.filter((app) => app.status === 1).length >
                        0 ? (
                          <span className="bullet bullet-dot bg-danger h-6px w-6px mb-3 me-2" />
                        ) : null}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content " id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="kt_tab_pane_1"
                  role="tabpanel"
                >
                  <>
                    <div className="d-flex flex-row justify-content-between p-5">
                      <div
                        className="d-flex flex-column flex-grow-1"
                        style={{ maxWidth: "335px" }}
                      >
                        <select
                          className="form-select form-select-solid"
                          data-control="select2"
                          data-placeholder="Select an option"
                          // value={selectedMonth}
                          onChange={(e) => handleAccountChange(e)}
                        >
                          {accounts?.map((acc, index) => (
                            <option
                              key={index}
                              value={acc.accountNumber + " " + acc.currency}
                            >
                              {`${acc.accountNumber} \u00A0 \u00A0 \u00A0 (${
                                acc.currency
                              } ${acc.balance.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })})`}
                            </option>
                          ))}
                        </select>
                        {balanceErr && (
                          <p className="text-danger">{balanceErr}</p>
                        )}
                      </div>
                      <div>
                        <div className="d-flex flex-row gap-5">
                          <button
                            className="btn btn-sm btn-primary mh-50px"
                            onClick={() => {
                              setReq("Approve");
                              OTPModalOpen();
                            }}
                            disabled={
                              payAmount < 1 ||
                              balanceErr.length > 0 ||
                              limitErr.length > 0
                            }
                          >
                            Approve {payAmount > 0 ? account?.currency : ""}{" "}
                            {"  "}
                            {payAmount > 0 ? payAmount : ""}
                          </button>
                          <button
                            className="btn btn-sm btn-danger mh-50px"
                            onClick={() => {
                              setReq("Reject");
                              handleRejectClick();
                            }}
                            disabled={payAmount < 1 || holderGuid.length !== 1}
                          >
                            Reject
                          </button>
                        </div>
                        {limitErr && <p className="text-danger">{limitErr}</p>}
                      </div>
                    </div>
                    <div
                      className="card p-2"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      <table className="table align-middle border rounded table-row-dashed fs-6 overflow-scroll">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">Date</th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Transaction Type
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Amount
                            </th>
                            <th className="min-w-150px p-5 text-nowrap">
                              Beneficiary
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Initiator
                            </th>
                            {roleName === "ROLE_SME_ADMIN" && (
                              <th className="min-w-100px p-5 text-nowrap">
                                Approver
                              </th>
                            )}

                            <th className="min-w-100px p-5 text-nowrap">
                              <div className="form-check form-check-custom form-check-sm">
                                <input
                                  type="checkbox"
                                  className="form-check-input cursor-pointer border border-dark"
                                  checked={selectAll}
                                  onClick={handleSelectAll}
                                />
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentData && currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                              <tr
                                key={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform = "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                                //   id="kt_receipt_modal_toggle"
                                //   onClick={() => handleDownloadClick(item.txnId)}
                              >
                                <td
                                  className="p-5 text-gray-600 mw-100px"
                                  // style={{ cursor: "pointer" }}
                                >
                                  {extractDate(item.createdDateString)}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item.functionCode}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item?.currency}{" "}
                                  {item.amount.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  className={`p-5 mw-100px text-nowrap ${
                                    item.txnType === 7 ||
                                    item.txnType === 9 ||
                                    item.txnType === 10
                                      ? "cursor-pointer text-primary"
                                      : "text-gray-600"
                                  }`}
                                  onClick={() => {
                                    if (
                                      item.txnType === 7 ||
                                      item.txnType === 9 ||
                                      item.txnType === 10
                                    ) {
                                      getBulkTxns(item.fileUploadId);
                                    }
                                  }}
                                >
                                  {item.txnType === 7 ||
                                  item.txnType === 9 ||
                                  item.txnType === 10
                                    ? "View List"
                                    : item.beneficiaryName}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item.initiatorName}
                                </td>
                                {roleName === "ROLE_SME_ADMIN" && (
                                  <td className="p-5 text-gray-600 mw-100px">
                                    {item.approverName}
                                  </td>
                                )}
                                <td className="d-flex p-5 text-gray-600 mw-150px gap-3 flex-row">
                                  <label className="form-check form-check-custom form-check-sm">
                                    <input
                                      className="form-check-input cursor-pointer border border-dark "
                                      type="checkbox"
                                      checked={holderGuid.includes(
                                        item.txnHolderId
                                      )}
                                      onClick={(e) =>
                                        handleCheckClick(
                                          e,
                                          item.txnHolderId,
                                          item.amount
                                        )
                                      }
                                    />
                                  </label>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_2"
                  role="tabpanel"
                >
                  <>
                    <div className="d-flex flex-row justify-content-between p-5">
                      <div
                        className="d-flex flex-column flex-grow-1"
                        style={{ maxWidth: "335px" }}
                      >
                        <select
                          className="form-select form-select-solid"
                          data-control="select2"
                          data-placeholder="Select an option"
                          // value={selectedMonth}
                          onChange={(e) => handleAccountChange(e)}
                        >
                          {accounts?.map((acc, index) => (
                            <option
                              key={index}
                              value={acc.accountNumber + " " + acc.currency}
                            >
                              {`${acc.accountNumber} \u00A0 \u00A0 \u00A0 (${
                                acc.currency
                              } ${acc.balance.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })})`}
                            </option>
                          ))}
                        </select>
                        {/* {balanceErr && (
                        <p className="text-danger">{balanceErr}</p>
                      )} */}
                      </div>
                      <div>
                        <div className="d-flex flex-row gap-5">
                          <button
                            className="btn btn-sm btn-primary mh-50px"
                            onClick={() => {
                              setReq("Approve");
                              OTPModalOpen();
                            }}
                            disabled={
                              payAmount < 1 ||
                              balanceErr.length > 0 ||
                              limitErr.length > 0
                            }
                          >
                            Approve {payAmount > 0 ? account?.currency : ""}{" "}
                            {"  "}
                            {payAmount > 0 ? payAmount : ""}
                          </button>
                          <button
                            className="btn btn-sm btn-danger mh-50px"
                            onClick={() => {
                              setReq("Reject");
                              handleRejectClick();
                            }}
                            disabled={payAmount < 1 || holderGuid.length !== 1}
                          >
                            Reject
                          </button>
                        </div>
                        {limitErr && <p className="text-danger">{limitErr}</p>}
                      </div>
                    </div>
                    <div
                      className="card p-2"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      <table className="table align-middle border rounded table-row-dashed fs-6 ">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">Scheduled Date</th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Transcation Type
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Amount
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Beneficiary
                            </th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Initiator
                            </th>
                            {roleName === "ROLE_SME_ADMIN" && (
                              <th className="min-w-100px p-5 text-nowrap">
                                Approver
                              </th>
                            )}

                            <th className="min-w-100px p-5 text-nowrap">
                              {/* <div className="form-check form-check-custom form-check-sm">
                                <input
                                  type="checkbox"
                                  className="form-check-input cursor-pointer border border-dark"
                                  checked={selectAll}
                                  onClick={handleSelectAll}
                                />
                              </div> */}
                              Select Transaction
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentData && currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                              <tr
                                key={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform = "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                                //   id="kt_receipt_modal_toggle"
                                //   onClick={() => handleDownloadClick(item.txnId)}
                              >
                                <td
                                  className="p-5 text-gray-600 mw-100px"
                                  // style={{ cursor: "pointer" }}
                                >
                                  {extractDate(item.expiryDateString)}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item.functionCode}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item?.currency}{" "}
                                  {item.amount.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  className={`p-5 mw-100px ${
                                    item.txnType === 7 ||
                                    item.txnType === 9 ||
                                    item.txnType === 10
                                      ? "cursor-pointer text-primary"
                                      : "text-gray-600"
                                  }`}
                                  onClick={() => {
                                    if (
                                      item.txnType === 7 ||
                                      item.txnType === 9 ||
                                      item.txnType === 10
                                    ) {
                                      getBulkTxns(item.fileUploadId);
                                    }
                                  }}
                                >
                                  {item.txnType === 7 ||
                                  item.txnType === 9 ||
                                  item.txnType === 10
                                    ? "View List"
                                    : item.beneficiaryName}
                                </td>
                                <td className="p-5 text-gray-600 mw-100px">
                                  {item.initiatorName}
                                </td>
                                {roleName === "ROLE_SME_ADMIN" && (
                                  <td className="p-5 text-gray-600 mw-100px">
                                    {item.approverName}
                                  </td>
                                )}
                                <td className="d-flex p-5 text-gray-600 mw-150px gap-3 flex-row">
                                  {/* <label className="form-check form-check-custom form-check-sm">
                                    <input
                                      className="form-check-input cursor-pointer border border-dark"
                                      type="checkbox"
                                      checked={holderGuid.includes(
                                        item.txnHolderId
                                      )}
                                      onClick={(e) =>
                                        handleCheckClick(
                                          e,
                                          item.txnHolderId,
                                          item.amount
                                        )
                                      }
                                    />
                                  </label> */}
                                  <input
                                    type="radio"
                                    name="schedule"
                                    className="form-check-input"
                                    checked={holderGuid.includes(
                                      item.txnHolderId
                                    )}
                                    onChange={() => {
                                      handleRadioClick(
                                        item.txnHolderId,
                                        item.amount
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_3"
                  role="tabpanel"
                >
                  <>
                    <div className="d-flex flex-row justify-content-end p-5">
                      <div className="d-flex flex-row gap-5">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setReq("Approve");
                            OTPModalOpen();
                          }}
                          disabled={holderGuid.length === 0}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setReq("Reject");
                            handleRejectClick();
                          }}
                          disabled={holderGuid.length !== 1}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                    <div className="card p-2">
                      <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                        <thead className="bg-gray-200">
                          <tr className="text-start text-dark-600 fw-bold fs-6 ">
                            <th className="min-w-100px p-5">Payee Name</th>
                            <th className="min-w-100px p-5">Payee Alias</th>
                            <th className="min-w-100px p-5">Payee Type</th>
                            <th className="min-w-100px p-5">Account Number</th>
                            <th className="min-w-100px p-5 text-nowrap">
                              Currency
                            </th>
                            {/* <th className="min-w-100px p-5 text-nowrap">
                              Initiator
                            </th> */}
                            {roleName === "ROLE_SME_ADMIN" && (
                              <th className="min-w-100px p-5 text-nowrap">
                                Approver
                              </th>
                            )}
                            <th className="min-w-100px p-5 text-nowrap">
                              <div className="form-check form-check-custom form-check-sm">
                                <input
                                  type="checkbox"
                                  className="form-check-input cursor-pointer border border-dark"
                                  checked={selectAll}
                                  onClick={handleSelectAll}
                                />
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentVerifyData && currentVerifyData.length > 0 ? (
                            currentVerifyData.map((item, index) => (
                              <tr
                                key={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                }}
                              >
                                <td
                                  className="p-5 text-gray-600"
                                  // onClick={() => handleDetailsClick(index)}
                                >
                                  {item.payeeName}
                                </td>
                                <td className="p-5 text-gray-600">
                                  {item?.payeeAlias}
                                </td>
                                <td className="p-5 text-gray-600">
                                  <span
                                    className={`badge ${
                                      item?.payeeCategory === "RETAIL"
                                        ? "badge-light-success"
                                        : "badge-light-info"
                                    }`}
                                  >
                                    {item?.payeeCategory === "RETAIL"
                                      ? "INDIVIDUAL"
                                      : item.payeeCategory}
                                  </span>
                                </td>
                                <td className="p-5 text-gray-600">
                                  {item.accountNumber}
                                </td>
                                <td className="p-5 text-gray-600">
                                  {item.currency === "R"
                                    ? "ZAR"
                                    : item.currency}
                                </td>
                                {/* <td className="p-5 text-gray-600 mw-100px">
                                  {item.initiationCode}
                                </td> */}
                                {roleName === "ROLE_SME_ADMIN" && (
                                  <td className="p-5 text-gray-600 mw-100px">
                                    {item.approverName}
                                  </td>
                                )}
                                <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    <input
                                      type="checkbox"
                                      className="form-check-input cursor-pointer border border-dark"
                                      checked={holderGuid.includes(
                                        item.txnHolderId
                                      )}
                                      onClick={(e) =>
                                        handleCheckClick(e, item.txnHolderId, 0)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage ===
                        Math.ceil(filteredTransactions?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow 
                        ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }
                      `}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                          )
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {filteredTransactions?.length}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-5">
              <p className="text-center">
                You dont have the authority to perform this action. Please
                contact the Admin
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="OTP_modal" ref={OTPRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-end">
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <div className="label fs-3 fw-bold">Enter Your PIN</div>

                <div className="label p-0">
                  <p className="lorem-ipsum-dolor mb-3 text-nowrap fs-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    pin: "",
                  }}
                  validationSchema={Yup.object({
                    pin: Yup.string()
                      .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Invalid characters")
                      .min(4, "Pin must be 4 digits")
                      .required("Pin is required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    if (req === "Approve") approveTxns();
                    if (req === "Reject") rejectTxns();
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <div>
                        <Field name="pin">
                          {({
                            field,
                            meta,
                            form,
                          }: {
                            field: any;
                            meta: any;
                            form: FormikProps<any>;
                          }) => (
                            <div>
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      form.setFieldValue("pin", pin);
                                      setErrMsg("");
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      form.setFieldValue("pin", pin);
                                      setErrMsg("");
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </Field>
                        <p
                          className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                          onClick={() => setShow(!show)}
                        >
                          {show ? "Show PIN" : "Hide PIN"}
                        </p>
                        <ErrorMessage
                          name="pin"
                          component="div"
                          className="text-danger text-center"
                        />
                      </div>

                      <br />
                      {errMsg && (
                        <p className="text-danger text-center">{errMsg}</p>
                      )}

                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn rounded"
                          style={{
                            width: "226px",
                          }}
                        >
                          {req}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="e_receipt_modal"
        // ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered w-400px">
          <div className="modal-content">
            <div className="modal-head d-flex flex-row justify-content-between align-items-center p-4 pb-0 mx-3 mb-3">
              <p className="fs-3 pb-0 mt-4">Approvals</p>

              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div className="modal-body p-0 py-10">
              <Carousel
                onSelect={handleSelect}
                activeIndex={selectedIndex}
                indicators={false}
                style={{ width: "400px", maxWidth: "400px" }}
                interval={null}
                wrap={false}
              >
                {Object.entries(normalizeReceipts(receipt)).length > 0
                  ? Object.entries(normalizeReceipts(receipt)).map(
                      ([txnHolderId, data], index) => (
                        <Carousel.Item key={index} className="">
                          <div className="mx-5">
                            <div className="d-flex flex-column justify-content-center mx-10">
                              <div
                                className="card shadow-sm p-5 w-300px"
                                style={{ maxHeight: "500px", overflow: "auto" }}
                              >
                                {Object.entries(data).map(([key, value]) => (
                                  <div
                                    key={key}
                                    className="d-flex justify-content-between mb-2"
                                  >
                                    <div>
                                      <p>{key}</p>
                                    </div>
                                    <div>
                                      <p
                                        className={`fw-bold badge badge-${
                                          key === "Status" &&
                                          value === "Success"
                                            ? "success"
                                            : key === "Status" &&
                                              value === "Failed"
                                            ? "danger"
                                            : ""
                                        }`}
                                      >
                                        {String(value)}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="d-flex justify-content-center mt-10">
                              <button
                                type="button"
                                className="btn rounded"
                                style={{ width: "300px" }}
                                onClick={() => downloadPDF(data as ReceiptData)}
                              >
                                Download E-Receipt
                              </button>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    )
                  : null}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="bulk_modal">
        <div className="modal-dialog modal-dialog-centered w-400px">
          <div className="modal-content">
            <div className="modal-head d-flex flex-row justify-content-between align-items-center p-4 pb-0 mx-3">
              <p className="fs-3 pb-0 mt-4">Bulk Payments</p>

              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div
              className="modal-body p-5"
              style={{
                minHeight: "300px",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <table className="table align-middle border rounded table-row-dashed fs-6 ">
                {" "}
                <thead className="bg-gray-200">
                  <tr className="text-start text-dark-600 fw-bold fs-6 ">
                    <th className="min-w-100px p-5">Beneficiary Name</th>
                    <th className="min-w-100px p-5 text-nowrap">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {bulk?.fileDataList?.map((data, key) => (
                    <tr>
                      <td className="p-5 text-gray-600 mw-100px">
                        {data.name}
                      </td>
                      <td className="p-5 text-gray-600 mw-100px">
                        {data.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Approvals };
