import React, { useRef, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import deleteIcon from "../../../_metronic/assets/Icons/Group.png";
import { Modal } from "bootstrap"; // Import the Modal module from Bootstrap

export function DeleteConfirmation() {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  return (
    <>
      <div className="modal" tabIndex={-1} id="delete" ref={modalRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="mb-4">
                  <i className="bi bi-check-circle fs-5x text-success"></i>
                </div>
                <div className="label">
                  <div className="modal-title-wrapper">
                    <h1 className="modal-title" style={{ color: "#246bfd" }}>
                      Payee Deleted !!
                    </h1>
                  </div>
                </div>
                <div className="label p-2">
                  <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                    <p
                      className="lorem-ipsum-dolor"
                      style={{
                        fontSize: "15px",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                    >
                      This payee has been deleted
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <button
                    type="button"
                    className="btn  rounded"
                    style={{
                      width: "230px",
                    }}
                    onClick={handleCloseModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
