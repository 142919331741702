import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

interface Document {
  documentExtention: string;
  documentName: string;
  documentTypes: number[];
  documentUploadedByType: number;
  documentStatus: number;
  fileUploadType: number;
  documentData: string; // This should represent the file data as a base64 string or URL
}

interface Setting {
  domesticInvoicePrefix: string;
  domesticInvoiceSequencePattern: string;
  exportedInvoicePrefix: string;
  exportedInvoiceSequencePattern: string;
  termsAndConditions: string;
  dueDatePeriod: string;
  tax: string;
  documents: Document[];
}

interface FormValues {
  prefix: string;
  sequence: string;
  expPrefix: string;
  expSequence: string;
  dueDate: string;
  terms: string;
  imageFile: File | null; // Should be File type
  signatureFile: File | null; // Should be File type
  tax: string;
}

export default function InvoiceSettings() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const signatureRef = useRef<HTMLInputElement | null>(null);

  const [logo, setLogo] = useState("");
  const [signature, setSignature] = useState("");
  const [invSetting, setInvSetting] = useState<Setting | null>(null);

  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setInvSetting(data.message);
      setLogo(data?.message?.documents[0]?.documentPath);
      setSignature(data?.message?.documents[1]?.documentPath);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadSettings();
  }, []);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      prefix: invSetting?.domesticInvoicePrefix || "",
      sequence: invSetting?.domesticInvoiceSequencePattern || "",
      expPrefix: invSetting?.exportedInvoicePrefix || "",
      expSequence: invSetting?.exportedInvoiceSequencePattern || "",
      dueDate: invSetting?.dueDatePeriod || "",
      terms: invSetting?.termsAndConditions || "",
      imageFile: null,
      signatureFile: null,
      tax: invSetting?.tax || "",
    },
    validationSchema: Yup.object({
      prefix: Yup.string().required("Domestic Prefix Required"),
      sequence: Yup.string().required("Domestic Sequence Required"),
      expPrefix: Yup.string().required("Export Prefix Required"),
      expSequence: Yup.string().required("Export Sequence Required"),
      terms: Yup.string().required("Terms and Conditions Required"),
      dueDate: Yup.number().required("Due Date Required"),
      tax: Yup.number().required("Tax Percentage Required"),
    }),
    onSubmit: async (values) => {
      console.log("submitting");

      try {
        const url = `${API_URL}/invSetting/manage`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const formData = new FormData();

        if (values.imageFile) {
          formData.append("customerLogo", values.imageFile);
        }

        if (values.signatureFile) {
          formData.append("signature", values.signatureFile);
        }

        formData.append("domesticInvoicePrefix", values.prefix);
        formData.append("domesticInvoiceSequencePattern", values.sequence);
        formData.append("exportedInvoicePrefix", values.expPrefix);
        formData.append("exportedInvoiceSequencePattern", values.expSequence);
        formData.append("termsAndConditions", values.terms);
        formData.append("dueDatePeriod", values.dueDate);
        formData.append("tax", values.tax);

        const response = await fetch(url, {
          method: "POST",
          headers,
          body: formData,
        });
        const data = await response.json();

        if (data.status.statusCode === 0) {
          LoadSettings();
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Setting Updated!",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="mb-5">
        <h4>Invoice Settings</h4>
      </div>
      <div className="card shadow p-10">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-row gap-10">
            <div className="mb-5">
              <label className="form-label">Logo</label>
              <div
                className="form-control d-flex align-items-center justify-content-center"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  minHeight: "130px",
                  maxHeight: "130px",
                  minWidth: "126px",
                  maxWidth: "126px",
                }}
              >
                {logo !== undefined || null ? (
                  <img
                    src={
                      formik.values.imageFile
                        ? URL.createObjectURL(formik.values.imageFile)
                        : logo
                    }
                    alt="Selected"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <i className="bi bi-folder-fill fs-2"></i>
                )}
              </div>
              <div
                className="p-2 bg-hover-primary"
                style={{
                  backgroundColor: "#0000ff",
                  position: "absolute",
                  marginLeft: "100px",
                  marginTop: "-30px",
                  borderRadius: "30%",
                  cursor: "pointer",
                }}
                onClick={() => inputRef.current?.click()}
              >
                <i className="bi bi-pencil fs-4 text-light"></i>
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files?.[0];
                  formik.setFieldValue("imageFile", selectedFile);
                }}
              />
              {formik.touched.imageFile && formik.errors.imageFile ? (
                <div className="text-danger">{formik.errors.imageFile}</div>
              ) : null}
            </div>
            <div className="mb-5">
              <label className="form-label">Signature</label>
              <div
                className="form-control d-flex align-items-center justify-content-center"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  minHeight: "130px",
                  maxHeight: "130px",
                  minWidth: "126px",
                  maxWidth: "126px",
                }}
              >
                {!signature !== undefined || null ? (
                  <img
                    src={
                      formik.values.signatureFile
                        ? URL.createObjectURL(formik.values.signatureFile)
                        : signature
                    }
                    alt="Selected"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <i className="bi bi-folder-fill text-center fs-2"></i>
                )}
              </div>
              <div
                className="p-2 bg-hover-primary"
                style={{
                  backgroundColor: "#0000ff",
                  position: "absolute",
                  marginLeft: "100px",
                  marginTop: "-30px",
                  borderRadius: "30%",
                  cursor: "pointer",
                }}
                onClick={() => signatureRef.current?.click()}
              >
                <i className="bi bi-pencil fs-4 text-light"></i>
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={signatureRef}
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files?.[0];
                  formik.setFieldValue("signatureFile", selectedFile);
                }}
              />
              {formik.touched.signatureFile && formik.errors.signatureFile ? (
                <div className="text-danger">{formik.errors.signatureFile}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Domestic Invoice Prefix</label>
              <input
                className={`form-control ${
                  formik.touched.prefix && formik.errors.prefix
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                placeholder="Prefix"
                {...formik.getFieldProps("prefix")}
              />
              {formik.touched.prefix && formik.errors.prefix ? (
                <div className="text-danger">{formik.errors.prefix}</div>
              ) : null}
            </div>
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Domestic Invoice Sequence</label>
              <input
                className={`form-control ${
                  formik.touched.sequence && formik.errors.sequence
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                placeholder="Sequence"
                {...formik.getFieldProps("sequence")}
              />
              {formik.touched.sequence && formik.errors.sequence ? (
                <div className="text-danger">{formik.errors.sequence}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Export Invoice Prefix</label>
              <input
                className={`form-control ${
                  formik.touched.expPrefix && formik.errors.expPrefix
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                placeholder="Prefix"
                {...formik.getFieldProps("expPrefix")}
              />
              {formik.touched.expPrefix && formik.errors.expPrefix ? (
                <div className="text-danger">{formik.errors.expPrefix}</div>
              ) : null}
            </div>
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Export Invoice Sequence</label>
              <input
                className={`form-control ${
                  formik.touched.expSequence && formik.errors.expSequence
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                placeholder="Sequence"
                {...formik.getFieldProps("expSequence")}
              />
              {formik.touched.expSequence && formik.errors.expSequence ? (
                <div className="text-danger">{formik.errors.expSequence}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Due Date</label>
              <input
                className={`form-control ${
                  formik.touched.dueDate && formik.errors.dueDate
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                placeholder="Due Date"
                {...formik.getFieldProps("dueDate")}
              />
              {formik.touched.dueDate && formik.errors.dueDate ? (
                <div className="text-danger">{formik.errors.dueDate}</div>
              ) : null}
            </div>
            <div className="col-12 col-md-6 mb-5">
              <label className="form-label">Tax</label>
              <input
                className={`form-control ${
                  formik.touched.tax && formik.errors.tax ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Tax"
                {...formik.getFieldProps("tax")}
              />
              {formik.touched.tax && formik.errors.tax ? (
                <div className="text-danger">{formik.errors.tax}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <label className="form-label">Terms & Conditions</label>
              <textarea
                className={`form-control ${
                  formik.touched.terms && formik.errors.terms
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Terms & Conditions"
                {...formik.getFieldProps("terms")}
              />
              {formik.touched.terms && formik.errors.terms ? (
                <div className="text-danger">{formik.errors.terms}</div>
              ) : null}
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn w-50 mt-5">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
