export const transferData = {
  accountNumber: "",
  payeeAccountNumber: "",
  payeeType: "",
  amount: "",
  payerAccountNumber: "",
  payerType: "",
  remarks: "",
  transactionPIN: "",
  approverGuid: "",
  beneficiaryName: "",
  currency: "",
  approverName: "",
  initiatedTxn: false,
};
