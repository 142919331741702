import { useState, ChangeEvent, FC, useEffect } from "react";
import { SubAccTxnReceipt } from "./SubAccTxnReceipt";
import { AccountStmnt, Receipt, TxnData } from "../../../interface/Interface";
import { Modal } from "bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { downloadAccountStmnt } from "./SubAccTxnApi";
import { useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
// import { Account, accDetails } from "../SubAccount";

export interface Account {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  createdDate: string;
  balance: string;
}

export interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  primaryFundId: string;
  accountType: string;
  subAccounts: Account[];
}
interface DateSelectionModalProps {
  accountNumber: string;
  currency: string;
}
export const accountStatement = {
  data: [] as AccountStmnt[],
  accountNumber: "",
  currency: "",
  stmntDuration: "",
  fromDate: "",
  toDate: "",
};

interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

const SubAccountTransaction: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const [dateRange, setDateRange] = useState(false);
  const [stmntDuration, setStmntDuration] = useState("4");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<AccountStmnt[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<
    AccountStmnt[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [search, setSearch] = useState(false);
  const [selectedData, setSelectedData] = useState<Receipt>({
    Amount: "",
    "Txn Type": "",
    Date: "",
    Time: "",
    "Transaction Id": "",
    Type: "",
  });
  useEffect(() => {
    // Update filteredTransactions whenever data changes
    setFilteredTransactions(data);
  }, [data]);
  const [accounts, setAccounts] = useState<Accounts[]>([]);

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const location = useLocation();

  const subData = (
    location.state as { accountDetails: Account; AccNumber: accDetails }
  )?.accountDetails;
  const AccNumber = (
    location.state as { accountDetails: Account; AccNumber: accDetails }
  )?.AccNumber;
  const subAccName = subData?.subAccountName;
  const subAccNum = AccNumber?.accountNumber;

  console.log(subAccNum);

  useEffect(() => {
    setFilteredTransactions(
      data?.filter((transaction) => {
        // Check if the transaction matches the selected tab
        const tabFilter =
          selectedTab === "All" ||
          (selectedTab === "Credit" && transaction.txnType === "CR") ||
          (selectedTab === "Debit" && transaction.txnType === "DR");

        // Check if the transaction matches the search query
        const searchFilter =
          transaction.txnTypeName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          transaction.dateTime
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          transaction.currency
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          transaction.txnId.toLowerCase().includes(searchQuery.toLowerCase());

        return tabFilter && searchFilter;
      })
    );
  }, [data, searchQuery, selectedTab]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(data);
    } else if (tab === "Credit" || tab === "Debit") {
      const filteredData = data.filter((transaction) =>
        tab === "Credit"
          ? transaction.txnType === "CR"
          : transaction.txnType === "DR"
      );
      setFilteredTransactions(filteredData);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };
  // const handleDownload = async () => {
  //   const response = await downloadAccountStmnt(
  //     API_URL,
  //     token,
  //     accountStatement?.accountNumber,
  //     accountStatement?.currency,
  //     accountStatement?.stmntDuration,
  //     accountStatement?.fromDate,
  //     accountStatement?.toDate
  //   );

  //   const blobData = new Blob(
  //     [
  //       new Uint8Array(
  //         atob(response.fileData)
  //           .split("")
  //           .map((char) => char.charCodeAt(0))
  //       ),
  //     ],
  //     {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     }
  //   );
  //   const url = window.URL.createObjectURL(blobData);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", response.fileName);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const getSubAccTxn = async () => {
    try {
      const url = `${API_URL}/subAccount/getTxnStatement`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: subAccNum,
        subAccountName: subAccName,
      });
      console.log(subAccNum);

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setData(data?.message?.transactions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubAccTxn();
  }, [subAccNum]);

  return (
    <>
      <h4 className="mb-5">Sub-Account Transactions</h4>
      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className={`nav-link text-active-gray-800 ${
                    selectedTab === "All" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className={`nav-link text-active-gray-800 ${
                    selectedTab === "Credit" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Credit")}
                >
                  Credit
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className={`nav-link text-active-gray-800 text-nowrap ${
                    selectedTab === "Debit" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Debit")}
                >
                  Debit
                </a>
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center p-3 gap-10 me-5">
            <div className={`d-flex align-items-center position-relative my-1`}>
              <i
                className={`ki-duotone ki-magnifier fs-1 position-absolute ms-5`}
                // onClick={() => setSearch(!search)}
              >
                <span className={`path1`}></span>
                <span className="path2"></span>
              </i>

              <input
                value={searchQuery}
                onChange={handleSearchChange}
                type="text"
                data-kt-docs-table-filter="search"
                className={`form-control p-3 border-secondary form-control-solid w-250px ps-15`}
                placeholder="Search transaction"
              />
            </div>
            {/* <div
              className="mt-1 "
              // onClick={() => handleDownloadRange()}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Filter"
            >
              <i className="bi bi-funnel fs-2 text-hover-dark"></i>
            </div>
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download"
            >
              <i
                className=" bi bi-download  fs-2  text-hover-dark"
                onClick={() => {
                  handleDownload();
                }}
              />
            </div> */}
          </div>
        </div>

        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transcation ID
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transaction Date
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transaction Time
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      {/* <th className="min-w-100px p-5 text-nowrap">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform = "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                          id="kt_modal_toggle_sub_receipt"
                          onClick={() => setSelectedData(item?.receipt)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                            {item?.txnTypeName}
                          </td>
                          <td className="p-5 text-gray-600">{item?.txnId}</td>
                          <td className="p-5 text-gray-600">
                            {item?.dateTime?.split(" | ")[0]}
                          </td>
                          <td className="p-5 text-gray-600">
                            {item?.dateTime?.split(" | ")[1]}
                          </td>
                          <td
                            className={`p-5 text-nowrap`}
                            style={{
                              color:
                                item?.txnType === "CR" ? "#0000ff" : "#FF0000",
                            }}
                          >
                            {item?.currency}{" "}
                            {item?.amount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            <i
                              className={`bi bi-box-arrow-${
                                item?.txnType == "CR" ? "in-down " : "up "
                              }`}
                              style={{
                                color:
                                  item?.txnType === "CR"
                                    ? "#0000ff"
                                    : "#FF0000",
                              }}
                            ></i>
                          </td>
                          {/* <td className="p-5 text-gray-600 p-2">
                          <i
                            className="bi bi-eye p-5 "
                            style={{ color: "#0000ff" }}
                          ></i>
                        </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5">Transcation ID</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5">Transaction Time</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      {/* <th className="min-w-100px p-5 text-nowrap">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.txnType === "CR") {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              id="kt_modal_toggle_sub_receipt"
                              onClick={() => setSelectedData(item?.receipt)}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                                {item?.txnTypeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.txnId}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.dateTime?.split(" | ")[0]}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.dateTime?.split(" | ")[1]}
                              </td>
                              <td
                                className={`p-5 text-nowrap`}
                                style={{
                                  color:
                                    item?.txnType === "CR"
                                      ? "#0000ff"
                                      : "#FF0000",
                                }}
                              >
                                {item?.currency}{" "}
                                {item?.amount?.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-box-arrow-in-down`}
                                  style={{
                                    color:
                                      item?.txnType === "CR"
                                        ? "#0000ff"
                                        : "#FF0000",
                                  }}
                                ></i>
                              </td>
                              {/* <td className="p-5 text-gray-600 p-2">
                            <i
                              className="bi bi-eye p-5 "
                              style={{ color: "#0000ff" }}
                            ></i>
                          </td> */}
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5">Transcation ID</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5">Transaction Time</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      {/* <th className="min-w-100px p-5 text-nowrap">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.txnType === "DR") {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              id="kt_modal_toggle_sub_receipt"
                              onClick={() => setSelectedData(item?.receipt)}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                                {item?.txnTypeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.txnId}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.dateTime?.split(" | ")[0]}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.dateTime?.split(" | ")[1]}
                              </td>
                              <td
                                className={`p-5 text-nowrap`}
                                style={{
                                  color:
                                    item?.txnType === "DR"
                                      ? "#FF0000"
                                      : "#0000ff",
                                }}
                              >
                                {item?.currency}{" "}
                                {item?.amount?.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-box-arrow-up`}
                                  style={{
                                    color:
                                      item?.txnType === "DR"
                                        ? "#FF0000"
                                        : "#0000ff",
                                  }}
                                ></i>
                              </td>
                              {/* <td className="p-5 text-gray-600 p-2">
                              <i
                                className="bi bi-eye p-5 "
                                style={{ color: "#0000ff" }}
                              ></i>
                            </td> */}
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="date_range" ref={modalRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-10">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column gap-10 w-100">
                  <div className="d-flex flex-column gap-5 ">
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio1">
                        This Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio1"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("1");
                        }}
                        checked={stmntDuration === "1"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Last Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio2"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("2");
                        }}
                        checked={stmntDuration === "2"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio3">
                        Last 3 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio3"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("3");
                        }}
                        checked={stmntDuration === "3"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio4">
                        Last 6 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio4"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("4");
                        }}
                        checked={stmntDuration === "4"}
                      />
                    </div>
                    <div
                      className={`form-check d-flex justify-content-between ${
                        dateRange ? "mb-0" : "mb-2"
                      } p-4 rounded shadow-sm`}
                    >
                      <label className="fw-bold" htmlFor="Radio5">
                        Date Range
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio5"
                        onChange={() => {
                          setDateRange(true);
                          setStmntDuration("5");
                        }}
                        checked={stmntDuration === "5"}
                      />
                    </div>

                    {dateRange && (
                      <>
                        {/* <li className='list-group-item'> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Start Date"
                              slotProps={{
                                textField: {
                                  className: "mb-2",
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={fromDate}
                              onChange={(date) => {
                                // handleFromDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={toDate}
                              onChange={(date) => {
                                // handleToDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* </li> */}
                        {validationError && (
                          <p className="text-danger">{validationError}</p>
                        )}
                      </>
                    )}
                    {/* </ul> */}
                  </div>
                </div>

                <div className="d-flex flex-row gap-5 mt-5">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      // handleGenerateClick();
                    }}
                  >
                    View
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={() => {
                      handleDeleteCloseModal();
                      setStmntDuration("4");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubAccTxnReceipt data={selectedData} />
    </>
  );
};

export default SubAccountTransaction;
