/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "./SubAccountAppModel";
import { useEffect, useRef, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
export const SteppperModal3 = ({ data, updateData, hasError }: StepProps) => {
  const baseCurrency = sessionStorage.getItem("currency");

  useEffect(() => { }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string

    if (value === "0" || value === "") {

      updateData({
        appLimit: {
          limit: "",
        },
      });
      return;
    }
    if (value === "") {
      updateData({
        appLimit: {
          limit: value,
        },
      });
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      updateData({
        appLimit: {
          limit: value,
        },
      });
    }
  };

  return (
    <div className="pb-5 card shadow-sm p-10" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row">
          {/* begin::Label */}
          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
            <span className="required">Set Goal</span>


          </label>
          {/* end::Label */}

          <div>
            <div className="d-flex flex-row gap-5">
              {/*begin:Option */}
              <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <div className="card shadow-sm d-flex flex-row gap-20 w-100 p-5">
                  <span className="d-flex align-items-center me-2">
                    <span className="d-flex flex-column">
                      <span className="fw-bolder fs-6">Open</span>
                      {/* <span className='fs-7 text-muted'>
                      Creating a clear text structure is just one SEO
                    </span> */}
                    </span>
                  </span>

                  <span className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="accType"
                      value="Open"
                      checked={data.appBasic.appType === "OPEN"}
                      onChange={() => {
                        updateData({
                          appBasic: {
                            appName: data.appBasic.appName,
                            appType: "OPEN",
                          },
                          appLimit: {
                            limit: "",
                          }
                        })

                      }
                      }
                    />
                  </span>
                </div>
              </label>

              <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <div className="card shadow-sm d-flex flex-row gap-20 w-100 p-5">
                  <span className="d-flex  align-items-center me-2">
                    <span className="d-flex flex-column ">
                      <span className="fw-bolder fs-6">Set Limit</span>
                      {/* <span className='fs-7 text-muted'>
                      Creating a clear text structure is just one aspect
                    </span> */}
                    </span>
                  </span>
                  <span className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="accType"
                      value="Set Limit"
                      checked={data.appBasic.appType === "SET_LIMIT"}
                      onChange={() =>
                        updateData({
                          appBasic: {
                            appName: data.appBasic.appName,
                            appType: "SET_LIMIT",
                          },
                        })
                      }
                    />
                  </span>
                </div>
              </label>
            </div>

            {data.appBasic.appType === "SET_LIMIT" && (
              <>
                <div className="mb-10 mt-10">
                  <label className="form-label">Please enter Limit</label>
                  <span
                    className="fs-6 fw-bold text-gray-700"
                    style={{
                      position: "absolute",
                      padding: "12px",
                      right: "345px",
                      marginTop: "26px",
                    }}
                  >
                    {baseCurrency}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: "60px" }}
                    value={data.appLimit.limit}
                    onChange={handleInputChange}
                    autoFocus
                  />

                  {!data.appLimit.limit && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Limit is required
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
