import { useState } from "react";


export function PasswordConfirmation(){
    return(
        <>
        <div className="card-body py-15 py-lg-20 text-center">
							{/* <!--begin::Logo--> */}
							
							{/* <!--end::Logo--> */}
							{/* <!--begin::Title--> */}
							<h1 className="fw-bolder text-gray-900 mb-5">Password is changed</h1>
							{/* <!--end::Title--> */}
							{/* <!--begin::Message--> */}
							<div className="fs-6 fw-semibold text-gray-500 mb-10 text-nowrap">This is your opportunity to get creative &nbsp;
							<a href="#" className="link-primary fw-semibold">max@xfin.digital</a>
							<br />that gives readers an idea</div>
							{/* <!--end::Message--> */}
							{/* <!--begin::Link--> */}
							<div className="mb-11">
								<a href="../../demo1/dist/authentication/layouts/corporate/sign-in.html" className="btn btn-sm btn">Sign in</a>
							</div>
							{/* <!--end::Link--> */}
							{/* <!--begin::Illustration--> */}
							<div className="mb-0">
								{/* <img src="assets/media/auth/ok.png" className="mw-100 mh-300px theme-light-show" alt="" /> */}
								<img src="https://img.freepik.com/premium-vector/password-secure-vector-illustration-flat-isometric-vector-illustration-data-secure-icon_194782-643.jpg?w=996" className="mw-100 mh-300px" alt="" />
							</div>
							{/* <!--end::Illustration--> */}
						</div>
        </>
    )
}