import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  findBusinessCategory,
  getUserDetails,
  updateDetails,
  updateUser,
} from "./Api";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  useFormik,
} from "formik";
import * as Yup from "yup";
import {
  BusinessFormValues,
  CategoryModal,
  Message,
  Payload,
} from "./Interface";
import dayjs from "dayjs";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ApiCalling = {
  api: (): void => {},
};

const CompanyDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [img, setImg] = useState<string | undefined>("");
  const [doc, setDoc] = useState<string | undefined>("");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [userDetails, setUserDetails] = useState<Message>();
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const validationSchema = Yup.object({
    tradeLicenceDocument: Yup.mixed()
    // .required("Document must be provided")
    .test("is-different-document", "Trade License Document must be different", function (value) {
      const { reapplyParams } = userDetails || {};
      if (reapplyParams?.tradeLicenceDocument && Object.keys(reapplyParams).length > 0) {
        return value instanceof File && value?.name  !==  userDetails?.businessDocs?.find(
          (document) => document?.documentTypes[0] === 16
        )?.documentName;
      }
      return true; // Bypass if not reapply
    }),
    aoaMoaDocument: Yup.mixed()
    // .required("Document must be provided")
    .test("is-different-document", "Aoa/Moa Document must be different", function (value) {
      const { reapplyParams } = userDetails || {};
      if (reapplyParams?.aoaMoaDocument && Object.keys(reapplyParams).length > 0) {
        return value instanceof File && value?.name  !== userDetails?.businessDocs?.find(
          (document) => document?.documentTypes[0] === 18
        )?.documentName;
      }
      return true; // Bypass if not reapply
    }),
    taxDocument: Yup.mixed()
    // .required("Document must be provided")
    .test("is-different-document", "Tax Document must be different", function (value) {
      const { reapplyParams } = userDetails || {};
      if (reapplyParams?.taxDocument && Object.keys(reapplyParams).length > 0) {
        return value instanceof File && value?.name  !== userDetails?.businessDocs?.find(
          (document) => document?.documentTypes[0] === 19
        )?.documentName;
      }
      return true; // Bypass if not reapply
    }),
  });

  const formik = useFormik({
    initialValues: {
      tradeLicenceDocument: undefined,
      aoaMoaDocument: undefined,
      taxDocument: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const formData = new FormData();
        const getRenamedFile = (file: any, label: any) => {
          const fileExtension = file.name.split(".").pop();
          const newFileName = `${label}.${fileExtension}`;
          return new File([file], newFileName, { type: file.type });
        };

        if (values.tradeLicenceDocument) {
          formData.append(
            "tradeLicenceDocument",
            getRenamedFile(values?.tradeLicenceDocument, "LICENSE_16")
          );
          formData.append("reapplyParams[tradeLicenceDocument]", "license");
        }

        if (values.aoaMoaDocument) {
          formData.append(
            "aoaMoaDocument",
            getRenamedFile(values?.aoaMoaDocument, "AOA/MOA_16")
          );
          formData.append("reapplyParams[aoaMoaDocument]", "aoamoa");
        }

        if (values.taxDocument) {
          formData.append(
            "taxDocument",
            getRenamedFile(values?.taxDocument, "TAX_16")
          );
          formData.append("reapplyParams[taxDocument]", "taxupdate");
        }

        formData.append("kycId", userDetails?.kycId || "");

        await updateDetails(
          formData,
          token,
          `${API_URL}`,
          "kycUpdateBusinessDocuments"
        ).then(async (res) => {
          console.log(res);

          if (res?.status?.statusCode === 0) {
            Swal.fire({
              icon: "success",
              title: "Confirmation",
              text: "Your profile will be updated after the review. We typically take between 24 to 72 hours to provide an update via SMS and email.",
            });
          }
          const data = await getUserDetails(API_URL, token);
          setUserDetails(data);
        });
      } catch (error) {
        toast("Error updating details");
      } finally {
        setLoading(false);
        setSubmitting(false);
        setIsLicEdit(false);
        setIsAoaEdit(false);
        setIsTaxEdit(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        formik.resetForm();
      }
    },
  });

  const [isLicEdit, setIsLicEdit] = useState(false);
  const [isAoaEdit, setIsAoaEdit] = useState(false);
  const [isTaxEdit, setIsTaxEdit] = useState(false);

  const handleLicEdit = () => {
    setIsLicEdit((prev) => !prev);
  };
  const handleAoaEdit = () => {
    setIsAoaEdit((prev) => !prev);
  };
  const handleTaxEdit = () => {
    setIsTaxEdit((prev) => !prev);
  };

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);

  return (
    <>
      {" "}
      <h4 className="mb-5">Company Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card shadow-sm p-5">
          <form onSubmit={formik.handleSubmit} className="fs-8">
            <div className="d-flex flex-row ms-5 mt-5">
              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">Company Name</div>
                <div className="fs-7">{userDetails?.businessName}</div>
              </div>
              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">Registered Email</div>
                <div>{userDetails?.email}</div>
              </div>
              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">Registered Phone Number</div>
                <div>
                  {userDetails?.isdCode}-{userDetails?.mobile}
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex flex-row ms-5 mt-5">
              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">Registered Address</div>
                <div className="fs-7">{userDetails?.city}</div>
              </div>
              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">License {typeof userDetails?.reapplyParams?.tradeLicenceDocument ==="string" ? (
                   <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{userDetails?.reapplyParams?.tradeLicenceDocument}</Tooltip>}>
                        <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                          <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                        </span>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}</div>
                {isLicEdit ? (
                  <div className="input-group">
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="document"
                      onChange={(event) => {
                        const file = event.currentTarget.files?.[0];
                        formik?.setFieldValue("tradeLicenceDocument", file);
                      }}
                      onBlur={formik.handleBlur}
                      // value={formik.values.document}
                      className={`form-control ${
                        formik.errors.tradeLicenceDocument &&
                        formik.touched.tradeLicenceDocument
                          ? "is-invalid"
                          : ""
                      }`}
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-row ">
                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        if (
                          userDetails?.businessDocs?.find(
                            (document) => document?.documentTypes[0] === 16
                          )?.documentExtention === "pdf"
                        ) {
                          setDoc(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 16
                            )?.documentPath
                          );
                          handleDocViewClick();
                        } else {
                          setImg(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 16
                            )?.documentPath
                          );
                          handleViewClick();
                        }
                      }}
                    >
                      {
                        userDetails?.businessDocs?.find(
                          (document) => document?.documentTypes[0] === 16
                        )?.documentName
                      }
                    </div>{" "}
                    <i
                      className="bi bi-pencil-square fs-8 cursor-pointer mt-1 text-dark ms-2"
                      onClick={handleLicEdit}
                    ></i>
                  </div>
                )}
                {formik.errors.tradeLicenceDocument &&
                              formik.touched.tradeLicenceDocument && (
                                <div className="text-danger">
                                  {formik.errors.tradeLicenceDocument}
                                </div>
                              )}
              </div>

              <div style={{ width: "400px" }}>
                <div className="fw-bold fs-5">Tax Document {typeof userDetails?.reapplyParams?.taxDocument ==="string" ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{userDetails?.reapplyParams?.taxDocument}</Tooltip>}>
                        <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                          <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                        </span>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}</div>
                {isTaxEdit ? (
                  <div className="input-group">
                    <input
                      type="file"
                      name="taxDocument"
                      ref={fileInputRef}
                      onChange={(event) => {
                        const file = event.currentTarget.files?.[0];
                        formik?.setFieldValue("taxDocument", file);
                      }}
                      onBlur={formik.handleBlur}
                      // value={formik.values.document}
                      className={`form-control ${
                        formik.errors.taxDocument && formik.touched.taxDocument
                          ? "is-invalid"
                          : ""
                      }`}
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-row">
                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        if (
                          userDetails?.businessDocs?.find(
                            (document) => document?.documentTypes[0] === 19
                          )?.documentExtention === "pdf"
                        ) {
                          setDoc(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 19
                            )?.documentPath
                          );
                          handleDocViewClick();
                        } else {
                          setImg(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 19
                            )?.documentPath
                          );
                          handleViewClick();
                        }
                      }}
                    >
                      {
                        userDetails?.businessDocs?.find(
                          (document) => document?.documentTypes[0] === 19
                        )?.documentName
                      }
                    </div>{" "}
                    <i
                      className="bi bi-pencil-square fs-8 cursor-pointer text-dark mt-1 ms-2"
                      onClick={handleTaxEdit}
                    ></i>
                  </div>
                )}
                 {formik.errors.taxDocument &&
                              formik.touched.taxDocument && (
                                <div className="text-danger">
                                  {formik.errors.taxDocument}
                                </div>
                              )}
              </div>
            </div>
            <br />
            <div
              className="d-flex flex-row ms-5 my-5"
              style={{ width: "250px" }}
            >
              <div>
                <div className="fw-bold fs-5">AOA/MOA {typeof userDetails?.reapplyParams?.aoaMoaDocument ==="string" ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{userDetails?.reapplyParams?.aoaMoaDocument}</Tooltip>}>
                        <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                          <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                        </span>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}</div>
                {isAoaEdit ? (
                  <div className="input-group">
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="aoaMoaDocument"
                      onChange={(event) => {
                        const file = event.currentTarget.files?.[0];
                        formik?.setFieldValue("aoaMoaDocument", file);
                      }}
                      onBlur={formik.handleBlur}
                      // value={formik.values.document}
                      className={`form-control ${
                        formik.errors.aoaMoaDocument &&
                        formik.touched.aoaMoaDocument
                          ? "is-invalid"
                          : ""
                      }`}
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-row">
                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        if (
                          userDetails?.businessDocs?.find(
                            (document) => document?.documentTypes[0] === 18
                          )?.documentExtention === "pdf"
                        ) {
                          setDoc(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 18
                            )?.documentPath
                          );
                          handleDocViewClick();
                        } else {
                          setImg(
                            userDetails?.businessDocs?.find(
                              (document) => document?.documentTypes[0] === 18
                            )?.documentPath
                          );
                          handleViewClick();
                        }
                      }}
                    >
                      {
                        userDetails?.businessDocs?.find(
                          (document) => document?.documentTypes[0] === 18
                        )?.documentName
                      }
                    </div>{" "}
                    <i
                      className="bi bi-pencil-square fs-8 cursor-pointer text-dark mt-1 ms-2"
                      onClick={handleAoaEdit}
                    ></i>
                  </div>
                )}
                 {formik.errors.aoaMoaDocument &&
                              formik.touched.aoaMoaDocument && (
                                <div className="text-danger">
                                  {formik.errors.aoaMoaDocument}
                                </div>
                              )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn ms-5 mb-5 mt-5"
                disabled={formik.isSubmitting || loading ||
                  (
                    formik.values.tradeLicenceDocument === undefined &&
                    formik.values.aoaMoaDocument === undefined &&
                    formik.values.taxDocument === undefined 
                  )}
              >
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>Update</div>
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="modal" tabIndex={-1} id="image_modal">
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="modal-body justify-content-center d-flex">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <img
                    className="image"
                    src={img}
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pdf_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
            <div
              className="modal-content"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <div className="modal-header" id="kt_modal_add_user_header">
                <h2 className="fw-bold">PDF Viewer</h2>

                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>

              <div className="modal-body px-15">
                <embed
                  src={`${doc}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyDetails;
