import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
  } from "@reduxjs/toolkit";

  import notificationReducer from "../firebase/NotificationSlice"
  
  
  const rootReducer = combineReducers({
   notificationLogs:notificationReducer
  });
  
  const store = configureStore({
    reducer: rootReducer,
  });
  
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  
  export default store;