/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import { PhoneInput } from "react-international-phone";
import { register } from "../core/_requests";
import XfinLogo from "../../../../_metronic/assets/Icons/xfin.png";
import { useFormCompletion } from "./KYC/Stepper";
import { Link } from "react-router-dom";
import { isPhoneValid } from "./Login";

const initialValues = {
  isdCode: "",
  phone: "",
  status: "",
};

const registrationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number cannot be longer than 15 digits")
    .test("is-valid-phone", "Phone number is invalid", function (value) {
      if (value) {
        return isPhoneValid(value); // Validate phone only if Phone is true and value exists
      }
      return true; // If Phone is false, or no value, bypass the phone validation
    }),
});

export function Registration() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [loading, setLoading] = useState(false);
  const { saveAuth, setRegUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        // Directly handle the registration process without setTimeout
        const data = await register(
          values.phone.split(" ")[0],
          values.phone.split(" ")[1]?.replace(/[^0-9]/g, "")
        );

        if (data?.data?.status?.statusCode === 0) {
          setRegUser(data?.data?.message);
          markFormCompleted("registration");
          navigate("/auth/verify");
        } else {
          formik.setFieldValue("status", data?.data?.status?.messageDescription);
        }
      } catch (error) {
        setStatus("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className=" ">
          <form
            className="form fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="kt_login_signup_form"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Heading */}
            <div className="mb-11">
              {/* begin::Title */}
              <h1 className="text-dark fs-2hx fw-bolder mb-3">
                Welcome to Xfin bank
              </h1>
              {/* end::Title */}

              <div className="d-flex flex-row">
                <img
                  src={XfinLogo}
                  style={{
                    height: "120px",
                    width: "120px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
            </div>

            {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}

            <div className="">
              <p className="mb-20">Please enter your phone number to proceed</p>
            </div>
            <div className="fv-row">
              <label className="form-label fw-bolder text-dark fs-6">
                Phone
              </label>

              <div className="d-flex flex-column flex-grow-1">
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "43.59px",
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      height: "43.59px",
                    },
                  }}
                  forceDialCode={true}
                  value={formik.values.phone} 
                  onChange={(value) => {
                    formik.setFieldValue("phone", value);
                  }}
                  defaultCountry="in" // Default country code
                />

                {formik.touched.phone && formik.errors.phone && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.phone}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p className="mt-2">
              This will be the registered number for the account.
            </p>

            <div className="text-center mt-10">
              <button
                type="submit"
                id="kt_sign_up_submit"
                className="btn btn-lg btn w-100"
                disabled={formik.isSubmitting || loading}
              >
                {loading ? (
                  <div className=" spinner-border text-primary" role="status">
                    {/* <span className="" role="status" aria-hidden="true"></span> */}
                  </div>
                ) : (
                  <div>Proceed</div>
                )}
              </button>
            </div>
          </form>
          <div className="text-gray-500 text-center fw-semibold fs-6 mt-5">
            Already a Member?{" "}
            <Link
              to="/auth"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
