import React, { ChangeEvent, useRef } from "react";
import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import PinInput from "react-pin-input";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface ApproverTxn {
  userId: string;
  tenantId: string;
  businessGuid: string;
  txnHolderId: string;
  initiatorGuid: string;
  approverGuid: string;
  txnType: number;
  requestJson: string;
  functionCode: string;
  approverName: string;
  initiatorName: string;
  currency: string;
  beneficiaryName: string;
  amount: number;
  expiryDate: string;
  status: number;
  createdDate: string;
  createdDateString: string;
  receipts: Record<string, any>;
  scheduleTxn: boolean;
  rejectionMsg: string;
}

const Initiations = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const details = sessionStorage.getItem("userDetails");
  const userDetails = details ? JSON.parse(details) : null;

  const role = sessionStorage.getItem("role");
  const roleName = role ? JSON.parse(role) : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const [initiatedTxns, setInitiatedTxns] = useState<ApproverTxn[]>([]);
  const [filteredTxns, setFilterdTxns] = useState(
    initiatedTxns?.filter(
      (txn) => txn.status === 1 || txn.status === 3 || txn.status === 5
    )
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const OTPRef = useRef<HTMLDivElement | null>(null);
  let ele = useRef<PinInput | null>(null);
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const [errMsg, setErrMsg] = useState("");
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [req, setReq] = useState("");
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  useEffect(() => {
    if (OTPRef.current) {
      const modal = new Modal(OTPRef.current);

      OTPRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
        ele?.current?.focus();
      });

      OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
      return () => {
        OTPRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleOTPCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    setReq("");
    ele.current?.clear();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const getInitiatedTxns = async () => {
    try {
      const url = `${API_URL}/team/getInitiatedTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        // pageNumber: currentPage,
        // pageSize: 25,
        searchCriteriaList: [
          // {
          //   propertyName: "amountLesserThan",
          //   propertyValue: userDetails?.userDetails?.zarLimit,
          // },
          // {
          //   propertyName: "approverName",
          //   propertyValue: userDetails?.userDetails?.name,
          // },
        ],
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        console.log(data);

        // setHide(!hide);
        setInitiatedTxns(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInitiatedTxns();
  }, []);

  useEffect(() => {
    setFilterdTxns(
      initiatedTxns?.filter((txn) => txn?.status === 1 || txn?.status === 3)
    );
  }, [initiatedTxns.length]);

  const deleteInitiatedTxn = async (id: string) => {
    try {
      const url = `${API_URL}/team/deleteInitiatedTxn`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        txnHolderId: id,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Initiated transaction deleted successfully",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        getInitiatedTxns();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTxns.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTxns?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  function extractDate(dateString: string) {
    const parts = dateString.split(",");
    if (parts.length >= 2) {
      return parts[0] + "," + parts[1];
    }
    return dateString; // Return the original string if it doesn't have enough commas
  }

  return (
    <>
      <style>
        {`
        .black-icon {
        color: black;
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
        margin-bottom: 60px; 
        filter: invert(100%);
        background-size: 20px 20px;
        }
      `}
      </style>
      <h4 className="mb-5">Initiated Transactions</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          {userDetails?.userDetails?.authority?.includes("1") ||
          userDetails?.userDetails?.authority?.includes("2") ||
          userDetails?.userDetails === undefined ? (
            <div className="p-5">
              <div
                className="card p-2"
                style={{
                  overflow: "scroll",
                }}
              >
                <table className="table align-middle border rounded table-row-dashed fs-6 ">
                  <thead
                    className="bg-gray-200"
                    style={{
                      overflowX: "scroll",
                    }}
                  >
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transcation Type
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Beneficiary
                      </th>
                      {roleName === "ROLE_SME_ADMIN" && (
                        <th className="min-w-100px p-5 text-nowrap">
                          Initiator
                        </th>
                      )}
                      <th className="min-w-100px p-5 text-nowrap">Approver</th>
                      <th className="min-w-125px p-5 text-nowrap">Status</th>
                      <th className="min-w-50px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform = "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                          //   id="kt_receipt_modal_toggle"
                          //   onClick={() => handleDownloadClick(item.txnId)}
                        >
                          <td
                            className="p-5 text-gray-600 mw-100px"
                            // style={{ cursor: "pointer" }}
                          >
                            {extractDate(item.createdDateString)}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item.functionCode}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item?.currency}{" "}
                            {item.amount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item.beneficiaryName ? item.beneficiaryName : "-"}
                          </td>
                          {roleName === "ROLE_SME_ADMIN" && (
                            <td className="p-5 text-gray-600 mw-100px">
                              {item.initiatorName}
                            </td>
                          )}

                          <td className="p-5 text-gray-600 mw-100px">
                            {item.approverName}
                          </td>
                          <td className={`p-5 mw-100px `}>
                            <p
                              className={`badge ${
                                item.status === 1
                                  ? "badge-light-warning"
                                  : item.status === 2
                                  ? "badge-light-success"
                                  : item.status === 5
                                  ? "badge-light-warning"
                                  : "badge-light-danger"
                              }`}
                            >
                              {item.status === 1
                                ? "PENDING_APPROVAL"
                                : item.status === 2
                                ? "SUCCESS"
                                : item.status === 5
                                ? "PENDING_APPROVAL"
                                : "REJECTED"}
                            </p>{" "}
                            &nbsp;&nbsp;
                            {item.status === 3 && (
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                  <Popover
                                    className="p-2 bg-light-secondary"
                                    id="popover-trigger-focus"
                                  >
                                    {item?.rejectionMsg}
                                  </Popover>
                                }
                              >
                                <i className="bi bi-info-circle cursor-pointer"></i>
                              </OverlayTrigger>
                            )}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item?.status === 1 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipDelete}
                              >
                                <i
                                  className="bi bi-trash3 text-danger p-5"
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "question",
                                      title: "Delete Transaction",
                                      text: "Are you sure you want to delete the initiated transaction",
                                      showCancelButton: true,
                                      confirmButtonText: "Yes",
                                      cancelButtonText: "No",
                                      allowEscapeKey: true,
                                      allowEnterKey: true,
                                    }).then((res) => {
                                      if (res.isConfirmed) {
                                        deleteInitiatedTxn(item.txnHolderId);
                                      }
                                    });
                                  }}
                                ></i>
                              </OverlayTrigger>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage ===
                        Math.ceil(filteredTxns?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow 
                        ${
                          currentPage ===
                          Math.ceil(filteredTxns?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }
                      `}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(
                            Math.ceil(filteredTxns?.length / itemsPerPage)
                          )
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {filteredTxns?.length}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-5">
              <p className="text-center">
                You dont have the authority to perform this action. Please
                contact the admin
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { Initiations };
