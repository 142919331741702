/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, ChangeEvent, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { StepProps } from "./PayrollAppModel";
import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parsePhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isPhoneValid } from "../../../modules/auth/components/Login";

export const PayrollStepperModal = ({
  data,
  updateData,
  hasError,
}: StepProps) => {
  const value = dayjs(data.doj.doj);
  const DateValue = dayjs(data.empDate.date);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const IdfileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const [id, setId] = useState("");

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    updateData({
      empAddressProof: {
        addressProof: file||null,
      },
    });
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        const base64Data = dataURL.split(",")[1];
        setImage(dataURL);
       
      };
      reader.readAsDataURL(file);
    } else {
      // Optional: Alert the user that only image files are accepted
      // alert('Please select an image file.');
      // Clear the input
      event.target.value = "";
    }
  };

  const handleIDImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    updateData({
      idProof: {
        idProof: file||null,
      },
    });
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        const base64Data = dataURL.split(",")[1];
        setId(dataURL);
       
      };
      reader.readAsDataURL(file);
    } else {
      // Optional: Alert the user that only image files are accepted
      // alert('Please select an image file.');
      // Clear the input
      event.target.value = "";
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleIdClick = () => {
    if (IdfileInputRef.current) {
      IdfileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div className="fv-row">
            {/* begin::Label */}
            {/* <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Select Category</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label> */}
            {/* end::Label */}
            <div
              className="card shadow p-5"
              style={{ maxHeight: "300px", overflow: "auto" }}
            >
              <div className="  p-3">
                <div>
                  <label className="form-label">Employee Name</label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Employee Name"
                    value={data.appBasic.empName}
                    style={{ padding: "6.5px" }}
                    autoFocus
                    onChange={(e) => {
                      const value = e.target.value;
                      // Regex to allow only alphanumeric characters
                      const alphanumericValue = value.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                      );

                      updateData({
                        appBasic: {
                          empName: alphanumericValue,
                        },
                      });
                    }}
                    maxLength={50}
                  />
                  {!data.appBasic.empName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Name is required
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex flex-column flex-grow-1 mt-6"
                  style={{ width: "100%" }}
                >
                  <label className="form-label">DOB</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data.empDate.date}
                    // Assuming you're storing the date in YYYY-MM-DD format
                    onChange={(e) => {
                      updateData({
                        empDate: {
                          date: e.target.value, // The value will be in YYYY-MM-DD format
                        },
                      });
                    }}
                    // max={new Date().toISOString().split("T")[0]}
                    max={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 15)
                      )
                        .toISOString()
                        .split("T")[0]
                    } // 15 years ago
                  />
                </div>
                {!data.empDate.date && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Date is required
                    </div>
                  </div>
                )}

                <div className="mt-8">
                  <label className="form-label">Employee Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Employee Email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
                    value={data.empEmail.email.toLowerCase()}
                    style={{ padding: "6.5px" }}
                    onChange={(e) =>
                      updateData({
                        empEmail: {
                          email: e.target.value,
                        },
                      })
                    }
                    maxLength={50}
                  />
                  {!data.empEmail.email && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Email is required
                      </div>
                    </div>
                  )}
                  {data.empEmail.email && (
                    <>
                      {/* Check if email is empty or contains only allowed characters */}
                      {!/^[a-z0-9.@]+$/.test(data.empEmail.email) && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="empEmail"
                            data-validator="invalidCharacters"
                            className="fv-help-block text-danger"
                          >
                            Only letters (a-z), digits (0-9), and periods (.)
                            are allowed
                          </div>
                        </div>
                      )}
                      {/* Check if the email is valid */}
                      {!/^[a-z0-9]+[a-z0-9._%+-]*@[a-z0-9.-]+\.[a-z]{2,}$/.test(
                        data.empEmail.email
                      ) && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="empEmail"
                            data-validator="invalidFormat"
                            className="fv-help-block text-danger"
                          >
                            Please enter a valid email address
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="mt-8">
                  <label className="form-label">Employee Phone Number</label>
                  <PhoneInput
                    defaultCountry="in"
                    onChange={(e) =>
                      updateData({
                        empPhone: {
                          phone: e ? e : "",
                        },
                      })
                    }
                    inputStyle={{ width: "100%" }}
                    disableDialCodePrefill={false}
                    // className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                    forceDialCode={true}
                    value={data.empPhone.phone}
                  />
                  {(!data.empPhone.phone ||
                    !isPhoneValid(data.empPhone.phone)) &&
                    hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="empPhone"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          {!data.empPhone.phone
                            ? "Phone is required"
                            : "Invalid phone number"}{" "}
                          {/* Show appropriate error message */}
                        </div>
                      </div>
                    )}
                </div>
                <div className="mt-8">
                  <label className="form-label">Employee Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    value={data.empAddress.address}
                    style={{ padding: "6.5px" }}
                    onChange={(e) =>
                      updateData({
                        empAddress: {
                          address: e.target.value,
                        },
                      })
                    }
                    maxLength={100}
                  />
                  {!data.empAddress.address && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Address is required
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="position-relative mt-8 form-control p-2 d-flex"
                  onClick={handleClick}
                >
                  <span className="text-gray-500">Address Proof</span>
                  <input
                    type="file"
                    id="uploadImage"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".jpeg,.jpg"
                    onChange={handleImageUpload}
                  />

                  <div
                    className="position-absolute translate-middle-y  end-0 me-3 "
                    style={{ marginTop: "13px" }}
                  >
                    <i
                      className="bi bi-cloud-arrow-up-fill fs-2"
                      id="uploadIcon"
                    ></i>
                  </div>
                </div>
                <p className="fs-7">Only images are accepted</p>
                {image && (
                  <>
                    <img
                      src={image}
                      style={{
                        height: "50px",
                        width: "50px",
                        padding: "5px 0px",
                      }}
                      alt=""
                    />
                  </>
                )}
                {!data.empAddressProof.addressProof && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Address proof is required
                    </div>
                  </div>
                )}

                <div className="mt-8">
                  <label className="form-label">Blood Group</label>
                  <select
                    className="form-select"
                    data-control="select2"
                    value={data.empBloodGroup.bloodGroup}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      updateData({
                        empBloodGroup: {
                          bloodGroup: e.target.value,
                        },
                      });
                    }}
                    placeholder="Select an option"
                    style={{ padding: "6.5px" }}
                  >
                    <option value="">Blood Group</option>
                    <option value="A +ve">A +ve</option>
                    <option value="O +ve">O +ve</option>
                    <option value="AB +ve">AB +ve</option>
                    <option value="A -ve">A -ve</option>
                    <option value="O -ve">O -ve</option>
                    <option value="B -ve">B -ve</option>
                    <option value="AB -ve">AB -ve</option>
                  </select>
                  {!data.empBloodGroup.bloodGroup && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Blood Group is required
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="position-relative mt-8 form-control p-2 d-flex"
                  onClick={handleIdClick}
                >
                  <span className="text-gray-500">Id Proof</span>
                  <input
                    type="file"
                    id="uploadImage"
                    ref={IdfileInputRef}
                    style={{ display: "none" }}
                    accept=".jpeg,.jpg"
                    onChange={handleIDImageUpload}
                  />
                  <div
                    className="position-absolute translate-middle-y  end-0 me-3 "
                    style={{ marginTop: "13px" }}
                  >
                    <i
                      className="bi bi-cloud-arrow-up-fill fs-2"
                      id="uploadIcon"
                    ></i>
                  </div>
                </div>
                <p className="fs-7">Only images are accepted</p>
                {id && (
                  <>
                    <img
                      src={id}
                      style={{
                        height: "50px",
                        width: "50px",
                        padding: "5px 0px",
                      }}
                      alt=""
                    />
                  </>
                )}
                {!data.idProof.idProof && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Id proof is required
                    </div>
                  </div>
                )}
                <div className="mt-8">
                  <label className="form-label">Emergency Contact Number</label>
                  <PhoneInput
                    defaultCountry="in"
                    // inputClassName={clsx(
                    //   'form-control bg-transparent',
                    //   { 'is-invalid': formik.touched.phone && formik.errors.phone },
                    //   { 'is-valid': formik.touched.phone && !formik.errors.phone }
                    // )}
                    onChange={(e) =>
                      updateData({
                        emeContact: {
                          emeContact: e ? e : "",
                        },
                      })
                    }
                    inputStyle={{ width: "100%" }}
                    disableDialCodePrefill={false}
                    // className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                    forceDialCode={true}
                    value={data.emeContact.emeContact}
                  />

                  {(!data.emeContact.emeContact ||
                    !isPhoneValid(data.emeContact.emeContact)) &&
                    hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="emeCont"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          {!data.emeContact.emeContact
                            ? "Phone is required"
                            : "Invalid phone number"}{" "}
                          {/* Show appropriate error message */}
                        </div>
                      </div>
                    )}
                </div>
                <div
                  className="d-flex flex-column flex-grow-1 mt-6"
                  style={{ width: "100%" }}
                >
                  <label className="form-label">Date of Joining</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data.doj.doj} // Assuming you're storing the date in YYYY-MM-DD format
                    onChange={(e) => {
                      updateData({
                        doj: {
                          doj: e.target.value, // The value will be in YYYY-MM-DD format
                        },
                      });
                    }}
                    max={new Date().toISOString().split("T")[0]} // Set max date to today
                  />
                </div>
                {!data.doj.doj && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Date is required
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*end::Form Group */}
        </div>
      </div>
    </>
  );
};
