export const InventoryData = {
  itemName: "",
  itemDesc: "",
  itemRate: "",
  itemAvailability: true,
};

export const InventoryEditData = {
  itemName: "",
  itemDesc: "",
  itemRate: "",
  itemAvailability: true,
  itemGuid: "",
};

export const customerData = {
  customerName: "",
  companyName: "",
  type:"",
  countryType: "",
  isdCode: 91,
  phoneNumber: "",
  emailId: "",
  address: "",
  vatNumber: "",
  notes: "",
  bankName: "",
  bankAccountNo: "",
  bic: "",
};

export const editCustomerData = {
  customerGuid: "",
  customerName: "",
  companyName: "",
  type:"",
  countryType: "",
  isdCode: 91,
  phoneNumber: "",
  emailId: "",
  address: "",
  vatNumber: "",
  notes: "",
  bankName: "",
  bankAccountNo: "",
  bic: "",
};
