/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "../../../../../_metronic/helpers";
import { StepProps } from "../IAppModels";

const Step1 = ({ data, updateData, hasError }: StepProps) => {
  const handleRadioChange = (category: any) => {
    // dataOutcome.cancel = true;
    updateData({
      category: {
        category: category,
      },
      name: {
        name: "",
      },
      description: {
        description: "",
      },
      submit: {
        submit: () => {},
      },
    });
  };

  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100 ms-20">
        <label className="form-label mb-5">Select a category</label>
        <div className="mb-3 d-flex flex-column justify-content-around gap-5">
          <div className="form-check form-check-custom form-check-solid form-check-sm">
            <input
              className="form-check-input"
              name="accType"
              type="radio"
              value="2"
              checked={data.category.category === "Manual"}
              onChange={() => handleRadioChange("Manual")}
            />
            <label className="form-check-label" htmlFor="employeePaymentRadio">
              Manual
            </label>
          </div>
          <div className="form-check form-check-custom form-check-solid form-check-sm">
            <input
              className="form-check-input"
              name="accType"
              type="radio"
              value="1"
              checked={data.category.category === "Batch"}
              onChange={() => handleRadioChange("Batch")}
            />
            <label className="form-check-label" htmlFor="vendorPaymentRadio">
              Batch Upload
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
