import { useState, useEffect, useRef, ChangeEvent } from "react";
import { Modal } from "bootstrap";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import PinInput from "react-pin-input";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Accounts } from "../../interface/Interface";
import { AddMoney } from "./AddMoney";
import masterCard from "../../../_metronic/assets/Icons/mastercard-logo-transparent-png-stickpng-10.png";
import blueCard from "../../../_metronic/assets/Icons/Blue_Plain.png";
import blackCard from "../../../_metronic/assets/Icons/Black_Plain.png";
import { Card, CardData } from "./MyCard";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";

interface AddCardProps {
  listCards: (action: string) => Promise<void>; // Adjust the return type as needed
  cards: Card[];
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setNewCardIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const AddCard: React.FC<AddCardProps> = ({
  listCards,
  cards,
  setSelectedIndex,
  setNewCardIndex,
}) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const createModalRef = useRef<HTMLDivElement | null>(null);
  const addPinModalRef = useRef<HTMLDivElement | null>(null);
  const createformikRef = useRef<FormikProps<any> | null>(null);
  const formikRef = useRef<FormikProps<any> | null>(null);
  let ele = useRef<PinInput | null>(null);
  const [cardType, setCardType] = useState(0);
  const [cardName, setCardName] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState<Accounts[]>([]);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const [createdCard, setCreatedCard] = useState<CardData>({
    cardType: 0,
    cardName: "",
    expiryDate: "",
    cardNumber: "",
    cvv: 0,
    trackingNumber: "",
    status: "",
    spendLimit: 0,
    transactionPIN: "",
    amount: 0.0,
  });

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) setAccounts(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const addCard = async () => {
    try {
      const url = `${API_URL}/card/createCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        cardType: +cardType,
        cardName: cardName,
        transactionPIN: pin,
        ...(cardType === 1 && { accountNumber: account }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setSelectedIndex(0);
        setNewCardIndex(0);
        handleCloseModal();
        setCreatedCard(data?.message);
        if (data?.message?.cardType === 1) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Your card has been generated",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
            customClass: {
              confirmButton: "btn-lg", // Adjust the size as needed
            },
          });
        }
        if (data?.message?.cardType === 2) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Card generated. Would you like to top-up your card",
            showCancelButton: true,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowEscapeKey: true,
            allowEnterKey: true,
          }).then((result) => {
            if (result.isConfirmed) handleAddMoney();
          });
        }
        listCards("");
      }

      if (data.status.statusCode === 1) {
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
        setError(data.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (createModalRef.current) {
      const modal = new Modal(createModalRef.current);

      createModalRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      createModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleCreateModalClose
      );
      return () => {
        createModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCreateModalClose
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (addPinModalRef.current) {
      const modal = new Modal(addPinModalRef.current);

      addPinModalRef?.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      addPinModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleCloseModal
      );
      return () => {
        addPinModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCreateModalClose = () => {
    createformikRef.current?.resetForm();
    const modalElement = createModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleConfirmClick = async () => {
    const modalElement = addPinModalRef.current;
    const modal = new Modal(modalElement as Element);
    modal.show();

    // Close the create_modal after opening the add_pin modal
    handleCreateModalClose();
  };

  const handleAddMoney = async () => {
    const modalElement = document.getElementById("addMoney_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleCloseModal = () => {
    setActiveInput(0);
    setPin("");
    setOtp(["", "", "", ""]);
    formikRef.current?.resetForm();
    const modalElement = addPinModalRef.current;
    if (modalElement) {
      ele.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  return (
    <>
      <div
        className="modal fade"
        tabIndex={-1}
        id="create_modal"
        ref={createModalRef}
          
      >
        <div className="modal-dialog modal-content">
          <div className="modal-header d-flex flex-row justify-content-between py-3">
            <h4 className="mb-0">Create New Card</h4>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
               <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
            </div>
          </div>

          <div
            className="modal-body mx-auto"
            style={{
              display: authority === 3 ? "flex" : "",
              minHeight: "300px",
              alignItems: authority === 3 ? "center" : "normal",
              justifyContent: authority === 3 ? "center" : "normal",
            }}
          >
            {authority === 3 ? (
              <div>
                <p className="fw-bold text-center text-danger">
                  You don't have access to this feature.
                </p>

                <p className="fw-bold text-center text-danger">
                  Please contact admin.
                </p>
              </div>
            ) : (
              <>
                <div
                  className="d-flex card shadow-sm p-10 mb-7"
                  style={{
                    height: "220px",
                    width: "380px",
                    backgroundImage:
                      cardType === 1 ? `url(${blueCard})` : `url(${blackCard})`,
                    backgroundSize: "auto",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="d-flex flex-row mb-15 justify-content-between align-items-center">
                    <div>
                      <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/logos/xfin Logo.png")}
                        className="h-35px app-sidebar-logo-default "
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-row gap-5 align-items-center">
                      <div>
                        <p className="text-white m-0">Card Holder Name</p>
                        <p className="text-white fs-3 m-0">Your Name</p>
                      </div>
                    </div>
                    <div>
                      <img
                        className="text-white"
                        src={masterCard}
                        style={{ width: "120px", height: "90px" }}
                      />
                    </div>
                  </div>
                </div>

                <Formik
                  innerRef={createformikRef}
                  initialValues={{
                    cardType: "",
                    cardName: "",
                    selectedAccount: "",
                  }}
                  validationSchema={Yup.object().shape({
                    cardType: Yup.string().required("Card Type is required"),
                    cardName: Yup.string()
                    .min(2, "Minimum 2 characters required")
                    .max(50, "Maximum 50 characters allowed")
                    .matches(/^(?=.*[a-zA-Z])[\sa-zA-Z0-9]*$/, 'Must contain at least one alphabetic character')
                    .matches(/^[\sa-zA-Z0-9]*$/, 'Only alphanumeric characters and spaces are allowed')
                    .test('no-only-spaces', 'Cannot contain only spaces', (value) => {
                      if (typeof value === 'string') {
                        return !/^\s+$/.test(value);
                      }
                      return false;
                    })
                    .trim().required("Card Name is required"),
                    selectedAccount: Yup.string().test(
                      "required",
                      "Select Account is required",
                      function (value) {
                        const cardType = this.parent.cardType;
                        if (cardType === "1") {
                          return !!value;
                        }
                        return true;
                      }
                    ),
                  })}
                  onSubmit={(values, actions) => {
                    actions.setFieldValue("cardType", values.cardType);
                    setCardType(+values.cardType);
                    setCardName(values.cardName);
                    if (values.selectedAccount)
                      setAccount(values.selectedAccount);

                    actions.resetForm();
                    handleCreateModalClose();
                    handleConfirmClick();
                  }}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <Form>
                      <div className="form-group mb-5">
                        <label className="form-label">Card Type</label>
                        <Field
                          as="select"
                          name="cardType"
                          className="form-select"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            resetForm();
                            setFieldValue("cardType", e.target.value); // Update the formik field value
                            setCardType(+e.target.value); // Update your component state if needed
                          }}
                          value={values.cardType} // Bind the value prop to Formik's field value
                        >
                          <option value="">Select</option>
                          <option value="1">Virtual Debit Card</option>
                          <option value="2">Virtual Prepaid Card</option>
                        </Field>
                        <ErrorMessage
                          name="cardType"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="form-group mb-5">
                        <label className="form-label">Enter Card Name</label>
                        <Field
                          type="text"
                          name="cardName"
                          className="form-control"
                          placeholder="Card Name"
                        />
                        <ErrorMessage
                          name="cardName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {cardType === 1 && (
                        <div className="form-group mb-5">
                          <label className="form-label">
                            Select Account to link
                          </label>
                          <Field
                            as="select"
                            name="selectedAccount"
                            className="form-select"
                          >
                            <option value="">Select an account</option>
                            {accounts
                              ?.filter((acc) => acc.currency === baseCurrency)
                              ?.map((acc) => (
                                <option
                                  key={acc.accountNumber}
                                  value={acc.accountNumber}
                                >
                                  {acc.accountNumber}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  ({acc.currency}{" "}
                                  {acc.balance?.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  )
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="selectedAccount"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}

                      <div className="d-flex justify-content-between mb-3">
                        <button
                          type="submit"
                          className="btn rounded"
                          style={{
                            width: "180px",
                          }}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "180px",
                            color: "#246bfb",
                          }}
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="add_pin"
        ref={addPinModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between align-items-center  ">
                <h1
                  className="text-center m-0"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    confirmPin: "",
                  }}
                  validationSchema={Yup.object({
                    confirmPin: Yup.string()
                      .matches(/^\d{4}$/, "PIN must be 4 digits")
                      .required("PIN is required"),
                  })}
                  onSubmit={(actions) => {
                    addCard();
                    // handleCloseModal();
                    ele.current?.clear();
                    actions.resetForm();
                  }}
                >
                  <Form>
                    <div className="mb-5 mt-5">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-center text-nowrap fs-5 mb-5">
                          Enter your PIN to confirm
                        </p>
                        <div className="d-flex justify-content-center">
                          <Field name="confirmPin">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      setError("");
                                      setPin(pin);
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      setError("");
                                      setPin(pin);
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </div>
                        <p
                          className="text-center cursor-pointer text-decoration-underline mb-0 mt-2"
                          onClick={() => setShow(!show)}
                        >
                          {show ? "Show PIN" : "Hide PIN"}
                        </p>
                        <div className="mt-3">
                          <ErrorMessage
                            name="confirmPin"
                            component="div"
                            className="text-danger text-center"
                          />
                          {error && (
                            <p className="text-danger text-center">{error}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                        <button
                          type="submit"
                          className="btn rounded me-5"
                          style={{
                            width: "140px",
                          }}
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "140px",
                            color: "#246bfb",
                          }}
                          onClick={() => handleCloseModal()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddMoney cardData={createdCard} listCards={listCards} />
    </>
  );
};
