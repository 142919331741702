import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import money from "../../../../assets/all-serviceIcons/arrow-down-arrow-up.svg";
import payroll from "../../../../assets/all-serviceIcons/calculator-simple.svg";
import invoice from "../../../../assets/all-serviceIcons/file-export-alt.svg";
import qr from "../../../../assets/all-serviceIcons/scan-qr.svg";
import paymentlink from "../../../../assets/all-serviceIcons/cursor-click.svg";
import support from "../../../../assets/all-serviceIcons/messages-dots.svg";
import team from "../../../../assets/all-serviceIcons/users-group-alt.svg";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const toggleRef = useRef<HTMLDivElement>(null);
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
    ? "minimize"
    : "";

  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
  const tooltipMoney = appSidebarDefaultMinimizeDesktopEnabled ? (
    <Tooltip id="tooltip-dashboard">Money Movement</Tooltip>
  ) : (
    <Tooltip id="tooltip-dashboard"></Tooltip>
  );
  const tooltipPayroll = <Tooltip id="tooltip-dashboard">Payroll</Tooltip>;
  const tooltipQr = <Tooltip id="tooltip-dashboard">QR</Tooltip>;
  const tooltipPaymentLink = (
    <Tooltip id="tooltip-dashboard">Payment Link</Tooltip>
  );
  const tooltipSupport = <Tooltip id="tooltip-dashboard">Support</Tooltip>;
  const tooltipInvoice = <Tooltip id="tooltip-dashboard">Invoice</Tooltip>;

  useEffect(() => {
    console.log(isActive);
  }, [isActive]);

  return (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span
            className="menu-icon"
            ref={toggleRef}
            id="kt_app_sidebar_toggle"
            data-kt-toggle="true"
            data-kt-toggle-state={toggleState}
            data-kt-toggle-target="body"
            data-kt-toggle-name={`app-sidebar-${toggleType}`}
          >
            {icon === "money" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={money}
                alt=""
              />
            )}

            {icon === "payroll" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={payroll}
                alt=""
              />
            )}
            {icon === "invoice" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={invoice}
                alt=""
              />
            )}
            {icon === "qr" && (
              <img style={{ height: "18px", width: "18px" }} src={qr} alt="" />
            )}
            {icon === "paymentlink" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={paymentlink}
                alt=""
              />
            )}
            {icon === "support" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={support}
                alt=""
              />
            )}
            {icon === "team" && (
              <img
                style={{ height: "18px", width: "18px", color: "black" }}
                src={team}
                alt=""
              />
            )}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
