import { useState, FC, useEffect, ChangeEvent, useRef } from "react";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import { AddCard } from "./AddCard";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import masterCard from "../../../_metronic/assets/Icons/mastercard-logo-transparent-png-stickpng-10.png";
import Download from "../../../_metronic/assets/all-serviceIcons/download.svg";
import blueCard from "../../../_metronic/assets/Icons/Blue_Plain.png";
import blackCard from "../../../_metronic/assets/Icons/Black_Plain.png";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ChangeLimit } from "./ChangeLimit";
import { Formik, Form, FormikProps, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import ReactCardFlip from "react-card-flip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { TransferMoney } from "./TransferMoney";
import { Accounts } from "../../interface/Interface";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Card } from "@mui/material";

import blockedImg from "../../../_metronic/assets/Icons/imgpsh_fullsize_anim (2).png";
import blockedImg2 from "../../../_metronic/assets/Icons/blocked2.png";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import { User } from "../TransferMoney/TransferModal";
import Authorize from "../../modules/auth/components/Authorize";

export interface Card {
  cardType: number;
  cardName: string;
  cardNumber: string;
  trackingNumber: string;
  status: string;
  spendLimit: number;
  amount: number;
  currency: string;
  balance: number;
}

export const card = {
  // data: [] as AccountStmnt[],
  cardType: "",
  cardNumber: "",
  stmntDuration: "",
  fromDate: "",
  toDate: "",
};

export interface Transaction {
  amount: number;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
}

export interface CardData {
  cardType: number;
  cardName: string;
  expiryDate: string;
  cardNumber: string;
  cvv: number;
  trackingNumber: string;
  balance?: number;
  spendLimit: number;
  transactionPIN: string;
  amount: number;
  status?: string;
}

interface FormValues {
  account: string;
  amount: string;
  approver: string;
}

const MyCard: FC = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const baseCurrency = sessionStorage.getItem("currency");
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Submit");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [approverName, setApprover] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [authority, setAuthority] = useState(0);

  const [hide, setHide] = useState(true);
  const [pin, setPin] = useState("");
  const [dateRange, setDateRange] = useState(false);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [account, setAccount] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [action, setAction] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [iconColour, setIconColour] = useState("btn-dark");
  const [balanceErr, setBalanceErr] = useState("");
  const [stmntDuration, setStmntDuration] = useState("4");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [newCardIndex, setNewCardIndex] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [cards, setCards] = useState<Card[]>([]);
  const [show, setShow] = useState(true);
  const [selectedCard, setSelectedCard] = useState<Card>({
    cardType: 0,
    cardName: "",
    cardNumber: "",
    trackingNumber: "",
    status: "",
    spendLimit: 0,
    amount: 0.0,
    currency: "",
    balance: 0,
  });
  const [cardDetails, setCardDetails] = useState<CardData>({
    cardType: 0,
    cardName: "",
    expiryDate: "",
    cardNumber: "",
    cvv: 0,
    trackingNumber: "",
    balance: 0,
    spendLimit: 0,
    transactionPIN: "",
    amount: 0,
    status: "",
  });
  const [blocked, setBlocked] = useState(selectedCard?.status);

  const tooltipFilter = <Tooltip id="tooltip-add-money">Filter</Tooltip>;
  const tooltipDownload = <Tooltip id="tooltip-add-money">Download</Tooltip>;

  const pinRef = useRef<HTMLDivElement | null>(null);
  const addMoneyModalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<any> | null>(null);
  const viewformikRef = useRef<FormikProps<any> | null>(null);
  const viewModalRef = useRef<HTMLDivElement | null>(null);
  const addformikRef = useRef<FormikProps<FormValues> | null>(null);
  const datemodalRef = useRef<HTMLDivElement | null>(null);
  let ele = useRef<PinInput | null>(null);
  let viewele = useRef<PinInput | null>(null);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const inputRefs2 = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
    inputRefs2.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const tooltipAddMoney = <Tooltip id="tooltip-add-money">Add Money</Tooltip>;
  const tooltipBlock = (
    <Tooltip id="tooltip-block">
      {blocked === "Blocked" || selectedCard?.status === "Blocked"
        ? "Unblock"
        : "Block"}
    </Tooltip>
  );
  const tooltipChangeLimit = (
    <Tooltip id="tooltip-change-limit">Change Limit</Tooltip>
  );
  const tooltipTransfer = <Tooltip id="tooltip-transfer">Transfer</Tooltip>;
  const tooltipClose = <Tooltip id="tooltip-closer">Close</Tooltip>;

  const listCards = async (action: string) => {
    try {
      const url = `${API_URL}/card/listCards`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        const nonClosedCards = data?.results?.filter(
          (card: Card) => card?.status !== "Closed"
        );
        const rearrangedArray =
          nonClosedCards && nonClosedCards.length > 0
            ? selectedIndex >= 0 && selectedIndex < nonClosedCards.length
              ? [
                  ...nonClosedCards.slice(0, selectedIndex), // Elements before selectedIndex
                  nonClosedCards[selectedIndex], // Element at selectedIndex
                  ...nonClosedCards.slice(selectedIndex + 1), // Elements after selectedIndex
                ]
              : nonClosedCards // If selectedIndex is out of bounds, return original array
            : [];

        if (action === "close") {
          if (rearrangedArray.length === 0) {
            setSelectedIndex(0);
          } else if (rearrangedArray.length === 1) {
            setSelectedIndex(0);
          } else if (selectedIndex === 0 && rearrangedArray.length > 1) {
            setSelectedIndex(selectedIndex + 1);
          } else if (selectedIndex === rearrangedArray.length - 1) {
            setSelectedIndex(selectedIndex - 1);
          } else {
            setSelectedIndex(selectedIndex - 1);
          }
        }

        setCards(rearrangedArray);
        setSelectedCard(rearrangedArray[selectedIndex]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) setAccounts(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ApproverList(userDet?.userDetails?.zarLimit);
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (+amount) {
        ApproverList(amount);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [amount]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  useEffect(() => {
    setSelectedCard(cards[selectedIndex]);
  }, [selectedIndex]);

  useEffect(() => {
    setSelectedIndex(0);
    getAccounts();
    listCards("");
  }, [newCardIndex]);

  const getCard = async (pin: string) => {
    try {
      const url = `${API_URL}/card/showCardDetail`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleViewCloseModal();
        setCardDetails(data?.message);
        setHide(!hide);
      }
      if (data.status.statusCode === 1) {
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
        viewformikRef?.current?.resetForm();
        viewele?.current?.clear();
        setError(data.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCardTxn = async () => {
    try {
      const url = `${API_URL}/card/getCardTxn`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard?.cardType,
        cardNumber: selectedCard?.cardNumber,
        stmntDuration: stmntDuration,
        fromDate: fromDate,
        toDate: toDate,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        // setHide(!hide);
        setTransactions(data?.message.transactions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCardStmnt = async (): Promise<any> => {
    try {
      const url = `${API_URL}/card/downloadCardStmnt`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard?.cardType,
        cardNumber: selectedCard?.cardNumber,
        stmntDuration: stmntDuration,
        fromDate: fromDate,
        toDate: toDate,
      });
      console.log(body);

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedIndex: number, e: any) => {
    setHide(true);

    // Ensure selectedIndex is within bounds
    if (selectedIndex >= 0 && selectedIndex < cards.length) {
      setSelectedIndex(selectedIndex);
      setSelectedCard(cards[selectedIndex]);
    }
  };

  useEffect(() => {
    if (selectedCard?.cardNumber) getCardTxn();
    setBlocked(selectedCard?.status);
  }, [selectedCard]);

  useEffect(() => {
    if (addMoneyModalRef.current) {
      const modal = new Modal(addMoneyModalRef.current);

      addMoneyModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleAddMoneyClose
      );
      return () => {
        addMoneyModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleAddMoneyClose
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (pinRef.current) {
      const modal = new Modal(pinRef.current);

      pinRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      pinRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        pinRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (viewModalRef.current) {
      const modal = new Modal(viewModalRef.current);

      viewModalRef.current.addEventListener("shown.bs.modal", () => {
        viewele?.current?.focus();

        inputRefs2.current[activeInput]?.focus();
      });

      viewModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleViewCloseModal
      );
      return () => {
        viewModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleViewCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCloseModal = () => {
    setPin("");
    setActiveInput(0);
    setOtp(["", "", "", ""]);
    formikRef.current?.resetForm();
    setError("");
    const modalElement = pinRef.current;
    if (modalElement) {
      setPin("");
      formikRef.current?.setFieldValue("confirmPin", "");
      ele.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleViewCloseModal = () => {
    setError("");
    setPin("");
    setActiveInput(0);
    setOtp(["", "", "", ""]);
    const modalElement = viewModalRef.current;
    if (modalElement) {
      viewformikRef?.current?.resetForm();
      viewele.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleAddMoneyClose = () => {
    setHide(true);
    addformikRef.current?.resetForm();
    setBalanceErr("");
    const modalElement = addMoneyModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleConfirmClick = async () => {
    const modalElement = pinRef.current;
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleAddClick = async () => {
    const modalElement = document.getElementById("create_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleTransferMoney = async () => {
    const modalElement = document.getElementById("transferMoney_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleAddMoney = async () => {
    const modalElement = document.getElementById("add_Money_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleViewClick = async (cardData: Card) => {
    setSelectedCard(cardData);
    const modalElement = document.getElementById("view_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleFreeze = () => {
    if (authority === 3) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
  
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Clarification",
        text: `Are you sure you want to ${
          blocked === "Blocked" ? "unblock" : "block"
        } the card?`,
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowEscapeKey: true,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) handleConfirmClick();
      });
    }
  };

  const handleClose = () => {
    if (authority === 3) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
  
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Clarification",
        text: "Are you sure you want to close your card? Note : Action once done cannot be undone.",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowEscapeKey: true,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) handleConfirmClick();
      });
    }
  };

  const handleCopyClick = () => {
    // Use the Clipboard API to copy the text
    navigator.clipboard
      .writeText(selectedCard?.cardNumber)
      .then(() => {
        alert("Text copied to clipboard");
      })
      .catch((err) => {
        alert("Failed to copy text: " + err);
      });
  };

  function maskCardNumber(cardNum: string): string {
    // Check for empty or undefined cardNum
    if (!cardNum) {
      return "";
    }

    // Remove spaces, then get the last four digits
    const cleanedCardNum = cardNum.replace(/\s+/g, "");
    const lastFourDigits = cleanedCardNum.slice(-4);

    // Construct the masked string with only two asterisks
    const masked = "** " + lastFourDigits;

    // Insert spaces to the masked string to maintain the format
    return masked;
  }

  const handleChangeLimit = () => {
    const modalElement = document.getElementById("limit_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const blockCard = async () => {
    try {
      const url = `${API_URL}/card/freezeCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        setHide(true);
        setBlocked(data.message.status);
        handleCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `Your card has been ${
            blocked === "Blocked" ? "unblocked" : "blocked"
          }`,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        listCards("");
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        formikRef.current?.resetForm();
        ele.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unBlockCard = async () => {
    try {
      const url = `${API_URL}/card/unFreezeCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        setBlocked(data.message.status);
        handleCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `Your card has been ${
            blocked === "Blocked" ? "unblocked" : "blocked"
          }`,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        listCards("");
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        formikRef.current?.resetForm();
        ele.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeCard = async () => {
    try {
      const url = `${API_URL}/card/retireCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: selectedCard?.cardType,
        cardNumber: selectedCard?.cardNumber,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        await listCards("close");
        setHide(true);
        // setTimeout(() => {
        //   console.log(cards.length);

        //   if (cards.length === 0) {
        //     setSelectedIndex(0);
        //   } else if (cards.length === 1) {
        //     setSelectedIndex(0);
        //   } else if (selectedIndex === 0 && cards.length > 1) {
        //     setSelectedIndex(selectedIndex + 1);
        //   } else if (selectedIndex === cards.length - 1) {
        //     setSelectedIndex(selectedIndex - 1);
        //   }
        // }, 2000);
        handleCloseModal();

        const msg =
          data?.message?.cardType === 2
            ? "Prepaid Card closed.The amount has been refunded to your attached account"
            : "Debit Card closed.";
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: msg,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        formikRef.current?.resetForm();
        ele.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const topUpCard = async () => {
    try {
      const url = `${API_URL}/card/topUpCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        cardType: selectedCard?.cardType,
        cardNumber: selectedCard?.cardNumber,
        accountNumber: account,
        topUpAmount: +amount,
        transactionPIN: pin,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: selectedCard.cardName,
          currency: currency,
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleCloseModal();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Pre-paid amount requested has been added",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }

        listCards("");
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        ele.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
      formikRef.current?.resetForm();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleGenerateClick = () => {
    // Assuming statementDuration is a state variable
    if (stmntDuration === "5") {
      // Check for fromDate and toDate conditions
      if (fromDate && toDate) {
        getCardTxn();
        handleDeleteCloseModal();
      } else {
        // Handle error or show a message if fromDate and toDate conditions are not met
        validateDates(fromDate, toDate);
        console.error("Invalid fromDate or toDate");
      }
    } else if (stmntDuration) {
      getCardTxn();
      handleDeleteCloseModal();
    } else {
      // Handle the case where statementDuration is falsy (undefined, null, etc.)
      console.error("Statement duration is not set");
    }
  };

  const handleDownloadRange = () => {
    const modalElement = document.getElementById("Date_Range");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    setStmntDuration("4");
    setDateRange(false);
    const modalElement = datemodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (datemodalRef.current) {
      const modal = new Modal(datemodalRef.current);
      datemodalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        datemodalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleFromDateChange = (date: string | Date | Dayjs | null) => {
    let formattedDate = "";
    if (date) {
      if (dayjs.isDayjs(date)) {
        formattedDate = date.format("YYYY-MM-DD");
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
      }
      setFromDate(formattedDate);
    } else {
      setFromDate("");
    }

    // If toDate is already set, validate the dates
    if (toDate) {
      validateDates(formattedDate, toDate);
    }
  };

  const handleToDateChange = (date: string | Date | Dayjs | null) => {
    let formattedDate = "";

    if (date) {
      if (dayjs.isDayjs(date)) {
        formattedDate = date.format("YYYY-MM-DD");
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
      }
      setToDate(formattedDate);
    } else {
      setToDate("");
    }

    // If fromDate is already set, validate the dates
    if (fromDate) {
      validateDates(fromDate, formattedDate);
    }
  };

  const validateDates = (startDate: string, endDate: string) => {
    if (stmntDuration === "5") {
      if (!startDate || !endDate) {
        setValidationError("Start date and End date should not be empty.");
        return false;
      } else if (startDate > endDate) {
        setValidationError("Start date should not be greater than End date.");
        return false;
      } else if (endDate > dayjs(new Date()).format("YYYY-MM-DD")) {
        setValidationError("End date should not be greater than Today.");
        return false;
      }
    }
    setValidationError(null);
    return true;
  };

  const handleDownload = async () => {
    try {
      const response = await downloadCardStmnt();

      // if (!response || !response.fileData || !response.fileName) {
      //   console.error("Invalid or missing data in the API response");
      //   return;
      // }
      console.log(response.message.fileData);

      // Convert base64 to binary
      const byteCharacters = atob(response?.message?.fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create Blob
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create download link
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response?.message?.fileName);

      // Append the link to the body and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
      setStmntDuration("4");
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = transactions?.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(transactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      <style>
        {`
           .custom-input::-webkit-outer-spin-button,
           .custom-input::-webkit-inner-spin-button {
             -webkit-appearance: none;
             margin: 0;
           }
    #shadow-hover {
      // transition: box-shadow 0.3s;
      box-shadow: 4px 4px 10px #d1d1d1;
    }

    #shadow-hover:hover {
      box-shadow: 2px 2px 5px #c7c7c5;
      background-color: #c4c4c0
    }
//     .carousel-control-prev-icon,

// .carousel-indicators {
//   display: none !important;
// }
.black-icon {
  color: black;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  margin-bottom: 60px; 
  filter: invert(100%);
  background-size: 20px 20px;
}
  `}
      </style>
      <h4 className="mb-4">Cards Dashboard</h4>
      <div className="py-2 card p-5">
        <div
          className={`d-flex justify-content-between gap-5 flex-nowrap mb-5
              } `}
          style={{
            paddingTop: "10px",
            // paddingLeft: "15px",
            // paddingRight: "15px",
          }}
        >
          <Carousel
            onSelect={handleSelect}
            activeIndex={selectedIndex}
            indicators={false}
            style={{ width: "890px", maxWidth: "890px" }}
            interval={null}
            className="card shadow-sm justify-content-center p-5"
          >
            {cards.length > 0 ? (
              cards.map((card, index) => (
                <Carousel.Item key={index} className="justify-content-center">
                  <div className="d-flex flex-column">
                    <ReactCardFlip
                      isFlipped={!hide}
                      flipDirection="horizontal"
                      flipSpeedFrontToBack={1}
                      containerStyle={{}}
                      containerClassName="m-auto justify-content-center"
                    >
                      <div
                        className={`d-flex flex-column gap-5 p-10 mb-5 ${
                          card?.status === "Blocked" ? "bg-gray-600" : ""
                        }`}
                        style={{
                          height: "220px",
                          width: "383.3px",
                          backgroundImage:
                            card?.cardType === 1 && card?.status === "Active"
                              ? `url(${blueCard})`
                              : card?.cardType === 2 &&
                                card?.status === "Active"
                              ? `url(${blackCard})`
                              : "none",
                          backgroundSize: "auto",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                          filter: `${
                            card?.status === "Blocked"
                              ? "grayscale(100%)"
                              : "none"
                          }`,
                          opacity: `${card?.status === "Blocked" ? "0.5" : ""}`,
                          position: "relative",
                        }}
                      >
                        <div className="d-flex flex-row mb-10 justify-content-between align-items-center mx-10">
                          <div>
                            <img
                              alt="Logo"
                              src={toAbsoluteUrl("/media/logos/xfin Logo.png")}
                              className="h-35px app-sidebar-logo-default "
                            />
                          </div>
                          <Authorize hasAnyPermission={["MENU_CARDS|VIEW"]}>
                            <i
                              className={`bi bi-eye${
                                hide ? "" : "-slash"
                              } fs-3 text-white cursor-pointer`}
                              onClick={() => {
                                handleViewClick(card);
                                // setHide(!hide);
                              }}
                            ></i>
                          </Authorize>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center mx-10">
                          <div className="d-flex flex-row gap-5 align-items-center">
                            <div>
                              <p className="text-white m-0">{card?.cardName}</p>
                              <p className="fs-3 text-white">
                                {maskCardNumber(card?.cardNumber)}
                              </p>
                            </div>
                          </div>
                          <div>
                            <img
                              className="text-white"
                              src={masterCard}
                              style={{ width: "90px", height: "70px" }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* {Card Back} */}
                      <div
                        className={`d-flex flex-column p-10 mb-5 ${
                          card?.status === "Blocked" ? "bg-gray-800" : ""
                        }`}
                        style={{
                          height: "220px",
                          width: "100%",
                          backgroundImage:
                            card?.cardType === 1 && card?.status === "Active"
                              ? `url(${blueCard})`
                              : card?.cardType === 2 &&
                                card?.status === "Active"
                              ? `url(${blackCard})`
                              : "none",
                          backgroundSize: "auto",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                          position: "relative",
                        }}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="d-flex flex-row align-items-end ">
                            <div>
                              <p className="text-white text-start m-0">
                                Card Name
                              </p>
                              <p className="fw-bold text-white fs-5">
                                {card?.cardName}
                              </p>
                            </div>
                          </div>
                          <i
                            className={`bi bi-eye${
                              hide ? "" : "-slash"
                            } fs-3 text-white me-10 mb-5 cursor-pointer`}
                            onClick={() => {
                              // getCard();
                              setHide(!hide);
                            }}
                          ></i>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div className="d-flex flex-row align-items-end ">
                            <div>
                              <p className="text-white text-start m-0">
                                Card Number
                              </p>
                              <p className="fw-bold text-white fs-5">
                                {card?.cardNumber}
                              </p>
                            </div>
                            {/* <i className="fa-light fa-copy"></i> */}
                            <FontAwesomeIcon
                              icon={faCopy}
                              onClick={handleCopyClick}
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              className="text-white fs-4 mb-7"
                            />
                          </div>
                          <div>
                            <p className="text-white m-0">Expiry Date</p>
                            <p className="fw-bold text-white fs-5">
                              {cardDetails?.expiryDate}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-20 align-items-center">
                          <div className="d-flex flex-row gap-12">
                            <div>
                              <p className="text-white m-0">CVV</p>
                              <p className="fw-bold text-white fs-5 ">
                                {cardDetails?.cvv}
                              </p>
                            </div>
                            <div>
                              <p className="text-white m-0">Card Limit</p>
                              <p className="fw-bold text-white fs-5 text-nowrap">
                                {baseCurrency}{" "}
                                {cardDetails?.spendLimit?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                            <div>
                              <p className="text-white m-0">Balance</p>
                              <p className="fw-bold text-white fs-5 text-nowrap">
                                {baseCurrency}{" "}
                                {cardDetails?.balance?.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ReactCardFlip>
                    {card?.status === "Blocked" && (
                      <div
                        className=" mb-0 bg-dark "
                        style={{
                          width: "383.3px",
                          position: "absolute",
                          bottom: "56px", // Adjust as needed to place the image correctly
                          // left: "0",
                          // right: "0",
                          borderRadius: "0 0 10px 10px",
                          display: "flex",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className="text-white"
                          src={blockedImg2}
                          style={{
                            width: "90px", // Adjust size as needed
                            height: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}

                    <div className="d-flex justify-content-center">
                      <div className="d-flex gap-7 jusftiy-content-center">
                        {card?.cardType === 2 && (
                          <Authorize hasAnyPermission={["MENU_CARDS|ADD"]}>
                            <div
                              style={{
                                opacity:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? 0.5
                                    : 1,
                                pointerEvents:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              {" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipAddMoney}
                              >
                                <button
                                  className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => {
                                    setAction("AddMoney");
                                    handleAddMoney();
                                  }}
                                  onMouseOver={() => {
                                    setIconColour("btn-light");
                                  }}
                                  onMouseOut={() => {
                                    setIconColour("btn-dark");
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1">
                                    <i className="bi bi-plus-lg text-light"></i>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </div>
                          </Authorize>
                        )}
                        <Authorize hasAnyPermission={["MENU_CARDS|EDIT"]}>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipBlock}
                          >
                            <button
                              className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                              style={{
                                height: "40px",
                                width: "40px",
                                outline: "none",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                              onClick={() => {
                                setAction("Block");
                                handleFreeze();
                              }}
                              // onMouseOver={() => {
                              //   setIconColour;
                              // }}
                            >
                              <span className="svg-icon svg-icon-1 black-icon">
                                <i className="bi bi-snow black-icon text-light"></i>
                              </span>
                            </button>
                          </OverlayTrigger>
                        </Authorize>
                        <Authorize hasAnyPermission={["MENU_CARDS|EDIT"]}>
                          <div
                            style={{
                              opacity:
                                blocked === "Blocked" ||
                                card?.status === "Blocked"
                                  ? 0.5
                                  : 1,
                              pointerEvents:
                                blocked === "Blocked" ||
                                card?.status === "Blocked"
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltipChangeLimit}
                            >
                              <button
                                className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  outline: "none",
                                  borderRadius: "5px",
                                  border: "0px",
                                }}
                                onClick={() => {
                                  handleChangeLimit();
                                }}
                              >
                                <span className="svg-icon svg-icon-1">
                                  <i className="bi bi-pencil-square black-icon text-light"></i>
                                </span>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </Authorize>

                        {card?.cardType === 2 && (
                          <Authorize hasAnyPermission={["MENU_CARDS|ADD"]}>
                            <div
                              style={{
                                opacity:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? 0.5
                                    : 1,
                                pointerEvents:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipTransfer}
                              >
                                <div
                                  className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                                  style={{ height: "40px", width: "40px" }}
                                  onClick={() => {
                                    handleTransferMoney();
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1 black-icon">
                                    <i className="bi bi-cash-coin black-icon text-light"></i>
                                  </span>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Authorize>
                        )}
                        <Authorize hasAnyPermission={["MENU_CARDS|DELETE"]}>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipClose}
                          >
                            <div
                              className="btn btn-icon bg-dark bg-hover-secondary text-hover-dark"
                              onClick={() => {
                                setAction("Close");
                                handleClose();
                              }}
                              style={{
                                height: "40px",
                                width: "40px",
                                opacity:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? 0.5
                                    : 1,
                                pointerEvents:
                                  blocked === "Blocked" ||
                                  card?.status === "Blocked"
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <span className="svg-icon svg-icon-1">
                                <i className="bi bi-x-circle black-icon text-light"></i>
                              </span>
                            </div>
                          </OverlayTrigger>
                        </Authorize>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))
            ) : (
              <p className="text-center fs-4">No cards available</p>
            )}
          </Carousel>

          <div
            className="card shadow-sm d-flex flex-grow-1 "
            style={{ width: "100%" }}
          >
            <div className="card-body p-5 flex-grow-1 p-0 m-0">
              <div className="d-flex justify-content-between mb-5 ms-3 flex-grow-1">
                <h4 className="p-0 m-0">Card Life Time</h4>
              </div>

              <div className="  w-100 d-flex flex-row gap-10">
                <div className=" card shadow-sm bg-info w-100  p-10 ">
                  <div className="d-flex justify-content-start">
                    <h1 className="text-light fs-2x fw-bolder">
                      {selectedCard?.spendLimit
                        ? selectedCard?.spendLimit.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}{" "}
                      <span className="fs-9">({baseCurrency})</span>
                      {/* {loans[selectedIndex]?.paidEmiAmount.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )} */}
                    </h1>
                    <br />
                    <br />
                  </div>
                  <div className="d-flex justify-content-start">
                    <p className="text-light fs-7 ">Spend Limit</p>
                  </div>
                </div>
                <div className=" card shadow-sm bg-primary w-100  p-10 ">
                  <div className="d-flex justify-content-start">
                    <h1 className="text-light fs-2x fw-bolder">
                      {selectedCard?.balance
                        ? selectedCard?.balance.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}{" "}
                      <span className="fs-9">({baseCurrency})</span>
                      {/* {loans[
                            selectedIndex
                          ]?.pendingEmiAmount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} */}
                    </h1>
                    <br />
                    <br />
                  </div>
                  <div className="d-flex justify-content-start">
                    <p className="text-light fs-7"> Card Balance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow-sm p-5">
          <div className="d-flex justify-content-between align-items-center mb-2 me-3">
            <h4 className="ms-2 mb-0">Recent Transactions</h4>
            <div className="d-flex align-items-center gap-10">
              <Authorize hasAnyPermission={["MENU_CARDS|ADD"]}>
                <button className="btn  fs-7 p-3 px-5" onClick={handleAddClick}>
                  <i className="bi bi-plus-lg"></i>
                  Create New Card
                </button>
              </Authorize>
              <div className="mt-1" onClick={() => handleDownloadRange()}>
                <OverlayTrigger placement="top" overlay={tooltipFilter}>
                  <i className="bi bi-funnel fs-2 cursor-pointer"></i>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger placement="top" overlay={tooltipDownload}>
                  <i
                    className="bi bi-download fs-2 me-2 cursor-pointer"
                    onClick={() => {
                      setStmntDuration("5");
                      handleDownload();
                    }}
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="card p-2">
            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6 ">
                  <th className="min-w-100px p-5">Transaction Name</th>
                  <th className="min-w-100px p-5">Transaction Date</th>
                  <th className="min-w-100px p-5">Transaction Time</th>
                  <th className="min-w-100px p-5 text-nowrap">Amount</th>
                </tr>
              </thead>

              <tbody>
                {currentData && currentData.length > 0 ? (
                  currentData?.map((txn, index) => (
                    <tr
                      key={index}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                        // target.style.transform = "translate3d(6px, -6px, 0)";
                        // target.style.boxShadow =
                        //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                        // target.style.transform = "none";
                        // target.style.boxShadow = "none";
                      }}
                    >
                      <td className="p-5 text-gray-600">{txn.txnTypeName}</td>
                      <td className="p-5 text-gray-600">
                        {txn.dateTime.toString().split("|")[0]}
                      </td>
                      <td className="p-5 text-gray-600">
                        {txn.dateTime.toString().split("|")[1]}
                      </td>
                      <td
                        className={`p-5 ${
                          txn.txnType.toString() == "DR"
                            ? "text-danger"
                            : "text-primary"
                        }`}
                      >
                        {txn.currency}{" "}
                        {txn.amount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        <i
                          className={`ms-2 bi bi-arrow-${
                            txn.txnType.toString() == "DR"
                              ? "up-square text-danger"
                              : "down-square text-primary"
                          }`}
                        ></i>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <div className="p-5">
              <ul className="pagination">
                <li
                  className={`page-item double-arrow ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={0}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(1)}
                  >
                    <i className="previous"></i>
                    <i className="previous"></i>
                  </a>
                </li>
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={1}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item next ${
                    currentPage ===
                    Math.ceil(transactions?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={2}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="next"></i>
                  </a>
                </li>
                <li
                  className={`page-item double-arrow ${
                    currentPage ===
                    Math.ceil(transactions?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={3}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() =>
                      handlePageChange(
                        Math.ceil(transactions?.length / itemsPerPage)
                      )
                    }
                  >
                    <i className="next"></i>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 d-flex align-items-center">
              <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                Total: {transactions?.length}&nbsp;&nbsp;
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ChangeLimit cardData={selectedCard} listCards={listCards} />
      <TransferMoney
        cardData={selectedCard}
        getCardTxn={getCardTxn}
        listCards={listCards}
      />
      <AddCard
        listCards={listCards}
        cards={cards}
        setSelectedIndex={setSelectedIndex}
        setNewCardIndex={setNewCardIndex}
      />

      <div
        className="modal"
        tabIndex={-1}
        id="add_Money_modal"
        ref={addMoneyModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-500px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between py-3">
              <h4 className="mb-0">Add Money</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div
              className="modal-body p-12"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "300px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <Formik
                  innerRef={addformikRef}
                  initialValues={{
                    account: "",
                    amount: "",
                    approver: "",
                  }}
                  validationSchema={Yup.object().shape({
                    account: Yup.string().required("Account is required"),
                    amount: Yup.number()
                      .required("Amount is required")
                      .min(0.01, "Amount must be greater than 0"),
                    approver: Yup.string().test(
                      "approver-required",
                      "Select an approver",
                      function (value) {
                        if (button === "Initiate") {
                          return !!value;
                        }
                        return true;
                      }
                    ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    setAccount(values.account);
                    const acc = accounts.find(
                      (acc) => acc.accountNumber === values.account
                    );
                    if (acc) setCurrency(acc.currency);
                    setAmount(values.amount);
                    setApproverGuid(values.approver);
                    const name = approvers.find(
                      (app) => app.userGuid === values.approver
                    );
                    if (name) setApprover(name.name);

                    if (!balanceErr) {
                      resetForm();
                      handleAddMoneyClose();
                      handleConfirmClick();
                    }
                  }}
                >
                  {(formik) => (
                    <Form>
                      <div className="form-group mb-5">
                        <label className="form-label">Select Account</label>
                        <Field
                          as="select"
                          name="account"
                          className={`form-select ${
                            formik.errors.account && formik.touched.account
                              ? "is-invalid"
                              : ""
                          }`}
                          data-control="select2"
                          placeholder="Select an option"
                          // style={{ padding: "6.5px" }}
                        >
                          <option value="" disabled>
                            Select an account
                          </option>
                          {accounts
                            ?.filter((acc) => acc.currency !== "USD")
                            .map((acc, key) => (
                              <option
                                key={acc.accountNumber}
                                value={acc.accountNumber}
                              >
                                {acc.accountNumber}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                ({acc.currency}{" "}
                                {acc.balance.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                )
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="account"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="form-group mb-5">
                        <label className="form-label">Enter Amount</label>
                        <div>
                          {" "}
                          <span
                            className="fs-6 fw-bold text-gray-700 ps-5"
                            style={{ position: "absolute", padding: "12px" }}
                          >
                            {baseCurrency}
                          </span>
                        </div>
                        <Field
                          type="text"
                          name="amount"
                          disabled={!formik?.values?.account}
                          className={`form-control custom-input ${
                            formik.errors.amount && formik.touched.amount
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ paddingLeft: "60px" }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // formik.handleChange(e);

                            // If the value is empty, set the previous value to an empty string
                            if (
                              e.target.value === "" ||
                              e.target.value === "0"
                            ) {
                              formik.setFieldValue("amount", "");
                              setAmount("");
                              return;
                            }

                            // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                            if (/^\d{1,12}(\.\d{0,2})?$/.test(e.target.value)) {
                              formik.setFieldValue("amount", e.target.value);
                              setAmount(e.target.value);
                            }

                            const enteredAmount = Number(e.target.value);

                            // Verify against the balance
                            const selectedAccount = accounts.find(
                              (acc) =>
                                acc.accountNumber === formik.values.account
                            );

                            if (
                              selectedAccount &&
                              enteredAmount > selectedAccount.balance
                            ) {
                              setBalanceErr("Amount exceeds available balance");
                              formik.setFieldValue("errorStatus", true); // Set errorStatus to true
                              formik.setFieldError(
                                "amount",
                                "Amount exceeds balance"
                              );
                            } else {
                              setBalanceErr("");
                              formik.setFieldValue("errorStatus", false); // Set errorStatus to false
                              formik.setFieldError("amount", ""); // Clear the error if condition is not met
                            }
                          }}
                        />
                        {balanceErr && ( // Use formik.status to conditionally render the error message
                          <div className="text-danger">{balanceErr}</div>
                        )}
                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {button === "Initiate" ? (
                        <div className="mb-5">
                          <label className="form-label" htmlFor="approver">
                            Select Approver
                          </label>
                          <Field name="approver">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <>
                                <select
                                  {...field}
                                  className="form-select"
                                  value={field.value}
                                  onChange={(e) => {
                                    form.setFieldValue(
                                      "approver",
                                      e.target.value
                                    );
                                  }}
                                  autoFocus={true}
                                >
                                  <option value="" disabled>
                                    Select Approver
                                  </option>
                                  {approvers.map((item, index) => (
                                    <option key={index} value={item?.userGuid}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                          </Field>

                          <ErrorMessage
                            component="div"
                            name="approver"
                            className="text-danger"
                          />
                        </div>
                      ) : null}

                      <div className="d-flex flex-row mt-5">
                        <button
                          type="submit"
                          className="btn rounded me-6"
                          style={{
                            width: "200px",
                          }}
                        >
                          Proceed
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "200px",
                            color: "#246bfb",
                          }}
                          onClick={() => handleAddMoneyClose()}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal" tabIndex={-1} id="add_pin" ref={pinRef}>
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between align-items-center ">
                <h1
                  className="text-center m-0"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {" "}
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    confirmPin: "",
                  }}
                  validationSchema={Yup.object({
                    confirmPin: Yup.string()
                      .matches(/^\d{4}$/, "PIN must be 4 digits")
                      .required("PIN is required"),
                  })}
                  onSubmit={(actions, { resetForm }) => {
                    // handleCloseModal();
                    if (action === "Close") closeCard();
                    if (action === "AddMoney") topUpCard();
                    if (action === "Block" && blocked === "Active") blockCard();
                    if (action === "Block" && blocked === "Blocked")
                      unBlockCard();
                    resetForm();
                    setPin("");
                  }}
                >
                  <Form>
                    <div className="mb-7 mt-5">
                      <div className="d-flex justify-content-center flex-column">
                        <p className="text-nowrap text-center fs-5 mb-5">
                          Enter your PIN to confirm
                        </p>
                        <div className="d-flex justify-content-center flex-column">
                          <Field name="confirmPin">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      setError("");
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      setError("");
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                          <p
                            className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                        </div>

                        <div className="mt-3">
                          <ErrorMessage
                            name="confirmPin"
                            component="div"
                            className="text-danger text-center"
                          />
                          {error && (
                            <p className="text-danger text-center">{error}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between mb-5 gap-5">
                      <div>
                        <button
                          type="submit"
                          className="btn rounded"
                          style={{
                            width: "120px",
                          }}
                        >
                          {button}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "120px",
                            color: "#246bfb",
                          }}
                          onClick={() => handleCloseModal()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="Date_Range"
        ref={datemodalRef}
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-10">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column gap-10 w-100">
                  <div className="d-flex flex-column gap-5 ">
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio1">
                        This Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio1"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("1");
                        }}
                        checked={stmntDuration === "1"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Last Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio2"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("2");
                        }}
                        checked={stmntDuration === "2"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Last Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio2"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("7");
                        }}
                        checked={stmntDuration === "7"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio3">
                        Last 3 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio3"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("3");
                        }}
                        checked={stmntDuration === "3"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio4">
                        Last 6 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio4"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("4");
                        }}
                        checked={stmntDuration === "4"}
                      />
                    </div>
                    <div
                      className={`form-check d-flex justify-content-between ${
                        dateRange ? "mb-0" : "mb-2"
                      } p-4 rounded shadow-sm`}
                    >
                      <label className="fw-bold" htmlFor="Radio5">
                        Date Range
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio5"
                        onChange={() => {
                          setDateRange(true);
                          setStmntDuration("5");
                        }}
                        checked={stmntDuration === "5"}
                      />
                    </div>

                    {dateRange && (
                      <>
                        {/* <li className='list-group-item'> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Start Date"
                              disableFuture
                              slotProps={{
                                textField: {
                                  className: "mb-2",
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={fromDate ? dayjs(fromDate) : null}
                              onChange={(date) => {
                                handleFromDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              disableFuture
                              slotProps={{
                                textField: {
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={toDate ? dayjs(toDate) : null}
                              onChange={(date) => {
                                handleToDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* </li> */}
                        {validationError && (
                          <p className="text-danger">{validationError}</p>
                        )}
                      </>
                    )}
                    {/* </ul> */}
                  </div>
                </div>

                <div className="d-flex flex-row gap-5 mt-5">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      if (stmntDuration) handleGenerateClick();
                    }}
                  >
                    View
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handleDeleteCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" tabIndex={-1} id="view_modal" ref={viewModalRef}>
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between py-3">
              <label className="form-label fs-3 m-0">Enter Your PIN</label>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div
              className="modal-body"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "200px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <p className="text-nowrap text-center">
                      Enter your PIN to confirm
                    </p>
                    <div className="d-flex flex-no-wrap mb-5">
                      <Formik
                        innerRef={viewformikRef}
                        initialValues={{
                          transactionPIN: "",
                        }}
                        validationSchema={Yup.object({
                          transactionPIN: Yup.string()
                            .matches(/^\d{4}$/, "Txn pin must be 4 digits")
                            .required("Txn pin is required"),
                        })}
                        onSubmit={({ transactionPIN }, actions) => {
                          setError("");
                          getCard(transactionPIN);
                        }}
                      >
                        <Form>
                          <div className="mb-7 mt-5">
                            <div className="d-flex justify-content-center">
                              <Field name="transactionPIN">
                                {({
                                  field,
                                  form,
                                }: {
                                  field: any;
                                  form: FormikProps<any>;
                                }) => (
                                  <OTPInput
                                    {...field}
                                    value={otp.join("")}
                                    onChange={(value: string) => {
                                      value
                                        .split("")
                                        .forEach((char, index) =>
                                          handleChange(char, index)
                                        );
                                    }}
                                    numInputs={4}
                                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                                    renderInput={(props, index) => (
                                      <input
                                        {...props}
                                        value={otp[index]}
                                        ref={(el) =>
                                          (inputRefs2.current[index] = el)
                                        }
                                        onChange={(e) => {
                                          const pin = handleChange(
                                            e.target.value,
                                            index
                                          );
                                          setError("");
                                          form.setFieldValue(
                                            "transactionPIN",
                                            pin
                                          );
                                        }}
                                        onKeyDown={(e) => {
                                          const pin = handleKeyDown(e, index);
                                          setError("");
                                          form.setFieldValue(
                                            "transactionPIN",
                                            pin
                                          );
                                        }}
                                        type={show ? "password" : "text"}
                                        autoFocus={true}
                                        disabled={index !== activeInput}
                                        onFocus={() => setActiveInput(index)}
                                        className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </Field>
                            </div>
                            <p
                              className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                              onClick={() => setShow(!show)}
                            >
                              {show ? "Show PIN" : "Hide PIN"}
                            </p>
                            <div className="mt-3">
                              <ErrorMessage
                                name="transactionPIN"
                                component="div"
                                className="text-danger text-center"
                              />
                              {error && (
                                <p className="text-danger text-center">
                                  {error}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center justify-content-between gap-5">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary rounded"
                                style={{
                                  width: "120px",
                                }}
                              >
                                Confirm
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary rounded"
                                style={{
                                  width: "120px",
                                  color: "#246bfb",
                                }}
                                onClick={handleViewCloseModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyCard;
