import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import Play from "../../../_metronic/assets/all-serviceIcons/play-button.png";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { OverlayTrigger, Popover } from "react-bootstrap";
import VideoComponent from "./VideoComponent";

export const ApiData = {
  api: (): void => {},
};

export interface Video {
  videoGuid: string;
  categoryName: string;
  videoName: string;
  videoDesc: string;
  videoUrl: string;
  createdDate: string;
  viewCount: number;
}

export interface Category {
  categoryName: string;
  categoryNameOld: string;
  videos: Video[];
}

const VideoLibrary = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [url, setUrl] = useState("");
  const [videos, setVideos] = useState<Category[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState(videos);
  const imageModalRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const navigate = useNavigate();

  function handleModalClose() {
    clearVideoSrc();
    const modalElement = imageModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  }

  function clearVideoSrc() {
    setUrl("");
    if (videoRef.current) {
      videoRef.current.src = "";
    }
  }

  const handleViewClick = async (url: string) => {
    clearVideoSrc(); // Clear the current video source
    setTimeout(() => {
      setUrl(url);
      if (videoRef.current) {
        videoRef.current.src =
          url.includes("youtube.com") || url.includes("youtu.be")
            ? url.replace("/watch?v=", "/embed/") + "?autoplay=1"
            : url + "?autoplay=1";
      }
      const modalElement = document.getElementById("image_modal");
      const modal = new Modal(modalElement as Element);
      modal.show();
    }, 0); // Set a timeout of 0 to ensure the iframe src is cleared before setting the new URL
  };

  const getVideos = async () => {
    try {
      const url = `${API_URL}/videoLib/listVideoByCategory`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) setVideos(data.message.categoryList);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const videoCount = async (videoGuid: string) => {
    try {
      const url = `${API_URL}/videoLib/incrementVideoViewCount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        videoGuid: videoGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      console.log(data.status.statusCode);
      //   if (data.status.statusCode === 0) console.log(data.status.statusCode);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <h4 className="mb-4">Video Library</h4>

      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row justify-content-end align-items-center mt-3 me-5">
            <div className="d-flex align-items-center gap-10">
              <div className="d-flex align-items-center  position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="text"
                  data-kt-docs-table-filter="search"
                  className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                  placeholder="Search video titles"
                />
              </div>
            </div>
          </div>
          <>
            {videos?.length > 0 ? (
              videos
                .filter((category) => {
                  return (
                    category.videos.some((video) =>
                      video.videoName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ) && category.videos.length > 0
                  );
                })
                .map((video, index) => (
                  <div
                    className="icon card d-flex flex-column m-5 shadow-sm p-5"
                    key={index}
                  >
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <p className="fs-3 fw-bold">{video?.categoryName}</p>
                      </div>
                      {video?.videos?.length > 4 ? (
                        <div className="d-flex align-items-center">
                          <p
                            className="text-primary cursor-pointer"
                            onClick={() => {
                              navigate("/helpdesk/video-listing", {
                                state: { responseData: video },
                              });
                            }}
                          >
                            Show All
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* Display the first row with the first 4 favourites */}
                    <div className="d-flex flex-row justify-content-start mb-8">
                      {video?.videos.filter((video) =>
                        video.videoName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )?.length > 0 ? (
                        video?.videos
                          .filter((video) =>
                            video.videoName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          ?.slice(0, 4)
                          .map((video, index) => (
                            <VideoComponent
                              key={index}
                              video={video}
                              handleViewClick={handleViewClick}
                              videoCount={videoCount}
                            />
                          ))
                      ) : (
                        <p className="text-center">No Data Found</p>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <p className="text-center p-5 m-0">No Data Found</p>
            )}
          </>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="image_modal"
          
        style={{ backdropFilter: " brightness(20%)" }}
        ref={imageModalRef}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered ">
          <div
            className="modal-content p-5"
            style={{
              width: "auto",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="d-flex flex-row justify-content-between">
              <label className="form-label fs-3">Video Player</label>
              <div
                className="btn btn-icon btn-sm btn-active-light-dark ms-2 "
                data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={handleModalClose}
                style={{ borderRadius: "50%" }}
              >
                <span className="fs-2x mb-1 m-0 p-0">&times;</span>
              </div>
            </div>
            <div className="modal-body p-2">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <iframe
                  id="videoPlayer"
                  width="1080"
                  height="560"
                  src={
                    url.includes("youtube.com") || url.includes("youtu.be")
                      ? url.replace("/watch?v=", "/embed/") + "?autoplay=1"
                      : url + "?autoplay=1"
                  }
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="no-referrer"
                  loading="lazy"
                  ref={videoRef}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoLibrary;
