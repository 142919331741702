import { useState } from "react";
import { json } from "stream/consumers";
import { taxResults } from "./Data";

export default function TaxCalculator() {
  const baseCurrency = sessionStorage.getItem("currency");
  console.log(taxResults);

  return (
    <>
      <h4></h4>
      <div className="card shadow p-10 mt-5">
        <h5>Earnings & Deductions</h5>
        <div className="p-5">
          <div className="d-flex gap-20 mt-5">
            <div className="mb-5">
              <label className="form-label">Basic Monthly Salary</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.basicMonthlySalary}
              />
              <span
                className="fs-6 fw-bold text-gray-700"
                style={{
                  position: "absolute",

                  marginTop: "-32.5px",
                  marginLeft: "10px",
                }}
              >
                {baseCurrency}
              </span>
            </div>
            <div className="mb-5">
              <label className="form-label">Pension Deduction</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.basicPensionDeduction}
              />
              <span
                className="fs-6 fw-bold text-gray-700"
                style={{
                  position: "absolute",

                  marginTop: "-32.5px",
                  marginLeft: "10px",
                }}
              >
                {baseCurrency}
              </span>
            </div>
          </div>
          <div className="d-flex gap-20 mt-5">
            <div className="mb-5">
              <label className="form-label">Travel Allowance</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.travelAllowance}
              />
              <span
                className="fs-6 fw-bold text-gray-700"
                style={{
                  position: "absolute",

                  marginTop: "-32.5px",
                  marginLeft: "10px",
                }}
              >
                {baseCurrency}
              </span>
            </div>
            <div className="mb-5">
              <label className="form-label">Medical Dependents</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.medicalDependants}
              />
            </div>
          </div>
          <div className="d-flex gap-20 mt-5">
            <div className="mb-5">
              <label className="form-label">Other Allowances</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.otherAllowances}
              />
              <span
                className="fs-6 fw-bold text-gray-700"
                style={{
                  position: "absolute",

                  marginTop: "-32.5px",
                  marginLeft: "10px",
                }}
              >
                {baseCurrency}
              </span>
            </div>
            <div className="mb-5">
              <label className="form-label">Your Age</label>
              <input
                type="text"
                className="form-control text-end"
                // value={TaxData.age}
              />
            </div>
          </div>
          <div className="d-flex  mt-5">
            <div className="mb-5">
              <label className="form-label">Annual Bonus</label>
              <input
                type="text"
                className="form-control text-end"
                value={taxResults.annualBonus}
              />
              <span
                className="fs-6 fw-bold text-gray-700"
                style={{
                  position: "absolute",

                  marginTop: "-32.5px",
                  marginLeft: "10px",
                }}
              >
                {baseCurrency}
              </span>
            </div>
          </div>
        </div>
        <h5>View your annual income tax calculation results</h5>
        <div className="p-5">
          <div className="d-flex ">
            <div className="w-50">
              <p>Taxable Earnings</p>
            </div>
            <div>
              <p>{baseCurrency}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Basic Salary</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.basicSalary}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Travel Allowance (only 80%)</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.travelAllowance}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p> Other Allowances</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.otherAllowances}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Annual Bonus</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.bonus}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Total Taxable Earnings</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.totalTaxableIncome}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Less Taxable Pension</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.pension}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Taxable Income</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.taxableIncome}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Taxable Taxation</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.totalTaxableIncome}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Less Rebate</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.taxRebate}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Less: Medical Tax Credits</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.medicalTaxCredits}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Annual Tax Payable</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.annualTaxPayable}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Income Tax Percentage</p>
            </div>
            <div>
              <p>0.0%</p>
            </div>
          </div>
        </div>
        <h4> View your monthly salary calculation results</h4>
        <div className="p-5">
          <div className="d-flex ">
            <div className="w-50">
              <p>Earnings</p>
            </div>
            <div>
              <p>{baseCurrency}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Basic Salary</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.basicSalaryMonthly}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Travel Allowance</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.basicSalaryMonthly}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Other Allowance</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.otherAllowancesMonthly}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Gross Pay</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.grossPayMonthly}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Deductions</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.totalDeductionsMonthly}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Income Tax (PAYE)</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.incomeTaxMonthly}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Unemployment Insurance (UIF)</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.uifMonthly}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Pension Fund</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.pensionMonthly}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Total Deductions</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.totalDeductionsMonthly}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Net Monthly Pay</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.netPayMonthly}</p>
            </div>
          </div>
        </div>
        <h4> View your annual bonus payment</h4>
        <div className="p-5">
          <div className="d-flex ">
            <div className="w-50">
              <p>Annual Bonus</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.bonus}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Less Tax On Bonus</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.bonus}</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50">
              <p>Less UIF On Bonus</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.uifMonthly}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex ">
            <div className="w-50">
              <p>Net Annual Bonus</p>
            </div>
            <div>
              <p>{taxResults.incomeSummary.bonus}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
