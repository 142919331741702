import { ChangeEvent, FC, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import download from "../../../_metronic/assets/all-serviceIcons/file-download.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { AlignCenter } from "react-bootstrap-icons";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import { InvoiceDetailsModal } from "../Invoice/InvoiceDetails";

const fileTypes = ["xlsx"];

interface Data {
  customerName: string;
  invoiceNumber: string;
  invoiceDate: string;
  subTotal: number;
  total: number;
  invoiceGuid: string;
  invStatus: number;
  customerEmailId: string;
}

interface TableProps {
  isActive: number;
  setNumOfInvoice: (num: number) => void;
  setEmpStatus: (status: string) => void;
  setActive: (num: number) => void;
}

export const ApiData = {
  api: (): void => {},
};

export const TableComponent: FC<TableProps> = ({
  isActive,
  setNumOfInvoice,
  setEmpStatus,
  setActive,
}) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const [tabledata, setTableData] = useState<Data[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>(tabledata);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedData, setSelectedData] = useState<Data | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [payAmount, setPayAmount] = useState(0);
  const [previousValue, setPreviousValue] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [add, setAdd] = useState(false);

  const tooltipresend = <Tooltip id="tooltip-add-money">Download</Tooltip>;
  const tooltipcancell = <Tooltip id="tooltip-add-money">Cancel</Tooltip>;

  const navigate = useNavigate();
  let editEmail = "";
  console.log("Table");

  const handleCancelClick = async (index: number) => {
    try {
      const response = await Swal.fire({
        icon: "warning",
        title: "Confirmation",
        text: "Are you sure you want to cancel this invoice?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
      });

      if (response.isConfirmed) {
        const clickedData = filteredData[index].invoiceGuid;
        const url = `${API_URL}/invoice/cancel`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          invoiceGuid: clickedData,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        const apiResponse = await enhancedFetch(url, options);
        const data = await apiResponse.json();

        if (data.status.statusCode === 0) {
          getInvoice();
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: `Invoice Cancelled`,
            confirmButtonColor: "#007bff",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendClick = async (index: number) => {
    try {
      const response = await Swal.fire({
        icon: "info",
        title: "Confirmation",
        input: "email",
        inputLabel: `Your Invoice with number ${filteredData[index].invoiceNumber} will be resent to`,
        inputValue: `${filteredData[index].customerEmailId || ""}`,
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Resend Invoice",
        cancelButtonText: "Cancel",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        preConfirm: () => {
          const emailInputValue = (
            Swal?.getPopup()?.querySelector("#emailInput") as HTMLInputElement
          )?.value;
          return emailInputValue;
        },
      });

      if (response.isConfirmed) {
        console.log("hi");

        editEmail = response?.value;
        const clickedData = filteredData[index].invoiceGuid;
        const url = `${API_URL}/invoice/sendInvoice`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          invoiceGuid: clickedData,
          customerEmailId: response?.value,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        const apiResponse = await enhancedFetch(url, options);
        const data = await apiResponse.json();

        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: `Your Invoice with number ${filteredData[index].invoiceNumber} has been resent to ${editEmail}`,
            confirmButtonColor: "#007bff",
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              getInvoice();
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0 && data?.results?.length > 0) {
        setTableData(data?.results);
        setNumOfInvoice(data?.results?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  ApiData.api = getInvoice;

  const getStatusClass = (paymentStatus: string): string => {
    switch (paymentStatus) {
      case "Paid":
        return "text-success";
      case "Issued":
        return "text-warning";
      case "Cancelled":
        return "text-danger";
      default:
        return "";
    }
  };

  const handleAdd = () => {
    const modalElement = document.getElementById("kt_modal_add_manual");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleDetailsClick = (index: number) => {
    const modalElement = document.getElementById("details_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    const clickedData: Data = filteredData[index];
    setSelectedData(() => clickedData);
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      setPreviousValue("");
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      // Assuming `updateData` is your function to update the state
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = tabledata?.filter((data) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Paid":
          isTabMatched = data.invStatus === 2;
          break;
        case "Unpaid":
          isTabMatched = data.invStatus === 1;
          break;
        case "Cancelled":
          isTabMatched = data.invStatus === 4;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const searchValue = searchTerm?.toLowerCase();
      const isSearchMatched =
        data.customerName.toLowerCase().includes(searchValue) ||
        data.invoiceDate.toLowerCase().includes(searchValue) ||
        data.subTotal.toString().toLowerCase().includes(searchValue) ||
        data.invoiceNumber.toLowerCase().includes(searchValue);

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered data
    setFilteredData(filteredData);
  }, [searchTerm, tabledata, selectedTab]);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredData(tabledata);
    } else if (tab === "Paid") {
      const filteredData = tabledata.filter((data) =>
        tab === "Paid" ? data.invStatus === 2 : ""
      );
      setFilteredData(filteredData);
    } else if (tab === "Unpaid") {
      const filteredData = tabledata.filter((data) =>
        tab === "Unpaid" ? data.invStatus === 1 : ""
      );
      setFilteredData(filteredData);
    } else if (tab === "Cancelled") {
      const filteredData = tabledata.filter((data) =>
        tab === "Cancelled" ? data.invStatus === 4 : ""
      );
      setFilteredData(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredData?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const formik = useFormik({
    initialValues: {
      invoice_date: "",
      inv_num: "",
      vendor_name: "",
      amount: "",
      due_date: "",
    },
    validationSchema: Yup.object().shape({
      invoice_date: Yup.string().required("Invoice date is required"),
      inv_num: Yup.string().required("Invoice number is required"),
      vendor_name: Yup.string()
        .max(50, "Maximum 50 characters allowed")
        .required("Vendor name is required"),
      amount: Yup.string()
        .required("Invoice amount is required")
        .test(
          "zar-limit-validation",
          "amount should be greater than 0",
          function (value) {
            const { limitType } = this.parent;

            if (value) {
              return +value > 0;
            }

            return true;
          }
        ),
      due_date: Yup.string().required("Due date is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      // const data = {
      //   firstName: values?.user_name,
      //   email: values?.user_email,
      //   roleId: values.user_role,
      //   authority: values.user_mode,
      //   limitType: values.limitType,
      //   ...(values.limitType === "2" && {
      //     zarLimit: values.zarLimit,
      //     usdLimit: values.usdLimit,
      //   }),
      // };

      try {
        setSubmitting(true);
        // addUser(data);
        // formik.resetForm();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      // setRole("");
      // setAuthority([]);
      // setShowRadio(false);
    },
  });

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    amount: number
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setPayAmount((prev) => prev + amount);
      //   setHolderGuid((prev) => [...prev, holderGuid]);
    } else {
      setPayAmount((prev) => prev - amount);
      //   setHolderGuid((prev) => prev?.filter((guid) => guid !== holderGuid));
    }
  };

  const tooltipmsg = (
    <Tooltip id="tooltip-count">Please select invoice to pay</Tooltip>
  );

  const handleFileRemove = (fileToRemove: any) => {
    setFile(null);
    // updateData({
    //   fileData: {
    //     fileData: "",
    //   },
    // });
    // dataOutcome.fileExist = false;
  };

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("bulkFile", uploadedFile);
      formData.append("channel", "WEB");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };
      console.log(requestOptions);

      const url = `${API_URL}/bulk/uploadAndValidateQRCodeFile`;
      // Send the POST request
      // fetch(url, requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     if (result?.status?.statusCode === 0) {
      //       console.log(result.message);

      //       // setValid(true);
      //       // setDataList(result?.message.validationMsgList);
      //       // dataOutcome.fileUploadId = result?.message?.fileUploadId;
      //       // dataOutcome.fileExist = true;
      //     } else {
      //       Swal.fire({
      //         icon: "warning",
      //         title: "Failed",
      //         text: result?.status?.messageDescription,
      //       });
      //     }
      //   })
      //   .catch((error) => console.error("error", error));
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <>
      <style>{`
    .dashed {
  border-bottom: 2px dashed #70706e;
  text-align: center;
  height: 10px;
  margin-bottom: 10px;
}

.dashed span {
  background: #fff;
  padding: 0 5px;
}
    `}</style>
      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className="nav-link active text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Paid")}
                >
                  Paid
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Unpaid")}
                >
                  Pending
                </a>
              </li>
              {/* <li className="nav-item" key={4}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => handleTabClick("Cancelled")}
                >
                  Cancelled
                </a>
              </li> */}
            </ul>
          </div>

          <div className="d-flex flex-1 align-items-center p-3 gap-10">
            <div className="d-flex align-items-center  position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                placeholder="Search invoice"
                defaultValue={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            {payAmount < 1 ? (
              <Authorize hasAnyPermission={["MENU_INVOICE|ADD"]}>
                <OverlayTrigger placement="top" overlay={tooltipmsg}>
                  <button
                    className={`btn btn-${
                      payAmount < 1 ? "secondary" : "primary"
                    } fs-7 p-3`}
                    // onClick={() => navigate("/invoice/create-invoice")}
                    // disabled={payAmount < 1}
                  >
                    Pay{" "}
                    {payAmount < 1 ? "Invoice" : `${baseCurrency} ${payAmount}`}
                  </button>
                </OverlayTrigger>
              </Authorize>
            ) : (
              <Authorize hasAnyPermission={["MENU_INVOICE|ADD"]}>
                <button
                  className={`btn btn-${
                    payAmount < 1 ? "secondary" : "primary"
                  } fs-7 p-3`}
                  // onClick={() => navigate("/invoice/create-invoice")}
                  disabled={payAmount < 1}
                >
                  Pay{" "}
                  {payAmount < 1
                    ? "Invoice"
                    : `${baseCurrency} ${payAmount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                </button>
              </Authorize>
            )}

            <Authorize hasAnyPermission={["MENU_INVOICE|ADD"]}>
              <button
                className="btn  fs-7 p-3"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                Add Invoice
              </button>
            </Authorize>

            <div
              className="menu menu-sub menu-sub-dropdown w-200px p-5"
              data-kt-menu="true"
              id="filter_dropdown"
            >
              <div className="mb-3">
                <label
                  className="form-label fs-6 fw-bold mx-3 text-hover-primary"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    setAdd(false);
                    handleAdd();
                  }}
                >
                  Add Manually
                </label>
              </div>
              <div className="mb-3">
                <label
                  className="form-label fs-6 fw-bold mx-3 text-hover-primary"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    setAdd(true);
                    handleAdd();
                  }}
                >
                  Drag and Drop Files
                </label>
              </div>
              <div className="">
                <label
                  className="form-label fs-6 fw-bold mx-3 text-hover-primary"
                  data-kt-menu-dismiss="true"
                >
                  Sync with accounting tool
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-content"
          id="myTabContent"
          style={{
            height: "440px",
            overflowY: "scroll",
          }}
        >
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Invoice Date</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Vendor Name</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5">Due Date</th>
                      <th className="min-w-100px p-5 text-nowrap">Status</th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          // onClick={() => handleDetailsClick(index)}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                        >
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {new Date(item.invoiceDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td
                            className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                            onClick={() => handleDetailsClick(index)}
                          >
                            {item.invoiceNumber}
                          </td>
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {item.customerName}
                          </td>

                          <td className="p-5 text-gray-600">
                            {baseCurrency}{" "}
                            {item.total.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {new Date(item.invoiceDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td
                            className={`p-5 ${
                              item.invStatus === 1
                                ? "text-dark"
                                : item.invStatus === 2
                                ? "text-success"
                                : "text-success"
                            }`}
                          >
                            {item.invStatus === 1 ? (
                              <label className="form-check form-check-custom form-check-sm fs-7">
                                <input
                                  className="form-check-input cursor-pointer border border-dark me-2"
                                  type="checkbox"
                                  // checked={holderGuid.includes(item.txnHolderId)}
                                  onClick={(e) =>
                                    handleCheckClick(e, item.total)
                                  }
                                />{" "}
                                {"  "}Select to pay
                              </label>
                            ) : item.invStatus === 2 ? (
                              "paid"
                            ) : (
                              "paid"
                            )}
                          </td>
                          <td className="p-5 text-gray-600 p-2">
                            {item.invStatus === 1 && (
                              <div className="d-flex gap-10 align-items-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipresend}
                                >
                                  {/* <i
                                    className="bi bi-file-earmark-arrow-down fs-3 cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                    onClick={() => handleResendClick(index)}
                                  ></i> */}
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "22px",
                                      mixBlendMode: "multiply",
                                    }}
                                    src={download}
                                    alt=""
                                  />
                                </OverlayTrigger>
                                <Authorize
                                  hasAnyPermission={["MENU_INVOICE|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipcancell}
                                  >
                                    <i
                                      className="bi bi-x-circle fs-3 cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => {
                                        handleCancelClick(index);
                                      }}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            )}

                            {item.invStatus === 2 && (
                              <div className="d-flex gap-10">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipresend}
                                >
                                  {/* <i
                                    className="bi bi-file-earmark-arrow-down fs-3 cursor-pointer text-dark"
                                    // style={{ color: "#0000ff" }}
                                    onClick={() => handleResendClick(index)}
                                  ></i> */}
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "22px",
                                      mixBlendMode: "multiply",
                                    }}
                                    src={download}
                                    alt=""
                                  />
                                </OverlayTrigger>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 2) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-success">paid</td>
                              <td className="p-5 text-gray-600 p-2">
                                <div className="d-flex gap-10">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipresend}
                                  >
                                    <i
                                      className="bi bi-file-earmark-arrow-up fs-3 cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleResendClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                  {/* <i
                                  className="bi bi-x-circle fs-3"
                                  style={{ color: "#FF0000" }}
                                  onClick={() => {
                                    handleCancelClick(index);
                                  }}
                                ></i> */}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 1) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-primary">unpaid</td>
                              <td className="p-5 text-gray-600 p-2">
                                <div className="d-flex gap-10">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipresend}
                                  >
                                    <i
                                      className="bi bi-send fs-3 cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleResendClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                  <Authorize
                                    hasAnyPermission={["MENU_INVOICE|DELETE"]}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={tooltipcancell}
                                    >
                                      <i
                                        className="bi bi-x-circle fs-3 cursor-pointer"
                                        style={{ color: "#FF0000" }}
                                        onClick={() => {
                                          handleCancelClick(index);
                                        }}
                                      ></i>
                                    </OverlayTrigger>
                                  </Authorize>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 4) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-danger">cancelled</td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(filteredData?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(filteredData?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(
                      Math.ceil(filteredData?.length / itemsPerPage)
                    )
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {filteredData?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <InvoiceDetailsModal data={selectedData} />

      <div
        className="modal fade"
        id="kt_modal_add_manual"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_manual_header">
              <h2 className="fw-bold">Add invoice details</h2>

              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                data-bs-dismiss="modal"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {add ? (
                <div className="d-flex mb-3">
                  {file ? (
                    <div className="d-flex flex-column w-100 justify-content-center ">
                      <div className="d-flex flex-column gap-5 ">
                        <div
                          style={{
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dashed #888",
                            borderRadius: "8px",
                            padding: "20px",
                            width: "280px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                            style={{ width: "250px" }}
                          >
                            <div className="d-flex flex-row">
                              <p className="fw-bold">{file.size}&nbsp;</p>
                              <span>KB</span>
                            </div>
                            <div className="text-container my-2">
                              <p
                                className="text-truncate"
                                style={{ maxWidth: "15rem" }}
                              >
                                {file.name}
                              </p>
                            </div>
                            <p
                              className="text-danger text-decoration-underline fw-bold cursor-pointer"
                              onClick={handleFileRemove}
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      </div>
                      <form
                        id="kt_modal_add_manual_form"
                        className="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >
                        <div
                          className="d-flex flex-column scroll-y me-n7 pe-7"
                          id="kt_modal_add_manualr_scroll"
                          data-kt-scroll="true"
                          data-kt-scroll-activate="{default: false, lg: true}"
                          data-kt-scroll-max-height="auto"
                          data-kt-scroll-dependencies="#kt_modal_add_manual_header"
                          data-kt-scroll-wrappers="#kt_modal_add_manual_scroll"
                          data-kt-scroll-offset="300px"
                        >
                          <div className="fv-row mb-7 mt-5">
                            <label className="required fw-bold fs-6 mb-2">
                              Invoice Date
                            </label>

                            <input
                              placeholder="Select invoice date"
                              {...formik.getFieldProps("invoice_date")}
                              type="date"
                              name="invoice_date"
                              maxLength={50}
                              className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0",
                                {
                                  "is-invalid":
                                    formik.touched.invoice_date &&
                                    formik.errors.invoice_date,
                                },
                                {
                                  "is-valid":
                                    formik.touched.invoice_date &&
                                    !formik.errors.invoice_date,
                                }
                              )}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.invoice_date}
                              autoComplete="off"
                            />
                            {formik.touched.invoice_date &&
                              formik.errors.invoice_date && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.invoice_date}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="fv-row mb-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Invoice Numer
                            </label>

                            <input
                              placeholder="Enter invoice number"
                              maxLength={70}
                              {...formik.getFieldProps("inv_num")}
                              className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0",
                                {
                                  "is-invalid":
                                    formik.touched.inv_num &&
                                    formik.errors.inv_num,
                                },
                                {
                                  "is-valid":
                                    formik.touched.inv_num &&
                                    !formik.errors.inv_num,
                                }
                              )}
                              type="text"
                              name="inv_num"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.inv_num}
                              autoComplete="off"
                            />

                            {formik.touched.inv_num && formik.errors.inv_num && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.inv_num}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mb-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Vendor Name
                            </label>

                            <input
                              placeholder="Enter vendor name"
                              maxLength={70}
                              {...formik.getFieldProps("vendor_name")}
                              className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0",
                                {
                                  "is-invalid":
                                    formik.touched.vendor_name &&
                                    formik.errors.vendor_name,
                                },
                                {
                                  "is-valid":
                                    formik.touched.vendor_name &&
                                    !formik.errors.vendor_name,
                                }
                              )}
                              type="text"
                              name="vendor_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.vendor_name}
                              autoComplete="off"
                            />
                            {formik.touched.vendor_name &&
                              formik.errors.vendor_name && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.vendor_name}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="mb-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Amount
                            </label>
                            {/* 
                    <input
                      placeholder="Enter invoice amount"
                      maxLength={70}
                      {...formik.getFieldProps("amount")}
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched.amount && formik.errors.amount,
                        },
                        {
                          "is-valid":
                            formik.touched.amount && !formik.errors.amount,
                        }
                      )}
                      type="text"
                      name="amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                      autoComplete="off"
                    /> */}
                            <div>
                              <div>
                                {" "}
                                <span
                                  className="fs-6 fw-bold text-gray-700"
                                  style={{
                                    position: "absolute",
                                    padding: "12.5px",
                                  }}
                                >
                                  {baseCurrency}
                                </span>
                              </div>
                              <input
                                {...formik.getFieldProps("amount")}
                                type="text"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0",
                                  {
                                    "is-invalid":
                                      formik.touched.amount &&
                                      formik.errors.amount,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.amount &&
                                      !formik.errors.amount,
                                  }
                                )}
                                value={formik.values.amount}
                                style={{ paddingLeft: "60px" }}
                                placeholder="Enter invoice amount"
                                max="100000"
                                pattern="[0-9]*"
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "amount",
                                    handleAmountChange(e)
                                  );
                                }}
                              />
                            </div>

                            {formik.touched.amount && formik.errors.amount && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.amount}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mb-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Due date
                            </label>

                            <input
                              placeholder="Select due date"
                              maxLength={70}
                              {...formik.getFieldProps("due_date")}
                              className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0",
                                {
                                  "is-invalid":
                                    formik.touched.due_date &&
                                    formik.errors.due_date,
                                },
                                {
                                  "is-valid":
                                    formik.touched.due_date &&
                                    !formik.errors.due_date,
                                }
                              )}
                              type="date"
                              name="due_date"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.due_date}
                              autoComplete="off"
                            />
                            {formik.touched.due_date && formik.errors.due_date && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.due_date}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="text-center pt-15">
                          <button
                            type="reset"
                            onClick={() => formik.resetForm()}
                            className="btn btn-light me-3"
                            data-kt-users-modal-action="cancel"
                          >
                            Discard
                          </button>

                          <button type="submit" className="btn btn-primary">
                            <span className="indicator-label">Submit</span>
                            {formik.isSubmitting && (
                              <span className="indicator-progress">
                                Please wait...{" "}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignSelf: "center",
                        }}
                      >
                        <FileUploader
                          handleChange={handleAddFile}
                          name="file"
                          types={fileTypes}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "2px dashed #888",
                              borderRadius: "8px",
                              padding: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCloudUploadAlt}
                              size="4x"
                              style={{ color: "#888" }}
                            />
                            <p style={{ marginTop: "10px", color: "#888" }}>
                              Drag & drop invoice here, or click to select
                              invoice
                            </p>
                          </div>
                        </FileUploader>
                        {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <form
                  id="kt_modal_add_manual_form"
                  className="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_add_manualr_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_manual_header"
                    data-kt-scroll-wrappers="#kt_modal_add_manual_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    <div className="fv-row mb-7 mt-5">
                      <label className="required fw-bold fs-6 mb-2">
                        Invoice Date
                      </label>

                      <input
                        placeholder="Select invoice date"
                        {...formik.getFieldProps("invoice_date")}
                        type="date"
                        name="invoice_date"
                        maxLength={50}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.invoice_date &&
                              formik.errors.invoice_date,
                          },
                          {
                            "is-valid":
                              formik.touched.invoice_date &&
                              !formik.errors.invoice_date,
                          }
                        )}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.invoice_date}
                        autoComplete="off"
                      />
                      {formik.touched.invoice_date &&
                        formik.errors.invoice_date && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.invoice_date}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Invoice Numer
                      </label>

                      <input
                        placeholder="Enter invoice number"
                        maxLength={70}
                        {...formik.getFieldProps("inv_num")}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.inv_num && formik.errors.inv_num,
                          },
                          {
                            "is-valid":
                              formik.touched.inv_num && !formik.errors.inv_num,
                          }
                        )}
                        type="text"
                        name="inv_num"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inv_num}
                        autoComplete="off"
                      />

                      {formik.touched.inv_num && formik.errors.inv_num && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.inv_num}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Vendor Name
                      </label>

                      <input
                        placeholder="Enter vendor name"
                        maxLength={70}
                        {...formik.getFieldProps("vendor_name")}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.vendor_name &&
                              formik.errors.vendor_name,
                          },
                          {
                            "is-valid":
                              formik.touched.vendor_name &&
                              !formik.errors.vendor_name,
                          }
                        )}
                        type="text"
                        name="vendor_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.vendor_name}
                        autoComplete="off"
                      />
                      {formik.touched.vendor_name && formik.errors.vendor_name && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {formik.errors.vendor_name}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Amount
                      </label>
                      {/* 
                    <input
                      placeholder="Enter invoice amount"
                      maxLength={70}
                      {...formik.getFieldProps("amount")}
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched.amount && formik.errors.amount,
                        },
                        {
                          "is-valid":
                            formik.touched.amount && !formik.errors.amount,
                        }
                      )}
                      type="text"
                      name="amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                      autoComplete="off"
                    /> */}
                      <div>
                        <div>
                          {" "}
                          <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{
                              position: "absolute",
                              padding: "12.5px",
                            }}
                          >
                            {baseCurrency}
                          </span>
                        </div>
                        <input
                          {...formik.getFieldProps("amount")}
                          type="text"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                formik.touched.amount && formik.errors.amount,
                            },
                            {
                              "is-valid":
                                formik.touched.amount && !formik.errors.amount,
                            }
                          )}
                          value={formik.values.amount}
                          style={{ paddingLeft: "60px" }}
                          placeholder="Enter invoice amount"
                          max="100000"
                          pattern="[0-9]*"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "amount",
                              handleAmountChange(e)
                            );
                          }}
                        />
                      </div>

                      {formik.touched.amount && formik.errors.amount && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.amount}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Due date
                      </label>

                      <input
                        placeholder="Select due date"
                        maxLength={70}
                        {...formik.getFieldProps("due_date")}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.due_date && formik.errors.due_date,
                          },
                          {
                            "is-valid":
                              formik.touched.due_date &&
                              !formik.errors.due_date,
                          }
                        )}
                        type="date"
                        name="due_date"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.due_date}
                        autoComplete="off"
                      />
                      {formik.touched.due_date && formik.errors.due_date && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.due_date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center pt-15">
                    <button
                      type="reset"
                      onClick={() => formik.resetForm()}
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Discard
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                      {formik.isSubmitting && (
                        <span className="indicator-progress">
                          Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
