export const TransferMoneyData = {
  accountNumber: "",
  payeeAlias: "",
  payeeType: "",
  payeeSubType: "",
  amount: 0,
  remark: "",
  transactionPIN: "",
  txnScheduleDate: "",
  trfCategory: [] as string[],
  approverName: "",
  approverGuid: "",
};
export const RemittanceMoneyData = {
  payeeAlias: "",
  payeeType: "",
  amount: 0,
  remark: "",
  trfCategory: [] as string[],
  payerAccountNumber: "",
  payerCurrency: "",
  enteredAmount: "",
  totalFees: "",
  transactionPIN: "",
};

export const resetFunction = {
  reset: function () {
    // Your reset function logic here
  },
};
