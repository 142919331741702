import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { Col, Row } from "react-bootstrap";
import Icon from "../../../_metronic/assets/Icons/xfinlogo.svg";
import { KTSVG } from "../../../_metronic/helpers";
import { RecordPayModal } from "./RecordPay";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface details {
  invoiceGuid: string;
}
interface InvPreviewData {
  tenantId: string;
  referenceId: string;
  invoiceGuid: string;
  invoiceNumber: string;
  businessName: string;
  businessAddress: string;
  customerGuid: string;
  customerName: string;
  invoiceDate: string;
  dueDate: string;
  subTotal: number;
  discount: number;
  tax: number;
  shipping: number;
  total: number;
  termsAndConditions: string;
  notes: string;
  invStatus: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  customerEmailId: string;
  items: [
    {
      tenantId: string;
      referenceId: string;
      invoiceGuid: string;
      itemName: string;
      qty: number;
      rate: number;
      total: number;
    }
  ];
}

interface Todetails {
  businessName: string;
  businessAddress: string;
  businessIsdCode: number;
  businessMobileNo: string;
  businessEmailId: string;
}

interface setting {
  invoicePrefix: "";
  invoiceSequencePattern: "";
  termsAndConditions: "";
  dueDatePeriod: "";
  tax: "";

  documents: [
    {
      documentExtention: "";
      documentName: "logo";
      documentTypes: [1];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;
      documentPath: "";
    },
    {
      documentExtention: "";
      documentName: "signature";
      documentTypes: [9];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;

      documentPath: "";
    }
  ];
}
export function InvoiceDetailsModal(data: { data?: details | null }) {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const detailsModal = useRef<HTMLDivElement | null>(null);
  const baseCurrency = sessionStorage.getItem("currency");

  const [invData, setData] = useState<InvPreviewData | null>(null);
  const [toData, setToData] = useState<Todetails | null>(null);
  const [invSetting, setInvSetting] = useState<setting | null>(null);

  const id = data?.data?.invoiceGuid;

  const sampleData: any[] = [
    { itemName: "Polish", rate: "20", quantity: "10", total: "200" },
    { itemName: "Pencil", rate: "5", quantity: "10", total: "50" },
    { itemName: "Pen", rate: "10", quantity: "100", total: "1000" },
  ];

  useEffect(() => {
    if (detailsModal.current) {
      const modal = new Modal(detailsModal.current);
      detailsModal.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        detailsModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleRecordPay = () => {
    const modalElement = document.getElementById("recordpay_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("details_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    const modalElement = detailsModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    //handleDeleteConfirmation()
  };
  const LoadInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        invoiceGuid: id,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceData = async () => {
    try {
      const url = `${API_URL}/invoice/init`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setToData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data?.data) {
      LoadInvoice();
      getInvoiceData();
    }
  }, [data]);

  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setInvSetting(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadSettings();
  }, []);

  return (
    <>
      <div
        className="modal"
        tabIndex={-1}
        id="details_modal"
        ref={detailsModal}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-top rounded"
          style={{ margin: "0 auto", marginTop: "5%", width: "1000px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="d-flex flex row w-100 justify-content-between">
                  <img
                    className="image-input-wrapper w-125px h-125px"
                    style={{ objectFit: "contain" }}
                    src={invSetting?.documents[0]?.documentPath}
                    alt=""
                  />
                  <div className="d-flex flex-column gap-10 justify-content-center align-items-center w-100px h-100px mb-5 text-primary  fs-3 fw-bold">
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                       <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                    </div>
                    {invData?.invoiceNumber}
                  </div>
                </div>
                <div className="row mb-4 w-100 justify-content-between">
                  <div className="d-flex flex-column w-50 text-start">
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Invoice Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {invData?.invoiceDate
                        ? new Date(invData.invoiceDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="d-flex flex-column w-50 text-end">
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Due Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {invData?.dueDate
                        ? new Date(invData.dueDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="mb-2 w-100 ">
                  <form>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          To
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={
                            invData?.customerName +
                            "\n" +
                            invData?.businessAddress +
                            "\n" +
                            invData?.customerEmailId
                          }
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          From
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={
                            toData?.businessName +
                            "\n" +
                            toData?.businessAddress +
                            "\n" +
                            "+" +
                            toData?.businessIsdCode +
                            " " +
                            toData?.businessMobileNo +
                            "\n" +
                            toData?.businessEmailId
                          }
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="d-flex flex-column w-100 mb-2">
                  <div className="border border-dark border-bottom-0 mt-2 w-100  bg-primary justify-content-center align-items-center">
                    <Row className="w-100 justify-content-center">
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Item
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Rate
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Qty
                        </Col>
                      </div>
                      <div className="d-flex w-25 p-2">
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Total
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="border border-dark justify-content-center align-items-center">
                    {invData?.items.map((data, index) => (
                      <div className="w-100 justify-content-center align-items-center">
                        <Row
                          className="w-100 justify-content-center "
                          key={index}
                        >
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-center fw-bold fs-6">
                              {data.itemName}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-center fw-bold fs-6">
                              {baseCurrency}&nbsp;
                              {data.rate.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-center fw-bold fs-6">
                              {data.qty}
                            </Col>
                          </div>
                          <div className="w-25 p-2">
                            <Col className="d-flex align-items-center justify-content-center fw-bold fs-6">
                              {baseCurrency}&nbsp;
                              {data.total.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                        </Row>
                        <hr className="m-0"></hr>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="w-100  justify-content-center align-items-center border border-dark border-top-0">
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Subtotal
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-end fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.subTotal.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Discount
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-end fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.discount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex flex-1 align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Tax
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-end fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.tax.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Shipping
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-end fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.shipping.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr
                        className="m-0"
                        style={{ borderColor: "1px solid black" }}
                      ></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Total
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-end fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.total.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <label htmlFor="" className="fw-bold fs-4 mt-2">
                    Notes
                  </label>
                  <textarea
                    className="w-100 m-2 ms-0 me-0 border-0 rounded bg-light p-2"
                    style={{ resize: "none" }}
                    value={invData?.notes}
                    rows={2}
                    maxLength={300}
                    disabled
                  ></textarea>
                </div>

                <div className="mt-3 mb-5 w-100">
                  <div>
                    <h4>Terms and conditions</h4>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {" "}
                      <p>{invData?.termsAndConditions}</p>
                    </div>
                    <div>
                      <img
                        className="image-input-wrapper w-125px h-125px"
                        style={{ objectFit: "contain" }}
                        src={invSetting?.documents[1]?.documentPath}
                        alt=""
                      />
                       <div>
                        <p className="m-0 p-0">{toData?.businessName}</p>
                        <p>Authorized Signature</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex w-100 gap-5 mt-4 justify-content-around">
                  {invData?.invStatus === 1 && (
                    <button
                      type="button"
                      className="btn  rounded"
                      style={{
                        width: "250px",
                      }}
                      onClick={() => {
                        handleDeleteCloseModal();
                        handleRecordPay();
                      }}
                    >
                      Record Pay
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    style={{
                      width: "250px",
                      color: "#248bfd",
                    }}
                    onClick={handleDeleteCloseModal}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecordPayModal data={data?.data?.invoiceGuid} />
    </>
  );
}
