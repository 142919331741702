import { useState } from "react";

export function VerifyEmail()
{
    return(
        <>
        {/* <div className="d-flex flex-column flex-center text-center p-10"> */}
        {/* <div className="card card-flush w-lg-650px py-5 text-center"> */}
						<div className="card-body py-15 py-lg-20 text-center">
							{/* <!--begin::Logo--> */}
							
							{/* <!--end::Logo--> */}
							{/* <!--begin::Title--> */}
							<h1 className="fw-bolder text-gray-900 mb-5">Verify your email</h1>
							{/* <!--end::Title--> */}
							{/* <!--begin::Action--> */}
							<div className="fs-6 mb-8">
								<span className="fw-semibold text-gray-500">Did’t receive an email?</span>
								<a href="../../demo1/dist/authentication/layouts/corporate/sign-up.html" className="link-primary fw-bold">Try Again</a>
							</div>
							{/* <!--end::Action--> */}
							{/* <!--begin::Link--> */}
							<div className="mb-11">
								<a href="../../demo1/dist/index.html" className="btn btn-sm btn">Skip for now</a>
							</div>
							{/* <!--end::Link--> */}
							{/* <!--begin::Illustration--> */}
							<div className="mb-0">
								{/* <img src="assets/media/auth/please-verify-your-email.png" className="mw-100 mh-300px theme-light-show" alt="" /> */}
								<img src='https://img.freepik.com/premium-vector/open-envelope-with-check-document-icon-excellent-flat-design-vector-illustration-eps10_115464-1159.jpg?w=2000' className="mw-100 mh-300px " alt="" />
							</div>
							{/* <!--end::Illustration--> */}
						</div>
					{/* </div> */}
                    {/* </div> */}
        </>
    )
}