import { useEffect, useState } from "react";
import { BarChart } from "./BarChart";
import { getAccountStmnt, getStatistics } from "./StatisticsApi";
import { TableComponent } from "./Table";
import {
  AccountStmnt,
  Accounts,
  StatisticsData,
} from "../../interface/Interface";
import { getAccounts } from "../paybill/PayBillApi";

export default function Statistics() {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [data, setData] = useState<AccountStmnt[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState<string>("1");
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [bardata, setBardata] = useState<StatisticsData>({
    userId: "",
    tenantId: "",
    accountNumber: "",
    referenceId: "",
    referenceType: 0,
    currency: "",
    stmntType: "",
    stmntDuration: 0,
    graphData: [],
    income: 0,
    expense: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAccounts(API_URL, token);
      setAccounts(result);
      setSelectedAccount(result[0].accountNumber);
      setSelectedCurrency(result[0].currency);
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const bardata = await getStatistics('3101298136', 'SSP', '4')
  //     const allNames = bardata.graphData.map((entry) => entry.name)
  //     setLabels(allNames)
  //     setExpense(bardata.expense)
  //     setIncome(bardata.income)
  //     setGraphData(bardata.graphData)
  //   }

  //   fetchData()
  // }, [])

  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = e.target.value;
    const selectedAccount = accounts[+selectedIndex];
    setSelectedAccount(selectedAccount.accountNumber);
    setSelectedCurrency(selectedAccount.currency);
  };

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateRange(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && selectedDateRange) {
        // const formattedStartDate =
        //   selectedDateRange === "4"
        //     ? new Date(new Date().getFullYear(), new Date().getMonth() - 5, 2)
        //         .toISOString()
        //         .split("T")[0]
        //     : "";

        // const result = await getAccountStmnt(
        //   API_URL,
        //   token,
        //   selectedAccount,
        //   selectedCurrency,
        //   selectedDateRange==="4"?"3":selectedDateRange,
        //   selectedDateRange==="4"?formattedStartDate:'',
        //   selectedDateRange==="4"?new Date().toISOString().split('T')[0]:''

        // );
        // setData(result);

        const bardata = await getStatistics(
          API_URL,
          token,
          selectedAccount,
          selectedDateRange
        );
        setBardata(bardata);
      }
    };
    fetchData();
  }, [selectedAccount, selectedDateRange]);

  return (
    <>
      <h4 className="mb-4">Statistics</h4>
      <BarChart
        accounts={accounts}
        bardata={bardata}
        handleAccountChange={handleAccountChange}
        handleDateRangeChange={handleDateRangeChange}
      />
    </>
  );
}
