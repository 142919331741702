export const subAccData = {
  accountNumber: "",
  accountType: 0,
  currency: "",
  subAccountName: "",
  iconCode: "",
  goal: "",
  goalLimit: 0,
  creditCondition: "",
  creditFrequency: "",
  frequencyValue: 0,
  creditType: "",
};
export const subAccData2 = {
  accountNumber: "",
  accountType: 0,
  currency: "",
  subAccountName: "",
  iconCode: "",
  goal: "",
  goalLimit: 0,
  creditCondition: "",
  creditType: "",
  creditValue: 0,
};
