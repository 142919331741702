export interface IAppBasic {
  category: string;
}
export interface IAppName {
  name: string;
}

export interface IAppDescription {
  description: string;
}
export interface IAppSchedule {
  schedule: string;
}

export interface IAppAccount {
  account: string;
}
export interface IAppDate {
  date: string;
}
export interface IAppApprover {
  approverName: string;
}
export interface IAppApproverGuid {
  approverGuid: string;
}
export interface IAppSubmit {
  submit: () => void;
}

export interface ICreateAppData {
  category: IAppBasic;
  name: IAppName;

  description: IAppDescription;
  schedule: IAppSchedule;
  account: IAppAccount;
  date: IAppDate;
  approverName: IAppApprover;
  approverGuid: IAppApproverGuid;
  submit: IAppSubmit;
}

export const defaultCreateAppData: ICreateAppData = {
  category: { category: "Employee Payment" },
  name: { name: "" },
  description: { description: "" },
  schedule: { schedule: "Now" },
  account: { account: "" },
  date: { date: "" },
  approverName: { approverName: "" },
  approverGuid: { approverGuid: "" },
  submit: { submit: () => {} },
};
export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
  txnPin: string;
  setTxnPin: (txnPin: string) => void;
};
