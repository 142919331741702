import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import PinInput from "react-pin-input";
import FAIcon from "../../../../_metronic/assets/Icons/6310507.jpg";
import { phoneNumber } from "./Login";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import XLogo from ".././../../../_metronic/assets/Icons/xfin.png";
import OtpInput from "react-otp-input";
import { enhancedFetch, signOut } from "../core/_requests";
import { useAuth } from "../core/Auth";

export default function TwoFactorAuthentication() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);
  const [timer, setTimer] = useState(55);
  const { saveAuth, setCurrentUser,logout,setToken } = useAuth()

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;


  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  useEffect(() => {
    if (timer > 0) {
      const myInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [timer]);

  const handleResend = () => {
    setTimer(55);
  };



  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      const confirmLeave = window.confirm("Are you sure you want to leave?");
      
      // Perform the actions regardless of user's choice
      if(confirmLeave){
      signOut();
      logout();
      sessionStorage.clear();
      sessionStorage.removeItem("kt-auth-react-v");
      setToken(undefined);
      }
  
      // Prevent navigation by pushing the current state again
      window.history.pushState(null, "", window.location.pathname);
    };
  
    // Add an initial state to handle the back button
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const confirmLeave = window.confirm("Are you sure you want to leave?");
      if(confirmLeave){
      
      signOut();
      logout();
      sessionStorage.clear();
      sessionStorage.removeItem("kt-auth-react-v");
      setToken(undefined);

      }

      event.preventDefault();
      event.returnValue = ''; 
    };
  
    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      // Clean up the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  
  


  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 6) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  console.log(phoneNumber);

  return (
    <>
      <div className="d-flex flex-column ">
        <div className=" p-10">
          <div className="text-center mb-10">
            <img
              alt="Logo"
              className="logo"
              src={FAIcon}
              style={{ maxHeight: "150px", maxWidth: "150px" }}
            />
          </div>
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Two-Factor Verification</h1>
            <div className="text-muted fw-semibold fs-5 mb-3">
              Enter the verification code we sent to
            </div>
            <div className="fw-bold text-dark fs-3">
            {phoneNumber.includes("@")
                ? `${phoneNumber.slice(0, 3)} **** ${phoneNumber.slice(-8)}`
                : `${phoneNumber.slice(0, 3)} **** ${phoneNumber.slice(-3)}`}
            </div>
          </div>
          <Formik
            initialValues={{
              securityCode: pin,
            }}
            validationSchema={Yup.object({
              securityCode: Yup.string()
                .matches(/^\d{6}$/, "Security code must be 6 digits")
                .required("Security code is required"),
            })}
            onSubmit={(values, actions) => {
              setSubmitted(true); // Optionally, set a state to indicate submission
              // Handle form submission e.g., navigate or redirect
              navigate("/dashboard");
              // window.location.href = "/dashboard";
            }}
          >
            <Form autoComplete="off">
              <div className="mb-5 d-flex flex-column align-items-center justify-content-center">
                <div className="fw-bold text-start text-dark fs-6 mb-1 text-center">
                  Type your 6-digit verification code
                </div>

                <Field name="securityCode">
                  {({ field, form }: any) => (
                    <OtpInput
                      value={otp.join("")}
                      onChange={(value: string) => {
                        value
                          .split("")
                          .forEach((char, index) => handleChange(char, index));
                      }}
                      numInputs={6}
                      renderSeparator={<span>&nbsp;&nbsp;</span>}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          value={otp[index]}
                          ref={(el) => (inputRefs.current[index] = el)}
                          onChange={(e) =>
                            form.setFieldValue(
                              "securityCode",
                              handleChange(e.target.value, index)
                            )
                          }
                          onKeyDown={(e) =>
                            form.setFieldValue(
                              "securityCode",
                              handleKeyDown(e, index)
                            )
                          }
                          type={show ? "password" : "text"}
                          disabled={index !== activeInput}
                          onFocus={() => setActiveInput(index)}
                          className="text-center rounded mt-3"
                          style={{ height: "45px", width: "45px" }}
                        />
                      )}
                    />
                  )}
                </Field>
                <p
                  className="text-center cursor-pointer text-decoration-underline mt-2 mb-0"
                  onClick={() => setShow(!show)}
                >
                  {show ? "Show Verification Code" : "Hide Verification Code"}
                </p>
                <div className="mt-2">
                  <ErrorMessage
                    name="securityCode"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="text-center mb-5">
                <button type="submit" className="btn btn-lg  fw-bold">
                  {submitted ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Form>
          </Formik>
          <div className="text-center  fs-5">
            <span className="text-muted me-2 fw-semibold">Didn’t get the code?</span>
            {timer === 0 ? (
                <span
                  className="text-primary cursor-pointer"
                  onClick={handleResend}
                >
                  Resend
                </span>
              ) : (
                <span>
                  {" "}
                  Resend code in <span className="text-primary">
                    {timer}
                  </span>{" "}
                  seconds
                </span>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
