import React, { useState, ChangeEvent, FC, useEffect } from "react";
import {
  Accounts,
  AccountStmnt,
  ScheduleReceipt,
  TxnData,
} from "../../interface/Interface";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Transaction, TransactionModel, Txn } from "./reconcilation";
import { Button } from "bootstrap";
import Swal from "sweetalert2";

export const accountStatement = {
  data: [] as AccountStmnt[],
  accountNumber: "",
  currency: "",
  stmntDuration: "",
  fromDate: "",
  toDate: "",
};

interface Schedule {
  functionCode: string;
  secondaryFundType: string;
  amount: number;
  txnId: string;
  txnStatus: number;
  accountNumber: string;
  currency: string;
  payeeAccountNumber: string;
  accountType: number;
  txnScheduleDate: string;
  receipt: ScheduleReceipt;
}

const Recon1: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [search, setSearch] = useState(false);
  const [txns, setTxns] = useState<Transaction[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(txns);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [searchData, setSearchData] = useState<TransactionModel[]>([]);
  const [previousValue, setPreviousValue] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState<
    number | null
  >(null);
  const [formData, setFormData] = useState({
    name: "",
    reference: "",
    amount: "",
  });
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Accounts>(
    accounts?.[0]
  );
  const [txnIds, setTxnIds] = useState<Txn[]>([]);
  const [reconIDs, setReconIDs] = useState<string[]>([]);
  const [txnIDs, setTxnIDs] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = txns?.filter((transaction) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Reconciled":
          isTabMatched = transaction.status === "Reconciled";
          break;
        case "Partial Match":
          isTabMatched = transaction.status === "Partial Match";
          break;
        case "No Match":
          isTabMatched = transaction.status === "No Match";
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const isSearchMatched =
        transaction?.description
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        transaction?.payeeName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        transaction?.reconId
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        transaction?.txnType
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [txns, searchQuery, selectedTab]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(txns);
    } else if (tab === "Reconciled") {
      const filteredData = txns.filter(
        (transaction) => transaction.status === "Reconciled"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Partial Match") {
      const filteredData = txns.filter(
        (transaction) => transaction.status === "Partial Match"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "No Match") {
      const filteredData = txns.filter(
        (transaction) => transaction.status === "No Match"
      );
      setFilteredTransactions(filteredData);
    }
  };

  const getReconData = async (accountNumber: string) => {
    try {
      setLoading(true);
      const url = `${API_URL}/recon/viewReconInvoiceData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setTxns(data.results);
        setLoading(false);
        const totalAmount = data?.results?.reduce((acc: any, item: any) => {
          return acc + parseFloat(item.amount);
        }, 0);
        // setBalance(totalAmount);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const dropdownarr = [
    "All",
    "Invoice with bank transactions",
    "Credit card with bank transactions",
    "Payroll with bank transactions",
  ];
  // Action handler
  const handleRowClick = (parentIndex: number, rowIndex: number) => {
    console.log("Parent Index:", parentIndex, "Row Index:", rowIndex);
  };

  const handleDropRowClick = (index: number) => {
    setSelectedRow(selectedRow === index ? null : index); // Toggle dropdown
  };
  const handleLeftCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    reconId: string
  ) => {
    const target = e.target as HTMLInputElement;
    console.log(reconId);

    setReconIDs((prevReconIds) => {
      if (target.checked) {
        // If the reconId doesn't already exist in the array, add it
        if (!prevReconIds.includes(reconId)) {
          return [...prevReconIds, reconId];
        }
      } else {
        // Remove the reconId if it's unchecked
        return prevReconIds.filter((id) => id !== reconId);
      }
      console.log(prevReconIds);

      // Return the previous state if no changes are made
      return prevReconIds;
    });
  };

  const handleRightCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    txnId: string
  ) => {
    const target = e.target as HTMLInputElement;
    console.log(txnId);

    setTxnIDs((prevTxnIds) => {
      if (target.checked) {
        // If the reconId doesn't already exist in the array, add it
        if (!prevTxnIds.includes(txnId)) {
          return [...prevTxnIds, txnId];
        }
      } else {
        // Remove the reconId if it's unchecked
        return prevTxnIds.filter((id) => id !== txnId);
      }
      console.log(prevTxnIds);

      // Return the previous state if no changes are made
      return prevTxnIds;
    });
  };

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    amount: number,
    reconId: string,
    txnId: string
  ) => {
    const target = e.target as HTMLInputElement;

    setTxnIds((prevTxnIds) => {
      // Map through prevTxnIds to update or add reconId
      const updatedTxnIds = prevTxnIds
        .map((t) =>
          t.reconId === reconId
            ? {
                ...t,
                txnIds: target.checked
                  ? t.txnIds.includes(txnId)
                    ? t.txnIds // Already exists, don't add
                    : [...t.txnIds, txnId] // Add the txnId
                  : t.txnIds.filter((id) => id !== txnId), // Remove txnId if unchecked
              }
            : t
        )
        // Filter out objects with empty txnIds
        .filter((t) => t.txnIds.length > 0);

      // If reconId didn't exist and checkbox is checked, add a new entry
      if (target.checked && !prevTxnIds.some((t) => t.reconId === reconId)) {
        return [...updatedTxnIds, { reconId: reconId, txnIds: [txnId] }];
      }
      console.log(updatedTxnIds);

      return updatedTxnIds;
    });

    // Update the selected amount safely
    setSelectedAmount((prev) => {
      if (target.checked) {
        return prev + amount; // Add the amount if checked
      } else {
        return Math.max(0, prev - amount); // Ensure no negative values
      }
    });
  };

  const reset = () => {
    setFormData({
      name: "",
      reference: "",
      amount: "",
    });
    setError("");
    setSearchData([]);
    setSelectedAmount(0);
    setSelectedTransactionIndex(null);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "amount" ? handleAmountChange(e) : value,
    }));
    setError("");
  };
  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    if (value === "0" || value === "") {
      setPreviousValue("");
      return "";
    }

    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };
  const handleSearch = async (reconId: string, txnType: string) => {
    if (formData.name || formData.reference || formData.amount) {
      try {
        const url = `${API_URL}/recon/findTxnInvoice`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure you have access to the token
        };

        // Create an object with only the fields that have values
        const apiBody: any = {
          reconId: reconId, // This seems to be a constant value from your original code
          txnType: txnType, // This also seems to be a constant
        };

        if (formData.name) apiBody.payeeName = formData.name;
        if (formData.reference) apiBody.refrenceNo = formData.reference;
        if (formData.amount) apiBody.amount = parseFloat(formData.amount);

        // Add accountNumber if it's available in formData, otherwise use the constant value
        apiBody.accountNumber = "3201298810";

        const options = {
          method: "POST",
          headers,
          body: JSON.stringify(apiBody),
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          setSearchData(data?.results?.[0]?.txnModels);
          setError("");
          if (data?.results?.[0]?.txnModels?.length === 0) {
            setError("No data found");
          }
        }
        if (data.status.statusCode === 1) {
          setError(data.status.messageDescription);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while searching. Please try again.");
      }
    } else {
      setError("At least one field is required");
    }
  };
  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
      setSelectedAccount(data?.results?.[0]);
      getReconData(data?.results?.[0]?.accountNumber);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const handleReverse = async (reconId: string, txnIds: string[]) => {
    try {
      setLoading(true);
      const url = `${API_URL}/recon/reconcileInvoiceReverse`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const apiBody: any = {
        reconIds: [reconId],
        txnIds: txnIds,
      };

      const options = {
        method: "POST",
        headers,
        body: JSON.stringify(apiBody),
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setError("");
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Reversed the reconciled transaction successfully.",
        });
        getReconData(selectedAccount?.accountNumber);
        if (data?.results?.[0]?.txnModels?.length === 0) {
          setError("No data found");
        }
      }
      if (data.status.statusCode === 1) {
        setError(data.status.messageDescription);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while searching. Please try again.");
    }
  };
  const handleReconcile = async () => {
    try {
      setLoading(true);
      const url = `${API_URL}/recon/reconcileInvoice`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const apiBody: any = {
        reconIds: [txnIds[0]?.reconId],
        txnIds: txnIds[0]?.txnIds,
      };

      const options = {
        method: "POST",
        headers,
        body: JSON.stringify(apiBody),
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setError("");
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Reconciled the transaction successfully.",
        });
        getReconData(selectedAccount?.accountNumber);
        if (data?.results?.[0]?.txnModels?.length === 0) {
          setError("No data found");
        }
      }
      if (data.status.statusCode === 1) {
        setError(data.status.messageDescription);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while searching. Please try again.");
    }
  };

  return (
    <>
      <h4 className="mb-5">Recon Live Dashboard</h4>
      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className={`nav-link text-active-gray-800 ${
                    selectedTab === "All" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className={`nav-link text-active-gray-800 ${
                    selectedTab === "Credit" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Reconciled")}
                >
                  Reconciled
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className={`nav-link text-active-gray-800 text-nowrap ${
                    selectedTab === "Debit" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Partial Match")}
                >
                  Partial Match
                </a>
              </li>
              <li className="nav-item" key={4}>
                <a
                  className={`nav-link text-active-gray-800 text-nowrap ${
                    selectedTab === "Schedule" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => handleTabClick("No Match")}
                >
                  No Match
                </a>
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center gap-10 me-5 p-3">
            <div>
              {" "}
              <select
                className="form-select text-gray cursor-pointer"
                value={selectedAccount ? selectedAccount.accountNumber : ""}
                onChange={(e) => {
                  const selectedAccountId = e.target.value;
                  const selectedAccount = accounts.find(
                    (account) => account.accountNumber === selectedAccountId
                  );
                  setSelectedAccount(
                    selectedAccount ? selectedAccount : accounts?.[0]
                  );
                  if (selectedAccount)
                    getReconData(selectedAccount?.accountNumber);
                }}
              >
                {accounts?.map((account) => (
                  <option
                    key={account.accountNumber}
                    value={account.accountNumber}
                  >
                    {account.accountNumber}
                    {"  "}({account.currency})
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
                className="form-select text-gray cursor-pointer"
                value={"Invoice with bank transactions"}
              >
                {dropdownarr.map((item, index) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div>
          <div
            className="tab-content"
            id="myTabContent"
            style={{
              overflow: "scroll",
            }}
          >
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6">
                      {/* Left Side Headers */}
                      {/* <th className="w-10px"></th> */}
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>

                      <th className="min-w-100px p-5 fs-7">Ref. ID</th>
                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderRight: "1px solid black" }}
                      >
                        Amount
                      </th>

                      {/* Right Side Headers */}
                      <th className="w-10px"></th>
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>
                      <th className="min-w-100px p-5 fs-7">Txn Desc.</th>
                      <th className="min-w-100px p-5 fs-7">Amount</th>

                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderLeft: "1px solid black" }}
                      >
                        Status
                      </th>
                      <th className="min-w-100px p-5 fs-7">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((leftItem, leftIndex) => {
                        const hasRightData = leftItem.txnModels.length > 0;

                        return (
                          <React.Fragment key={leftIndex}>
                            {hasRightData ? (
                              leftItem.txnModels.map(
                                (rightItem, rightIndex) => (
                                  <React.Fragment
                                    key={`${leftIndex}-${rightIndex}`}
                                  >
                                    <tr className="align-top">
                                      {rightIndex === 0 ? (
                                        <>
                                          {/* <td className="p-5">
                                            <div className="form-check form-check-custom form-check-sm">
                                              {leftItem.txnModels.length > 1 ? (
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input cursor-pointer border border-dark"
                                                  onClick={(e) => {
                                                    handleLeftCheckClick(
                                                      e,
                                                      leftItem.reconId
                                                    );
                                                  }}
                                                  disabled={
                                                    reconIDs.length === 1 &&
                                                    txnIDs.length > 1
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.date}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.payeeName}
                                          </td>

                                          {/* <td
                                        rowSpan={leftItem.txnModels.length}
                                        className="p-5 text-gray-600 fs-7"
                                      >
                                        {leftItem.description}
                                      </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.refrenceNo}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderRight: "1px solid black",
                                            }}
                                          >
                                            {leftItem.amount}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          {/* Empty cells to maintain alignment when left data is not rendered */}
                                          {/* <td style={{ width: "1px" }}></td> */}
                                        </>
                                      )}

                                      <td className="p-5">
                                        <div className="form-check form-check-custom form-check-sm">
                                          {leftItem.status !== "Reconciled" ? (
                                            <input
                                              type="checkbox"
                                              className="form-check-input cursor-pointer border border-dark"
                                              onClick={(e) => {
                                                handleRightCheckClick(
                                                  e,
                                                  rightItem.txnId
                                                );
                                                handleCheckClick(
                                                  e,
                                                  +leftItem.amount,
                                                  leftItem.reconId,
                                                  rightItem.txnId
                                                );
                                              }}
                                              disabled={
                                                txnIds.length > 0 &&
                                                txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.date}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.senderName}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.txnType}
                                      </td>

                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.totalAmount}
                                      </td>

                                      {rightIndex === 0 ? (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          >
                                            {rightItem?.status && (
                                              <span
                                                className={`badge badge-light-${
                                                  leftItem.status ===
                                                  "Reconciled"
                                                    ? "primary"
                                                    : "info"
                                                } p-2`}
                                                style={{
                                                  fontWeight: "normal",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {leftItem.status}
                                              </span>
                                            )}
                                            {}
                                          </td>
                                          <td className="p-5 text-gray-600 fs-7">
                                            {leftItem.status ===
                                            "Reconciled" ? (
                                              <button
                                                className="btn btn-primary btn-sm fs-7 px-6 py-1"
                                                onClick={() => {
                                                  handleReverse(
                                                    leftItem?.reconId,
                                                    leftItem?.txnModels
                                                      ?.length > 1
                                                      ? leftItem?.txnModels?.flatMap(
                                                          (model) => model.txnId
                                                        ) || []
                                                      : [rightItem?.txnId]
                                                  );
                                                }}
                                              >
                                                Reverse
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-info btn-sm fs-7 px-5 py-1"
                                                disabled={
                                                  txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                                }
                                                onClick={() =>
                                                  handleReconcile()
                                                }
                                              >
                                                Reconcile
                                              </button>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          ></td>
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <tr className="align-top">
                                {/* Left Side */}
                                {/* <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    <input
                                      type="checkbox"
                                      className="form-check-input cursor-pointer border border-dark"
                                      onClick={(e) =>
                                        handleLeftCheckClick(
                                          e,
                                          leftItem.reconId
                                        )
                                      }
                                      disabled={
                                        reconIDs.length === 1 &&
                                        txnIDs.length > 1
                                      }
                                    />
                                  </div>
                                </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.date}
                                </td>
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.payeeName}
                                </td>

                                {/* <td className="p-5 text-gray-600 fs-7">
                                {leftItem.description}
                              </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.refrenceNo}
                                </td>
                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderRight: "1px solid black" }}
                                >
                                  {leftItem.amount}
                                </td>

                                {/* Right Side (show "Find & Match" button when no data) */}
                                <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    {/* <input
                                    type="checkbox"
                                    className="form-check-input cursor-pointer border border-dark"
                                    // onClick={(e) =>
                                    //   handleCheckClick(
                                    //     e,
                                    //     +item.amount,
                                    //     tran.reconId,
                                    //     item.txnId
                                    //   )
                                    // }
                                  /> */}
                                  </div>
                                </td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>

                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderLeft: "1px solid black" }}
                                >
                                  <span
                                    className={`badge badge-light-dark p-2`}
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {leftItem.status}
                                  </span>
                                </td>
                                <td className="py-5 text-gray-600 fs-7 d-flex px-3">
                                  <button
                                    className="btn btn-sm btn-secondary fs-7 px-3 py-1 text-nowrap"
                                    style={{ maxHeight: "25px" }}
                                    onClick={() => {
                                      reset();
                                      handleDropRowClick(leftIndex);
                                    }}
                                  >
                                    Find & Match
                                  </button>
                                </td>
                              </tr>
                            )}

                            {/* Conditionally render dropdown */}
                            {selectedRow === leftIndex && (
                              <tr>
                                <td colSpan={12} className="ms-10">
                                  <div className="card shadow dropdown-content mb-5">
                                    <div className="card-header d-flex align-items-center">
                                      <p className="fs-3">
                                        Find and match the transactions
                                      </p>
                                    </div>
                                    {/* Replace with your form and table */}
                                    <div className="card-body p-5">
                                      <>
                                        <form className="d-flex flex-row flex-end gap-5 mb-3">
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Refrence
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter reference id"
                                              name="reference"
                                              value={formData.reference}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Amount
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter amount"
                                              name="amount"
                                              value={formData.amount}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              alignSelf: "flex-end",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() =>
                                                handleSearch(
                                                  leftItem.reconId,
                                                  leftItem.txnType
                                                )
                                              }
                                            >
                                              Search
                                            </button>
                                          </div>
                                        </form>
                                        {error && (
                                          <div className="text-danger mt-2 text-end">
                                            {error}
                                          </div>
                                        )}
                                      </>
                                      <div className="card p-2">
                                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                                          <thead className="bg-gray-200">
                                            <tr className="text-start text-dark-600 fw-bold fs-6">
                                              <th className="w-10px"></th>
                                              <th className="min-w-100px p-5">
                                                Name
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Date
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Refrence
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody
                                            style={{
                                              maxHeight: "200px",
                                              overflow: "scroll",
                                            }}
                                          >
                                            {searchData &&
                                            searchData.length > 0 ? (
                                              searchData.map((item, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    onMouseOver={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "#f1f1f2";
                                                      // target.style.transform =
                                                      //   "translate3d(6px, -6px, 0)";
                                                      // target.style.boxShadow =
                                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                                    }}
                                                    onMouseOut={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "white";
                                                      // target.style.transform = "none";
                                                      // target.style.boxShadow = "none";
                                                    }}
                                                  >
                                                    <td className="p-5">
                                                      <div className="form-check form-check-custom form-check-sm">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input cursor-pointer border border-dark"
                                                          onClick={(e) =>
                                                            handleCheckClick(
                                                              e,
                                                              +item.amount,
                                                              leftItem.reconId,
                                                              item.txnId
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="p-5 text-gray-600 position-relative">
                                                      {item.benefName
                                                        ? item.benefName
                                                        : item.benefAccountNumber}{" "}
                                                      <i
                                                        className="bi bi-info-circle text-primary cursor-pointer"
                                                        // onClick={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOver={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOut={() =>
                                                        //   toggleDropdown(-1)
                                                        // }
                                                      ></i>
                                                      {openDropdownIndex ===
                                                        index && (
                                                        <div
                                                          className="position-absolute start-100 top-0 bg-white border rounded shadow-sm p-3"
                                                          style={{
                                                            width: "300px",
                                                            zIndex: 1000,
                                                          }}
                                                        >
                                                          <div className="d-flex flex-column gap-3">
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p
                                                                className="fs-7 fw-semibold"
                                                                style={{
                                                                  width: "40%",
                                                                }}
                                                              >
                                                                Beneficiary
                                                                account number
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.benefAccountNumber
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Beneficiary name
                                                              </p>
                                                              <p
                                                                className="fs-7 text-wrap"
                                                                style={{
                                                                  wordBreak:
                                                                    "break-word",
                                                                }}
                                                              >
                                                                {item.benefName
                                                                  ? item.benefName
                                                                  : item.benefAccountNumber}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction ID
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnId}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction Type
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnType}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Date
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.date}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Amount
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.totalAmount
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Status
                                                              </p>
                                                              <p
                                                                className={`fs-7 text-${
                                                                  item.status ===
                                                                  "Success"
                                                                    ? "success"
                                                                    : "danger"
                                                                }`}
                                                              >
                                                                {item.status}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.date}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.txnId}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.currency}{" "}
                                                      {Number(
                                                        item?.amount
                                                      )?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan={6}
                                                  className="text-center"
                                                >
                                                  No Data Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="card p-2">
                                        <p className="fs-3 fw-bold text-decoration-underline">
                                          The sum of your selected transactions
                                          must match the money spent
                                        </p>
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                          <div className="">
                                            <p className="fw-bold fs-5">
                                              Amount spent : {baseCurrency}{" "}
                                              {Number(
                                                leftItem.amount
                                              )?.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="fw-bold fs-5">
                                              Amount selected : {baseCurrency}{" "}
                                              {selectedAmount?.toLocaleString(
                                                "en-US",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </p>
                                          </div>
                                          <div className="">
                                            {/* {Number(tran?.amount) !== selectedAmount ? (
                              <p className="text-danger">
                                Selected amount is less by: {baseCurrency}{" "}
                                {(
                                  Number(tran?.amount) - selectedAmount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <div className="d-flex flex-row">
                                <p className="text-success">
                                  Selected amount matched
                                </p>
                              
                              </div>
                            )} */}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-end gap-5">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={selectedAmount < 1}
                                            onClick={() => {
                                              handleReconcile();
                                              // Find the matching Txn object based on reconId
                                              // const matchingTxn = txnIds.find(
                                              //   (txn) =>
                                              //     txn.reconId === tran.reconId
                                              // );
                                              // if (!matchingTxn) {
                                              //   console.error(
                                              //     `No matching transaction found for reconId: ${tran.reconId}`
                                              //   );
                                              //   return;
                                              // }
                                              // reconcile(
                                              //   tran.reconId,
                                              //   matchingTxn.txnIds
                                              // );
                                            }}
                                          >
                                            Reconcile
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={reset}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6">
                      {/* Left Side Headers */}
                      {/* <th className="w-10px"></th> */}
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>

                      <th className="min-w-100px p-5 fs-7">Ref. ID</th>
                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderRight: "1px solid black" }}
                      >
                        Amount
                      </th>

                      {/* Right Side Headers */}
                      <th className="w-10px"></th>
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>
                      <th className="min-w-100px p-5 fs-7">Txn Desc.</th>
                      <th className="min-w-100px p-5 fs-7">Amount</th>

                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderLeft: "1px solid black" }}
                      >
                        Status
                      </th>
                      <th className="min-w-100px p-5 fs-7">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((leftItem, leftIndex) => {
                        const hasRightData = leftItem.txnModels.length > 0;

                        return (
                          <React.Fragment key={leftIndex}>
                            {hasRightData ? (
                              leftItem.txnModels.map(
                                (rightItem, rightIndex) => (
                                  <React.Fragment
                                    key={`${leftIndex}-${rightIndex}`}
                                  >
                                    <tr className="align-top">
                                      {rightIndex === 0 ? (
                                        <>
                                          {/* <td className="p-5">
                                            <div className="form-check form-check-custom form-check-sm">
                                              {leftItem.txnModels.length > 1 ? (
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input cursor-pointer border border-dark"
                                                  onClick={(e) => {
                                                    handleLeftCheckClick(
                                                      e,
                                                      leftItem.reconId
                                                    );
                                                  }}
                                                  disabled={
                                                    reconIDs.length === 1 &&
                                                    txnIDs.length > 1
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.date}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.payeeName}
                                          </td>

                                          {/* <td
                                        rowSpan={leftItem.txnModels.length}
                                        className="p-5 text-gray-600 fs-7"
                                      >
                                        {leftItem.description}
                                      </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.refrenceNo}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderRight: "1px solid black",
                                            }}
                                          >
                                            {leftItem.amount}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          {/* Empty cells to maintain alignment when left data is not rendered */}
                                          {/* <td style={{ width: "1px" }}></td> */}
                                        </>
                                      )}

                                      <td className="p-5">
                                        <div className="form-check form-check-custom form-check-sm">
                                          {leftItem.status !== "Reconciled" ? (
                                            <input
                                              type="checkbox"
                                              className="form-check-input cursor-pointer border border-dark"
                                              onClick={(e) => {
                                                handleRightCheckClick(
                                                  e,
                                                  rightItem.txnId
                                                );
                                                handleCheckClick(
                                                  e,
                                                  +leftItem.amount,
                                                  leftItem.reconId,
                                                  rightItem.txnId
                                                );
                                              }}
                                              disabled={
                                                txnIds.length > 0 &&
                                                txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.date}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.senderName}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.txnType}
                                      </td>

                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.totalAmount}
                                      </td>

                                      {rightIndex === 0 ? (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          >
                                            {rightItem?.status && (
                                              <span
                                                className={`badge badge-light-${
                                                  leftItem.status ===
                                                  "Reconciled"
                                                    ? "primary"
                                                    : "info"
                                                } p-2`}
                                                style={{
                                                  fontWeight: "normal",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {leftItem.status}
                                              </span>
                                            )}
                                            {}
                                          </td>
                                          <td className="p-5 text-gray-600 fs-7">
                                            {leftItem.status ===
                                            "Reconciled" ? (
                                              <button
                                                className="btn btn-primary btn-sm fs-7 px-6 py-1"
                                                onClick={() => {
                                                  handleReverse(
                                                    leftItem?.reconId,
                                                    leftItem?.txnModels
                                                      ?.length > 1
                                                      ? leftItem?.txnModels?.flatMap(
                                                          (model) => model.txnId
                                                        ) || []
                                                      : [rightItem?.txnId]
                                                  );
                                                }}
                                              >
                                                Reverse
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-info btn-sm fs-7 px-5 py-1"
                                                disabled={
                                                  txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                                }
                                                onClick={() =>
                                                  handleReconcile()
                                                }
                                              >
                                                Reconcile
                                              </button>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          ></td>
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <tr className="align-top">
                                {/* Left Side */}
                                {/* <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    <input
                                      type="checkbox"
                                      className="form-check-input cursor-pointer border border-dark"
                                      onClick={(e) =>
                                        handleLeftCheckClick(
                                          e,
                                          leftItem.reconId
                                        )
                                      }
                                      disabled={
                                        reconIDs.length === 1 &&
                                        txnIDs.length > 1
                                      }
                                    />
                                  </div>
                                </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.date}
                                </td>
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.payeeName}
                                </td>

                                {/* <td className="p-5 text-gray-600 fs-7">
                                {leftItem.description}
                              </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.refrenceNo}
                                </td>
                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderRight: "1px solid black" }}
                                >
                                  {leftItem.amount}
                                </td>

                                {/* Right Side (show "Find & Match" button when no data) */}
                                <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    {/* <input
                                    type="checkbox"
                                    className="form-check-input cursor-pointer border border-dark"
                                    // onClick={(e) =>
                                    //   handleCheckClick(
                                    //     e,
                                    //     +item.amount,
                                    //     tran.reconId,
                                    //     item.txnId
                                    //   )
                                    // }
                                  /> */}
                                  </div>
                                </td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>

                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderLeft: "1px solid black" }}
                                >
                                  <span
                                    className={`badge badge-light-dark p-2`}
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {leftItem.status}
                                  </span>
                                </td>
                                <td className="py-5 text-gray-600 fs-7 d-flex px-3">
                                  <button
                                    className="btn btn-sm btn-secondary fs-7 px-3 py-1 text-nowrap"
                                    style={{ maxHeight: "25px" }}
                                    onClick={() => {
                                      reset();
                                      handleDropRowClick(leftIndex);
                                    }}
                                  >
                                    Find & Match
                                  </button>
                                </td>
                              </tr>
                            )}

                            {/* Conditionally render dropdown */}
                            {selectedRow === leftIndex && (
                              <tr>
                                <td colSpan={12} className="ms-10">
                                  <div className="card shadow dropdown-content mb-5">
                                    <div className="card-header d-flex align-items-center">
                                      <p className="fs-3">
                                        Find and match the transactions
                                      </p>
                                    </div>
                                    {/* Replace with your form and table */}
                                    <div className="card-body p-5">
                                      <>
                                        <form className="d-flex flex-row flex-end gap-5 mb-3">
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Refrence
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter reference id"
                                              name="reference"
                                              value={formData.reference}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Amount
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter amount"
                                              name="amount"
                                              value={formData.amount}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              alignSelf: "flex-end",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() =>
                                                handleSearch(
                                                  leftItem.reconId,
                                                  leftItem.txnType
                                                )
                                              }
                                            >
                                              Search
                                            </button>
                                          </div>
                                        </form>
                                        {error && (
                                          <div className="text-danger mt-2 text-end">
                                            {error}
                                          </div>
                                        )}
                                      </>
                                      <div className="card p-2">
                                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                                          <thead className="bg-gray-200">
                                            <tr className="text-start text-dark-600 fw-bold fs-6">
                                              <th className="w-10px"></th>
                                              <th className="min-w-100px p-5">
                                                Name
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Date
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Refrence
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody
                                            style={{
                                              maxHeight: "200px",
                                              overflow: "scroll",
                                            }}
                                          >
                                            {searchData &&
                                            searchData.length > 0 ? (
                                              searchData.map((item, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    onMouseOver={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "#f1f1f2";
                                                      // target.style.transform =
                                                      //   "translate3d(6px, -6px, 0)";
                                                      // target.style.boxShadow =
                                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                                    }}
                                                    onMouseOut={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "white";
                                                      // target.style.transform = "none";
                                                      // target.style.boxShadow = "none";
                                                    }}
                                                  >
                                                    <td className="p-5">
                                                      <div className="form-check form-check-custom form-check-sm">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input cursor-pointer border border-dark"
                                                          onClick={(e) =>
                                                            handleCheckClick(
                                                              e,
                                                              +item.amount,
                                                              leftItem.reconId,
                                                              item.txnId
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="p-5 text-gray-600 position-relative">
                                                      {item.benefName
                                                        ? item.benefName
                                                        : item.benefAccountNumber}{" "}
                                                      <i
                                                        className="bi bi-info-circle text-primary cursor-pointer"
                                                        // onClick={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOver={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOut={() =>
                                                        //   toggleDropdown(-1)
                                                        // }
                                                      ></i>
                                                      {openDropdownIndex ===
                                                        index && (
                                                        <div
                                                          className="position-absolute start-100 top-0 bg-white border rounded shadow-sm p-3"
                                                          style={{
                                                            width: "300px",
                                                            zIndex: 1000,
                                                          }}
                                                        >
                                                          <div className="d-flex flex-column gap-3">
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p
                                                                className="fs-7 fw-semibold"
                                                                style={{
                                                                  width: "40%",
                                                                }}
                                                              >
                                                                Beneficiary
                                                                account number
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.benefAccountNumber
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Beneficiary name
                                                              </p>
                                                              <p
                                                                className="fs-7 text-wrap"
                                                                style={{
                                                                  wordBreak:
                                                                    "break-word",
                                                                }}
                                                              >
                                                                {item.benefName
                                                                  ? item.benefName
                                                                  : item.benefAccountNumber}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction ID
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnId}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction Type
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnType}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Date
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.date}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Amount
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.totalAmount
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Status
                                                              </p>
                                                              <p
                                                                className={`fs-7 text-${
                                                                  item.status ===
                                                                  "Success"
                                                                    ? "success"
                                                                    : "danger"
                                                                }`}
                                                              >
                                                                {item.status}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.date}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.txnId}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.currency}{" "}
                                                      {Number(
                                                        item?.amount
                                                      )?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan={6}
                                                  className="text-center"
                                                >
                                                  No Data Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="card p-2">
                                        <p className="fs-3 fw-bold text-decoration-underline">
                                          The sum of your selected transactions
                                          must match the money spent
                                        </p>
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                          <div className="">
                                            <p className="fw-bold fs-5">
                                              Amount spent : {baseCurrency}{" "}
                                              {Number(
                                                leftItem.amount
                                              )?.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="fw-bold fs-5">
                                              Amount selected : {baseCurrency}{" "}
                                              {selectedAmount?.toLocaleString(
                                                "en-US",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </p>
                                          </div>
                                          <div className="">
                                            {/* {Number(tran?.amount) !== selectedAmount ? (
                              <p className="text-danger">
                                Selected amount is less by: {baseCurrency}{" "}
                                {(
                                  Number(tran?.amount) - selectedAmount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <div className="d-flex flex-row">
                                <p className="text-success">
                                  Selected amount matched
                                </p>
                              
                              </div>
                            )} */}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-end gap-5">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={selectedAmount < 1}
                                            onClick={() => {
                                              handleReconcile();
                                              // Find the matching Txn object based on reconId
                                              // const matchingTxn = txnIds.find(
                                              //   (txn) =>
                                              //     txn.reconId === tran.reconId
                                              // );
                                              // if (!matchingTxn) {
                                              //   console.error(
                                              //     `No matching transaction found for reconId: ${tran.reconId}`
                                              //   );
                                              //   return;
                                              // }
                                              // reconcile(
                                              //   tran.reconId,
                                              //   matchingTxn.txnIds
                                              // );
                                            }}
                                          >
                                            Reconcile
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={reset}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6">
                      {/* Left Side Headers */}
                      {/* <th className="w-10px"></th> */}
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>

                      <th className="min-w-100px p-5 fs-7">Ref. ID</th>
                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderRight: "1px solid black" }}
                      >
                        Amount
                      </th>

                      {/* Right Side Headers */}
                      <th className="w-10px"></th>
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>
                      <th className="min-w-100px p-5 fs-7">Txn Desc.</th>
                      <th className="min-w-100px p-5 fs-7">Amount</th>

                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderLeft: "1px solid black" }}
                      >
                        Status
                      </th>
                      <th className="min-w-100px p-5 fs-7">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((leftItem, leftIndex) => {
                        const hasRightData = leftItem.txnModels.length > 0;

                        return (
                          <React.Fragment key={leftIndex}>
                            {hasRightData ? (
                              leftItem.txnModels.map(
                                (rightItem, rightIndex) => (
                                  <React.Fragment
                                    key={`${leftIndex}-${rightIndex}`}
                                  >
                                    <tr className="align-top">
                                      {rightIndex === 0 ? (
                                        <>
                                          {/* <td className="p-5">
                                            <div className="form-check form-check-custom form-check-sm">
                                              {leftItem.txnModels.length > 1 ? (
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input cursor-pointer border border-dark"
                                                  onClick={(e) => {
                                                    handleLeftCheckClick(
                                                      e,
                                                      leftItem.reconId
                                                    );
                                                  }}
                                                  disabled={
                                                    reconIDs.length === 1 &&
                                                    txnIDs.length > 1
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.date}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.payeeName}
                                          </td>

                                          {/* <td
                                        rowSpan={leftItem.txnModels.length}
                                        className="p-5 text-gray-600 fs-7"
                                      >
                                        {leftItem.description}
                                      </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.refrenceNo}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderRight: "1px solid black",
                                            }}
                                          >
                                            {leftItem.amount}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          {/* Empty cells to maintain alignment when left data is not rendered */}
                                          {/* <td style={{ width: "1px" }}></td> */}
                                        </>
                                      )}

                                      <td className="p-5">
                                        <div className="form-check form-check-custom form-check-sm">
                                          {leftItem.status !== "Reconciled" ? (
                                            <input
                                              type="checkbox"
                                              className="form-check-input cursor-pointer border border-dark"
                                              onClick={(e) => {
                                                handleRightCheckClick(
                                                  e,
                                                  rightItem.txnId
                                                );
                                                handleCheckClick(
                                                  e,
                                                  +leftItem.amount,
                                                  leftItem.reconId,
                                                  rightItem.txnId
                                                );
                                              }}
                                              disabled={
                                                txnIds.length > 0 &&
                                                txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.date}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.senderName}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.txnType}
                                      </td>

                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.totalAmount}
                                      </td>

                                      {rightIndex === 0 ? (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          >
                                            {rightItem?.status && (
                                              <span
                                                className={`badge badge-light-${
                                                  leftItem.status ===
                                                  "Reconciled"
                                                    ? "primary"
                                                    : "info"
                                                } p-2`}
                                                style={{
                                                  fontWeight: "normal",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {leftItem.status}
                                              </span>
                                            )}
                                            {}
                                          </td>
                                          <td className="p-5 text-gray-600 fs-7">
                                            {leftItem.status ===
                                            "Reconciled" ? (
                                              <button
                                                className="btn btn-primary btn-sm fs-7 px-6 py-1"
                                                onClick={() => {
                                                  handleReverse(
                                                    leftItem?.reconId,
                                                    leftItem?.txnModels
                                                      ?.length > 1
                                                      ? leftItem?.txnModels?.flatMap(
                                                          (model) => model.txnId
                                                        ) || []
                                                      : [rightItem?.txnId]
                                                  );
                                                }}
                                              >
                                                Reverse
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-info btn-sm fs-7 px-5 py-1"
                                                disabled={
                                                  txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                                }
                                                onClick={() =>
                                                  handleReconcile()
                                                }
                                              >
                                                Reconcile
                                              </button>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          ></td>
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <tr className="align-top">
                                {/* Left Side */}
                                {/* <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    <input
                                      type="checkbox"
                                      className="form-check-input cursor-pointer border border-dark"
                                      onClick={(e) =>
                                        handleLeftCheckClick(
                                          e,
                                          leftItem.reconId
                                        )
                                      }
                                      disabled={
                                        reconIDs.length === 1 &&
                                        txnIDs.length > 1
                                      }
                                    />
                                  </div>
                                </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.date}
                                </td>
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.payeeName}
                                </td>

                                {/* <td className="p-5 text-gray-600 fs-7">
                                {leftItem.description}
                              </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.refrenceNo}
                                </td>
                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderRight: "1px solid black" }}
                                >
                                  {leftItem.amount}
                                </td>

                                {/* Right Side (show "Find & Match" button when no data) */}
                                <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    {/* <input
                                    type="checkbox"
                                    className="form-check-input cursor-pointer border border-dark"
                                    // onClick={(e) =>
                                    //   handleCheckClick(
                                    //     e,
                                    //     +item.amount,
                                    //     tran.reconId,
                                    //     item.txnId
                                    //   )
                                    // }
                                  /> */}
                                  </div>
                                </td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>

                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderLeft: "1px solid black" }}
                                >
                                  <span
                                    className={`badge badge-light-dark p-2`}
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {leftItem.status}
                                  </span>
                                </td>
                                <td className="py-5 text-gray-600 fs-7 d-flex px-3">
                                  <button
                                    className="btn btn-sm btn-secondary fs-7 px-3 py-1 text-nowrap"
                                    style={{ maxHeight: "25px" }}
                                    onClick={() => {
                                      reset();
                                      handleDropRowClick(leftIndex);
                                    }}
                                  >
                                    Find & Match
                                  </button>
                                </td>
                              </tr>
                            )}

                            {/* Conditionally render dropdown */}
                            {selectedRow === leftIndex && (
                              <tr>
                                <td colSpan={12} className="ms-10">
                                  <div className="card shadow dropdown-content mb-5">
                                    <div className="card-header d-flex align-items-center">
                                      <p className="fs-3">
                                        Find and match the transactions
                                      </p>
                                    </div>
                                    {/* Replace with your form and table */}
                                    <div className="card-body p-5">
                                      <>
                                        <form className="d-flex flex-row flex-end gap-5 mb-3">
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Refrence
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter reference id"
                                              name="reference"
                                              value={formData.reference}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Amount
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter amount"
                                              name="amount"
                                              value={formData.amount}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              alignSelf: "flex-end",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() =>
                                                handleSearch(
                                                  leftItem.reconId,
                                                  leftItem.txnType
                                                )
                                              }
                                            >
                                              Search
                                            </button>
                                          </div>
                                        </form>
                                        {error && (
                                          <div className="text-danger mt-2 text-end">
                                            {error}
                                          </div>
                                        )}
                                      </>
                                      <div className="card p-2">
                                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                                          <thead className="bg-gray-200">
                                            <tr className="text-start text-dark-600 fw-bold fs-6">
                                              <th className="w-10px"></th>
                                              <th className="min-w-100px p-5">
                                                Name
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Date
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Refrence
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody
                                            style={{
                                              maxHeight: "200px",
                                              overflow: "scroll",
                                            }}
                                          >
                                            {searchData &&
                                            searchData.length > 0 ? (
                                              searchData.map((item, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    onMouseOver={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "#f1f1f2";
                                                      // target.style.transform =
                                                      //   "translate3d(6px, -6px, 0)";
                                                      // target.style.boxShadow =
                                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                                    }}
                                                    onMouseOut={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "white";
                                                      // target.style.transform = "none";
                                                      // target.style.boxShadow = "none";
                                                    }}
                                                  >
                                                    <td className="p-5">
                                                      <div className="form-check form-check-custom form-check-sm">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input cursor-pointer border border-dark"
                                                          onClick={(e) =>
                                                            handleCheckClick(
                                                              e,
                                                              +item.amount,
                                                              leftItem.reconId,
                                                              item.txnId
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="p-5 text-gray-600 position-relative">
                                                      {item.benefName
                                                        ? item.benefName
                                                        : item.benefAccountNumber}{" "}
                                                      <i
                                                        className="bi bi-info-circle text-primary cursor-pointer"
                                                        // onClick={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOver={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOut={() =>
                                                        //   toggleDropdown(-1)
                                                        // }
                                                      ></i>
                                                      {openDropdownIndex ===
                                                        index && (
                                                        <div
                                                          className="position-absolute start-100 top-0 bg-white border rounded shadow-sm p-3"
                                                          style={{
                                                            width: "300px",
                                                            zIndex: 1000,
                                                          }}
                                                        >
                                                          <div className="d-flex flex-column gap-3">
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p
                                                                className="fs-7 fw-semibold"
                                                                style={{
                                                                  width: "40%",
                                                                }}
                                                              >
                                                                Beneficiary
                                                                account number
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.benefAccountNumber
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Beneficiary name
                                                              </p>
                                                              <p
                                                                className="fs-7 text-wrap"
                                                                style={{
                                                                  wordBreak:
                                                                    "break-word",
                                                                }}
                                                              >
                                                                {item.benefName
                                                                  ? item.benefName
                                                                  : item.benefAccountNumber}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction ID
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnId}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction Type
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnType}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Date
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.date}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Amount
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.totalAmount
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Status
                                                              </p>
                                                              <p
                                                                className={`fs-7 text-${
                                                                  item.status ===
                                                                  "Success"
                                                                    ? "success"
                                                                    : "danger"
                                                                }`}
                                                              >
                                                                {item.status}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.date}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.txnId}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.currency}{" "}
                                                      {Number(
                                                        item?.amount
                                                      )?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan={6}
                                                  className="text-center"
                                                >
                                                  No Data Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="card p-2">
                                        <p className="fs-3 fw-bold text-decoration-underline">
                                          The sum of your selected transactions
                                          must match the money spent
                                        </p>
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                          <div className="">
                                            <p className="fw-bold fs-5">
                                              Amount spent : {baseCurrency}{" "}
                                              {Number(
                                                leftItem.amount
                                              )?.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="fw-bold fs-5">
                                              Amount selected : {baseCurrency}{" "}
                                              {selectedAmount?.toLocaleString(
                                                "en-US",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </p>
                                          </div>
                                          <div className="">
                                            {/* {Number(tran?.amount) !== selectedAmount ? (
                              <p className="text-danger">
                                Selected amount is less by: {baseCurrency}{" "}
                                {(
                                  Number(tran?.amount) - selectedAmount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <div className="d-flex flex-row">
                                <p className="text-success">
                                  Selected amount matched
                                </p>
                              
                              </div>
                            )} */}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-end gap-5">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={selectedAmount < 1}
                                            onClick={() => {
                                              handleReconcile();
                                              // Find the matching Txn object based on reconId
                                              // const matchingTxn = txnIds.find(
                                              //   (txn) =>
                                              //     txn.reconId === tran.reconId
                                              // );
                                              // if (!matchingTxn) {
                                              //   console.error(
                                              //     `No matching transaction found for reconId: ${tran.reconId}`
                                              //   );
                                              //   return;
                                              // }
                                              // reconcile(
                                              //   tran.reconId,
                                              //   matchingTxn.txnIds
                                              // );
                                            }}
                                          >
                                            Reconcile
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={reset}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6">
                      {/* Left Side Headers */}
                      {/* <th className="w-10px"></th> */}
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>

                      <th className="min-w-100px p-5 fs-7">Ref. ID</th>
                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderRight: "1px solid black" }}
                      >
                        Amount
                      </th>

                      {/* Right Side Headers */}
                      <th className="w-10px"></th>
                      <th className="min-w-100px p-5 fs-7">Txn Date</th>
                      <th className="min-w-100px p-5 fs-7">Payee Name</th>
                      <th className="min-w-100px p-5 fs-7">Txn Desc.</th>
                      <th className="min-w-100px p-5 fs-7">Amount</th>

                      <th
                        className="min-w-100px p-5 fs-7"
                        style={{ borderLeft: "1px solid black" }}
                      >
                        Status
                      </th>
                      <th className="min-w-100px p-5 fs-7">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((leftItem, leftIndex) => {
                        const hasRightData = leftItem.txnModels.length > 0;

                        return (
                          <React.Fragment key={leftIndex}>
                            {hasRightData ? (
                              leftItem.txnModels.map(
                                (rightItem, rightIndex) => (
                                  <React.Fragment
                                    key={`${leftIndex}-${rightIndex}`}
                                  >
                                    <tr className="align-top">
                                      {rightIndex === 0 ? (
                                        <>
                                          {/* <td className="p-5">
                                            <div className="form-check form-check-custom form-check-sm">
                                              {leftItem.txnModels.length > 1 ? (
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input cursor-pointer border border-dark"
                                                  onClick={(e) => {
                                                    handleLeftCheckClick(
                                                      e,
                                                      leftItem.reconId
                                                    );
                                                  }}
                                                  disabled={
                                                    reconIDs.length === 1 &&
                                                    txnIDs.length > 1
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.date}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.payeeName}
                                          </td>

                                          {/* <td
                                        rowSpan={leftItem.txnModels.length}
                                        className="p-5 text-gray-600 fs-7"
                                      >
                                        {leftItem.description}
                                      </td> */}
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                          >
                                            {leftItem.refrenceNo}
                                          </td>
                                          <td
                                            rowSpan={leftItem.txnModels.length}
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderRight: "1px solid black",
                                            }}
                                          >
                                            {leftItem.amount}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          {/* Empty cells to maintain alignment when left data is not rendered */}
                                          {/* <td style={{ width: "1px" }}></td> */}
                                        </>
                                      )}

                                      <td className="p-5">
                                        <div className="form-check form-check-custom form-check-sm">
                                          {leftItem.status !== "Reconciled" ? (
                                            <input
                                              type="checkbox"
                                              className="form-check-input cursor-pointer border border-dark"
                                              onClick={(e) => {
                                                handleRightCheckClick(
                                                  e,
                                                  rightItem.txnId
                                                );
                                                handleCheckClick(
                                                  e,
                                                  +leftItem.amount,
                                                  leftItem.reconId,
                                                  rightItem.txnId
                                                );
                                              }}
                                              disabled={
                                                txnIds.length > 0 &&
                                                txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.date}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.senderName}
                                      </td>
                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.txnType}
                                      </td>

                                      <td className="p-5 text-gray-600 fs-7">
                                        {rightItem.totalAmount}
                                      </td>

                                      {rightIndex === 0 ? (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          >
                                            {rightItem?.status && (
                                              <span
                                                className={`badge badge-light-${
                                                  leftItem.status ===
                                                  "Reconciled"
                                                    ? "primary"
                                                    : "info"
                                                } p-2`}
                                                style={{
                                                  fontWeight: "normal",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {leftItem.status}
                                              </span>
                                            )}
                                            {}
                                          </td>
                                          <td className="p-5 text-gray-600 fs-7">
                                            {leftItem.status ===
                                            "Reconciled" ? (
                                              <button
                                                className="btn btn-primary btn-sm fs-7 px-6 py-1"
                                                onClick={() => {
                                                  handleReverse(
                                                    leftItem?.reconId,
                                                    leftItem?.txnModels
                                                      ?.length > 1
                                                      ? leftItem?.txnModels?.flatMap(
                                                          (model) => model.txnId
                                                        ) || []
                                                      : [rightItem?.txnId]
                                                  );
                                                }}
                                              >
                                                Reverse
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-info btn-sm fs-7 px-5 py-1"
                                                disabled={
                                                  txnIds[0]?.reconId !==
                                                  leftItem?.reconId
                                                }
                                                onClick={() =>
                                                  handleReconcile()
                                                }
                                              >
                                                Reconcile
                                              </button>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            className="p-5 text-gray-600 fs-7"
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          ></td>
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <tr className="align-top">
                                {/* Left Side */}
                                {/* <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    <input
                                      type="checkbox"
                                      className="form-check-input cursor-pointer border border-dark"
                                      onClick={(e) =>
                                        handleLeftCheckClick(
                                          e,
                                          leftItem.reconId
                                        )
                                      }
                                      disabled={
                                        reconIDs.length === 1 &&
                                        txnIDs.length > 1
                                      }
                                    />
                                  </div>
                                </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.date}
                                </td>
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.payeeName}
                                </td>

                                {/* <td className="p-5 text-gray-600 fs-7">
                                {leftItem.description}
                              </td> */}
                                <td className="p-5 text-gray-600 fs-7">
                                  {leftItem.refrenceNo}
                                </td>
                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderRight: "1px solid black" }}
                                >
                                  {leftItem.amount}
                                </td>

                                {/* Right Side (show "Find & Match" button when no data) */}
                                <td className="p-5">
                                  <div className="form-check form-check-custom form-check-sm">
                                    {/* <input
                                    type="checkbox"
                                    className="form-check-input cursor-pointer border border-dark"
                                    // onClick={(e) =>
                                    //   handleCheckClick(
                                    //     e,
                                    //     +item.amount,
                                    //     tran.reconId,
                                    //     item.txnId
                                    //   )
                                    // }
                                  /> */}
                                  </div>
                                </td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>
                                <td className="p-5 text-gray-600 fs-7"></td>

                                <td
                                  className="p-5 text-gray-600 fs-7"
                                  style={{ borderLeft: "1px solid black" }}
                                >
                                  <span
                                    className={`badge badge-light-dark p-2`}
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {leftItem.status}
                                  </span>
                                </td>
                                <td className="py-5 text-gray-600 fs-7 d-flex px-3">
                                  <button
                                    className="btn btn-sm btn-secondary fs-7 px-3 py-1 text-nowrap"
                                    style={{ maxHeight: "25px" }}
                                    onClick={() => {
                                      reset();
                                      handleDropRowClick(leftIndex);
                                    }}
                                  >
                                    Find & Match
                                  </button>
                                </td>
                              </tr>
                            )}

                            {/* Conditionally render dropdown */}
                            {selectedRow === leftIndex && (
                              <tr>
                                <td colSpan={12} className="ms-10">
                                  <div className="card shadow dropdown-content mb-5">
                                    <div className="card-header d-flex align-items-center">
                                      <p className="fs-3">
                                        Find and match the transactions
                                      </p>
                                    </div>
                                    {/* Replace with your form and table */}
                                    <div className="card-body p-5">
                                      <>
                                        <form className="d-flex flex-row flex-end gap-5 mb-3">
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Refrence
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter reference id"
                                              name="reference"
                                              value={formData.reference}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="fw-bold text-gray-600">
                                              Amount
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter amount"
                                              name="amount"
                                              value={formData.amount}
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              alignSelf: "flex-end",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() =>
                                                handleSearch(
                                                  leftItem.reconId,
                                                  leftItem.txnType
                                                )
                                              }
                                            >
                                              Search
                                            </button>
                                          </div>
                                        </form>
                                        {error && (
                                          <div className="text-danger mt-2 text-end">
                                            {error}
                                          </div>
                                        )}
                                      </>
                                      <div className="card p-2">
                                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                                          <thead className="bg-gray-200">
                                            <tr className="text-start text-dark-600 fw-bold fs-6">
                                              <th className="w-10px"></th>
                                              <th className="min-w-100px p-5">
                                                Name
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Date
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Refrence
                                              </th>
                                              <th className="min-w-100px p-5">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody
                                            style={{
                                              maxHeight: "200px",
                                              overflow: "scroll",
                                            }}
                                          >
                                            {searchData &&
                                            searchData.length > 0 ? (
                                              searchData.map((item, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    onMouseOver={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "#f1f1f2";
                                                      // target.style.transform =
                                                      //   "translate3d(6px, -6px, 0)";
                                                      // target.style.boxShadow =
                                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                                    }}
                                                    onMouseOut={(e) => {
                                                      const target =
                                                        e.currentTarget as HTMLElement;
                                                      target.style.backgroundColor =
                                                        "white";
                                                      // target.style.transform = "none";
                                                      // target.style.boxShadow = "none";
                                                    }}
                                                  >
                                                    <td className="p-5">
                                                      <div className="form-check form-check-custom form-check-sm">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input cursor-pointer border border-dark"
                                                          onClick={(e) =>
                                                            handleCheckClick(
                                                              e,
                                                              +item.amount,
                                                              leftItem.reconId,
                                                              item.txnId
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="p-5 text-gray-600 position-relative">
                                                      {item.benefName
                                                        ? item.benefName
                                                        : item.benefAccountNumber}{" "}
                                                      <i
                                                        className="bi bi-info-circle text-primary cursor-pointer"
                                                        // onClick={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOver={() =>
                                                        //   toggleDropdown(index)
                                                        // }
                                                        // onMouseOut={() =>
                                                        //   toggleDropdown(-1)
                                                        // }
                                                      ></i>
                                                      {openDropdownIndex ===
                                                        index && (
                                                        <div
                                                          className="position-absolute start-100 top-0 bg-white border rounded shadow-sm p-3"
                                                          style={{
                                                            width: "300px",
                                                            zIndex: 1000,
                                                          }}
                                                        >
                                                          <div className="d-flex flex-column gap-3">
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p
                                                                className="fs-7 fw-semibold"
                                                                style={{
                                                                  width: "40%",
                                                                }}
                                                              >
                                                                Beneficiary
                                                                account number
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.benefAccountNumber
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Beneficiary name
                                                              </p>
                                                              <p
                                                                className="fs-7 text-wrap"
                                                                style={{
                                                                  wordBreak:
                                                                    "break-word",
                                                                }}
                                                              >
                                                                {item.benefName
                                                                  ? item.benefName
                                                                  : item.benefAccountNumber}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction ID
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnId}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Transaction Type
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.txnType}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Date
                                                              </p>
                                                              <p className="fs-7">
                                                                {item.date}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Amount
                                                              </p>
                                                              <p className="fs-7">
                                                                {
                                                                  item.totalAmount
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between gap-5">
                                                              <p className="fs-7 fw-semibold">
                                                                Status
                                                              </p>
                                                              <p
                                                                className={`fs-7 text-${
                                                                  item.status ===
                                                                  "Success"
                                                                    ? "success"
                                                                    : "danger"
                                                                }`}
                                                              >
                                                                {item.status}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.date}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.txnId}
                                                    </td>
                                                    <td className="p-5 text-gray-600">
                                                      {item.currency}{" "}
                                                      {Number(
                                                        item?.amount
                                                      )?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan={6}
                                                  className="text-center"
                                                >
                                                  No Data Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="card p-2">
                                        <p className="fs-3 fw-bold text-decoration-underline">
                                          The sum of your selected transactions
                                          must match the money spent
                                        </p>
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                          <div className="">
                                            <p className="fw-bold fs-5">
                                              Amount spent : {baseCurrency}{" "}
                                              {Number(
                                                leftItem.amount
                                              )?.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="fw-bold fs-5">
                                              Amount selected : {baseCurrency}{" "}
                                              {selectedAmount?.toLocaleString(
                                                "en-US",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </p>
                                          </div>
                                          <div className="">
                                            {/* {Number(tran?.amount) !== selectedAmount ? (
                              <p className="text-danger">
                                Selected amount is less by: {baseCurrency}{" "}
                                {(
                                  Number(tran?.amount) - selectedAmount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <div className="d-flex flex-row">
                                <p className="text-success">
                                  Selected amount matched
                                </p>
                              
                              </div>
                            )} */}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-end gap-5">
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={selectedAmount < 1}
                                            onClick={() => {
                                              handleReconcile();
                                              // Find the matching Txn object based on reconId
                                              // const matchingTxn = txnIds.find(
                                              //   (txn) =>
                                              //     txn.reconId === tran.reconId
                                              // );
                                              // if (!matchingTxn) {
                                              //   console.error(
                                              //     `No matching transaction found for reconId: ${tran.reconId}`
                                              //   );
                                              //   return;
                                              // }
                                              // reconcile(
                                              //   tran.reconId,
                                              //   matchingTxn.txnIds
                                              // );
                                            }}
                                          >
                                            Reconcile
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={reset}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="p-5">
                <ul className="pagination">
                  <li
                    className={`page-item double-arrow ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={0}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                    >
                      <i className="previous"></i>
                      <i className="previous"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item previous ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={1}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="previous"></i>
                    </a>
                  </li>
                  {renderPageNumbers()}
                  <li
                    className={`page-item next ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={2}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="next"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item double-arrow ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={3}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() =>
                        handlePageChange(
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                        )
                      }
                    >
                      <i className="next"></i>
                      <i className="next"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="p-5 d-flex align-items-center">
                <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                  Total: {filteredTransactions?.length}
                  &nbsp;&nbsp;
                </label>
                {/* <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  style={{
                    padding: "1px 2px 1px 2px",
                    cursor: "pointer",
                  }}
                >
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recon1;
