export const SteppperModal5 = () => {
    return (
        <div className="pb-5 card shadow-sm p-15" data-kt-stepper-element="content">
            <div className="w-100">

                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Confirmation!!</h1>
                    <div className="mb-10 d-flex align-items-center justify-content-center mt-5">
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input bg-success" type="checkbox" value="" id="flexCheckChecked" checked={true} />
                            <label className="form-check-label" form="flexCheckChecked">
                                <div className="text-dark fw-semibold fs-5 ">
                                    All data validated...
                                </div>

                            </label>
                        </div>
                    </div>

                    <div className="text-muted fw-semibold fs-5 ">
                        To proceed click on create
                    </div>
                </div>
            </div>
        </div>
    );
};