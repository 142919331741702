import { useState, useEffect, useRef } from "react";
import {
  useFormikContext,
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikProps,
} from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { subEditData } from "./EditData";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PinInput from "react-pin-input";
import { apiCall } from "../SubAccount";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";

interface FormValues {
  selectedGoal: string;
  selectedRadio: string;
  amount: string;
  percentage: string;
  frequency: string;
  frequencyValue: string;
}

interface Account {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  createdDate: string;
  balance: string;
}

interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  primaryFundId: string;
  accountType: string;
  subAccounts: [
    {
      currency: string;
      iconCode: string;
      goal: string;
      goalLimit: number;
      creditCondition: string;
      creditType: string;
      creditValue: number;
      creditFrequency: string;
      subAccountType: string;
      subAccountName: string;
      subAccountNumber: string;
      subAccountStatus: number;
      balance: number;
      createdDate: string;
    }
  ];
}

interface UpdateData {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  frequencyValue: number;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  balance: number;
  createdDate: string;
}

export function EditModal({
  passedData,
  accData,
}: {
  passedData: accDetails | null;
  accData: Account | null;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const modalRef1 = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const [data, setData] = useState<UpdateData | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const [enable, setEnable] = useState<boolean>(
    data?.goalLimit && data.goalLimit > 0 ? true : false
  );

  const [pinError, setPinError] = useState(false);
  const [txnPin, setTxnPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);
  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (pin.length === 4) {
        UpdateSubAccount();
      } else {
        setPinError(true);
      }
    }
  };

  const handlePaybillTxnPinClose = () => {
    setPinError(false);
    setTxnPin("");
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    const modalElement = modalRef1.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (modalRef1.current) {
      const modal = new Modal(modalRef1.current);

      modalRef1.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
      });

      modalRef1.current.addEventListener(
        "hidden.bs.modal",
        handlePaybillTxnPinClose
      );
      return () => {
        modalRef1.current?.removeEventListener(
          "hidden.bs.modal",
          handlePaybillTxnPinClose
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    setEnable(data?.goalLimit && data.goalLimit > 0 ? true : false);
  }, [data]);
  const ediVal = useFormikContext<FormValues>();
  const isClickable =
    ediVal?.values?.selectedGoal === "OPEN" ||
    (ediVal?.values?.selectedGoal === "SET_LIMIT" && ediVal?.values?.amount);

  const handleNextClick = () => {
    setActiveTab(activeTab + 1);
  };

  const initialValues: FormValues = {
    selectedGoal: data?.goal || "",
    selectedRadio: data?.creditCondition || "",
    amount: data?.goal === "OPEN" ? "" : data?.goalLimit.toString() || "",
    percentage:
      data?.creditCondition === "SCHEDULED"
        ? ""
        : data?.creditValue?.toString() || "",
    frequency: data?.creditFrequency || "",
    frequencyValue:
      data?.creditCondition === "ON_CREDIT"
        ? ""
        : data?.frequencyValue?.toString() || "",
  };

  const handlePin = () => {
    const modalElement = document.getElementById("editpin_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const editSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/findByAccountNumber`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    editSubAccount();
  }, []);

  const UpdateSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/updateSubAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ...subEditData,
        transactionPIN: txnPin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        editSubAccount();
        handleCloseModal();
        handlePaybillTxnPinClose();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Sub Account has been updated.",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        }).then((result) => {
          if (result.isConfirmed) {
            apiCall.submit();
          }
        });
      }
      if (data.status.statusCode === 1) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${data.status.messageDescription}`,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [passedData]);

  const handleCloseModal = () => {
    setActiveTab(1);
    formikRef?.current?.resetForm();
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .custom-input::-webkit-outer-spin-button,
          .custom-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      <div
        className="modal fade"
        tabIndex={-1}
        id="edit_modal"
        ref={modalRef}
          
      >
        <div
          className="modal-dialog w-350px"
          style={{ margin: "0 auto", marginTop: "8%" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between py-3">
              <h4 className="mb-0">Edit Sub Account</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                 <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div
              className="modal-body"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "200px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <>
                  <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 p-3 d-flex flex-nowrap">
                    <li className={`nav-item`} key={1}>
                      <a
                        className={`nav-link text-active-gray-800 ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_1"
                        onClick={() => {
                          setActiveTab(1);
                        }}
                      >
                        Goal
                      </a>
                    </li>
                    <li className={`nav-item`} key={2}>
                      <a
                        className={`nav-link text-active-gray-800 ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_2"
                        onClick={() => {
                          // Check if the link is clickable before setting the active tab
                          if (isClickable) {
                            setActiveTab(2);
                          }
                        }}
                        // Disable the link if it's not clickable
                        style={{
                          pointerEvents: enable ? "auto" : "none",
                          opacity: enable ? 1 : 0.5,
                        }}
                      >
                        Condition
                      </a>
                    </li>
                  </ul>
                  <div>
                    <Formik
                      innerRef={formikRef}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={Yup.object().shape({
                        selectedGoal: Yup.string().required(
                          "Please select a option"
                        ),
                        selectedRadio: Yup.string().required(
                          "Please select a option"
                        ),
                        amount: Yup.number()
                          .required("Please enter a amount")
                          .test(
                            "greater-than-zero",
                            "Amount should be greater than 0",
                            function (value) {
                              const selectedRadio = this.parent.selectedRadio;

                              if (selectedRadio === "SET_LIMIT") {
                                if (value === 0 || value === undefined) {
                                  setEnable(false);
                                } else {
                                  setEnable(true);
                                }
                                return value > 0;
                              }
                              return true;
                            }
                          )
                          .notRequired(),

                        percentage: Yup.number()
                          .required("Please enter a percentage")
                          .test(
                            "conditional-validation",
                            "Percentage should be greater than 0",
                            function (value) {
                              const selectedRadio = this.parent.selectedRadio;

                              if (selectedRadio === "ON_CREDIT") {
                                return value > 0;
                              }
                              return true;
                            }
                          )
                          .notRequired(),

                        frequency: Yup.string().test(
                          "frequency",
                          "Please select a frequency",
                          function (value) {
                            const selectedRadio = this.parent.selectedRadio;
                            if (selectedRadio === "SCHEDULED") {
                              return !!value;
                            }
                            return true;
                          }
                        ),
                        frequencyValue: Yup.string()
                          .required("Please enter an amount")
                          .test({
                            name: "greater-than-zero",
                            message: "Amount should be greater than 0",
                            test: function (value) {
                              const selectedRadio = this.parent.selectedRadio;
                              console.log(selectedRadio);
                              console.log(value);

                              if (selectedRadio === "SCHEDULED") {
                                return typeof value === "string" && +value > 0;
                              }
                              return true;
                            },
                          })
                          .test({
                            name: "less-than-amount",
                            message:
                              "Amount should be less than the goal limit",
                            test: function (value) {
                              const { amount } = this.parent;
                              const selectedRadio = this.parent.selectedRadio;
                              const selectedGoal = this.parent.selectedGoal;
                              console.log(
                                amount,
                                selectedRadio,
                                value,
                                selectedGoal
                              );

                              if (
                                selectedRadio === "SCHEDULED" &&
                                selectedGoal === "SET_LIMIT"
                              ) {
                                return Number(value) < Number(amount);
                              }
                              return true;
                            },
                          })
                          .notRequired(),
                      })}
                      onSubmit={(values, action) => {
                        subEditData.accountNumber =
                          passedData?.accountNumber || "";
                        subEditData.subAccountName = data?.subAccountName || "";
                        subEditData.iconCode = data?.iconCode || "";
                        subEditData.goal = values?.selectedGoal || "";
                        subEditData.goalLimit = +values.amount || 0;
                        subEditData.creditCondition =
                          values?.selectedRadio || "";

                        subEditData.creditType = data?.creditType || "";
                        subEditData.creditValue =
                          values?.selectedRadio === "ON_CREDIT"
                            ? Number(values.percentage)
                            : 0;
                        subEditData.creditFrequency = values?.frequency || "";
                        subEditData.frequencyValue =
                          values?.selectedRadio === "SCHEDULED"
                            ? Number(values.frequencyValue)
                            : 0;
                        const showPinInputModal = async () => {
                          handlePin();
                        };
                        handleCloseModal();
                        showPinInputModal();
                        // action.resetForm()
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleChange,
                        validateField,
                      }) => (
                        <>
                          <Form>
                            <div
                              className={`tab-content ${
                                activeTab === 1 ? "show active" : ""
                              }`}
                              id="myTabContent"
                            >
                              <div
                                className={`tab-pane fade ${
                                  activeTab === 1 ? "show active" : ""
                                }`}
                                id="kt_tab_pane_1"
                                role="tabpanel"
                              >
                                <div className="mb-5 mt-10">
                                  <div className=" d-flex flex-row justify-content-between gap-5">
                                    <div className="d-flex flex-row card shadow w-100 justify-content-between p-5">
                                      <label className="form-label">Open</label>
                                      <Field
                                        type="radio"
                                        className="form-check-input ms-3"
                                        name="selectedGoal"
                                        value="OPEN"
                                        checked={values.selectedGoal === "OPEN"}
                                      />
                                    </div>
                                    <div className="d-flex flex-row card shadow w-100 justify-content-between p-5">
                                      <label>Set Limit</label>
                                      <Field
                                        type="radio"
                                        className="form-check-input ms-3"
                                        name="selectedGoal"
                                        value="SET_LIMIT"
                                        checked={
                                          values.selectedGoal === "SET_LIMIT"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {values.selectedGoal === "SET_LIMIT" && (
                                  <>
                                    <div className="p-5  card shadow mb-5">
                                      <span
                                        className="fs-6 fw-bold text-gray-700"
                                        style={{
                                          position: "absolute",
                                          padding: "12px",
                                          right: "230px",
                                          top: "42.5px",
                                        }}
                                      >
                                        {baseCurrency}
                                      </span>
                                      <label className="form-label">
                                        Enter Amount
                                      </label>
                                      <Field
                                        name="amount"
                                        type="text"
                                        className="form-control ps-17 custom-input"
                                        placeholder="0"
                                        autoFocus
                                        onChange={(e: any) => {
                                          const inputValue = e.target.value;

                                          // Check if the input value is an empty string
                                          if (inputValue === ""|| inputValue==="0") {
                                            handleChange(e); // Trigger Formik's change handler
                                            validateField("amount");
                                            setFieldValue("amount", "");
                                            return
                                          } else {
                                            // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                            if (
                                              /^\d{1,12}(\.\d{0,2})?$/.test(
                                                inputValue
                                              )
                                            ) {
                                              handleChange(e); // Trigger Formik's change handler
                                              validateField("amount");
                                              setFieldValue(
                                                "amount",
                                                inputValue
                                              );
                                            }
                                          }
                                        }}
                                        innerRef={inputRef}
                                      ></Field>
                                      <ErrorMessage
                                        name="amount"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                )}
                                <ErrorMessage
                                  name="selectedRadio"
                                  component="div"
                                  className="text-danger"
                                />
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className={` rounded mt-5 mb-5 ${
                                      values.selectedGoal === "SET_LIMIT" &&
                                      !values.amount
                                        ? "disabled-btn p-3"
                                        : "btn"
                                    }`}
                                    style={{ width: "200px" }}
                                    onClick={handleNextClick}
                                    disabled={
                                      values.selectedGoal !== "OPEN" &&
                                      !values.amount
                                    }
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                              <div
                                className={`tab-pane fade ${
                                  activeTab === 2 ? "show active" : ""
                                }`}
                                id="kt_tab_pane_2"
                                role="tabpanel"
                              >
                                <div className="mb-5 mt-10">
                                  <div className=" d-flex flex-row justify-content-between gap-5">
                                    <div className="d-flex flex-row card shadow w-100 justify-content-between p-5">
                                      <label className="form-label">
                                        On credit
                                      </label>
                                      <Field
                                        type="radio"
                                        className="form-check-input ms-3"
                                        name="selectedRadio"
                                        value="ON_CREDIT"
                                        checked={
                                          values.selectedRadio === "ON_CREDIT"
                                        }
                                      />
                                    </div>
                                    <div className="d-flex flex-row card shadow w-100 justify-content-between p-5">
                                      <label>Scheduled</label>
                                      <Field
                                        type="radio"
                                        className="form-check-input ms-3"
                                        name="selectedRadio"
                                        value="SCHEDULED"
                                        checked={
                                          values.selectedRadio === "SCHEDULED"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {values.selectedRadio === "ON_CREDIT" && (
                                  <div className="p-5 card shadow mb-5 d-flex flex-row align-items-center flex-column">
                                    <div className="d-flex flex-row align-items-center">
                                      <label className="form-label w-50 m-0">
                                        Enter Percentage
                                      </label>
                                      <Field
                                        name="percentage"
                                        type="text"
                                        style={{ paddingRight: "30px" }}
                                        className="form-control custom-input text-end w-50"
                                        placeholder="0"
                                        onChange={(event: any) => {
                                          let value = event.target.value;

                                          // If the value is empty, set the previous value to an empty string
                                          if (value === "" || value==="0") {
                                            handleChange(event); // Trigger Formik's change handler
                                            validateField("percentage");
                                            setFieldValue("percentage", value);
                                            return
                                          }

                                          if (/^\d{1,2}$/.test(value)) {
                                            handleChange(event); // Trigger Formik's change handler
                                            validateField("percentage");
                                            setFieldValue("percentage", value);
                                          }
                                        }}
                                      ></Field>
                                      <span
                                        className="fs-6 fw-bold text-gray-700"
                                        style={{
                                          position: "absolute",
                                          padding: "12px",
                                          left: "250px",
                                        }}
                                      >
                                        %
                                      </span>
                                    </div>
                                    <ErrorMessage
                                      name="percentage"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                )}

                                {/* <div className="mb-10">
                        <div className=" p-5  card shadow">
                          <div className="d-flex justify-content-between">
                            <label>Scheduled</label>
                            <Field
                              type="radio"
                              className="form-check-input"
                              name="selectedRadio"
                              value="SCHEDULED"
                              checked={values.selectedRadio === "SCHEDULED"}
                            />
                          </div>
                        </div>
                      </div> */}
                                {values.selectedRadio === "SCHEDULED" && (
                                  <>
                                    <div className="p-5  card shadow mb-5">
                                      <label className="form-label">
                                        Select Frequency
                                      </label>
                                      <Field
                                        as="select"
                                        className="form-select"
                                        aria-label="Select Country"
                                        name="frequency"
                                        value={values.frequency}
                                      >
                                        <option value="">
                                          Select frequency
                                        </option>
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                      </Field>
                                      <ErrorMessage
                                        name="frequency"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                    <div className="p-5  card shadow mb-5">
                                      <label className="form-label">
                                        Enter Amount
                                      </label>

                                      <Field
                                        name="frequencyValue"
                                        type="text"
                                        className="form-control custom-input"
                                        style={{ paddingLeft: "50px" }}
                                        placeholder="0"
                                        onChange={(e: any) => {
                                          const inputValue = e.target.value;

                                          // Check if the input value is an empty string
                                          if (inputValue === ""|| inputValue==="0") {
                                            handleChange(e); // Trigger Formik's change handler
                                            validateField("frequencyValue");
                                            setFieldValue("frequencyValue", "");
                                            return
                                          } else {
                                            // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                            if (
                                              /^\d{1,12}(\.\d{0,2})?$/.test(
                                                inputValue
                                              )
                                            ) {
                                              handleChange(e); // Trigger Formik's change handler
                                              validateField("frequencyValue");
                                              setFieldValue(
                                                "frequencyValue",
                                                inputValue
                                              );
                                            }
                                          }
                                        }}
                                      />
                                      <span
                                        className="fs-6 fw-bold text-gray-700"
                                        style={{
                                          position: "absolute",
                                          padding: "12px",
                                          left: "20px",
                                          top: "42.5px",
                                        }}
                                      >
                                        {baseCurrency}
                                      </span>
                                      <ErrorMessage
                                        name="frequencyValue"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                )}

                                <ErrorMessage
                                  name="selectedRadio"
                                  component="div"
                                  className="text-danger"
                                />
                                <div className="d-flex justify-content-between gap-5">
                                  <button
                                    type="button"
                                    className="btn rounded mt-5 mb-5"
                                    style={{ width: "200px" }}
                                    onClick={() => {
                                      // setActiveTab(1)
                                      const feeTabLink = document.querySelector(
                                        'a[href="#kt_tab_pane_1"]'
                                      );
                                      if (
                                        feeTabLink instanceof HTMLAnchorElement
                                      ) {
                                        feeTabLink.click();
                                      }
                                    }}
                                  >
                                    Back
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn rounded mt-5 mb-5"
                                    style={{ width: "200px" }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <EditConfirmation /> */}

      {/* PinInput Modal */}
      <div
        className="modal fade"
        tabIndex={-1}
        id="editpin_modal"
        ref={modalRef1}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between">
                <h1
                  className="modal-title text-dark"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="d-flex align-items-center justify-content-center">
                  <p className="text-center fs-5 mb-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center mb-7">
                  <OTPInput
                    // {...field}
                    value={otp.join("")}
                    onChange={(value: string) => {
                      value
                        .split("")
                        .forEach((char, index) => handleChange(char, index));
                    }}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        value={otp[index]}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => {
                          const pin = handleChange(e.target.value, index);
                          if (pin.length === 4) {
                            setTxnPin(pin);
                          } else {
                            setTxnPin("");
                          }
                          setPinError(false);
                        }}
                        onKeyDown={(e) => {
                          const pin = handleKeyDown(e, index);
                          handleEnterKeyPress(e);
                          setTxnPin("");
                        }}
                        type={show ? "password" : "text"}
                        autoFocus={true}
                        disabled={index !== activeInput}
                        onFocus={() => setActiveInput(index)}
                        className="text-center rounded mt-3 border border-1 border-dark"
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  />
                  <p
                    className="text-center cursor-pointer text-decoration-underline mb-0 mt-2"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Show PIN" : "Hide PIN"}
                  </p>
                </div>

                {!txnPin && pinError && (
                  <p className="text-danger">Please enter Pin</p>
                )}
                <div className="d-flex gap-5">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      if (pin.length === 4) {
                        UpdateSubAccount();
                      } else {
                        setPinError(true);
                      }
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handlePaybillTxnPinClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
