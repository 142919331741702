import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { QRPayment, ViewQR } from "./QRInterface";
import { KTSVG } from "../../../_metronic/helpers";
import { QRPaymentModal } from "./QRPaymentModal";
import QRcode from "../../../_metronic/assets/Icons/pngwing.com (1).png";
import Swal from "sweetalert2";
import { formatAmount } from "../../interface/Interface";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface QRModalProps {
  data: ViewQR;
  getQrCodes: () => void; // Adjust the type based on the actual function signature
}

export function QRModal({ data, getQrCodes }: QRModalProps) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const navigate = useNavigate();
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<QRPayment>({
    paymentId: "",
    transactionDate: "",
    qrDescription: "",
    bankRefNo: "",
    sender: "",
    status: "",
    currency: "",
    amount: 0,
  });
  console.log(data);

  const closeQR = async (
    address: string | undefined,
    token: string,
    qrCodeid: string
  ) => {
    try {
      const url = `${address}/payment/closeQRCode`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ qrCodeModels: [{ qrCodeid }] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        getQrCodes();
        Swal.fire({
          icon: "success",
          title: "Clarification",
          html: `Qr Code Closed.`,
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok", 
          allowEscapeKey: true,
          allowEnterKey: true,// Text for the cancel button
        });
      }
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  async function handleClose() {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      html: `Are you sure you want to disable the QR code?</br> <b>Note:</b> Once disabled cannot be enabled again`,
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCloseModal();
        closeQR(API_URL, token, data?.qrCodeid);
      }
    });
  }
  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleRequestClick = (payment: QRPayment) => {
    setSelectedPayment(payment);

    const modalElement = document.getElementById("qrpayment_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  return (
    <>
      <div
        className="modal fade"
        tabIndex={-1}
        id="qr_modal"
        ref={modalRef}
          
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">QR Info</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div className="modal-body p-8" style={{ overflow: "auto" }}>
              <div className="d-flex flex-column">
                <div className="card shadow-sm p-5">
                  <div className="d-flex justify-content-center">
                    <img
                      src={data?.qrCodeUrl}
                      alt="Payment QR Code"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>QR Name</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.qrCodeName}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Amount</p>
                    </div>
                    <div>
                      <p className="fw-bold">
                        {data?.currency} {formatAmount(data?.amount)}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Creation Date</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.createdDate}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Payments Count</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.paymentCount}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Amount Collected</p>
                    </div>
                    <div>
                      <p className="fw-bold">
                        {baseCurrency}{" "}
                        {data.totalPaymentAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Status</p>
                    </div>
                    <div>
                      <p
                        className={`fw-bold badge badge-${
                          data?.status === "active" ? "primary" : "danger"
                        }`}
                      >
                        {data?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {data?.status === "active" && (
                <button
                  className="btn btn-white w-100 mt-2 shadow bg-danger shadow-sm mb-2"
                  onClick={handleClose}
                >
                  Close QR
                </button>
              )}

              <div className="d-flex flex-row justify-content-between p-5">
                <p className="text-dark m-0 fw-bold">Recent Transactions</p>
                <p
                  className="text-primary m-0 fw-bold  text-hover-dark "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteCloseModal();
                    navigate("/qr/qr-listing", {
                      state: {
                        transactionDetails: data.qrPayments,
                      },
                    });
                  }}
                >
                  View All
                </p>
              </div>
              {data.qrPayments?.slice(0, 10).map((payment, index) => (
                <div
                  className="card shadow-sm gap-20 mt-5 p-5 d-flex flex-row"
                  onClick={() => handleRequestClick(payment)}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  key={index}
                >
                  <div className="fw-bold ms-4">{payment.sender}</div>
                  <div>
                    {new Date(payment.transactionDate).toLocaleDateString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </div>
                  <div className="text-primary fw-bold">
                    {payment.currency}
                    {payment.amount}
                  </div>
                </div>
              ))}

              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn rounded w-100"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QRPaymentModal {...selectedPayment} />
    </>
  );
}
