import React, { FC, useEffect, useState } from "react";
import { StepProps } from "../IAppModels";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

const Step5: FC<StepProps> = ({
  data,
  updateData,
  hasError,
  reset,
}: StepProps) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [selectAll, setSelectAll] = useState(true);
  const [txnTypes, setTxnTypes] = useState<{ key: string; value: string }[]>(
    []
  );
  const [selectedTxnTypes, setSelectedTxnTypes] = useState<string[]>([]);
  useEffect(() => {
    setSelectedTxnTypes(txnTypes.map(({ key }) => key));
  }, [txnTypes]);

  data.txnType.txnType = selectedTxnTypes;

  useEffect(() => {
    setSelectAll(true);
    setSelectedTxnTypes(txnTypes.map(({ key }) => key));
  }, [reset]);

  const handleMasterCheckboxChange = () => {
    setSelectAll(!selectAll);
    const allTxnTypeKeys: string[] = txnTypes.map(({ key }) => key);
    setSelectedTxnTypes(selectAll ? [] : allTxnTypeKeys);

    updateData({
      txnType: {
        txnType: selectAll ? allTxnTypeKeys : [],
      },
    });
  };

  const handleCheckboxChange = (key: string) => {
    const updatedSelectedTxnTypes = selectedTxnTypes.includes(key)
      ? selectedTxnTypes.filter((selectedType) => selectedType !== key)
      : [...selectedTxnTypes, key];

    const allSelected = txnTypes.length === updatedSelectedTxnTypes.length;
    setSelectAll(allSelected);
    setSelectedTxnTypes(updatedSelectedTxnTypes);
    console.log(updatedSelectedTxnTypes);

    updateData({
      txnType: {
        txnType: updatedSelectedTxnTypes,
      },
    });
  };

  const listTxnTypes = async () => {
    try {
      const url = `${API_URL}/txnStmnt/getTxnType`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setTxnTypes(
          (
            Object.entries(data?.message?.txnTypeMap || {}) as [
              string,
              string
            ][]
          ).map(([key, value]) => ({
            key: String(key),
            value: String(value),
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listTxnTypes();
  }, []);

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <div className="d-flex flex-row justify-content-between align-items-cemter mb-10">
          <h2 className="fw-bolder text-dark">Transaction Type</h2>
          <span className="form-check form-check-custom form-check-solid me-5">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() => handleMasterCheckboxChange()}
              checked={selectAll}
            />
          </span>
        </div>

        <div
          className="mb-0 fv-row px-5"
          style={{ height: "330px", overflowY: "auto" }}
        >
          {txnTypes && txnTypes.length > 0 ? (
            txnTypes.map(({ key, value }, index) => (
              <label
                key={index}
                className="d-flex flex-stack mb-10 cursor-pointer"
              >
                <span className="d-flex align-items-center me-2">
                  <span className="d-flex flex-column">
                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">
                      {value}
                    </span>
                    <span className="fs-6 fw-bold text-gray-400"></span>
                  </span>
                </span>
                <span className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => handleCheckboxChange(key)}
                    checked={selectedTxnTypes.includes(key)}
                  />
                </span>
              </label>
            ))
          ) : (
            <p>No transaction types available.</p>
          )}
        </div>
        {selectedTxnTypes.length === 0 && hasError && (
          <p className="text-danger ms-5">Select transaction type</p>
        )}
      </div>
    </div>
  );
};

export { Step5 };
