import React, { useState, useRef } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikProps,
  FieldInputProps,
} from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { InvoiceModal } from "../InvoiceModal";
import { Modal } from "bootstrap";
import { InvData } from "./InvData";
import { customerData } from "../Inventory/data";
import Swal from "sweetalert2";
import { parsePhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { isPhoneValid } from "../../../modules/auth/components/Login";

interface Customer {
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  isfavourite: boolean;
  customerStatus: number;
}
interface Business {
  businessName: string;
  businessAddress: string;
  businessIsdCode: number;
  businessMobileNo: string;
  businessEmailId: string;
}

interface Bank {
  key: string;
  value: string;
}

interface Item {
  itemName: string;
  rate: number;
}

interface Invoice {
  domesticInvoicePrefix: string;
  domesticInvoiceSequencePattern: string;
  exportedInvoicePrefix: string;
  exportedInvoiceSequencePattern: string;
  domesticInvoiceSequenceNumber: number;
  exportedInvoiceSequenceNumber: number;
  termsAndConditions: string;
  dueDatePeriod: number;
  reminderFrequency: number;
  tax: number;
}

interface InvoiceItem extends Item {
  qty: number;
  total: number;
}

const CreateInvoice = () => {
  const datePickerRef = useRef<DatePicker>(null);
  const datePickerReff = useRef<DatePicker>(null);

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const modalRef = useRef<HTMLDivElement | null>(null);
  const baseCurrency = sessionStorage.getItem("currency");

  const [invoiceDate, setInvoiceDate] = useState<Date | null>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [itemsList, setItemsList] = useState<InvoiceItem[]>([
    { itemName: "", qty: 1, rate: 0, total: 0 },
  ]);
  const [initialItems, setinitialItems] = useState<Item[]>([]);
  const [InvoiceSetting, SetInvoiceSetting] = useState<Invoice>({
    domesticInvoicePrefix: "",
    domesticInvoiceSequencePattern: "",
    exportedInvoicePrefix: "",
    exportedInvoiceSequencePattern: "",
    domesticInvoiceSequenceNumber: 0,
    exportedInvoiceSequenceNumber: 0,
    termsAndConditions: "",
    dueDatePeriod: 0,
    reminderFrequency: 0,
    tax: 0,
  });
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [companyDetails, setCompanyDetails] = useState<Customer | null>(null);
  const [businessData, setBusinessData] = useState<Business | null>(null);
  const [discount, setDiscount] = useState("");
  const [shipping, setShipping] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const [tax, setTax] = useState("");
  const [TC, setTC] = useState("");
  const [Notes, setNotes] = useState("");
  const [preview, setPreview] = useState(false);
  const [calculatedDiscount, setCalculatedDiscount] = useState("");
  const [isPercentage, setIsPercentage] = useState(false);
  const [bank, setBank] = useState<Bank[] | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [netTotal, setNetTotal] = useState(0);

  const formikRef = useRef<FormikProps<any>>(null);
  const createformikRef = useRef<FormikProps<any>>(null);

  const handleCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const toggleValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDiscount("");
    createformikRef?.current?.setFieldValue("discount", "");
    setIsPercentage(e.target.value === "Percentage");
    handleCalculate();
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("item-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    if (formikRef.current) {
      formikRef.current.resetForm(); // Reset Formik form
    }
  };

  const subtotal = itemsList.reduce((acc, item) => acc + item.total, 0);
  const calculateDiscount = () => {
    const inputValue = Number(discount);
    if (isPercentage) {
      return ((subtotal * inputValue) / 100).toFixed(2); // Discount as a percentage of subtotal
    } else {
      return inputValue.toFixed(2);
    }
  };
  console.log(calculatedDiscount);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleIconClickk = () => {
    if (datePickerReff.current) {
      datePickerReff.current.setOpen(true);
    }
  };

  const handleCalculate = () => {
    const discountValue = calculateDiscount();
    setCalculatedDiscount(discountValue); // Set the calculated discount to the state variable
  };
  const grossTotal = subtotal - Number(calculatedDiscount) + Number(shipping);
  const taxAmount = (grossTotal * Number(tax)) / 100;
  const total = grossTotal + taxAmount;

  useEffect(() => {
    setNetTotal(total);
    createformikRef?.current?.setFieldValue("total", total);
  }, [calculatedDiscount, shipping, discount, itemsList]);

  useEffect(() => {
    handleCalculate();
  }, [discount, isPercentage]);

  const [InvPreviewData, setInvPreviewData] = useState({
    invDate: invoiceDate || new Date(),
    invDueDate: selectedDate || new Date(),
    billFrom: businessData || null,
    billTo: companyDetails || null,
    items: itemsList,
    discount: Math.round(Number(discount) * 100) / 100,
    subTotal: Math.round(subtotal * 100) / 100,
    shipping: Math.round(Number(shipping) * 100) / 100,
    tax: Math.round(Number(taxAmount) * 100) / 100,
    total: Math.round(Number(netTotal) * 100) / 100,
    tc: TC,
    notes: Notes,
  });

  useEffect(() => {
    const updatedInvPreviewData = {
      ...InvPreviewData,
      invDate: invoiceDate || new Date(),
      invDueDate: selectedDate || new Date(),
      billFrom: businessData || null,
      billTo: companyDetails || null,
      items: itemsList,
      discount: Math.round(Number(calculatedDiscount) * 100) / 100,
      subTotal: Math.round(subtotal * 100) / 100,
      shipping: Math.round(Number(shipping) * 100) / 100,
      tax: Math.round(Number(taxAmount) * 100) / 100,
      total: Math.round(Number(netTotal) * 100) / 100,
      tc: TC,
      notes: Notes,
    };

    setInvPreviewData(updatedInvPreviewData);
  }, [
    invoiceDate,
    selectedDate,
    businessData,
    companyDetails,
    itemsList,
    discount,
    subtotal,
    shipping,
    taxAmount,
    netTotal,
    TC,
    Notes,
    calculatedDiscount,
    isPercentage,
  ]);

  useEffect(() => {
    InvData.customerGuid = companyDetails?.customerGuid || "";
    InvData.businessName = companyDetails?.companyName || "";
    InvData.businessAddress = companyDetails?.address || "";
    InvData.subTotal = Math.round(Number(subtotal) * 100) / 100;
    InvData.discount = Math.round(Number(calculatedDiscount) * 100) / 100;
    InvData.tax = Math.round(Number(taxAmount) * 100) / 100;
    InvData.shipping = Math.round(Number(shipping) * 100) / 100;
    InvData.total = Math.round(Number(netTotal) * 100) / 100;
    InvData.termsAndConditions = TC;
    InvData.notes = Notes;
    InvData.items = itemsList;
    InvData.invoiceType = invoiceType;
  }, [
    preview,
    itemsList,
    subtotal,
    calculatedDiscount,
    taxAmount,
    shipping,
    TC,
    Notes,
    invoiceType,
  ]);

  // console.log(InvData);

  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = invoiceDate || new Date();
    const defaultDueDate = new Date(
      new Date(currentDate).getTime() +
        InvoiceSetting?.dueDatePeriod * 24 * 60 * 60 * 1000
    );

    setSelectedDate(defaultDueDate);
  }, [invoiceDate, InvoiceSetting?.dueDatePeriod]);

  // if (preview) {
  //     console.log(InvPreviewData)
  // }

  const handleAddRow = () => {
    setItemsList([...itemsList, { itemName: "", qty: 1, rate: 0, total: 0 }]);
  };

  const handleItemChange = (index: number, selectedItemName: string) => {
    const newItemsList = itemsList.map((item, i) => {
      if (i === index) {
        const selectedItem = initialItems.find(
          (item) => item.itemName === selectedItemName
        );
        const rate = selectedItem ? selectedItem.rate : 0;
        return {
          ...item,
          itemName: selectedItemName,
          rate: rate,
          total: rate * item.qty,
        };
      }
      return item;
    });

    setItemsList(newItemsList);
    setSelectedItems([selectedItemName]);
  };

  const handleQuantityChange = (index: number, newQty: number) => {
    newQty = Math.max(1, newQty);

    const newItemsList = itemsList.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          qty: newQty,
          total: newQty * item.rate,
        };
      }
      return item;
    });
    setItemsList(newItemsList);
  };

  const handleRemoveRow = (index: number) => {
    const newItemsList = itemsList.filter((_, i) => i !== index);
    setItemsList(newItemsList);

    // Remove the corresponding item from the selectedItems array
    const updatedSelectedItems = selectedItems.filter((_, i) => i !== index);
    setSelectedItems(updatedSelectedItems);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleInvoiceDateChange = (date: Date | null) => {
    setInvoiceDate(date);
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);

    if (selectedValue === "add-new-customer") {
      // navigate("/invoice/customers"); // Assuming you're using a routing library like react-router
      handleOpenModal();

      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    }

    if (selectedValue !== "add-new-customer") {
      // Fetch company details based on selectedValue (companyName or id) from API or data source
      const selectedCompanyDetails = customers.find(
        (company) => company.customerName === selectedValue
      );

      if (selectedCompanyDetails) {
        setCompanyDetails(selectedCompanyDetails);
      }
    } else {
      setCompanyDetails(null);
    }
  };

  const handleInvoiceClick = () => {
    const modalElement = document.getElementById("invoice_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const InitInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/init`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setBusinessData(data.message);
        setCustomers(data?.message?.customers);
        setinitialItems(data.message.items);
        SetInvoiceSetting(data.message.invoiceSetting);
      } else {
        Swal.fire({
          icon: "warning",
          text: data.status.messageDescription,
          allowEscapeKey: true,
          allowEnterKey: true,
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/invoice/settings");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    InitInvoice();
  }, []);
  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setTax(data.message.tax);
      setTC(data.message.termsAndConditions);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    LoadSettings();
  }, []);
  const getBank = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setBank(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBank();
  }, []);

  const addCustomer = async () => {
    try {
      const url = `${API_URL}/customer/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...customerData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Customer has been added",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      //   viewItem();
      InitInvoice();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <style>
        {`
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        `}
      </style>
      <div>
        <h4>Invoice Creation</h4>
        <div className="card mt-5">
          <Formik
            // Assigning ref to Formik
            innerRef={createformikRef}
            enableReinitialize={true}
            initialValues={{
              billTo: "",
              invoiceType: "",
              itemName: "",
              discount: "",
              shipppingCost: "",
              tax: "",
              terms: "",
              notes: "",
              total: "",
            }}
            validationSchema={Yup.object({
              billTo: Yup.string().required("Bill to is required"),
              invoiceType: Yup.string().required("Invoice type is required"),
              itemName: Yup.string().required("Item name is required"),
              discount: Yup.number().moreThan(
                -1,
                "Discount should not be less than 0"
              ),
              shipppingCost: Yup.number().moreThan(
                -1,
                "Shippping Cost should not be less than 0"
              ),
              total: Yup.number()
                .moreThan(-1, "Net total should not be less than 0")
                .required("Net total should not be less than 0"),
              // tax: Yup.string().required("Tax is required"),
              // terms: Yup.string().required("Terms is required"),

              // itemAvailability: Yup.boolean().test('itemAvailability', 'Item Availability Required', value => value === true)
            })}
            onSubmit={(values, { resetForm }) => {
              setPreview(true);
              handleInvoiceClick();
              handleCalculate();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form>
                <div className="card-body p-12">
                  <div className="d-flex flex-column align-items-start flex-xxl-row mb-5">
                    <div
                      className="d-flex align-items-center flex-equal fw-row me-4 order-2"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      title="Specify invoice date"
                    >
                      <div className="fs-6 fw-bold text-gray-700 text-nowrap">
                        Date:
                      </div>
                      <div className="position-relative d-flex align-items-center w-150px cursor-pointer">
                        <DatePicker
                          ref={datePickerReff}
                          selected={invoiceDate}
                          minDate={new Date()}
                          onChange={handleInvoiceDateChange}
                          dateFormat="dd/MM/yyyy" // Corrected the date format string
                          placeholderText="Select date"
                          name="invoice_date"
                          className="form-control form-control-transparent fw-bold pe-5 cursor-pointer"
                          onFocus={handleIconClickk}
                        />

                        <i
                          className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"
                          onClick={handleIconClickk}
                        ></i>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      title="Specify invoice due date"
                    >
                      <div className="fs-6 fw-bold text-gray-700 text-nowrap">
                        Due Date:
                      </div>

                      <div className="position-relative d-flex align-items-center w-150px cursor-pointer">
                        <DatePicker
                          ref={datePickerRef}
                          selected={selectedDate}
                          minDate={invoiceDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={handleDateChange}
                          placeholderText="Select date"
                          name="invoice_due_date"
                          className="form-control form-control-transparent fw-bold pe-5 cursor-pointer"
                          onFocus={handleIconClick}
                        />

                        <i
                          className="ki-duotone ki-down fs-4 position-absolute end-0 ms-4"
                          onClick={handleIconClick}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row gx-10 mb-5 justify-content-between">
                    <div className="col-lg-6 mb-5">
                      <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                        Bill To
                      </label>
                      <Field name="billTo">
                        {({
                          field,
                          form,
                        }: {
                          field: any;
                          form: FormikProps<any>;
                        }) => (
                          <div className="">
                            <select
                              {...field}
                              className="form-select"
                              data-control="select2"
                              data-placeholder="Select an option"
                              onChange={(e) => {
                                handleCompanyChange(e);
                                form.setFieldValue("billTo", e.target.value);
                              }}
                            >
                              <option selected value="">
                                To
                              </option>
                              <option value="add-new-customer">
                                Add new customer
                              </option>
                              <hr />
                              {customers?.map((item, index) => (
                                <option key={index} value={item.customerName}>
                                  {item.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </Field>

                      <ErrorMessage
                        name="billTo"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 mb-5">
                      <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                        Select Invoice Sequence
                      </label>
                      <Field name="invoiceType">
                        {({
                          field,
                          form,
                        }: {
                          field: any;
                          form: FormikProps<any>;
                        }) => (
                          <div className="">
                            <select
                              {...field}
                              className="form-select"
                              data-control="select2"
                              onChange={(e) => {
                                form.setFieldValue(
                                  "invoiceType",
                                  e.target.value
                                );
                                setInvoiceType(e.target.value);
                              }}
                              onBlur={field.onBlur}
                            >
                              <option disabled selected value="">
                                Select sequence
                              </option>
                              <option value="DOMESTIC">Domestic</option>
                              <option value="EXPORT">Export</option>
                            </select>
                          </div>
                        )}
                      </Field>

                      <ErrorMessage
                        name="invoiceType"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="table-responsive mb-10">
                    <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700">
                      <thead>
                        <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                          <th className="min-w-300px w-475px">Item Name</th>
                          <th className="min-w-100px w-100px">Quantity</th>
                          <th className="min-w-150px w-150px">Unit Price</th>
                          <th className="min-w-100px w-150px text-center">
                            Total Amount
                          </th>
                          <th className="min-w-75px w-75px text-end">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsList.map((item, index) => (
                          <tr
                            key={index}
                            className="border-bottom border-bottom-dashed"
                          >
                            <td className="pe-7">
                              <Field name="itemName">
                                {({
                                  field,
                                  form,
                                }: {
                                  field: any;
                                  form: FormikProps<any>;
                                }) => (
                                  <select
                                    {...field}
                                    className="form-select"
                                    onChange={(e) => {
                                      handleItemChange(index, e.target.value);
                                      form.setFieldValue(
                                        "itemName",
                                        e.target.value
                                      );
                                    }}
                                    value={item.itemName}
                                  >
                                    <option value="">Select an item</option>
                                    {initialItems.map((item) => (
                                      <option
                                        key={item.itemName}
                                        value={item.itemName}
                                        disabled={itemsList.some(
                                          (invoiceItem) =>
                                            invoiceItem.itemName ===
                                            item.itemName
                                        )}
                                      >
                                        {item.itemName}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </Field>
                              <ErrorMessage
                                name="itemName"
                                component="div"
                                className="text-danger"
                              />
                            </td>

                            <td>
                              <input
                                className="form-control form-control-solid"
                                placeholder="1"
                                type="number"
                                value={item.qty}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    index,
                                    parseInt(e.target.value, 10)
                                  )
                                }
                                onWheel={(e) => e.currentTarget.blur()}
                              />
                            </td>
                            {/* <td   className="form-control form-control-solid text-center mt-5" style={{padding:"12px"}}  placeholder="0.00">{item.rate.toFixed(2)}</td>
                             */}
                            <td className="">
                              <input
                                className="form-control form-control-solid"
                                min="1"
                                name="quantity[]"
                                placeholder="1"
                                value={item.rate.toFixed(2)}
                              />
                            </td>
                            <td className="pt-8 text-center text-nowrap">
                              {item.total.toFixed(2)}
                            </td>
                            <td className="d-flex justify-content-center align-items-center">
                              <div
                                className="btn bg-hover-secondary btn-sm bg-light btn-icon btn-active-color-light "
                                onClick={() => handleRemoveRow(index)}
                              >
                                <i
                                  className="bi bi-trash text-danger fs-5 "
                                  style={{ color: "#ffff" }}
                                >
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                  <span className="path5"></span>
                                </i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <div
                        className="btn "
                        style={{ backgroundColor: "#0000ff", color: "#ffff" }}
                        onClick={handleAddRow}
                      >
                        Add Item
                      </div>
                    </table>
                    <div className="d-flex justify-content-between ">
                      <div></div>
                      <div className="d-flex flex-column gap-5 ">
                        <div className="d-flex flex-row gap-10 align-items-center justify-content-between">
                          <div className="fs-6 fw-bold text-gray-700">
                            Discount
                          </div>
                          <div className="d-flex flex-row gap-5">
                            {/* <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{
                              position: "absolute",
                              padding: "12px",
                              marginLeft: "155px",
                            }}
                            onClick={toggleValue}
                          >
                            <i className="bi bi-arrow-down-up fs-7 fw-bold text-gray-900"></i>
                          </span> */}
                            <Field
                              name="selectedOption"
                              as="select"
                              className="form-select custom-input"
                              onChange={toggleValue}
                            >
                              <option value="Flat fee">Flat fee</option>
                              <option value="Percentage">Percentage</option>
                            </Field>
                            <Field name="discount">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <>
                                  <input
                                    {...field}
                                    type="number"
                                    className="custom-input form-control text-center"
                                    style={{ width: "200px" }}
                                    placeholder="0"
                                    value={discount}
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const inputValue = e.target.value;

                                      // If the value is "0" or empty, set the value to an empty string
                                      if (
                                        inputValue === "0" ||
                                        inputValue === ""
                                      ) {
                                        setDiscount("");
                                        form.setFieldValue("discount", "");
                                        return;
                                      }

                                      if (isPercentage) {
                                        if (/^\d{1,2}$/.test(inputValue)) {
                                          setDiscount(inputValue);
                                          form.setFieldValue(
                                            "discount",
                                            inputValue
                                          );
                                        }
                                      } else {
                                        if (
                                          /^\d{1,12}(\.\d{0,2})?$/.test(
                                            inputValue
                                          )
                                        ) {
                                          setDiscount(inputValue);
                                          form.setFieldValue(
                                            "discount",
                                            inputValue
                                          );
                                        }
                                      }
                                    }}
                                  />

                                  <span
                                    className="fs-6 fw-bold text-gray-700"
                                    style={{
                                      position: "absolute",
                                      padding: "12px",
                                      right: isPercentage ? "38px" : "180px",
                                    }}
                                  >
                                    {isPercentage ? "%" : "ZAR"}
                                  </span>
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                        <ErrorMessage
                          name="discount"
                          component="div"
                          className="text-danger text-end"
                        />

                        <div className="d-flex flex-row gap-10 align-items-center justify-content-between">
                          <div className="fs-6 fw-bold text-gray-700">
                            Shipping Cost
                          </div>
                          <div>
                            <span
                              className="fs-6 fw-bold text-gray-700"
                              style={{ position: "absolute", padding: "12px" }}
                            >
                              {baseCurrency}
                            </span>
                            <Field name="shipppingCost">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <input
                                  {...field}
                                  type="number"
                                  className="form-control text-center custom-input"
                                  placeholder="0"
                                  value={shipping}
                                  onWheel={(e) => e.currentTarget.blur()}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const inputValue = e.target.value;

                                    // If the value is "0" or empty, set the value to an empty string
                                    if (
                                      inputValue === "0" ||
                                      inputValue === ""
                                    ) {
                                      setShipping("");
                                      form.setFieldValue("shippingCost", "");
                                      return;
                                    }

                                    // If the value matches the pattern for up to 12 digits with optional 2 decimal places
                                    if (
                                      /^\d{1,12}(\.\d{0,2})?$/.test(inputValue)
                                    ) {
                                      setShipping(inputValue);
                                      form.setFieldValue(
                                        "shippingCost",
                                        inputValue
                                      );
                                    }
                                  }}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="shipppingCost"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-10 align-items-center justify-content-between">
                          <div className="fs-6 fw-bold text-gray-700">Tax</div>
                          <div>
                            <span
                              className="fs-6 fw-bolder text-gray-700 "
                              style={{
                                position: "absolute",
                                padding: "12px",
                                marginLeft: "160px",
                              }}
                            >
                              %
                            </span>
                            <Field name="tax">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control text-center"
                                  placeholder="0"
                                  value={tax}
                                  // onChange={(e) => {
                                  //     setTax(e.target.value);
                                  //     form.setFieldValue('tax', e.target.value);
                                  // }}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="tax"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-10 align-items-center justify-content-between">
                          <div className="fs-6 fw-bold text-primary">
                            Net Amount
                          </div>
                          <div>
                            <span
                              className="fs-6 fw-bold text-gray-700"
                              style={{ position: "absolute", padding: "12px" }}
                            >
                              {baseCurrency}
                            </span>

                            <Field name="total">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control text-center"
                                  placeholder="0"
                                  value={netTotal.toFixed(2)}
                                  // onChange={(e) => {
                                  //     setTax(e.target.value);
                                  //     form.setFieldValue('tax', e.target.value);
                                  // }}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="total"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <label className="form-label fs-6 fw-bold text-gray-700">
                        Terms and Conditions
                      </label>
                      <Field name="terms">
                        {({
                          field,
                          form,
                        }: {
                          field: any;
                          form: FormikProps<any>;
                        }) => (
                          <textarea
                            {...field}
                            name="terms"
                            className="form-control form-control-solid"
                            rows={2}
                            placeholder="Terms and Conditions"
                            value={TC}
                            onChange={(e) => {
                              setTC(e.target.value);
                              form.setFieldValue("terms", e.target.value);
                            }}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMessage
                        name="terms"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mt-10">
                      <label className="form-label fs-6 fw-bold text-gray-700">
                        Notes
                      </label>
                      <Field name="Phone">
                        {({
                          field,
                          form,
                        }: {
                          field: any;
                          form: FormikProps<any>;
                        }) => (
                          <textarea
                            {...field}
                            name="notes"
                            className="form-control form-control-solid"
                            rows={2}
                            placeholder="Thanks for your business"
                            value={Notes}
                            onChange={(e) => {
                              setNotes(e.target.value);
                              form.setFieldValue("notes", e.target.value);
                            }}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMessage
                        name="notes"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="submit"
                      className={`w-50 ${
                        itemsList.some((item) => !item.itemName.trim())
                          ? " btn btn-light-primary"
                          : "btn"
                      }`}
                      onClick={(e) => {
                        // setPreview(true);
                        // handleInvoiceClick();
                        // handleCalculate();
                      }}
                      disabled={itemsList.some((item) => !item.itemName.trim())}
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <InvoiceModal data={InvPreviewData} />
        <div
          className="modal fade"
          tabIndex={-1}
          id="item-modal"
          ref={modalRef}
        >
          <div className="modal-dialog modal-dialog-centered w-800px">
            <div
              className="modal-content"
              // style={{ maxHeight: "600px", overflow: "auto", padding: "10px" }}
            >
              <Formik
                innerRef={formikRef}
                initialValues={{
                  customerName: "",
                  CompanyName: "",
                  Business: "",
                  Country: "",
                  Phone: "",
                  Email: "",
                  Address: "",
                  VATNumber: "",
                  Bank: "",
                  bankAccNumber: "",
                  BIC: "",
                  Notes: "",
                }}
                validationSchema={Yup.object({
                  customerName: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9 ]+$/,
                      "Only letters and numbers are allowed"
                    )
                    .required("Business Holder Name Required"),
                  CompanyName: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9 ]+$/,
                      "Only letters and numbers are allowed"
                    )
                    .required("Company Name Required"),
                  Business: Yup.string().required("Business Type Required"),
                  Country: Yup.string().required("Country Required"),
                  Phone: Yup.string()
                    .test(
                      "is-valid-phone",
                      "Phone number is invalid",
                      function (value) {
                        if (value) {
                          return isPhoneValid(value); // Validate phone only if Phone is true and value exists
                        }
                        return true; // If Phone is false, or no value, bypass the phone validation
                      }
                    )
                    .required("Phone Required"),
                  Email: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      "Invalid email address"
                    )
                    .required("Email Required"),
                  Address: Yup.string().required("Address Required"),
                  VATNumber: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9 ]+$/,
                      "Only letters and numbers are allowed"
                    )
                    .required("VAT Number Required"),
                  Bank: Yup.string().required("Bank Required"),
                  bankAccNumber: Yup.string()
                    .matches(/^[0-9]+$/, "Only numbers are allowed")
                    .required("Bank Account Number Required"),
                  BIC: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9 ]+$/,
                      "Only letters and numbers are allowed"
                    )
                    .required("BIC Required"),
                  Notes: Yup.string().required("Notes Required"),
                })}
                onSubmit={(values, { resetForm }) => {
                  const phoneNumber = values.Phone;
                  const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                  customerData.customerName = values?.customerName;
                  customerData.companyName = values?.CompanyName;
                  customerData.countryType = values?.Country;
                  customerData.type = values?.Business;
                  customerData.phoneNumber =
                    phoneNumberParsed?.nationalNumber.toString() || "";
                  customerData.isdCode = +(
                    phoneNumberParsed?.countryCallingCode ?? 0
                  );
                  customerData.emailId = values?.Email;
                  customerData.address = values?.Address;
                  customerData.vatNumber = values?.VATNumber;
                  customerData.bankName = values?.Bank;
                  customerData.bankAccountNo = values?.bankAccNumber;
                  customerData.bic = values?.BIC;
                  customerData.notes = values?.Notes;
                  addCustomer();
                  handleCloseModal();
                  resetForm();
                }}
              >
                {({ resetForm }) => (
                  <>
                    <div className="modal-header">
                      <h4
                        className="modal-title text-nowrap"
                        // style={{ color: "#246bfd" }}
                      >
                        Create Business
                      </h4>
                      <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          resetForm();
                          handleCloseModal();
                        }}
                      >
                        <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                      </div>
                    </div>

                    <div className="modal-body p-10">
                      <Form>
                        <div
                          className="mb-10"
                          style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                          <div className="mb-5">
                            <label className="form-label">
                              Business Holder Name
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Business Holder Name"
                              name="customerName"
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="customerName"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Company Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              name="CompanyName"
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="CompanyName"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">
                              Type of Business
                            </label>
                            <Field
                              as="select"
                              className="form-select"
                              aria-label="Select Business"
                              name="Business"
                            >
                              <option value="">Select a business</option>
                              <option value="3">Both</option>
                              <option value="2">Vendor</option>
                              <option value="1">Customer</option>
                            </Field>
                            <ErrorMessage
                              name="Business"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Country</label>
                            <Field
                              as="select"
                              className="form-select"
                              aria-label="Select Country"
                              name="Country"
                            >
                              <option value="">Select a country</option>
                              <option value="Domestic">Domestic</option>
                              <option value="International">
                                International
                              </option>
                            </Field>
                            <ErrorMessage
                              name="Country"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Phone</label>
                            <Field name="Phone">
                              {({
                                field,
                                form,
                              }: {
                                field: FieldInputProps<any>;
                                form: FormikProps<any>;
                              }) => (
                                <div className="mb-5">
                                  <PhoneInput
                                    {...field}
                                    inputClassName="form-control"
                                    defaultCountry="in"
                                    onChange={(phone) =>
                                      form.setFieldValue("Phone", phone)
                                    }
                                  />
                                </div>
                              )}
                            </Field>
                            <ErrorMessage
                              name="Phone"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Email</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              name="Email"
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="Email"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Address</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="Address"
                              maxLength={100}
                            />
                            <ErrorMessage
                              name="Address"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">VAT Number</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="VAT Number"
                              name="VATNumber"
                              maxLength={20}
                            />
                            <ErrorMessage
                              name="VATNumber"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Bank</label>
                            <Field
                              as="select"
                              className="form-select"
                              aria-label="Select Bank"
                              name="Bank"
                            >
                              <option value="">Select a bank</option>
                              {bank &&
                                bank.map((item, index) => (
                                  <option key={index} value={item.key}>
                                    {item.value}
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="Bank"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">
                              Bank Account Number
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Bank Account Number"
                              name="bankAccNumber"
                              maxLength={20}
                            />
                            <ErrorMessage
                              name="bankAccNumber"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">BIC</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="BIC"
                              name="BIC"
                              maxLength={20}
                            />
                            <ErrorMessage
                              name="BIC"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-5">
                            <label className="form-label">Notes</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Notes"
                              name="Notes"
                              maxLength={100}
                            />
                            <ErrorMessage
                              name="Notes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="">
                          <button type="submit" className="btn w-100">
                            Create
                          </button>
                        </div>
                      </Form>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateInvoice;
