import { useState, useRef, useEffect, ChangeEvent } from "react";
import React from "react";
import { Modal } from "bootstrap";
import { InventoryData } from "../Inventory/data";
import { InventoryEditData } from "../Inventory/data";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikProps,
  FieldInputProps,
} from "formik";
import * as Yup from "yup";
import InvoiceDetailsModal from "../Inventory/InventoryDetailsModal/InventoryDetails";
import { parsePhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import { customerData } from "../Inventory/data";
import { editCustomerData } from "../Inventory/data";
import { CustomerDetailsModal } from "./CustomerDetails/CustomerDetails";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { PhoneNumberUtil } from "google-libphonenumber";
import Authorize from "../../../modules/auth/components/Authorize";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

interface Data {
  isfavourite: boolean;
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  type: number;
}

interface EditData {
  isfavourite: boolean;
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  type: number;
}

interface Bank {
  key: string;
  value: string;
}

interface FormValues {
  customerName: string;
  CompanyName: string;
  Country: string;
  Phone: string;
  Business: string;
  Email: string;
  Address: string;
  VATNumber: string;
  Bank: string;
  bankAccNumber: string;
  BIC: string;
  Notes: string;
}

export default function Customer() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const modalRef = useRef<HTMLDivElement | null>(null);
  const editmodalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const DeleteModal = useRef<HTMLDivElement | null>(null);
  const DeleteConfirm = useRef<HTMLDivElement | null>(null);
  const UpdateConfirm = useRef<HTMLDivElement | null>(null);

  const tooltipEdit = <Tooltip id="tooltip-add-money">Edit</Tooltip>;
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const handleReset = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const [ItemData, setItemData] = useState<Data[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filteredTransactions, setFilteredTransactions] = useState(ItemData);
  const [selectedData, setSelectedData] = useState<Data | null>(null);
  const [editData, setEditData] = useState<EditData | null>(null);
  const [DeleteData, setDeleteData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [bank, setBank] = useState<Bank[] | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(ItemData);
    } else if (tab === "Favourite") {
      const filteredData = ItemData.filter(
        (transaction) => transaction.isfavourite === true
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Vendor") {
      const filteredData = ItemData.filter(
        (transaction) => transaction.type === 2
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Customer") {
      const filteredData = ItemData.filter(
        (transaction) => transaction.type === 1
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Both") {
      const filteredData = ItemData.filter(
        (transaction) => transaction.type === 3
      );
      setFilteredTransactions(filteredData);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (DeleteModal.current) {
      const modal = new Modal(DeleteModal.current);
      DeleteModal.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        DeleteModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (DeleteConfirm.current) {
      const modal = new Modal(DeleteConfirm.current);
      DeleteConfirm.current.addEventListener(
        "hidden.bs.modal",
        handleConfirmCloseModal
      );
      return () => {
        DeleteConfirm.current?.removeEventListener(
          "hidden.bs.modal",
          handleConfirmUpdateCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (UpdateConfirm.current) {
      const modal = new Modal(UpdateConfirm.current);
      UpdateConfirm.current.addEventListener(
        "hidden.bs.modal",
        handleConfirmCloseModal
      );
      return () => {
        UpdateConfirm.current?.removeEventListener(
          "hidden.bs.modal",
          handleConfirmUpdateCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  const handleDeleteCloseModal = () => {
    const modalElement = DeleteModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleConfirmCloseModal = () => {
    const modalElement = DeleteConfirm.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleConfirmUpdateCloseModal = () => {
    const modalElement = UpdateConfirm.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("delete");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleUpdateConfirmation = () => {
    const modalElement = document.getElementById("update");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const filteredData = ItemData;
  const totalCount = filteredData?.length || 0;

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (editmodalRef.current) {
      const modal = new Modal(editmodalRef.current);
      editmodalRef.current.addEventListener(
        "hidden.bs.modal",
        handleCloseModal
      );
      return () => {
        editmodalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleEditCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = ItemData?.filter((transaction) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Favourite":
          isTabMatched = transaction.isfavourite === true;
          break;
        case "Vendor":
          isTabMatched = transaction.type === 2;
          break;
        case "Customer":
          isTabMatched = transaction.type === 1;
          break;
        case "Both":
          isTabMatched = transaction.type === 3;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const isSearchMatched =
        transaction.companyName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.customerName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.bankName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.bankAccountNo
          .toString()
          .includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [ItemData, searchQuery, selectedTab]);

  const handleCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleEditCloseModal = () => {
    const modalElement = editmodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleOpenModal = () => {
    const modalElement = document.getElementById("item-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    handleReset();
  };

  const handleDetailsClick = (index: number) => {
    const clickedData: Data = currentData[index];
    setSelectedData(() => clickedData);
  };

  const handleClick = (index: number) => {
    const clickedData: Data = currentData[index];

    if (!clickedData.isfavourite) {
      addFav(clickedData.customerGuid);
    } else if (clickedData.isfavourite) {
      undoFav(clickedData.customerGuid);
    }
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleEditClick = (index: number) => {
    handleReset();
    const clickedData: EditData = currentData[index];
    editCustomerData.customerGuid = clickedData.customerGuid;
    setEditData(clickedData); // Update editData state with clickedData

    const modalElement = document.getElementById("item-Editmodal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  // ... rest of your code ...

  const handleDeleteClick = (index: number) => {
    const clickedData: Data = currentData[index];
    console.log(clickedData.customerGuid);

    setDeleteData(() => clickedData.customerGuid);

    const deleteItem = async () => {
      try {
        const url = `${API_URL}/customer/delete`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          customerGuid: clickedData.customerGuid,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation!!",
            text: "The selected customer has been deleted",
            showCancelButton: true,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
        viewItem();
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      title: "Delete customer",
      text: "Are you sure you want to delete the selected customer ?",
      showCancelButton: true,
      confirmButtonColor: "#007bff",
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "No",
      allowEscapeKey: true,
      allowEnterKey: true,
    }).then((res) => {
      if (res.isConfirmed) deleteItem();
    });
    // const modalElement = document.getElementById("delete_modal");
    // const modal = new Modal(modalElement as Element);
    // modal.show();
  };

  const addFav = async (customerGuid: string) => {
    try {
      const url = `${API_URL}/customer/markFavourite`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        customerGuid: customerGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const undoFav = async (customerGuid: string) => {
    try {
      const url = `${API_URL}/customer/unMarkFavourite`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        customerGuid: customerGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const getBank = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setBank(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBank();
    viewItem();
  }, []);

  const addCustomer = async () => {
    try {
      const url = `${API_URL}/customer/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...customerData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        viewItem();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Customer has been added",
          showCancelButton: false, // Hide the cancel button
          confirmButtonColor: "#007bff", // Set the button color to btn
          confirmButtonText: "OK",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Confirmation",
          text: data?.status?.messageDescription,
          showCancelButton: false, // Hide the cancel button
          confirmButtonColor: "#007bff", // Set the button color to btn
          confirmButtonText: "OK",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const updateItem = async () => {
    try {
      const url = `${API_URL}/customer/update`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...editCustomerData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation!!",
          text: "The selected customer has been updated",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // handleUpdateConfirmation();
      }
      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const viewItem = async () => {
    try {
      const url = `${API_URL}/customer/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setItemData(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const location = useLocation();
  const navdata = location.state as { data: string };

  useEffect(() => {
    if (navdata?.data === "nav") {
      const modalElement = document.getElementById("item-modal");

      if (modalElement) {
        const modal = new Modal(modalElement as Element);
        modal.show();
      }
    }
  }, [navdata]);

  return (
    <>
      <div>
        <h4 className="mb-4">Business</h4>
      </div>
      <div className="card shadow py-2 px-5">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <ul className="nav nav-tabs nav-line-tabs ms-5 fs-6 d-flex flex-nowrap">
            <li className="nav-item" key={1}>
              <a
                className="nav-link active text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
                onClick={() => {
                  handleTabClick("All");
                }}
              >
                All
              </a>
            </li>
            <li className="nav-item" key={2}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
                onClick={() => {
                  handleTabClick("Favourite");
                }}
              >
                Favourite
              </a>
            </li>

            <li className="nav-item" key={3}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
                onClick={() => {
                  handleTabClick("Vendor");
                }}
              >
                Vendor
              </a>
            </li>
            <li className="nav-item" key={4}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
                onClick={() => {
                  handleTabClick("Customer");
                }}
              >
                Customer
              </a>
            </li>
            <li className="nav-item" key={5}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
                onClick={() => {
                  handleTabClick("Both");
                }}
              >
                Both
              </a>
            </li>
          </ul>

          <div className="d-flex justify-content-center align-items-center p-3 gap-10">
            <div className="d-flex align-items-center gap-10">
              <div className="d-flex align-items-center  position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="text"
                  data-kt-docs-table-filter="search"
                  className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                  placeholder="Search customers"
                />
              </div>
            </div>
            <Authorize hasAnyPermission={["MENU_BUSINESS|ADD"]}>
              <button className="btn " onClick={handleOpenModal}>
                Add Business
              </button>
            </Authorize>
          </div>
        </div>

        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6  ">
                      <th className="min-w-100px p-5">Business Name</th>
                      <th className="min-w-100px p-5">Business Category</th>
                      <th className="min-w-100px p-5">Bank</th>

                      <th className="min-w-100px p-5 text-nowrap">
                        Account Number
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Favourite</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                        >
                          <td
                            className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                            onClick={() => handleDetailsClick(index)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            data-bs-dismiss="click"
                            data-bs-trigger="hover"
                            id="kt_modal_toggle_customer"
                          >
                            {/* <a href="" style={{ textDecoration: "underline" }}> */}
                            {item.companyName}
                            {/* </a> */}
                          </td>
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {item.type === 1
                              ? "Customer"
                              : item.type === 2
                              ? "Vendor"
                              : "Both"}
                          </td>
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {item.bankName}
                          </td>

                          <td className="p-5 text-gray-600">
                            {item.bankAccountNo}
                          </td>
                          <td className="p-5 text-gray-600">
                            <Authorize
                              hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                            >
                              <div
                                className="d-flex "
                                style={{ marginLeft: "20px" }}
                              >
                                <i
                                  onClick={() => handleClick(index)}
                                  className={`bi bi-star${
                                    item.isfavourite ? "-fill text-warning" : ""
                                  } fs-2 cursor-pointer`}
                                />
                              </div>
                            </Authorize>
                          </td>
                          <td className="p-5 text-gray-600 p-2">
                            <div className="d-flex gap-10">
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipEdit}
                                >
                                  <i
                                    className="bi bi-pencil-fill cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                    onClick={() => handleEditClick(index)}
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|DELETE"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipDelete}
                                >
                                  <i
                                    className="bi bi-trash cursor-pointer"
                                    style={{ color: "#FF0000" }}
                                    onClick={() => handleDeleteClick(index)}
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <PayrollDetailsModal Data={selectedData} /> */}
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal /> */}
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6  ">
                      <th className="min-w-100px p-5">Business Name</th>
                      <th className="min-w-100px p-5">Business Category</th>
                      <th className="min-w-100px p-5">Bank</th>

                      <th className="min-w-100px p-5 text-nowrap">
                        Account Number
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Favourite</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => {
                      if (item.isfavourite === true) {
                        return (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                              // target.style.transform =
                              //   "translate3d(6px, -6px, 0)";
                              // target.style.boxShadow =
                              //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                              // target.style.transform = "none";
                              // target.style.boxShadow = "none";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() => handleDetailsClick(index)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              id="kt_modal_toggle_customer"
                            >
                              {/* <a
                                href=""
                                style={{ textDecoration: "underline" }}
                              > */}
                              {item.companyName}
                              {/* </a> */}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.type === 1
                                ? "Customer"
                                : item.type === 2
                                ? "Vendor"
                                : "Both"}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.bankAccountNo}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isfavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                {/* <PayrollDetailsModal Data={selectedData} /> */}
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6  ">
                      <th className="min-w-100px p-5">Business Name</th>
                      <th className="min-w-100px p-5">Business Category</th>
                      <th className="min-w-100px p-5">Bank</th>

                      <th className="min-w-100px p-5 text-nowrap">
                        Account Number
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Favourite</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => {
                      if (item.type === 2) {
                        return (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                              // target.style.transform =
                              //   "translate3d(6px, -6px, 0)";
                              // target.style.boxShadow =
                              //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                              // target.style.transform = "none";
                              // target.style.boxShadow = "none";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() => handleDetailsClick(index)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              id="kt_modal_toggle_customer"
                            >
                              {/* <a
                                href=""
                                style={{ textDecoration: "underline" }}
                              > */}
                              {item.companyName}
                              {/* </a> */}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              Vendor
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.bankAccountNo}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isfavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6  ">
                      <th className="min-w-100px p-5">Business Name</th>
                      <th className="min-w-100px p-5">Business Category</th>
                      <th className="min-w-100px p-5">Bank</th>

                      <th className="min-w-100px p-5 text-nowrap">
                        Account Number
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Favourite</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => {
                      if (item.type === 1) {
                        return (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                              // target.style.transform =
                              //   "translate3d(6px, -6px, 0)";
                              // target.style.boxShadow =
                              //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                              // target.style.transform = "none";
                              // target.style.boxShadow = "none";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() => handleDetailsClick(index)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              id="kt_modal_toggle_customer"
                            >
                              {/* <a
                                href=""
                                style={{ textDecoration: "underline" }}
                              > */}
                              {item.companyName}
                              {/* </a> */}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.type === 1
                                ? "Customer"
                                : item.type === 2
                                ? "Vendor"
                                : ""}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.bankAccountNo}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isfavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6  ">
                      <th className="min-w-100px p-5">Business Name</th>
                      <th className="min-w-100px p-5">Business Category</th>
                      <th className="min-w-100px p-5">Bank</th>

                      <th className="min-w-100px p-5 text-nowrap">
                        Account Number
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Favourite</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => {
                      if (item.type === 3) {
                        return (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                              // target.style.transform =
                              //   "translate3d(6px, -6px, 0)";
                              // target.style.boxShadow =
                              //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                              // target.style.transform = "none";
                              // target.style.boxShadow = "none";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() => handleDetailsClick(index)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              id="kt_modal_toggle_customer"
                            >
                              {/* <a
                                href=""
                                style={{ textDecoration: "underline" }}
                              > */}
                              {item.companyName}
                              {/* </a> */}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              Both
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.bankAccountNo}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isfavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_BUSINESS|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage ===
                  Math.ceil(filteredTransactions?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage ===
                  Math.ceil(filteredTransactions?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                    )
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {filteredTransactions?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="item-modal" ref={modalRef}>
        <div
          className="modal-dialog modal-dialog-centered w-800px"
          // style={{ marginTop: "80px" }}
        >
          <div className="modal-content">
            <Formik
              innerRef={formikRef}
              initialValues={{
                customerName: "",
                CompanyName: "",
                Business: "",
                Country: "",
                Phone: "",
                Email: "",
                Address: "",
                VATNumber: "",
                Bank: "",
                bankAccNumber: "",
                BIC: "",
                Notes: "",
              }}
              validationSchema={Yup.object({
                customerName: Yup.string()
                  .min(2, "Minimum 2 characters required")
                  .max(50, "Maximum 50 characters allowed")
                  .matches(
                    /^[a-zA-Z0-9 ]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("Business Holder Name Required"),
                CompanyName: Yup.string()
                  .min(2, "Minimum 2 characters required")
                  .max(50, "Maximum 50 characters allowed")
                  .matches(
                    /^[a-zA-Z0-9 ]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("Company Name Required"),
                Business: Yup.string().required("Business Type Required"),
                Country: Yup.string().required("Country Required"),
                Phone: Yup.string()
                  .min(12, "Phone number is required")
                  .test(
                    "is-valid-phone",
                    "Phone number is invalid",
                    function (value) {
                      if (value) {
                        return isPhoneValid(value); // Validate phone only if Phone is true and value exists
                      }
                      return true; // If Phone is false, or no value, bypass the phone validation
                    }
                  )
                  .required("Phone number is Required"),
                Email: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    "Invalid email address"
                  )
                  .required("Email Required"),
                Address: Yup.string()
                  .min(2, "Minimum 2 characters required")
                  .max(100, "Maximum 100 characters allowed")
                  .required("Address Required"),
                VATNumber: Yup.string()
                  .max(20, "Maximum 20 characters allowed")
                  .matches(
                    /^[a-zA-Z0-9 ]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("VAT Number Required"),
                Bank: Yup.string()
                  .min(10, "Minimum 10 characters required")
                  .max(34, "Maximum 34 characters allowed")
                  .matches(
                    /^[a-zA-Z0-9 ]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("Bank Required"),
                bankAccNumber: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("Bank Account Number Required"),
                BIC: Yup.string()
                  .min(8, "Minimum 8 characters required")
                  .max(11, "Maximum 11 characters allowed")
                  .matches(
                    /^[a-zA-Z0-9 ]+$/,
                    "Only letters and numbers are allowed"
                  )
                  .required("BIC Required"),
                Notes: Yup.string()
                  .max(100, "Maximum 100 characters allowed")
                  .required("Notes Required"),
              })}
              onSubmit={(values, { resetForm }) => {
                const phoneNumber = values.Phone;
                const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                customerData.customerName = values?.customerName;
                customerData.companyName = values?.CompanyName;
                customerData.countryType = values?.Country;
                customerData.type = values?.Business;
                customerData.phoneNumber =
                  phoneNumberParsed?.nationalNumber.toString() || "";
                customerData.isdCode = +(
                  phoneNumberParsed?.countryCallingCode ?? 0
                );
                customerData.emailId = values?.Email;
                customerData.address = values?.Address;
                customerData.vatNumber = values?.VATNumber;
                customerData.bankName = values?.Bank;
                customerData.bankAccountNo = values?.bankAccNumber;
                customerData.bic = values?.BIC;
                customerData.notes = values?.Notes;
                addCustomer();
                handleCloseModal();
                resetForm();
              }}
            >
              {({ resetForm }) => (
                <>
                  <div className="modal-header">
                    <h4
                      className="modal-title text-nowrap"
                      // style={{ color: "#246bfd" }}
                    >
                      Create Business
                    </h4>
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        resetForm();
                        handleCloseModal();
                      }}
                    >
                      <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                    </div>
                  </div>

                  <div className="modal-body p-10">
                    <Form>
                      <div
                        className="mb-10"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                      >
                        <div className="mb-5">
                          <label className="form-label">
                            Business Holder Name
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Business Holder Name"
                            name="customerName"
                            maxLength={50}
                          />
                          <ErrorMessage
                            name="customerName"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Company Name</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            name="CompanyName"
                            maxLength={50}
                          />
                          <ErrorMessage
                            name="CompanyName"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Type of Business</label>
                          <Field
                            as="select"
                            className="form-select"
                            aria-label="Select Business"
                            name="Business"
                          >
                            <option value="">Select business</option>
                            <option value="3">Both</option>
                            <option value="2">Vendor</option>
                            <option value="1">Customer</option>
                          </Field>
                          <ErrorMessage
                            name="Business"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Country</label>
                          <Field
                            as="select"
                            className="form-select"
                            aria-label="Select Country"
                            name="Country"
                          >
                            <option value="">Select a country</option>
                            <option value="Domestic">Domestic</option>
                            <option value="International">International</option>
                          </Field>
                          <ErrorMessage
                            name="Country"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Phone</label>
                          <Field name="Phone">
                            {({
                              field,
                              form,
                            }: {
                              field: FieldInputProps<any>;
                              form: FormikProps<any>;
                            }) => (
                              <div className="mb-5">
                                <PhoneInput
                                  {...field}
                                  inputClassName="form-control"
                                  defaultCountry="in"
                                  onChange={(phone) =>
                                    form.setFieldValue("Phone", phone)
                                  }
                                />
                              </div>
                            )}
                          </Field>
                          <ErrorMessage
                            name="Phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Email</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="Email"
                            maxLength={50}
                          />
                          <ErrorMessage
                            name="Email"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Address</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            name="Address"
                            maxLength={100}
                          />
                          <ErrorMessage
                            name="Address"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">VAT Number</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="VAT Number"
                            name="VATNumber"
                            maxLength={20}
                          />
                          <ErrorMessage
                            name="VATNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Bank</label>
                          <Field
                            as="select"
                            className="form-select"
                            aria-label="Select Bank"
                            name="Bank"
                          >
                            <option value="">Select a bank</option>
                            {bank &&
                              bank.map((item, index) => (
                                <option key={index} value={item.key}>
                                  {item.value}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="Bank"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">
                            IBAN/Account Number
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="IBAN/Account Number"
                            name="bankAccNumber"
                            maxLength={34}
                          />
                          <ErrorMessage
                            name="bankAccNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">BIC</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="BIC"
                            name="BIC"
                            maxLength={11}
                          />
                          <ErrorMessage
                            name="BIC"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Notes</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Notes"
                            name="Notes"
                            maxLength={100}
                          />
                          <ErrorMessage
                            name="Notes"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="">
                        <button type="submit" className="btn w-100">
                          Create
                        </button>
                      </div>
                    </Form>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex={-1}
        id="item-Editmodal"
        ref={editmodalRef}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-nowrap"
                // style={{ color: "#246bfd" }}
              >
                Edit Business
              </h4>

              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div className="modal-body" style={{ padding: "30px" }}>
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  customerName: editData ? editData.customerName : "",
                  CompanyName: editData ? editData.companyName : "",
                  Business: editData ? editData?.type?.toString() : "",
                  Country: editData ? editData.countryType : "",
                  Phone: editData
                    ? editData.isdCode + editData.phoneNumber
                    : "",
                  Email: editData ? editData.emailId : "",
                  Address: editData ? editData.address : "",
                  VATNumber: editData ? editData.vatNumber : "",
                  Bank: editData ? editData.bankName : "",
                  bankAccNumber: editData ? editData.bankAccountNo : "",
                  BIC: editData ? editData.bic : "",
                  Notes: editData ? editData.notes : "",
                }}
                validationSchema={Yup.object({
                  customerName: Yup.string().required("Business Name Required"),
                  CompanyName: Yup.string().required(
                    "Legal / Registered Company Name Required"
                  ),
                  Country: Yup.string().required("Country Required"),
                  Business: Yup.string().required("Business Type Required"),
                  Phone: Yup.string()
                    .test(
                      "is-valid-phone",
                      "Phone number is invalid",
                      function (value) {
                        if (value) {
                          return isPhoneValid(value); // Validate phone only if Phone is true and value exists
                        }
                        return true; // If Phone is false, or no value, bypass the phone validation
                      }
                    )
                    .required("Phone Required"),
                  Email: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      "Invalid email address"
                    )
                    .required("Email Required"),
                  Address: Yup.string().required("Address Required"),
                  VATNumber: Yup.string().required("VAT Number Required"),
                  Bank: Yup.string().required("Bank Required"),
                  bankAccNumber: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9 ]+$/,
                      "Only letters and numbers are allowed"
                    )
                    .required("Bank Account Number Required"),
                  BIC: Yup.string().required("BIC Required"),
                  Notes: Yup.string().required("Notes Required"),
                })}
                onSubmit={(values, { resetForm }) => {
                  const phoneNumber = values.Phone;
                  const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                  editCustomerData.customerName = values?.customerName;
                  editCustomerData.companyName = values?.CompanyName;
                  editCustomerData.countryType = values?.Country;
                  editCustomerData.type = values?.Business;
                  editCustomerData.phoneNumber =
                    phoneNumberParsed?.nationalNumber.toString() || "";
                  editCustomerData.isdCode = +(
                    phoneNumberParsed?.countryCallingCode ?? 0
                  );
                  editCustomerData.emailId = values?.Email;
                  editCustomerData.address = values?.Address;
                  editCustomerData.vatNumber = values?.VATNumber;
                  editCustomerData.bankName = values?.Bank;
                  editCustomerData.bankAccountNo = values?.bankAccNumber;
                  editCustomerData.bic = values?.BIC;
                  editCustomerData.notes = values?.Notes;
                  updateItem();
                  handleEditCloseModal();
                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form>
                    <div
                      className="mb-10"
                      style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      <div className="mb-5">
                        <label className="form-label">
                          Contact Person Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Contact Person Name"
                          name="customerName"
                        />
                        <ErrorMessage
                          name="customerName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">
                          Legal / Registered Company Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Legal / Registered Company Name"
                          name="CompanyName"
                        />
                        <ErrorMessage
                          name="CompanyName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Type of Business</label>
                        <Field
                          as="select"
                          className="form-select"
                          aria-label="Select Business"
                          name="Business"
                        >
                          <option value="">Select a business</option>

                          <option value="2">Vendor</option>
                          <option value="1">Customer</option>
                          <option value="3">Both</option>
                        </Field>
                        <ErrorMessage
                          name="Business"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Country</label>
                        <Field name="Country">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <div className="mb-5">
                              <CountryDropdown
                                {...field}
                                classes="form-select"
                                onChange={(country) =>
                                  form.setFieldValue("Country", country)
                                }
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMessage
                          name="Country"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-5">
                        <label className="form-label">Phone</label>
                        <Field name="Phone">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <div className="mb-5">
                              <PhoneInput
                                {...field}
                                inputClassName="form-control"
                                defaultCountry="in"
                                onChange={(phone) => {
                                  form.setFieldValue("Phone", phone);
                                }}
                                inputStyle={{ padding: "21px", width: "100%" }}
                                countrySelectorStyleProps={{
                                  buttonStyle: {
                                    padding: "21px 5px",
                                  },
                                }}
                                disableDialCodePrefill={false}
                                forceDialCode={true}
                              />
                            </div>
                          )}
                        </Field>

                        <ErrorMessage
                          name="Phone"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Email</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="Email"
                        />
                        <ErrorMessage
                          name="Email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Address</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          name="Address"
                        />
                        <ErrorMessage
                          name="Address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">VATNumber</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="VATNumber"
                          name="VATNumber"
                        />
                        <ErrorMessage
                          name="VATNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Bank</label>
                        <Field
                          as="select"
                          className="form-select"
                          aria-label="Select Bank"
                          name="Bank"
                        >
                          <option value="">Select a bank</option>
                          {bank &&
                            bank.map((item: Bank, index: number) => (
                              <option key={index} value={item.key}>
                                {item.value}
                              </option>
                            ))}
                        </Field>

                        <ErrorMessage
                          name="Bank"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">
                          IBAN / Account Number
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="bankAccNumber"
                          name="bankAccNumber"
                          maxLength={20}
                        />
                        <ErrorMessage
                          name="bankAccNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">SWIFT / BIC</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="BIC"
                          name="BIC"
                        />
                        <ErrorMessage
                          name="BIC"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Notes</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="Notes"
                        />
                        <ErrorMessage
                          name="Notes"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="">
                      <button type="submit" className="btn w-100">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        tabIndex={-1}
        id="delete_modal"
        ref={DeleteModal}
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="mb-4">
                  <i className="bi bi-exclamation-circle fs-5x text-warning"></i>
                </div>
                <div className="label">
                  <div className="modal-title-wrapper">
                    <h1 className="modal-title" style={{ color: "#246bfd" }}>
                      Delete customer
                    </h1>
                  </div>
                </div>
                <div className="label p-0">
                  <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                    <p
                      className="lorem-ipsum-dolor mb-7"
                      style={{
                        fontSize: "15px",
                        boxSizing: "border-box",
                        textAlign: "center",
                        padding: "0px 25px",
                      }}
                    >
                      Are you sure you want to delete the selected customer ?
                    </p>
                  </div>
                </div>

                <div className="d-flex gap-5">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "150px",
                    }}
                    onClick={() => {
                      deleteItem();
                      handleDeleteCloseModal();
                      handleDeleteConfirmation();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handleDeleteCloseModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="modal" tabIndex={-1} id="delete" ref={DeleteConfirm}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="mb-4">
                  <i className="bi bi-check-circle text-success fs-5x"></i>
                </div>
                <div className="label">
                  <div className="modal-title-wrapper">
                    <h1 className="modal-title" style={{ color: "#246bfd" }}>
                      Confirmation !!
                    </h1>
                  </div>
                </div>
                <div className="label p-2">
                  <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                    <p
                      className="lorem-ipsum-dolor"
                      style={{
                        width: "276px",
                        fontSize: "15px",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                    >
                      The selected customer has been deleted
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "230px",
                    }}
                    onClick={handleConfirmCloseModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="modal" tabIndex={-1} id="update" ref={UpdateConfirm}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="mb-4">
                  <i className="bi bi-check-circle text-success fs-5x"></i>
                </div>
                <div className="label">
                  <div className="modal-title-wrapper">
                    <h1 className="modal-title" style={{ color: "#246bfd" }}>
                      Confirmation !!
                    </h1>
                  </div>
                </div>
                <div className="label p-2">
                  <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                    <p
                      className="lorem-ipsum-dolor"
                      style={{
                        width: "276px",
                        fontSize: "15px",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                    >
                      The selected customer has been updated
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "230px",
                    }}
                    onClick={handleConfirmUpdateCloseModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <CustomerDetailsModal Data={selectedData} />
    </>
  );
}
