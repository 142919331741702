/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import MoneyIcon from "../../../../assets/all-serviceIcons/arrow-down-arrow-up.svg";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { Menus } from "../../../../../app/pages/dashboard/DashboardWrapper";
import Authorize from "../../../../../app/modules/auth/components/Authorize";

type SetMenusType = Dispatch<SetStateAction<Menus[] | undefined>>;

// Define the type for Api object
interface ApiType {
  setMenus?: SetMenusType;
}

export const Api: ApiType = {};

const assignSetMenus = (setCategory: SetMenusType) => {
  Api.setMenus = setCategory;
};

const SidebarMenuMain = () => {
  const intl = useIntl();

  const [menus, setMenus] = useState<Menus[] | undefined>([]);

  assignSetMenus(setMenus);

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="dashboard"
        title="Dashboard"
      // fontIcon="bi bi-faChevronCircleUp"
      />
      <Authorize hasAnyPermission={["MENU_TRF|VIEW", "MENU_PAYEE|VIEW"]}>
        <SidebarMenuItemWithSub
          to="/money-movement"
          icon="money"
          title="Money Movement"
          fontIcon="bi-app-indicator"
        >
          <Authorize hasAnyPermission={["MENU_ALL_TXN|VIEW"]}>
            <SidebarMenuItem
              to="/money-movement/transaction-dashboard"
              title="All Transaction"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_TRF|VIEW"]}>
            <Authorize hasAnyPermission={["MENU_TRF|ADD"]}>
              <SidebarMenuItem
                to="/money-movement/transfer-money"
                title="Transfer Money"
                hasBullet={true}
              />
            </Authorize>
          </Authorize>
          <Authorize hasAnyPermission={["MENU_PAYEE|VIEW"]}>
            <SidebarMenuItem
              to="/money-movement/payee-management"
              title="Payee Management"
              hasBullet={true}
            />
          </Authorize>

          {/* <Authorize hasAnyPermission={["MENU_BULK_PAY|VIEW"]}> */}
          <SidebarMenuItem
            to="/money-movement/cardless-withdrawal"
            title="Cardless Withdrawal"
            hasBullet={true}
          />
          {/* </Authorize> */}
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize hasAnyPermission={["MENU_BULK_PAY|VIEW", "MENU_BILLPAY|VIEW"]}>
        <SidebarMenuItemWithSub
          to="/payment-products"
          icon="money"
          title="Payment Products"
          fontIcon="bi-app-indicator"
        >
          <Authorize hasAnyPermission={["MENU_BILLPAY|VIEW"]}>
            <SidebarMenuItem
              to="/paybill"
              hasBullet={true}
              title="Bill Payment"
              fontIcon="bi-layers"
            />
          </Authorize>

          {/* <Authorize hasAnyPermission={["MENU_BULK_PAY|VIEW"]}> */}
          <SidebarMenuItem to="#" title="Vendor Payment" hasBullet={true} />
          {/* </Authorize> */}
          <Authorize hasAnyPermission={["MENU_BULK_PAY|VIEW"]}>
            <SidebarMenuItem
              to="/bulk-payment"
              title="Bulk Payment"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize hasAnyPermission={["MENU_STATISTICS|VIEW"]}>
        <SidebarMenuItem
          to="/analytics"
          icon="statistics"
          title="Analytics"
          fontIcon="bi-layers"
        />
      </Authorize>
      <Authorize hasAnyPermission={["MENU_CARDS|VIEW"]}>
        <SidebarMenuItem
          to="/my-card"
          icon="card"
          title="My Cards"
          fontIcon="bi-layers"
        />
      </Authorize>
      <Authorize hasAnyPermission={["MENU_LOAN|VIEW"]}>
        <SidebarMenuItem
          to="/loan"
          icon="loan"
          title="Loan"
          fontIcon="bi-layers"
        />
      </Authorize>
      <Authorize hasAnyPermission={["MENU_STMNT|VIEW"]}>
        <SidebarMenuItem
          to="/statements"
          icon="statements"
          title="Statements"
          fontIcon="bi-layers"
        />
      </Authorize>

      <Authorize
        hasAnyPermission={[
          "MENU_INVOICE|VIEW",
          "MENU_QR_CODE|VIEW",
          "MENU_PAY_LINK|VIEW",
        ]}
      >
        <SidebarMenuItemWithSub
          to="/collection-products"
          icon="payroll"
          title="Collection Products"
        >
          <SidebarMenuItem
            to="/invoice/quotations"
            title="Quotations"
            hasBullet={true}
          />
          <Authorize hasAnyPermission={["MENU_INVOICE|VIEW"]}>
            <SidebarMenuItem
              to="/invoice/invoice-dashboard"
              title="Invoices"
              hasBullet={true}
            />
          </Authorize>
          {/* <Authorize hasAnyPermission={["MENU_INVOICE|VIEW"]}> */}

          {/* </Authorize> */}
          <Authorize hasAnyPermission={["MENU_QR_CODE|VIEW"]}>
            <SidebarMenuItem
              to="/qr/qr-dashboard"
              title="QR Codes"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_PAY_LINK|VIEW"]}>
            <SidebarMenuItem
              to="/payment-link/payment-dashboard"
              title="Payment Links"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize
        hasAnyPermission={["MENU_INVENTRY|VIEW", "MENU_BUSINESS|VIEW"]}
      >
        <SidebarMenuItemWithSub to="/business" icon="payroll" title="Business">
          <Authorize hasAnyPermission={["MENU_INVENTRY|VIEW"]}>
            <SidebarMenuItem
              to="/invoice/inventory"
              title="Inventory"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_BUSINESS|VIEW"]}>
            <SidebarMenuItem
              to="/invoice/customers"
              title="Business"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize hasAnyPermission={["MENU_PAYROLL|VIEW", "MENU_PAYROLL|ADD"]}>
        <SidebarMenuItemWithSub to="/payroll" icon="payroll" title="Payroll">
          <Authorize hasAnyPermission={["MENU_PAYROLL|VIEW"]}>
            <SidebarMenuItem
              to="/payrollemp/payroll-dashboard"
              title="Dashboard"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
            <SidebarMenuItem
              to="/payroll-paysalary"
              title="Pay Salary"
              hasBullet={true}
            />
          </Authorize>
          {/* <Authorize hasAnyPermission={["MENU_PAYROLL|VIEW"]}>
            <SidebarMenuItem
              to="/payroll-settings"
              title="Settings"
              hasBullet={true}
            />
          </Authorize> */}
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize hasAnyPermission={["MENU_COMMN_CENTER|VIEW"]}>
        <SidebarMenuItem
          icon="communication"
          to="/communication-center"
          title="Communication Center"
        />
      </Authorize>

      <Authorize
        hasAnyPermission={["MENU_SR|VIEW", "MENU_VL|VIEW", "MENU_FAQ|VIEW"]}
      >
        <SidebarMenuItemWithSub to="/helpdesk" icon="support" title="Help Desk">
          <Authorize hasAnyPermission={["MENU_FAQ|VIEW"]}>
            <SidebarMenuItem to="/helpdesk/faq" title="FAQ" hasBullet={true} />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_VL|VIEW"]}>
            <SidebarMenuItem
              to="/helpdesk/video-library"
              title="Video Library"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_SR|VIEW"]}>
            <SidebarMenuItem
              to="/helpdesk/service-ticket"
              title="Service Request"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize hasAnyPermission={["MENU_SR|VIEW", "MENU_VL|VIEW"]}>
        <SidebarMenuItemWithSub
          to="/profile"
          icon="support"
          title="Account Profile"
        >
          <Authorize hasAnyPermission={["MENU_SR|VIEW"]}>
            <SidebarMenuItem
              to="/profile/details"
              title="Your Details"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_VL|VIEW"]}>
            <SidebarMenuItem
              to="/profile/companyDetails"
              title="Company Details"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_SR|VIEW"]}>
            <SidebarMenuItem
              to="/profile/partnerDetails"
              title="Your Partners"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>

      <Authorize
        hasAnyPermission={[
          "MENU_TEAM_APPVORALS|VIEW",
          "MENU_TEAM_INITIATION|VIEW",
          "MENU_TEAM_ROLE|VIEW",
          "MENU_TEAM_MEM|VIEW",
        ]}
      >
        <SidebarMenuItemWithSub
          to="/team-banking"
          icon="team"
          title="Team Banking"
        >
          <Authorize hasAnyPermission={["MENU_TEAM_MEM|VIEW"]}>
            <SidebarMenuItem
              to="/team-banking/team-listing"
              title="Team Members"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_TEAM_ROLE|VIEW"]}>
            <SidebarMenuItem
              to="/team-banking/roles"
              title="Roles"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_TEAM_APPVORALS|VIEW"]}>
            <SidebarMenuItem
              to="/team-banking/approvals"
              title="Approvals"
              hasBullet={true}
            />
          </Authorize>
          <Authorize hasAnyPermission={["MENU_TEAM_INITIATION|VIEW"]}>
            <SidebarMenuItem
              to="/team-banking/initiations"
              title="Initiations"
              hasBullet={true}
            />
          </Authorize>
        </SidebarMenuItemWithSub>
      </Authorize>


      <SidebarMenuItemWithSub
        to="/recon"
        icon="support"
        title="Reconciliation"
        fontIcon="bi-layers"
      >
        {/* <Authorize hasAnyPermission={["MENU_TEAM_APPVORALS|VIEW"]}> */}
        <SidebarMenuItem
          to="reconcilation"
          title="Reconciliation"
          hasBullet={true}
        />
        {/* </Authorize> */}
        {/* <Authorize hasAnyPermission={["MENU_TEAM_INITIATION|VIEW"]}> */}
        <SidebarMenuItem
          to="recon-1"
          title="Reconciliation-1"
          hasBullet={true}
        />
        {/* </Authorize> */}
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="#"
        icon="payroll"
        title="Expense Management"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="payroll"
        title="Product Returns"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="payroll"
        title="Cash Flow Manager"
        fontIcon="bi-layers"
      />
       <SidebarMenuItemWithSub
      to="#"
        icon="payroll"
        title="Supply Chain"
      >

        <SidebarMenuItem
           to="#"
          title="Supply Chain Management"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="#"
          title="Group Purchase"
          hasBullet={true}
        />

        

      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
         to="#"
        icon="payroll"
        title="Vault"
      >

        <SidebarMenuItem
           to="#"
          title="Data Vault"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="#"
          title="Document Vault"
          hasBullet={true}
        />

      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
       to="#"
        icon="payroll"
        title="Compliance Zone"
      >

        <SidebarMenuItem
          to="#"
          title="Tax Payment"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="#"
          title="Returns File"
          hasBullet={true}
        />

        <SidebarMenuItem
          to="#"
          title="P&L Statement"
          hasBullet={true}
        />
         <SidebarMenuItem
          to="#"
          title="Balance Sheet"
          hasBullet={true}
        />

      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="#"
        icon="payroll"
        title="Business Insights"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="payroll"
        title="Digital Advisor"
        fontIcon="bi-layers"
      />
     
      <SidebarMenuItemWithSub
       to="#"
        icon="payroll"
        title="Networking Opportunities"
      >

        <SidebarMenuItem
          to="#"
          title="Match Making"
          hasBullet={true}
        />
       
        

      </SidebarMenuItemWithSub>
      

    </>
  );
};

export { SidebarMenuMain };
