/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import { useAuth } from "../core/Auth";
import { PhoneInputs } from "./PhoneInput";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { Id, ToastContainer, toast } from "react-toastify";
import XLogo from ".././../../../_metronic/assets/Icons/xfin.png";
import Swal from "sweetalert2";
import { getAllData } from "./KYC/api";
import { useFormCompletion } from "./KYC/Stepper";
import { PhoneInput } from "react-international-phone";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export let phoneNumber: string = "";
const phoneUtil = PhoneNumberUtil.getInstance();
 export const isPhoneValid = (phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, setToken } = useAuth();
  const [email, setEmail] = useState(false);
  const [Phone, setPhone] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const { resetCompletedForms } = useFormCompletion();

  const navigate = useNavigate();
  const { regUser, setRegUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();

  



  const loginSchema = Yup.object().shape({
    email: Yup.string()
    .email("Wrong email format")
    .min(8, "Minimum 8 characters")
    // .max(50, "Maximum 50 characters")
    // .matches(/^[a-z0-9.@]+$/, "Only letters (a-z), digits (0-9), and periods (.) are allowed")
    .test("is-required", "Email is required", function (value) {
      if (email) {
        return !!value; 
      }
      return true; // If email is false, bypass the required check
    }),
  

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      // .max(50, "Password must not exceed 50 characters")
      // .matches(
      //   /(?=.*[A-Z])/,
      //   "Password must contain at least one uppercase letter"
      // )
      // .matches(
      //   /(?=.*[a-z])/,
      //   "Password must contain at least one lowercase letter"
      // )
      // .matches(
      //   /(?=.*[!@#$%^&*])/,
      //   "Password must contain at least one special character like !@#$%^&*"
      // )
      // .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .required("Password is required"),

    mobile: Yup.string()
      .test("is-required", "Phone number is required", function (value) {
        if (Phone) {
          return !!value; // If Phone is true, check if value is truthy (non-empty)
        }
        return true; // If Phone is false, bypass the required check
      })
      .test("is-valid-phone", "Phone number is invalid", function (value) {
        if (Phone && value) {
          return isPhoneValid(value); // Validate phone only if Phone is true and value exists
        }
        return true; // If Phone is false, or no value, bypass the phone validation
      }),
  });

  const initialValues = {
    email: "",
    password: "",
    mobile: "",
    isdCode: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setRegUser(undefined)
      try {
        let loginValue = email ? values.email.trim() : formik?.values?.mobile;
        const { data: auth } = await login(loginValue, values.password);
        setRegUser((prevState) => ({
          ...prevState,
          ...auth,
          mobile: values?.mobile,
          isdCode: Number(values?.isdCode),
        }));

        if (auth.hasOwnProperty("token") && auth.userStatus === 10) {
          saveAuth(auth);
          setToken(auth);
          setCurrentUser(auth);

          const token = auth.token;
          const privileges = auth.privileges;
          const role = auth.roleName;
          const userDetails = auth.userAdditionalProps;

          sessionStorage.setItem("kt-auth-react-v", JSON.stringify({ token }));
          sessionStorage.setItem("privileges", JSON.stringify({ privileges }));
          sessionStorage.setItem("role", JSON.stringify(role));
          sessionStorage.setItem(
            "userDetails",
            JSON.stringify({ userDetails })
          );
        } else if (auth.kycStatus === "PENDING_APPROVAL") {
          Swal.fire({
            icon: "info",
            title: "Application under review",
            text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else if (auth.kycStatus === "APPROVED") {
          navigate("/auth/approved");
        } else if (auth.kycStatus === "RE_APPLY") {
          markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
          navigate("/auth/reapply", {
            state: { data: auth },
          });
          setRegUser((prevState) => ({
            ...prevState,
            ...auth,
            reapply: true,
          }));
        } else if (auth.kycStatus === "INITIATED") {
          markFormCompleted("registration,verify,password");
          navigate("/auth/terms", {
            state: { data: auth },
          });
          setRegUser((prevState) => ({
            ...prevState,
            ...auth,
            // reapply: true,
          }));
        } else if (auth.kycStatus === "BUSINESS_CREATED") {
          navigate("/auth/documents");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business");
            }
            
          });
        } else if (auth.kycStatus === "BUSINESS_DOCUMENT_UPLOADED") {
          navigate("/auth/applicant");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business,documents");
            }
            
          });
        } else if (auth.kycStatus === "NO_OF_DIRECTORS_SELECTED") {
          navigate("/auth/primary-applicant");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business,documents,applicant");
            }
            
          });
        } else if (auth.kycStatus === "PRIMARY_APPLICANT_INFO_CAPTURED") {
          navigate("/auth/smileId");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant");
            }
            
          });
        } else if (auth.kycStatus === "PRIMARY_APPLICANT_LIVELINESS_VERIFIED") {
          navigate("/auth/signature");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams &&  res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId");
            }
            
          });
        } else if (auth.kycStatus === "PRIMARY_APPLICANT_SIGNATURE_UPLOADED") {
          navigate("/auth/secondary");
          getAllData(auth.token ?? "").then((res) => {
            setRegUser((prevState) => ({
              ...prevState,
              ...res.data.message,
              ...(res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited ? { reapply: true } : {})
            }));
            if (res?.data?.message?.reapplyParams  && res?.data?.message?.kycSubmited) {
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            else{
              markFormCompleted("registration,verify,password,terms,business,documents,applicant,primary-applicant,smileId,signature");
            }
            
          });
        } else if (auth.status.statusCode === 1) {
          toast.error(auth.status.messageDescription, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          setSubmitting(false);
          return;
        } else {
          setStatus("Something went wrong, please check again");
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        if (!navigator.onLine) {
          setStatus("Please check your internet connection and Retry!");
        }
      }
      setLoading(false);
      setSubmitting(false);
    },
  });

  phoneNumber = Phone ? formik.values?.mobile : formik.values.email;

  const [online, setOnline] = useState(navigator.onLine);
  const [toastId, setToastId] = useState<Id | null>(null);

  const handleOnlineStatusChange = () => {
    setOnline(navigator.onLine);
    if (!navigator.onLine) {
      if (!toastId) {
        const id = toast.error("Internet connection lost!");
        setToastId(id);
      }
    } else {
      if (toastId) {
        toast.dismiss(toastId);
        setToastId(null);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [toastId]);

  return (
    <>
      <ToastContainer limit={1}/>

      <form
        className="form "
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
        </div>

        <div>
          <div className="d-flex flex-row gap-10 mb-5">
            <div className="">
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="phone"
                  name="type"
                  id="flexRadioDefault"
                  checked={Phone}
                  onChange={() => {
                    setEmail(false);
                    setPhone(true);
                    formik.resetForm()
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault">
                  Phone
                </label>
              </div>
            </div>
            <div className="">
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="email"
                  name="type"
                  id="flexRadioChecked"
                  checked={email}
                  onChange={() => {
                    setEmail(true);
                    setPhone(false);
                    formik.resetForm()
                  }}
                  

                />

                <label className="form-check-label" htmlFor="flexRadioChecked">
                  Email
                </label>
              </div>
            </div>
          </div>
        </div>

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        {email && (
          <div className="fv-row mb-8">
            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
            <input
              placeholder="Email"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent"
                // { "is-invalid": formik.touched.email && formik.errors.email },
                // { "is-valid": formik.touched.email && !formik.errors.email }
              )}
              type="email"
              name="email"
              autoComplete="off"
              maxLength={50}
              style={{ minWidth: "400px" }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {Phone && (
          <div className="fv-row">
            <label className="form-label fs-6 fw-bolder text-dark">Phone</label>
            {/* <PhoneInputs
              onChange={handleInputChange}
              placeholder="Phone Number"
            /> */}
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "43.59px",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  height: "43.59px",
                },
              }}
              // hideDropdown={true}
              // inputProps={{
              //   readOnly: true,
              // }}
              forceDialCode={true}
              value={formik.values.mobile}
              onChange={(value) => {
                formik.setFieldValue("mobile", value);
              }}
              defaultCountry="in"
            />
          </div>
        )}
        {formik.touched.mobile && formik.errors.mobile && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.mobile}</span>
            </div>
          </div>
        )}
        <div className="fv-row mb-3 mt-8">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent"

                // {
                //   'is-invalid': formik.touched.password && formik.errors.password,
                // },
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              maxLength={20}
            />
            <div
              className="position-absolute end-0 top-50 translate-middle-y me-5"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeSlash /> : <Eye />}
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />
          <Link to="/auth/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
        </div>
        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn"
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          Not a Member yet?{" "}
          <Link to="/auth/registration" onClick={(e) => resetCompletedForms()}>
            Sign up
          </Link>
        </div>
      </form>
    </>
  );
}
