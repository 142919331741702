import { useState, useEffect, useRef } from "react";
import React from "react";
import { KTIcon } from "../../../_metronic/helpers";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikProps,
  useFormik,
} from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
// import { TransferMoneyData, resetFunction } from "./TransferMoney";
import PinInput from "react-pin-input";
// import { E_ReceiptModal } from "./E-Receipt";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ExchangeData } from "../TransferMoney/OverseasTransfer";
import { E_ReceiptModal } from "../TransferMoney/E-Receipt";
import { ReceiptModal } from "./Receipt";
import clsx from "clsx";
import { apiCall } from "./DashboardWrapper";
import OTPInput from "react-otp-input";

interface Bank {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

export interface TransferResponse {
  "Currency From": string;
  Amount: string;
  "Payee Account": string;
  "Converted Amount": string;
  "Exchange Fee": string;
  "Exchange Rate": string;
}

interface FormValues {
  toAccount: string;
  fromAccount: string;
  fromCurrency: string;
  toCurrency: string;
  fromAmount: string;
  toAmount: string;
  pin: string;
}

export function CurrencyConversion({ Data }: { Data: Bank | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const convmodalRef = useRef(null);

  const [accounts, setAccounts] = useState<Bank[]>([]);
  const [convData, setConvData] = useState<ExchangeData>({
    fromCurrency: "",
    toCurrency: "",
    exchangeRate: "",
    amountEntered: "",
    totalFees: "",
    remittancePartnerFees: "",
    ourFees: "",
    amountToBeDeducted: "",
  });
  const [password, setPassword] = useState("");
  const [container, setContainer] = useState(false);
  const [details, setDetails] = useState(false);
  const [successData, setSuccessData] = useState<TransferResponse>({
    "Currency From": "",
    Amount: "",
    "Payee Account": "",
    "Converted Amount": "",
    "Exchange Fee": "",
    "Exchange Rate": "",
  });

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const conversionformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toAccount: "",
      fromAccount: Data ? Data?.accountNumber : "",
      fromCurrency: Data ? Data?.currency : "",
      toCurrency: "",
      fromAmount: "",
      toAmount: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      fromAmount: Yup.number()
        .required("Amount is required")
        .positive("Minimum value should be greater than 1")
        .test({
          name: "balanceCheck",
          test: function (value) {
            const { fromAccount } = this.parent;

            // Check if an account is selected before performing the balance check
            if (!fromAccount) {
              return true; // Return true to indicate no error if no account is selected
            }

            const selectedAccountData = accounts.find(
              (account) => account.accountNumber === fromAccount
            ); // Find the selected account in the accounts array

            // Check if the amount is greater than the selected account balance
            return (
              selectedAccountData &&
              value + +convData?.totalFees <= selectedAccountData.balance
            );
          },
          message:
            "Amount (including conversion fee) must be less than or equal to the selected account balance",
        }),
      toAccount: Yup.string().required("Please choose to account"),
      fromAccount: Yup.string().required("Please choose from account"),
      toAmount: Yup.string().required("To Amount is required"),
    }),
    onSubmit: async (values) => {
      if (values) setContainer(true);
    },
  });

  const handleClickOutsideModal = (event: MouseEvent) => {
    const modalElement = convmodalRef?.current as HTMLElement | null;

    if (modalElement && !modalElement.contains(event.target as Node)) {
      // Clicked outside modal, so hide the modal and reset the form
      handleReset();
      // Additional hide modal logic here
    }
  };

  useEffect(() => {
    // Add click event listener to document to handle clicks outside the modal
    document.addEventListener("click", handleClickOutsideModal);
    return () => {
      // Cleanup: remove the click event listener when the component unmounts
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, []);

  const handleReset = () => {
    conversionformik?.resetForm();
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    setPassword("");
    setDetails(false);
    setContainer(false);
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const getConversionRate = async (
    fromCurrency: string,
    toCurrency: string,
    amountEntered: string
  ) => {
    try {
      const url = `${API_URL}/transaction/getCurrencyConversionRates`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        fromCurrency: fromCurrency,
        toCurrency: toCurrency,
        amountEntered: +amountEntered,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0) {
        setDetails(true);
        conversionformik?.setFieldValue(
          "toAmount",
          data?.message?.amountToBeDeducted
        );
        setConvData(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);

  const doTransfer = async () => {
    try {
      const url = `${API_URL}/transaction/transferBetweenSelfAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: conversionformik?.values?.fromAccount,
        payeeAccountNumber: conversionformik?.values?.toAccount,
        currency: convData?.fromCurrency,
        payeeCurrency: convData?.toCurrency,
        enteredAmount: convData
          ? parseFloat(convData?.amountEntered.replace(/,/g, ""))
          : "",
        benefAmount: convData
          ? parseFloat(convData?.amountToBeDeducted.replace(/,/g, ""))
          : "",
        totalFees: convData?.totalFees,
        exchangeRate: convData?.exchangeRate,
        remarks: "transfer",
        transactionPIN: conversionformik?.values?.pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data?.status?.statusCode === 0) {
        apiCall.submit();
        handleReset();
        getAccounts();
        Swal.fire({
          icon: "success",
          title: "Conversion completed successfully",
          text: `Your conversion from ${data?.message?.currency} to ${data?.message?.payeeCurrency} successful.`,
          showConfirmButton: data?.message?.txnScheduleDate ? false : true,
          showCancelButton: true, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "View E-Receipt",
          cancelButtonColor: "#9fa6b2",
          allowEscapeKey: true,
          allowEnterKey: true,
          cancelButtonText: `${
            data?.message?.txnScheduleDate ? "Ok" : "Close"
          }`, // Text for the cancel button
        }).then((result) => {
          if (result.isConfirmed) {
            handleEReceipt();
          }
        });

        setSuccessData(data?.message?.receipt as TransferResponse);
      }
      if (data?.status?.statusCode === 1) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data?.status?.messageDescription,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (conversionformik?.values?.toAccount) {
      setDetails(false);
      conversionformik?.setFieldValue(
        "toCurrency",
        accounts?.find(
          (acc) => acc?.accountNumber !== conversionformik?.values?.fromAccount
        )?.currency
      );
      conversionformik?.setFieldValue("toAmount", "");
      conversionformik?.setFieldValue("fromAmount", "");
    }
  }, [conversionformik?.values?.fromAccount]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (
        conversionformik?.values?.fromCurrency &&
        conversionformik?.values?.toCurrency
      ) {
        if (conversionformik?.values?.fromAmount) {
          getConversionRate(
            conversionformik?.values?.fromCurrency,
            conversionformik?.values?.toCurrency,
            conversionformik?.values?.fromAmount
          );
        } else {
          getConversionRate(
            conversionformik?.values?.fromCurrency,
            conversionformik?.values?.toCurrency,
            "0"
          );
        }
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [conversionformik?.values?.fromAmount]);

  return (
    <>
      <style>
        {`
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
 .App {
 font-family: sans-serif;
 }
 
 .dropdown {
 margin: 0 auto;
 z-index: 10000;
 width: 180px;
 position: relative;
 border-radius: 50px;
 }
 .dropdown,
 .dropdown * {
 // z-index: 10;
 }
 .dropdown .dropdown-btn {
 cursor: pointer;
 background: white;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px;
 background: white;
 border: 1px solid #ddd;
 border-radius: 20px;
 color: #777;
 font-weight: 500;
 }
 .dropdown-content {
 position: absolute;
 background: white; 
 /* top: 110%; */
 left: 0;
 width: 100%;
 box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
 }
 .dropdown-content .item {
 padding: 10px;
 cursor: pointer;
 }
 .dropdown-content .item:hover {
 background: #fcfcfc;
 }
 
 // button {
 // // z-index: -1;
 // display: block;
 // width: 300px;
 // margin: 0 auto;
 // }
 `}
      </style>

      <div
        ref={convmodalRef}
        id="kt_modal_conversion"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_conversion"
        data-kt-drawer-close="#kt_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_close"
              style={{ height: "24px", width: "24px" }}
              onClick={() => {
                handleReset();
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">
              Currency Conversion
            </h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body">
            <form
              className="form"
              onSubmit={conversionformik.handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="mb-5">
                <label className="form-label" htmlFor="fromAccount">
                  From Account
                </label>
                <select
                  {...conversionformik.getFieldProps("fromAccount")}
                  className="form-control form-select"
                  value={conversionformik.values.fromAccount}
                  onChange={(e) => {
                    console.log(e.target.value);
                    conversionformik.setFieldValue(
                      "fromAccount",
                      e.target.value
                    );
                    conversionformik.setFieldValue(
                      "toAccount",
                      accounts?.find(
                        (acc) => acc?.accountNumber !== e.target.value
                      )?.accountNumber
                    );
                    conversionformik.setFieldValue(
                      "fromCurrency",
                      accounts?.find(
                        (acc) => acc?.accountNumber === e.target.value
                      )?.currency
                    );
                    conversionformik.setFieldValue(
                      "toCurrency",
                      accounts?.find(
                        (acc) => acc?.accountNumber !== e.target.value
                      )?.currency
                    );
                  }}
                >
                  <option value="" disabled>
                    Select Account
                  </option>
                  {accounts?.map((item, index) => (
                    <option key={index} value={item?.accountNumber}>
                      {item?.accountNumber}
                      &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {item.currency}{" "}
                      {item?.balance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </option>
                  ))}
                </select>

                {conversionformik.touched.fromAccount &&
                  conversionformik.errors.fromAccount && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {conversionformik.errors.fromAccount}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
              <div className="mb-5">
                <label className="form-label" htmlFor="toAccount">
                  To Account
                </label>

                <select
                  {...conversionformik.getFieldProps("toAccount")}
                  className="form-control form-select"
                  value={conversionformik.values.toAccount}
                  onChange={(e) => {
                    console.log(e.target.value);

                    conversionformik.setFieldValue("toAccount", e.target.value);
                    conversionformik.setFieldValue(
                      "toCurrency",
                      accounts?.find(
                        (acc) => acc?.accountNumber === e.target.value
                      )?.currency
                    );
                  }}
                  autoFocus={true}
                >
                  <option value="" disabled>
                    Select Account
                  </option>
                  {accounts
                    ?.filter(
                      (acc) =>
                        acc?.accountNumber !=
                        conversionformik?.values?.fromAccount
                    )
                    ?.map((item, index) => (
                      <option
                        key={index}
                        value={item?.accountNumber}
                        className="d-flex justify-content-between"
                      >
                        {item?.accountNumber}
                        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {
                          item.currency
                        }{" "}
                        {item?.balance?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </option>
                    ))}
                </select>
                {conversionformik.touched.toAccount &&
                conversionformik.errors.toAccount && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {conversionformik.errors.toAccount}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              
<div className="mb-5 ">
<label className="form-label " htmlFor="fromAmount">
                From Amount
              </label>

              <div
                className="card shadow-sm border-p d-flex flex-row ps-2 py-2 gap-5 "
                style={{
                  borderRadius: "10px",
                  border: "1px solid #246BFD",
                }}
              >
                <div className="d-flex flex-column ">
                  <div className="d-flex flex-row align-items-center ">
                    <h1 className="m-0 p-2 ms-2 fs-2">
                      {conversionformik?.values?.fromCurrency}
                    </h1>
                    <input
                      {...conversionformik.getFieldProps("fromAmount")}
                      onWheel={(e: any) => e.currentTarget.blur()}
                      className="fs-2 form-control"
                      type="text"
                      name="fromAmount"
                      placeholder="0"
                      style={{
                        border: "none",
                        padding: "7px",
                        outline: "none",
                        fontWeight: "700",
                        pointerEvents:
                          !conversionformik?.values?.fromAccount ||
                          !conversionformik?.values?.toAccount
                            ? "none"
                            : "auto",
                      }}
                      readOnly={
                        !conversionformik?.values?.fromAccount ||
                        !conversionformik?.values?.toAccount
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputValue = e.target.value;
                        if (inputValue === ""|| inputValue === "0") {
                          conversionformik?.setFieldValue("fromAmount", "");
                          return
                        }
                        if (/^\d{1,12}(\.\d{0,2})?$/.test(inputValue)) {
                          conversionformik?.setFieldValue(
                            "fromAmount",
                            inputValue
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {conversionformik.touched.fromAmount &&
                conversionformik.errors.fromAmount && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {conversionformik.errors.fromAmount}
                      </span>
                    </div>
                  </div>
                )}
</div>
              

              <label className="form-label " htmlFor="toAmount">
                To Amount
              </label>
              <div
                className="card shadow-sm border-p d-flex flex-row px-2 py-2 mb-5 gap-5 "
                style={{
                  borderRadius: "10px",
                  border: "1px solid #246BFD",
                }}
              >
                <div className="d-flex flex-column ">
                  <div className="d-flex flex-row align-items-center ">
                    <h1 className="m-0 p-2 ms-2 fs-2">
                      {conversionformik?.values?.toCurrency}
                    </h1>
                    <input
                      {...conversionformik?.getFieldProps("toAmount")}
                      onWheel={(e: any) => e.currentTarget.blur()}
                      className="fs-2 text-dark form-control"
                      type="text"
                      name="toAmount"
                      placeholder="0"
                      style={{
                        border: "none",
                        padding: "7px",
                        outline: "none",
                        fontWeight: "700",
                      }}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              {details && (
                <div className="d-flex flex-column mt-8">
                  <div className="d-flex flex-row ">
                    <p>Exchange Rate:&nbsp;</p>
                    <p>
                      {conversionformik?.values?.fromCurrency} &nbsp;1.00 ={" "}
                      {conversionformik?.values?.toCurrency}{" "}
                      {convData?.exchangeRate}
                    </p>
                  </div>
                  <div className="d-flex flex-row ">
                    <p>Conversion Fee:&nbsp; </p>
                    <p>
                      {conversionformik?.values?.fromCurrency}{" "}
                      {convData?.totalFees}
                    </p>
                  </div>
                </div>
              )}

              <br />
              {container && (
                <div className="card shadow p-5">
                  <div className="text-center mt-5">
                    <h4>Enter Your PIN</h4>
                  </div>
                  <div className="text-center">
                    <p className="mb-3">Enter your PIN to confirm</p>
                  </div>
                  <div className="text-center">
                    <div className="d-flex mb-5 justify-content-center align-items-center">
                      <OTPInput
                        {...conversionformik?.getFieldProps("pin")}
                        value={otp.join("")}
                        onChange={(value: string) => {
                          value
                            .split("")
                            .forEach((char, index) =>
                              handleChange(char, index)
                            );
                        }}
                        numInputs={4}
                        renderSeparator={<span>&nbsp;&nbsp;</span>}
                        renderInput={(props, index) => (
                          <input
                            {...props}
                            value={otp[index]}
                            ref={(el) => (inputRefs.current[index] = el)}
                            onChange={(e) => {
                              const pin = handleChange(e.target.value, index);
                              conversionformik.setFieldValue("pin", pin);
                              setPassword(pin);
                            }}
                            onKeyDown={(e) => {
                              const pin = handleKeyDown(e, index);
                              conversionformik.setFieldValue("pin", pin);
                              setPassword(pin);
                            }}
                            type={show ? "password" : "text"}
                            autoFocus={true}
                            disabled={index !== activeInput}
                            onFocus={() => setActiveInput(index)}
                            className="text-center rounded mt-3 border border-1 border-dark"
                            style={{ height: "50px", width: "50px" }}
                          />
                        )}
                      />
                    </div>
                    <p
                      className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Show PIN" : "Hide PIN"}
                    </p>
                  </div>
                </div>
              )}

              <br />

              <div
                className="d-flex justify-content-center"
                style={{ marginTop: container ? "5px" : "50px" }}
              >
                {password.toString().length === 4 ? (
                  <button
                    type="submit"
                    className="btn rounded"
                    id="kt_close"
                    aria-label="Close"
                    style={{
                      width: "380px",
                    }}
                    onClick={() => {
                      doTransfer();
                    }}
                  >
                    Confirm Conversion
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn rounded"
                    style={{
                      width: "380px",
                    }}
                  >
                    Convert Now
                  </button>
                )}
              </div>
            </form>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
      <ReceiptModal data={successData} />
    </>
  );
}
