/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC, useCallback } from "react";
import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useAuth } from "../../modules/auth/core/Auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "./Roles";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";

interface AddUser {
  firstName: string;
  email: string;
  roleId: string;
  authority: string[];
  limitType?: string;
  zarLimit?: string;
  usdLimit?: string;
}

export interface Document {
  tenantId: string;
  documentGuid: string;
  documentExtention: string;
  documentName: string;
  documentPath: string;
  fileUploadType: number;
  documentUploadedByType: number;
  documentStatus: number;
  referenceType: string;
  referenceId: string;
  documentTypes: number[];
}

export interface AdditionalProperties {
  zarLimit: string;
  authority: string;
  limitType: string;
  usdLimit: string;
}

interface Roles {
  roleGuid: string;
  // tenantId: string;
}

interface Edit {
  email: string;
  firstName: string;
  userGuid: string;
  roles: Roles[];
  additionalProperties: AdditionalProperties;
}

export interface User {
  tenantId: string;
  businessGuid: string;
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  userStatus: number;
  userGuid: string;
  limitType: number;
  zarLimit: number;
  usdLimit: number;
  authority: string[];
  roles: Role[];
  isdCode: number;
  primaryUser: boolean;
  token: string;
  documents: Document[];
  idType: string;
  idNumber: string;
  mobileNumber: string;
  additionalProperties: AdditionalProperties;
  lastLogin: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  createdDateString: string;
}

const TeamListPage: FC = () => {
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const tooltipFilter = <Tooltip id="tooltip-add-money">Filter</Tooltip>;

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [filter, setFilter] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [previousValue, setPreviousValue] = useState("");
  const [showRadio, setShowRadio] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [statusMap, setStatusMap] = useState<{ [key: string]: string }>({});
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [member, setMember] = useState<User>();
  const [authority, setAuthority] = useState<string[]>([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (member) {
      setAuthority(member.authority);
      if (member?.authority?.includes("2")) {
        setShowRadio(true);
      }
      editformik.setValues({
        user_name: member.firstName,
        user_email: member.email,
        user_role: member.roles?.[0]?.roleGuid,
        user_mode: member.authority,
        limitType: member.limitType?.toString(),
        zarLimit: member.zarLimit?.toString(),
        usdLimit: member.usdLimit?.toString(),
      });
    }
  }, [member]);

  const getStatusString = (statusCode: string | number): string => {
    switch (statusCode.toString()) {
      case "10":
        return "Active";
      case "11":
        return "Inactive";
      case "13":
        return "Invite Sent";
      // case "14":
      //   return "Invite Expired";
      default:
        return "Invite Sent";
    }
  };

  const getBootstrapColor = (statusCode: string | number): string => {
    switch (statusCode.toString()) {
      case "10":
        return "success";
      case "11":
        return "secondary";
      case "13":
        return "info";
      // case "14":
      //   return "warning";
      default:
        return "info";
    }
  };

  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_email: "",
      user_role: "",
      user_mode: [],
      limitType: "1",
      zarLimit: "",
      usdLimit: "",
    },
    validationSchema: Yup.object().shape({
      user_email: Yup.string()
        .email("Wrong email format")
        .max(50, "Maximum 50 characters allowed")
        .required("Email is required")
        .matches(/^[A-za-z0-9.@]+$/, "Only alphanumeric characters are allowed")
        .test(
          "valid-domain",
          "Invalid email domain. Allowed domains are .com, .in, .net, .co, .digital",
          (value) => {
            if (!value) {
              return true; // Empty value is handled by the 'required' validation
            }

            const allowedDomains = [".com", ".in", ".net", ".co", ".digital"]; // Add more if needed
            const domain = value.split("@")[1];

            return allowedDomains.some((allowedDomain) =>
              domain?.endsWith(allowedDomain)
            );
          }
        ),

      user_name: Yup.string()
        .min(2, "Minimum 2 characters required")
        .max(50, "Maximum 50 characters allowed")
        .matches(
          /^(?=.*[a-zA-Z])[\sa-zA-Z0-9]*$/,
          "Must contain at least one alphabetic character"
        )
        .matches(
          /^[\sa-zA-Z0-9]*$/,
          "Only alphanumeric characters and spaces are allowed"
        )
        .test("no-only-spaces", "Cannot contain only spaces", (value) => {
          if (typeof value === "string") {
            return !/^\s+$/.test(value);
          }
          return false;
        })
        .trim()
        .required("Name is required"),
      user_role: Yup.string().required("Role is required"),
      user_mode: Yup.array()
        .min(1, "Select at least one mode of operation")
        .required("Select any mode of operation"),
      limitType: showRadio
        ? Yup.string().required("Select limit type")
        : Yup.string().notRequired(),
      zarLimit: Yup.string()
        .required("Please enter ZAR limit")
        .test(
          "zar-limit-validation",
          "ZAR limit should be greater than 0",
          function (value) {
            const { limitType } = this.parent;

            if (limitType === "3" && value) {
              return +value > 0;
            }

            return true;
          }
        )
        .notRequired(),
      usdLimit: Yup.string()
        .required("Please enter USD limit")
        .test(
          "usd-limit-validation",
          "USD limit should be greater than 0",
          function (value) {
            const { limitType } = this.parent;

            if (limitType === "3" && value) {
              return +value > 0;
            }

            return true;
          }
        )
        .notRequired(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        firstName: values?.user_name,
        email: values?.user_email,
        roleId: values.user_role,
        authority: values.user_mode,
        limitType: values.limitType,
        ...(values.limitType === "2" && {
          zarLimit: values.zarLimit,
          usdLimit: values.usdLimit,
        }),
      };

      try {
        setSubmitting(true);
        addUser(data);
        // formik.resetForm();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      setRole("");
      setAuthority([]);
      setShowRadio(false);
    },
  });

  useEffect(() => {
    listUsers();
  }, [filter]);

  const filterformik = useFormik({
    initialValues: {
      role: "",
      status: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      setFilter((prev) => prev + 1);
      // listUsers();
    },
    onReset: async () => {
      setFilter(0);
      // listUsers();
    },
  });

  const editformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_name: member?.firstName || "",
      user_email: member?.email || "",
      user_role: member?.roles[0].roleGuid || "",
      user_mode: member?.authority || [],
      limitType: member?.limitType?.toString() || "",
      zarLimit: member?.zarLimit?.toString() || "",
      usdLimit: member?.usdLimit?.toString() || "",
    },
    validationSchema: Yup.object().shape({
      user_email: Yup.string()
        .email("Wrong email format")
        .max(50, "Maximum 70 characters allowed")
        .required("Email is required")
        .matches(/^[A-za-z0-9.@]+$/, "Only alphanumeric characters are allowed")
        .test(
          "valid-domain",
          "Invalid email domain. Allowed domains are .com, .in, .net, .co, .digital",
          (value) => {
            if (!value) {
              return true; // Empty value is handled by the 'required' validation
            }

            const allowedDomains = [".com", ".in", ".net", ".co", ".digital"]; // Add more if needed
            const domain = value.split("@")[1];

            return allowedDomains.some((allowedDomain) =>
              domain?.endsWith(allowedDomain)
            );
          }
        ),

      user_name: Yup.string()
        .max(50, "Maximum 50 characters allowed")
        .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric characters are allowed")
        .required("Name is required"),
      user_role: Yup.string().required("Role is required"),
      user_mode: Yup.array()
        .min(1, "Select at least one mode of operation")
        .required("Select any mode of operation"),
      limitType: showRadio
        ? Yup.string().required("Select limit type")
        : Yup.string().notRequired(),
      zarLimit: Yup.string()
        .required("Please enter ZAR limit")
        .test(
          "zar-limit-validation",
          "ZAR limit should be greater than 0",
          function (value) {
            const { limitType } = this.parent;

            if (limitType === "3" && value) {
              return +value > 0;
            }

            return true;
          }
        )
        .notRequired(),
      usdLimit: Yup.string()
        .required("Please enter USD limit")
        .test(
          "usd-limit-validation",
          "USD limit should be greater than 0",
          function (value) {
            const { limitType } = this.parent;

            if (limitType === "3" && value) {
              return +value > 0;
            }

            return true;
          }
        )
        .notRequired(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        firstName: values?.user_name,
        email: values?.user_email,
        userGuid: member?.userGuid || "",
        roles: [
          {
            roleGuid: values.user_role,
            // tenantId: member?.tenantId,
          },
        ],

        additionalProperties: {
          authority:
            roles.find((r) => r.roleGuid === values.user_role)?.name ===
            "ROLE_SME_ADMIN"
              ? "1"
              : values.user_mode.join(","),
          limitType:
            roles.find((r) => r.roleGuid === values.user_role)?.name ===
            "ROLE_SME_ADMIN"
              ? "1"
              : values.limitType,
          zarLimit:
            roles.find((r) => r.roleGuid === values.user_role)?.name ===
            "ROLE_SME_ADMIN"
              ? ""
              : values.zarLimit,
          usdLimit:
            roles.find((r) => r.roleGuid === values.user_role)?.name ===
            "ROLE_SME_ADMIN"
              ? ""
              : values.usdLimit,
        },
      };

      try {
        setSubmitting(true);
        editUser(data);
        editformik.resetForm();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      setRole("");
      setAuthority([]);
      setShowRadio(false);
    },
  });

  const handleAdd = () => {
    const modalElement = document.getElementById("kt_modal_add_user");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleEditClick = async (userGuid: string) => {
    await fetchUser(userGuid);
    const modalElement = document.getElementById("kt_modal_edit_user");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      setPreviousValue("");
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      // Assuming `updateData` is your function to update the state
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = teamMembers?.filter((member) => {
      // Filter based on search query
      const isSearchMatched = member.firstName
        .toLowerCase()
        .includes(searchValue.toLowerCase());

      // Return true if both conditions are matched
      return isSearchMatched;
    });

    // Update filtered transactions
    setFilteredMembers(filteredData);
  }, [teamMembers, searchValue]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredMembers?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredMembers?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleRoleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setRole(e.target.value);
    setShowRadio(false);

    if (
      roles.find((r) => r.roleGuid === e.target.value)?.name ===
      "ROLE_SME_ADMIN"
    ) {
      setAuthority(["1"]);
      formik.setFieldValue("user_mode", ["1"]);
      editformik.setFieldValue("user_mode", ["1"]);
    } else {
      setAuthority([""]);
      formik.setFieldValue("user_mode", [""]);
      editformik.setFieldValue("user_mode", [""]);
    }
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, mode: string) => {
    const isChecked = e.target.checked;

    setAuthority((prevAuthority) => {
      let newAuthority = prevAuthority.filter((item) => item !== "");
      if (isChecked) {
        newAuthority.push(mode);
      } else {
        newAuthority = newAuthority.filter((m) => m !== mode);
      }
      console.log(newAuthority);

      formik.setFieldValue("user_mode", newAuthority);
      editformik.setFieldValue("user_mode", newAuthority);
      return newAuthority;
    });

    if (mode === "3") {
      setShowRadio(isChecked);
    }
  };

  const listUsers = async () => {
    try {
      const url = `${API_URL}/team/viewRoleUsers`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...(filterformik.values.role &&
          filter && {
            roleName: filterformik.values.role,
          }),
        ...(filterformik.values.status &&
          filter && {
            status: +filterformik.values.status,
          }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setTeamMembers(data.results);
        setStatusMap(data.statusMap);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addUser = async (userdata: AddUser) => {
    try {
      const url = `${API_URL}/team/createUser`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify(userdata);

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        listUsers();
        formik.resetForm();
        const modal = Modal.getInstance("#kt_modal_add_user");
        if (modal) {
          modal.hide();
        }

        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `Invite sent successfully to ${userdata.email}`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      if (data.status.statusCode === 1) {
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: `${msg}`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const editUser = async (userdata: Edit) => {
    try {
      const url = `${API_URL}/team/changeUserDetails`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify(userdata);

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        listUsers();
        formik.resetForm();
        const modal = Modal.getInstance("#kt_modal_edit_user");
        if (modal) {
          modal.hide();
        }
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `User details updated successfully`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      if (data.status.statusCode === 1) {
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: `${msg}`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchRoles = async () => {
    try {
      const url = `${API_URL}/team/getRoles`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setRoles(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async (userGuid: string) => {
    try {
      const url = `${API_URL}/team/findUser`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ userGuid: userGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setRole(data.message.roleGuid);
        setMember(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reInvite = async (userGuid: string) => {
    try {
      const url = `${API_URL}/team/reInvite`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ userGuid: userGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Invite sent successfully",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // setMember(data.message);
      }
      if (data.status.statusCode === 1) {
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: msg,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (userGuid: string) => {
    try {
      const url = `${API_URL}/team/deleteUser`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ userGuid: userGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        listUsers();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "User deleted",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // setMember(data.message);
      }
      if (data.status.statusCode === 1) {
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: msg,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoles();
    listUsers();
  }, []);

  return (
    <>
      <h3 className="mb-5">Team Members</h3>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="">
          <div className="card p-5">
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-1 align-items-center gap-10 me-5">
                {filter > 0 && (
                  <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                    <p
                      className="text-danger text-hover-danger fw-bold"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => filterformik.resetForm()}
                    >
                      Clear Filters
                    </p>
                  </div>
                )}
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    name="searchQuery"
                    value={searchValue}
                    className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                    data-kt-docs-table-filter="search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search Users"
                    autoComplete="new-password"
                  />
                </div>
                <Authorize hasAnyPermission={["MENU_TEAM_MEM|ADD"]}>
                  <button
                    className="btn fs-7 p-3 px-5"
                    onClick={() => {
                      formik.resetForm();
                      handleAdd();
                    }}
                  >
                    <i className="ki-duotone ki-plus fs-2"></i>Add User
                  </button>
                </Authorize>
                <div className="mt-1">
                  <OverlayTrigger placement="top" overlay={tooltipFilter}>
                    <i
                      className="bi bi-funnel fs-2 cursor-pointer"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    ></i>
                  </OverlayTrigger>
                  <div
                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                    id="filter_dropdown"
                  >
                    <div className="px-7 py-5">
                      <div className="fs-5 text-gray-900 fw-bolder">
                        Filter Options
                      </div>
                    </div>
                    <div className="separator border-gray-200"></div>

                    <form onSubmit={filterformik.handleSubmit}>
                      <div
                        className="px-7 py-5"
                        data-kt-user-table-filter="form"
                      >
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">
                            Role:
                          </label>
                          <select
                            className={`form-select form-select-solid fw-bolder `}
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            data-kt-user-table-filter="role"
                            data-hide-search="true"
                            onChange={filterformik.handleChange}
                            onBlur={filterformik.handleBlur}
                            value={filterformik.values.role}
                            name="role"
                          >
                            <option value="">Select role</option>
                            {roles?.map((role, index) => (
                              <option key={index} value={role?.name}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                          {/* {filterformik.touched.role &&
                            filterformik.errors.role && (
                              <div className="invalid-feedback">
                                {filterformik.errors.role}
                              </div>
                            )} */}
                        </div>

                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">
                            Status:
                          </label>
                          <select
                            className={`form-select form-select-solid fw-bolder `}
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            data-kt-user-table-filter="status"
                            data-hide-search="true"
                            onChange={filterformik.handleChange}
                            onBlur={filterformik.handleBlur}
                            value={filterformik.values.status}
                            name="status"
                          >
                            <option value="">Select status</option>
                            {Object.entries(statusMap)
                              .filter(([key, value]) =>
                                ["10", "11", "13"].includes(key)
                              )
                              .map(([key, value]) => (
                                <option key={key} value={key}>
                                  {value}
                                </option>
                              ))}
                          </select>
                          {/* Uncomment the following block if you use Formik for form handling */}
                          {/* {filterformik.touched.role &&
                            filterformik.errors.role && (
                              <div className="invalid-feedback">
                                {filterformik.errors.role}
                              </div>
                            )} */}
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                            data-kt-menu-dismiss="true"
                            data-kt-user-table-filter="reset"
                            onClick={() => filterformik.resetForm()}
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary fw-bold px-6"
                            data-kt-menu-dismiss="true"
                            data-kt-user-table-filter="filter"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card p-2"
              style={{
                overflow: "scroll",
              }}
            >
              <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                <thead className="bg-gray-200">
                  <tr className="text-start text-dark-600 fw-bold fs-6">
                    <th className="min-w-125px p-5">Name</th>
                    <th
                      className="min-w-125px p-5 text-wrap"
                      style={{
                        maxWidth: "250px",
                      }}
                    >
                      Email
                    </th>
                    <th className="min-w-125px p-5">Role</th>
                    <th className="min-w-125px p-5">Last login</th>
                    <th className="min-w-125px p-5">Date</th>
                    <th className="min-w-125px p-5">Status</th>
                    <th className=" min-w-100px p-5">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData && currentData.length > 0 ? (
                    currentData?.map((user, index) => (
                      <tr
                        key={index}
                        onMouseOver={(e) => {
                          const target = e.currentTarget as HTMLElement;
                          target.style.backgroundColor = "#f1f1f2";
                          // target.style.transform = "translate3d(6px, -6px, 0)";
                          // target.style.boxShadow =
                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                        }}
                        onMouseOut={(e) => {
                          const target = e.currentTarget as HTMLElement;
                          target.style.backgroundColor = "white";
                          // target.style.transform = "none";
                          // target.style.boxShadow = "none";
                        }}
                      >
                        <td className="p-5 text-gray-600">{user?.firstName}</td>
                        <td
                          className="p-5 text-gray-600 text-wrap"
                          style={{
                            maxWidth: "250px",
                          }}
                        >
                          {user?.email}
                        </td>
                        <td className="p-5 text-gray-600">
                          {user?.roles.map((role, index) => (
                            <span key={index}>{role?.name}</span>
                          ))}
                        </td>
                        <td className="p-5 text-gray-600">
                          <div className="badge badge-light fw-bold">
                            {user?.lastLogin}
                          </div>
                        </td>

                        <td className="p-5 text-gray-600">
                          {user?.createdDateString.split(",")[0]}
                        </td>
                        <td className="p-5 text-gray-600">
                          <span
                            className={`badge badge-light-${getBootstrapColor(
                              user.userStatus
                            )} fw-bold`}
                          >
                            {getStatusString(user.userStatus)}
                          </span>
                          <p
                            className="my-2 fs-8 ms-5 text-decoration-underline text-hover-primary cursor-pointer"
                            onClick={() => {
                              Swal.fire({
                                icon: "question",
                                title: "Resend Invite",
                                text: "Are you sure you want to re-send the invite",
                                showCancelButton: true,
                                cancelButtonText: "No",
                                confirmButtonText: "Yes",
                                allowEscapeKey: true,
                                allowEnterKey: true,
                              }).then((res) => {
                                if (res.isConfirmed) {
                                  reInvite(user.userGuid);
                                }
                              });
                            }}
                          >
                            {getStatusString(user.userStatus) !== "Active"
                              ? "Resend link"
                              : ""}
                          </p>
                        </td>
                        <td className="p-5 text-gray-600">
                          <div className="d-flex flex-row gap-5">
                            {user.userStatus === 10 ||
                            user.userStatus === 15 ? (
                              <div>
                                <i
                                  className="ki-outline ki-eye fs-4"
                                  data-bs-placement="top"
                                  style={{ cursor: "pointer" }}
                                  title="View"
                                  onClick={() =>
                                    navigate(
                                      "/team-banking/team-listing/viewuser",
                                      {
                                        state: {
                                          userData: user?.userGuid,
                                          roleGuid: user?.roles[0].roleGuid,
                                        },
                                      }
                                    )
                                  }
                                ></i>
                              </div>
                            ) : (
                              <div>
                                <i
                                  className="ki-outline ki-eye fs-4 text-secondary"
                                  data-bs-placement="top"
                                  style={{ cursor: "pointer" }}
                                  title="View"
                                ></i>
                              </div>
                            )}
                            <Authorize
                              hasAnyPermission={["MENU_TEAM_MEM|EDIT"]}
                            >
                              <div>
                                <i
                                  className="ki-outline ki-pencil fs-4"
                                  data-bs-placement="top"
                                  style={{ cursor: "pointer" }}
                                  title="Edit"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#kt_modal_edit_user"
                                  onClick={() => {
                                    setShowRadio(true);
                                    handleEditClick(user.userGuid);
                                  }}
                                ></i>
                              </div>
                            </Authorize>
                            <Authorize
                              hasAnyPermission={["MENU_TEAM_MEM|DELETE"]}
                            >
                              {!user.primaryUser && (
                                <div>
                                  <i
                                    className="ki-outline ki-trash fs-4"
                                    data-bs-placement="top"
                                    style={{ cursor: "pointer" }}
                                    title="Delete"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation(); // Stop the event from propagating to parent elements
                                      (async () => {
                                        const confirmationResult =
                                          await Swal.fire({
                                            title: "Confirmation!",
                                            text: "Are you sure want to delete the user?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: "Cancel",
                                            confirmButtonText: "Confirm",
                                            allowEscapeKey: true,
                                            allowEnterKey: true,
                                          });

                                        if (confirmationResult.isConfirmed) {
                                          deleteUser(user.userGuid);
                                        }
                                      })();
                                    }}
                                  ></i>
                                </div>
                              )}
                            </Authorize>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage ===
                        Math.ceil(filteredMembers?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow 
                        ${
                          currentPage ===
                          Math.ceil(filteredMembers?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }
                      `}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(
                            Math.ceil(filteredMembers?.length / itemsPerPage)
                          )
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {filteredMembers?.length}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="kt_modal_add_user"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content" style={{ maxHeight: "700px" }}>
                <div className="modal-header" id="kt_modal_add_user_header">
                  <h2 className="fw-bold">Add User</h2>

                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary text-hover-primary"
                    data-kt-users-modal-action="close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    {" "}
                    <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                  </div>
                </div>

                <div className="modal-body mx-5 mx-xl-15 my-7 px-5">
                  <form
                    id="kt_modal_add_user_form"
                    className="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <div
                      className="d-flex flex-column scroll-y me-n7 pe-7 mh-450px"
                      id="kt_modal_add_user_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_user_header"
                      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Full Name
                        </label>

                        <input
                          placeholder="Full name"
                          {...formik.getFieldProps("user_name")}
                          type="text"
                          name="user_name"
                          maxLength={50}
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                formik.touched.user_name &&
                                formik.errors.user_name,
                            },
                            {
                              "is-valid":
                                formik.touched.user_name &&
                                !formik.errors.user_name,
                            }
                          )}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.user_name}
                          autoComplete="off"
                        />
                        {formik.touched.user_name && formik.errors.user_name && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.user_name}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Email
                        </label>

                        <input
                          placeholder="Email"
                          maxLength={50}
                          {...formik.getFieldProps("user_email")}
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                formik.touched.user_email &&
                                formik.errors.user_email,
                            },
                            {
                              "is-valid":
                                formik.touched.user_email &&
                                !formik.errors.user_email,
                            }
                          )}
                          type="email"
                          name="user_email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.user_email}
                          autoComplete="off"
                        />

                        {formik.touched.user_email && formik.errors.user_email && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.user_email}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Role
                        </label>

                        <select
                          {...formik.getFieldProps("user_role")}
                          name="user_role"
                          aria-label="Select a Timezone"
                          data-control="select2"
                          data-placeholder="Period"
                          className="form-select form-select-sm form-select-solid"
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleRoleSelect(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.user_role}
                        >
                          <option selected>Select a role</option>
                          {roles?.map((role) => (
                            <option value={role.roleGuid} key={role.roleGuid}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.user_role && formik.errors.user_role && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.user_role}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {!(
                        roles.find((r) => r.roleGuid === role)?.name ===
                        "ROLE_SME_ADMIN"
                      ) && (
                        <div className="mb-7">
                          <label className="required fw-bold fs-6">
                            Mode of operation
                          </label>

                          <div className="d-flex flex-row gap-5 pt-5">
                            <label
                              className="form-check form-check-sm form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  authority?.includes("2") ||
                                  authority?.includes("3") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  authority?.includes("2") ||
                                  authority?.includes("3") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...formik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                checked={authority?.includes("1")}
                                onChange={(e) => handleCheckbox(e, "1")}
                                disabled={
                                  authority?.includes("2") ||
                                  authority?.includes("3") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Single
                              </span>
                            </label>
                            <label
                              className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...formik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                checked={authority?.includes("2")}
                                onChange={(e) => handleCheckbox(e, "2")}
                                disabled={
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Initiator
                              </span>
                            </label>
                            <label
                              className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...formik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                checked={authority?.includes("3")}
                                onChange={(e) => {
                                  handleCheckbox(e, "3");
                                }}
                                disabled={
                                  authority?.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Approver
                              </span>
                            </label>
                          </div>
                          {formik.touched.user_mode && formik.errors.user_mode && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.user_mode}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {showRadio ? (
                        <div className="mb-7">
                          <label className="required fw-bold fs-6">
                            Set Approver Limit
                          </label>
                          <div className="d-flex flex-row gap-5 pt-5">
                            <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                              <input
                                {...formik.getFieldProps("limitType")}
                                className="form-check-input"
                                type="radio"
                                name="limitType"
                                value={1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultChecked
                              />
                              <span className="form-check-label text-dark">
                                Any
                              </span>
                            </label>
                            <label className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9">
                              <input
                                {...formik.getFieldProps("limitType")}
                                className="form-check-input"
                                type="radio"
                                name="limitType"
                                value={2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <span className="form-check-label text-dark">
                                Upto
                              </span>
                            </label>
                          </div>
                          {formik.touched.limitType && formik.errors.limitType && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.limitType}
                                </span>
                              </div>
                            </div>
                          )}
                          {formik.values.limitType === "2" ? (
                            <div className="d-flex flex-row gap-5 mt-5">
                              <div>
                                <div>
                                  <div>
                                    {" "}
                                    <span
                                      className="fs-6 fw-bold text-gray-700"
                                      style={{
                                        position: "absolute",
                                        padding: "12.5px",
                                      }}
                                    >
                                      {baseCurrency}
                                    </span>
                                  </div>
                                  <input
                                    {...formik.getFieldProps("zarLimit")}
                                    type="text"
                                    className="form-control custom-input"
                                    value={formik.values.zarLimit}
                                    style={{ paddingLeft: "60px" }}
                                    // To ensure numeric keyboard on mobile devices
                                    max="100000"
                                    pattern="[0-9]*"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "zarLimit",
                                        handleAmountChange(e)
                                      );
                                    }}
                                  />
                                </div>
                                {formik.touched.zarLimit &&
                                  formik.errors.zarLimit && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.zarLimit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div>
                                <div>
                                  <div>
                                    {" "}
                                    <span
                                      className="fs-6 fw-bold text-gray-700"
                                      style={{
                                        position: "absolute",
                                        padding: "12.5px",
                                      }}
                                    >
                                      USD
                                    </span>
                                  </div>
                                  <input
                                    {...formik.getFieldProps("usdLimit")}
                                    type="text"
                                    className="form-control custom-input"
                                    value={formik.values.usdLimit}
                                    style={{ paddingLeft: "60px" }}
                                    // To ensure numeric keyboard on mobile devices
                                    max="100000"
                                    pattern="[0-9]*"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "usdLimit",
                                        handleAmountChange(e)
                                      );
                                    }}
                                  />
                                </div>
                                {formik.touched.usdLimit &&
                                  formik.errors.usdLimit && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.usdLimit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>

                    <div className="text-center pt-15">
                      <button
                        type="reset"
                        onClick={() => formik.resetForm()}
                        className="btn btn-light me-3"
                        data-kt-users-modal-action="cancel"
                      >
                        Discard
                      </button>

                      <button type="submit" className="btn btn-primary">
                        <span className="indicator-label">Send Invite</span>
                        {formik.isSubmitting && (
                          <span className="indicator-progress">
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="kt_modal_edit_user"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content" style={{ maxHeight: "700px" }}>
                <div className="modal-header" id="kt_modal_edit_user_header">
                  <h2 className="fw-bold">Edit User</h2>

                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary text-hover-primary"
                    data-kt-users-modal-action="close"
                    data-bs-dismiss="modal"
                  >
                    <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                  </div>
                </div>
                <div className="modal-body mx-5 mx-xl-15 my-7 px-5">
                  <form
                    id="kt_modal_add_user_form"
                    className="form"
                    onSubmit={editformik.handleSubmit}
                    noValidate
                  >
                    <div
                      className="d-flex flex-column scroll-y me-n7 pe-7 mh-450px"
                      id="kt_modal_add_user_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_user_header"
                      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Full Name
                        </label>
                        <input
                          placeholder="Full name"
                          maxLength={50}
                          {...editformik.getFieldProps("user_name")}
                          type="text"
                          name="user_name"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                editformik.touched.user_name &&
                                editformik.errors.user_name,
                              "is-valid":
                                editformik.touched.user_name &&
                                !editformik.errors.user_name,
                            }
                          )}
                          onChange={editformik.handleChange}
                          onBlur={editformik.handleBlur}
                          value={editformik.values.user_name}
                          autoComplete="off"
                          readOnly
                        />
                        {editformik.touched.user_name &&
                          editformik.errors.user_name && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {editformik.errors.user_name}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Email
                        </label>
                        <input
                          placeholder="Email"
                          maxLength={70}
                          {...editformik.getFieldProps("user_email")}
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                editformik.touched.user_email &&
                                editformik.errors.user_email,
                              "is-valid":
                                editformik.touched.user_email &&
                                !editformik.errors.user_email,
                            }
                          )}
                          type="email"
                          name="user_email"
                          onChange={editformik.handleChange}
                          onBlur={editformik.handleBlur}
                          value={editformik.values.user_email}
                          autoComplete="off"
                          readOnly
                        />
                        {editformik.touched.user_email &&
                          editformik.errors.user_email && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {editformik.errors.user_email}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Role
                        </label>
                        <select
                          {...editformik.getFieldProps("user_role")}
                          name="user_role"
                          aria-label="Select a Timezone"
                          data-control="select2"
                          data-placeholder="Period"
                          className="form-select form-select-sm form-select-solid"
                          onChange={(e) => {
                            editformik.handleChange(e);
                            handleRoleSelect(e);
                          }}
                          onBlur={editformik.handleBlur}
                          value={editformik.values.user_role}
                        >
                          <option selected>Select a role</option>
                          {roles?.map((role) => (
                            <option value={role.roleGuid} key={role.roleGuid}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        {editformik.touched.user_role &&
                          editformik.errors.user_role && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {editformik.errors.user_role}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      {!(
                        roles.find(
                          (r) => r.roleGuid === editformik.values.user_role
                        )?.name === "ROLE_SME_ADMIN"
                      ) && (
                        <div className="mb-7">
                          <label className="required fw-bold fs-6">
                            Mode of operation
                          </label>
                          <div className="d-flex flex-row gap-5 pt-5">
                            <label
                              className="form-check form-check-sm form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  editformik.values.user_mode.includes("2") ||
                                  editformik.values.user_mode.includes("3") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  editformik.values.user_mode.includes("2") ||
                                  editformik.values.user_mode.includes("3") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...editformik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                value="1"
                                checked={editformik.values.user_mode.includes(
                                  "1"
                                )}
                                onChange={(e) => {
                                  handleCheckbox(e, "1");
                                }}
                                disabled={
                                  editformik.values.user_mode.includes("2") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Single
                              </span>
                            </label>
                            <label
                              className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...editformik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                value="2"
                                checked={editformik.values.user_mode.includes(
                                  "2"
                                )}
                                onChange={(e) => handleCheckbox(e, "2")}
                                disabled={
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Initiator
                              </span>
                            </label>
                            <label
                              className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9"
                              style={{
                                pointerEvents:
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "none"
                                    : "all",
                                textDecoration:
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <input
                                {...editformik.getFieldProps("user_mode")}
                                className="form-check-input"
                                type="checkbox"
                                name="user_mode"
                                value="3"
                                checked={editformik.values.user_mode.includes(
                                  "3"
                                )}
                                onChange={(e) => {
                                  setShowRadio(!showRadio);
                                  handleCheckbox(e, "3");
                                }}
                                disabled={
                                  editformik.values.user_mode.includes("1") ||
                                  roles.find((r) => r.roleGuid === role)
                                    ?.name === "ROLE_SME_ADMIN" ||
                                  roles.find(
                                    (r) =>
                                      r.roleGuid === editformik.values.user_role
                                  )?.name === "ROLE_SME_ADMIN"
                                }
                              />
                              <span className="form-check-label text-dark">
                                Approver
                              </span>
                            </label>
                          </div>
                          {editformik.errors.user_mode && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {editformik.errors.user_mode}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {editformik.values.user_mode.includes("3") &&
                        roles.find(
                          (r) => r.roleGuid === editformik.values.user_role
                        )?.name !== "ROLE_SME_ADMIN" && (
                          <div className="mb-7">
                            <label className="required fw-bold fs-6">
                              Set Approver Limit
                            </label>
                            <div className="d-flex flex-row gap-5 pt-5">
                              <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                                <input
                                  {...editformik.getFieldProps("limitType")}
                                  className="form-check-input"
                                  type="radio"
                                  name="limitType"
                                  value="1"
                                  checked={editformik.values.limitType === "1"}
                                  onChange={editformik.handleChange}
                                />
                                <span className="form-check-label text-dark">
                                  Any
                                </span>
                              </label>
                              <label className="form-check form-check-sm ms-5 form-check-custom form-check-solid me-9">
                                <input
                                  {...editformik.getFieldProps("limitType")}
                                  className="form-check-input"
                                  type="radio"
                                  name="limitType"
                                  value="2"
                                  checked={editformik.values.limitType === "2"}
                                  onChange={editformik.handleChange}
                                />
                                <span className="form-check-label text-dark">
                                  Upto
                                </span>
                              </label>
                            </div>
                            {editformik.errors.limitType && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {editformik.errors.limitType}
                                  </span>
                                </div>
                              </div>
                            )}
                            {editformik.values.limitType === "2" &&
                              roles.find(
                                (r) =>
                                  r.roleGuid === editformik.values.user_role
                              )?.name !== "ROLE_SME_ADMIN" && (
                                <div className="d-flex flex-row gap-5 mt-5">
                                  <div>
                                    <div>
                                      <span
                                        className="fs-6 fw-bold text-gray-700"
                                        style={{
                                          position: "absolute",
                                          padding: "12.5px",
                                        }}
                                      >
                                        {baseCurrency}
                                      </span>
                                      <input
                                        {...editformik.getFieldProps(
                                          "zarLimit"
                                        )}
                                        type="text"
                                        className="form-control custom-input"
                                        value={editformik.values.zarLimit}
                                        style={{ paddingLeft: "60px" }}
                                        max="100000"
                                        pattern="[0-9]*"
                                        onChange={(e) => {
                                          editformik.setFieldValue(
                                            "zarLimit",
                                            handleAmountChange(e)
                                          );
                                        }}
                                      />
                                    </div>
                                    {editformik.touched.zarLimit &&
                                      editformik.errors.zarLimit && (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            <span role="alert">
                                              {editformik.errors.zarLimit}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <div>
                                    <div>
                                      <span
                                        className="fs-6 fw-bold text-gray-700"
                                        style={{
                                          position: "absolute",
                                          padding: "12.5px",
                                        }}
                                      >
                                        USD
                                      </span>
                                      <input
                                        {...editformik.getFieldProps(
                                          "usdLimit"
                                        )}
                                        type="text"
                                        className="form-control custom-input"
                                        value={editformik.values.usdLimit}
                                        style={{ paddingLeft: "60px" }}
                                        max="100000"
                                        pattern="[0-9]*"
                                        onChange={(e) => {
                                          editformik.setFieldValue(
                                            "usdLimit",
                                            handleAmountChange(e)
                                          );
                                        }}
                                      />
                                    </div>
                                    {editformik.touched.usdLimit &&
                                      editformik.errors.usdLimit && (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            <span role="alert">
                                              {editformik.errors.usdLimit}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                    </div>
                    <div className="text-center pt-15">
                      <button
                        type="reset"
                        onClick={() => {
                          if (member) fetchUser(member?.userGuid);
                        }}
                        className="btn btn-light me-3"
                        data-kt-users-modal-action="cancel"
                      >
                        Reset
                      </button>
                      <button type="submit" className="btn btn-primary">
                        <span className="indicator-label">Update</span>
                        {editformik.isSubmitting && (
                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TeamListPage };
