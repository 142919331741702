import { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Document, User } from "./TeamListing";
import { KTIcon } from "../../../_metronic/helpers";

export interface Session {
  userId: string;
  userName: string;
  tenantId: string;
  customerId: string;
  createdDate: string;
  updatedDate: string;
  channel: string;
  eventCategory: string;
  eventName: string;
  ipAddress: string;
  location: string;
  action: string;
  device: string;
  deviceId: string;
  sessionId: string;
  traceId: string;
  actionStatus: string;
  requestStatus: string;
  failureResponse: string;
  receipt: Receipt;
}
export interface Receipt {
  "Activity Id": string;
  "Business Id": string;
  "User Id": string;
  "User Name": string;
  Channel: string;
  "Date & Time": string;
  "Event Category": string;
  "Event Name": string;
  IP: string;
  Location: string;
  "Action Type": string;
  Device: string;
  "Device Id": string;
  "Session Id": string;
  "Response Time": string;
  "Action Status": string;
  "Response Status": string;
  "Failure Message": string;
}
const ViewUser = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const userGuid = (useLocation().state as { userData: string })?.userData;
  const roleGuid = (useLocation().state as { roleGuid: string })?.roleGuid;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [url, setUrl] = useState("");
  const [roleData, setRoleData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<string>("User");
  const [sessions, setSessions] = useState<Session[]>([]);
  const [log, setLog] = useState<Receipt | null>(null);
  const [phone, setPhone] = useState("");
  const [count, setCount] = useState(0);
  const [member, setMember] = useState<User>({
    tenantId: "",
    businessGuid: "",
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    userStatus: 0,
    userGuid: "",
    limitType: 0,
    zarLimit: 0,
    usdLimit: 0,
    primaryUser: false,
    authority: [],
    roles: [],
    isdCode: 0,
    token: "",
    documents: [],
    idType: "",
    idNumber: "",
    mobileNumber: "",
    additionalProperties: {
      zarLimit: "0",
      authority: "",
      limitType: "",
      usdLimit: "0",
    },
    lastLogin: "",
    createdDate: "",
    createdBy: "",
    updatedDate: "",
    updatedBy: "",
    createdDateString: "",
  });

  const fetchUserDetails = async () => {
    try {
      const url = `${API_URL}/team/findUser`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ userGuid: userGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setMember(data.message);
        setPhone(data.message.isdCode + "-" + data.message.mobileNumber);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserSessions = async (phone: string) => {
    try {
      const url = `${API_URL}/getSessionLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        searchCriteriaList: [
          {
            propertyName: "appType",
            propertyValue: "SME",
          },
          {
            propertyName: "userName",
            propertyValue: phone,
          },
        ],
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        skipPagination: false,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setSessions(data.results);
        setCount(data.count as number);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserActivity = async (phone: string) => {
    try {
      const url = `${API_URL}/getActivityLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        searchCriteriaList: [
          {
            propertyName: "appType",
            propertyValue: "SME",
          },
          {
            propertyName: "userName",
            propertyValue: phone,
          },
        ],
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        skipPagination: false,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setSessions(data.results);
        setCount(data.count as number);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedTab === "Session") fetchUserSessions(phone);
    if (selectedTab === "Activity") fetchUserActivity(phone);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (userGuid) {
      fetchUserDetails();
    }
  }, [userGuid]);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);
  };

  const handleViewClick = async (doc: Document | undefined) => {
    if (doc) {
      setUrl(doc.documentPath);
      const modalElement = document.getElementById("image_modal");
      const modal = new Modal(modalElement as Element);
      modal.show();
    }
  };

  const handleDocViewClick = async (doc: Document | undefined) => {
    if (doc) {
      setUrl(doc.documentPath);
      if (doc.documentExtention === "pdf") {
        const modalElement = document.getElementById("pdf_modal");
        const modal = new Modal(modalElement as Element);
        modal.show();
      }
      if (doc.documentExtention !== "pdf") {
        const modalElement = document.getElementById("image_modal");
        const modal = new Modal(modalElement as Element);
        modal.show();
      }
    }
  };

  function getAuthority(value: string | string[]): string {
    const map: { [key: string]: string } = {
      "1": "Single",
      "2": "Initiator",
      "3": "Approver",
    };

    if (Array.isArray(value)) {
      return value.map((val) => map[val] || "Unknown").join("/");
    } else {
      return map[value] || "Unknown";
    }
  }

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(count / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      <h4 className="mb-5">View User</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div className="p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className="nav-link text-active-gray-800 active"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      onClick={() => {
                        handleTabClick("User");
                      }}
                    >
                      User Details
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      onClick={() => {
                        fetchUserSessions(phone);
                        handleTabClick("Session");
                      }}
                    >
                      Sessions
                    </a>
                  </li>
                  {/* <li className="nav-item" key={3}>
                    <a
                      className="nav-link text-active-gray-800 text-nowrap"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_3"
                      onClick={() => {
                        fetchUserActivity(phone);
                        handleTabClick("Activity");
                      }}
                    >
                      Logs
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="tab-content " id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <div className="py-5" style={{ overflowX: "scroll" }}>
                  <div className="card p-5 mb-5 d-flex flex-column">
                    <div className="d-flex flex-row gap-20 align-items-center mb-5">
                      <div className="d-flex flex-column p-3 gap-2 ">
                        <div
                          className="card shadow-sm"
                          style={{ maxHeight: "150px", maxWidth: "150px" }}
                        >
                          <div
                            className="image-input image-input-outline overlay overflow-hidden shadow"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="overlay-wrapper">
                              <img
                                src={
                                  member?.documents?.find((mem) =>
                                    mem.documentTypes.includes(2)
                                  )?.documentPath
                                }
                                style={{
                                  borderRadius: "10px",
                                  objectFit: "fill",
                                  height: "150px",
                                  width: "150px",
                                }}
                              />
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-50 align-items-center">
                              <div
                                className="btn btn-icon bg-light border border-dark"
                                style={{ height: "30px", width: "30px" }}
                                onClick={() =>
                                  handleViewClick(
                                    member?.documents?.find((mem) =>
                                      mem.documentTypes.includes(2)
                                    )
                                  )
                                }
                              >
                                <i className="bi bi-eye fs-5 text-dark"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card rounded p-3">
                          <div className="fw-bold fs-7">
                            DOB :{" "}
                            {new Date(member?.dob)
                              .toLocaleDateString("en-GB")
                              .replace(/\//g, "-")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row ms-5 mt-5">
                        <div style={{ width: "400px" }}>
                          <div className="fw-bold fs-7">Name</div>
                          <div className="fs-8">{member?.firstName}</div>
                        </div>
                        <div style={{ width: "400px" }}>
                          <div className="fw-bold fs-7">Gender</div>
                          {member?.gender}
                        </div>
                        <div style={{ width: "355px" }}>
                          <div className="fw-bold fs-7">Email</div>
                          {member?.email}
                        </div>
                      </div>
                      <br />
                      <div className="d-flex flex-row ms-5 mt-5">
                        <div style={{ width: "400px" }}>
                          <div className="fw-bold fs-7">Mobile Number</div>
                          {member?.isdCode}-{member?.mobileNumber}
                        </div>
                        <div style={{ width: "400px" }}>
                          <div className="d-flex flex-row gap-1">
                            <p className="fw-bold fs-7 m-0">ID</p>
                          </div>
                          <p
                            className="m-0 cursor-pointer text-primary"
                            onClick={() =>
                              handleDocViewClick(
                                member?.documents?.find((mem) =>
                                  mem.documentTypes.includes(8)
                                )
                              )
                            }
                          >
                            {
                              member?.documents?.find((mem) =>
                                mem.documentTypes.includes(8)
                              )?.documentName
                            }
                          </p>
                        </div>
                        <div style={{ width: "355px" }}>
                          <div className="fw-bold fs-7">Role</div>
                          {member?.roles[0]?.name}
                        </div>
                      </div>
                      <br />
                      <div className="d-flex flex-row ms-5 mt-5">
                        <div style={{ width: "400px" }}>
                          <div className="fw-bold fs-7">Authority</div>
                          {getAuthority(member?.authority)}
                        </div>
                        <div style={{ width: "400px" }}>
                          <div className="d-flex flex-row gap-1">
                            <p className="fw-bold fs-7 m-0">Limit (ZAR)</p>
                          </div>
                          <p className="m-0">
                            {member?.zarLimit === 0
                              ? "No limit"
                              : member?.zarLimit}
                          </p>
                        </div>
                        <div style={{ width: "355px" }}>
                          <div className="fw-bold fs-7">Limit (USD)</div>
                          {member?.usdLimit === 0
                            ? "No limit"
                            : member?.usdLimit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="card pt-4 mb-6 mb-xl-9">
                  {/* <div className="card-header border-0">
                    <div className="card-title">
                      <h2>Login Sessions</h2>
                    </div>

                    <div className="card-toolbar">
                      <button
                        type="button"
                        className="btn btn-sm btn-flex btn-light-primary"
                        id="kt_modal_sign_out_sesions"
                      >
                        <i className="ki-duotone ki-entrance-right fs-3">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Sign out all sessions
                      </button>
                    </div>
                  </div> */}

                  <div className="card-body pt-0 pb-5">
                    <div style={{ overflowX: "scroll" }}>
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-150px">Username</th>
                            <th className="min-w-125px">Date</th>
                            <th className="min-w-125px">Time</th>
                            <th className="min-w-125px">Channel</th>
                            {/* <th className="min-w-125px">Session ID</th> */}
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {sessions?.length === 0 ? (
                            <tr>
                              <td colSpan={7} className="text-center">
                                No data found.
                              </td>
                            </tr>
                          ) : (
                            sessions?.map((session, index) => (
                              <tr>
                                <td>{session?.userName}</td>
                                <td>
                                  {session?.createdDate.substring(
                                    0,
                                    session?.createdDate.lastIndexOf(" ")
                                  )}
                                </td>
                                <td>
                                  {session?.createdDate.substring(
                                    session?.createdDate.lastIndexOf(" ") + 1
                                  )}
                                </td>
                                <td>{session?.channel}</td>

                                {/* <td className="text-start">
                                  <p
                                    className={`mb-1 cursor-pointer text-primary`}
                                  >
                                    {session?.sessionId}
                                  </p>
                                </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedTab !== "User" && (
              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage === Math.ceil(count / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow ${
                        currentPage === Math.ceil(count / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(Math.ceil(count / itemsPerPage))
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {count}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal" tabIndex={-1} id="image_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            {/* <div className="d-flex flex-row justify-content-end"> */}
            {/* <label className="form-label fs-3">Profile Image</label> */}
            {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div> */}
            <div className="modal-body justify-content-center d-flex">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <img
                  className="image"
                  src={url}
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxWidth: "600px",
                    maxHeight: "500px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="pdf_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_user_header">
              <h2 className="fw-bold">PDF Viewer</h2>

              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                data-bs-dismiss="modal"
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>

            <div className="modal-body px-15">
              <embed
                src={`${url}#toolbar=0`}
                type="application/pdf"
                width="100%"
                height="800px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ViewUser };
