export interface IAppBasic {
  empName: string
  
}
export interface IAppDate {
  date: string

}
export interface IAppEmail {
  email: string

}
export interface IAppPhone {
  phone: string

}
export interface IAppAddress {
  address: string

}
export interface IAppAddressProof {
  addressProof: File | null 

}
export interface IAppBloodGroup {
  bloodGroup: string

}
export interface IAppIdProof {
  idProof: File | null

}
export interface IAppEmeContact {
  emeContact: string

}
export interface IAppDoj {
  doj: string

}
export interface IAppEmployeeID {
  employeeID: string

}
export interface IAppDesignation {
  designation: string

}
export interface IAppDepartment {
  department: string

}
export interface IAppLocation {
  location: string

}
export interface IAppAccNumber {
  accNumber: string

}
export interface IAppAccName{
  accName: string

}
export interface IAppAccIdentifier{
  accIdetifier: string

}
export interface IAppAccIdentifier{
  accIdetifier: string

}
export interface IAppMonthly{
  monthlySalary: string

}
export interface IAppPensionDeductions{
  pensionDeductions: string

}
export interface IAppTravelAllowance{
  travelAllowance: string

}
export interface IAppMedicalDependents{
  medicalDependents: string

}
export interface IAppOtherAllowance{
  otherAllowance: string

}
export interface IAppAge{
  age: string

}
export interface IAppAnnualBonus{
  annualBonus: string

}










export interface IAppLimit {
  limit: string

}



export interface ICreateAppData {
  appBasic: IAppBasic
  empDate: IAppDate
  empEmail: IAppEmail
  empPhone: IAppPhone
  empAddress: IAppAddress
  empAddressProof: IAppAddressProof
  empBloodGroup: IAppBloodGroup
  appLimit: IAppLimit
  idProof: IAppIdProof
  emeContact: IAppEmeContact
  doj: IAppDoj
  employeeID:IAppEmployeeID
  designation:IAppDesignation
  department:IAppDepartment
  location:IAppLocation
  accNumber:IAppAccNumber
  accName:IAppAccName
  accIdentifier:IAppAccIdentifier
  monthlySalary:IAppMonthly
  pensionDeductions:IAppPensionDeductions
  travelAllowance:IAppTravelAllowance
  medicalDependents:IAppMedicalDependents
  otherAllowance:IAppOtherAllowance
  age:IAppAge
  annualBonus:IAppAnnualBonus
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: { empName: ''},
  empDate: { date: '' },
  empEmail: { email: '' },
  empPhone: { phone: '' },
  appLimit: { limit: '' },
  empAddress: { address: '' },
  empAddressProof: { addressProof: null },
  empBloodGroup: { bloodGroup: '' },
  idProof: { idProof: null },
  emeContact: { emeContact: '' },
  doj: { doj: '' },
  employeeID: { employeeID: '' },
  designation: { designation: '' },
  department: { department: '' },
  location: { location: '' },
  accNumber: { accNumber: '' },
  accName:{accName:''},
  accIdentifier: { accIdetifier: '' },
  monthlySalary:{monthlySalary:''},
  pensionDeductions:{pensionDeductions:''},
  travelAllowance:{travelAllowance:''},
  medicalDependents:{medicalDependents:"0"},
  otherAllowance:{otherAllowance:''},
  age:{age:''},
  annualBonus:{annualBonus:''}
}

export type StepProps = {
  data: ICreateAppData
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void
  hasError: boolean
}
