import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { Col, Row } from "react-bootstrap";
import Logo from "../../../_metronic/assets/Icons/xfinlogo.svg";
import Icon from "../../../_metronic/assets/Icons/Group.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { InvData } from "../Invoice/CreateInvoice/InvData";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface Business {
  businessName: string;
  businessAddress: string;
  businessIsdCode: number;
  businessMobileNo: string;
  businessEmailId: string;
}

interface Customer {
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  isfavourite: boolean;
  customerStatus: number;
}
interface Items {
  itemName: string;
  qty: number;
  rate: number;
  total: number;
}
interface InvPreviewData {
  quotationGuid?: string;
  invDate: Date;
  invDueDate: Date;
  billTo: Customer | null;
  billFrom: Business | null;
  items: Items[];
  subTotal: number;
  discount: number;
  shipping: number;
  total: number;
  tax: number;
  tc: string;
  notes: string;
}
interface setting {
  domesticQuotationPrefix: "";
  domesticQuotationSequencePattern: "";
  exportedQuotationPrefix: "";
  exportedQuotationSequencePattern: "";
  termsAndConditions: "";
  dueDatePeriod: "";
  tax: "";

  documents: [
    {
      documentExtention: "";
      documentName: "logo";
      documentTypes: [20];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;
      documentPath: "";
    },
    {
      documentExtention: "";
      documentName: "signature";
      documentTypes: [21];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;

      documentPath: "";
    }
  ];
}

export function QuotationModal({ data }: { data: InvPreviewData }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const modalRef = useRef<HTMLDivElement | null>(null);

  const SuccessModal = useRef<HTMLDivElement | null>(null);

  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceMail, setInvoiceMail] = useState("");
  const [loading, setLoading] = useState(false);
  const [invSetting, setInvSetting] = useState<setting | null>(null);

  const navigate = useNavigate();
  console.log("edit data - ", data);

  useEffect(() => {
    if (SuccessModal.current) {
      const modal = new Modal(SuccessModal.current);
      SuccessModal.current.addEventListener(
        "hidden.bs.modal",
        handleCloseModal
      );
      return () => {
        SuccessModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleConfirmationModal = () => {
    const modalElement = document.getElementById("successModal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleCloseModal = () => {
    const modalElement = SuccessModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }

    //handleDeleteConfirmation()
  };

  const GenerateInvoice = async () => {
    try {
      const url = `${API_URL}/quotation/${
        data?.quotationGuid ? "updateQuotation" : "generateQuotation"
      }`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const { invoiceType, ...restInvData } = InvData;

      const body = JSON.stringify({
        ...restInvData,
        ...(data?.quotationGuid ? { quotationGuid: data.quotationGuid } : {}),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let dataa = await response.json();
      if (dataa.status.statusCode === 0) {
        setLoading(false);
        handleDeleteCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `Your Quotation has been ${
            data?.quotationGuid ? "updated" : "generated"
          }`,
          // text: `Your Invoice with number ${dataa?.message?.invoiceNumber} has been generated and sent to ${data.billTo?.emailId}`,
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Home",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/invoice/quotations");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/quotationSettings/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setInvSetting(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadSettings();
  }, []);

  console.log(invSetting);

  return (
    <>
      <div className="modal " tabIndex={-1} id="invoice_modal" ref={modalRef}>
        <div
          className="modal-dialog modal-lg modal-dialog-top"
          style={{ margin: "0 auto", marginTop: "5%", width: "1000px" }}
        >
          <div className="modal-content" style={{ borderRadius: "20px" }}>
            <div className="modal-body p-10">
              <div className="p-0 m-0">
                <div className=" mb-10 d-flex justify-content-between">
                  <img
                    className="image-input-wrapper w-125px h-125px"
                    style={{ objectFit: "contain" }}
                    src={invSetting?.documents[0]?.documentPath}
                    alt=""
                  />
                  <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleDeleteCloseModal}
                  >
                    X
                  </div>
                </div>

                <div className=" w-100 p-0 mb-10 d-flex justify-content-between">
                  <div
                    className="d-flex flex-column text-start p-0"
                    style={{ width: "48%" }}
                  >
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Quotation Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {data.invDate.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  <div
                    className="d-flex flex-column  text-end p-0"
                    style={{ width: "48%" }}
                  >
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Due Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {data.invDueDate.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                </div>
                <div className="mb-2 w-100 ">
                  <form>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          To
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={`${data.billTo?.companyName},\n${data.billTo?.address},\n${data.billTo?.isdCode}${data.billTo?.phoneNumber},\n${data.billTo?.emailId}`}
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          From
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={`${data.billFrom?.businessName},\n${data.billFrom?.businessAddress},\n${data.billFrom?.businessIsdCode}${data.billFrom?.businessMobileNo},\n${data.billFrom?.businessEmailId}`}
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="d-flex flex-column w-100 mb-2">
                  <div className="border border-dark border-bottom-0 mt-2 w-100  bg-primary justify-content-center align-items-center">
                    <Row className="w-100 justify-content-center">
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-start fw-bold fs-5 ms-3">
                          Item
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-start fw-bold fs-5">
                          Rate
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-start fw-bold fs-5">
                          Qty
                        </Col>
                      </div>
                      <div className="d-flex w-25 p-2">
                        <Col className="d-flex align-items-center justify-content-start fw-bold fs-5">
                          Total
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="border border-dark justify-content-center align-items-center">
                    {data.items.map((data, index) => (
                      <div
                        className="w-100 justify-content-center align-items-center"
                        key={index}
                      >
                        <Row
                          className="w-100 justify-content-center "
                          key={index}
                        >
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6 ms-3">
                              {data.itemName}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {data.rate?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {data.qty}
                            </Col>
                          </div>
                          <div className="w-25 p-2">
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {data.total?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                        </Row>
                        <hr className="m-0"></hr>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="w-100  justify-content-center align-items-center border border-dark border-top-0">
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold ms-3">
                            Subtotal
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold ">
                            {baseCurrency}{" "}
                            {data.subTotal?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold ms-1">
                            &nbsp;&nbsp;Discount
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold ">
                            {baseCurrency}{" "}
                            {data.discount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex flex-1 align-items-center justify-content-start fw-bold ms-1">
                            &nbsp;&nbsp;Tax
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}{" "}
                            {data.tax?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold ms-1">
                            &nbsp;&nbsp;Shipping
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}{" "}
                            {data.shipping?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr
                        className="m-0"
                        style={{ borderColor: "1px solid black" }}
                      ></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold ms-1">
                            &nbsp;&nbsp;Total
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}{" "}
                            {data.total?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <label htmlFor="" className="fw-bold fs-4 mt-2">
                    Notes
                  </label>
                  <textarea
                    className="w-100 m-2 ms-0 me-0 border-0 rounded bg-light p-2"
                    style={{ resize: "none" }}
                    value={InvData?.notes}
                    rows={2}
                    maxLength={300}
                    disabled
                  ></textarea>
                </div>

                <div className="mt-3 mb-5">
                  <div>
                    <h4>Payment Options</h4>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {" "}
                      <p>{InvData?.termsAndConditions}</p>
                    </div>
                    <div>
                      <img
                        className="image-input-wrapper w-125px h-125px"
                        style={{ objectFit: "contain" }}
                        src={invSetting?.documents[1]?.documentPath}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex w-100 gap-5 mt-2 justify-content-around">
                  {/* <button
                    type="button"
                    className="btn"
                    style={{
                      width: "250px",
                      borderRadius: "25px",
                      backgroundColor: "#246bfd",
                      color: "#ffff",
                    }}
                  >
                    Edit
                  </button> */}
                  <button
                    type="button"
                    className="btn"
                    style={{
                      width: "250px",
                      borderRadius: "25px",
                      backgroundColor: "#246bfd",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      setLoading(true);
                      GenerateInvoice();
                      // else
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="indicator-label">
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">
                        {data?.quotationGuid ? "Update" : "Generate"}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
