/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC } from "react";
import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import { PageTitle } from "../../../_metronic/layout/core";
import { TransactionStatement } from "../paybill/PayBillApi";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { apiCall } from "../dashboard/DashboardWrapper";
import Authorize from "../../modules/auth/components/Authorize";

interface Product {
  product: string;
  productName: string;
  description: string;
  currency: string;
  subscriptionAmount: string;
}

interface History {
  paymentDate: string;
  product: string;
  status: string;
  subscriptionAmount: number;
  txnId: string;
}

interface Subscription {
  productCode: string;
  subscriptionMonthlyCharge: number;
  tenantId: string;
  subscriptionPayments: History[];
  businessGuid: string;
  subscriptionPayDate: string;
  products: Product[];
}

const Subscription: FC = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const [data, setData] = useState<Subscription>({
    productCode: "",
    subscriptionMonthlyCharge: 0,
    tenantId: "",
    subscriptionPayments: [],
    businessGuid: "",
    subscriptionPayDate: "",
    products: [],
  });

  function formatDate(dateString: string) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[monthIndex]}, ${year}`;
  }

  const getSubscriptionDetails = async () => {
    try {
      const url = `${API_URL}/getSubscriptionDetail`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setData(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const manageSubscription = async (product: string, amount: number) => {
    try {
      const url = `${API_URL}/manageSubscriptionPlan`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        product: product,
        subscriptionAmount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          title: "Congratulations",
          text: "Your subscription plan been changed.",
          icon: "success",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        getSubscriptionDetails();
        apiCall.submit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.subscriptionPayments?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(
      data?.subscriptionPayments?.length / itemsPerPage
    );
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      <h4 className="mb-5">Subscription</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <>
            <div className="d-flex row gap-10 m-5 flex-grow">
              <div
                className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end `}
                style={{
                  backgroundColor: "black",
                  //   backgroundImage: `url('${img}')`,
                  width: "350px",
                }}
              >
                <div className="card-body d-flex flex-column">
                  <div className="card-title d-flex flex-row text-white mb-2 fs-1 fw-bold gap-3 align-items-center">
                    {data?.productCode}{" "}
                    <i
                      className="bi bi-info-circle text-white fs-6 fw-normal mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_details_modal"
                    ></i>
                  </div>
                  <div className="d-flex flex-row justify-content-between mb-5">
                    <p className=" text-white">
                      {baseCurrency} {data?.subscriptionMonthlyCharge}/month
                    </p>
                    <p className=" text-white">
                      Bills {data?.subscriptionPayDate} of every month
                    </p>
                  </div>
                  <button
                    className="btn rounded-4 px-0 py-1"
                    style={{
                      backgroundColor: "#71FF99",
                      width: "110px",
                      fontSize: "13px",
                      color: "black",
                    }}
                  >
                    Active Plan
                  </button>
                </div>
              </div>
              <div
                className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end `}
                style={{
                  backgroundColor: "#723AE9",
                  //   backgroundImage: `url('${img}')`,
                  width: "350px",
                }}
              >
                {data?.products
                  ?.filter((sub) => sub?.product !== data?.productCode)
                  ?.map((sub, index) => {
                    return (
                      <div className="card-body d-flex flex-column">
                        <div className="card-title d-flex flex-row text-white mb-2 fs-1 fw-bold gap-3 align-items-center">
                          {sub?.product}{" "}
                          <i
                            className="bi bi-info-circle text-white fs-6 fw-normal mb-0"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_details_modal"
                          ></i>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-5 text-white">
                          <p>
                            {baseCurrency} {sub?.subscriptionAmount}/month
                          </p>
                          {/* <p>Bill on 5th of every month</p> */}
                        </div>
                        <Authorize hasAnyPermission={["MENU_SUBSCRIPTION|ADD"]}>
                          <button
                            className="btn btn-light rounded-4 px-0 py-1 text-dark"
                            style={{
                              width: "110px",
                              fontSize: "13px",
                            }}
                            onClick={() => {
                              Swal.fire({
                                title:
                                  "Are you sure you want to change the plan?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                                allowEscapeKey: true,
                                allowEnterKey: true,
                              }).then((response) => {
                                if (response.isConfirmed) {
                                  manageSubscription(
                                    sub?.product,
                                    +sub?.subscriptionAmount
                                  );
                                }
                              });
                            }}
                          >
                            Switch Plan
                          </button>
                        </Authorize>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="shadow-sm m-5 rounded">
              <div className="py-2 card py-5 px-5">
                <div className="d-flex justify-content-between align-items-center p-3 pt-0">
                  <h4 className="ms mb-0">History</h4>
                </div>
                <div className="tab-content " id="myTabContent">
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-125px p-5"> Date</th>
                          <th className="min-w-125px p-5">Plan Name</th>
                          <th className="min-w-125px p-5">Amount</th>
                          <th className="min-w-110 text-start p-5">Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData?.map((trans, index) => (
                            <tr key={index}>
                              <td className="p-5">
                                <p className="text-gray-600 mb-1">
                                  {formatDate(trans?.paymentDate)}
                                </p>
                              </td>

                              <td className="p-5">
                                <p className="text-gray-800 mb-1">
                                  {trans?.product}
                                </p>
                              </td>

                              <td className="p-5">
                                {baseCurrency}{" "}
                                {trans?.subscriptionAmount.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>

                              <td className="p-5">
                                <div className="d-flex flex-row gap-5">
                                  <p
                                    className={`badge badge-light-${
                                      trans?.status === "Paid"
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {trans?.status === "Paid"
                                      ? "Success"
                                      : "Failed"}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(
                            data?.subscriptionPayments?.length / itemsPerPage
                          )
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(
                            data?.subscriptionPayments?.length / itemsPerPage
                          )
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                data?.subscriptionPayments?.length /
                                  itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {data?.subscriptionPayments?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      <div
        className="modal fade"
        id="kt_details_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div
            className="modal-content h-600px"
            style={{
              backgroundColor: "#F5F5F7",
            }}
          >
            <div className="modal-header">
              <h2 className="fw-bold">Essential Fees and Charges</h2>
              <div
                id="kt_customers_export_close"
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  // downloadFormik.resetForm();
                }}
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
            <div
              className="modal-body px-10"
              style={{
                overflow: "auto",
              }}
            >
              <div className="d-flex flex-column">
                <p className="fw-bold">EVERYDAY BANKING</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Virtual Card Issuance</p>
                  <p className="m-0">Free for Life</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Local Transfers</p>
                  <p className="m-0">Free for Life</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Wise international transfer</p>
                  <p className="m-0">Wise fees apply</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">
                    Debit card international transaction fee
                  </p>
                  <p className="m-0">2%</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Swift international transfers</p>
                  <p className="m-0">
                    ZAR 52.50(correspondent bank fees apply)
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">TEAM MANAGEMENT</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Additional users included</p>
                  <p className="m-0">2 users</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">MU additional users access</p>
                  <p className="m-0">ZAR 26.25 / month(per user)</p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">SALARY PAYMENT</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Free files for every WPS payroll</p>
                  <p className="m-0">1 file</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">
                    Payroll with WPS(Wage Protection System)
                  </p>
                  <p className="m-0">ZAR 31.50</p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">CASH</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cash withdrawls & deposits (FAB ATMs)</p>
                  <p className="m-0">Free for Life</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cash withdrawls(other UAE ATMs)</p>
                  <p className="m-0">ZAR 31.50</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cash withdrawls(GCC ATMs)</p>
                  <p className="m-0">ZAR 6.30</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">international cash withdrawls</p>
                  <p className="m-0">ZAR 21.50</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cash deposits (Lulu Exchange)</p>
                  <p className="m-0">ZAR 10.50</p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">CHEQUES</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cheque deposits (FAB CDM)</p>
                  <p className="m-0">Free for Life</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cheque Book(25 leaves)</p>
                  <p className="m-0">One free, ZAR 26.25 per book after</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Returned cheque</p>
                  <p className="m-0">ZAR 105.00</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Cheque Copy</p>
                  <p className="m-0">ZAR 10.50 per copy</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Stop cheque</p>
                  <p className="m-0">ZAR 52.50</p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">BEYOND BANKING</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Invoicing (ZAR)</p>
                  <p className="m-0">Free for life</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Stripe payments</p>
                  <p className="m-0">Free sign-up (Stripe fees apply)</p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="fw-bold mt-2">STATEMNTS AND OTHER CHARGES</p>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Paper statements</p>
                  <p className="m-0">ZAR 26.50</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">No liability letter (physical)</p>
                  <p className="m-0">ZAR 105.00</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Audit confirmation letter</p>
                  <p className="m-0">ZAR 105.00</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Expired trade license fee</p>
                  <p className="m-0">ZAR 210 per month</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4 mb-3">
                  <p className="m-0">Account closure letter fee</p>
                  <p className="m-0">ZAR 105.00</p>
                </div>
                <div className="d-flex flex-row card shadow-none rounded justify-content-between p-4">
                  <p className="m-0">Balance confirmation letter fee</p>
                  <p className="m-0">ZAR 105.00</p>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
