import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../core/Auth";
import { password } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";

const Password = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { regUser, setRegUser } = useAuth();
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const formik = useFormik({
    initialValues: {
      password: "",
      status: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])/,
          "Password must contain at least one lowercase letter"
        )
        .matches(
          /^(?=.*[A-Z])/,
          "Password must contain at least one uppercase letter"
        )
        .matches(/^(?=.*\d)/, "Password must contain at least one number")
        .matches(
          /^(?=.*[@$!%*?&])/,
          "Password must contain at least one special character"
        )
        .matches(
          /^[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must be at least 8 characters long"
        ),
    }),
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
    
      try {
        const data = await password(
          regUser?.mobile ?? "",
          regUser?.isdCode ?? 91,
          values?.password,
          regUser?.appId ?? ""
        );
    
        if (data?.data?.status?.statusCode === 0) {
          setRegUser(data?.data?.message);
          markFormCompleted("password");
          navigate("/auth/terms");
          resetForm();
        } else {
          formik.setFieldValue("status", data?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    }
    
  });

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">Create Password</p>
      <div className="mt-10">
        <div className="d-flex justify-content-center mt-5">
          <img src={toAbsoluteUrl("/media/auth/Password.svg")} alt="" />
        </div>
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="mt-5 p-10">
            <label className="form-label">Create Your Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                className={`form-control ${
                  formik.touched.password && formik.errors.password
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Password"
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />

              <button
                type="button"
                className="fw-bold"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger mt-1">{formik.errors.password}</div>
            )}
            <button type="submit" className="btn w-100 mt-15" disabled={formik.isSubmitting || loading}>
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="" role="status" aria-hidden="true"></span>
                </div>
              ) : (
                <div>Proceed</div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Password;
