import React from "react";

declare global {
  interface Window {
    $zoho: {
      salesiq: {
        widgetcode: string;
        values: Record<string, any>;
        ready: () => void;
      };
    };
  }
}

export default function ZohoSalesIQ() {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode:"siqb69afa41e8a6a67d94a457cf36d9a1666abec650b3e58468c595575a65822cb2" ,
    values: {},
    ready: function () {},
  };
  const d = document;
  let s;
  s = d.createElement('script');
  s.type = 'text/javascript';
  s.id = 'zsiqscript';
  s.defer = true;
  s.src = 'https://salesiq.zoho.com/widget';
  let t;
  t = d.getElementsByTagName('script')[0];
  t?.parentNode?.insertBefore(s, t);
  
  return (
    <React.Fragment>
      <div id='zsiqwidget'></div>
    </React.Fragment>
  );
}
