/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { StepProps } from "./SubAccountAppModel";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export const SteppperModal2 = ({ data, updateData, hasError,setHasError }: StepProps) => {
  const [selectedEmoji, setSelectedEmoji] = useState("");

  const handleEmojiClick = (emoji: string) => {
    setSelectedEmoji(emoji);
    data.appIcon.icon = emoji;
    updateData({ ...data }); // Ensure to update data properly
  };

  const emojiList = {
    ic_book: toAbsoluteUrl("/media/subicons/Bill_Payments.svg"),
    ic_car: toAbsoluteUrl("/media/subicons/Business_Expenses.svg"),
    ic_cellphone: toAbsoluteUrl("/media/subicons/Contingency.svg"),
    ic_chair: toAbsoluteUrl("/media/subicons/Cash_Backup.svg"),
    ic_coffee: toAbsoluteUrl("/media/subicons/Gifting.svg"),
    ic_coke: toAbsoluteUrl("/media/subicons/Healthcare.svg"),
    ic_education: toAbsoluteUrl("/media/subicons/Office_Hardware.svg"),
    ic_fries: toAbsoluteUrl("/media/subicons/Office_Outings.svg"),
    ic_gift: toAbsoluteUrl("/media/subicons/Packaging & Logistics.svg"),
    ic_shopping: toAbsoluteUrl("/media/subicons/Payroll.svg"),
    ic_tree: toAbsoluteUrl("/media/subicons/Sales & Marketing.svg"),
    ic_software: toAbsoluteUrl("/media/subicons/Software_subscriptions.svg"),
  };

  return (
    <div className="pb-5 card shadow-sm p-10" data-kt-stepper-element="content">
      <div className="w-100">
        <div>
          <h2>Choose an emoji for your account:</h2>
          <div className="emoji-container d-flex flex-wrap mt-10 mb-10">
            {Object.entries(emojiList).map(([key, emoji]) => (
              <p
                key={key}
                className={`emoji ${
                  selectedEmoji === key ? "selected" : ""
                } fs-2x mt-5 text-center cursor-pointer`}
                onClick={() => handleEmojiClick(key)}
                style={{
                  padding: "10px",
                  flexBasis: "25%",
                  boxSizing: "border-box",
                  border: selectedEmoji === key ? "2px solid #000" : "transparent",
                  borderRadius: "10px",
                }}
              >
                <img src={emoji} alt={key} style={{ width: "50%" }} />
              </p>
            ))}
            {!data.appIcon.icon && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Emoji is required
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};