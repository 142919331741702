import React, { useEffect, useState } from "react";
import { useAuth } from "../../core/Auth";
import { useNavigate } from "react-router-dom";
import { activate, getPlans, managePlans } from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

// Interface for Plan object
interface Plan {
  product: string;
  productName: string;
  description: string;
  currency: string;
  subscriptionAmount: string;
}

const Plans = () => {
  const { regUser, setRegUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Plan[]>([]);

  // Fetch plans on component mount
  useEffect(() => {
    getPlans(regUser?.token ?? "").then((res) => {
      if (res?.data?.status?.statusCode === 0) {
        setData(res?.data?.results);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, [regUser?.token]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    product: Yup.string().required("Product is required"),
  });

  // Form handling with useFormik
  const formik = useFormik({
    initialValues: {
      product: "", // Initial value can be empty since we set default in radio inputs
      subscriptionAmount: 0, // Initial value can be 0 or whatever default you need
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        setTimeout(async () => {
          const data = await managePlans(
            values?.product,
            values?.subscriptionAmount,
            regUser?.token ?? ""
          ).then((res) => {
            if (res?.data?.status?.statusCode === 0) {
              setLoading(false);
              activate(regUser?.token ?? "");
              Swal.fire({
                icon: "success",
                title: "Set up Completed",
                text: "Logging out",
                showCancelButton: false,
                showConfirmButton: false,
                html: ` <div className="spinner-border text-primary" role="status">
              <span className="" role="status" aria-hidden="true"></span>
            </div> `,
                confirmButtonColor: "#007bff",
                confirmButtonText: "Proceed",
                allowEscapeKey: true,
                allowEnterKey: true,
              });
              setTimeout(() => {
                Swal.close();
                navigate("/auth");
                setRegUser(undefined);
              }, 2000);
              resetForm();
            } else {
              formik.setFieldValue(
                "status",
                res?.data?.status?.messageDescription
              );
            }
          });
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  return (
    <div>
  <form onSubmit={formik.handleSubmit}>
    <div>
      <p className="fs-4 fw-bold">Please select an account plan</p>
    </div>

    {loading ? (
      <div className="text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <>
        <div>
          {data.map((item, index) => (
            <div key={index} className="mb-10 card shadow-sm p-10">
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value={item.productName}
                  id={`flexRadioChecked${index}`}
                  checked={formik.values.product === item.productName}
                  onChange={() => {
                    formik.setFieldValue("product", item.productName);
                    formik.setFieldValue(
                      "subscriptionAmount",
                      item.subscriptionAmount
                    );
                  }}
                />
                <div className="w-100 ms-10">
                  <div className="d-flex flex-row justify-content-between w-100">
                    <span className="fw-bold">{item.productName}</span>
                    <span className="fw-bold">
                      {item.currency}&nbsp;{item.subscriptionAmount}
                    </span>
                  </div>
                  <div className="mt-2">{item.description}</div>
                </div>
              </div>
            </div>
          ))}
          {formik.touched.product && formik.errors.product && (
            <div className="text-danger mt-1">{formik.errors.product}</div>
          )}
        </div>

        <button type="submit" className="btn w-100 mt-4">
          Submit
        </button>
      </>
    )}
  </form>
</div>

  );
};

export default Plans;
