
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyB65inNavLrkJ-UEq1oucqDNWdAbspKMNM",
    authDomain: "xfin-sme.firebaseapp.com",
    projectId: "xfin-sme",
    storageBucket: "xfin-sme.appspot.com",
    messagingSenderId: "501237155375",
    appId: "1:501237155375:web:d2770b98393863b540a8da",
    measurementId: "G-RBEJQTK0L4"
};



export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);






