import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import WelcomePng from "../../../../../src/_metronic/assets/Icons/welcome-38255.png";
import { Link } from "react-router-dom";

export function Welcome() {
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
    const [isAnimationVisible, setIsAnimationVisible] = useState(false);

    const handleAnimationComplete = () => {
        setIsAnimationComplete(true);
    };

    useEffect(() => {
        setIsAnimationVisible(true);
    }, []);

    return (
        <>
            <motion.div
                className="d-flex flex-column flex-center text-center p-10"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                onAnimationComplete={handleAnimationComplete}
            >
                <h1 className="fw-bolder text-gray-900 mb-5">Welcome to Xfin</h1>
                <div className="fw-semibold fs-6 text-gray-500 mb-7">
                    This is your opportunity to get creative and make a name
                    <br />
                    that gives readers an idea
                </div>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <img src={WelcomePng} className="mw-100 mh-300px mb-10" alt="" />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                   <div className="mb-0">
                            <Link to='/dashboard' className='link-primary'>

                                <button className="btn btn-sm btn">Go To Dashboard</button>
                                </Link>
                            </div>

                </motion.div>
            </motion.div>

            {/* Animation */}
            {isAnimationVisible && (
                <>
                    {/* Falling Confetti */}
                    <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        recycle={false}
                        numberOfPieces={500}
                        onConfettiComplete={handleAnimationComplete}
                    />
                </>
            )}
        </>
    );
}





