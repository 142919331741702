import clsx from "clsx";
import { KTIcon, KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../../../app/pages/profile/Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { fetchNotifications } from "../../../../firebase/NotificationSlice";
import Authorize from "../../../../app/modules/auth/components/Authorize";
import { setTimeout } from "timers/promises";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

export const ImgUpdate = {
  api: (): void => {},
};

const Navbar = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const { config } = useLayout();
  const [imgURL, setImgURL] = useState("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const profiledata = await getUserDetails(API_URL, token);
  //     setImgURL(
  //       profiledata?.selfiDocument?.documentPath
  //     );
  //   };
  //   fetchData();
  //   ImgUpdate.api = fetchData;
  // }, []);

  useEffect(() => {
    // Declare a variable to store the timeout ID
    let timeoutId: number;
  
    // Set the timeout and store its ID
    timeoutId = window.setTimeout(() => {
      setImgURL(sessionStorage?.getItem("profile") ?? "");
    }, 5000);
  
    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  


  useEffect(() => {
    // Declare a variable to store the timeout ID
    let timeoutId: number;
  
    // Set the timeout and store its ID
    timeoutId = window.setTimeout(() => {
      setImgURL(sessionStorage?.getItem("profile") ?? "");
    }, 2000);
  
    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  
  

  const dispatch = useDispatch();
  const { notifications, count } = useSelector(
    (state: RootState) => state.notificationLogs
  );

  return (
    <div className="app-navbar flex-shrink-0 gap-5">
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {/* 
      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}
      {/*  */}
      <div className={clsx("app-navbar-item", itemClass)}>
        {/* <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        // className={btnClass}
        >
          <i className="ki-duotone ki-notification fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <span className="badge badge-notify">4</span>
        </div> */}
        <Authorize hasAnyPermission={["MENU_COMMN_CENTER|VIEW"]}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            // className={btnClass}
          >
            <div className="position-relative">
              <i
                className="ki-outline cursor-pointer ki-notification-on fs-2 fw-bold"
                onClick={() => {
                  dispatch(fetchNotifications({} as any) as any);
                }}
              ></i>
              <span
                className="position-absolute start-100 translate-middle "
                style={{ bottom: "3px", left: "80%" }}
              >
                {notifications.filter(
                  (item) => item?.notificationStatus === 2
                ) && (
                  <span
                    className="bullet bullet-dot  h-8px w-8px "
                    style={{ backgroundColor: "#FF0000" }}
                  />
                )}
              </span>
            </div>
          </div>
        </Authorize>
        <HeaderNotificationsMenu />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img src={imgURL} alt="" />
        </div>
        <HeaderUserMenu imgURL={imgURL} />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            {/* <KTIcon iconName="text-align-left" className={btnIconClass} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
