import { number } from "yup"

export const TaxData = {
    'employeeCode':"",
    'basicMonthlySalary': "",
    'basicPensionDeduction': "",
    'travelAllowance': "",
    'medicalDependants': "",
    'otherAllowances': "",
    'age': "",
    'annualBonus': ""
}

export const EmployeeDetails={
    empCode:'',
    empName:''
}

export const employeeData = {

    "firstName": "",
    "dob": "",
    "address": "",
    "isdCode": 0,
    "phoneNumber": 0,
    "emergencyContactIsdCode": 0,
    "emergencyContactNo": 0,
    "bloodGroup": "",
    "email": "",
    "joiningDate": "",
    "location": "",
    "designation": "",
    "department": "",
    "bankAccountNumber": "",
    "bankCode": "",
    "bankName": "",
  
}

export const taxResults={
    "basicMonthlySalary": 0,
        "basicPensionDeduction": 0,
        "travelAllowance": 0,
        "medicalDependants": 0,
        "otherAllowances": 0,
        "annualBonus": 0,
        "incomeSummary": {
            "basicSalaryMonthly": "",
            "travelAllowanceMonthly":  "",
            "otherAllowancesMonthly":  "",
            "grossPayMonthly":  "",
            "pensionMonthly":  "",
            "incomeTaxMonthly":  "",
            "uifMonthly":  "",
            "totalDeductionsMonthly": "",
            "netPayMonthly":  "",
            "basicSalary": "",
            "travelAllowance": "",
            "otherAllowances": "",
            "bonus":  "",
            "totalTaxableIncome":  "",
            "pension": "",
            "taxableIncome": "",
            "totalIncomeTax":  "",
            "taxRebate":  "",
            "medicalTaxCredits":  "",
            "annualTaxPayable":  "",
        }
}


