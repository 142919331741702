import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import XLogo from "../../../../_metronic/assets/Icons/xfin.png";
import * as Yup from "yup";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useFormik } from "formik";

export function InvitePassword() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const { userGuid, token } = location.state as {
    userGuid: string;
    token: string;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(20, "Password must be 20 characters or less")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,20}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      capturePassword(values.password);
      console.log("Form submitted with values:", values);
    },
  });

  const capturePassword = async (password: string) => {
    try {
      const url = `${API_URL}/team/capturePassword`;
      const headers = {
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({
        userGuid: userGuid,
        password: password,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await fetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        console.log(data);
        navigate("/new-mpin", {
          state: {
            userGuid: userGuid,
            token: token,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <style>
        {`
                .responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.responsive-card {
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-card {
    padding: 1rem;
    min-height: 200px;
  }
}
  `}
      </style>
      <div className="responsive-container">
        <div className="responsive-card">
          <img alt="Logo" src={XLogo} className="h-50px mb-20" />
          <div className="d-flex flex-column">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="fv-row mb-10 w-400px">
                <h1 className="text-center mb-3">Enter New Password</h1>
                <div className="mb-4">
                  <div className="position-relative">
                    <input
                      {...formik.getFieldProps("password")}
                      className={`form-control bg-transparent`}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      minLength={8}
                      maxLength={20}
                      autoComplete="off"
                    />
                    <div
                      className="position-absolute end-0 top-50 translate-middle-y me-5"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeSlash /> : <Eye />}
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <p className="text-danger">{formik.errors.password}</p>
                  )}
                </div>
              </div>

              <div className="fv-row mb-8">
                <h1 className="text-center mb-3">Confirm New Password</h1>
                <div className="mb-2">
                  <div className="position-relative">
                    <input
                      {...formik.getFieldProps("confirmPassword")}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Repeat Password"
                      name="confirmPassword"
                      minLength={8}
                      maxLength={20}
                      autoComplete="off"
                      className={`form-control bg-transparent`}
                    />
                    <div
                      className="position-absolute end-0 top-50 translate-middle-y me-5"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <EyeSlash /> : <Eye />}
                    </div>
                  </div>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <p className="text-danger">
                        {formik.errors.confirmPassword}
                      </p>
                    )}
                </div>
              </div>

              <button
                className="btn rounded fs-4 mt-20 w-100"
                type="submit"
                style={{
                  color: "#ffff",
                  backgroundColor: "#246BFD",
                  fontWeight: "bolder",
                }}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
