import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Role } from "./Roles";
import { User } from "./TeamListing";

const ViewRole = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [role, setRole] = useState<Role>();
  const [users, setUsers] = useState<User[]>([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [debouncedValue, setDebouncedValue] = useState(searchValue);

  const roleName = (useLocation().state as { roleName: string })?.roleName;
  const roleGuid = (useLocation().state as { roleGuid: string })?.roleGuid;

  let count = 50;

  const viewRoles = async () => {
    try {
      const url = `${API_URL}/team/viewRole`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ roleGuid: roleGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setRole(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listUsers = async (roleName: string, searchValue: string) => {
    try {
      const url = `${API_URL}/team/viewRoleUsers`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        roleName: roleName,
        ...(searchValue && { firstName: searchValue }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setUsers(data.results);
        setTotalEntries(data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (roleGuid) {
      viewRoles();
      listUsers(roleName, "");
    }
  }, [roleGuid]);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  const generatePageNumbers = () => {
    const pages: Array<number | string> = [];

    if (totalPages <= MAX_VISIBLE_PAGES) {
      // If total pages are less than or equal to MAX_VISIBLE_PAGES, display all pages
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Display pages around the current page with ellipsis
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

      // Always include the first page
      pages.push(1);

      // Display ellipsis if there are pages before the displayed range
      if (startPage > 2) {
        pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      // Display ellipsis if there are pages after the displayed range
      if (endPage < totalPages - 1) {
        pages.push("...");
      }

      // Always include the last page
      pages.push(totalPages);
    }

    // Remove consecutive duplicate page numbers
    // const uniquePages = [...new Set(pages)];

    return pages;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const newTotalPages = Math.ceil(totalEntries / pageSize);
    setTotalPages(newTotalPages);
  }, [currentPage, totalEntries]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    listUsers(roleName, debouncedValue);
  }, [debouncedValue]);

  const entriesStart = count > 0 ? (currentPage - 1) * 10 + 1 : 0;
  const entriesEnd = Math.min(currentPage * 10, totalEntries);

  return (
    <>
      <h3 className="mb-5">View Roles</h3>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h2 className="mb-0">{role?.name}</h2>
                </div>
              </div>

              <div className="card-body pt-0">
                <div className="d-flex flex-column text-gray-600">
                  {role?.description?.split(",").map((des: any, index: any) => (
                    <>
                      <div
                        className="d-flex align-items-center py-2"
                        key={index}
                      >
                        <span className="bullet bg-primary me-3"></span>
                        {des}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-lg-row-fluid ms-lg-10">
            <div className="card card-flush mb-6 mb-xl-9">
              <div className="card-header pt-5">
                <div className="card-title">
                  <h2 className="d-flex align-items-center">
                    Users Assigned
                    <span className="text-gray-600 fs-6 ms-1">
                      ({totalEntries})
                    </span>
                  </h2>
                </div>

                <div className="card-toolbar">
                  <div
                    className="d-flex align-items-center position-relative my-1"
                    data-kt-view-roles-table-toolbar="base"
                  >
                    <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-roles-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder="Search Users"
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div
                    className="d-flex justify-content-end align-items-center d-none"
                    data-kt-view-roles-table-toolbar="selected"
                  >
                    <div className="fw-bold me-5">
                      <span
                        className="me-2"
                        data-kt-view-roles-table-select="selected_count"
                      ></span>
                      Selected
                    </div>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-kt-view-roles-table-select="delete_selected"
                    >
                      Delete Selected
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body pt-0" style={{ overflowX: "scroll" }}>
                <table
                  className="table align-middle table-row-dashed fs-6 gy-5 mb-0"
                  id="kt_roles_view_table"
                >
                  <thead>
                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                      <th className="w-10px pe-2">
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3"></div>
                      </th>
                      <th className="min-w-50px">ID</th>
                      <th className="min-w-150px">User</th>
                      <th className="min-w-125px">Date</th>
                      <th className="min-w-125px">Time</th>
                    </tr>
                  </thead>
                  <tbody className="fw-semibold text-gray-600">
                    {users?.map((user, index) => (
                      <>
                        <tr key={index}>
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid"></div>
                          </td>
                          <td>{user?.userGuid.slice(0, 5)}</td>
                          <td className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                              <p className="text-gray-800 text-hover-primary mb-1">
                                {user?.firstName}
                              </p>
                              <span>{user?.email}</span>
                            </div>
                          </td>
                          <td className="text-nowrap">
                            {user?.createdDateString.split(",")[0]}
                          </td>
                          <td className="text-nowrap">
                            {user?.createdDateString.split(",")[1]}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex flex-stack flex-wrap mt-5">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </span>
                    </li>

                    {generatePageNumbers().map((pageNumber, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        {pageNumber === "..." ? (
                          <span className="page-link ">...</span>
                        ) : (
                          <span
                            className="page-link"
                            onClick={() =>
                              handlePageChange(pageNumber as number)
                            }
                          >
                            {pageNumber}
                          </span>
                        )}
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </span>
                    </li>
                  </ul>
                  <div className="fs-6 fw-bold text-gray-700">
                    Showing {entriesStart} to {entriesEnd} of {totalEntries}{" "}
                    entries
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ViewRole };
