import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../core/Auth";
import { getAllData, secondaryApplicant } from "./api";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import { PhoneInputs } from "../PhoneInput";
import Swal from "sweetalert2";
import { log } from "console";
import { useFormCompletion } from "./Stepper";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { isPhoneValid } from "../Login";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface UserFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailId: string;
  secondaryIsdCode:Number| String;
  secondaryMobileNumber: string;
  nationality: string;
  idProofType: string;
  file: File | undefined; // File type for file upload, can be undefined initially
  idNumber: string;
  idExpiryDate: string;
  status: string;
  orderId:number;
}


const SecondaryApplicant = () => {
  const deviceId = uuidv4();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const { regUser, setRegUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formIndex, setFormIndex] = useState(1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { resetCompletedForms } = useFormCompletion();
  const [showInput, setShowInput] = React.useState(false);

  const navigate = useNavigate();

  const maxSize = 3 * 1024 * 1024;

  // const validationSchema = Yup.object({
  //   firstName: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("First name required"),
  //   lastName: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("Last name required"),
  //   dateOfBirth: Yup.date().required("Date of birth required"),
  //   emailId: Yup.string()
  //     .required("Email ID required")
  //     .email("Wrong email format")
  //     .min(8, "Minimum 8 characters")
  //     .max(50, "Maximum 50 characters")
  //     .matches(/^[a-z0-9.@]+$/, "Only letters (a-z), digits (0-9), and periods (.) are allowed"),
  //   idNumber: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("ID number required"),
  //   nationality: Yup.string().required("Nationality is required"),
  //   idProofType: Yup.string().required("Id proof type required"),
  //   idExpiryDate: Yup.date().required("Id expiry date is required"),
  //   secondaryMobileNumber: Yup.string()
  //     .required("Phone number is required")
  //     .min(10, "Phone number must be at least 10 digits")
  //     .max(15, "Phone number cannot be longer than 15 digits")
  //     .test("is-valid-phone", "Phone number is invalid", function (value) {
  //       if ( value) {
  //         return isPhoneValid(value); // Validate phone only if Phone is true and value exists
  //       }
  //       return true; // If Phone is false, or no value, bypass the phone validation
  //     }),
  //   // .matches(/^[0-9]+$/, 'Phone number must only contain digits'),
  //   file: Yup.mixed()
  //   .nullable()
  //   .test("fileType", "Upload PDF or images", function (value) {
  //     const reapply = regUser?.reapply;
  //     if (reapply) {
  //       return true;
  //     }
  //     if (!value) return true; // Allow null or undefined

  //     const file = value as File;
  //     const validExtensions = [
  //       ".pdf",
  //       ".jpg",
  //       ".jpeg",
  //       ".png",
  //       ".svg",
  //       ".gif",
  //       ".bmp",
  //       ".webp",
  //       ".tiff",
  //     ];
  //     const extension = file.name
  //       ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
  //       : "";
  //     return validExtensions.includes(extension);
  //   })
  //   .test("fileSize", "File size must be less than or equal to 3 MB", function (value) {
  //     const reapply = regUser?.reapply;
  //     if (reapply) {
  //       return true;
  //     }
  //     if (!value) return true; // Allow null or undefined

  //     const file = value as File;
  //     return file.size <= maxSize;
  //   }),
  // });

  const initialValues = {
    firstName: regUser?.secondaryDirectors?.[formIndex - 1]?.firstName || "",
    lastName: regUser?.secondaryDirectors?.[formIndex - 1]?.lastName || "",
    dateOfBirth:
      regUser?.secondaryDirectors?.[formIndex - 1]?.dob?.split("T")[0] || "",
    emailId: regUser?.secondaryDirectors?.[formIndex - 1]?.email || "",
    secondaryIsdCode:
      regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode || "",
    secondaryMobileNumber:
      (regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode
        ? regUser.secondaryDirectors[formIndex - 1].isdCode.toString()
        : "") +
      (regUser?.secondaryDirectors?.[formIndex - 1]?.mobileNumber
        ? regUser.secondaryDirectors[formIndex - 1].mobileNumber
        : ""),
    orderId: formIndex + 1,
    nationality:
      regUser?.secondaryDirectors?.[formIndex - 1]?.nationality || "",
    idProofType: regUser?.secondaryDirectors?.[formIndex - 1]?.idType || "",
    file: undefined,
    idNumber: regUser?.secondaryDirectors?.[formIndex - 1]?.idNumber || "",
    idExpiryDate:
      regUser?.secondaryDirectors?.[formIndex - 1]?.idExpiryDate?.split(
        "T"
      )[0] || "",
    status: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "First name can only contain letters and numbers")
      .required("First name is required")
      .test("name-changed", "First name must be different from the current value", function (value) {
        if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.firstName) {
          return value !== initialValues.firstName;
        }
        return true; // No validation if reapplyParams is not present
      }),
    lastName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("Last name is required")
      .test(
        "name-changed",
        "Last name must be different from the existing value",
        function (value) {
          if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.lastName) {
            return value !== initialValues.lastName;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),
    dateOfBirth: Yup.date()
      .required("Date of birth is required")
      .test("date-changed", "Registration date must differ from the current one", function (value) {
        if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.dob) {
          const initialDate = new Date(initialValues.dateOfBirth);
          const newDate = new Date(value);
          initialDate.setHours(0, 0, 0, 0);
          newDate.setHours(0, 0, 0, 0);
          return initialDate.getTime() !== newDate.getTime();
        }
        return true; // No validation if reapplyParams is not present
      }),
    emailId: Yup.string()
      .required("Email ID is required")
      .email("Invalid email format")
      .min(8, "Email must be at least 8 characters")
      .max(50, "Email cannot exceed 50 characters")
      .matches(
        /^[a-z0-9.@]+$/,
        "Only lowercase letters (a-z), digits (0-9), and periods (.) are allowed"
      ),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("ID number is required")
      .test(
        "name-changed",
        "ID number must differ from the existing value",
        function (value) {
          if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idNumber) {
            return value !== initialValues.idNumber;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),
    nationality: Yup.string()
      .required("Nationality is required")
      .test(
        "name-changed",
        "Nationality must differ from the existing value",
        function (value) {
          if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.nationality) {
            return value !== initialValues.nationality;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),
    idProofType: Yup.string()
      .required("ID proof type is required")
      .test(
        "name-changed",
        "ID proof type must differ from the existing value",
        function (value) {
          if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idType) {
            return value !== initialValues.idProofType;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),
    idExpiryDate: Yup.date()
      .required("ID expiry date is required")
      .test("date-changed", "Expiry date must differ from the existing one", function (value) {
        if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idExpiryDate) {
          const initialDate = new Date(initialValues.idExpiryDate);
          const newDate = new Date(value);
          initialDate.setHours(0, 0, 0, 0);
          newDate.setHours(0, 0, 0, 0);
          return initialDate.getTime() !== newDate.getTime();
        }
        return true; // No validation if reapplyParams is not present
      }),
    secondaryMobileNumber: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number cannot exceed 15 digits")
      .test("is-valid-phone", "Invalid phone number", function (value) {
        if (value) {
          return isPhoneValid(value); // Validate phone only if Phone is true and value exists
        }
        return true; // If Phone is false, or no value, bypass the phone validation
      }),
    file: Yup.mixed()
    .nullable()
      .test("fileType", "Please upload a valid PDF or image file", function (value) {
        const reapply = regUser?.reapply;
        if (reapply) {
          return true;
        }
        if (!value) return true;

        const file = value as File;
        const validExtensions = [
          ".pdf",
          ".jpg",
          ".jpeg",
          ".png",
          ".svg",
          ".gif",
          ".bmp",
          ".webp",
          ".tiff",
        ];
        const extension = file?.name
          ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
          : "";
        return validExtensions.includes(extension);
      })
      .test("fileSize", "File size must not exceed 3 MB", function (value) {
        const reapply = regUser?.reapply;
        if (reapply) {
          return true;
        }
        if (!value) return true; // Allow null or undefined

        const file = value as File;
        return file?.size <= 3 * 1024 * 1024; // 3 MB
      })
      .test("required", function (value) {
        if (regUser?.reapply) {
          if (regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idProofDocument) {
            return !!value || this.createError({ message: "Please upload a valid ID document" });
          }
          if (formik?.values?.file === undefined || null) {
            return this.createError({ message: "ID proof is required" });
          }
          // return true;
        }
        return !!value || this.createError({ message: "ID proof is required" });
      }),
  });

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik<UserFormValues>({
    initialValues: {
      firstName: regUser?.secondaryDirectors?.[formIndex - 1]?.firstName || "",
      lastName: regUser?.secondaryDirectors?.[formIndex - 1]?.lastName || "",
      dateOfBirth:
        regUser?.secondaryDirectors?.[formIndex - 1]?.dob?.split("T")[0] || "",
      emailId: regUser?.secondaryDirectors?.[formIndex - 1]?.email || "",
      secondaryIsdCode:
        regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode || "",
      secondaryMobileNumber:
        (regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode
          ? regUser.secondaryDirectors[formIndex - 1].isdCode.toString()
          : "") +
        (regUser?.secondaryDirectors?.[formIndex - 1]?.mobileNumber
          ? regUser.secondaryDirectors[formIndex - 1].mobileNumber
          : ""),
      orderId: formIndex + 1,
      nationality:
        regUser?.secondaryDirectors?.[formIndex - 1]?.nationality || "",
      idProofType: regUser?.secondaryDirectors?.[formIndex - 1]?.idType || "",
      file: undefined,
      idNumber: regUser?.secondaryDirectors?.[formIndex - 1]?.idNumber || "",
      idExpiryDate:
        regUser?.secondaryDirectors?.[formIndex - 1]?.idExpiryDate?.split("T")[0] || "",
      status: "",
    },
    validateOnChange:false,
    validateOnBlur:false,
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values, { setFieldValue, setSubmitting, resetForm }) => {
      setLoading(true);
      setSubmitting(true);  // Start form submission

      try {
        const res = await secondaryApplicant(
          values?.file,
          values?.firstName,
          values?.lastName,
          values?.dateOfBirth,
          values?.emailId,
          values?.nationality,
          values?.idProofType,
          values?.idNumber,
          values?.idExpiryDate,
          regUser?.token ?? "",
          values?.secondaryMobileNumber.split(" ")[0],
          values?.secondaryMobileNumber.split(" ")[1].replace(/[^0-9]/g, ""),
          values?.orderId,
          regUser?.reapply ?? false
        );

        if (res?.data?.status?.statusCode === 0) {
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }

          if (formIndex + 1 < Number(regUser?.noOfDirectors)) {
            setFormIndex(formIndex + 1);
          } else {
            resetCompletedForms();
            Swal.fire({
              icon: "info",
              title: "Application under review",
              text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
              showCancelButton: false,
              confirmButtonColor: "#007bff",
              confirmButtonText: "Proceed",
              allowEscapeKey: true,
              allowEnterKey: true,
            }).then((result) => {
              if (result.isConfirmed) navigate("/auth");
            });

            navigate("/auth");
          }

          resetForm();  // Reset the form only after all logic is executed
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);  // Stop loading
        setSubmitting(false);  // Stop form submission
        setShowInput(false);
      }
    }

  });

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, [])


  const handleCancelIDProof = () => {
    setShowInput(true);
    formik.setFieldValue("file", null);
    const fileInput = document.getElementById(
      "idFile"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  useEffect(() => {
    // Ensure regUser and businessDocs exist and fetch only if data is available
    if (regUser?.secondaryDirectors[formIndex - 1]?.documents && regUser?.secondaryDirectors[formIndex - 1]?.documents[0]?.documentPath && !regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idProofDocument) {
      const inputId = "idFile"; // License
      const formikField = "file";
  
      const fileUrl = regUser?.secondaryDirectors[formIndex - 1]?.documents[0]?.documentPath;
  
      // Fetch the file and simulate file selection for the specific input
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = `${regUser?.secondaryDirectors[formIndex - 1]?.documents[0]?.documentName || "document"}.${regUser?.secondaryDirectors[formIndex - 1]?.documents[0]?.documentExtention || "pdf"}`;
          const file = new File([blob], fileName, { type: blob.type });
  
          const fileInput = document.getElementById(inputId) as HTMLInputElement;
          if (fileInput) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            fileInput.files = dataTransfer.files;
  
            const event = new Event("change", { bubbles: true });
            fileInput.dispatchEvent(event);
  
            // Set the file in Formik for the respective field
            formik.setFieldValue(formikField, file);
          }
        })
        .catch((error) => {
          console.error("Error fetching file: ", error);
        });
    }
  }, [getAllData,formIndex]);

  return (
    <div
      className=" p-5 "

    >
      <p className="fs-2 fw-bold text-center">
        Please enter the secondary applicant’s details. (Director{" "}
        {formIndex + 1})
      </p>

      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">

            <label className="form-label">First Name {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.firstName ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.firstName}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik.touched.firstName && formik.errors.firstName
                  ? "is-invalid"
                  : ""
                } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.firstName ? "is-invalid"
                  : ""}`}
              placeholder="First Name"
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              maxLength={50}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="invalid-feedback">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Last Name {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.lastName ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.lastName}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik.touched.lastName && formik.errors.lastName
                  ? "is-invalid"
                  : ""
                } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.lastName ? "is-invalid"
                  : ""}`}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              maxLength={50}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="invalid-feedback">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Date Of Birth {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.dob ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.dob}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="date"
              className={`form-control ${formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  ? "is-invalid"
                  : ""
                } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.dob ? "is-invalid"
                  : ""}`}
              placeholder="Date Of Birth"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              max={new Date(new Date().setFullYear(new Date().getFullYear() - 15)).toISOString().split("T")[0]}
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="invalid-feedback">
                {formik.errors.dateOfBirth}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">
              Email Id (To be used as registered ID)
            </label>
            <input
              type="email"
              className={`form-control ${formik.touched.emailId && formik.errors.emailId
                  ? "is-invalid"
                  : ""
                }`}
              placeholder="Email Id"
              id="emailId"
              name="emailId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailId}
              maxLength={50}
            />
            {formik.touched.emailId && formik.errors.emailId ? (
              <div className="invalid-feedback">{formik.errors.emailId}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Mobile</label>
            {/* <PhoneInputs value={(formik.values?.secondaryMobileNumber)} onChange={(value) => { formik.setFieldValue("secondaryIsdCode", value?.countryData?.dialCode); formik.setFieldValue("secondaryMobileNumber", value?.phoneNumber) }} /> */}
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "43.59px",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  height: "43.59px",
                },
              }}
              // hideDropdown={true}
              // inputProps={{
              //   readOnly: true,
              // }}
              forceDialCode={true}
              value={formik.values.secondaryMobileNumber}
              onChange={(value) => {
                formik.setFieldValue("secondaryMobileNumber", value);
              }}
              defaultCountry="in"
            />
            {formik.touched.secondaryMobileNumber &&
              formik.errors.secondaryMobileNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {formik.errors.secondaryMobileNumber}
                    </span>
                  </div>
                </div>
              )}
          </div>
          <div className="mb-5">
            <label className="form-label">Nationality {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.nationality ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.nationality}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <div>
              <select
                className={`form-select ${formik.touched.nationality && formik.errors.nationality
                    ? "is-invalid"
                    : ""
                  } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.nationality ? "is-invalid"
                    : ""}`}
                name="nationality"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nationality}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="India">India</option>
                <option value="South Africa">South Africa</option>
                <option value="Uganda">Uganda</option>
              </select>
              {formik.touched.nationality && formik.errors.nationality ? (
                <div className="invalid-feedback">
                  {formik.errors.nationality}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Id Proof Type {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idType ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idType}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <div>
              <select
                className={`form-select ${formik.touched.idProofType && formik.errors.idProofType
                    ? "is-invalid"
                    : ""
                  } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idType ? "is-invalid"
                    : ""}`}
                name="idProofType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idProofType}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="NATIONAL_ID">NID</option>
                <option value="NATIONAL_ID_NO_PHOTO">Passport</option>
              </select>
              {formik.touched.idProofType && formik.errors.idProofType ? (
                <div className="invalid-feedback">
                  {formik.errors.idProofType}
                </div>
              ) : null}
            </div>
          </div>
          <div className={`mb-5 ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams
                          ?.idProofDocument ? "form-control is-invalid" : ""}`}>
            <label className="form-label">
              Upload ID Proof
              {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams
                ?.idProofDocument && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-add-money">
                        {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams
                          ?.idProofDocument}
                      </Tooltip>
                    }
                  >
                    <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                      <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                    </span>
                  </OverlayTrigger>
                )}
            </label>

            {/* Show preview if reapply is true and file exists */}
            {formik.values?.file && !showInput ? (
              <div className="d-flex flex-start">
                <div className="position-relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "20px",
                    }}
                    onClick={handleCancelIDProof} // Cancel preview to allow re-upload
                  >
                    &times;
                  </span>

                  <img
                    src={
                      formik?.values?.file?.type === "application/pdf"
                        ? toAbsoluteUrl("/media/subicons/pdf.png")
                        : formik?.values?.file
                          ? URL.createObjectURL(formik?.values?.file)
                          : ""
                    }
                    onClick={() => {
                      const fileUrl = formik?.values?.file
                        ? URL.createObjectURL(formik.values.file)
                        : "";
                      const isPdf = formik?.values?.file?.type === "application/pdf";
                      const extension = isPdf
                        ? "pdf"
                        : formik?.values?.file?.name?.split(".").pop() || "";
                      handlePreview(fileUrl, extension); // Open modal to preview file
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="mt-2"
                    alt="ID Proof"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      borderRadius: "5%",
                    }}
                  />
                </div>
              </div>
            ) : (
              <input
                type="file"
                ref={fileInputRef}
                id="idFile"
                className={`form-control ${formik.errors.file ? "is-invalid" : ""
                  }`}
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0];
                  formik.setFieldValue("file", file); // Set file value in Formik
                  if(!regUser?.reapply){
                    setShowInput(false);
                  }
                }}
                accept="image/*,.pdf"
              />
            )}

            {/* Show validation error */}
            {formik.touched.file && formik.errors.file && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.file}</span>
                </div>
              </div>
            )}

          </div>

          <div className="mb-5">
            <label className="form-label">ID Number {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idNumber ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idNumber}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="text"
              className={`form-control ${formik.touched.idNumber && formik.errors.idNumber
                  ? "is-invalid"
                  : ""
                } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idNumber ? "is-invalid"
                  : ""}`}
              placeholder="ID Number"
              id="idNumber"
              name="idNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idNumber}
              maxLength={20}
            />
            {formik.touched.idNumber && formik.errors.idNumber ? (
              <div className="invalid-feedback">{formik.errors.idNumber}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Id Expiry Date {regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idExpiryDate ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-add-money">{regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idExpiryDate}</Tooltip>}>
                <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                </span>
              </OverlayTrigger> : ""
            }</label>
            <input
              type="date"
              className={`form-control ${formik.touched.idExpiryDate && formik.errors.idExpiryDate
                  ? "is-invalid"
                  : ""
                } ${regUser?.secondaryDirectors[formIndex - 1]?.reapplyParams?.idExpiryDate ? "is-invalid"
                  : ""}`}
              placeholder="Id Expiry Date"
              id="idExpiryDate"
              name="idExpiryDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idExpiryDate}
              min={new Date().toISOString().split("T")[0]}
            />
            {formik.touched.idExpiryDate && formik.errors.idExpiryDate ? (
              <div className="invalid-feedback">
                {formik.errors.idExpiryDate}
              </div>
            ) : null}
          </div>
          <div className="mt-20">
            <button type="submit" className="btn w-100" disabled={formik.isSubmitting || loading}>
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Next"
              )}
            </button>
          </div>
        </form>
        <div
        className="modal fade w-100"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered w-100"
          role="document"
        >
          {previewSrc && (
            <>
              {extension === "pdf" ? (
                <div
                  className="modal-content w-100 p-0 m-0"
                  style={{ position: "absolute", right: "50%" }}
                >
                  <div className="modal-body p-0 m-0">
                    <iframe
                      src={previewSrc}
                      title="PDF Preview"
                      style={{ width: "200%", height: "80vh" }} // Adjust width to 100%
                    ></iframe>
                  </div>
                </div>
              ) : (
                <img
                  src={previewSrc}
                  alt="Document Preview"
                  style={{
                    width: "300px", // Optional: set width to 100%
                    height: "auto", // Optional: maintain aspect ratio
                    borderRadius: "10px",
                    objectFit: "fill",
                    marginLeft: "20%",
                    // maxWidth:"200px"
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default SecondaryApplicant;

