import React, { FC, useEffect, useState } from "react";
import { StepProps } from "../IAppModels";

const Step4 = ({ data, updateData, reset, hasError }: StepProps) => {
  const [selectAll, setSelectAll] = useState(true);
  const [txnDirections, setTxnDirections] = useState<
    { key: string; value: string }[]
  >([
    { key: "Cr", value: "Credit" },
    { key: "Dr", value: "Debit" },
  ]);
  const [selectedTxnDir, setSelectedTxnDir] = useState<string[]>(
    txnDirections.map(({ key }) => key)
  );

  data.direction.direction =
    selectedTxnDir?.length > 1 ? "all" : selectedTxnDir?.[0];

  useEffect(() => {
    setSelectAll(true);
    setSelectedTxnDir(txnDirections.map(({ key }) => key));
  }, [reset]);

  const handleMasterCheckboxChange = () => {
    const allTxnTypeKeys: string[] = txnDirections.map(({ key }) => key);
    setSelectAll(!selectAll);
    setSelectedTxnDir(selectAll ? [] : allTxnTypeKeys);

    updateData({
      direction: {
        direction: "",
      },
    });
  };

  const handleCheckboxChange = (key: string) => {
    const updatedSelectedTxnDir = selectedTxnDir.includes(key)
      ? selectedTxnDir.filter((selectedDir) => selectedDir !== key)
      : [...selectedTxnDir, key];

    const allSelected = txnDirections.length === updatedSelectedTxnDir.length;
    setSelectAll(allSelected);
    setSelectedTxnDir(updatedSelectedTxnDir);

    updateData({
      direction: {
        direction:
          updatedSelectedTxnDir?.length > 1
            ? "all"
            : updatedSelectedTxnDir?.[0],
      },
    });
  };

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <div className="d-flex flex-row justify-content-between align-items-center mb-10">
          <h2 className="fw-bolder text-dark">Transaction Direction</h2>
          <span className="form-check form-check-custom form-check-solid me-5">
            <input
              className="form-check-input"
              type="checkbox"
              name="txnDirection"
              value={"1"}
              onChange={() => {
                handleMasterCheckboxChange();
              }}
              checked={selectAll}
            />
          </span>
        </div>

        <div
          className="mb-0 fv-row px-5"
          style={{ height: "330px", overflowY: "auto" }}
        >
          <div className="mb-0">
            {txnDirections && txnDirections.length > 0 ? (
              txnDirections.map(({ key, value }, index) => (
                <label
                  className="d-flex flex-stack mb-10 cursor-pointer"
                  key={index}
                >
                  <span className="d-flex align-items-center me-2">
                    <span className="d-flex flex-column">
                      <span className="fw-bolder text-gray-800 text-hover-primary fs-5">
                        {value}
                      </span>
                      <span className="fs-6 fw-bold text-gray-400"></span>
                    </span>
                  </span>

                  <span className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="txnDirection"
                      value={key}
                      onChange={() => {
                        handleCheckboxChange(key);
                      }}
                      checked={selectedTxnDir.includes(key)}
                    />
                  </span>
                </label>
              ))
            ) : (
              <p>No transaction directions available.</p>
            )}
          </div>
          {selectedTxnDir.length === 0 && hasError && (
            <p className="text-danger">Select Transaction direction</p>
          )}
        </div>
      </div>
    </div>
  );
};

export { Step4 };
