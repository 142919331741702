/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../../../../app/pages/components/Modal/AppModel";
import { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import PinInput from "react-pin-input";

export const SteppperModal3 = ({ data, updateData, hasError }: StepProps) => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  return (
    <div
      className="pb-5 card shadow-sm p-10 "
      data-kt-stepper-element="content"
    >
      <div>
        <div className="text-center mt-3">
          <h4>Enter Your PIN</h4>
        </div>
        <div className="text-center text-success mt-3 fw-bold fs-4 p-0">
          <p>Payee Added</p>
        </div>
        <div className="text-center mt-3">
          <p>Enter your PIN to confirm</p>
        </div>
        <form action="" autoComplete="off">
          <div className="d-flex flex-column text-center mt-5 justify-content-center">
            {/* <PinInput
              length={4}
              // initialValue={data.pin.pin}
              secret
              type="numeric"
              inputMode="number"
              style={{ padding: "0" }}
              inputStyle={{
                borderColor: "black",
                borderRadius: "10px",
                // padding: "20px",
                marginLeft: "10px",
                flexWrap: "nowrap",
              }}
              focus={true}
              autoSelect={false}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {
                updateData({
                  pin: {
                    pin: value,
                  },
                });
              }}
              // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            /> */}

            <OTPInput
              // {...conversionformik?.getFieldProps("pin")}
              value={otp.join("")}
              onChange={(value: string) => {
                value
                  .split("")
                  .forEach((char, index) => handleChange(char, index));
              }}
              numInputs={4}
              containerStyle={{
                justifyContent: "center",
              }}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  value={otp[index]}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => {
                    const pin = handleChange(e.target.value, index);
                    if (pin.length === 4) {
                      updateData({
                        pin: {
                          pin: pin,
                        },
                      });
                    } else {
                      updateData({
                        pin: {
                          pin: "",
                        },
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    const pin = handleKeyDown(e, index);
                    updateData({
                      pin: {
                        pin: pin,
                      },
                    });
                  }}
                  type={show ? "password" : "text"}
                  autoFocus={true}
                  disabled={index !== activeInput}
                  onFocus={() => setActiveInput(index)}
                  className="text-center rounded mt-3 border border-1 border-dark"
                  style={{ height: "50px", width: "50px" }}
                />
              )}
            />
            <p
              className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
              onClick={() => setShow(!show)}
            >
              {show ? "Show PIN" : "Hide PIN"}
            </p>
            {!data.pin.pin && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  PIN is required
                </div>
              </div>
            )}
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};
