export const popularCurrencies = [
  {
    name: "United States",
    flag: "🇺🇸",
    code: "1",
    isoCode: "USA",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "44",
    isoCode: "GBR",
    currency: "GBP",
    currencyName: "British Pound Sterling",
  },
  {
    name: "France",
    flag: "🇫🇷",
    code: "33",
    isoCode: "FRA",
    currency: "EUR",
    currencyName: "Euro",
  },
];

export const currencyData = [
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    code: "93",
    isoCode: "AFG",
    currency: "AFN",
    currencyName: "Afghan Afghani",
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    code: "355",
    isoCode: "ALB",
    currency: "ALL",
    currencyName: "Albanian Lek",
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    code: "213",
    isoCode: "DZA",
    currency: "DZD",
    currencyName: "Algerian Dinar",
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    code: "376",
    isoCode: "AND",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    code: "244",
    isoCode: "AGO",
    currency: "AOA",
    currencyName: "Angolan Kwanza",
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    code: "1-268",
    isoCode: "ATG",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    code: "54",
    isoCode: "ARG",
    currency: "ARS",
    currencyName: "Argentine Peso",
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    code: "374",
    isoCode: "ARM",
    currency: "AMD",
    currencyName: "Armenian Dram",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "61",
    isoCode: "AUS",
    currency: "AUD",
    currencyName: "Australian Dollar",
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    code: "43",
    isoCode: "AUT",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    code: "994",
    isoCode: "AZE",
    currency: "AZN",
    currencyName: "Azerbaijani Manat",
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    code: "1-242",
    isoCode: "BHS",
    currency: "BSD",
    currencyName: "Bahamian Dollar",
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    code: "973",
    isoCode: "BHR",
    currency: "BHD",
    currencyName: "Bahraini Dinar",
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    code: "880",
    isoCode: "BGD",
    currency: "BDT",
    currencyName: "Bangladeshi Taka",
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    code: "1-246",
    isoCode: "BRB",
    currency: "BBD",
    currencyName: "Barbadian Dollar",
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    code: "375",
    isoCode: "BLR",
    currency: "BYN",
    currencyName: "Belarusian Ruble",
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    code: "32",
    isoCode: "BEL",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    code: "501",
    isoCode: "BLZ",
    currency: "BZD",
    currencyName: "Belize Dollar",
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    code: "229",
    isoCode: "BEN",
    currency: "XOF",
    currencyName: "West African CFA franc",
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    code: "975",
    isoCode: "BTN",
    currency: "BTN",
    currencyName: "Bhutanese Ngultrum",
  },
  {
    name: "Bolivia",
    flag: "🇧🇴",
    code: "591",
    isoCode: "BOL",
    currency: "BOB",
    currencyName: "Bolivian Boliviano",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    code: "387",
    isoCode: "BIH",
    currency: "BAM",
    currencyName: "Bosnia and Herzegovina Convertible Mark",
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    code: "267",
    isoCode: "BWA",
    currency: "BWP",
    currencyName: "Botswana Pula",
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    code: "55",
    isoCode: "BRA",
    currency: "BRL",
    currencyName: "Brazilian Real",
  },
  {
    name: "Brunei",
    flag: "🇧🇳",
    code: "673",
    isoCode: "BRN",
    currency: "BND",
    currencyName: "Brunei Dollar",
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    code: "359",
    isoCode: "BGR",
    currency: "BGN",
    currencyName: "Bulgarian Lev",
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    code: "226",
    isoCode: "BFA",
    currency: "XOF",
    currencyName: "West African CFA franc",
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    code: "257",
    isoCode: "BDI",
    currency: "BIF",
    currencyName: "Burundian Franc",
  },
  {
    name: "Cabo Verde",
    flag: "🇨🇻",
    code: "238",
    isoCode: "CPV",
    currency: "CVE",
    currencyName: "Cape Verdean Escudo",
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    code: "855",
    isoCode: "KHM",
    currency: "KHR",
    currencyName: "Cambodian Riel",
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    code: "237",
    isoCode: "CMR",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  // {
  //   name: "Canada",
  //   flag: "🇨🇦",
  //   code: "1",
  //   isoCode: "CAN",
  //   currency: "CAD",
  //   currencyName: "Canadian Dollar",
  // },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    code: "236",
    isoCode: "CAF",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    code: "235",
    isoCode: "TCD",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    code: "56",
    isoCode: "CHL",
    currency: "CLP",
    currencyName: "Chilean Peso",
  },
  {
    name: "China",
    flag: "🇨🇳",
    code: "86",
    isoCode: "CHN",
    currency: "CNY",
    currencyName: "Chinese Yuan",
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    code: "57",
    isoCode: "COL",
    currency: "COP",
    currencyName: "Colombian Peso",
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    code: "269",
    isoCode: "COM",
    currency: "KMF",
    currencyName: "Comorian Franc",
  },
  {
    name: "Congo (Congo-Brazzaville)",
    flag: "🇨🇬",
    code: "242",
    isoCode: "COG",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    code: "506",
    isoCode: "CRI",
    currency: "CRC",
    currencyName: "Costa Rican Colón",
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    code: "385",
    isoCode: "HRV",
    currency: "HRK",
    currencyName: "Croatian Kuna",
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    code: "53",
    isoCode: "CUB",
    currency: "CUP",
    currencyName: "Cuban Peso",
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    code: "357",
    isoCode: "CYP",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Czechia (Czech Republic)",
    flag: "🇨🇿",
    code: "420",
    isoCode: "CZE",
    currency: "CZK",
    currencyName: "Czech Koruna",
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    code: "45",
    isoCode: "DNK",
    currency: "DKK",
    currencyName: "Danish Krone",
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    code: "253",
    isoCode: "DJI",
    currency: "DJF",
    currencyName: "Djiboutian Franc",
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    code: "1-767",
    isoCode: "DMA",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    code: "1-849",
    isoCode: "DOM",
    currency: "DOP",
    currencyName: "Dominican Peso",
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    code: "593",
    isoCode: "ECU",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    code: "20",
    isoCode: "EGY",
    currency: "EGP",
    currencyName: "Egyptian Pound",
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    code: "503",
    isoCode: "SLV",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    code: "240",
    isoCode: "GNQ",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    code: "291",
    isoCode: "ERI",
    currency: "ERN",
    currencyName: "Eritrean Nakfa",
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    code: "372",
    isoCode: "EST",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Eswatini",
    flag: "🇸🇿",
    code: "268",
    isoCode: "SWZ",
    currency: "SZL",
    currencyName: "Swazi Lilangeni",
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    code: "251",
    isoCode: "ETH",
    currency: "ETB",
    currencyName: "Ethiopian Birr",
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    code: "679",
    isoCode: "FJI",
    currency: "FJD",
    currencyName: "Fijian Dollar",
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    code: "358",
    isoCode: "FIN",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "France",
    flag: "🇫🇷",
    code: "33",
    isoCode: "FRA",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    code: "241",
    isoCode: "GAB",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    code: "220",
    isoCode: "GMB",
    currency: "GMD",
    currencyName: "Gambian Dalasi",
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    code: "995",
    isoCode: "GEO",
    currency: "GEL",
    currencyName: "Georgian Lari",
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    code: "49",
    isoCode: "DEU",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    code: "233",
    isoCode: "GHA",
    currency: "GHS",
    currencyName: "Ghanaian Cedi",
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    code: "30",
    isoCode: "GRC",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    code: "1-473",
    isoCode: "GRD",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    code: "502",
    isoCode: "GTM",
    currency: "GTQ",
    currencyName: "Guatemalan Quetzal",
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    code: "224",
    isoCode: "GIN",
    currency: "GNF",
    currencyName: "Guinean Franc",
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    code: "245",
    isoCode: "GNB",
    currency: "XOF",
    currencyName: "West African CFA Franc",
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    code: "592",
    isoCode: "GUY",
    currency: "GYD",
    currencyName: "Guyanese Dollar",
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    code: "509",
    isoCode: "HTI",
    currency: "HTG",
    currencyName: "Haitian Gourde",
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    code: "504",
    isoCode: "HND",
    currency: "HNL",
    currencyName: "Honduran Lempira",
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    code: "36",
    isoCode: "HUN",
    currency: "HUF",
    currencyName: "Hungarian Forint",
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    code: "354",
    isoCode: "ISL",
    currency: "ISK",
    currencyName: "Icelandic Króna",
  },
  {
    name: "India",
    flag: "🇮🇳",
    code: "91",
    isoCode: "IND",
    currency: "INR",
    currencyName: "Indian Rupee",
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    code: "62",
    isoCode: "IDN",
    currency: "IDR",
    currencyName: "Indonesian Rupiah",
  },
  {
    name: "Iran",
    flag: "🇮🇷",
    code: "98",
    isoCode: "IRN",
    currency: "IRR",
    currencyName: "Iranian Rial",
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    code: "964",
    isoCode: "IRQ",
    currency: "IQD",
    currencyName: "Iraqi Dinar",
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    code: "353",
    isoCode: "IRL",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    code: "972",
    isoCode: "ISR",
    currency: "ILS",
    currencyName: "Israeli New Shekel",
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    code: "39",
    isoCode: "ITA",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    code: "1-876",
    isoCode: "JAM",
    currency: "JMD",
    currencyName: "Jamaican Dollar",
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    code: "81",
    isoCode: "JPN",
    currency: "JPY",
    currencyName: "Japanese Yen",
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    code: "962",
    isoCode: "JOR",
    currency: "JOD",
    currencyName: "Jordanian Dinar",
  },
  // {
  //   name: "Kazakhstan",
  //   flag: "🇰🇿",
  //   code: "7",
  //   isoCode: "KAZ",
  //   currency: "KZT",
  //   currencyName: "Kazakhstani Tenge",
  // },
  {
    name: "Kenya",
    flag: "🇰🇪",
    code: "254",
    isoCode: "KEN",
    currency: "KES",
    currencyName: "Kenyan Shilling",
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    code: "686",
    isoCode: "KIR",
    currency: "AUD",
    currencyName: "Australian Dollar",
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    code: "965",
    isoCode: "KWT",
    currency: "KWD",
    currencyName: "Kuwaiti Dinar",
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    code: "996",
    isoCode: "KGZ",
    currency: "KGS",
    currencyName: "Kyrgyzstani Som",
  },
  {
    name: "Laos",
    flag: "🇱🇦",
    code: "856",
    isoCode: "LAO",
    currency: "LAK",
    currencyName: "Lao Kip",
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    code: "371",
    isoCode: "LVA",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    code: "961",
    isoCode: "LBN",
    currency: "LBP",
    currencyName: "Lebanese Pound",
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    code: "266",
    isoCode: "LSO",
    currency: "LSL",
    currencyName: "Lesotho Loti",
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    code: "231",
    isoCode: "LBR",
    currency: "LRD",
    currencyName: "Liberian Dollar",
  },
  {
    name: "Libya",
    flag: "🇱🇾",
    code: "218",
    isoCode: "LBY",
    currency: "LYD",
    currencyName: "Libyan Dinar",
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    code: "423",
    isoCode: "LIE",
    currency: "CHF",
    currencyName: "Swiss Franc",
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    code: "370",
    isoCode: "LTU",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    code: "352",
    isoCode: "LUX",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    code: "261",
    isoCode: "MDG",
    currency: "MGA",
    currencyName: "Malagasy Ariary",
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    code: "265",
    isoCode: "MWI",
    currency: "MWK",
    currencyName: "Malawian Kwacha",
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    code: "60",
    isoCode: "MYS",
    currency: "MYR",
    currencyName: "Malaysian Ringgit",
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    code: "960",
    isoCode: "MDV",
    currency: "MVR",
    currencyName: "Maldivian Rufiyaa",
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    code: "223",
    isoCode: "MLI",
    currency: "XOF",
    currencyName: "West African CFA Franc",
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    code: "356",
    isoCode: "MLT",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    code: "692",
    isoCode: "MHL",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    code: "222",
    isoCode: "MRT",
    currency: "MRU",
    currencyName: "Mauritanian Ouguiya",
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    code: "230",
    isoCode: "MUS",
    currency: "MUR",
    currencyName: "Mauritian Rupee",
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    code: "52",
    isoCode: "MEX",
    currency: "MXN",
    currencyName: "Mexican Peso",
  },
  {
    name: "Micronesia",
    flag: "🇫🇲",
    code: "691",
    isoCode: "FSM",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Moldova",
    flag: "🇲🇩",
    code: "373",
    isoCode: "MDA",
    currency: "MDL",
    currencyName: "Moldovan Leu",
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    code: "377",
    isoCode: "MCO",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    code: "976",
    isoCode: "MNG",
    currency: "MNT",
    currencyName: "Mongolian Tugrik",
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    code: "382",
    isoCode: "MNE",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    code: "212",
    isoCode: "MAR",
    currency: "MAD",
    currencyName: "Moroccan Dirham",
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    code: "258",
    isoCode: "MOZ",
    currency: "MZN",
    currencyName: "Mozambican Metical",
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    code: "95",
    isoCode: "MMR",
    currency: "MMK",
    currencyName: "Burmese Kyat",
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    code: "264",
    isoCode: "NAM",
    currency: "NAD",
    currencyName: "Namibian Dollar",
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    code: "674",
    isoCode: "NRU",
    currency: "AUD",
    currencyName: "Australian Dollar",
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    code: "977",
    isoCode: "NPL",
    currency: "NPR",
    currencyName: "Nepalese Rupee",
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    code: "31",
    isoCode: "NLD",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    code: "64",
    isoCode: "NZL",
    currency: "NZD",
    currencyName: "New Zealand Dollar",
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    code: "505",
    isoCode: "NIC",
    currency: "NIO",
    currencyName: "Nicaraguan Córdoba",
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    code: "227",
    isoCode: "NER",
    currency: "XOF",
    currencyName: "West African CFA Franc",
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    code: "234",
    isoCode: "NGA",
    currency: "NGN",
    currencyName: "Nigerian Naira",
  },
  {
    name: "North Korea",
    flag: "🇰🇵",
    code: "850",
    isoCode: "PRK",
    currency: "KPW",
    currencyName: "North Korean Won",
  },
  {
    name: "North Macedonia",
    flag: "🇲🇰",
    code: "389",
    isoCode: "MKD",
    currency: "MKD",
    currencyName: "Macedonian Denar",
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    code: "47",
    isoCode: "NOR",
    currency: "NOK",
    currencyName: "Norwegian Krone",
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    code: "968",
    isoCode: "OMN",
    currency: "OMR",
    currencyName: "Omani Rial",
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    code: "92",
    isoCode: "PAK",
    currency: "PKR",
    currencyName: "Pakistani Rupee",
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    code: "680",
    isoCode: "PLW",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    code: "507",
    isoCode: "PAN",
    currency: "PAB",
    currencyName: "Panamanian Balboa",
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    code: "675",
    isoCode: "PNG",
    currency: "PGK",
    currencyName: "Papua New Guinean Kina",
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    code: "595",
    isoCode: "PRY",
    currency: "PYG",
    currencyName: "Paraguayan Guarani",
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    code: "51",
    isoCode: "PER",
    currency: "PEN",
    currencyName: "Peruvian Nuevo Sol",
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    code: "63",
    isoCode: "PHL",
    currency: "PHP",
    currencyName: "Philippine Peso",
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    code: "48",
    isoCode: "POL",
    currency: "PLN",
    currencyName: "Polish Złoty",
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    code: "351",
    isoCode: "PRT",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    code: "974",
    isoCode: "QAT",
    currency: "QAR",
    currencyName: "Qatari Riyal",
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    code: "40",
    isoCode: "ROU",
    currency: "RON",
    currencyName: "Romanian Leu",
  },
  {
    name: "Russia",
    flag: "🇷🇺",
    code: "7",
    isoCode: "RUS",
    currency: "RUB",
    currencyName: "Russian Ruble",
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    code: "250",
    isoCode: "RWA",
    currency: "RWF",
    currencyName: "Rwandan Franc",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    code: "1-869",
    isoCode: "KNA",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    code: "1-758",
    isoCode: "LCA",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "1-784",
    isoCode: "VCT",
    currency: "XCD",
    currencyName: "East Caribbean Dollar",
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    code: "685",
    isoCode: "WSM",
    currency: "WST",
    currencyName: "Samoan Tala",
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    code: "378",
    isoCode: "SMR",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    code: "239",
    isoCode: "STP",
    currency: "STN",
    currencyName: "São Tomé and Príncipe Dobra",
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    code: "966",
    isoCode: "SAU",
    currency: "SAR",
    currencyName: "Saudi Riyal",
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    code: "221",
    isoCode: "SEN",
    currency: "XOF",
    currencyName: "West African CFA Franc",
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    code: "381",
    isoCode: "SRB",
    currency: "RSD",
    currencyName: "Serbian Dinar",
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    code: "248",
    isoCode: "SYC",
    currency: "SCR",
    currencyName: "Seychellois Rupee",
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    code: "232",
    isoCode: "SLE",
    currency: "SLL",
    currencyName: "Sierra Leonean Leone",
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    code: "65",
    isoCode: "SGP",
    currency: "SGD",
    currencyName: "Singapore Dollar",
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    code: "421",
    isoCode: "SVK",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    code: "386",
    isoCode: "SVN",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    code: "677",
    isoCode: "SLB",
    currency: "SBD",
    currencyName: "Solomon Islands Dollar",
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    code: "252",
    isoCode: "SOM",
    currency: "SOS",
    currencyName: "Somali Shilling",
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    code: "27",
    isoCode: "ZAF",
    currency: "ZAR",
    currencyName: "South African Rand",
  },
  {
    name: "South Korea",
    flag: "🇰🇷",
    code: "82",
    isoCode: "KOR",
    currency: "KRW",
    currencyName: "South Korean Won",
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    code: "211",
    isoCode: "SSD",
    currency: "SSP",
    currencyName: "South Sudanese Pound",
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    code: "34",
    isoCode: "ESP",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    code: "94",
    isoCode: "LKA",
    currency: "LKR",
    currencyName: "Sri Lankan Rupee",
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    code: "249",
    isoCode: "SDN",
    currency: "SDG",
    currencyName: "Sudanese Pound",
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    code: "597",
    isoCode: "SUR",
    currency: "SRD",
    currencyName: "Surinamese Dollar",
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    code: "46",
    isoCode: "SWE",
    currency: "SEK",
    currencyName: "Swedish Krona",
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    code: "41",
    isoCode: "CHE",
    currency: "CHF",
    currencyName: "Swiss Franc",
  },
  {
    name: "Syria",
    flag: "🇸🇾",
    code: "963",
    isoCode: "SYR",
    currency: "SYP",
    currencyName: "Syrian Pound",
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    code: "886",
    isoCode: "TWN",
    currency: "TWD",
    currencyName: "New Taiwan Dollar",
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    code: "992",
    isoCode: "TJK",
    currency: "TJS",
    currencyName: "Tajikistani Somoni",
  },
  {
    name: "Tanzania",
    flag: "🇹🇿",
    code: "255",
    isoCode: "TZA",
    currency: "TZS",
    currencyName: "Tanzanian Shilling",
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    code: "66",
    isoCode: "THA",
    currency: "THB",
    currencyName: "Thai Baht",
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    code: "670",
    isoCode: "TLS",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    code: "228",
    isoCode: "TGO",
    currency: "XOF",
    currencyName: "West African CFA Franc",
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    code: "676",
    isoCode: "TON",
    currency: "TOP",
    currencyName: "Tongan Paʻanga",
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    code: "1-868",
    isoCode: "TTO",
    currency: "TTD",
    currencyName: "Trinidad and Tobago Dollar",
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    code: "216",
    isoCode: "TUN",
    currency: "TND",
    currencyName: "Tunisian Dinar",
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    code: "90",
    isoCode: "TUR",
    currency: "TRY",
    currencyName: "Turkish Lira",
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    code: "993",
    isoCode: "TKM",
    currency: "TMT",
    currencyName: "Turkmenistan Manat",
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    code: "688",
    isoCode: "TUV",
    currency: "AUD",
    currencyName: "Australian Dollar",
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    code: "256",
    isoCode: "UGA",
    currency: "UGX",
    currencyName: "Ugandan Shilling",
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    code: "380",
    isoCode: "UKR",
    currency: "UAH",
    currencyName: "Ukrainian Hryvnia",
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    code: "971",
    isoCode: "ARE",
    currency: "AED",
    currencyName: "United Arab Emirates Dirham",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "44",
    isoCode: "GBR",
    currency: "GBP",
    currencyName: "British Pound Sterling",
  },
  {
    name: "United States",
    flag: "🇺🇸",
    code: "1",
    isoCode: "USA",
    currency: "USD",
    currencyName: "United States Dollar",
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    code: "598",
    isoCode: "URY",
    currency: "UYU",
    currencyName: "Uruguayan Peso",
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    code: "998",
    isoCode: "UZB",
    currency: "UZS",
    currencyName: "Uzbekistan Som",
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    code: "678",
    isoCode: "VUT",
    currency: "VUV",
    currencyName: "Vanuatu Vatu",
  },
  {
    name: "Vatican City",
    flag: "🇻🇦",
    code: "379",
    isoCode: "VAT",
    currency: "EUR",
    currencyName: "Euro",
  },
  {
    name: "Venezuela",
    flag: "🇻🇪",
    code: "58",
    isoCode: "VEN",
    currency: "VES",
    currencyName: "Venezuelan Bolívar",
  },
  {
    name: "Vietnam",
    flag: "🇻🇳",
    code: "84",
    isoCode: "VNM",
    currency: "VND",
    currencyName: "Vietnamese Đồng",
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    code: "967",
    isoCode: "YEM",
    currency: "YER",
    currencyName: "Yemeni Rial",
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    code: "260",
    isoCode: "ZMB",
    currency: "ZMW",
    currencyName: "Zambian Kwacha",
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    code: "263",
    isoCode: "ZWE",
    currency: "ZWD",
    currencyName: "Zimbabwean Dollar",
  },
];
