/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "../../../../_metronic/helpers";
import { StepProps } from "../../../../app/pages/components/Modal/AppModel";

export const StepperModal = ({ data, updateData, hasError }: StepProps) => {
  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row">
          {/* begin::Label */}
          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
            <span className="required">Select Category</span>
          </label>
          {/* end::Label */}
          <div>
            {/*begin:Option */}
            <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span
                    className="symbol-label"
                    style={{ backgroundColor: "#f0f8ff" }}
                  >
                    {/* <KTIcon iconName='category' className='fs-1 text-danger' />
                     */}
                    <i
                      className="bi bi-arrows-angle-contract fs-2"
                      style={{ color: "#0000FF" }}
                    ></i>
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bolder fs-6">Same bank (Domestic)</span>
                  {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one SEO
                  </span> */}
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  name="appType"
                  value="Quick Online Courses"
                  checked={data.appBasic.appType === "Same Bank"}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appType: "Same Bank",
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span
                    className="symbol-label"
                    style={{ backgroundColor: "#f0f8ff" }}
                  >
                    {/* <KTIcon iconName='category' className='fs-1 text-danger' />
                     */}
                    <i
                      className="bi bi-arrows-angle-expand fs-2"
                      style={{ color: "#0000FF" }}
                    ></i>
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bolder fs-6">
                    Different bank (Domestic)
                  </span>
                  {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one aspect
                  </span> */}
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  name="appType"
                  value="Different Bank"
                  checked={data.appBasic.appType === "Different Bank"}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appType: "Different Bank",
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span
                    className="symbol-label "
                    style={{ backgroundColor: "#f0f8ff" }}
                  >
                    {/* <KTIcon iconName='timer' className='fs-1 text-success' /> */}
                    <i
                      className="bi bi-globe2 fs-2"
                      style={{ color: "#0000ff" }}
                    ></i>
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bolder fs-6">International</span>
                  {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure copywriting
                  </span> */}
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  name="appType"
                  value="International"
                  checked={data.appBasic.appType === "International"}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appType: "International",
                      },
                    })
                  }
                />
              </span>
            </label>
            <label className="d-flex align-items-center justify-content-between mb-6 disabled">
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span
                    className="symbol-label"
                    style={{ backgroundColor: "#f0f8ff" }}
                  >
                    {/* <KTIcon iconName='timer' className='fs-1 text-success' />
                     */}
                    <i
                      className="bi bi-wallet2 fs-5"
                      style={{ color: "#0000ff" }}
                    ></i>
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bolder fs-6">Wallet</span>
                  {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure copywriting
                  </span> */}
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  name="appType"
                  value="Wallet"
                  checked={data.appBasic.appType === "Wallet"}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appType: "Wallet",
                      },
                    })
                  }
                  disabled={true}
                />
              </span>
            </label>
            {/*end::Option */}
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  );
};
