import { useState, useEffect, useRef } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import deleteIcon from "../../../../_metronic/assets/Icons/Group.png";
import { Modal } from "bootstrap";
import { TransferAuthentication } from "./TransferAuthentication";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikProps,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import { transferData } from "./TransferData";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { User } from "../../TransferMoney/TransferModal";

interface Account {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  createdDate: string;
  balance: string;
}

interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  accountType: string;
  primaryFundId: string;
  subAccounts: [
    {
      currency: string;
      iconCode: string;
      goal: string;
      goalLimit: number;
      creditCondition: string;
      creditType: string;
      creditValue: number;
      creditFrequency: string;
      subAccountType: string;
      subAccountName: string;
      subAccountNumber: string;
      subAccountStatus: number;
      balance: number;
      createdDate: string;
    }
  ];
}

interface subAccData {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
  subAccountExist: boolean;
}

interface FormValues {
  selectedAccount: string;
  amount: string;
  approver: string;
}

export function TransferModal({
  passedData,
  accData,
  accNumber,
}: {
  passedData: accDetails | null;
  accData: Account | null;
  accNumber: string | "";
}) {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [inputValue, setInputValue] = useState("");
  const [accounts, setAccounts] = useState<subAccData[]>([]);
  const [acc, setAcc] = useState("");
  const [type, setType] = useState("");
  const [button, setButton] = useState("Submit");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [authority, setAuthority] = useState(0);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  transferData.accountNumber = passedData?.accountNumber || ""; // Assign an empty string if 'acc' is undefined
  transferData.payerAccountNumber = acc || "";
  transferData.amount = inputValue || "";
  transferData.payerType = type || "";
  transferData.payeeAccountNumber = accData?.subAccountName || "";
  transferData.payeeType = "Sub Account"; // Assign an empty string if 'accData?.subAccountType' is undefined
  transferData.remarks = "trf";

  console.log(passedData);

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);

      modalRef.current.addEventListener("shown.bs.modal", () => {
        const selectField = modalRef?.current?.querySelector(
          "select"
        ) as HTMLSelectElement | null;
        if (selectField) {
          selectField.focus();
        }
      });

      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleTransferCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleTransferCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("transfer-authentication");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleTransferCloseModal = () => {
    formikRef?.current?.resetForm();
    formikRef.current?.setFieldValue("selectedAccount", "");
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  const subAccountData = async () => {
    try {
      const url = `${API_URL}/subAccount/addMoney/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ApproverList(userDet?.userDetails?.zarLimit);
    subAccountData();
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus on the input field when the component mounts or updates
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (+inputValue) {
        ApproverList(inputValue);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [inputValue]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .custom-input::-webkit-outer-spin-button,
          .custom-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      <div
        className="modal fade"
        tabIndex={-1}
        id="transfer_modal"
        ref={modalRef}
        //   
      >
        <div
          className="modal-dialog "
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between align-items-center py-4">
              <h4 className="mb-0">Add Money</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={() => {
                  handleTransferCloseModal();
                  formikRef?.current?.resetForm();
                  formikRef.current?.setFieldValue("selectedAccount", "");
                }}
              >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div
              className="modal-body w-500px"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "300px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <div className="d-flex flex-column gap-5 p-5">
                  <Formik
                    innerRef={formikRef}
                    initialValues={{
                      selectedAccount: "",
                      amount: "",
                      approver: "",
                    }}
                    validationSchema={Yup.object().shape({
                      selectedAccount: Yup.string().required(
                        "Please select an account"
                      ),
                      amount: Yup.number()
                        .required("Please enter an amount")
                        .positive("Amount must be greater than 0")
                        .typeError("Please enter a valid number")
                        .test({
                          name: "balanceCheck",
                          test: function (value) {
                            const { selectedAccount } = this.parent; // Get the selected account

                            // Check if an account is selected before performing the balance check
                            if (!selectedAccount) {
                              return true; // Return true to indicate no error if no account is selected
                            }

                            const selectedAccountData = accounts.find(
                              (account) =>
                                account.accountNumber === selectedAccount
                            ); // Find the selected account in the accounts array

                            // Check if the amount is greater than the selected account balance
                            return (
                              selectedAccountData &&
                              value <= selectedAccountData.balance
                            );
                          },
                          message:
                            "Amount must be less than or equal to the selected account balance",
                        }),
                      approver: Yup.string().test(
                        "approver-required",
                        "Select an approver",
                        function (value) {
                          if (button === "Initiate") {
                            return !!value;
                          }
                          return true;
                        }
                      ),
                    })}
                    onSubmit={async (values, { resetForm }) => {
                      console.log(values);

                      try {
                        const approverGuid = approvers.find(
                          (app) => app.userGuid === values.approver
                        );

                        if (button === "Initiate") {
                          transferData.approverName = approverGuid
                            ? approverGuid.name
                            : "";
                          transferData.approverGuid = values.approver;
                          transferData.beneficiaryName = values.selectedAccount;
                          // AccData?.accountNumber || "";
                          transferData.currency = baseCurrency || "";
                          transferData.initiatedTxn = true;
                        }

                        // Assuming handleTransferCloseModal is an asynchronous function
                        await handleTransferCloseModal();

                        await new Promise((resolve) =>
                          setTimeout(resolve, 100)
                        );

                        // Assuming handleDeleteConfirmation is an asynchronous function
                        await handleDeleteConfirmation();

                        resetForm();
                      } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle any error gracefully here
                      }
                    }}
                  >
                    <Form>
                      <div className="mb-5">
                        <label className="form-label" htmlFor="selectedAccount">
                          Select Account
                        </label>
                        <Field name="selectedAccount">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <>
                              <select
                                {...field}
                                className="form-select"
                                // value={field.selectedAccount}
                                onChange={(e) => {
                                  const selectedAccountNumber = e.target.value;
                                  const selectedAccount = accounts.find(
                                    (account) =>
                                      account.accountNumber ===
                                      selectedAccountNumber
                                  );

                                  if (selectedAccount) {
                                    setAcc(selectedAccount.accountNumber);
                                    setType(selectedAccount.accountType);
                                    form.setFieldValue(
                                      "selectedAccount",
                                      selectedAccountNumber
                                    );
                                  }
                                }}
                              >
                                <option value="">Select Account</option>

                                {accounts?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item?.accountNumber}
                                  >
                                    {item?.accountNumber} ({item?.accountType}
                                    )&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{" "}
                                    {item.currency}{" "}
                                    {item?.balance.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                        </Field>

                        <ErrorMessage
                          component="div"
                          name="selectedAccount"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-5" style={{ position: "relative" }}>
                        <label className="form-label" htmlFor="amount">
                          Enter Amount
                        </label>

                        <Field name="amount" type="number">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <>
                               <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{
                              position: "absolute",
                              top: "68%",
                              left: "10px",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                            }}
                          >
                            {baseCurrency}
                          </span>
                              <input
                                {...field}
                                type="text"
                                className={`form-control ${
                                  form.touched.amount && form.errors.amount
                                    ? "is-invalid"
                                    : ""
                                } custom-input`}
                                style={{ paddingLeft: "60px" }}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // If the value is empty, set the previous value to an empty string
                                  if (inputValue === ""||inputValue==="0") {
                                    form.handleChange(e); // Trigger Formik's change handler
                                    form.validateField("amount");
                                    form.setFieldValue("amount", "");
                                    setInputValue("");
                                    return
                                  }

                                  // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                  if (
                                    /^\d{1,12}(\.\d{0,2})?$/.test(inputValue)
                                  ) {
                                    form.handleChange(e); // Trigger Formik's change handler
                                    form.validateField("amount");
                                    form.setFieldValue("amount", inputValue);
                                    setInputValue(inputValue);
                                  }
                                  // if (inputValue.length <= 12) {
                                  //   form.handleChange(e); // Trigger Formik's change handler
                                  //   form.validateField("amount");
                                  //   form.setFieldValue("amount", inputValue);
                                  //   setInputValue(inputValue);
                                  // }
                                }}
                                ref={inputRef}
                              />
                            </>
                          )}
                        </Field>

                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="text-danger text-nowrap mt-2" // Adjust the margin as needed
                        />
                      </div>

                      {button === "Initiate" ? (
                        <div className="mb-5">
                          <label className="form-label" htmlFor="approver">
                            Select Approver
                          </label>
                          <Field name="approver">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <>
                                <select
                                  {...field}
                                  className="form-select"
                                  value={field.value}
                                  onChange={(e) => {
                                    form.setFieldValue(
                                      "approver",
                                      e.target.value
                                    );
                                  }}
                                  autoFocus={true}
                                >
                                  <option value="" disabled>
                                    Select Approver
                                  </option>
                                  {approvers.map((item, index) => (
                                    <option key={index} value={item?.userGuid}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                          </Field>

                          <ErrorMessage
                            component="div"
                            name="approver"
                            className="text-danger"
                          />
                        </div>
                      ) : null}

                      <div className="d-flex flex-row gap-5">
                        <button
                          type="submit"
                          className="btn rounded"
                          style={{
                            width: "100%",
                          }}
                        >
                          Proceed
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "100%",
                            color: "#246bfd",
                          }}
                          onClick={() => {
                            handleTransferCloseModal();
                            formikRef?.current?.resetForm();
                            formikRef.current?.setFieldValue(
                              "selectedAccount",
                              ""
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      {authority === 3 && (
                        <p className="text-danger text-center fw-bold">
                          You don't have the authority to perform this action
                        </p>
                      )}
                    </Form>
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {<TransferAuthentication />}
    </>
  );
}
