/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../../../../_metronic/partials/modals/create-app-stepper/IAppModels";
import Ellipse0 from "../../../../../src/_metronic/assets/Icons/Ellipse0.svg";
import Ellipse from "../../../../_metronic/assets/Icons/Group.png";

interface SteppperModal4Props {
  error: string;
}

export const SteppperModal4: React.FC<SteppperModal4Props> = ({ error }) => {
  return (
    <div className="pb-5 card shadow-sm p-15" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="text-center mb-10">
          <div className="d-flex justify-content-center position-relative">
            {error ? (
              <i className="bi bi-x-circle text-danger fs-5x"></i>
            ) : (
              <i className="bi bi-check-circle text-success fs-5x"></i>
            )}
          </div>
        </div>
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {error ? error : "Congratulations !!"}
          </h1>
          <div className="text-muted fw-semibold fs-5 mb-10 ">
            {error
              ? "Payee has not been verfied.."
              : "Payee has been verfied.."}
          </div>
          {error && (
            <p className="text-danger text-center">
              Please verify payee from dashboard
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
//
