/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "./SubAccountAppModel";
import Ellipse0 from "../../../../../src/_metronic/assets/Icons/Ellipse0.svg";
import Ellipse from "../../../../_metronic/assets/Icons/Group.png";
import { KTIcon } from "../../../../_metronic/helpers";
import { useState } from "react";

export const SteppperModal4 = ({
  data,
  updateData,
  hasError,
  setHasError,
}: StepProps) => {
  const baseCurrency = sessionStorage.getItem("currency");
  const [previousValue, setPreviousValue] = useState("");

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handlePercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      updateData({
        appPercentage: {
          percentage: value.toString(),
        },
      });
    }

    if (/^\d{1,2}$/.test(value)) {
      updateData({
        appPercentage: {
          percentage: value.toString(),
        },
      });
    }
  };

  return (
    <div
      className="pb-5 card shadow-sm p-10"
      style={{ width: "480px" }}
      data-kt-stepper-element="content"
    >
      <div className="w-100">
        {data.appBasic.appType === "OPEN" && (
          <>
            {/*begin:Option */}
            <div>
              <div className="d-flex flex-row justify-content-around text-nowrap">
                <label className="d-flex align-items-center justify-content-between  mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5 ">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">Scheduled</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one aspect
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="Scheduled"
                        checked={data.appBasi.appType === "SCHEDULED"}
                        onChange={() => {
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "SCHEDULED",
                            },
                            appPercentage: {
                              percentage: "",
                            },
                          });
                          setHasError(false);
                        }}
                      />
                    </span>
                  </div>
                </label>
                <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5 ms-5">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">On Credit</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one SEO
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="On Credit"
                        checked={data.appBasi.appType === "ON_CREDIT"}
                        onChange={() => {
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "ON_CREDIT",
                            },
                            appFrequency: {
                              frequency: "",
                            },
                            appAmount: {
                              amount: "",
                            },
                            
                          });
                          setHasError(false);
                        }}
                      />
                    </span>
                  </div>
                </label>
                {/*end::Option */}

                {/*begin:Option */}

                <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5 ms-5">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">Do it later</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one aspect
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="later"
                        checked={data.appBasi.appType === "LATER"}
                        onChange={() => {
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "LATER",
                            },
                            appFrequency: {
                              frequency: "",
                            },
                            appAmount: {
                              amount: "",
                            },
                            appPercentage: {
                              percentage: "",
                            },
                          });
                          setHasError(false);
                        }}
                      />
                    </span>
                  </div>
                </label>
              </div>
              {/*end::Option */}
              {data.appBasi.appType === "ON_CREDIT" && (
                <>
                  <div className=" mt-10 d-flex align-items-center">
                    <label className="form-label w-50 m-0">
                      Please enter Percentage
                    </label>
                    <input
                      type="text"
                      className="form-control text-end w-50"
                      style={{ paddingRight: "30px" }}
                      value={data.appPercentage.percentage}
                      onChange={(e) => handlePercentageChange(e)}
                    />
                    <span
                      className="position-absolute"
                      style={{ marginLeft: "390px" }}
                    >
                      %
                    </span>
                  </div>
                  {!data.appPercentage.percentage && hasError && (
                    <div className="fv-plugins-message-container text-end">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Percentage is required
                      </div>
                    </div>
                  )}
                </>
              )}
              {data.appBasi.appType === "SCHEDULED" && (
                <>
                  <div className="mb-10 mt-10">
                    <div className="d-flex flex-column mb-5 mt-5">
                      <label className="form-label">Set Frequency</label>

                      <select
                        className="form-select"
                        data-control="select2"
                        data-placeholder="Select an option"
                        value={data.appFrequency.frequency}
                        onChange={(e) =>
                          updateData({
                            appFrequency: {
                              frequency: e.target.value,
                            },
                          })
                        }
                      >
                        <option>Select</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                      {!data.appFrequency.frequency && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Frequency is required
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Please enter Amount</label>
                      <span
                        className="fs-6 fw-bold text-gray-700"
                        style={{
                          position: "absolute",
                          padding: "12px",
                          right: "390px",
                          marginTop: "27px",
                        }}
                      >
                        {baseCurrency}
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        style={{ paddingLeft: "60px" }}
                        value={data.appAmount.amount}
                        onChange={(e) =>{
                          if (e.target.value === "0" || e.target.value === "") {
                            updateData({
                              appAmount: {
                                amount: "",
                              },
                            })
                            return;
                          };
                          updateData({
                            appAmount: {
                              amount: handleAmountChange(e),
                            },
                          })
                        }
                        }
                      />
                      {!data.appAmount.amount && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Amount is required
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {data.appBasic.appType === "SET_LIMIT" && (
          <>
            <div>
              {/*begin:Option */}
              <div className="d-flex flex-row justify-content-around text-nowrap">
                <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">Scheduled</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one aspect
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="Scheduled"
                        checked={data.appBasi.appType === "SCHEDULED"}
                        onChange={() =>
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "SCHEDULED",
                            },
                            appPercentage: {
                              percentage: "",
                            },
                          })
                        }
                      />
                    </span>
                  </div>
                </label>
                <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">On Credit</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one SEO
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="On Credit"
                        checked={data.appBasi.appType === "ON_CREDIT"}
                        onChange={() =>
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "ON_CREDIT",
                            },
                            appFrequency: {
                              frequency: "",
                            },
                            appAmount: {
                              amount: "",
                            },
                          })
                        }
                      />
                    </span>
                  </div>
                </label>
                {/*end::Option */}

                {/*begin:Option */}

                {/*end::Option */}
                <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                  <div className="card shadow-sm d-flex flex-row justify-content-between w-100 p-5">
                    <span className="d-flex align-items-center me-2">
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">Do it later</span>
                        {/* <span className='fs-7 text-muted'>
                    Creating a clear text structure is just one aspect
                  </span> */}
                      </span>
                    </span>

                    <span className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value="later"
                        checked={data.appBasi.appType === "LATER"}
                        onChange={() =>
                          updateData({
                            appBasi: {
                              appName: data.appBasi.appName,
                              appType: "LATER",
                            },
                            appFrequency: {
                              frequency: "",
                            },
                            appAmount: {
                              amount: "",
                            },
                            appPercentage: {
                              percentage: "",
                            },
                          })
                        }
                      />
                    </span>
                  </div>
                </label>
              </div>
              {data.appBasi.appType === "ON_CREDIT" && (
                <>
                 <div className=" mt-10 d-flex align-items-center">
                    <label className="form-label w-50 m-0">
                      Please enter Percentage
                    </label>
                    <input
                      type="text"
                      className="form-control text-end w-50"
                      style={{ paddingRight: "30px" }}
                      value={data.appPercentage.percentage}
                      onChange={(e) => handlePercentageChange(e)}
                    />
                    <span
                      className="position-absolute"
                      style={{ marginLeft: "390px" }}
                    >
                      %
                    </span>
                  </div>
                </>
              )}
              {data.appBasi.appType === "SCHEDULED" && (
                <>
                  <div className="mb-10 mt-10">
                    <div className="d-flex flex-column mb-5 mt-5">
                      <label className="form-label">Set Frequency</label>

                      <select
                        className="form-select"
                        data-control="select2"
                        data-placeholder="Select an option"
                        value={data.appFrequency.frequency}
                        onChange={(e) =>
                          updateData({
                            appFrequency: {
                              frequency: e.target.value,
                            },
                          })
                        }
                      >
                        <option value="" selected>
                          Select
                        </option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                      {!data.appFrequency.frequency && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Frequency is required
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Please enter Amount</label>
                      <span
                        className="fs-6 fw-bold text-gray-700"
                        style={{
                          position: "absolute",
                          padding: "12px",
                          right: "390px",
                          marginTop: "27px",
                        }}
                      >
                        {baseCurrency}
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        style={{ paddingLeft: "50px" }}
                        value={data.appAmount.amount}
                        onChange={(e) =>{
                          if (e.target.value === "0" || e.target.value === "") {
                            updateData({
                              appAmount: {
                                amount: "",
                              },
                            })
                            return;
                          };
                          updateData({
                            appAmount: {
                              amount: handleAmountChange(e),
                            },
                          })
                        }
                        }
                      />
                      {!data.appAmount.amount && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Amount is required
                          </div>
                        </div>
                      )}
                      {+data.appAmount.amount > +data.appLimit.limit && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Amount should be less than goal limit
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
