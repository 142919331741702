import React, { useEffect, useRef, useState } from "react";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../_metronic/helpers";
import { ICreateAppData, defaultCreateAppData } from "./IAppModels";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface FileResponse {
  status: {
    statusCode: number;
    messageDescription: string;
  };
  message: {
    fileData: any;
    fileName: string;
  };
}

const Vertical = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  const [skip, setSkip] = useState(true);
  const [reset, setReset] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkAppBasic1 = (): boolean => {
    if (!data.stmntDuration.stmntDuration) {
      if (!data.fromDate.fromDate || !data.toDate.toDate) return false;
      if (data.fromDate.fromDate > data.toDate.toDate) {
        return false;
      } else if (data.toDate.toDate > dayjs(new Date()).format("YYYY-MM-DD")) {
        return false;
      } else return true;
    }
    return true;
  };

  const checkAppBasic2 = (): boolean => {
    if (!data.account.account) {
      return false;
    }
    return true;
  };

  const checkAppBasic4 = (): boolean => {
    if (!data.direction.direction) {
      return false;
    }
    return true;
  };

  const checkAppBasic5 = (): boolean => {
    if (data.txnType.txnType.length === 0) {
      return false;
    }
    return true;
  };

  const prevStep = () => {
    if (currentIndex === 1) {
    } else {
      setCurrentIndex((prev) => prev - 1);
      if (!stepper.current) {
        return;
      }
      stepper.current.goPrev();
    }
  };

  const nextStep = () => {
    console.log(currentIndex);

    if (currentIndex === 5) {
    } else {
      setHasError(false);
      if (!stepper.current) {
        return;
      }
      if (currentIndex === 1) {
        console.log(checkAppBasic1());
        if (!checkAppBasic1()) {
          setHasError(true);
          return;
        } else {
          setCurrentIndex((prev) => prev + 1);
          console.log(currentIndex);
          stepper.current.goNext();
        }
      }

      if (currentIndex === 2) {
        if (!checkAppBasic2()) {
          setHasError(true);
          return;
        } else {
          setCurrentIndex((prev) => prev + 1);
          console.log(currentIndex);
          stepper.current.goNext();
        }
      }
      if (currentIndex === 3) {
        setCurrentIndex((prev) => prev + 1);
        console.log(currentIndex);
        stepper.current.goNext();
      }

      if (currentIndex === 4) {
        if (!checkAppBasic4()) {
          setHasError(true);
          return;
        } else {
          setCurrentIndex((prev) => prev + 1);
          console.log(currentIndex);
          stepper.current.goNext();
        }
      }
    }
  };

  const downloadAccountStmnt = async (
    accountNumber: string,
    currency: string,
    stmntDuration: string,
    txnType: string[],
    fromDate: string,
    toDate: string,
    type: string
  ) => {
    const url = `${API_URL}/txnStmnt/findTxnDownload`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const searchCriteriaList = [
      {
        propertyName: "accountNumber",
        propertyValue: accountNumber,
      },
      {
        propertyName: "txnType",
        propertyValue: txnType,
      },
    ];

    if (stmntDuration) {
      searchCriteriaList.push({
        propertyName: "stmntDuration",
        propertyValue: stmntDuration,
      });
    }
    if (fromDate && toDate) {
      searchCriteriaList.push({
        propertyName: "fromDate",
        propertyValue: fromDate,
      });
      searchCriteriaList.push({
        propertyName: "toDate",
        propertyValue: toDate,
      });
    }
    if (type !== "all") {
      searchCriteriaList.push({
        propertyName: "type",
        propertyValue: type,
      });
    }

    const body = JSON.stringify({
      searchCriteriaList,
      pageNumber: 1,
      pageSize: 50,
      skipPagination: true,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    try {
      let response = await enhancedFetch(url, options);

      if (response.headers.get("content-type")?.includes("application/json")) {
        // JSON response
        let data: FileResponse = await response.json();

        if (data.status.statusCode === 1) {
          Swal.fire({
            icon: "error",
            title: "Download Failed",
            html: `${data?.status?.messageDescription}`,
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          // Handle the failure case (optional)
          return null; // or throw an error, depending on your use case
        }

        return data;
      } else {
        // Binary response (assumed to be a PDF in this case)
        const contentDisposition = response.headers.get("content-disposition");
        let filename = "statement.pdf";

        if (contentDisposition) {
          const parts = contentDisposition.split(".");
          const extension = parts[parts.length - 1];

          if (extension) {
            filename = `statement.${extension}`;
          }
        }

        const blob = await response.blob();
        saveAs(blob, filename);

        Swal.fire({
          icon: "success",
          title: "Download Successful",
          html: `File <strong>${filename}</strong> has been saved successfully.`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });

        return null; // or return additional information about the download
      }
    } catch (error) {
      // Handle network or other errors
      console.log(error);
      throw error;
    }
  };

  const submit = () => {
    setHasError(false);

    if (!stepper.current) {
      return;
    }

    if (currentIndex === 5) {
      if (!checkAppBasic5()) {
        setHasError(true);
        return;
      }
    }

    if (data.txnType.txnType.length > 0 && !hasError) {
      downloadAccountStmnt(
        data.account.account,
        data.currency.currency,
        data.stmntDuration.stmntDuration,
        data.txnType.txnType,
        data.fromDate.fromDate,
        data.toDate.toDate,
        data.direction.direction
      );
      handleReset();
      stepper.current.goto(1);
    }
  };

  const handleReset = () => {
    setData(defaultCreateAppData);
    setCurrentIndex(1);
    setReset(!reset);
  };

  return (
    <>
      <h4 className="mb-4">Statements</h4>
      <div className="py-2 card p-5">
        <div
          ref={stepperRef}
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_create_account_stepper"
        >
          <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 border border-dashed border-dark my-2">
            <div className="card-body px-6 px-lg-10 px-xxl-15 py-10">
              <div className="stepper-nav">
                {/* begin::Step 1*/}
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose Period</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>

                {/* begin::Step 2*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose Account</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>

                {/* begin::Step 3*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose Card</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>

                {/* begin::Step 4*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">4</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">
                        Choose Transaction Direction
                      </h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>

                {/* begin::Step 5*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">5</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose Type</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row-fluid flex-center bg-body rounded border border-dashed border-dark my-2">
            <form
              className="py-10 w-100 w-xl-700px px-9"
              noValidate
              id="kt_create_account_form"
            >
              <div className="current" data-kt-stepper-element="content">
                <Step1
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  reset={reset}
                  setSkip={setSkip}
                />
              </div>

              <div data-kt-stepper-element="content">
                <Step2
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  reset={reset}
                  setSkip={setSkip}
                />
              </div>

              <div data-kt-stepper-element="content">
                <Step3
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  reset={reset}
                  setSkip={setSkip}
                />
              </div>

              <div data-kt-stepper-element="content">
                <Step4
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  reset={reset}
                  setSkip={setSkip}
                />
              </div>

              <div data-kt-stepper-element="content">
                <Step5
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  reset={reset}
                  setSkip={setSkip}
                />
              </div>

              <div className="d-flex flex-stack pt-5">
                <div className="mr-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg me-3"
                    data-kt-stepper-action="previous"
                  >
                    <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                    Back
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="submit"
                    onClick={submit}
                  >
                    Create Statement{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="next"
                    onClick={nextStep}
                  >
                    Next{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vertical;
