import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateSubAccountModal } from "./SubAccountStepper/SubAccountModal";
import { useLocation } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Authorize from "../../modules/auth/components/Authorize";

interface Account {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  createdDate: string;
  balance: string;
}

interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  accountType: string;
  primaryFundId: string;
  subAccounts: [
    {
      currency: string;
      iconCode: string;
      goal: string;
      goalLimit: number;
      creditCondition: string;
      creditType: string;
      creditValue: number;
      creditFrequency: string;
      subAccountType: string;
      subAccountName: string;
      subAccountNumber: string;
      subAccountStatus: number;
      balance: number;
      createdDate: string;
    }
  ];
}

export const Api = {
  api: (): void => {},
};

export default function SubAccountListing() {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accdetails, setAccDetails] = useState<accDetails>();

  const navigate = useNavigate();
  const location = useLocation();
  const passedData = (
    location.state as { data: accDetails; AccNumber: Account }
  )?.data;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const emojiList: Record<string, string> = {
    ic_book: toAbsoluteUrl("/media/subicons/Bill_Payments.svg"),
    ic_car: toAbsoluteUrl("/media/subicons/Business_Expenses.svg"),
    ic_cellphone: toAbsoluteUrl("/media/subicons/Contingency.svg"),
    ic_chair: toAbsoluteUrl("/media/subicons/Cash_Backup.svg"),
    ic_coffee: toAbsoluteUrl("/media/subicons/Gifting.svg"),
    ic_coke: toAbsoluteUrl("/media/subicons/Healthcare.svg"),
    ic_education: toAbsoluteUrl("/media/subicons/Office_Hardware.svg"),
    ic_fries: toAbsoluteUrl("/media/subicons/Office_Outings.svg"),
    ic_gift: toAbsoluteUrl("/media/subicons/Packaging & Logistics.svg"),
    ic_shopping: toAbsoluteUrl("/media/subicons/Payroll.svg"),
    ic_tree: toAbsoluteUrl("/media/subicons/Sales & Marketing.svg"),
    ic_software: toAbsoluteUrl("/media/subicons/Software_subscriptions.svg"),
  };

  const SubAccounts = async () => {
    try {
      const url = `${API_URL}/subAccount/getSubAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data?.message?.subAccounts);
      setAccDetails(data?.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Api.api = SubAccounts;
    SubAccounts();
  }, [passedData]);

  return (
    <>
      <style>
        {`
    #shadow-hover {
      // transition: box-shadow 0.3s;
      box-shadow: 4px 4px 10px #d1d1d1;
    }

    #shadow-hover:hover {
      box-shadow: 2px 2px 5px #000;
    }
    #glow-on-hover {
        padding:10px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }

    

    #glow-on-hover:hover {
      transform: translateY(-5px) scale(1.005) translateZ(0);
      box-shadow: 0 24px 36px rgba(0,0,0,0.11),
        0 24px 46px var(--box-shadow-color);
        // backgroundColor: #0000ff
        // border-color: var(--bg-color-light); 
        background: #0000ff;
    }
    
 

    
    // #glow-on-hover:active {
    //   transform: scale(1) translateZ(0);
    //   box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    //     0 15px 24px var(--box-shadow-color);
    // }
    
    #glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        left: 0;
        top: 0;
        border-radius: 10px;
    }
    
  
    .btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
    position: absolute;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: #fff;
    color: #777;
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.btn-white::after {
    background-color: #000;
}



.btn-animated {
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
/* Define the colors */
:root {
  --color: #19bc8b;
  --hover: #246BFD;
}

/* Define the box shadow styles */
.offset {
  box-shadow:
    0.6em 0.6em 0.3em 0 var(--color),
    inset 0.3em 0.3em 0 0 var(--color);
}

.offset:hover,
.offset:focus {
  box-shadow:
    0 0 0 0 var(--hover),
    inset 30em 3.5em 0 0 var(--hover);
}

  `}
      </style>
      <h4 className="mb-5">Sub Account Summary</h4>
      <div className="card p-5">
        <Authorize hasAnyPermission={["MENU_SUB_ACCOUNTS|ADD"]}>
          <div className="d-flex flex-row justify-content-end align-items-center">
            <button className="btn" onClick={() => setShowCreateAppModal(true)}>
              Create Sub Account
            </button>
          </div>
        </Authorize>
        <CreateSubAccountModal
          subData={accdetails}
          passed={passedData}
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
        />

        <div className="container">
          <div className="row">
            {accounts?.length > 0 ? (
              accounts.map((account, key) => (
                <div className="col-md-4 p-10" key={key}>
                  <div
                    className="card shadow bg-gray-200 px-10 py-5"
                    style={{
                      height: "180px",
                    }}
                    id="glow-on-hover"
                    onClick={() =>
                      navigate("/subaccounts", {
                        state: { subData: accdetails, AccNumber: account },
                      })
                    }
                  >
                    <div className="d-flex flex-column mb-2">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row  align-items-center gap-5">
                          <div className="mb-3">
                            {account.iconCode &&
                            emojiList.hasOwnProperty(account.iconCode) ? (
                              <img
                                src={emojiList[account.iconCode]}
                                alt={account.iconCode}
                                style={{ width: "30px", height: "30px" }}
                              />
                            ) : (
                              <div className="fs-2">{account?.iconCode}</div>
                            )}
                          </div>

                          <h4 className="text-nowrap">
                            {account.subAccountName}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="d-flex flex-row text-nowrap">
                          <div>
                            {" "}
                            <h4 style={{ color: "#246BFD" }}>
                              {account.currency === "USD" ? "$" : "ZAR"}{" "}
                              {Number(account.balance)?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div>
                          <p className="text-gray-600">
                            {account.goal === "OPEN"
                              ? "Goal- Open"
                              : `Goal - ${
                                  account.currency === "USD" ? "$" : "ZAR"
                                } ${account.goalLimit?.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                          </p>
                        </div>
                      </div>
                    </div>
                    {account.goal !== "OPEN" && (
                      <div>
                        <div
                          className="progress fw-bold mt-5"
                          style={{ height: "20px" }}
                        >
                          <div
                            className="progress-bar "
                            role="progressbar"
                            style={{
                              width: `${parseFloat(
                                Math.min(
                                  isNaN(Number(account.balance)) ||
                                    isNaN(account.goalLimit) ||
                                    account.goalLimit < 1
                                    ? 100
                                    : (Number(account.balance) /
                                        Math.max(account.goalLimit, 1)) *
                                        100,
                                  100
                                ).toFixed(0)
                              )}%`,

                              backgroundColor: "#0000ff",
                            }}
                            aria-valuenow={parseFloat(
                              Math.min(
                                isNaN(Number(account.balance)) ||
                                  isNaN(Number(account.goalLimit)) ||
                                  account.goalLimit < 1
                                  ? 100
                                  : (Number(account.balance) /
                                      Math.max(account.goalLimit, 1)) *
                                      100,
                                100
                              ).toFixed(0)
                            )}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {`${parseFloat(
                              Math.min(
                                isNaN(Number(account.balance)) ||
                                  isNaN(account.goalLimit) ||
                                  account.goalLimit < 1
                                  ? 100
                                  : (Number(account.balance) /
                                      Math.max(account.goalLimit, 1)) *
                                      100,
                                100
                              ).toFixed(0)
                            )}%`}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No Subaccounts found</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
