import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  ChangeEmailFormValues,
  ChangePasswordFormValues,
  ChangePhoneNumFormValues,
  ChangePinFormValues,
} from "./Interface";
import { updateEmail, updatePassword, updatePhoneNum, updatePin } from "./Api";
import { UpdateSuccessModal } from "./SuccessModal";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import { PhoneInput } from "react-international-phone";

const Security = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [name, setName] = useState("");
  const validationPasswordSchema = Yup.object().shape({
    // currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    reenterNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("New password is required"),
  });

  const validationEmailSchema = Yup.object({
    // currentEmailID: Yup.string()
    //   .email("Invalid email format")
    //   .required("Current email is required"),

    newEmailID: Yup.string()
      .email("Invalid email format")
      .required("New email is required"),

    reenterNewEmailID: Yup.string()
      .email("Invalid email format")
      .oneOf([Yup.ref("newEmailID")], "Emails must match")
      .required("New email is required"),
  });

  const validationPinSchema = Yup.object().shape({
    // currentPin: Yup.string().required("Current MPIN is required"),
    newPin: Yup.string()
      .min(4, "PIN must be at least 4 characters")
      .required("New MPIN is required"),
    reenterNewPin: Yup.string()
      .oneOf([Yup.ref("newPin")], "MPIN must match")
      .required("New MPIN is required"),
  });

  const validationPhoneNumSchema = Yup.object().shape({
    // currentPhoneNum: Yup.string().required("Current password is required"),
    newPhoneNum: Yup.string()
      .min(6, "Phone number must be at least 9 characters")
      .required("New Phone Number is required"),
    reenterNewPhoneNum: Yup.string()
      .oneOf([Yup.ref("newPhoneNum")], "Phone Number must match")
      .required("New Phone Number is required"),
  });

  const initialEmailValues: ChangeEmailFormValues = {
    // currentEmailID: "",
    newEmailID: "",
    reenterNewEmailID: "",
  };

  const initialPhoneNumValues: ChangePhoneNumFormValues = {
    // currentPhoneNum: "",
    newPhoneNum: "",
    reenterNewPhoneNum: "",
  };

  const initialPasswordValues: ChangePasswordFormValues = {
    // currentPassword: "",
    newPassword: "",
    reenterNewPassword: "",
  };

  const initialPinValues: ChangePinFormValues = {
    // currentPin: "",
    newPin: "",
    reenterNewPin: "",
  };

  const onEmailSubmit = (
    values: ChangeEmailFormValues,
    { resetForm }: FormikHelpers<ChangeEmailFormValues>
  ) => {
    updateEmail(API_URL, token, values.newEmailID).then((response) => {
      console.log(response);
      if (response.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Email has been updated and is ready to use",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else if (response.statusCode === 1) {
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: response.messageDescription,

        });
      }
    });
    resetForm();
  };

  const onPhoneNumSubmit = (
    values: ChangePhoneNumFormValues,
    { resetForm }: FormikHelpers<ChangePhoneNumFormValues>
  ) => {
    updatePhoneNum(
      API_URL,
      token,
      // values.currentPhoneNum,
      values.newPhoneNum
    ).then((response) => {
      if (response.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Phone Number has been updated and is ready to use",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else if (response.statusCode === 1) {
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: response.messageDescription,

        });
      }
    });
    resetForm();
  };

  const onPasswordSubmit = (
    values: ChangePasswordFormValues,
    { resetForm }: FormikHelpers<ChangePasswordFormValues>
  ) => {
    updatePassword(
      API_URL,
      token,
      // values.currentPassword,
      values.newPassword
    ).then((response) => {
      if (response.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Password has been updated and is ready to use",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }

    });
    resetForm();
  };

  const onPinSubmit = (
    values: ChangePinFormValues,
    { resetForm }: FormikHelpers<ChangePinFormValues>
  ) => {
    updatePin(
      API_URL,
      token,
      //  values.currentPin,
      values.newPin
    ).then((response) => {
      if (response.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Pin has been updated and is ready to use",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    });
    resetForm();
  };

  return (
    <>
      <h4 className="mb-5">Security</h4>
      <div
        className={`d-flex gap-3 ${window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
          }`}
      >
        <div className="card p-5 d-flex flex-column flex-grow-1">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className="nav-link text-active-gray-800 active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                >
                  Change Registered Email ID
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                >
                  Change Registered Mobile Number
                </a>
              </li>
              <li className="nav-item text-active-gray-800" key={3}>
                <a
                  className="nav-link text-nowrap"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                >
                  Change Password
                </a>
              </li>
              <li className="nav-item text-active-gray-800" key={4}>
                <a
                  className="nav-link text-nowrap"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                >
                  Change PIN
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content " id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <Formik
                initialValues={initialEmailValues}
                validationSchema={validationEmailSchema}
                onSubmit={onEmailSubmit}
              >
                {() => (
                  <Form>
                    {/* <div className="d-flex flex-row m-5 align-items-center">
                    <label className="form-label w-25 m-0">
                      Current Email ID
                    </label>
                    <div className="d-flex flex-column w-50">
                      <Field
                        type="email"
                        name="currentEmailID"
                        className="form-control w-100"
                        placeholder="Enter Current Email ID"
                        style={{ padding: "6.5px" }}
                      />

                      <ErrorMessage
                        name="currentEmailID"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div> */}

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">
                        New Email ID
                      </label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="text"
                          name="newEmailID"
                          className="form-control w-100"
                          placeholder="Enter New Email ID"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="newEmailID"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">
                        Re-enter New Email ID
                      </label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="text"
                          name="reenterNewEmailID"
                          className="form-control w-100"
                          placeholder="Re-enter New Email ID"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="reenterNewEmailID"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "230px",
                          color: "#ffff",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <Formik
                initialValues={initialPhoneNumValues}
                validationSchema={validationPhoneNumSchema}
                onSubmit={onPhoneNumSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    {/* <div className="d-flex flex-row m-5 align-items-center">
          <label className="form-label w-25 m-0">
            Current Phone Number
          </label>
          <div className="d-flex flex-column w-50">
            <Field
              type="text"
              name="currentPhoneNum"
              className="form-control w-100"
              placeholder="Enter Current Phone Number"
              style={{ padding: "6.5px" }}
            />
            <ErrorMessage
              name="currentPhoneNum"
              className="text-danger"
              component="div"
            />
          </div>
        </div> */}

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">New Phone Number</label>
                      <div className="d-flex flex-column w-50">
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            height: "43.59px",
                          }}
                          countrySelectorStyleProps={{
                            buttonStyle: {
                              height: "43.59px",
                            },
                          }}
                          forceDialCode={true}
                          value={values.newPhoneNum}
                          onChange={(value) => setFieldValue("newPhoneNum", value)}
                          defaultCountry="in"
                        />
                        {errors.newPhoneNum && touched.newPhoneNum && (
                          <div className="text-danger">{errors.newPhoneNum}</div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">Re-enter New Phone Number</label>
                      <div className="d-flex flex-column w-50">
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            height: "43.59px",
                          }}
                          countrySelectorStyleProps={{
                            buttonStyle: {
                              height: "43.59px",
                            },
                          }}
                          forceDialCode={true}
                          value={values.reenterNewPhoneNum}
                          onChange={(value) => setFieldValue("reenterNewPhoneNum", value)}
                          defaultCountry="in"
                        />
                        {errors.reenterNewPhoneNum && touched.reenterNewPhoneNum && (
                          <div className="text-danger">{errors.reenterNewPhoneNum}</div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "230px",
                          color: "#ffff",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <Formik
                initialValues={initialPasswordValues}
                validationSchema={validationPasswordSchema}
                onSubmit={onPasswordSubmit}
              >
                {() => (
                  <Form>
                    {/* <div className="d-flex flex-row m-5 align-items-center">
                    <label className="form-label w-25 m-0">
                      Current Password
                    </label>
                    <div className="d-flex flex-column w-50">
                      <Field
                        type="password"
                        name="currentPassword"
                        className="form-control w-100"
                        placeholder="Enter Current Password"
                        style={{ padding: "6.5px" }}
                      />
                      <ErrorMessage
                        name="currentPassword"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div> */}

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">
                        New Password
                      </label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="password"
                          name="newPassword"
                          className="form-control w-100"
                          placeholder="Enter New Password"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="newPassword"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">
                        Re-enter New Password
                      </label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="password"
                          name="reenterNewPassword"
                          className="form-control w-100"
                          placeholder="Re-enter New Password"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="reenterNewPassword"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "230px",
                          color: "#ffff",
                        }}
                      // onClick={handleTransferModalClose}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
              <Formik
                initialValues={initialPinValues}
                validationSchema={validationPinSchema}
                onSubmit={onPinSubmit}
              >
                {() => (
                  <Form>
                    {/* <div className="d-flex flex-row m-5 align-items-center">
                    <label className="form-label w-25 m-0">Current MPIN</label>
                    <div className="d-flex flex-column w-50">
                      <Field
                        type="password"
                        name="currentPin"
                        className="form-control w-100"
                        placeholder="Enter current MPIN"
                        style={{ padding: "6.5px" }}
                      />
                      <ErrorMessage
                        name="currentPin"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div> */}

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">New MPIN</label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="password"
                          name="newPin"
                          className="form-control w-100"
                          placeholder="New MPIN"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="newPin"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row m-5 align-items-center">
                      <label className="form-label w-25 m-0">
                        Re-enter New MPIN
                      </label>
                      <div className="d-flex flex-column w-50">
                        <Field
                          type="password"
                          name="reenterNewPin"
                          className="form-control w-100"
                          placeholder="Re-enter New MPIN"
                          style={{ padding: "6.5px" }}
                        />
                        <ErrorMessage
                          name="reenterNewPin"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "230px",
                          color: "#ffff",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Security;
