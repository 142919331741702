import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ApiResponse, CsvData, QRCodeModel, ViewQR } from "./QRInterface";

export const getQrCodes = async (
  address: string | undefined,
  token: string
): Promise<ViewQR[]> => {
  try {
    const url = `${address}/payment/viewQRCodes`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message?.qrCodeModels as ViewQR[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getQRCode = async (
  address: string | undefined,
  token: string,
  qrCodeModel: QRCodeModel[]
): Promise<ApiResponse> => {
  try {
    const url = `${address}/payment/getQRCode`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({ qrCodeModels: qrCodeModel });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);

    let data = await response.json();

    return data as ApiResponse;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadFileForPayment = async (
  address: string | undefined,
  token: string
): Promise<ViewQR[]> => {
  try {
    const url = `${address}/payment/uploadFileForPayment`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      ...CsvData,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message?.qrCodeModels as ViewQR[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getQRCodePayments = async (
  address: string | undefined,
  token: string,
  qrCodeid: string
): Promise<ViewQR[]> => {
  try {
    const url = `${address}/payment/getQRCodePayments`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({ qrCodeModels: [{ qrCodeid: qrCodeid }] });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message?.qrCodeModels as ViewQR[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const closeQR = async (
  address: string | undefined,
  token: string,
  qrCodeid: string
) => {
  try {
    const url = `${address}/payment/closeQRCode`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({ qrCodeModels: [{ qrCodeid }] });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
