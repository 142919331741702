/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { Languages } from "./Languages";
import { toAbsoluteUrl } from "../../../helpers";
import { getUserDetails } from "../../../../app/pages/profile/Api";
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../../app/modules/auth/core/_requests";

interface ContactDetails {
  contactEmail: string;
  contactPhone: string;
  contactWhatsapp: string;
}

const HeaderUserMenu = ({ imgURL }: { imgURL: string }) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const { currentUser, logout, setToken } = useAuth();
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const handleOnIdle = (event?: Event, idleTimer?: IIdleTimer) => {
    deRegister();
    signOut();
    logout();
    setTimeout(()=>{
      sessionStorage.removeItem("kt-auth-react-v");
      sessionStorage.removeItem("menus");
      sessionStorage.removeItem("profile")
      setToken(undefined);
    },300)
    
    Swal.fire({
      icon: "warning",
      title: "",
      text: "Session expired. You have been logged out.",
      confirmButtonColor: "#007bff",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
   }
 
   const handleOnActive = (event?: Event, idleTimer?: IIdleTimer) => {
     // console.log('user is active', event)
     // console.log('time remaining', getRemainingTime())
   }
 
   const handleOnAction =(event?: Event, idleTimer?: IIdleTimer) => {
     // console.log('user did something', event)
   }
 
   const { getRemainingTime, getLastActiveTime } = useIdleTimer({
     timeout: 1000 * 60 * 30,
     onIdle: handleOnIdle,
     onActive: handleOnActive,
     onAction: handleOnAction,
     debounce: 500
   })
 

  const deRegister = async () => {
    try {
      const url = `${API_URL}/deRegisterDevice`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const url = `${API_URL}/logout`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };



  const [contactDetails, setContactDetails] = useState({
    contactEmail: "",
    contactPhone: "",
    contactWhatsapp: "",
  });

  const getContactDetails = async (): Promise<ContactDetails> => {
    try {
      const url = `${API_URL}/findTenantData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };
      let response = await enhancedFetch(url, options);
      let data = await response?.json();

      return data.message as ContactDetails;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getContactDetails();

      setContactDetails(data);
    };
    fetchData();
  }, []);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={imgURL} />
          </div>

          {/* <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div> */}
        </div>
      </div>

      <div className="separator my-2"></div>

      {/* <div className="menu-item px-5">
        <Link to={"/editProfile"} className="menu-link px-5">
          Edit Profile
        </Link>
      </div> */}

      <Languages />

      <div className="menu-item px-5">
        <Link to={"/security"} className="menu-link px-5">
          Security
        </Link>
      </div>

      <div className="menu-item px-5 my-1">
        <a
          href="https://www.xfin.digital/privacy-policy"
          className="menu-link px-5"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>

      <div className="menu-item px-5 my-1">
        <Link to={"/subscription"} className="menu-link px-5">
          Subscription
        </Link>
      </div>

      <div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">Contact Us</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-220px py-4">
          <div className="menu-item px-3">
            <a className="menu-link px-5">{contactDetails?.contactEmail}</a>
          </div>

          <div className="menu-item px-3">
            <a className="menu-link px-5">{contactDetails?.contactPhone}</a>
          </div>

          <div className="menu-item px-3">
            <a className="menu-link px-5">
              {contactDetails?.contactWhatsapp}(WhatsApp)
            </a>
          </div>
        </div>
      </div>

      <div className="menu-item px-5">
        <a
          onClick={() => {
            deRegister();
            signOut();
            logout();
            setTimeout(()=>{
              sessionStorage.removeItem("kt-auth-react-v");
              setToken(undefined);
            },300)
            
          }}
          className="menu-link px-5"
        >
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };