export interface IAppBasic {
  category: string;
}
export interface IAppAmount {
  amount: string;
}

export interface IAppCustomer {
  customer: string;
}

export interface IAppPhone {
  phone: string;
}

export interface IAppEmail {
  email: string;
}

export interface IAppFileData {
  fileData: string;
}

export interface IAppDescription {
  description: string;
}

export interface IAppDate {
  date: string;
}
export interface IAppReminder {
  reminder: string;
}
export interface IAppReminderDays {
  days: string;
}
export interface IAppSubmit {
  submit: () => void;
}

export interface ICreateAppData {
  category: IAppBasic;
  amount: IAppAmount;
  customer: IAppCustomer;
  phone: IAppPhone;
  email: IAppEmail;
  fileData: IAppFileData;
  description: IAppDescription;
  date: IAppDate;
  reminder: IAppReminder;
  days: IAppReminderDays;
  submit: IAppSubmit;
}

export const defaultCreateAppData: ICreateAppData = {
  category: { category: "Manual" },
  amount: { amount: "" },
  customer: { customer: "" },
  phone: { phone: "" },
  email: { email: "" },
  description: { description: "" },
  date: { date: "" },
  fileData: { fileData: "" },
  reminder: { reminder: "No" },
  days: { days: "" },
  submit: { submit: () => {} },
};

export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
  txnPin: string;
  setTxnPin: (txnPin: string) => void;
};
