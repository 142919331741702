/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StepProps } from "../IAppModels";

const Step1 = ({ data, updateData, hasError, reset }: StepProps) => {
  const [selectedRadio, setSelectedRadio] = useState("0");
  const [dateRange, setDateRange] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    setSelectedRadio("0");
    setDateRange(false);
    setFromDate("");
    setToDate("");
    setValidationError(null);
  }, [reset]);

  const handleRadioChange = (radio: string) => {
    setSelectedRadio(radio);
    if (radio !== "7") {
      setValidationError("");
      setFromDate("");
      setToDate("");
    }
  };
  const handleFromDateChange = (date: string | Date | null | undefined) => {
    let formattedDate = "";
    if (date) {
      formattedDate = dayjs(date).format("YYYY-MM-DD");
      setFromDate(formattedDate);
      updateData({
        fromDate: {
          fromDate: formattedDate,
        },
      });
    } else {
      setFromDate("");
    }

    // If toDate is already set, validate the dates
    if (toDate) {
      validateDates(formattedDate, toDate);
    }
  };

  const handleToDateChange = (date: string | Date | null | undefined) => {
    let formattedDate = "";
    if (date) {
      formattedDate = dayjs(date).format("YYYY-MM-DD");
      setToDate(formattedDate);
      updateData({
        toDate: {
          toDate: formattedDate,
        },
      });
    } else {
      setToDate("");
    }

    // If fromDate is already set, validate the dates
    if (fromDate) {
      validateDates(fromDate, formattedDate);
    }
  };

  const validateDates = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
      setValidationError("From date and End date should not be empty.");
      return false;
    } else if (startDate > endDate) {
      setValidationError("From date should not be greater than End date.");
      return false;
    } else if (endDate > dayjs(new Date()).format("YYYY-MM-DD")) {
      setValidationError("End date should not be greater than Today.");
      return false;
    }

    setValidationError(null);
    return true;
  };

  const options = [
    "7 Days",
    "15 Days",
    "30 Days",
    "1 Month",
    "3 Month",
    "6 Month",
    "Custom Date Range",
  ];
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder d-flex align-items-center text-dark mb-10">
          Create Custom Statement
        </h2>

        <div className="mb-0 fv-row">
          <div className="d-flex flex-column mb-0 px-20 justify-content-center w-100">
            <>
              <div
                className="d-flex flex-column w-100 p-5"
                style={{ height: "330px", overflowY: "auto" }}
              >
                <div className="d-flex flex-column gap-5 ">
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio1">
                      7 Days
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("1");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "7",
                          },
                        });
                      }}
                      checked={selectedRadio === "1"}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio2">
                      15 Days
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("2");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "15",
                          },
                        });
                      }}
                      checked={selectedRadio === "2"}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio4">
                      1 Month
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("3");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "30",
                          },
                        });
                      }}
                      checked={selectedRadio === "3"}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio5">
                      3 Month
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("4");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "90",
                          },
                        });
                      }}
                      checked={selectedRadio === "4"}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio6">
                      6 Month
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("5");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "180",
                          },
                        });
                      }}
                      checked={selectedRadio === "5"}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                    <label className="fw-bold" htmlFor="Radio5">
                      1 year
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(false);
                        handleRadioChange("6");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "365",
                          },
                        });
                      }}
                      checked={selectedRadio === "6"}
                    />
                  </div>
                  <div
                    className={`form-check d-flex justify-content-between ${
                      dateRange ? "mb-0" : "mb-2"
                    } p-4 rounded shadow-sm`}
                  >
                    <label className="fw-bold" htmlFor="Radio7">
                      Custom Date Range
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      onChange={() => {
                        setDateRange(true);
                        handleRadioChange("7");
                        updateData({
                          stmntDuration: {
                            stmntDuration: "",
                          },
                        });
                      }}
                      checked={selectedRadio === "7"}
                    />
                  </div>

                  {dateRange && (
                    <div className="d-flex flex-column">
                      {/* <li className='list-group-item'> */}
                      <div className="d-flex flex-row gap-5 w-100">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Start Date"
                              slotProps={{
                                textField: {
                                  className: "mb-2",
                                  size: "small",
                                  style: {
                                    width: "25vw",
                                  },
                                },
                              }}
                              // value={dayjs(fromDate)}
                              disableFuture
                              onChange={(date: any) => {
                                handleFromDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  style: {
                                    width: "25vw",
                                  },
                                },
                              }}
                              disableFuture
                              onChange={(date: any) => {
                                handleToDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      {/* </li> */}
                    </div>
                  )}
                </div>
              </div>
            </>
            {validationError && selectedRadio === "7" && (
              <p className="text-danger ms-4">{validationError}</p>
            )}
            {!data.stmntDuration.stmntDuration &&
              !data.fromDate.fromDate &&
              !data.toDate.toDate &&
              hasError && (
                <p className="text-danger ms-6 mt-2">
                  Select statement duration
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
