import ForgotImg from "../../../../_metronic/assets/Icons/forgot.jpeg";
import ChatImg from "../../../../_metronic/assets/Icons/Chat.png";
import MessageImg from "../../../../_metronic/assets/Icons/Message.png";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OtpResponse, PasswordResponse } from "../../../interface/Interface";
import { enhancedFetch } from "../core/_requests";

export function AuthSelect() {
  const [selectedOption, setSelectedOption] = useState<string | null>("sms");
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const generateOtp = async (
    mobile: string,
    channel: string,
    otpType: string,
    appId: string,
    isdCode?: number
  ): Promise<OtpResponse> => {
    try {
      const url = `${API_URL}/generateOtp`;
      const headers = {
        "Content-Type": "application/json",
        tenant_id: "eazy_bank",
        appId: appId,
      };

      const body = JSON.stringify({ mobile, isdCode, channel, otpType });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      return data?.message as OtpResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const responseData: PasswordResponse = (
    location.state as { responseData: PasswordResponse }
  )?.responseData;

  const handleClick = (option: string) => {
    console.log(option);
    
    setSelectedOption(option);
  };

  const sendToBackend = async () => {
    if (selectedOption === "sms") {
      const response = await generateOtp(
        responseData.mobile,
        "MOBILE",
        "RESETPASSWORD",
        responseData.appId,
        responseData.isdCode
      );
      if (response && response.otp.length === 6) {
        navigate("otpscreen", {
          state: { responseData: response, appId: responseData.appId },
        });
      }
    }
    if (selectedOption === "email") {
      const response = await generateOtp(
        responseData.email,
        "MOBILE",
        "RESETPASSWORD",
        responseData.appId
      );
    }
  };

  return (
    <>
      <div className="" style={{ padding: "0px" }}>
        <div className="d-flex justify-content-center">
          <p
            className="p-0 mb-10 "
            style={{ fontWeight: "800", fontSize: "20px" }}
          >
            Forgot Password
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center ">
          <img
            src={ForgotImg}
            className=""
            alt=""
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center mt-12 "
          style={{ fontSize: "16px" }}
        >
          <p>
            Select which contact details should we use to reset your password
          </p>
        </div>
        <div>
          <div>
            <div
              className="card shadow d-flex flex-row gap-5 p-5 mb-5 cursor-pointer"
              style={{
                outline:
                  selectedOption === "sms" ? "2px solid #246BFD" : "none",
              }}
              onClick={() => handleClick("sms")}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#E9F0FF",
                  borderRadius: "100px",
                }}
              >
                <img src={ChatImg} alt="" />
              </div>
              <div>
                <p>via SMS:</p>
                <h4>{responseData.isdCode + responseData.mobile}</h4>
              </div>
            </div>
            {/* <div
              className="card shadow d-flex flex-row  gap-5 p-5"
              style={{
                outline:
                  selectedOption === "email" ? "2px solid #246BFD" : "none",
              }}
              onClick={() => handleClick("email")}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#E9F0FF",
                  borderRadius: "100px",
                }}
              >
                <img src={MessageImg} alt="" />
              </div>
              <div>
                <p>via Email:</p>
                <h4>{responseData.email}</h4>
              </div>
            </div> */}
          </div>
          <div className="mt-10">
            <button
              className="p-5 border-0 fs-4"
              style={{
                borderRadius: "50px",
                color: "#ffff",
                backgroundColor: "#246BFD",
                width: "434px",
                fontWeight: "bolder",
              }}
              onClick={() => {
                sendToBackend();
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
