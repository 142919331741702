import { enhancedFetch } from "../../modules/auth/core/_requests";
import {
  BusinessFormValues,
  CategoryModal,
  Message,
  Payload,
  ResponseModal,
} from "./Interface";

export const getUserDetails = async (
  address: string | undefined,
  token: string
): Promise<Message> => {
  try {
    const url = `${address}/getAccountProfileDetails`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data?.message;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findBusinessCategory = async (
  address: string | undefined,
  token: string
): Promise<CategoryModal[]> => {
  try {
    const url = `${address}/findBusinessCategory`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data?.results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateUser = async (
  address: string | undefined,
  token: string,
  payload: Payload
): Promise<ResponseModal> => {
  try {
    const url = `${address}/updateUser`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify(payload);

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    getUserDetails(address, token);

    return data.status as ResponseModal;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateEmail = async (
  address: string | undefined,
  token: string,
  email: string
): Promise<ResponseModal> => {
  try {
    const url = `${address}/updateEmail`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({ email: email });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.status;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePhoneNum = async (
  address: string | undefined,
  token: string,
  phoneNum: string
): Promise<ResponseModal> => {
  try {
    const url = `${address}/updateMobile`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // Extracting country code and phone number
    const countryCodeRegex = /^\+(\d{1,3})\s?/; // Updated regex to properly capture the country code without the space
    const phoneWithoutCountryCode = phoneNum
      .replace(countryCodeRegex, "")
      .replace(/[\s+\-]/g, ""); // Remove the country code and non-digit characters from the phone number
    const extractedCountryCode =
      (phoneNum.match(countryCodeRegex) || [])[1] || "";

    const body = JSON.stringify({
      mobile: phoneWithoutCountryCode,
      isdCode: extractedCountryCode,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.status as ResponseModal;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePassword = async (
  address: string | undefined,
  token: string,
  // oldPassword: string,
  newPassword: string
): Promise<ResponseModal> => {
  try {
    const url = `${address}/changePassword`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      // oldPin: oldPassword,
      pin: newPassword,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.status as ResponseModal;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePin = async (
  address: string | undefined,
  token: string,
  // oldPin: string,
  newPin: string
): Promise<ResponseModal> => {
  try {
    const url = `${address}/changePin`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      // oldPin: oldPin,
      pin: newPin,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.status as ResponseModal;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateDetails = async (
  formData: FormData,
  token: string,
  address: string,
  urlPath: string
): Promise<any> => {
  try {
    console.log(formData);
    
    const url = `${address}/${urlPath}`;

    const headers = {
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      // tenant_id: "eazy_bank",
    };

    const options = {
      method: "POST",
      headers,
      body: formData,
    };

    let response = await fetch(url, options);
    let data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
