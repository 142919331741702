/* eslint-disable jsx-a11y/anchor-is-valid */
import OTPInput from "react-otp-input";
import { StepProps } from "../IAppModels";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export interface Step4Ref {
  handlePinRemove: (msg: string) => void;
}

const Step4 = forwardRef<Step4Ref, StepProps>(
  ({ data, updateData, hasError, txnPin, setTxnPin }, ref4) => {
    const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
    const [activeInput, setActiveInput] = useState(0);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [pin, setPin] = useState("");
    const [show, setShow] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
      // Set focus on the active input whenever activeInput changes
      inputRefs.current[activeInput]?.focus();
    }, [activeInput]);

    useImperativeHandle(ref4, () => ({
      handlePinRemove,
    }));

    const handlePinRemove = (msg: string) => {
      setTxnPin("");
      setError(msg);
      setPin("");
      setOtp(["", "", "", ""]);
      setActiveInput(0);
    };

    const handleChange = (value: string, index: number): string => {
      // Only allow positive numbers
      if (/^\d*$/.test(value) && pin.length < 4) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        setPin(pin + value);

        if (value && index < otp.length - 1) {
          setActiveInput(index + 1);
        }
        return pin + value;
      }
      return pin;
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
      if (e.key === "Backspace") {
        if (otp[index] !== "") {
          const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
          setPin(pin.substring(0, pin.length - 1));
          return pin.substring(0, pin.length - 1);
        } else if (index > 0) {
          setActiveInput(index - 1);
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
          setPin(pin.substring(0, pin.length - 1));
          return pin.substring(0, pin.length - 1);
        }
        setError("");
        setTxnPin("");
      }
      return pin;
    };

    return (
      <>
        <div className="p-5 card shadow-sm" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="text-center mt-3">
              <h4>Enter Your PIN</h4>
            </div>
            <div className="text-center mt-3">
              <p>Enter your PIN to confirm</p>
            </div>
            <form>
              <div className="d-flex flex-column text-center mt-5 justify-content-center">
                <OTPInput
                  // {...conversionformik?.getFieldProps("pin")}
                  value={otp.join("")}
                  onChange={(value: string) => {
                    value
                      .split("")
                      .forEach((char, index) => handleChange(char, index));
                  }}
                  numInputs={4}
                  containerStyle={{
                    justifyContent: "center",
                  }}
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      value={otp[index]}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => {
                        const pin = handleChange(e.target.value, index);
                        setError("");
                        if (pin.length === 4) {
                          setTxnPin(pin);
                        } else {
                          setTxnPin("");
                        }
                      }}
                      onKeyDown={(e) => {
                        const pin = handleKeyDown(e, index);
                       
                      }}
                      type={show ? "password" : "text"}
                      autoFocus={true}
                      disabled={index !== activeInput}
                      onFocus={() => setActiveInput(index)}
                      className="text-center rounded mt-3 border border-1 border-dark"
                      style={{ height: "50px", width: "50px" }}
                    />
                  )}
                />
                <p
                  className="text-center cursor-pointer mb-0 text-decoration-underline mt-2"
                  onClick={() => setShow(!show)}
                >
                  {show ? "Show PIN" : "Hide PIN"}
                </p>
                {error && <p className="text-danger text-center">{error}</p>}
                {!txnPin && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      PIN is required
                    </div>
                  </div>
                )}
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
);

export { Step4 };
