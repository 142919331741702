import { FC, useRef } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import money from "../../../../assets/all-serviceIcons/home.svg";
import card from "../../../../assets/all-serviceIcons/card.svg";
import payroll from "../../../../assets/all-serviceIcons/calculator-simple.svg";
import statistics from "../../../../assets/all-serviceIcons/chart-line-up.svg";
import bill from "../../../../assets/all-serviceIcons/money-bill-check.svg";
import loan from "../../../../assets/all-serviceIcons/hand-holding-dollar-circle.svg";
import statements from "../../../../assets/all-serviceIcons/papers-text.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const toggleRef = useRef<HTMLDivElement>(null);
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
    ? "minimize"
    : "";
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
  const tooltipDashboard = <Tooltip id="tooltip-dashboard">Dashboard</Tooltip>;
  const tooltipCard = <Tooltip id="tooltip-dashboard">My Cards</Tooltip>;
  const tooltipStatistics = (
    <Tooltip id="tooltip-dashboard">Statistics</Tooltip>
  );
  const tooltipBill = <Tooltip id="tooltip-dashboard">Bill Pay</Tooltip>;

  return (
    <>
      <div className="menu-item">
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span
              className="menu-icon"
              ref={toggleRef}
              id="kt_app_sidebar_toggle"
              data-kt-toggle="true"
              data-kt-toggle-state={toggleState}
              data-kt-toggle-target="body"
              data-kt-toggle-name={`app-sidebar-${toggleType}`}
            >
              {/* <KTIcon iconName={icon} className='fs-5 text-dark text-hover-info '  /> */}
              {icon === "dashboard" && (
                <img 
                  style={{ height: "18px", width: "18px" }}
                  src={money}
                  alt=""
                />
              )}
              {icon === "card" && (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={card}
                  alt=""
                />
              )}
              {icon === "loan" && (
                <img
                  style={{ height: "18px", width: "18px", color: "black" }}
                  src={loan}
                  alt=""
                />
              )}
              {icon === "statements" && (
                <img
                  style={{ height: "18px", width: "18px", color: "black" }}
                  src={statements}
                  alt=""
                />
              )}
              {icon === "statistics" && (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={statistics}
                  alt=""
                />
              )}
              {icon === "bill" && (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={bill}
                  alt=""
                />
              )}
               {icon === "payroll" && (
              <img
                style={{ height: "18px", width: "18px" }}
                src={payroll}
                alt=""
              />
            )}
               {icon === "communication" && (
                <i className="ki-outline ki-messages text-dark fs-3" ></i>
              )}
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </Link>
        {children}
      </div>
    </>
  );
};

export { SidebarMenuItem };
