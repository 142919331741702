import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import {
  findBusinessCategory,
  getUserDetails,
  updateDetails,
  updateUser,
} from "./Api";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  useFormik,
} from "formik";
import * as Yup from "yup";
import {
  BusinessFormValues,
  CategoryModal,
  Message,
  Payload,
} from "./Interface";
import dayjs from "dayjs";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";
import { toast } from "react-toastify";
import { secondaryApplicant } from "../../modules/auth/components/KYC/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ApiCalling = {
  api: (): void => { },
};

interface SecondaryDirector {
  reapplyParams?: Record<string, any>;  // Or define a more specific type if you know the shape of reapplyParams
  idType?: string;
  idNumber?: string;
  idExpiryDate?: string | Date;  // Use string or Date depending on your data format
  documents?: Array<{ documentName: string }>;  // Adjust according to the structure of documents
}

interface UserDetails {
  secondaryDirectors?: SecondaryDirector[];
}


const PartnerDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [userDetails, setUserDetails] = useState<Message>();

  const [img, setImg] = useState<string | undefined>("");
  const [doc, setDoc] = useState<string | undefined>("");

  const [isIDTypeEditing, setIsTypeIdEditing] = useState(false);
  const [isIDEditing, setIsIdEditing] = useState(false);
  const [isIDExpExpiring, setIsIDExpExpiring] = useState(false);
  const [isIDDocEditing, setIsIDDocEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const validationSchema = Yup.object({
    idType: Yup.string()
      .required("ID Proof Type is required")
      .test("is-different-idType", "ID Proof Type must be different", function (value) {
        const secondaryDirector: SecondaryDirector = userDetails?.secondaryDirectors?.[editingIndex] || {};
        const { reapplyParams, idType } = secondaryDirector;
        if (reapplyParams?.idType && Object.keys(reapplyParams).length > 0) {
          return value !== idType;
        }
        return true; // Bypass if not reapply
      }),

    idNumber: Yup.string()
      .required("ID Number is required")
      .test("is-different-idNumber", "ID Number must be different", function (value) {
        const secondaryDirector: SecondaryDirector = userDetails?.secondaryDirectors?.[editingIndex] || {};
        const { reapplyParams, idNumber } = secondaryDirector;
        if (reapplyParams?.idNumber && Object.keys(reapplyParams).length > 0) {
          return value !== idNumber;
        }
        return true; // Bypass if not reapply
      }),

    expiryDate: Yup.date()
      .required("Expiry Date is required")
      .typeError("Invalid date format")
      .test("is-different-expiryDate", "Expiry Date must be different", function (value) {
        const secondaryDirector: SecondaryDirector = userDetails?.secondaryDirectors?.[editingIndex] || {};
        const { reapplyParams, idExpiryDate } = secondaryDirector;
        if (reapplyParams?.idExpiryDate && Object.keys(reapplyParams).length > 0) {
          return (
            value &&
            dayjs(value).format("YYYY-MM-DD") !== dayjs(idExpiryDate).format("YYYY-MM-DD")
          );
        }
        return true; // Bypass if not reapply
      }),

    document: Yup.mixed()
      .test("is-different-document", "Document must be different", function (value) {
        const secondaryDirector: SecondaryDirector = userDetails?.secondaryDirectors?.[editingIndex] || {};
        const { reapplyParams, documents } = secondaryDirector;
        if (reapplyParams?.idProofDocument && Object.keys(reapplyParams).length > 0) {
          const existingDocumentName = documents?.[0]?.documentName;
          return value instanceof File && value?.name !== existingDocumentName;
        } 
        return true; // Bypass if not reapply
      }),
  });



  const formik = useFormik({
    initialValues: {
      idType: "",
      idNumber: "",
      expiryDate: "",
      document: undefined,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const formData = new FormData();

        if (isIDTypeEditing && values.idType !== userDetails?.secondaryDirectors[editingIndex || 0]?.idType) {
          formData.append(`secondaryDirectors[${editingIndex}].idType`, values.idType);
          formData.append(`secondaryDirectors[${editingIndex}].reapplyParams[idType]`, values.idType);
        }

        if (isIDEditing && values.idNumber !== userDetails?.secondaryDirectors[editingIndex || 0]?.idNumber) {
          formData.append(`secondaryDirectors[${editingIndex}].idNumber`, values.idNumber);
          formData.append(`secondaryDirectors[${editingIndex}].reapplyParams[idNumber]`, values.idNumber);
        }

        if (isIDExpExpiring && values.expiryDate !== userDetails?.secondaryDirectors[editingIndex || 0]?.idExpiryDate) {
          formData.append(`secondaryDirectors[${editingIndex}].idExpiryDate`, values.expiryDate);
          formData.append(`secondaryDirectors[${editingIndex}].reapplyParams[idExpiryDate]`, values.expiryDate);
        }

        if (isIDDocEditing && values.document) {
          formData.append(`secondaryDirectors[${editingIndex}].file`, values.document);
          formData.append(`secondaryDirectors[${editingIndex}].reapplyParams[idProofDocument]`, "document");
        }

        formData.append(
          `secondaryDirectors[${editingIndex}].userGuid`,
          userDetails?.secondaryDirectors[editingIndex || 0]?.userGuid || ""
        );
        formData.append("kycId", userDetails?.kycId || "");

        const res = await updateDetails(
          formData,
          token,
          `${API_URL}`,
          "kycUpdatePartnersData"
        );

        if (res?.status?.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Your profile will be updated after the review. We typically take between 24 to 72 hours to provide an update via SMS and email.",
          });
          const data = await getUserDetails(API_URL, token);
          setUserDetails(data);
        }
      } catch (error) {
        toast("Error updating details");
      } finally {
        // Reset flags after submit
        setEditingIndex(0);
        setIsTypeIdEditing(false);
        setIsIdEditing(false);
        setIsIDExpExpiring(false);
        setIsIDDocEditing(false);
        setLoading(false);
        setSubmitting(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        formik.resetForm();
      }
    },

  });

  const handleEditClick = (index: number, field: string) => {
    setEditingIndex(index as any);

    switch (field) {
      case "idType":
        formik.setFieldValue("idType", userDetails?.secondaryDirectors[index]?.idType || "");
        setIsTypeIdEditing(true);
        break;
      case "idNumber":
        formik.setFieldValue("idNumber", userDetails?.secondaryDirectors[index]?.idNumber || "");
        setIsIdEditing(true);
        break;
      case "expiryDate":
        formik.setFieldValue("expiryDate", userDetails?.secondaryDirectors[index]?.idExpiryDate?.split("T")[0] || "");
        setIsIDExpExpiring(true);
        break;
      case "idProofDocument":
        formik.setFieldValue("document", undefined);
        setIsIDDocEditing(true);
        break;
      default:
        break;
    }
  };


  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
      const businessCategory = await findBusinessCategory(API_URL, token);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);




  return (
    <>
      {" "}
      <h4 className="mb-5">Partner Details</h4>
      <div
      // className={`d-flex gap-3 ${
      //   // window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
      // }`}
      >
        <div className="d-flex flex-column">
          <form onSubmit={formik.handleSubmit} className="fs-8">
            <div>
              {userDetails?.secondaryDirectors?.map((dir, index) => (
                <div
                  className="card shadow-sm p-5 mb-5 d-flex flex-column"
                  key={index}
                  onMouseEnter={() => {
                    const secondaryDirector = userDetails?.secondaryDirectors[index];
                
                    formik.setValues({
                      idType: secondaryDirector?.idType || "",
                      idNumber: secondaryDirector?.idNumber || "",
                      expiryDate: secondaryDirector?.idExpiryDate?.split("T")[0] || "",
                      document: undefined, // Reset document field for each edit
                    });
                  }}
                  
                >
                  <div className="d-flex flex-row gap-20 align-items-center mb-5">
                    <div className="d-flex flex-column p-3 gap-2">
                      <div
                        className="card shadow-sm"
                        style={{ maxHeight: "150px", maxWidth: "150px" }}
                      >
                        <div
                          className="image-input image-input-outline overlay overflow-hidden shadow"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="overlay-wrapper">
                            <img
                              src={dir?.documents[0]?.documentPath}
                              style={{
                                borderRadius: "10px",
                                objectFit: "fill",
                                height: "150px",
                                width: "150px",
                              }}
                            />
                          </div>
                          <div className="overlay-layer bg-dark bg-opacity-50 align-items-center">
                            <div
                              className="btn btn-icon bg-light border border-dark"
                              style={{ height: "30px", width: "30px" }}
                              onClick={() => {
                                if (
                                  dir?.documents[0]?.documentExtention === "pdf"
                                ) {
                                  setDoc(dir?.documents[0]?.documentPath);
                                  handleDocViewClick();
                                } else {
                                  setImg(dir?.documents[0]?.documentPath);
                                  handleViewClick();
                                }
                              }}
                            >
                              <i className="bi bi-eye fs-5 text-dark"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card rounded p-3">
                        <div className="fw-bold fs-7">
                          DOB : {dir?.dob?.split("T")[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row ms-5 mt-5">
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">First Name</div>
                        <div className="fs-8">{dir?.firstName}</div>
                      </div>
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">Last Name</div>
                        <div className="fs-8">{dir?.lastName}</div>
                      </div>
                      <div style={{ width: "355px" }}>
                        <div className="fw-bold fs-7">Nationality</div>
                        <div className="fs-8">{dir?.nationality}</div>
                      </div>
                    </div>
                    <br />
                    <div className="d-flex flex-row ms-5 mt-5">
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">Mobile Number</div>
                        <div className="fs-8">
                          {dir?.isdCode}-{dir?.mobileNumber}
                        </div>
                      </div>
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">Email ID</div>
                        <div className="fs-8">{dir?.email}</div>
                      </div>
                      <div style={{ width: "355px" }}>
                        <div className="fw-bold fs-7">
                          ID Proof Type{" "}
                          {typeof userDetails?.secondaryDirectors[index]
                            .reapplyParams?.idType === "string" ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">
                                  {userDetails?.secondaryDirectors[index].reapplyParams?.idType}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              >
                                <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </div>
                        {isIDTypeEditing && editingIndex === index ? (
                          <div className="input-group">
                            <select
                              name="idType"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.idType}
                              className={`form-control ${formik.errors.idType && formik.touched.idType
                                  ? "is-invalid"
                                  : ""
                                }`}
                              style={{ maxWidth: "200px" }}
                            >
                              <option value="">Select</option>
                              <option value="NATIONAL_ID">NID</option>
                              <option value="NATIONAL_ID_NO_PHOTO">Passport</option>
                            </select>
                          </div>
                        ) : (
                          <div className="fs-8">
                            {dir?.idType}{" "}
                            <i
                              className="bi bi-pencil-square fs-8 cursor-pointer text-dark"
                              onClick={() => handleEditClick(index, "idType")}
                            ></i>
                          </div>
                        )}
                        {formik.errors.idType && formik.touched.idType && (
                          <div className="text-danger">
                            {formik.errors.idType}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="d-flex flex-row ms-5 mt-5 mb-5">
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">
                          ID Number{" "}
                          {typeof userDetails?.secondaryDirectors[index]
                            .reapplyParams?.idNumber === "string" ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">
                                  {userDetails?.secondaryDirectors[index].reapplyParams?.idNumber}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              >
                                <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </div>
                        {isIDEditing && editingIndex === index ? (
                          <div className="input-group">
                            <input
                              type="text"
                              name="idNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.idNumber}
                              className={`form-control ${formik.errors.idNumber &&
                                  formik.touched.idNumber
                                  ? "is-invalid"
                                  : ""
                                }`}
                              style={{ maxWidth: "200px" }}
                              max={15}
                            />
                          </div>
                        ) : (
                          <div className="fs-8">
                            {dir?.idNumber}{" "}
                            <i
                              className="bi bi-pencil-square fs-8 cursor-pointer"
                              onClick={() => handleEditClick(index, "idNumber")}
                            ></i>
                          </div>
                        )}
                        {formik.errors.idNumber && formik.touched.idNumber && (
                          <div className="text-danger">
                            {formik.errors.idNumber}
                          </div>
                        )}
                      </div>
                      <div style={{ width: "400px" }}>
                        <div className="fw-bold fs-7">
                          Expiry Date{" "}
                          {typeof userDetails?.secondaryDirectors[index]
                            .reapplyParams?.idExpiryDate === "string" ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">
                                  {userDetails?.secondaryDirectors[index].reapplyParams?.idExpiryDate}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              >
                                <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </div>
                        {isIDExpExpiring && editingIndex === index ? (
                          <div className="input-group">
                            <input
                              type="date"
                              name="expiryDate"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.expiryDate}
                              className={`form-control ${formik.errors.expiryDate &&
                                  formik.touched.expiryDate
                                  ? "is-invalid"
                                  : ""
                                }`}
                              style={{ maxWidth: "200px" }}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        ) : (
                          <div className="fs-8">
                            {dir?.idExpiryDate?.split("T")[0]}{" "}
                            <i
                              className="bi bi-pencil-square fs-8 cursor-pointer text-dark"
                              onClick={() =>
                                handleEditClick(index, "expiryDate")
                              }
                            ></i>
                          </div>
                        )}
                        {formik.errors.expiryDate && formik.touched.expiryDate && (
                          <div className="text-danger">
                            {formik.errors.expiryDate}
                          </div>
                        )}
                      </div>
                      <div style={{ width: "355px" }}>
                        <div className="fw-bold fs-7">
                          ID Document{" "}
                          {typeof userDetails?.secondaryDirectors[index]
                            .reapplyParams?.idProofDocument === "string" ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-add-money">
                                  {userDetails?.secondaryDirectors[index].reapplyParams?.idProofDocument}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              >
                                <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </div>
                        {isIDDocEditing && editingIndex === index ? (
                          <div className="input-group">
                            <input
                              type="file"
                              ref={fileInputRef}
                              name="document"
                              onChange={(event) => {
                                const file = event.currentTarget.files?.[0];
                                formik?.setFieldValue("document", file);
                              }}
                              onBlur={formik.handleBlur}
                              className={`form-control ${formik.errors.document &&
                                  formik.touched.document
                                  ? "is-invalid"
                                  : ""
                                }`}
                              style={{ maxWidth: "200px" }}
                            />
                          </div>
                        ) : (
                          <div className="fs-8 text-primary fw-bold d-flex align-items-center cursor-pointer text-dark">
                            <span
                              onClick={() => {
                                if (
                                  dir?.documents[0]?.documentExtention === "pdf"
                                ) {
                                  setDoc(dir?.documents?.[index]?.documentPath);
                                  handleDocViewClick();
                                } else {
                                  setImg(dir?.documents?.[index]?.documentPath);
                                  handleViewClick();
                                }
                              }}
                            >
                              {dir?.documents?.[0]?.documentName}{" "}
                            </span>

                            <i
                              className="bi bi-pencil-square fs-8 cursor-pointer text-dark ms-3 mt-1"
                              onClick={() =>
                                handleEditClick(index, "idProofDocument")
                              }
                            ></i>
                          </div>
                        )}
                        {formik.errors.document && formik.touched.document && (
                          <div className="text-danger">
                            {formik.errors.document}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {editingIndex === index && (
                    <div className="">
                      {/* <button
                className="btn btn-secondary ms-3"
                onClick={handleCancelEdit}
                disabled={loading}
              >
                Cancel
              </button> */}
                      <button
                        type="submit"
                        className="btn ms-5 mt-5 mb-5"
                        disabled={formik.isSubmitting || loading ||
                          (formik.values.idType === userDetails?.secondaryDirectors[editingIndex]?.idType &&
                            formik.values.idNumber === userDetails?.secondaryDirectors[editingIndex]?.idNumber &&
                            formik.values.expiryDate === userDetails?.secondaryDirectors[editingIndex]?.idExpiryDate &&
                            formik.values.document === undefined)}

                      >
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div>Update</div>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className="modal" tabIndex={-1} id="image_modal">
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="modal-body justify-content-center d-flex">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <img
                    className="image"
                    src={img}
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pdf_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
            <div
              className="modal-content"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <div className="modal-header" id="kt_modal_add_user_header">
                <h2 className="fw-bold">PDF Viewer</h2>

                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>

              <div className="modal-body px-15">
                <embed
                  src={`${doc}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PartnerDetails;
