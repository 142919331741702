import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import PinInput from "react-pin-input";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Accounts } from "../../interface/Interface";
import ReactCardFlip from "react-card-flip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import masterCard from "../../../_metronic/assets/Icons/mastercard-logo-transparent-png-stickpng-10.png";
import blackCard from "../../../_metronic/assets/Icons/Black_Plain.png";
import { CardData } from "./MyCard";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import { User } from "../TransferMoney/TransferModal";

interface AddMoneyProps {
  listCards: (action: string) => Promise<void>;
  cardData: CardData;
}

export const AddMoney: React.FC<AddMoneyProps> = ({ cardData, listCards }) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const ModalRef = useRef<HTMLDivElement | null>(null);
  const PinModalRef = useRef<HTMLDivElement | null>(null);
  const viewPinModalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<any> | null>(null);
  const viewFormikRef = useRef<FormikProps<any> | null>(null);
  let viewPinRef = useRef<PinInput | null>(null);
  let confirmPinRef = useRef<PinInput | null>(null);
  const addMoneyformikRef = useRef<FormikProps<any> | null>(null);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [card, setCard] = useState<CardData>(cardData);
  const [account, setAccount] = useState("");
  const [name, setName] = useState(cardData?.cardName);
  const [cardNumber, setCardNumber] = useState(cardData?.cardNumber);
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [balanceErr, setBalanceErr] = useState("");
  const [hide, setHide] = useState(true);
  const [show, setShow] = useState(true);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const inputRefs2 = useRef<(HTMLInputElement | null)[]>([]);
  const [authority, setAuthority] = useState(0);
  const [currency, setCurrency] = useState("");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Submit");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [approverName, setApprover] = useState("");
  const [approverGuid, setApproverGuid] = useState("");

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
    inputRefs2.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) setAccounts(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
    ApproverList(userDet?.userDetails?.zarLimit);
  }, []);

  const topUpCard = async () => {
    try {
      const url = `${API_URL}/card/topUpCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        cardType: cardData.cardType,
        cardNumber: cardData.cardNumber,
        accountNumber: account,
        topUpAmount: +amount,
        transactionPIN: pin,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: cardData.cardName,
          currency: currency,
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        listCards("");
        handleCloseModal();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Pre-paid amount requested has been added",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      }
      if (data.status.statusCode === 1) {
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
        setError(data?.status?.messageDescription);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getCard = async (pin: string) => {
    try {
      const url = `${API_URL}/card/showCardDetail`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: cardData.cardType,
        cardNumber: cardData.cardNumber,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setHide(!hide);
        setCard(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCloseModal = () => {
    setBalanceErr("");
    addMoneyformikRef.current?.resetForm();

    const modalElement = ModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleConfirmClick = async () => {
    const modalElement = PinModalRef.current;
    const modal = new Modal(modalElement as Element);
    modal.show();

    // Close the create_modal after opening the add_pin modal
    handleDeleteCloseModal();
  };

  useEffect(() => {
    if (PinModalRef.current) {
      const modal = new Modal(PinModalRef.current);

      PinModalRef.current.addEventListener("shown.bs.modal", () => {
        confirmPinRef?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      PinModalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        PinModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }

    if (viewPinModalRef.current) {
      const modal = new Modal(viewPinModalRef.current);

      viewPinModalRef.current.addEventListener("shown.bs.modal", () => {
        viewPinRef?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      viewPinModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleViewCloseModal
      );
      return () => {
        viewPinModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleViewCloseModal
        );
        modal.dispose();
      };
    }

    if (ModalRef.current) {
      const modal = new Modal(ModalRef.current);
      ModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        ModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    formikRef.current?.setFieldValue("confirmPin", "");
    const modalElement = PinModalRef.current;
    if (modalElement) {
      confirmPinRef.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleViewCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    viewFormikRef.current?.setFieldValue("securityCode", "");
    const modalElement = viewPinModalRef.current;
    if (modalElement) {
      viewPinRef.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleView = () => {
    const modalElement = document.getElementById("viewPin_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  function maskCardNumber(cardNum: string): string {
    // Check for empty or undefined cardNum
    if (!cardNum) {
      return "";
    }

    // Remove spaces, then get the last four digits
    const cleanedCardNum = cardNum.replace(/\s+/g, "");
    const lastFourDigits = cleanedCardNum.slice(-4);

    // Construct the masked string with only two asterisks
    const masked = "** " + lastFourDigits;

    // Insert spaces to the masked string to maintain the format
    return masked;
  }

  const handleCopyClick = () => {
    // Use the Clipboard API to copy the text
    navigator.clipboard
      .writeText(cardData.cardNumber)
      .then(() => {
        alert("Text copied to clipboard");
      })
      .catch((err) => {
        alert("Failed to copy text: " + err);
      });
  };

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  return (
    <>
      <style>{`
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }`}</style>
      <div
        className="modal fade"
        tabIndex={-1}
        id="addMoney_modal"
        ref={ModalRef}
          
      >
        <div className="modal-dialog modal-content">
          <div className="modal-header d-flex flex-row justify-content-between py-3">
            <h4 className="mb-0">Top Up Card</h4>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
            </div>
          </div>

          <div
            className="modal-body mx-auto"
            style={{
              display: authority === 3 ? "flex" : "",
              minHeight: "300px",
              alignItems: authority === 3 ? "center" : "normal",
              justifyContent: authority === 3 ? "center" : "normal",
            }}
          >
            {authority === 3 ? (
              <div>
                <p className="fw-bold text-center text-danger">
                  You don't have access to this feature.
                </p>

                <p className="fw-bold text-center text-danger">
                  Please contact admin.
                </p>
              </div>
            ) : (
              <>
                <div className="d-flex flex-column justify-content-center ">
                  <ReactCardFlip
                    isFlipped={!hide}
                    flipDirection="horizontal"
                    flipSpeedFrontToBack={1}
                  >
                    <div
                      className="d-flex card shadow-sm p-10 ms-10"
                      style={{
                        height: "220px",
                        width: "380px",
                        backgroundImage: `url(${blackCard})`,
                        backgroundSize: "auto",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="d-flex flex-row mb-15 justify-content-between align-items-center">
                        
                        <div>
                          <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/xfin Logo.png")}
                            className="h-35px app-sidebar-logo-default "
                          />
                        </div>
                        <i
                          className={`bi bi-eye${
                            hide ? "" : "-slash"
                          } fs-3 text-white cursor-pointer`}
                          onClick={() => {
                            handleView();
                          }}
                        ></i>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row gap-5 align-items-center">
                          <div>
                            <p className="text-white m-0">{cardData?.cardName}</p>
                            <p className="fs-3 text-white">
                              {maskCardNumber(cardData?.cardNumber)}
                            </p>
                          </div>
                        </div>
                        <div>
                          <img
                            className=" text-white"
                            src={masterCard}
                            style={{ width: "120px", height: "90px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex card shadow-sm bg-dark p-10 ms-10"
                      style={{
                        height: "220px",
                        width: "380px",
                        backgroundImage: `url(${blackCard})`,
                        backgroundSize: "auto",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="d-flex flex-row mb-2 justify-content-between align-items-center">
                        <div className="d-flex flex-row align-items-end ">
                          <div>
                            <p className="text-white m-0">Card Name</p>
                            <p className="fw-bold text-white fs-4">
                              {card?.cardName}
                            </p>
                          </div>
                        </div>
                        <i
                          className={`bi bi-eye${
                            hide ? "" : "-slash"
                          } fs-3 text-white cursor-pointer`}
                          onClick={() => setHide(!hide)}
                        ></i>
                      </div>
                      <div className="d-flex flex-row mb-2 justify-content-between align-items-center">
                        <div className="d-flex flex-row align-items-end">
                          <div>
                            <p className="text-white m-0">Card Number</p>
                            <p className="fw-bold text-white fs-4">
                              {card?.cardNumber}
                            </p>
                          </div>
                          {/* <i className="fa-light fa-copy"></i> */}
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={handleCopyClick}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            className="text-white fs-4 mb-7"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-20 align-items-center">
                        <div className="d-flex flex-row gap-20">
                          <div>
                            <p className="text-white m-0">Expiry Date</p>
                            <p className="fw-bold text-white fs-4">
                              {card?.expiryDate}
                            </p>
                          </div>
                          <div>
                            <p className="text-white m-0">CVV</p>
                            <p className="fw-bold text-white fs-4">
                              {card?.cvv}
                            </p>
                          </div>
                          <div>
                            <p className="text-white m-0">Card Limit</p>
                            <p className="fw-bold text-white fs-4">
                              {card?.spendLimit}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ReactCardFlip>
                </div>
                <div className="p-10">
                  {" "}
                  <Formik
                    innerRef={addMoneyformikRef}
                    initialValues={{
                      account: "",
                      amount: "",
                      approver: "",
                    }}
                    validationSchema={Yup.object().shape({
                      account: Yup.string().required("Account is required"),
                      amount: Yup.number()
                        .required("Amount is required")
                        .min(0.01, "Amount must be greater than 0"),
                      approver: Yup.string().test(
                        "approver-required",
                        "Select an approver",
                        function (value) {
                          if (button === "Initiate") {
                            return !!value;
                          }
                          return true;
                        }
                      ),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setAccount(values.account);
                      setAmount(values.amount);
                      const acc = accounts.find(
                        (acc) => acc.accountNumber === values.account
                      );
                      if (acc) setCurrency(acc.currency);
                      setApproverGuid(values.approver);
                      const name = approvers.find(
                        (app) => app.userGuid === values.approver
                      );
                      if (name) setApprover(name.name);
                      if (!balanceErr) {
                        handleConfirmClick();
                        resetForm();
                      }
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <div className="form-group">
                          <label className="form-label">Select Account</label>
                          <Field
                            as="select"
                            name="account"
                            className={`form-select ${
                              formik.errors.account && formik.touched.account
                                ? "is-invalid"
                                : ""
                            }`}
                            data-control="select2"
                            placeholder="Select an option"
                            style={{ padding: "6.5px" }}
                          >
                            <option value="" disabled>
                              Select an account
                            </option>
                            {accounts
                              ?.filter((acc) => acc.currency !== "USD")
                              .map((acc, key) => (
                                <option
                                  key={acc.accountNumber}
                                  value={acc.accountNumber}
                                >
                                  {acc.accountNumber}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  ({acc.currency}{" "}
                                  {acc.balance.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  )
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="account"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group mt-5 mb-5">
                          <label className="form-label">Enter Amount</label>
                          <div>
                            {" "}
                            <span
                              className="fs-6 fw-bold text-gray-700"
                              style={{
                                position: "absolute",
                                padding: "12.5px",
                              }}
                            >
                              {baseCurrency}
                            </span>
                          </div>
                          <Field
                            type="text"
                            name="amount"
                            className={`form-control custom-input ${
                              formik.errors.amount && formik.touched.amount
                                ? "is-invalid"
                                : ""
                            }`}
                            style={{ paddingLeft: "60px" }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // formik.handleChange(e);

                              // If the value is empty, set the previous value to an empty string
                              if (e.target.value === ""|| e.target.value === "0") {
                                formik.setFieldValue("amount", "");
                                setAmount("");
                                return
                              }

                              // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                              if (
                                /^\d{1,12}(\.\d{0,2})?$/.test(e.target.value)
                              ) {
                                formik.setFieldValue("amount", e.target.value);
                                setAmount(e.target.value);
                              }

                              const enteredAmount = Number(e.target.value);

                              // Verify against the balance
                              const selectedAccount = accounts.find(
                                (acc) =>
                                  acc.accountNumber === formik.values.account
                              );

                              if (
                                selectedAccount &&
                                enteredAmount > selectedAccount.balance
                              ) {
                                setBalanceErr(
                                  "Amount exceeds available balance"
                                );
                                formik.setFieldValue("errorStatus", true); // Set errorStatus to true
                                formik.setFieldError(
                                  "amount",
                                  "Amount exceeds balance"
                                );
                              } else {
                                setBalanceErr("");
                                formik.setFieldValue("errorStatus", false); // Set errorStatus to false
                                formik.setFieldError("amount", ""); // Clear the error if condition is not met
                              }
                            }}
                            disabled={!formik.values.account}
                          />
                          {balanceErr && ( // Use formik.status to conditionally render the error message
                            <div className="text-danger">
                              {balanceErr}
                            </div>
                          )}
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        {button === "Initiate" ? (
                          <div className="mb-5">
                            <label className="form-label" htmlFor="approver">
                              Select Approver
                            </label>
                            <Field name="approver">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <>
                                  <select
                                    {...field}
                                    className="form-select"
                                    value={field.value}
                                    onChange={(e) => {
                                      form.setFieldValue(
                                        "approver",
                                        e.target.value
                                      );
                                    }}
                                    autoFocus={true}
                                  >
                                    <option value="" disabled>
                                      Select Approver
                                    </option>
                                    {approvers.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item?.userGuid}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}
                            </Field>

                            <ErrorMessage
                              component="div"
                              name="approver"
                              className="text-danger"
                            />
                          </div>
                        ) : null}

                        <button
                          type="submit"
                          className="btn rounded me-5"
                          style={{
                            width: "180px",
                          }}
                        >
                          Proceed
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "180px",
                            color: "#246bfb",
                          }}
                          onClick={handleDeleteCloseModal}
                        >
                          Cancel
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="validate_pin"
        ref={PinModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between align-items-center ">
                <h1
                  className="text-center m-0"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                {" "}
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    confirmPin: "",
                  }}
                  validationSchema={Yup.object({
                    confirmPin: Yup.string()
                      .matches(/^\d{4}$/, "PIN must be 4 digits")
                      .required("PIN is required"),
                  })}
                  onSubmit={(values, actions) => {
                    setPin(values.confirmPin);
                    actions.resetForm();
                    topUpCard();
                    // handleCloseModal();
                  }}
                >
                  <Form>
                    <div className="my-5 ms-6">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-center fs-5">
                          Enter your PIN to confirm
                        </p>
                        <div className="d-flex justify-content-center">
                          <Field name="confirmPin">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      setError("");
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      setError("");
                                      form.setFieldValue("confirmPin", pin);
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </div>
                        <p
                          className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                          onClick={() => setShow(!show)}
                        >
                          {show ? "Show PIN" : "Hide PIN"}
                        </p>
                        <div className="mt-3">
                          <ErrorMessage
                            name="confirmPin"
                            component="div"
                            className="text-danger text-center"
                          />
                          {error && (
                            <p className="text-danger text-center">{error}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                        <button
                          type="submit"
                          className="btn rounded me-5"
                          style={{
                            width: "150px",
                          }}
                        >
                          {button}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          style={{
                            width: "150px",
                            color: "#246bfb",
                          }}
                          onClick={() => handleCloseModal()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="viewPin_modal"
        ref={viewPinModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body p-5">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div>
                  <h1 className="text-center">Enter Your PIN</h1>
                  <p className="text-center text-nowrap">
                    Enter your PIN to confirm
                  </p>
                  <div className="d-flex flex-no-wrap justify-content-evenly mt-1 mb-5">
                    <Formik
                      innerRef={viewFormikRef}
                      initialValues={{
                        securityCode: "",
                      }}
                      validationSchema={Yup.object({
                        securityCode: Yup.string()
                          .matches(/^\d{4}$/, "Security code must be 4 digits")
                          .required("Security code is required"),
                      })}
                      onSubmit={(values, actions) => {
                        getCard(values.securityCode);
                        // setPin(security);
                        actions.resetForm();
                        handleViewCloseModal();
                      }}
                    >
                      <Form>
                        <div className="mb-10 mt-5">
                          <div className="d-flex justify-content-center">
                            {" "}
                            <Field name="securityCode">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <OTPInput
                                  {...field}
                                  value={otp.join("")}
                                  onChange={(value: string) => {
                                    value
                                      .split("")
                                      .forEach((char, index) =>
                                        handleChange(char, index)
                                      );
                                  }}
                                  numInputs={4}
                                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                                  renderInput={(props, index) => (
                                    <input
                                      {...props}
                                      value={otp[index]}
                                      ref={(el) =>
                                        (inputRefs2.current[index] = el)
                                      }
                                      onChange={(e) => {
                                        const pin = handleChange(
                                          e.target.value,
                                          index
                                        );
                                        form.setFieldValue("securityCode", pin);
                                      }}
                                      onKeyDown={(e) => {
                                        const pin = handleKeyDown(e, index);
                                        form.setFieldValue("securityCode", pin);
                                      }}
                                      type={show ? "password" : "text"}
                                      autoFocus={true}
                                      disabled={index !== activeInput}
                                      onFocus={() => setActiveInput(index)}
                                      className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                      style={{ height: "50px", width: "50px" }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                          <p
                            className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                          <div className="mt-3">
                            <ErrorMessage
                              name="securityCode"
                              component="div"
                              className="text-danger text-center"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                          <button
                            type="submit"
                            className="btn rounded me-5"
                            style={{
                              width: "150px",
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary rounded"
                            style={{
                              width: "150px",
                              color: "#246bfb",
                            }}
                            onClick={() => handleViewCloseModal()}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
