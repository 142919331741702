import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAuth } from "../../modules/auth/core/Auth";
import { enhancedFetch } from "../../modules/auth/core/_requests";

export interface Role {
  roleGuid: string;
  name: string;
  description: string;
  roleStatus: number;
  userCount: number;
  appType: string;
  authority: number;
}

const Roles = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const [roles, setRoles] = useState<Role[]>([]);

  const navigate = useNavigate();

  const fetchRoles = async () => {
    try {
      const url = `${API_URL}/team/getRoles`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setRoles(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <>
      <h3 className="mb-5">Roles</h3>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
            {roles?.map((role) => (
              <div className="col-md-4" key={role.roleGuid}>
                <div className="card card-flush h-md-100">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>{role.name}</h2>
                    </div>
                  </div>

                  <div className="card-body pt-1">
                    <div className="fw-bold text-primary mb-5">
                      Total users with this role:{" "}
                      <span className="text-primary">{role.userCount}</span>
                    </div>

                    <div className="d-flex flex-column text-gray-600">
                      {role?.description
                        ?.split(",")
                        .slice(0, 5)
                        .map((roledes) => (
                          <div
                            key={roledes}
                            className="d-flex align-items-center py-2"
                          >
                            <span className="bullet bg-primary me-3"></span>
                            {roledes}
                          </div>
                        ))}
                      {role?.description?.split(",").length > 5 && (
                        <div className="d-flex align-items-center py-2">
                          <span className="bullet bg-primary me-3"></span>
                          <em>
                            and {role.description.split(",").slice(5).length}{" "}
                            more...
                          </em>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="card-footer flex-wrap pt-0">
                    <p
                      className="btn btn-light btn-active-primary my-1 me-2"
                      onClick={() => {
                        navigate("/team-banking/roles/view-role", {
                          state: {
                            roleGuid: role.roleGuid,
                            roleName: role.name,
                          },
                        });
                      }}
                    >
                      View Role
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export { Roles };
