/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "./PayrollAppModel";
import { useRef } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { TaxData } from "./Data";
export const SteppperModal3 = ({ data, updateData, hasError }: StepProps) => {
  TaxData.basicMonthlySalary = data.monthlySalary.monthlySalary;
  TaxData.basicPensionDeduction = data.pensionDeductions.pensionDeductions;
  TaxData.medicalDependants = data.medicalDependents.medicalDependents;
  TaxData.age = Math.floor(
    (new Date().getTime() - new Date(data.empDate.date).getTime()) /
      (365.25 * 24 * 60 * 60 * 1000)
  ).toString();
  TaxData.travelAllowance = data.travelAllowance.travelAllowance;
  TaxData.otherAllowances = data.otherAllowance.otherAllowance;
  TaxData.annualBonus = data.annualBonus.annualBonus;

  const baseCurrency = sessionStorage.getItem("currency");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputfield: string
  ) => {
    const value = event.target.value;

    // Check if the value is a valid number using a regular expression
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value) || value === "") {
      // Assuming `updateData` is your function to update the state
      if (inputfield === "Salary") {
        updateData({
          monthlySalary: {
            monthlySalary: value,
          },
        });
      } else if (inputfield === "Pension") {
        updateData({
          pensionDeductions: {
            pensionDeductions: value,
          },
        });
      } else if (inputfield === "Travel") {
        updateData({
          travelAllowance: {
            travelAllowance: value,
          },
        });
      } else if (inputfield === "Other") {
        updateData({
          otherAllowance: {
            otherAllowance: value,
          },
        });
      } else if (inputfield === "Annual") {
        updateData({
          annualBonus: {
            annualBonus: value,
          },
        });
      }
    }
  };

  return (
    <div
      className="pb-5 card shadow-sm p-10"
      data-kt-stepper-element="content"
      style={{ maxHeight: "300px", overflow: "auto" }}
    >
      <div className="w-100">
        <div className="fv-row p-0 ">
          <div className="">
            <label className="form-label">Basic Monthly Salary</label>
            <input
              type="text"
              className="form-control text-end"
              placeholder="Enter Basic monthly"
              value={data.monthlySalary.monthlySalary}
              style={{ padding: "6.5px" }}
              onChange={(e) => {
                const input = e.target.value;
                
                // Prevent leading zeros and validate input (only digits)
                if (/^\d*$/.test(input) && !(input.length > 1 && input.startsWith('0'))) {
                  handleInputChange(e, "Salary");
                }
              }}
              maxLength={12}
            />
            <span
              className="fs-6 fw-bold text-gray-700"
              style={{
                position: "absolute",
                padding: "12px",
                left: " 30px",
                marginTop: "-40px",
              }}
            >
              {baseCurrency}
            </span>
            {!data.monthlySalary.monthlySalary && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Monthly Salary is required
                </div>
              </div>
            )}
            {data.monthlySalary.monthlySalary === "0" && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Monthly Salary Should be greater 0
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Pension Deduction</label>
            <input
              type="text"
              className="form-control text-end custom-input"
              placeholder="Enter Pension Deduction"
              value={data.pensionDeductions.pensionDeductions}
              style={{ padding: "6.5px" }}
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d*$/.test(input) && !(input.length > 1 && input.startsWith('0'))) {
                  handleInputChange(e, "Pension");
                }
              }}
              maxLength={12}
            />
            <span
              className="fs-6 fw-bold text-gray-700"
              style={{
                position: "absolute",
                padding: "12px",
                left: " 30px",
                marginTop: "-40px",
              }}
            >
              {baseCurrency}
            </span>
            {!data.pensionDeductions.pensionDeductions && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Pension Deductions is required
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Travel Allowance</label>
            <input
              type="text"
              className="form-control text-end"
              placeholder="Enter travel allowance"
              value={data.travelAllowance.travelAllowance}
              style={{ padding: "6.5px" }}
            
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d*$/.test(input) && !(input.length > 1 && input.startsWith('0'))) {
                  handleInputChange(e, "Travel");
                }
              }}
              maxLength={12}
            />
            <span
              className="fs-6 fw-bold text-gray-700"
              style={{
                position: "absolute",
                padding: "12px",
                left: " 30px",
                marginTop: "-40px",
              }}
            >
              {baseCurrency}
            </span>
            {!data.travelAllowance.travelAllowance && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Travel Allowance is required
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Medical Dependents</label>
            <select
              className="form-select"
              data-control="select2"
              value={data.medicalDependents.medicalDependents}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = e.target.value;
                updateData({
                  medicalDependents: {
                    medicalDependents: e.target.value,
                  },
                });
              }}
              data-placeholder="Select an option"
              style={{ padding: "6.5px" }}
            >
              <option value="" style={{ color: "red" }}>
                Select Number of Dependents
              </option>
              {Array.from({ length: 11 }, (_, index) => (
                <option
                  key={index}
                  value={index}
                  selected={data.accName.accName === `${index}`}
                >
                  {index}
                </option>
              ))}
            </select>
            {!data.medicalDependents.medicalDependents && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Medical Dependents is required
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Other Allowance</label>
            <input
              type="text"
              className="form-control text-end"
              placeholder="Other allowances"
              value={data.otherAllowance.otherAllowance}
              style={{ padding: "6.5px" }}
             
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d*$/.test(input) && !(input.length > 1 && input.startsWith('0'))) {
                  handleInputChange(e, "Other");
                }
              }}
              maxLength={12}
            />
            <span
              className="fs-6 fw-bold text-gray-700"
              style={{
                position: "absolute",
                padding: "12px",
                left: " 30px",
                marginTop: "-40px",
              }}
            >
              {baseCurrency}
            </span>
            {!data.otherAllowance.otherAllowance && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Other Allowances is required
                </div>
              </div>
            )}
          </div>
          {/* <div className="mt-8">
            <label className="form-label">Age</label>
            <input
              type="text"
              className="form-control text-end"
              placeholder="Your age"
              value={Math.floor(
                (new Date().getTime() - new Date(data.empDate.date).getTime()) /
                  (365.25 * 24 * 60 * 60 * 1000)
              )}
              style={{ padding: "6.5px" }} *
              // onChange={(e) =>
              //   updateData({
              //     age: {
              //       age: e.target.value,

              //     }
              //   })
              // }
            />
            {/* {!data.age.age && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Age is required
                </div>
              </div>
            )} 
          </div>*/}
          <div className="mt-8">
            <label className="form-label">Annual Bonus</label>
            <input
              type="text"
              className="form-control text-end"
              placeholder="Annual Bonus"
              value={data.annualBonus.annualBonus}
              style={{ padding: "6.5px" }}
              
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d*$/.test(input) && !(input.length > 1 && input.startsWith('0'))) {
                  handleInputChange(e, "Annual");
                }
              }}
              maxLength={12}
            />
            <span
              className="fs-6 fw-bold text-gray-700"
              style={{
                position: "absolute",
                padding: "12px",
                left: " 30px",
                marginTop: "-40px",
              }}
            >
              {baseCurrency}
            </span>
            {!data.annualBonus.annualBonus && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Annual Bonus is required
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
