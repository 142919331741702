import { useState, useEffect, useRef, useContext } from "react";
import { Modal } from "bootstrap";
import jsPDF from "jspdf";
import { TransferResponse } from "./CurrencyConversion";
import Xfin from "../../../_metronic/assets/Icons/xfin.png";

interface EReceiptProps {
  data: TransferResponse;
}

export function ReceiptModal({ data }: EReceiptProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const downloadPDF = (billData: TransferResponse) => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");
        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 10;
        const lineHeight = 10;
        const keyWidth = 50;
        doc.text("Account Transfer", 105, yPosition, { align: "center" });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");
        // Dynamically add data from the billData object
        Object.entries(billData).forEach(([key, value], index) => {
          const keyText = `${capitalizeFirstLetter(key)}:`;
          const valueText = value.toString(); // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);

        doc.save("Account_Transfer.pdf");
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  return (
    <>
      <div className="modal fade" tabIndex={-1} id="e_receipt" ref={modalRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "8%" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center px-7 py-3">
              <p className="fs-3 pb-o">E-Receipt</p>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary text-hover-primary"
                onClick={() => handleDeleteCloseModal()}
              >
                 <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="card shadow-sm p-5">
                  {Object.entries(data).map(([key, value]) => (
                    <div
                      key={key}
                      className="d-flex justify-content-between mb-5"
                    >
                      <div>
                        <p className="fw-bold">{key}</p>
                      </div>
                      <div>
                        <p>{value}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn rounded"
                  style={{
                    width: "380px",
                  }}
                  onClick={() => downloadPDF(data)}
                >
                  Download E-Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
