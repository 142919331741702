import { Bar } from "react-chartjs-2";
import { Accounts, GraphData, StatisticsData } from "../../interface/Interface";
import { useEffect, useState } from "react";
import { getStatistics } from "./StatisticsApi";

type BardataProps = {
  accounts: Accounts[];
  bardata: StatisticsData;
  handleDateRangeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAccountChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function BarChart({
  accounts,
  bardata,
  handleDateRangeChange,
  handleAccountChange,
}: BardataProps) {
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);
  const [labels, setLabels] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<GraphData[]>([]);

  useEffect(() => {
    setLabels(
      bardata?.graphData
        ?.sort((a, b) => a.index - b.index)
        .map((entry) => entry.name)
    );
    setExpense(bardata?.expense);
    setIncome(bardata?.income);
    setGraphData(bardata?.graphData);
  }, [bardata]);

  const ExpenseValues = graphData?.map((day) => {
    const dataForD = day.data.find((entry) => entry.key === "D");
    return dataForD ? parseFloat(dataForD.value) : 0;
  });

  const IncomeValues = graphData?.map((day) => {
    const dataForC = day.data.find((entry) => entry.key === "C");
    return dataForC ? parseFloat(dataForC.value) : 0;
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Expense",
        backgroundColor: "#E8EDFE",
        borderColor: "rgb(255, 99, 132)",
        borderRadius: 5,
        data: ExpenseValues,
      },
      {
        label: "Income",
        backgroundColor: "#184BFA",
        borderColor: "rgb(132, 99, 255)",
        borderRadius: 5,
        data: IncomeValues,
      },
    ],
  };

  return (
    <div className="p-5 bg-light shadow-sm rounded">
      <div className="d-flex flex-row justify-content-between mb-5">
        <div>
          <select
            className="form-select border border-primary cursor-pointer"
            data-control="select2"
            defaultValue={accounts?.[0]?.accountNumber} // <-- Set the defaultValue here
            onChange={handleAccountChange}
          >
            {accounts?.map((acc, index) => (
              <option key={index} value={index}>
                {acc.accountNumber + " " + `(${acc.currency})`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className="form-select border border-primary cursor-pointer"
            data-control="select2"
            onChange={(e) => {
              handleDateRangeChange(e);
            }}
          >
            <option value="1" onSelect={() => {}}>
              This Week
            </option>
            <option value="2" onSelect={() => {}}>
              Last Week
            </option>
            <option value="3" onSelect={() => {}}>
              Last 3 Months
            </option>
            <option value="4" onSelect={() => {}}>
              Last 6 Months
            </option>
          </select>
        </div>
      </div>
      <Bar data={data} className="shadow-sm p-5 rounded" />
      <div className="d-flex flex-row justify-content-around mt-5 ">
        <div className="d-flex flex-row card shadow-sm w-50 me-2">
          <div className="d-flex m-5 align-items-center">
            <i
              className="bi bi-arrow-down-square-fill  fs-1"
              style={{ color: "#184BFA" }}
            ></i>
            <div className="d-flex m-2 align-items-center fs-3"> Income</div>
            <div className="d-flex m-2 align-items-center  fs-2">
              {bardata?.currency}{" "}
              {income?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row card shadow-sm w-50 ms-4">
          <div className="d-flex m-5 align-items-center">
            <i
              className="bi bi-arrow-up-square-fill  fs-1"
              style={{ color: "#E8EDFE", backgroundColor: "#0000" }}
            ></i>
            <div className="d-flex m-2 align-items-center fs-3"> Expense</div>
            <div className="d-flex m-2 align-items-center  fs-2">
              {bardata?.currency}{" "}
              {expense?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
