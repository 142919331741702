import { TxnData } from "../../interface/Interface";
import { AccountStmnt } from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

export interface FileResponse {
  fileData: string;
  fileName: string;
}

export const downloadAccountStmnt = async (
  address: string | undefined,
  token: string,
  accountNumber: string,
  currency: string,
  stmntDuration: string,
  fromDate: string,
  toDate: string
): Promise<FileResponse> => {
  try {
    const url = `${address}/txnStmnt/downloadAccountStmnt`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({
      accountNumber: accountNumber,
      currency: currency === "R" ? "SSP" : currency,
      stmntDuration: stmntDuration, //3 to have from date and to date , 2 - month data , 1 - week data
      fromDate: fromDate,
      toDate: toDate,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.message as FileResponse;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getTransactionDetail = async (
  address: string | undefined,
  token: string,
  accountNumber: string,
  txnId: string
): Promise<TxnData> => {
  try {
    const url = `${address}/txnStmnt/getTransactionDetail`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      accountNumber: accountNumber,
      txnId: txnId,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.message as TxnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAccountStmnt = async (
  address: string | undefined,
  token: string,
  accountNumber: string,
  currency: string,
  stmntDuration: string,
  fromDate: string,
  toDate: string
): Promise<AccountStmnt[]> => {
  try {
    const url = `${address}/txnStmnt/getAccountStmnt`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      accountNumber: accountNumber,
      currency: currency === "R" ? "SSP" : currency,
      stmntType: "A",
      stmntDuration: stmntDuration,
      fromDate: fromDate,
      toDate: toDate,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);

    let data = await response.json();
    return data.results as AccountStmnt[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};
