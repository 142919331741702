import { useState, useEffect, useRef, ChangeEvent } from "react";
import { Modal } from "bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import PinInput from "react-pin-input";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Card } from "./MyCard";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import { User } from "../TransferMoney/TransferModal";

interface AccandCard {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
  subAccountExist: boolean;
}

interface TransferMoneyProps {
  listCards: (action: string) => Promise<void>;
  getCardTxn: () => Promise<void>;
  cardData: Card;
}

export const TransferMoney: React.FC<TransferMoneyProps> = ({
  listCards,
  getCardTxn,
  cardData,
}) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Submit");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [approverName, setApprover] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [authority, setAuthority] = useState(0);

  const ModalRef = useRef<HTMLDivElement | null>(null);
  const PinModalRef = useRef<HTMLDivElement | null>(null);
  const pinformikRef = useRef<FormikProps<any> | null>(null);
  const formikRef = useRef<FormikProps<any> | null>(null);
  let ref = useRef<PinInput | null>(null);
  const [accounts, setAccounts] = useState<AccandCard[] | null>(null);
  const [accType, setAccType] = useState(0);
  const [account, setAccount] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [balanceErr, setBalanceErr] = useState("");

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ApproverList(userDet?.userDetails?.zarLimit);
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (+amount) {
        ApproverList(amount);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [amount]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const getAccAndCard = async () => {
    setAccounts([]);
    try {
      const url = `${API_URL}/card/getAccNdCard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        cardType: cardData?.cardType,
        cardNumber: cardData?.cardNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) setAccounts(data?.message?.accountDtos);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getAccAndCard();
  }, [cardData?.cardNumber]);

  const transferMoney = async () => {
    try {
      const url = `${API_URL}/card/transferFund`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        cardType: cardData?.cardType,
        cardNumber: cardData?.cardNumber,
        transferType: accType,
        payeeNumber: account,
        amount: +amount,
        transactionPIN: pin,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: cardData.cardName,
          currency: currency,
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setPin("");
        listCards("");
        getCardTxn();
        handleCloseModal();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Money has been transferred to selected account",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        ref.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCloseModal = () => {
    formikRef.current?.resetForm();
    setBalanceErr("");
    const modalElement = ModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleConfirmClick = async () => {
    const modalElement = PinModalRef.current;
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    pinformikRef.current?.resetForm();
    ref.current?.clear();
    const modalElement = PinModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (PinModalRef.current) {
      const modal = new Modal(PinModalRef.current);

      PinModalRef.current.addEventListener("shown.bs.modal", () => {
        ref?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      PinModalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        PinModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (ModalRef.current) {
      const modal = new Modal(ModalRef.current);
      ModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        ModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  return (
    <>
      <style>
        {`
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }`}
      </style>
      <div
        className="modal"
        tabIndex={-1}
        id="transferMoney_modal"
        ref={ModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-500px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between py-3">
              <h4 className="mb-0">Transfer Money</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div
              className="modal-body p-12"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "300px",
                alignItems: authority === 3 ? "center" : "normal",
                justifyContent: authority === 3 ? "center" : "normal",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <Formik
                  innerRef={formikRef}
                  initialValues={{ account: "", amount: "", approver: "" }}
                  validationSchema={Yup.object().shape({
                    account: Yup.string().required("Account is required"),
                    amount: Yup.number()
                      .required("Amount is required")
                      .min(0.01, "Amount must be greater than 0"),
                    approver: Yup.string().test(
                      "approver-required",
                      "Select an approver",
                      function (value) {
                        if (button === "Initiate") {
                          return !!value;
                        }
                        return true;
                      }
                    ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    // Handle form submission here
                    setAccount(values.account);
                    setAmount(values.amount);
                    setApproverGuid(values.approver);
                    const name = approvers.find(
                      (app) => app.userGuid === values.approver
                    );
                    if (name) setApprover(name.name);
                    console.log(values);

                    // Find the account object with the desired accountNumber
                    const selectedAccount = accounts?.find(
                      (acc) => acc.accountNumber === values.account
                    );

                    if (selectedAccount) {
                      const accType = selectedAccount.accountType;
                      // Now you have the accType for the accountNumber
                      console.log(accType);
                      setCurrency(selectedAccount.currency);
                      if (accType === "BANK") {
                        setAccType(1);
                      } else {
                        setAccType(2);
                      }
                    }
                    if (!balanceErr) {
                      handleDeleteCloseModal();
                      handleConfirmClick();
                    }
                  }}
                >
                  {(formik) => (
                    <Form>
                      <div className="form-group">
                        <label className="form-label">Transfer to</label>
                        <Field
                          as="select"
                          name="account"
                          className={`form-select ${
                            formik.errors.account && formik.touched.account
                              ? "is-invalid"
                              : ""
                          }`}
                          data-control="select2"
                        >
                          <option value="" disabled>
                            Select an account
                          </option>
                          {accounts?.map((acc) => (
                            <option
                              key={acc.accountNumber}
                              value={acc.accountNumber}
                            >
                              {acc.accountNumber}
                              &nbsp;&nbsp; ({acc.accountType}) &nbsp;&nbsp; (
                              {acc.currency} {acc.balance})
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="account"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="form-group mt-5">
                        <label className="form-label">Enter Amount</label>
                        <div>
                          {" "}
                          <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{ position: "absolute", padding: "12.5px" }}
                          >
                            {baseCurrency}
                          </span>
                        </div>
                        <Field
                          type="text"
                          name="amount"
                          className={`form-control custom-input ${
                            formik.errors.amount && formik.touched.amount
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ paddingLeft: "60px" }}
                          max={999999999999}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // formik.handleChange(e);

                            // If the value is empty, set the previous value to an empty string
                            if (e.target.value === ""||e.target.value==="0") {
                              setAmount(e.target.value);
                              formik.setFieldValue("amount", "");
                              return
                            }

                            // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                            if (/^\d{1,12}(\.\d{0,2})?$/.test(e.target.value)) {
                              setAmount(e.target.value);
                              formik.setFieldValue("amount", e.target.value);
                            }

                            const enteredAmount = Number(e.target.value);

                            // Verify against the balance

                            if (enteredAmount > cardData?.balance) {
                              setBalanceErr("Amount exceeds available balance");
                              formik.setFieldValue("errorStatus", true); // Set errorStatus to true
                              formik.setFieldError(
                                "amount",
                                "Amount exceeds balance"
                              );
                            } else {
                              setBalanceErr("");
                              formik.setFieldValue("errorStatus", false); // Set errorStatus to false
                              formik.setFieldError("amount", ""); // Clear the error if condition is not met
                            }
                          }}
                          disabled={!formik.values.account}
                        />
                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {balanceErr && ( // Use formik.status to conditionally render the error message
                        <div className="text-danger">
                          {balanceErr}
                        </div>
                      )}
                      {button === "Initiate" ? (
                        <div className="mt-5">
                          <label className="form-label" htmlFor="approver">
                            Select Approver
                          </label>
                          <Field name="approver">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <>
                                <select
                                  {...field}
                                  className="form-select"
                                  value={field.value}
                                  onChange={(e) => {
                                    form.setFieldValue(
                                      "approver",
                                      e.target.value
                                    );
                                  }}
                                  autoFocus={true}
                                >
                                  <option value="" disabled>
                                    Select Approver
                                  </option>
                                  {approvers.map((item, index) => (
                                    <option key={index} value={item?.userGuid}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                          </Field>

                          <ErrorMessage
                            component="div"
                            name="approver"
                            className="text-danger"
                          />
                        </div>
                      ) : null}
                      <div className="d-flex flex-row mt-7">
                        <button type="submit" className="btn rounded me-5 w-50">
                          Proceed
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary rounded w-50"
                          style={{
                            color: "#246bfb",
                          }}
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal" tabIndex={-1} id="validate_pin" ref={PinModalRef}>
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between align-items-center ">
                <h1
                  className="text-center m-0"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {" "}
                <Formik
                  innerRef={pinformikRef}
                  initialValues={{
                    confirmPin: "",
                  }}
                  validationSchema={Yup.object({
                    confirmPin: Yup.string()
                      .matches(/^\d{4}$/, "PIN must be 4 digits")
                      .required("PIN is required"),
                  })}
                  onSubmit={(values, actions) => {
                    setPin(values.confirmPin);
                    actions.resetForm();
                    transferMoney();
                  }}
                >
                  <Form>
                    <div className="mb-7 mt-5">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-center fs-5">
                          Enter your PIN to confirm
                        </p>

                        <div className="d-flex flex-column justify-content-center my-2">
                          <div className="d-flex justify-content-center">
                            <Field name="confirmPin">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <OTPInput
                                  {...field}
                                  value={otp.join("")}
                                  onChange={(value: string) => {
                                    value
                                      .split("")
                                      .forEach((char, index) =>
                                        handleChange(char, index)
                                      );
                                  }}
                                  numInputs={4}
                                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                                  renderInput={(props, index) => (
                                    <input
                                      {...props}
                                      value={otp[index]}
                                      ref={(el) =>
                                        (inputRefs.current[index] = el)
                                      }
                                      onChange={(e) => {
                                        const pin = handleChange(
                                          e.target.value,
                                          index
                                        );
                                        setError("");
                                        form.setFieldValue("confirmPin", pin);
                                      }}
                                      onKeyDown={(e) => {
                                        const pin = handleKeyDown(e, index);
                                        setError("");
                                        form.setFieldValue("confirmPin", pin);
                                      }}
                                      type={show ? "password" : "text"}
                                      autoFocus={true}
                                      disabled={index !== activeInput}
                                      onFocus={() => setActiveInput(index)}
                                      className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                      style={{ height: "50px", width: "50px" }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                          <p
                            className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                          <div className="mt-3">
                            <ErrorMessage
                              name="confirmPin"
                              component="div"
                              className="text-danger text-center"
                            />
                            {error && (
                              <p className="text-danger text-center">{error}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                      <button
                        type="submit"
                        className="btn rounded me-5"
                        style={{
                          width: "150px",
                        }}
                      >
                        {button}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary rounded"
                        style={{
                          width: "150px",
                          color: "#246bfb",
                        }}
                        onClick={() => handleCloseModal()}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
