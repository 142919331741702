import React, { useEffect, useState } from "react";
import { Accounts } from "../../interface/Interface";
import { Accordion, AccordionItem, Option } from "./Accordion";
import { FAQ, ResultItem } from "./Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

const Faq = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [faq, setFaq] = useState<ResultItem[]>([]);

  const getFAQs = async () => {
    try {
      const url = `${API_URL}/helpConfiguration/find`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        pageNumber: 1,
        pageSize: 1000,
        skipPagination: false,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setFaq(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getFAQs();
  }, []);

  return (
    <>
      <h4 className="mb-4">Frequently Asked Questions</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card  d-flex flex-column flex-grow-1">
          <>
            <div className="card m-5 p-5">
              {faq?.map((faq) => (
                <Accordion key={faq.guid}>
                  {faq.questions.map((q, index) => (
                    <React.Fragment key={index}>
                      <AccordionItem>
                        <Option label={q.question}>{q.answer}</Option>
                      </AccordionItem>
                      <hr
                        className="m-0"
                        style={{ borderTop: "1px dashed black" }}
                      />
                    </React.Fragment>
                  ))}
                </Accordion>
              ))}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Faq;
