/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../IAppModels";
import { useEffect, useRef, useState } from "react";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
import { User } from "../../../TransferMoney/TransferModal";

const Step3_5 = ({
  data,
  updateData,
  hasError,
  txnPin,
  setTxnPin,
}: StepProps) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [approvers, setApprovers] = useState<User[]>([]);

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount ? amount : '0',
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ApproverList(userDet?.userDetails?.zarLimit);
  }, []);

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) {
      updateData({
        approverName: {
          approverName: appName.name,
        },
        approverGuid: {
          approverGuid: e.target.value,
        },
      });
    }
  };

  return (
    <>
      <div className="p-5" data-kt-stepper-element="content">
        <div className="w-100">
          <div className="mb-5">
            <label className="form-label">Select Approver</label>
            <select
              id="approver"
              name="approver"
              className="form-select"
              onChange={handleApprover}
            >
              <option value="">Select Approver</option>
              {approvers.map((approv, key) => (
                <option value={approv.userGuid}>{approv.name}</option>
              ))}
            </select>
            {!data.approverName.approverName && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Approver is required
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Step3_5 };
