import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { QRPayment, ViewQR } from "./QRInterface";
import { KTSVG } from "../../../_metronic/helpers";
import QRcode from "../../../_metronic/assets/Icons/pngwing.com (1).png";

export function QRPaymentModal(data: QRPayment) {
  const modalRef1 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalRef1.current) {
      const modal = new Modal(modalRef1.current);
      modalRef1.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef1.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCloseModal = () => {
    const modalElement = modalRef1.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  return (
    <>
      <div className="modal" tabIndex={-1} id="qrpayment_modal" ref={modalRef1}>
        <div className="modal-dialog modal-dialog-centered modal-content p-0">
          <div className="card w-100 p-10">
            <div className="modal-header px-0 pt-0">
              <h4 className="modal-title">QR Receipt</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            {/* end::Header */}

            <div className="card-body p-0 w-100">
              <div className="d-flex flex-column">
                <div className="card shadow-sm p-5">
                  <div className="d-flex justify-content-center">
                    <img
                      src={QRcode}
                      alt="Payment QR Code"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Amount ({data?.currency})</p>
                    </div>
                    <div>
                      <p className="fw-bold">
                        {data?.currency} {data?.amount}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Date of Payment</p>
                    </div>
                    <div>
                      <p className="fw-bold">
                        {new Date(data.transactionDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Bank Refrence ID</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.bankRefNo}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Payment ID</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.paymentId}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>VPA</p>
                    </div>
                    <div>
                      <p className="fw-bold">{data?.sender}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p>Status</p>
                    </div>
                    <div>
                      <p
                        className={`badge badge-light-${
                          data.status === "Paid" ? "success" : "danger"
                        }`}
                      >
                        {data.status == "Paid" ? "Paid" : "Failed"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn rounded"
                  type="button"
                  style={{
                    width: "380px",
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
