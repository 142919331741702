// VideoComponent.js
import React, { useState, useEffect } from "react";
import Play from "../../../_metronic/assets/all-serviceIcons/play-button.png";
import { Video } from "./VideoLibrary";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  video: Video;
  handleViewClick: (url: string) => void;
  videoCount: (videoGuid: string) => void;
}

interface ThumbnailUrl {
  uri: string;
}

const VideoComponent: React.FC<Props> = ({
  video,
  handleViewClick,
  videoCount,
}) => {
  const [timestamp, setTimestamp] = useState("");

  useEffect(() => {
    const getTimestamp = async () => {
      const apiKey = "AIzaSyAoz4wAERLqa3luOe187jfSN3hSGaDHlzc";
      const videoIdMatch = video.videoUrl.match(/[?&]v=([^&]+)/);

      if (!videoIdMatch || videoIdMatch.length < 2) {
        console.error("Invalid YouTube video URL");
        setTimestamp("0:00 mins");
        return;
      }

      const videoId = videoIdMatch[1];
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=contentDetails`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const duration = data.items[0].contentDetails.duration;

        // Extract minutes and seconds
        const matches = duration.match(/PT(?:(\d+)M)?(?:(\d+)S)?/);

        if (matches) {
          const [, minutes, seconds] = matches;
          // Format the duration
          const formattedMinutes = minutes ? `${minutes}:` : "0:";
          const formattedSeconds = seconds ? seconds.padStart(2, "0") : "00";
          const formattedDuration = `${formattedMinutes}${formattedSeconds} mins`;
          setTimestamp(formattedDuration);
        } else {
          // Handle invalid duration format
          console.error("Invalid duration format");
          setTimestamp("0:00 mins");
        }
      } catch (error) {
        console.error("Error fetching video timestamp:", error);
        setTimestamp("0:00 mins");
      }
    };

    getTimestamp();
  }, [video.videoUrl]);

  const [thumbnailUrl, setThumbnailUrl] = useState<ThumbnailUrl | null>(null);

  useEffect(() => {
    const downloadThumbnail = async () => {
      const videoId = extractVideoId(video.videoUrl);
      if (videoId) {
        const downloadedUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        const downloadedImagePath = await downloadImageFunction(downloadedUrl);
        setThumbnailUrl({ uri: downloadedImagePath });
      }
    };

    downloadThumbnail();
  }, [video.videoUrl]);

  const extractVideoId = (url: string): string | null => {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|watch\?v=))([a-zA-Z0-9_-]{11})/
    );
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const downloadImageFunction = async (url: string): Promise<string> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return url; // Simulate the download by returning the URL after a delay
  };
  return (
    <div
      className="d-flex flex-column ms-5 me-4 card p-3 position-relative mb-6"
      style={{
        height: "230px",
        width: "230px",
        borderRadius: "10px",
        // backgroundColor: "#C0C0C0", // Assuming hoveredIndex is not used here
        // transition: "background-color 0.3s ease",
      }}
    >
      <div
        className="bg-light"
        style={{ height: "150px", width: "210px", borderRadius: "10px" }}
      >
        <div
          className="image-input image-input-outline overlay overflow-hidden shadow"
          style={{ width: "210px", height: "150px", borderRadius: "10px" }}
        >
          <div className="overlay-wrapper">
            <img
              src={thumbnailUrl?.uri}
              alt="Video Thumbnail"
              style={{
                width: "210px",
                height: "155px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            {/* <iframe
              width="210"
              height="155"
              style={{ borderRadius: "10px" }}
              src={
                video.videoUrl.includes("youtube.com")
                  ? video.videoUrl.replace("watch?v=", "embed/")
                  : video.videoUrl
              }
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe> */}
          </div>
          <div className="overlay-layer bg-dark bg-opacity-25 align-items-center">
            <div className="d-flex flex-grow-1 flex-center py-5 justify-content-evenly">
              <img
                src={Play}
                onClick={() => {
                  handleViewClick(video.videoUrl);
                  videoCount(video.videoGuid);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-dark m-0 fw-bold fs-6 mt-2">
          {video?.videoName.length > 20
            ? `${video?.videoName.slice(0, 20)}...`
            : video?.videoName}
        </p>
        <>
          {" "}
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={
              <Popover
                className="p-2 bg-light-secondary"
                id="popover-trigger-focus"
              >
                {video?.videoDesc}
              </Popover>
            }
          >
            <p className="text-gray-800 mb-1 fs-7 cursor-pointer">
              {video?.videoDesc.length > 28
                ? `${video?.videoDesc.slice(0, 28)}...`
                : video?.videoDesc}
            </p>
          </OverlayTrigger>
        </>
        <p className="text-primary fs-8 m-0">{timestamp}</p>
      </div>
    </div>
  );
};

export default VideoComponent;
