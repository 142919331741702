import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { docUpload, getAllData, uploadSignature } from "./api";
import { useFormCompletion } from "./Stepper";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

interface FormValues {
  signature: File | null;
}

const Signature = () => {
  const [loading, setLoading] = useState(false);
  const { regUser, setRegUser } = useAuth();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const [showInput, setShowInput] = React.useState(false);
  const { markFormCompleted, resetCompletedForms } = useFormCompletion();

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      signature: null,
    },
    validateOnChange:false,
    validateOnBlur:false,
    validationSchema: Yup.object().shape({
      signature: Yup.mixed().nullable()
        .test('fileType', 'Only images are allowed', (value) => {
          if (!value) return true;
          const file = value as File;
          const validExtensions = [
            '.jpg',
            '.jpeg',
            '.png',
            '.svg',
            '.gif',
            '.bmp',
            '.webp',
            '.tiff',
          ];
          const extension = file.name
            ? file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
            : '';
          return validExtensions.includes(extension);
        })
        .test("required", function (value) {

          if (regUser?.reapply) {
            if (regUser?.reapplyParams?.signatureDocument) {
              return !!value || this.createError({ message: "Please upload a valid signature image" });
            }
            if (formik?.values?.signature === undefined || null) {
              return this.createError({ message: "Signature is required" });
            }
            // return true;
          }
          return !!value || this.createError({ message: "Signature is required" });
        }),
    }),

    onSubmit: async (values, { setSubmitting, setFieldValue, resetForm }) => {
      setLoading(true);  // Start loading
      setSubmitting(true);  // Indicate form is being submitted

      try {
        if (!regUser?.reapply && formik.values) {
          const res = await uploadSignature(values?.signature, regUser?.token ?? "");

          if (res?.data?.status?.statusCode === 0) {
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }

            markFormCompleted("signature");

            if (regUser?.noOfDirectors && Number(regUser.noOfDirectors) > 1) {
              navigate("/auth/secondary");
            } else {
              const result = await Swal.fire({
                icon: "info",
                title: "Application under review",
                text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
                showCancelButton: false,
                confirmButtonColor: "#007bff",
                confirmButtonText: "Proceed",
                allowEscapeKey: true,
                allowEnterKey: true,
              });

              if (result.isConfirmed) navigate("/auth");
            }

            resetForm();
          } else {
            setFieldValue("status", res?.data?.status?.messageDescription);
          }
        } else if (regUser?.reapply && formik?.values?.signature) {
          const res = await uploadSignature(values?.signature, regUser?.token ?? "");

          if (res?.data?.status?.statusCode === 0) {
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }

            markFormCompleted("signature");

            if (regUser?.noOfDirectors && Number(regUser.noOfDirectors) > 1) {
              navigate("/auth/secondary");
            } else {
              const result = await Swal.fire({
                icon: "info",
                title: "Application under review",
                text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
                showCancelButton: false,
                confirmButtonColor: "#007bff",
                confirmButtonText: "Proceed",
                allowEscapeKey: true,
                allowEnterKey: true,
              });

              if (result.isConfirmed) navigate("/auth");
            }

            resetForm();
          } else {
            setFieldValue("status", res?.data?.status?.messageDescription);
          }
        }
        else if (regUser?.noOfDirectors && Number(regUser.noOfDirectors) > 1) {
          markFormCompleted("signature");
          navigate("/auth/secondary");
        }

        else {
          markFormCompleted("signature");
          const result = await Swal.fire({
            icon: "info",
            title: "Application under review",
            text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Proceed",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          navigate("/auth");
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);  // Stop loading
        setSubmitting(false);  // Indicate form submission is complete
        setShowInput(false)
      }
    }


  });
  //

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, [])

  const handleCancelSignature = () => {
    setShowInput(true);
    formik.setFieldValue("signature", null);
    const fileInput = document.getElementById(
      "signatureFile"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  useEffect(() => {
    // Ensure regUser and businessDocs exist and fetch only if data is available
    if (regUser?.signatureDocument && regUser?.signatureDocument?.documentPath && !regUser?.reapplyParams?.signatureDocument) {
      const inputId = "signatureFile"; // License
      const formikField = "signature";
  
      const fileUrl = regUser?.signatureDocument?.documentPath;
  
      // Fetch the file and simulate file selection for the specific input
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = `${regUser?.signatureDocument?.documentName || "document"}.${regUser?.signatureDocument?.documentExtention || "pdf"}`;
          const file = new File([blob], fileName, { type: blob.type });
  
          const fileInput = document.getElementById(inputId) as HTMLInputElement;
          if (fileInput) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            fileInput.files = dataTransfer.files;
  
            const event = new Event("change", { bubbles: true });
            fileInput.dispatchEvent(event);
  
            // Set the file in Formik for the respective field
            formik.setFieldValue(formikField, file);
          }
        })
        .catch((error) => {
          console.error("Error fetching file: ", error);
        });
    }
  }, [getAllData]);

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">Please upload your signature</p>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="p-10">
        <div className={`mb-10 ${regUser?.reapplyParams?.signatureDocument ? "form-control is-invalid" : ""}`}>
            <label className="form-label">
              Sign on a white paper with proper lighting and take a picture to upload.
              {regUser?.reapplyParams?.signatureDocument && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.signatureDocument}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              )}
            </label>

            {/* Show preview if reapply is true and file exists */}
            {formik.values?.signature && !showInput ? (
              <div className="d-flex flex-start">
                <div className="position-relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "20px",
                    }}
                    onClick={handleCancelSignature} // Cancel preview to allow re-upload
                  >
                    &times;
                  </span>

                  <img
                    src={
                      formik?.values?.signature?.type === "application/pdf"
                        ? toAbsoluteUrl("/media/subicons/pdf.png")
                        : formik?.values?.signature
                          ? URL.createObjectURL(formik?.values?.signature)
                          : ""
                    }
                    onClick={() => {
                      const fileUrl = formik?.values?.signature
                        ? URL.createObjectURL(formik.values.signature)
                        : "";
                      const isPdf =
                        formik?.values?.signature?.type === "application/pdf";
                      const extension = isPdf
                        ? "pdf"
                        : formik?.values?.signature?.name?.split(".").pop() || "";
                      handlePreview(fileUrl, extension); // Open modal to preview file
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="mt-2"
                    alt="Signature"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      borderRadius: "5%",
                    }}
                  />
                </div>
              </div>
            ) : (
              <input
                type="file"
                ref={fileInputRef}
                id="signatureFile"
                className={`form-control ${formik.errors.signature ? "is-invalid" : ""
                  }`}
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0];
                  formik.setFieldValue("signature", file);
                  if(!regUser?.reapply){
                    setShowInput(false);
                  }
                }}
                accept="image/*,.pdf"
              />
            )}

            {/* Show validation error */}
            {formik.touched.signature && formik.errors.signature && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.signature}</span>
                </div>
              </div>
            )}
          </div>

        </div>
        <div className="p-10">
          <button type="submit" className="btn w-100 mt-15" disabled={formik.isSubmitting || loading}>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="" role="status" aria-hidden="true"></span>
              </div>
            ) : (
              <div>Proceed</div>
            )}
          </button>
        </div>
      </form>
      <div
        className="modal fade w-100"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered w-100"
          role="document"
        >
          {previewSrc && (
            <>
              {extension === "pdf" ? (
                <div
                  className="modal-content w-100 p-0 m-0"
                  style={{ position: "absolute", right: "50%" }}
                >
                  <div className="modal-body p-0 m-0">
                    <iframe
                      src={previewSrc}
                      title="PDF Preview"
                      style={{ width: "200%", height: "80vh" }} // Adjust width to 100%
                    ></iframe>
                  </div>
                </div>
              ) : (
                <img
                  src={previewSrc}
                  alt="Document Preview"
                  style={{
                    width: "300px", // Optional: set width to 100%
                    height: "auto", // Optional: maintain aspect ratio
                    borderRadius: "10px",
                    objectFit: "fill",
                    marginLeft: "20%",
                    // maxWidth:"200px"
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signature;
