import { FC, useState } from "react";
import { ScheduleReceipt, TxnData } from "../../interface/Interface";
import jsPDF from "jspdf";

interface TxnReceiptProps {
  data: ScheduleReceipt;
}

export const ScheduleTxnReceipt: FC<TxnReceiptProps> = ({ data }) => {
  console.log(data);

  const handleDownloadClick = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.setFont("bold");

    let yPosition = 20;
    const lineHeight = 10;
    const keyWidth = 50; // Width for the keys

    doc.text("Transaction Receipt", 105, yPosition, { align: "center" });

    yPosition += lineHeight * 2;
    doc.setFontSize(12);
    doc.setFont("normal");

    Object.entries(data).forEach(([key, value]) => {
      const keyText = `${capitalizeFirstLetter(key)}:`;
      const valueText = value.toString(); // Ensure the value is a string
      doc.text(keyText, 20, yPosition);
      doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
      yPosition += lineHeight;
    });

    doc.save("txn-details.pdf");
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <div
        id="kt_schedule_receipt_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_schedule_receipt_modal_toggle"
        data-kt-drawer-close="#kt_modal_receipt_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_receipt_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-dark p-0">E-Receipt</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="card shadow-sm p-5">
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p>Amount</p>
                  </div>
                  <div>
                    <p className="fw-bold">{data?.Amount}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p className="text-nowrap">Payee Name</p>
                  </div>
                  <div>
                    <p className="fw-bold">{data?.["Payee Name"]}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p>Account Number</p>
                  </div>
                  <div>
                    <p className="fw-bold">{data?.["Bank Account"]}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p>Date</p>
                  </div>
                  <div>
                    <p className="fw-bold">
                      {data?.["Schedule Date"]?.split(" ")[0]}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p>Time</p>
                  </div>
                  <div>
                    <p className="fw-bold">
                      {data?.["Schedule Date"]?.split(" ")[1]}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <p>Status</p>
                  </div>
                  <div>
                    <p
                      className={`badge badge-${
                        data?.Status === "SCHEDULED"
                          ? "primary"
                          : data?.Status === "COMPLETED"
                          ? "success"
                          : data?.Status === "FAILED"
                          ? "danger"
                          : ""
                      }`}
                    >
                      {data?.Status}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn rounded"
                style={{
                  width: "380px",
                }}
                onClick={() => handleDownloadClick()}
              >
                Download E-Receipt
              </button>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
};
