export interface IAppDuration {
  stmntDuration: string;
}

export interface IAppFromDate {
  fromDate: string;
}

export interface IAppToDate {
  toDate: string;
}

export interface IAppAccount {
  account: string;
}

export interface IAppCurrency {
  currency: string;
}

export interface IAppCard {
  card: string[];
}

export interface IAppDirection {
  direction: string;
}

export interface IAppTxnType {
  txnType: string[];
}

export interface IAppSubmit {
  submit: () => void;
}

export interface ICreateAppData {
  stmntDuration: IAppDuration;
  fromDate: IAppFromDate;
  toDate: IAppToDate;
  account: IAppAccount;
  currency: IAppCurrency;
  card: IAppCard;
  direction: IAppDirection;
  txnType: IAppTxnType;
  submit: IAppSubmit;
}

export const defaultCreateAppData: ICreateAppData = {
  stmntDuration: { stmntDuration: "" },
  fromDate: { fromDate: "" },
  toDate: { toDate: "" },
  account: { account: "" },
  currency: { currency: "" },
  card: { card: [] },
  direction: { direction: "" },
  txnType: { txnType: [] },
  submit: { submit: () => {} },
};

export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  hasError: boolean;
  reset: boolean;
  setSkip: (skip: boolean) => void;
};
