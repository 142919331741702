export const sameBank = {
  payeeAccountNumber: "",
  currency: "",
  payeeFirstName: "",
  payeeLastName: "",
  payeeSubType: "",
  payeeAlias: "",
  payeeType: "",
  payeeLimit: "",
  payeeCategory: "",
};

export const diffBank = {
  payeeAccountNumber: "",
  currency: "",
  payeeFirstName: "",
  payeeLastName: "",
  payeeSubType: "",
  payeeAlias: "",
  payeeType: "", //1 same bank 2 different bank 3 remittance 4 wallet
  payeeLimit: "",
  payeeCategory: "", //1 individual 2 business
  businessName: "",
  payeeBank: "",
  payeeBic: "",
  payeeBranchCode: "",
};

export const international = {
  payeeFirstName: "",
  payeeLastName: "",
  payeeAlias: "",
  payeeType: "", //1 same bank 2 different bank 3 remittance 4 wallet
  payeeLimit: "",
 currency: "",
  payeeCategory: "", //1 individual 2 business
  businessName: "",
  isdCode: "",
  mobileNumber: "",
  payeeCountry: "",
  idType: "",
  idNumber: "",
  address: "",
  city: "",
  state: "",
  payeeDob: "",
  fundType: "", // 1 bank 2 wallet for international remittance
  payeeBank: "",
  payeeAccountNumber: "",
  payeeBic: "",
};

export const wallet = {
  currency: "",
  payeeFirstName: "",
  payeeLastName: "",
  payeeAlias: "",
  payeeType: "", //1 same bank 2 different bank 3 remittance 4 wallet
  payeeLimit: "",
  payeeCategory: "", //1 individual 2 business
  payeeCountry: "",
  payeeMobile: "",
};

export const countries = [
  {
    "CLDR display name": "Taiwan",
    Capital: "Taipei",
    Continent: "AS",
    DS: "RC",
    "Developed / Developing Countries": null,
    Dial: "886",
    EDGAR: null,
    FIFA: "TPE",
    FIPS: "TW",
    GAUL: "925",
    "Geoname ID": 1668284,
    "Global Code": null,
    "Global Name": null,
    IOC: "TPE",
    "ISO3166-1-Alpha-2": "TW",
    "ISO3166-1-Alpha-3": "TWN",
    "ISO3166-1-numeric": "158",
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name": null,
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": null,
    "ISO4217-currency_numeric_code": null,
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "zh-TW,zh,nan,hak",
    "Least Developed Countries (LDC)": null,
    M49: null,
    MARC: "ch",
    "Region Code": null,
    "Region Name": null,
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": null,
    "Sub-region Name": null,
    TLD: ".tw",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Yes",
    official_name_ar: null,
    official_name_cn: null,
    official_name_en: null,
    official_name_es: null,
    official_name_fr: null,
    official_name_ru: null,
  },
  {
    "CLDR display name": "Afghanistan",
    Capital: "Kabul",
    Continent: "AS",
    DS: "AFG",
    "Developed / Developing Countries": "Developing",
    Dial: "93",
    EDGAR: "B2",
    FIFA: "AFG",
    FIPS: "AF",
    GAUL: "1",
    "Geoname ID": 1149361,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AFG",
    "ISO3166-1-Alpha-2": "AF",
    "ISO3166-1-Alpha-3": "AFG",
    "ISO3166-1-numeric": "004",
    "ISO4217-currency_alphabetic_code": "AFN",
    "ISO4217-currency_country_name": "AFGHANISTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Afghani",
    "ISO4217-currency_numeric_code": "971",
    ITU: "AFG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fa-AF,ps,uz-AF,tk",
    "Least Developed Countries (LDC)": "x",
    M49: 4,
    MARC: "af",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".af",
    "UNTERM Arabic Formal": "جمهورية أفغانستان الإسلامية",
    "UNTERM Arabic Short": "أفغانستان",
    "UNTERM Chinese Formal": "阿富汗伊斯兰共和国",
    "UNTERM Chinese Short": "阿富汗",
    "UNTERM English Formal": "the Islamic Republic of Afghanistan",
    "UNTERM English Short": "Afghanistan",
    "UNTERM French Formal": "République islamique d'Afghanistan",
    "UNTERM French Short": "Afghanistan (l') [masc.]",
    "UNTERM Russian Formal": "Исламская Республика Афганистан",
    "UNTERM Russian Short": "Афганистан",
    "UNTERM Spanish Formal": "República Islámica del Afganistán (la)",
    "UNTERM Spanish Short": "Afganistán (el)",
    WMO: "AF",
    is_independent: "Yes",
    official_name_ar: "أفغانستان",
    official_name_cn: "阿富汗",
    official_name_en: "Afghanistan",
    official_name_es: "Afganistán",
    official_name_fr: "Afghanistan",
    official_name_ru: "Афганистан",
  },
  {
    "CLDR display name": "Albania",
    Capital: "Tirana",
    Continent: "EU",
    DS: "AL",
    "Developed / Developing Countries": "Developed",
    Dial: "355",
    EDGAR: "B3",
    FIFA: "ALB",
    FIPS: "AL",
    GAUL: "3",
    "Geoname ID": 783754,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ALB",
    "ISO3166-1-Alpha-2": "AL",
    "ISO3166-1-Alpha-3": "ALB",
    "ISO3166-1-numeric": "008",
    "ISO4217-currency_alphabetic_code": "ALL",
    "ISO4217-currency_country_name": "ALBANIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lek",
    "ISO4217-currency_numeric_code": "008",
    ITU: "ALB",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sq,el",
    "Least Developed Countries (LDC)": null,
    M49: 8,
    MARC: "aa",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".al",
    "UNTERM Arabic Formal": "جمهورية ألبانيا",
    "UNTERM Arabic Short": "ألبانيا",
    "UNTERM Chinese Formal": "阿尔巴尼亚共和国",
    "UNTERM Chinese Short": "阿尔巴尼亚",
    "UNTERM English Formal": "the Republic of Albania",
    "UNTERM English Short": "Albania",
    "UNTERM French Formal": "la République d'Albanie",
    "UNTERM French Short": "Albanie (l') [fém.]",
    "UNTERM Russian Formal": "Республика Албания",
    "UNTERM Russian Short": "Албания",
    "UNTERM Spanish Formal": "la República de Albania",
    "UNTERM Spanish Short": "Albania",
    WMO: "AB",
    is_independent: "Yes",
    official_name_ar: "ألبانيا",
    official_name_cn: "阿尔巴尼亚",
    official_name_en: "Albania",
    official_name_es: "Albania",
    official_name_fr: "Albanie",
    official_name_ru: "Албания",
  },
  {
    "CLDR display name": "Algeria",
    Capital: "Algiers",
    Continent: "AF",
    DS: "DZ",
    "Developed / Developing Countries": "Developing",
    Dial: "213",
    EDGAR: "B4",
    FIFA: "ALG",
    FIPS: "AG",
    GAUL: "4",
    "Geoname ID": 2589581,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ALG",
    "ISO3166-1-Alpha-2": "DZ",
    "ISO3166-1-Alpha-3": "DZA",
    "ISO3166-1-numeric": "012",
    "ISO4217-currency_alphabetic_code": "DZD",
    "ISO4217-currency_country_name": "ALGERIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Algerian Dinar",
    "ISO4217-currency_numeric_code": "012",
    ITU: "ALG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-DZ",
    "Least Developed Countries (LDC)": null,
    M49: 12,
    MARC: "ae",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".dz",
    "UNTERM Arabic Formal": "الجمهورية الجزائرية الديمقراطية الشعبية",
    "UNTERM Arabic Short": "الجزائر",
    "UNTERM Chinese Formal": "阿尔及利亚民主人民共和国",
    "UNTERM Chinese Short": "阿尔及利亚",
    "UNTERM English Formal": "the People's Democratic Republic of Algeria",
    "UNTERM English Short": "Algeria",
    "UNTERM French Formal":
      "la République algérienne démocratique et populaire",
    "UNTERM French Short": "Algérie (l') [fém.]",
    "UNTERM Russian Formal": "Алжирская Народная Демократическая Республика",
    "UNTERM Russian Short": "Алжир",
    "UNTERM Spanish Formal": "la República Argelina Democrática y Popular",
    "UNTERM Spanish Short": "Argelia",
    WMO: "AL",
    is_independent: "Yes",
    official_name_ar: "الجزائر",
    official_name_cn: "阿尔及利亚",
    official_name_en: "Algeria",
    official_name_es: "Argelia",
    official_name_fr: "Algérie",
    official_name_ru: "Алжир",
  },
  {
    "CLDR display name": "American Samoa",
    Capital: "Pago Pago",
    Continent: "OC",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: "1-684",
    EDGAR: "B5",
    FIFA: "ASA",
    FIPS: "AQ",
    GAUL: "5",
    "Geoname ID": 5880801,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ASA",
    "ISO3166-1-Alpha-2": "AS",
    "ISO3166-1-Alpha-3": "ASM",
    "ISO3166-1-numeric": "016",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "AMERICAN SAMOA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "SMA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-AS,sm,to",
    "Least Developed Countries (LDC)": null,
    M49: 16,
    MARC: "as",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".as",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of US",
    official_name_ar: "ساموا الأمريكية",
    official_name_cn: "美属萨摩亚",
    official_name_en: "American Samoa",
    official_name_es: "Samoa Americana",
    official_name_fr: "Samoa américaines",
    official_name_ru: "Американское Самоа",
  },
  {
    "CLDR display name": "Andorra",
    Capital: "Andorra la Vella",
    Continent: "EU",
    DS: "AND",
    "Developed / Developing Countries": "Developed",
    Dial: "376",
    EDGAR: "B6",
    FIFA: "AND",
    FIPS: "AN",
    GAUL: "7",
    "Geoname ID": 3041565,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AND",
    "ISO3166-1-Alpha-2": "AD",
    "ISO3166-1-Alpha-3": "AND",
    "ISO3166-1-numeric": "020",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "ANDORRA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "AND",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ca",
    "Least Developed Countries (LDC)": null,
    M49: 20,
    MARC: "an",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".ad",
    "UNTERM Arabic Formal": "إمارة أندورا",
    "UNTERM Arabic Short": "أندورا",
    "UNTERM Chinese Formal": "安道尔公国",
    "UNTERM Chinese Short": "安道尔",
    "UNTERM English Formal": "the Principality of Andorra",
    "UNTERM English Short": "Andorra",
    "UNTERM French Formal": "la Principauté d'Andorre",
    "UNTERM French Short": "Andorre (l') [fém.]",
    "UNTERM Russian Formal": "Княжество Андорра",
    "UNTERM Russian Short": "Андорра",
    "UNTERM Spanish Formal": "el Principado de Andorra",
    "UNTERM Spanish Short": "Andorra",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "أندورا",
    official_name_cn: "安道尔",
    official_name_en: "Andorra",
    official_name_es: "Andorra",
    official_name_fr: "Andorre",
    official_name_ru: "Андорра",
  },
  {
    "CLDR display name": "Angola",
    Capital: "Luanda",
    Continent: "AF",
    DS: "AO",
    "Developed / Developing Countries": "Developing",
    Dial: "244",
    EDGAR: "B7",
    FIFA: "ANG",
    FIPS: "AO",
    GAUL: "8",
    "Geoname ID": 3351879,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ANG",
    "ISO3166-1-Alpha-2": "AO",
    "ISO3166-1-Alpha-3": "AGO",
    "ISO3166-1-numeric": "024",
    "ISO4217-currency_alphabetic_code": "AOA",
    "ISO4217-currency_country_name": "ANGOLA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Kwanza",
    "ISO4217-currency_numeric_code": "973",
    ITU: "AGL",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-AO",
    "Least Developed Countries (LDC)": "x",
    M49: 24,
    MARC: "ao",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ao",
    "UNTERM Arabic Formal": "جمهورية أنغولا",
    "UNTERM Arabic Short": "أنغولا",
    "UNTERM Chinese Formal": "安哥拉共和国",
    "UNTERM Chinese Short": "安哥拉",
    "UNTERM English Formal": "the Republic of Angola",
    "UNTERM English Short": "Angola",
    "UNTERM French Formal": "la République d'Angola",
    "UNTERM French Short": "Angola (l') [masc.]",
    "UNTERM Russian Formal": "Республика Ангола",
    "UNTERM Russian Short": "Ангола",
    "UNTERM Spanish Formal": "la República de Angola",
    "UNTERM Spanish Short": "Angola",
    WMO: "AN",
    is_independent: "Yes",
    official_name_ar: "أنغولا",
    official_name_cn: "安哥拉",
    official_name_en: "Angola",
    official_name_es: "Angola",
    official_name_fr: "Angola",
    official_name_ru: "Ангола",
  },
  {
    "CLDR display name": "Anguilla",
    Capital: "The Valley",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "1-264",
    EDGAR: "1A",
    FIFA: "AIA",
    FIPS: "AV",
    GAUL: "9",
    "Geoname ID": 3573511,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AIA",
    "ISO3166-1-Alpha-2": "AI",
    "ISO3166-1-Alpha-3": "AIA",
    "ISO3166-1-numeric": "660",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "ANGUILLA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "AIA",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-AI",
    "Least Developed Countries (LDC)": null,
    M49: 660,
    MARC: "am",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ai",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of GB",
    official_name_ar: "أنغيلا",
    official_name_cn: "安圭拉",
    official_name_en: "Anguilla",
    official_name_es: "Anguila",
    official_name_fr: "Anguilla",
    official_name_ru: "Ангилья",
  },
  {
    "CLDR display name": "Antarctica",
    Capital: null,
    Continent: "AN",
    DS: null,
    "Developed / Developing Countries": null,
    Dial: "672",
    EDGAR: "B8",
    FIFA: "ROS",
    FIPS: "AY",
    GAUL: "10",
    "Geoname ID": 6697173,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "AQ",
    "ISO3166-1-Alpha-3": "ATA",
    "ISO3166-1-numeric": "010",
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name": "ANTARCTICA",
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": "No universal currency",
    "ISO4217-currency_numeric_code": null,
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: null,
    "Least Developed Countries (LDC)": null,
    M49: 10,
    MARC: "ay",
    "Region Code": null,
    "Region Name": null,
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": null,
    "Sub-region Name": null,
    TLD: ".aq",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "AA",
    is_independent: "International",
    official_name_ar: "أنتاركتيكا",
    official_name_cn: "南极洲",
    official_name_en: "Antarctica",
    official_name_es: "Antártida",
    official_name_fr: "Antarctique",
    official_name_ru: "Антарктике",
  },
  {
    "CLDR display name": "Antigua & Barbuda",
    Capital: "St. John's",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "1-268",
    EDGAR: "B9",
    FIFA: "ATG",
    FIPS: "AC",
    GAUL: "11",
    "Geoname ID": 3576396,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ANT",
    "ISO3166-1-Alpha-2": "AG",
    "ISO3166-1-Alpha-3": "ATG",
    "ISO3166-1-numeric": "028",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "ANTIGUA AND BARBUDA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "ATG",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-AG",
    "Least Developed Countries (LDC)": null,
    M49: 28,
    MARC: "aq",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ag",
    "UNTERM Arabic Formal": "أنتيغوا وبربودا",
    "UNTERM Arabic Short": "أنتيغوا وبربودا",
    "UNTERM Chinese Formal": "安提瓜和巴布达",
    "UNTERM Chinese Short": "安提瓜和巴布达",
    "UNTERM English Formal": "Antigua and Barbuda",
    "UNTERM English Short": "Antigua and Barbuda",
    "UNTERM French Formal": "Antigua-et-Barbuda [fém.]",
    "UNTERM French Short": "Antigua-et-Barbuda [fém.]",
    "UNTERM Russian Formal": "Антигуа и Барбуда",
    "UNTERM Russian Short": "Антигуа и Барбуда",
    "UNTERM Spanish Formal": "Antigua y Barbuda",
    "UNTERM Spanish Short": "Antigua y Barbuda",
    WMO: "AT",
    is_independent: "Yes",
    official_name_ar: "أنتيغوا وبربودا",
    official_name_cn: "安提瓜和巴布达",
    official_name_en: "Antigua and Barbuda",
    official_name_es: "Antigua y Barbuda",
    official_name_fr: "Antigua-et-Barbuda",
    official_name_ru: "Антигуа и Барбуда",
  },
  {
    "CLDR display name": "Argentina",
    Capital: "Buenos Aires",
    Continent: "SA",
    DS: "RA",
    "Developed / Developing Countries": "Developing",
    Dial: "54",
    EDGAR: "C1",
    FIFA: "ARG",
    FIPS: "AR",
    GAUL: "12",
    "Geoname ID": 3865483,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ARG",
    "ISO3166-1-Alpha-2": "AR",
    "ISO3166-1-Alpha-3": "ARG",
    "ISO3166-1-numeric": "032",
    "ISO4217-currency_alphabetic_code": "ARS",
    "ISO4217-currency_country_name": "ARGENTINA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Argentine Peso",
    "ISO4217-currency_numeric_code": "032",
    ITU: "ARG",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-AR,en,it,de,fr,gn",
    "Least Developed Countries (LDC)": null,
    M49: 32,
    MARC: "ag",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ar",
    "UNTERM Arabic Formal": "جمهورية الأرجنتين",
    "UNTERM Arabic Short": "الأرجنتين",
    "UNTERM Chinese Formal": "阿根廷共和国",
    "UNTERM Chinese Short": "阿根廷",
    "UNTERM English Formal": "the Argentine Republic",
    "UNTERM English Short": "Argentina",
    "UNTERM French Formal": "la République argentine",
    "UNTERM French Short": "Argentine (l') [fém.]",
    "UNTERM Russian Formal": "Аргентинская Республика",
    "UNTERM Russian Short": "Аргентина",
    "UNTERM Spanish Formal": "la República Argentina",
    "UNTERM Spanish Short": "Argentina (la)",
    WMO: "AG",
    is_independent: "Yes",
    official_name_ar: "الأرجنتين",
    official_name_cn: "阿根廷",
    official_name_en: "Argentina",
    official_name_es: "Argentina",
    official_name_fr: "Argentine",
    official_name_ru: "Аргентина",
  },
  {
    "CLDR display name": "Armenia",
    Capital: "Yerevan",
    Continent: "AS",
    DS: "AM",
    "Developed / Developing Countries": "Developing",
    Dial: "374",
    EDGAR: "1B",
    FIFA: "ARM",
    FIPS: "AM",
    GAUL: "13",
    "Geoname ID": 174982,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ARM",
    "ISO3166-1-Alpha-2": "AM",
    "ISO3166-1-Alpha-3": "ARM",
    "ISO3166-1-numeric": "051",
    "ISO4217-currency_alphabetic_code": "AMD",
    "ISO4217-currency_country_name": "ARMENIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Armenian Dram",
    "ISO4217-currency_numeric_code": "051",
    ITU: "ARM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "hy",
    "Least Developed Countries (LDC)": null,
    M49: 51,
    MARC: "ai",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".am",
    "UNTERM Arabic Formal": "جمهورية أرمينيا",
    "UNTERM Arabic Short": "أرمينيا",
    "UNTERM Chinese Formal": "亚美尼亚共和国",
    "UNTERM Chinese Short": "亚美尼亚",
    "UNTERM English Formal": "the Republic of Armenia",
    "UNTERM English Short": "Armenia",
    "UNTERM French Formal": "la République d'Arménie",
    "UNTERM French Short": "Arménie (l') [fém.]",
    "UNTERM Russian Formal": "Республика Армения",
    "UNTERM Russian Short": "Армения",
    "UNTERM Spanish Formal": "la República de Armenia",
    "UNTERM Spanish Short": "Armenia",
    WMO: "AY",
    is_independent: "Yes",
    official_name_ar: "أرمينيا",
    official_name_cn: "亚美尼亚",
    official_name_en: "Armenia",
    official_name_es: "Armenia",
    official_name_fr: "Arménie",
    official_name_ru: "Армения",
  },
  {
    "CLDR display name": "Aruba",
    Capital: "Oranjestad",
    Continent: "NA",
    DS: "AW",
    "Developed / Developing Countries": "Developing",
    Dial: "297",
    EDGAR: "1C",
    FIFA: "ARU",
    FIPS: "AA",
    GAUL: "14",
    "Geoname ID": 3577279,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ARU",
    "ISO3166-1-Alpha-2": "AW",
    "ISO3166-1-Alpha-3": "ABW",
    "ISO3166-1-numeric": "533",
    "ISO4217-currency_alphabetic_code": "AWG",
    "ISO4217-currency_country_name": "ARUBA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Aruban Florin",
    "ISO4217-currency_numeric_code": "533",
    ITU: "ABW",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl-AW,pap,es,en",
    "Least Developed Countries (LDC)": null,
    M49: 533,
    MARC: "aw",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".aw",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "NU",
    is_independent: "Part of NL",
    official_name_ar: "أروبا",
    official_name_cn: "阿鲁巴",
    official_name_en: "Aruba",
    official_name_es: "Aruba",
    official_name_fr: "Aruba",
    official_name_ru: "Аруба",
  },
  {
    "CLDR display name": "Australia",
    Capital: "Canberra",
    Continent: "OC",
    DS: "AUS",
    "Developed / Developing Countries": "Developed",
    Dial: "61",
    EDGAR: "C3",
    FIFA: "AUS",
    FIPS: "AS",
    GAUL: "17",
    "Geoname ID": 2077456,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AUS",
    "ISO3166-1-Alpha-2": "AU",
    "ISO3166-1-Alpha-3": "AUS",
    "ISO3166-1-numeric": "036",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "AUSTRALIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "AUS",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-AU",
    "Least Developed Countries (LDC)": null,
    M49: 36,
    MARC: "at",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".au",
    "UNTERM Arabic Formal": "أستراليا",
    "UNTERM Arabic Short": "أستراليا",
    "UNTERM Chinese Formal": "澳大利亚",
    "UNTERM Chinese Short": "澳大利亚",
    "UNTERM English Formal": "Australia",
    "UNTERM English Short": "Australia",
    "UNTERM French Formal": "l'Australie",
    "UNTERM French Short": "Australie (l') [fém.]",
    "UNTERM Russian Formal": "Австралия",
    "UNTERM Russian Short": "Австралия",
    "UNTERM Spanish Formal": "Australia",
    "UNTERM Spanish Short": "Australia",
    WMO: "AU",
    is_independent: "Yes",
    official_name_ar: "أستراليا",
    official_name_cn: "澳大利亚",
    official_name_en: "Australia",
    official_name_es: "Australia",
    official_name_fr: "Australie",
    official_name_ru: "Австралия",
  },
  {
    "CLDR display name": "Austria",
    Capital: "Vienna",
    Continent: "EU",
    DS: "A",
    "Developed / Developing Countries": "Developed",
    Dial: "43",
    EDGAR: "C4",
    FIFA: "AUT",
    FIPS: "AU",
    GAUL: "18",
    "Geoname ID": 2782113,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AUT",
    "ISO3166-1-Alpha-2": "AT",
    "ISO3166-1-Alpha-3": "AUT",
    "ISO3166-1-numeric": "040",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "AUSTRIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "AUT",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "de-AT,hr,hu,sl",
    "Least Developed Countries (LDC)": null,
    M49: 40,
    MARC: "au",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".at",
    "UNTERM Arabic Formal": "جمهورية النمسا",
    "UNTERM Arabic Short": "النمسا",
    "UNTERM Chinese Formal": "奥地利共和国",
    "UNTERM Chinese Short": "奥地利",
    "UNTERM English Formal": "the Republic of Austria",
    "UNTERM English Short": "Austria",
    "UNTERM French Formal": "la République d'Autriche",
    "UNTERM French Short": "Autriche (l') [fém.]",
    "UNTERM Russian Formal": "Австрийская Республика",
    "UNTERM Russian Short": "Австрия",
    "UNTERM Spanish Formal": "la República de Austria",
    "UNTERM Spanish Short": "Austria",
    WMO: "OS",
    is_independent: "Yes",
    official_name_ar: "النمسا",
    official_name_cn: "奥地利",
    official_name_en: "Austria",
    official_name_es: "Austria",
    official_name_fr: "Autriche",
    official_name_ru: "Австрия",
  },
  {
    "CLDR display name": "Azerbaijan",
    Capital: "Baku",
    Continent: "AS",
    DS: "AZ",
    "Developed / Developing Countries": "Developing",
    Dial: "994",
    EDGAR: "1D",
    FIFA: "AZE",
    FIPS: "AJ",
    GAUL: "19",
    "Geoname ID": 587116,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AZE",
    "ISO3166-1-Alpha-2": "AZ",
    "ISO3166-1-Alpha-3": "AZE",
    "ISO3166-1-numeric": "031",
    "ISO4217-currency_alphabetic_code": "AZN",
    "ISO4217-currency_country_name": "AZERBAIJAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Azerbaijan Manat",
    "ISO4217-currency_numeric_code": "944",
    ITU: "AZE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "az,ru,hy",
    "Least Developed Countries (LDC)": null,
    M49: 31,
    MARC: "aj",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".az",
    "UNTERM Arabic Formal": "جمهورية أذربيجان",
    "UNTERM Arabic Short": "أذربيجان",
    "UNTERM Chinese Formal": "阿塞拜疆共和国",
    "UNTERM Chinese Short": "阿塞拜疆",
    "UNTERM English Formal": "the Republic of Azerbaijan",
    "UNTERM English Short": "Azerbaijan",
    "UNTERM French Formal": "la République d'Azerbaïdjan",
    "UNTERM French Short": "Azerbaïdjan (l') [masc.]",
    "UNTERM Russian Formal": "Азербайджанская Республика",
    "UNTERM Russian Short": "Азербайджан",
    "UNTERM Spanish Formal": "la República de Azerbaiyán",
    "UNTERM Spanish Short": "Azerbaiyán",
    WMO: "AJ",
    is_independent: "Yes",
    official_name_ar: "أذربيجان",
    official_name_cn: "阿塞拜疆",
    official_name_en: "Azerbaijan",
    official_name_es: "Azerbaiyán",
    official_name_fr: "Azerbaïdjan",
    official_name_ru: "Азербайджан",
  },
  {
    "CLDR display name": "Bahamas",
    Capital: "Nassau",
    Continent: "NA",
    DS: "BS",
    "Developed / Developing Countries": "Developing",
    Dial: "1-242",
    EDGAR: "C5",
    FIFA: "BAH",
    FIPS: "BF",
    GAUL: "20",
    "Geoname ID": 3572887,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BAH",
    "ISO3166-1-Alpha-2": "BS",
    "ISO3166-1-Alpha-3": "BHS",
    "ISO3166-1-numeric": "044",
    "ISO4217-currency_alphabetic_code": "BSD",
    "ISO4217-currency_country_name": "BAHAMAS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Bahamian Dollar",
    "ISO4217-currency_numeric_code": "044",
    ITU: "BAH",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-BS",
    "Least Developed Countries (LDC)": null,
    M49: 44,
    MARC: "bf",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bs",
    "UNTERM Arabic Formal": "كمنولث جزر البهاما",
    "UNTERM Arabic Short": "جزر البهاما",
    "UNTERM Chinese Formal": "巴哈马国",
    "UNTERM Chinese Short": "巴哈马",
    "UNTERM English Formal": "the Commonwealth of the Bahamas",
    "UNTERM English Short": "Bahamas (the)",
    "UNTERM French Formal": "le Commonwealth des Bahamas",
    "UNTERM French Short": "Bahamas (les) [fém.]",
    "UNTERM Russian Formal": "Содружество Багамских Островов",
    "UNTERM Russian Short": "Багамские Острова",
    "UNTERM Spanish Formal": "el Commonwealth de las Bahamas",
    "UNTERM Spanish Short": "Bahamas (las)",
    WMO: "BA",
    is_independent: "Yes",
    official_name_ar: "جزر البهاما",
    official_name_cn: "巴哈马",
    official_name_en: "Bahamas",
    official_name_es: "Bahamas",
    official_name_fr: "Bahamas",
    official_name_ru: "Багамские Острова",
  },
  {
    "CLDR display name": "Bahrain",
    Capital: "Manama",
    Continent: "AS",
    DS: "BRN",
    "Developed / Developing Countries": "Developing",
    Dial: "973",
    EDGAR: "C6",
    FIFA: "BHR",
    FIPS: "BA",
    GAUL: "21",
    "Geoname ID": 290291,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BRN",
    "ISO3166-1-Alpha-2": "BH",
    "ISO3166-1-Alpha-3": "BHR",
    "ISO3166-1-numeric": "048",
    "ISO4217-currency_alphabetic_code": "BHD",
    "ISO4217-currency_country_name": "BAHRAIN",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Bahraini Dinar",
    "ISO4217-currency_numeric_code": "048",
    ITU: "BHR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-BH,en,fa,ur",
    "Least Developed Countries (LDC)": null,
    M49: 48,
    MARC: "ba",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".bh",
    "UNTERM Arabic Formal": "مملكة البحرين",
    "UNTERM Arabic Short": "البحرين",
    "UNTERM Chinese Formal": "巴林王国",
    "UNTERM Chinese Short": "巴林",
    "UNTERM English Formal": "the Kingdom of Bahrain",
    "UNTERM English Short": "Bahrain",
    "UNTERM French Formal": "le Royaume de Bahreïn",
    "UNTERM French Short": "Bahreïn [masc.]",
    "UNTERM Russian Formal": "Королевство Бахрейн",
    "UNTERM Russian Short": "Бахрейн",
    "UNTERM Spanish Formal": "el Reino de Bahrein",
    "UNTERM Spanish Short": "Bahrein",
    WMO: "BN",
    is_independent: "Yes",
    official_name_ar: "البحرين",
    official_name_cn: "巴林",
    official_name_en: "Bahrain",
    official_name_es: "Bahrein",
    official_name_fr: "Bahreïn",
    official_name_ru: "Бахрейн",
  },
  {
    "CLDR display name": "Bangladesh",
    Capital: "Dhaka",
    Continent: "AS",
    DS: "BD",
    "Developed / Developing Countries": "Developing",
    Dial: "880",
    EDGAR: "C7",
    FIFA: "BAN",
    FIPS: "BG",
    GAUL: "23",
    "Geoname ID": 1210997,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BAN",
    "ISO3166-1-Alpha-2": "BD",
    "ISO3166-1-Alpha-3": "BGD",
    "ISO3166-1-numeric": "050",
    "ISO4217-currency_alphabetic_code": "BDT",
    "ISO4217-currency_country_name": "BANGLADESH",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Taka",
    "ISO4217-currency_numeric_code": "050",
    ITU: "BGD",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "bn-BD,en",
    "Least Developed Countries (LDC)": "x",
    M49: 50,
    MARC: "bg",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".bd",
    "UNTERM Arabic Formal": "جمهورية بنغلاديش الشعبية",
    "UNTERM Arabic Short": "بنغلاديش",
    "UNTERM Chinese Formal": "孟加拉人民共和国",
    "UNTERM Chinese Short": "孟加拉国",
    "UNTERM English Formal": "the People's Republic of Bangladesh",
    "UNTERM English Short": "Bangladesh",
    "UNTERM French Formal": "la République populaire du Bangladesh",
    "UNTERM French Short": "Bangladesh (le)",
    "UNTERM Russian Formal": "Народная Республика Бангладеш",
    "UNTERM Russian Short": "Бангладеш",
    "UNTERM Spanish Formal": "la República Popular de Bangladesh",
    "UNTERM Spanish Short": "Bangladesh",
    WMO: "BW",
    is_independent: "Yes",
    official_name_ar: "بنغلاديش",
    official_name_cn: "孟加拉国",
    official_name_en: "Bangladesh",
    official_name_es: "Bangladesh",
    official_name_fr: "Bangladesh",
    official_name_ru: "Бангладеш",
  },
  {
    "CLDR display name": "Barbados",
    Capital: "Bridgetown",
    Continent: "NA",
    DS: "BDS",
    "Developed / Developing Countries": "Developing",
    Dial: "1-246",
    EDGAR: "C8",
    FIFA: "BRB",
    FIPS: "BB",
    GAUL: "24",
    "Geoname ID": 3374084,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BAR",
    "ISO3166-1-Alpha-2": "BB",
    "ISO3166-1-Alpha-3": "BRB",
    "ISO3166-1-numeric": "052",
    "ISO4217-currency_alphabetic_code": "BBD",
    "ISO4217-currency_country_name": "BARBADOS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Barbados Dollar",
    "ISO4217-currency_numeric_code": "052",
    ITU: "BRB",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-BB",
    "Least Developed Countries (LDC)": null,
    M49: 52,
    MARC: "bb",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bb",
    "UNTERM Arabic Formal": "بربادوس",
    "UNTERM Arabic Short": "بربادوس",
    "UNTERM Chinese Formal": "巴巴多斯",
    "UNTERM Chinese Short": "巴巴多斯",
    "UNTERM English Formal": "Barbados",
    "UNTERM English Short": "Barbados",
    "UNTERM French Formal": "la Barbade",
    "UNTERM French Short": "Barbade (la)",
    "UNTERM Russian Formal": "Барбадос",
    "UNTERM Russian Short": "Барбадос",
    "UNTERM Spanish Formal": "Barbados",
    "UNTERM Spanish Short": "Barbados",
    WMO: "BR",
    is_independent: "Yes",
    official_name_ar: "بربادوس",
    official_name_cn: "巴巴多斯",
    official_name_en: "Barbados",
    official_name_es: "Barbados",
    official_name_fr: "Barbade",
    official_name_ru: "Барбадос",
  },
  {
    "CLDR display name": "Belarus",
    Capital: "Minsk",
    Continent: "EU",
    DS: "BY",
    "Developed / Developing Countries": "Developed",
    Dial: "375",
    EDGAR: "1F",
    FIFA: "BLR",
    FIPS: "BO",
    GAUL: "26",
    "Geoname ID": 630336,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BLR",
    "ISO3166-1-Alpha-2": "BY",
    "ISO3166-1-Alpha-3": "BLR",
    "ISO3166-1-numeric": "112",
    "ISO4217-currency_alphabetic_code": "BYN",
    "ISO4217-currency_country_name": "BELARUS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Belarusian Ruble",
    "ISO4217-currency_numeric_code": "933",
    ITU: "BLR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "be,ru",
    "Least Developed Countries (LDC)": null,
    M49: 112,
    MARC: "bw",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".by",
    "UNTERM Arabic Formal": "جمهورية بيلاروس",
    "UNTERM Arabic Short": "بيلاروس",
    "UNTERM Chinese Formal": "白俄罗斯共和国",
    "UNTERM Chinese Short": "白俄罗斯",
    "UNTERM English Formal": "the Republic of Belarus",
    "UNTERM English Short": "Belarus",
    "UNTERM French Formal": "la République du Bélarus",
    "UNTERM French Short": "Bélarus (le)",
    "UNTERM Russian Formal": "Республика Беларусь",
    "UNTERM Russian Short": "Беларусь",
    "UNTERM Spanish Formal": "la República de Belarús",
    "UNTERM Spanish Short": "Belarús",
    WMO: "BY",
    is_independent: "Yes",
    official_name_ar: "بيلاروس",
    official_name_cn: "白俄罗斯",
    official_name_en: "Belarus",
    official_name_es: "Belarús",
    official_name_fr: "Bélarus",
    official_name_ru: "Беларусь",
  },
  {
    "CLDR display name": "Belgium",
    Capital: "Brussels",
    Continent: "EU",
    DS: "B",
    "Developed / Developing Countries": "Developed",
    Dial: "32",
    EDGAR: "C9",
    FIFA: "BEL",
    FIPS: "BE",
    GAUL: "27",
    "Geoname ID": 2802361,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BEL",
    "ISO3166-1-Alpha-2": "BE",
    "ISO3166-1-Alpha-3": "BEL",
    "ISO3166-1-numeric": "056",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "BELGIUM",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "BEL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl-BE,fr-BE,de-BE",
    "Least Developed Countries (LDC)": null,
    M49: 56,
    MARC: "be",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".be",
    "UNTERM Arabic Formal": "مملكة بلجيكا",
    "UNTERM Arabic Short": "بلجيكا",
    "UNTERM Chinese Formal": "比利时王国",
    "UNTERM Chinese Short": "比利时",
    "UNTERM English Formal": "the Kingdom of Belgium",
    "UNTERM English Short": "Belgium",
    "UNTERM French Formal": "le Royaume de Belgique",
    "UNTERM French Short": "Belgique (la)",
    "UNTERM Russian Formal": "Королевство Бельгия",
    "UNTERM Russian Short": "Бельгия",
    "UNTERM Spanish Formal": "el Reino de Bélgica",
    "UNTERM Spanish Short": "Bélgica",
    WMO: "BX",
    is_independent: "Yes",
    official_name_ar: "بلجيكا",
    official_name_cn: "比利时",
    official_name_en: "Belgium",
    official_name_es: "Bélgica",
    official_name_fr: "Belgique",
    official_name_ru: "Бельгия",
  },
  {
    "CLDR display name": "Belize",
    Capital: "Belmopan",
    Continent: "NA",
    DS: "BH",
    "Developed / Developing Countries": "Developing",
    Dial: "501",
    EDGAR: "D1",
    FIFA: "BLZ",
    FIPS: "BH",
    GAUL: "28",
    "Geoname ID": 3582678,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BIZ",
    "ISO3166-1-Alpha-2": "BZ",
    "ISO3166-1-Alpha-3": "BLZ",
    "ISO3166-1-numeric": "084",
    "ISO4217-currency_alphabetic_code": "BZD",
    "ISO4217-currency_country_name": "BELIZE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Belize Dollar",
    "ISO4217-currency_numeric_code": "084",
    ITU: "BLZ",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-BZ,es",
    "Least Developed Countries (LDC)": null,
    M49: 84,
    MARC: "bh",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bz",
    "UNTERM Arabic Formal": "بليز",
    "UNTERM Arabic Short": "بليز",
    "UNTERM Chinese Formal": "伯利兹",
    "UNTERM Chinese Short": "伯利兹",
    "UNTERM English Formal": "Belize",
    "UNTERM English Short": "Belize",
    "UNTERM French Formal": "le Belize",
    "UNTERM French Short": "Belize (le)",
    "UNTERM Russian Formal": "Белиз",
    "UNTERM Russian Short": "Белиз",
    "UNTERM Spanish Formal": "Belice",
    "UNTERM Spanish Short": "Belice",
    WMO: "BH",
    is_independent: "Yes",
    official_name_ar: "بليز",
    official_name_cn: "伯利兹",
    official_name_en: "Belize",
    official_name_es: "Belice",
    official_name_fr: "Belize",
    official_name_ru: "Белиз",
  },
  {
    "CLDR display name": "Benin",
    Capital: "Porto-Novo",
    Continent: "AF",
    DS: "DY",
    "Developed / Developing Countries": "Developing",
    Dial: "229",
    EDGAR: "G6",
    FIFA: "BEN",
    FIPS: "BN",
    GAUL: "29",
    "Geoname ID": 2395170,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BEN",
    "ISO3166-1-Alpha-2": "BJ",
    "ISO3166-1-Alpha-3": "BEN",
    "ISO3166-1-numeric": "204",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "BENIN",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "BEN",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-BJ",
    "Least Developed Countries (LDC)": "x",
    M49: 204,
    MARC: "dm",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".bj",
    "UNTERM Arabic Formal": "جمهورية بنن",
    "UNTERM Arabic Short": "بنن",
    "UNTERM Chinese Formal": "贝宁共和国",
    "UNTERM Chinese Short": "贝宁",
    "UNTERM English Formal": "the Republic of Benin",
    "UNTERM English Short": "Benin",
    "UNTERM French Formal": "la République du Bénin",
    "UNTERM French Short": "Bénin (le)",
    "UNTERM Russian Formal": "Республика Бенин",
    "UNTERM Russian Short": "Бенин",
    "UNTERM Spanish Formal": "la República de Benin",
    "UNTERM Spanish Short": "Benin",
    WMO: "BJ",
    is_independent: "Yes",
    official_name_ar: "بنن",
    official_name_cn: "贝宁",
    official_name_en: "Benin",
    official_name_es: "Benin",
    official_name_fr: "Bénin",
    official_name_ru: "Бенин",
  },
  {
    "CLDR display name": "Bermuda",
    Capital: "Hamilton",
    Continent: "NA",
    DS: "BM",
    "Developed / Developing Countries": "Developed",
    Dial: "1-441",
    EDGAR: "D0",
    FIFA: "BER",
    FIPS: "BD",
    GAUL: "30",
    "Geoname ID": 3573345,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BER",
    "ISO3166-1-Alpha-2": "BM",
    "ISO3166-1-Alpha-3": "BMU",
    "ISO3166-1-numeric": "060",
    "ISO4217-currency_alphabetic_code": "BMD",
    "ISO4217-currency_country_name": "BERMUDA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Bermudian Dollar",
    "ISO4217-currency_numeric_code": "060",
    ITU: "BER",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-BM,pt",
    "Least Developed Countries (LDC)": null,
    M49: 60,
    MARC: "bm",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "21",
    "Sub-region Name": "Northern America",
    TLD: ".bm",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "BE",
    is_independent: "Territory of GB",
    official_name_ar: "برمودا",
    official_name_cn: "百慕大",
    official_name_en: "Bermuda",
    official_name_es: "Bermuda",
    official_name_fr: "Bermudes",
    official_name_ru: "Бермудские острова",
  },
  {
    "CLDR display name": "Bhutan",
    Capital: "Thimphu",
    Continent: "AS",
    DS: "BT",
    "Developed / Developing Countries": "Developing",
    Dial: "975",
    EDGAR: "D2",
    FIFA: "BHU",
    FIPS: "BT",
    GAUL: "31",
    "Geoname ID": 1252634,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BHU",
    "ISO3166-1-Alpha-2": "BT",
    "ISO3166-1-Alpha-3": "BTN",
    "ISO3166-1-numeric": "064",
    "ISO4217-currency_alphabetic_code": "INR,BTN",
    "ISO4217-currency_country_name": "BHUTAN",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Indian Rupee,Ngultrum",
    "ISO4217-currency_numeric_code": "356,064",
    ITU: "BTN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "dz",
    "Least Developed Countries (LDC)": "x",
    M49: 64,
    MARC: "bt",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".bt",
    "UNTERM Arabic Formal": "مملكة بوتان",
    "UNTERM Arabic Short": "بوتان",
    "UNTERM Chinese Formal": "不丹王国",
    "UNTERM Chinese Short": "不丹",
    "UNTERM English Formal": "the Kingdom of Bhutan",
    "UNTERM English Short": "Bhutan",
    "UNTERM French Formal": "le Royaume du Bhoutan",
    "UNTERM French Short": "Bhoutan (le)",
    "UNTERM Russian Formal": "Королевство Бутан",
    "UNTERM Russian Short": "Бутан",
    "UNTERM Spanish Formal": "el Reino de Bhután",
    "UNTERM Spanish Short": "Bhután",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "بوتان",
    official_name_cn: "不丹",
    official_name_en: "Bhutan",
    official_name_es: "Bhután",
    official_name_fr: "Bhoutan",
    official_name_ru: "Бутан",
  },
  {
    "CLDR display name": "Bolivia",
    Capital: "Sucre",
    Continent: "SA",
    DS: "BOL",
    "Developed / Developing Countries": "Developing",
    Dial: "591",
    EDGAR: null,
    FIFA: "BOL",
    FIPS: "BL",
    GAUL: "33",
    "Geoname ID": 3923057,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BOL",
    "ISO3166-1-Alpha-2": "BO",
    "ISO3166-1-Alpha-3": "BOL",
    "ISO3166-1-numeric": "068",
    "ISO4217-currency_alphabetic_code": "BOB",
    "ISO4217-currency_country_name": "BOLIVIA (PLURINATIONAL STATE OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Boliviano",
    "ISO4217-currency_numeric_code": "068",
    ITU: "BOL",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "es-BO,qu,ay",
    "Least Developed Countries (LDC)": null,
    M49: 68,
    MARC: "bo",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bo",
    "UNTERM Arabic Formal": "دولة بوليفيا المتعددة القوميات",
    "UNTERM Arabic Short": "بوليفيا (دولة - المتعددة القوميات)",
    "UNTERM Chinese Formal": "玻利维亚多民族国",
    "UNTERM Chinese Short": "玻利维亚（多民族国）",
    "UNTERM English Formal": "the Plurinational State of Bolivia",
    "UNTERM English Short": "Bolivia (Plurinational State of)",
    "UNTERM French Formal": "État plurinational de Bolivie",
    "UNTERM French Short": "Bolivie (État plurinational de) (l')",
    "UNTERM Russian Formal": "Многонациональное Государство Боливия",
    "UNTERM Russian Short": "Боливия (Многонациональное Государство)",
    "UNTERM Spanish Formal": "Estado Plurinacional de Bolivia",
    "UNTERM Spanish Short": "Bolivia (Estado Plurinacional de)",
    WMO: "BO",
    is_independent: "Yes",
    official_name_ar: "بوليفيا (دولة - المتعددة القوميات)",
    official_name_cn: "玻利维亚(多民族国)",
    official_name_en: "Bolivia (Plurinational State of)",
    official_name_es: "Bolivia (Estado Plurinacional de)",
    official_name_fr: "Bolivie (État plurinational de)",
    official_name_ru: "Боливия (Многонациональное Государство)",
  },
  {
    "CLDR display name": "Caribbean Netherlands",
    Capital: null,
    Continent: "NA",
    DS: "NA",
    "Developed / Developing Countries": "Developing",
    Dial: "599",
    EDGAR: null,
    FIFA: "ANT",
    FIPS: "NL",
    GAUL: "176",
    "Geoname ID": 7626844,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "AHO",
    "ISO3166-1-Alpha-2": "BQ",
    "ISO3166-1-Alpha-3": "BES",
    "ISO3166-1-numeric": "535",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "BONAIRE, SINT EUSTATIUS AND SABA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "ATN",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl,pap,en",
    "Least Developed Countries (LDC)": null,
    M49: 535,
    MARC: "ca",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bq",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "NU",
    is_independent: "Part of NL",
    official_name_ar: "بونير وسان يوستاتيوس وسابا",
    official_name_cn: "博纳尔,圣俄斯塔休斯和萨巴",
    official_name_en: "Bonaire, Sint Eustatius and Saba",
    official_name_es: "Bonaire, San Eustaquio y Saba",
    official_name_fr: "Bonaire, Saint-Eustache et Saba",
    official_name_ru: "Бонайре, Саба и Синт-Эстатиус",
  },
  {
    "CLDR display name": "Bosnia",
    Capital: "Sarajevo",
    Continent: "EU",
    DS: "BIH",
    "Developed / Developing Countries": "Developed",
    Dial: "387",
    EDGAR: "1E",
    FIFA: "BIH",
    FIPS: "BK",
    GAUL: "34",
    "Geoname ID": 3277605,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BIH",
    "ISO3166-1-Alpha-2": "BA",
    "ISO3166-1-Alpha-3": "BIH",
    "ISO3166-1-numeric": "070",
    "ISO4217-currency_alphabetic_code": "BAM",
    "ISO4217-currency_country_name": "BOSNIA AND HERZEGOVINA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Convertible Mark",
    "ISO4217-currency_numeric_code": "977",
    ITU: "BIH",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "bs,hr-BA,sr-BA",
    "Least Developed Countries (LDC)": null,
    M49: 70,
    MARC: "bn",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".ba",
    "UNTERM Arabic Formal": "البوسنة والهرسك",
    "UNTERM Arabic Short": "البوسنة والهرسك",
    "UNTERM Chinese Formal": "波斯尼亚和黑塞哥维那",
    "UNTERM Chinese Short": "波斯尼亚和黑塞哥维那",
    "UNTERM English Formal": "Bosnia and Herzegovina",
    "UNTERM English Short": "Bosnia and Herzegovina",
    "UNTERM French Formal": "la Bosnie-Herzégovine",
    "UNTERM French Short": "Bosnie-Herzégovine (la)",
    "UNTERM Russian Formal": "Босния и Герцеговина",
    "UNTERM Russian Short": "Босния и Герцеговина",
    "UNTERM Spanish Formal": "Bosnia y Herzegovina",
    "UNTERM Spanish Short": "Bosnia y Herzegovina",
    WMO: "BG",
    is_independent: "Yes",
    official_name_ar: "البوسنة والهرسك",
    official_name_cn: "波斯尼亚和黑塞哥维那",
    official_name_en: "Bosnia and Herzegovina",
    official_name_es: "Bosnia y Herzegovina",
    official_name_fr: "Bosnie-Herzégovine",
    official_name_ru: "Босния и Герцеговина",
  },
  {
    "CLDR display name": "Botswana",
    Capital: "Gaborone",
    Continent: "AF",
    DS: "BW",
    "Developed / Developing Countries": "Developing",
    Dial: "267",
    EDGAR: "B1",
    FIFA: "BOT",
    FIPS: "BC",
    GAUL: "35",
    "Geoname ID": 933860,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BOT",
    "ISO3166-1-Alpha-2": "BW",
    "ISO3166-1-Alpha-3": "BWA",
    "ISO3166-1-numeric": "072",
    "ISO4217-currency_alphabetic_code": "BWP",
    "ISO4217-currency_country_name": "BOTSWANA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pula",
    "ISO4217-currency_numeric_code": "072",
    ITU: "BOT",
    "Intermediate Region Code": "18",
    "Intermediate Region Name": "Southern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-BW,tn-BW",
    "Least Developed Countries (LDC)": null,
    M49: 72,
    MARC: "bs",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".bw",
    "UNTERM Arabic Formal": "جمهورية بوتسوانا",
    "UNTERM Arabic Short": "بوتسوانا",
    "UNTERM Chinese Formal": "博茨瓦纳共和国",
    "UNTERM Chinese Short": "博茨瓦纳",
    "UNTERM English Formal": "the Republic of Botswana",
    "UNTERM English Short": "Botswana",
    "UNTERM French Formal": "la République du Botswana",
    "UNTERM French Short": "Botswana (le)",
    "UNTERM Russian Formal": "Республика Ботсвана",
    "UNTERM Russian Short": "Ботсвана",
    "UNTERM Spanish Formal": "la República de Botswana",
    "UNTERM Spanish Short": "Botswana",
    WMO: "BC",
    is_independent: "Yes",
    official_name_ar: "بوتسوانا",
    official_name_cn: "博茨瓦纳",
    official_name_en: "Botswana",
    official_name_es: "Botswana",
    official_name_fr: "Botswana",
    official_name_ru: "Ботсвана",
  },
  {
    "CLDR display name": "Bouvet Island",
    Capital: null,
    Continent: "AN",
    DS: "BV",
    "Developed / Developing Countries": "Developing",
    Dial: "47",
    EDGAR: "D4",
    FIFA: null,
    FIPS: "BV",
    GAUL: "36",
    "Geoname ID": 3371123,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "BV",
    "ISO3166-1-Alpha-3": "BVT",
    "ISO3166-1-numeric": "074",
    "ISO4217-currency_alphabetic_code": "NOK",
    "ISO4217-currency_country_name": "BOUVET ISLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Norwegian Krone",
    "ISO4217-currency_numeric_code": "578",
    ITU: null,
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: null,
    "Least Developed Countries (LDC)": null,
    M49: 74,
    MARC: "bv",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".bv",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "BV",
    is_independent: "Territory of NO",
    official_name_ar: "جزيرة بوفيت",
    official_name_cn: "布维岛",
    official_name_en: "Bouvet Island",
    official_name_es: "Isla Bouvet",
    official_name_fr: "Île Bouvet",
    official_name_ru: "Остров Буве",
  },
  {
    "CLDR display name": "Brazil",
    Capital: "Brasilia",
    Continent: "SA",
    DS: "BR",
    "Developed / Developing Countries": "Developing",
    Dial: "55",
    EDGAR: "D5",
    FIFA: "BRA",
    FIPS: "BR",
    GAUL: "37",
    "Geoname ID": 3469034,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BRA",
    "ISO3166-1-Alpha-2": "BR",
    "ISO3166-1-Alpha-3": "BRA",
    "ISO3166-1-numeric": "076",
    "ISO4217-currency_alphabetic_code": "BRL",
    "ISO4217-currency_country_name": "BRAZIL",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Brazilian Real",
    "ISO4217-currency_numeric_code": "986",
    ITU: "B",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-BR,es,en,fr",
    "Least Developed Countries (LDC)": null,
    M49: 76,
    MARC: "bl",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".br",
    "UNTERM Arabic Formal": "الجمهورية الاتحادية البرازيلية",
    "UNTERM Arabic Short": "البرازيل",
    "UNTERM Chinese Formal": "巴西联邦共和国",
    "UNTERM Chinese Short": "巴西",
    "UNTERM English Formal": "the Federative Republic of Brazil",
    "UNTERM English Short": "Brazil",
    "UNTERM French Formal": "la République fédérative du Brésil",
    "UNTERM French Short": "Brésil (le)",
    "UNTERM Russian Formal": "Федеративная Республика Бразилия",
    "UNTERM Russian Short": "Бразилия",
    "UNTERM Spanish Formal": "la República Federativa del Brasil",
    "UNTERM Spanish Short": "Brasil (el)",
    WMO: "BZ",
    is_independent: "Yes",
    official_name_ar: "البرازيل",
    official_name_cn: "巴西",
    official_name_en: "Brazil",
    official_name_es: "Brasil",
    official_name_fr: "Brésil",
    official_name_ru: "Бразилия",
  },
  {
    "CLDR display name": "British Indian Ocean Territory",
    Capital: "Diego Garcia",
    Continent: "AS",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "246",
    EDGAR: "D6",
    FIFA: null,
    FIPS: "IO",
    GAUL: "38",
    "Geoname ID": 1282588,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "IO",
    "ISO3166-1-Alpha-3": "IOT",
    "ISO3166-1-numeric": "086",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "BRITISH INDIAN OCEAN TERRITORY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "BIO",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-IO",
    "Least Developed Countries (LDC)": null,
    M49: 86,
    MARC: "bi",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".io",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of GB",
    official_name_ar: "المحيط الهندي الإقليم البريطاني في",
    official_name_cn: "英属印度洋领土",
    official_name_en: "British Indian Ocean Territory",
    official_name_es: "Territorio Británico del Océano Índico",
    official_name_fr: "Territoire britannique de l'océan Indien",
    official_name_ru: "Британская территория в Индийском океане",
  },
  {
    "CLDR display name": "British Virgin Islands",
    Capital: "Road Town",
    Continent: "NA",
    DS: "BVI",
    "Developed / Developing Countries": "Developing",
    Dial: "1-284",
    EDGAR: null,
    FIFA: "VGB",
    FIPS: "VI",
    GAUL: "39",
    "Geoname ID": 3577718,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "IVB",
    "ISO3166-1-Alpha-2": "VG",
    "ISO3166-1-Alpha-3": "VGB",
    "ISO3166-1-numeric": "092",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "VIRGIN ISLANDS (BRITISH)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "VRG",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-VG",
    "Least Developed Countries (LDC)": null,
    M49: 92,
    MARC: "vb",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".vg",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "VI",
    is_independent: "Territory of GB",
    official_name_ar: "جزر فرجن البريطانية",
    official_name_cn: "英属维尔京群岛",
    official_name_en: "British Virgin Islands",
    official_name_es: "Islas Vírgenes Británicas",
    official_name_fr: "Îles Vierges britanniques",
    official_name_ru: "Британские Виргинские острова",
  },
  {
    "CLDR display name": "Brunei",
    Capital: "Bandar Seri Begawan",
    Continent: "AS",
    DS: "BRU",
    "Developed / Developing Countries": "Developing",
    Dial: "673",
    EDGAR: "D9",
    FIFA: "BRU",
    FIPS: "BX",
    GAUL: "40",
    "Geoname ID": 1820814,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BRU",
    "ISO3166-1-Alpha-2": "BN",
    "ISO3166-1-Alpha-3": "BRN",
    "ISO3166-1-numeric": "096",
    "ISO4217-currency_alphabetic_code": "BND",
    "ISO4217-currency_country_name": "BRUNEI DARUSSALAM",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Brunei Dollar",
    "ISO4217-currency_numeric_code": "096",
    ITU: "BRU",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ms-BN,en-BN",
    "Least Developed Countries (LDC)": null,
    M49: 96,
    MARC: "bx",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".bn",
    "UNTERM Arabic Formal": "بروني دار السلام",
    "UNTERM Arabic Short": "بروني دار السلام",
    "UNTERM Chinese Formal": "文莱达鲁萨兰国",
    "UNTERM Chinese Short": "文莱达鲁萨兰国",
    "UNTERM English Formal": "Brunei Darussalam",
    "UNTERM English Short": "Brunei Darussalam",
    "UNTERM French Formal": "le Brunéi Darussalam",
    "UNTERM French Short": "Brunéi Darussalam (le)",
    "UNTERM Russian Formal": "Бруней-Даруссалам",
    "UNTERM Russian Short": "Бруней-Даруссалам",
    "UNTERM Spanish Formal": "Brunei Darussalam",
    "UNTERM Spanish Short": "Brunei Darussalam",
    WMO: "BD",
    is_independent: "Yes",
    official_name_ar: "بروني دار السلام",
    official_name_cn: "文莱达鲁萨兰国",
    official_name_en: "Brunei Darussalam",
    official_name_es: "Brunei Darussalam",
    official_name_fr: "Brunéi Darussalam",
    official_name_ru: "Бруней-Даруссалам",
  },
  {
    "CLDR display name": "Bulgaria",
    Capital: "Sofia",
    Continent: "EU",
    DS: "BG",
    "Developed / Developing Countries": "Developed",
    Dial: "359",
    EDGAR: "E0",
    FIFA: "BUL",
    FIPS: "BU",
    GAUL: "41",
    "Geoname ID": 732800,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BUL",
    "ISO3166-1-Alpha-2": "BG",
    "ISO3166-1-Alpha-3": "BGR",
    "ISO3166-1-numeric": "100",
    "ISO4217-currency_alphabetic_code": "BGN",
    "ISO4217-currency_country_name": "BULGARIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Bulgarian Lev",
    "ISO4217-currency_numeric_code": "975",
    ITU: "BUL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "bg,tr-BG,rom",
    "Least Developed Countries (LDC)": null,
    M49: 100,
    MARC: "bu",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".bg",
    "UNTERM Arabic Formal": "جمهورية بلغاريا",
    "UNTERM Arabic Short": "بلغاريا",
    "UNTERM Chinese Formal": "保加利亚共和国",
    "UNTERM Chinese Short": "保加利亚",
    "UNTERM English Formal": "the Republic of Bulgaria",
    "UNTERM English Short": "Bulgaria",
    "UNTERM French Formal": "la République de Bulgarie",
    "UNTERM French Short": "Bulgarie (la)",
    "UNTERM Russian Formal": "Республика Болгария",
    "UNTERM Russian Short": "Болгария",
    "UNTERM Spanish Formal": "la República de Bulgaria",
    "UNTERM Spanish Short": "Bulgaria",
    WMO: "BU",
    is_independent: "Yes",
    official_name_ar: "بلغاريا",
    official_name_cn: "保加利亚",
    official_name_en: "Bulgaria",
    official_name_es: "Bulgaria",
    official_name_fr: "Bulgarie",
    official_name_ru: "Болгария",
  },
  {
    "CLDR display name": "Burkina Faso",
    Capital: "Ouagadougou",
    Continent: "AF",
    DS: "BF",
    "Developed / Developing Countries": "Developing",
    Dial: "226",
    EDGAR: "X2",
    FIFA: "BFA",
    FIPS: "UV",
    GAUL: "42",
    "Geoname ID": 2361809,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BUR",
    "ISO3166-1-Alpha-2": "BF",
    "ISO3166-1-Alpha-3": "BFA",
    "ISO3166-1-numeric": "854",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "BURKINA FASO",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "BFA",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-BF,mos",
    "Least Developed Countries (LDC)": "x",
    M49: 854,
    MARC: "uv",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".bf",
    "UNTERM Arabic Formal": "بوركينا فاسو",
    "UNTERM Arabic Short": "بوركينا فاسو",
    "UNTERM Chinese Formal": "布基纳法索",
    "UNTERM Chinese Short": "布基纳法索",
    "UNTERM English Formal": "Burkina Faso",
    "UNTERM English Short": "Burkina Faso",
    "UNTERM French Formal": "le Burkina Faso",
    "UNTERM French Short": "Burkina Faso (le)",
    "UNTERM Russian Formal": "Буркина-Фасо",
    "UNTERM Russian Short": "Буркина-Фасо",
    "UNTERM Spanish Formal": "Burkina Faso",
    "UNTERM Spanish Short": "Burkina Faso",
    WMO: "HV",
    is_independent: "Yes",
    official_name_ar: "بوركينا فاسو",
    official_name_cn: "布基纳法索",
    official_name_en: "Burkina Faso",
    official_name_es: "Burkina Faso",
    official_name_fr: "Burkina Faso",
    official_name_ru: "Буркина-Фасо",
  },
  {
    "CLDR display name": "Burundi",
    Capital: "Bujumbura",
    Continent: "AF",
    DS: "RU",
    "Developed / Developing Countries": "Developing",
    Dial: "257",
    EDGAR: "E2",
    FIFA: "BDI",
    FIPS: "BY",
    GAUL: "43",
    "Geoname ID": 433561,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "BDI",
    "ISO3166-1-Alpha-2": "BI",
    "ISO3166-1-Alpha-3": "BDI",
    "ISO3166-1-numeric": "108",
    "ISO4217-currency_alphabetic_code": "BIF",
    "ISO4217-currency_country_name": "BURUNDI",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Burundi Franc",
    "ISO4217-currency_numeric_code": "108",
    ITU: "BDI",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-BI,rn",
    "Least Developed Countries (LDC)": "x",
    M49: 108,
    MARC: "bd",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".bi",
    "UNTERM Arabic Formal": "جمهورية بوروندي",
    "UNTERM Arabic Short": "بوروندي",
    "UNTERM Chinese Formal": "布隆迪共和国",
    "UNTERM Chinese Short": "布隆迪",
    "UNTERM English Formal": "the Republic of Burundi",
    "UNTERM English Short": "Burundi",
    "UNTERM French Formal": "la République du Burundi",
    "UNTERM French Short": "Burundi (le)",
    "UNTERM Russian Formal": "Республика Бурунди",
    "UNTERM Russian Short": "Бурунди",
    "UNTERM Spanish Formal": "la República de Burundi",
    "UNTERM Spanish Short": "Burundi",
    WMO: "BI",
    is_independent: "Yes",
    official_name_ar: "بوروندي",
    official_name_cn: "布隆迪",
    official_name_en: "Burundi",
    official_name_es: "Burundi",
    official_name_fr: "Burundi",
    official_name_ru: "Бурунди",
  },
  {
    "CLDR display name": "Cape Verde",
    Capital: "Praia",
    Continent: "AF",
    DS: "CV",
    "Developed / Developing Countries": "Developing",
    Dial: "238",
    EDGAR: null,
    FIFA: "CPV",
    FIPS: "CV",
    GAUL: "47",
    "Geoname ID": 3374766,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CPV",
    "ISO3166-1-Alpha-2": "CV",
    "ISO3166-1-Alpha-3": "CPV",
    "ISO3166-1-numeric": "132",
    "ISO4217-currency_alphabetic_code": "CVE",
    "ISO4217-currency_country_name": "CABO VERDE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Cabo Verde Escudo",
    "ISO4217-currency_numeric_code": "132",
    ITU: "CPV",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-CV",
    "Least Developed Countries (LDC)": null,
    M49: 132,
    MARC: "cv",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".cv",
    "UNTERM Arabic Formal": "جمهورية كابو فيردي",
    "UNTERM Arabic Short": "كابو فيردي",
    "UNTERM Chinese Formal": "佛得角共和国",
    "UNTERM Chinese Short": "佛得角",
    "UNTERM English Formal": "the Republic of Cabo Verde",
    "UNTERM English Short": "Cabo Verde",
    "UNTERM French Formal": "la République de Cabo Verde",
    "UNTERM French Short": "Cabo Verde (sans article)",
    "UNTERM Russian Formal": "Республика Кабо-Верде",
    "UNTERM Russian Short": "Кабо-Верде",
    "UNTERM Spanish Formal": "la República de Cabo Verde",
    "UNTERM Spanish Short": "Cabo Verde",
    WMO: "CV",
    is_independent: "Yes",
    official_name_ar: "كابو فيردي",
    official_name_cn: "佛得角",
    official_name_en: "Cabo Verde",
    official_name_es: "Cabo Verde",
    official_name_fr: "Cabo Verde",
    official_name_ru: "Кабо-Верде",
  },
  {
    "CLDR display name": "Cambodia",
    Capital: "Phnom Penh",
    Continent: "AS",
    DS: "K",
    "Developed / Developing Countries": "Developing",
    Dial: "855",
    EDGAR: "E3",
    FIFA: "CAM",
    FIPS: "CB",
    GAUL: "44",
    "Geoname ID": 1831722,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CAM",
    "ISO3166-1-Alpha-2": "KH",
    "ISO3166-1-Alpha-3": "KHM",
    "ISO3166-1-numeric": "116",
    "ISO4217-currency_alphabetic_code": "KHR",
    "ISO4217-currency_country_name": "CAMBODIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Riel",
    "ISO4217-currency_numeric_code": "116",
    ITU: "CBG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "km,fr,en",
    "Least Developed Countries (LDC)": "x",
    M49: 116,
    MARC: "cb",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".kh",
    "UNTERM Arabic Formal": "مملكة كمبوديا",
    "UNTERM Arabic Short": "كمبوديا",
    "UNTERM Chinese Formal": "柬埔寨王国",
    "UNTERM Chinese Short": "柬埔寨",
    "UNTERM English Formal": "the Kingdom of Cambodia",
    "UNTERM English Short": "Cambodia",
    "UNTERM French Formal": "le Royaume du Cambodge",
    "UNTERM French Short": "Cambodge (le)",
    "UNTERM Russian Formal": "Королевство Камбоджа",
    "UNTERM Russian Short": "Камбоджа",
    "UNTERM Spanish Formal": "el Reino de Camboya",
    "UNTERM Spanish Short": "Camboya",
    WMO: "KP",
    is_independent: "Yes",
    official_name_ar: "كمبوديا",
    official_name_cn: "柬埔寨",
    official_name_en: "Cambodia",
    official_name_es: "Camboya",
    official_name_fr: "Cambodge",
    official_name_ru: "Камбоджа",
  },
  {
    "CLDR display name": "Cameroon",
    Capital: "Yaounde",
    Continent: "AF",
    DS: "CAM",
    "Developed / Developing Countries": "Developing",
    Dial: "237",
    EDGAR: "E4",
    FIFA: "CMR",
    FIPS: "CM",
    GAUL: "45",
    "Geoname ID": 2233387,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CMR",
    "ISO3166-1-Alpha-2": "CM",
    "ISO3166-1-Alpha-3": "CMR",
    "ISO3166-1-numeric": "120",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "CAMEROON",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "CME",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-CM,fr-CM",
    "Least Developed Countries (LDC)": null,
    M49: 120,
    MARC: "cm",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".cm",
    "UNTERM Arabic Formal": "جمهورية الكاميرون",
    "UNTERM Arabic Short": "الكاميرون",
    "UNTERM Chinese Formal": "喀麦隆共和国",
    "UNTERM Chinese Short": "喀麦隆",
    "UNTERM English Formal": "the Republic of Cameroon",
    "UNTERM English Short": "Cameroon",
    "UNTERM French Formal": "la République du Cameroun",
    "UNTERM French Short": "Cameroun (le)",
    "UNTERM Russian Formal": "Республика Камерун",
    "UNTERM Russian Short": "Камерун",
    "UNTERM Spanish Formal": "la República del Camerún",
    "UNTERM Spanish Short": "Camerún (el)",
    WMO: "CM",
    is_independent: "Yes",
    official_name_ar: "الكاميرون",
    official_name_cn: "喀麦隆",
    official_name_en: "Cameroon",
    official_name_es: "Camerún",
    official_name_fr: "Cameroun",
    official_name_ru: "Камерун",
  },
  {
    "CLDR display name": "Canada",
    Capital: "Ottawa",
    Continent: "NA",
    DS: "CDN",
    "Developed / Developing Countries": "Developed",
    Dial: "1",
    EDGAR: null,
    FIFA: "CAN",
    FIPS: "CA",
    GAUL: "46",
    "Geoname ID": 6251999,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CAN",
    "ISO3166-1-Alpha-2": "CA",
    "ISO3166-1-Alpha-3": "CAN",
    "ISO3166-1-numeric": "124",
    "ISO4217-currency_alphabetic_code": "CAD",
    "ISO4217-currency_country_name": "CANADA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Canadian Dollar",
    "ISO4217-currency_numeric_code": "124",
    ITU: "CAN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-CA,fr-CA,iu",
    "Least Developed Countries (LDC)": null,
    M49: 124,
    MARC: "xxc",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "21",
    "Sub-region Name": "Northern America",
    TLD: ".ca",
    "UNTERM Arabic Formal": "كندا",
    "UNTERM Arabic Short": "كندا",
    "UNTERM Chinese Formal": "加拿大",
    "UNTERM Chinese Short": "加拿大",
    "UNTERM English Formal": "Canada",
    "UNTERM English Short": "Canada",
    "UNTERM French Formal": "le Canada",
    "UNTERM French Short": "Canada (le)",
    "UNTERM Russian Formal": "Канада",
    "UNTERM Russian Short": "Канада",
    "UNTERM Spanish Formal": "el Canadá",
    "UNTERM Spanish Short": "Canadá (el)",
    WMO: "CN",
    is_independent: "Yes",
    official_name_ar: "كندا",
    official_name_cn: "加拿大",
    official_name_en: "Canada",
    official_name_es: "Canadá",
    official_name_fr: "Canada",
    official_name_ru: "Канада",
  },
  {
    "CLDR display name": "Cayman Islands",
    Capital: "George Town",
    Continent: "NA",
    DS: "KY",
    "Developed / Developing Countries": "Developing",
    Dial: "1-345",
    EDGAR: "E9",
    FIFA: "CAY",
    FIPS: "CJ",
    GAUL: "48",
    "Geoname ID": 3580718,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CAY",
    "ISO3166-1-Alpha-2": "KY",
    "ISO3166-1-Alpha-3": "CYM",
    "ISO3166-1-numeric": "136",
    "ISO4217-currency_alphabetic_code": "KYD",
    "ISO4217-currency_country_name": "CAYMAN ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Cayman Islands Dollar",
    "ISO4217-currency_numeric_code": "136",
    ITU: "CYM",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-KY",
    "Least Developed Countries (LDC)": null,
    M49: 136,
    MARC: "cj",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ky",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "GC",
    is_independent: "Territory of GB",
    official_name_ar: "جزر كايمان",
    official_name_cn: "开曼群岛",
    official_name_en: "Cayman Islands",
    official_name_es: "Islas Caimán",
    official_name_fr: "Îles Caïmanes",
    official_name_ru: "Кайман острова",
  },
  {
    "CLDR display name": "Central African Republic",
    Capital: "Bangui",
    Continent: "AF",
    DS: "RCA",
    "Developed / Developing Countries": "Developing",
    Dial: "236",
    EDGAR: "F0",
    FIFA: "CTA",
    FIPS: "CT",
    GAUL: "49",
    "Geoname ID": 239880,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CAF",
    "ISO3166-1-Alpha-2": "CF",
    "ISO3166-1-Alpha-3": "CAF",
    "ISO3166-1-numeric": "140",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "CENTRAL AFRICAN REPUBLIC",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "CAF",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-CF,sg,ln,kg",
    "Least Developed Countries (LDC)": "x",
    M49: 140,
    MARC: "cx",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".cf",
    "UNTERM Arabic Formal": "جمهورية أفريقيا الوسطى",
    "UNTERM Arabic Short": "جمهورية أفريقيا الوسطى",
    "UNTERM Chinese Formal": "中非共和国",
    "UNTERM Chinese Short": "中非共和国",
    "UNTERM English Formal": "the Central African Republic",
    "UNTERM English Short": "Central African Republic (the)",
    "UNTERM French Formal": "la République centrafricaine",
    "UNTERM French Short": "République centrafricaine (la)",
    "UNTERM Russian Formal": "Центральноафриканская Республика",
    "UNTERM Russian Short": "Центральноафриканская Республика",
    "UNTERM Spanish Formal": "la República Centroafricana",
    "UNTERM Spanish Short": "República Centroafricana (la)",
    WMO: "CE",
    is_independent: "Yes",
    official_name_ar: "جمهورية أفريقيا الوسطى",
    official_name_cn: "中非共和国",
    official_name_en: "Central African Republic",
    official_name_es: "República Centroafricana",
    official_name_fr: "République centrafricaine",
    official_name_ru: "Центральноафриканская Республика",
  },
  {
    "CLDR display name": "Chad",
    Capital: "N'Djamena",
    Continent: "AF",
    DS: "TCH",
    "Developed / Developing Countries": "Developing",
    Dial: "235",
    EDGAR: "F2",
    FIFA: "CHA",
    FIPS: "CD",
    GAUL: "50",
    "Geoname ID": 2434508,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CHA",
    "ISO3166-1-Alpha-2": "TD",
    "ISO3166-1-Alpha-3": "TCD",
    "ISO3166-1-numeric": "148",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "CHAD",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "TCD",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-TD,ar-TD,sre",
    "Least Developed Countries (LDC)": "x",
    M49: 148,
    MARC: "cd",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".td",
    "UNTERM Arabic Formal": "جمهورية تشاد",
    "UNTERM Arabic Short": "تشاد",
    "UNTERM Chinese Formal": "乍得共和国",
    "UNTERM Chinese Short": "乍得",
    "UNTERM English Formal": "the Republic of Chad",
    "UNTERM English Short": "Chad",
    "UNTERM French Formal": "la République du Tchad",
    "UNTERM French Short": "Tchad (le)",
    "UNTERM Russian Formal": "Республика Чад",
    "UNTERM Russian Short": "Чад",
    "UNTERM Spanish Formal": "la República del Chad",
    "UNTERM Spanish Short": "Chad (el)",
    WMO: "CD",
    is_independent: "Yes",
    official_name_ar: "تشاد",
    official_name_cn: "乍得",
    official_name_en: "Chad",
    official_name_es: "Chad",
    official_name_fr: "Tchad",
    official_name_ru: "Чад",
  },
  {
    "CLDR display name": "Chile",
    Capital: "Santiago",
    Continent: "SA",
    DS: "RCH",
    "Developed / Developing Countries": "Developing",
    Dial: "56",
    EDGAR: "F3",
    FIFA: "CHI",
    FIPS: "CI",
    GAUL: "51",
    "Geoname ID": 3895114,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CHI",
    "ISO3166-1-Alpha-2": "CL",
    "ISO3166-1-Alpha-3": "CHL",
    "ISO3166-1-numeric": "152",
    "ISO4217-currency_alphabetic_code": "CLP",
    "ISO4217-currency_country_name": "CHILE",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Chilean Peso",
    "ISO4217-currency_numeric_code": "152",
    ITU: "CHL",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-CL",
    "Least Developed Countries (LDC)": null,
    M49: 152,
    MARC: "cl",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".cl",
    "UNTERM Arabic Formal": "جمهورية شيلي",
    "UNTERM Arabic Short": "شيلي",
    "UNTERM Chinese Formal": "智利共和国",
    "UNTERM Chinese Short": "智利",
    "UNTERM English Formal": "the Republic of Chile",
    "UNTERM English Short": "Chile",
    "UNTERM French Formal": "la République du Chili",
    "UNTERM French Short": "Chili (le)",
    "UNTERM Russian Formal": "Республика Чили",
    "UNTERM Russian Short": "Чили",
    "UNTERM Spanish Formal": "la República de Chile",
    "UNTERM Spanish Short": "Chile",
    WMO: "CH",
    is_independent: "Yes",
    official_name_ar: "شيلي",
    official_name_cn: "智利",
    official_name_en: "Chile",
    official_name_es: "Chile",
    official_name_fr: "Chili",
    official_name_ru: "Чили",
  },
  {
    "CLDR display name": "China",
    Capital: "Beijing",
    Continent: "AS",
    DS: "CN",
    "Developed / Developing Countries": "Developing",
    Dial: "86",
    EDGAR: "F4",
    FIFA: "CHN",
    FIPS: "CH",
    GAUL: "53",
    "Geoname ID": 1814991,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CHN",
    "ISO3166-1-Alpha-2": "CN",
    "ISO3166-1-Alpha-3": "CHN",
    "ISO3166-1-numeric": "156",
    "ISO4217-currency_alphabetic_code": "CNY",
    "ISO4217-currency_country_name": "CHINA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Yuan Renminbi",
    "ISO4217-currency_numeric_code": "156",
    ITU: "CHN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "zh-CN,yue,wuu,dta,ug,za",
    "Least Developed Countries (LDC)": null,
    M49: 156,
    MARC: "cc",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".cn",
    "UNTERM Arabic Formal": "جمهورية الصين الشعبية",
    "UNTERM Arabic Short": "الصين",
    "UNTERM Chinese Formal": "中华人民共和国",
    "UNTERM Chinese Short": "中国",
    "UNTERM English Formal": "the People's Republic of China",
    "UNTERM English Short": "China",
    "UNTERM French Formal": "la République populaire de Chine",
    "UNTERM French Short": "Chine (la)",
    "UNTERM Russian Formal": "Китайская Народная Республика",
    "UNTERM Russian Short": "Китай",
    "UNTERM Spanish Formal": "la República Popular China",
    "UNTERM Spanish Short": "China",
    WMO: "CI",
    is_independent: "Yes",
    official_name_ar: "الصين",
    official_name_cn: "中国",
    official_name_en: "China",
    official_name_es: "China",
    official_name_fr: "Chine",
    official_name_ru: "Китай",
  },
  {
    "CLDR display name": "Hong Kong",
    Capital: "Hong Kong",
    Continent: "AS",
    DS: "HK",
    "Developed / Developing Countries": "Developing",
    Dial: "852",
    EDGAR: "K3",
    FIFA: "HKG",
    FIPS: "HK",
    GAUL: "33364",
    "Geoname ID": 1819730,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "HKG",
    "ISO3166-1-Alpha-2": "HK",
    "ISO3166-1-Alpha-3": "HKG",
    "ISO3166-1-numeric": "344",
    "ISO4217-currency_alphabetic_code": "HKD",
    "ISO4217-currency_country_name": "HONG KONG",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Hong Kong Dollar",
    "ISO4217-currency_numeric_code": "344",
    ITU: "HKG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "zh-HK,yue,zh,en",
    "Least Developed Countries (LDC)": null,
    M49: 344,
    MARC: null,
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".hk",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "HK",
    is_independent: "Part of CN",
    official_name_ar: "منطقة هونغ كونغ الصينية الإدارية الخاصة",
    official_name_cn: "中国香港特别行政区",
    official_name_en: "China, Hong Kong Special Administrative Region",
    official_name_es: "China, región administrativa especial de Hong Kong",
    official_name_fr: "Chine, région administrative spéciale de Hong Kong",
    official_name_ru: "Китай, Специальный административный район Гонконг",
  },
  {
    "CLDR display name": "Macau",
    Capital: "Macao",
    Continent: "AS",
    DS: "MO",
    "Developed / Developing Countries": "Developing",
    Dial: "853",
    EDGAR: null,
    FIFA: "MAC",
    FIPS: "MC",
    GAUL: "149",
    "Geoname ID": 1821275,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAC",
    "ISO3166-1-Alpha-2": "MO",
    "ISO3166-1-Alpha-3": "MAC",
    "ISO3166-1-numeric": "446",
    "ISO4217-currency_alphabetic_code": "MOP",
    "ISO4217-currency_country_name": "MACAO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pataca",
    "ISO4217-currency_numeric_code": "446",
    ITU: "MAC",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "zh,zh-MO,pt",
    "Least Developed Countries (LDC)": null,
    M49: 446,
    MARC: null,
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".mo",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "MU",
    is_independent: "Part of CN",
    official_name_ar: "منطقة ماكاو الإدارية الخاصة التابعة للصين",
    official_name_cn: "中国澳门特别行政区",
    official_name_en: "China, Macao Special Administrative Region",
    official_name_es: "China, región administrativa especial de Macao",
    official_name_fr: "Chine, région administrative spéciale de Macao",
    official_name_ru: "Китай, Специальный административный район Макао",
  },
  {
    "CLDR display name": "Christmas Island",
    Capital: "Flying Fish Cove",
    Continent: "OC",
    DS: "AUS",
    "Developed / Developing Countries": "Developed",
    Dial: "61",
    EDGAR: "F6",
    FIFA: "CXR",
    FIPS: "KT",
    GAUL: "54",
    "Geoname ID": 2078138,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "CX",
    "ISO3166-1-Alpha-3": "CXR",
    "ISO3166-1-numeric": "162",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "CHRISTMAS ISLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "CHR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en,zh,ms-CC",
    "Least Developed Countries (LDC)": null,
    M49: 162,
    MARC: "xa",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".cx",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "KI",
    is_independent: "Territory of AU",
    official_name_ar: "جزيرة كريسماس",
    official_name_cn: "圣诞岛",
    official_name_en: "Christmas Island",
    official_name_es: "Isla de Navidad",
    official_name_fr: "Île Christmas",
    official_name_ru: "остров Рождества",
  },
  {
    "CLDR display name": "Cocos (Keeling) Islands",
    Capital: "West Island",
    Continent: "AS",
    DS: "AUS",
    "Developed / Developing Countries": "Developed",
    Dial: "61",
    EDGAR: "F7",
    FIFA: "CCK",
    FIPS: "CK",
    GAUL: "56",
    "Geoname ID": 1547376,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "CC",
    "ISO3166-1-Alpha-3": "CCK",
    "ISO3166-1-numeric": "166",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "COCOS (KEELING) ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "ICO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ms-CC,en",
    "Least Developed Countries (LDC)": null,
    M49: 166,
    MARC: "xb",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".cc",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "KK",
    is_independent: "Territory of AU",
    official_name_ar: "جزر كوكوس (كيلينغ)",
    official_name_cn: "科科斯(基林)群岛",
    official_name_en: "Cocos (Keeling) Islands",
    official_name_es: "Islas Cocos (Keeling)",
    official_name_fr: "Îles des Cocos (Keeling)",
    official_name_ru: "Кокосовых (Килинг) островов",
  },
  {
    "CLDR display name": "Colombia",
    Capital: "Bogota",
    Continent: "SA",
    DS: "CO",
    "Developed / Developing Countries": "Developing",
    Dial: "57",
    EDGAR: "F8",
    FIFA: "COL",
    FIPS: "CO",
    GAUL: "57",
    "Geoname ID": 3686110,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "COL",
    "ISO3166-1-Alpha-2": "CO",
    "ISO3166-1-Alpha-3": "COL",
    "ISO3166-1-numeric": "170",
    "ISO4217-currency_alphabetic_code": "COP",
    "ISO4217-currency_country_name": "COLOMBIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Colombian Peso",
    "ISO4217-currency_numeric_code": "170",
    ITU: "CLM",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-CO",
    "Least Developed Countries (LDC)": null,
    M49: 170,
    MARC: "ck",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".co",
    "UNTERM Arabic Formal": "جمهورية كولومبيا",
    "UNTERM Arabic Short": "كولومبيا",
    "UNTERM Chinese Formal": "哥伦比亚共和国",
    "UNTERM Chinese Short": "哥伦比亚",
    "UNTERM English Formal": "the Republic of Colombia",
    "UNTERM English Short": "Colombia",
    "UNTERM French Formal": "la République de Colombie",
    "UNTERM French Short": "Colombie (la)",
    "UNTERM Russian Formal": "Республика Колумбия",
    "UNTERM Russian Short": "Колумбия",
    "UNTERM Spanish Formal": "la República de Colombia",
    "UNTERM Spanish Short": "Colombia",
    WMO: "CO",
    is_independent: "Yes",
    official_name_ar: "كولومبيا",
    official_name_cn: "哥伦比亚",
    official_name_en: "Colombia",
    official_name_es: "Colombia",
    official_name_fr: "Colombie",
    official_name_ru: "Колумбия",
  },
  {
    "CLDR display name": "Comoros",
    Capital: "Moroni",
    Continent: "AF",
    DS: "KM",
    "Developed / Developing Countries": "Developing",
    Dial: "269",
    EDGAR: "F9",
    FIFA: "COM",
    FIPS: "CN",
    GAUL: "58",
    "Geoname ID": 921929,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "COM",
    "ISO3166-1-Alpha-2": "KM",
    "ISO3166-1-Alpha-3": "COM",
    "ISO3166-1-numeric": "174",
    "ISO4217-currency_alphabetic_code": "KMF",
    "ISO4217-currency_country_name": "COMOROS",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Comorian Franc",
    "ISO4217-currency_numeric_code": "174",
    ITU: "COM",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar,fr-KM",
    "Least Developed Countries (LDC)": "x",
    M49: 174,
    MARC: "cq",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".km",
    "UNTERM Arabic Formal": "اتحاد جزر القمر",
    "UNTERM Arabic Short": "جزر القمر",
    "UNTERM Chinese Formal": "科摩罗联盟",
    "UNTERM Chinese Short": "科摩罗",
    "UNTERM English Formal": "the Union of the Comoros",
    "UNTERM English Short": "Comoros (the)",
    "UNTERM French Formal": "l'Union des Comores",
    "UNTERM French Short": "Comores (les) [fém.]",
    "UNTERM Russian Formal": "Союз Коморских Островов",
    "UNTERM Russian Short": "Коморские Острова",
    "UNTERM Spanish Formal": "la Unión de las Comoras",
    "UNTERM Spanish Short": "Comoras (las)",
    WMO: "IC",
    is_independent: "Yes",
    official_name_ar: "جزر القمر",
    official_name_cn: "科摩罗",
    official_name_en: "Comoros",
    official_name_es: "Comoras",
    official_name_fr: "Comores",
    official_name_ru: "Коморские Острова",
  },
  {
    "CLDR display name": "Congo - Brazzaville",
    Capital: "Brazzaville",
    Continent: "AF",
    DS: "RCB",
    "Developed / Developing Countries": "Developing",
    Dial: "242",
    EDGAR: "G0",
    FIFA: "CGO",
    FIPS: "CF",
    GAUL: "59",
    "Geoname ID": 2260494,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CGO",
    "ISO3166-1-Alpha-2": "CG",
    "ISO3166-1-Alpha-3": "COG",
    "ISO3166-1-numeric": "178",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "CONGO",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "COG",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-CG,kg,ln-CG",
    "Least Developed Countries (LDC)": null,
    M49: 178,
    MARC: "cf",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".cg",
    "UNTERM Arabic Formal": "جمهورية الكونغو",
    "UNTERM Arabic Short": "الكونغو",
    "UNTERM Chinese Formal": "刚果共和国",
    "UNTERM Chinese Short": "刚果",
    "UNTERM English Formal": "the Republic of the Congo",
    "UNTERM English Short": "Congo (the)",
    "UNTERM French Formal": "la République du Congo",
    "UNTERM French Short": "Congo (le)",
    "UNTERM Russian Formal": "Республика Конго",
    "UNTERM Russian Short": "Конго",
    "UNTERM Spanish Formal": "la República del Congo",
    "UNTERM Spanish Short": "Congo (el)",
    WMO: "CG",
    is_independent: "Yes",
    official_name_ar: "الكونغو",
    official_name_cn: "刚果",
    official_name_en: "Congo",
    official_name_es: "Congo",
    official_name_fr: "Congo",
    official_name_ru: "Конго",
  },
  {
    "CLDR display name": "Cook Islands",
    Capital: "Avarua",
    Continent: "OC",
    DS: "NZ",
    "Developed / Developing Countries": "Developing",
    Dial: "682",
    EDGAR: "G1",
    FIFA: "COK",
    FIPS: "CW",
    GAUL: "60",
    "Geoname ID": 1899402,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "COK",
    "ISO3166-1-Alpha-2": "CK",
    "ISO3166-1-Alpha-3": "COK",
    "ISO3166-1-numeric": "184",
    "ISO4217-currency_alphabetic_code": "NZD",
    "ISO4217-currency_country_name": "COOK ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Zealand Dollar",
    "ISO4217-currency_numeric_code": "554",
    ITU: "CKH",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-CK,mi",
    "Least Developed Countries (LDC)": null,
    M49: 184,
    MARC: "cw",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".ck",
    "UNTERM Arabic Formal": "جزر كوك",
    "UNTERM Arabic Short": "جزر كوك    **",
    "UNTERM Chinese Formal": "库克群岛",
    "UNTERM Chinese Short": "库克群岛    **",
    "UNTERM English Formal": "the Cook Islands",
    "UNTERM English Short": "Cook Islands (the)    **",
    "UNTERM French Formal": "les Îles Cook",
    "UNTERM French Short": "Îles Cook (les)    **",
    "UNTERM Russian Formal": "Острова Кука",
    "UNTERM Russian Short": "Острова Кука    **",
    "UNTERM Spanish Formal": "las Islas Cook",
    "UNTERM Spanish Short": "Islas Cook (las)    **",
    WMO: "KU",
    is_independent: "Associated with NZ",
    official_name_ar: "جزر كوك",
    official_name_cn: "库克群岛",
    official_name_en: "Cook Islands",
    official_name_es: "Islas Cook",
    official_name_fr: "Îles Cook",
    official_name_ru: "Острова Кука",
  },
  {
    "CLDR display name": "Costa Rica",
    Capital: "San Jose",
    Continent: "NA",
    DS: "CR",
    "Developed / Developing Countries": "Developing",
    Dial: "506",
    EDGAR: "G2",
    FIFA: "CRC",
    FIPS: "CS",
    GAUL: "61",
    "Geoname ID": 3624060,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CRC",
    "ISO3166-1-Alpha-2": "CR",
    "ISO3166-1-Alpha-3": "CRI",
    "ISO3166-1-numeric": "188",
    "ISO4217-currency_alphabetic_code": "CRC",
    "ISO4217-currency_country_name": "COSTA RICA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Costa Rican Colon",
    "ISO4217-currency_numeric_code": "188",
    ITU: "CTR",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-CR,en",
    "Least Developed Countries (LDC)": null,
    M49: 188,
    MARC: "cr",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".cr",
    "UNTERM Arabic Formal": "جمهورية كوستاريكا",
    "UNTERM Arabic Short": "كوستاريكا",
    "UNTERM Chinese Formal": "哥斯达黎加共和国",
    "UNTERM Chinese Short": "哥斯达黎加",
    "UNTERM English Formal": "the Republic of Costa Rica",
    "UNTERM English Short": "Costa Rica",
    "UNTERM French Formal": "la République du Costa Rica",
    "UNTERM French Short": "Costa Rica (le)",
    "UNTERM Russian Formal": "Республика Коста-Рика",
    "UNTERM Russian Short": "Коста-Рика",
    "UNTERM Spanish Formal": "la República de Costa Rica",
    "UNTERM Spanish Short": "Costa Rica",
    WMO: "CS",
    is_independent: "Yes",
    official_name_ar: "كوستاريكا",
    official_name_cn: "哥斯达黎加",
    official_name_en: "Costa Rica",
    official_name_es: "Costa Rica",
    official_name_fr: "Costa Rica",
    official_name_ru: "Коста-Рика",
  },
  {
    "CLDR display name": "Croatia",
    Capital: "Zagreb",
    Continent: "EU",
    DS: "HR",
    "Developed / Developing Countries": "Developed",
    Dial: "385",
    EDGAR: "1M",
    FIFA: "CRO",
    FIPS: "HR",
    GAUL: "62",
    "Geoname ID": 3202326,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CRO",
    "ISO3166-1-Alpha-2": "HR",
    "ISO3166-1-Alpha-3": "HRV",
    "ISO3166-1-numeric": "191",
    "ISO4217-currency_alphabetic_code": "HRK",
    "ISO4217-currency_country_name": "CROATIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Kuna",
    "ISO4217-currency_numeric_code": "191",
    ITU: "HRV",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "hr-HR,sr",
    "Least Developed Countries (LDC)": null,
    M49: 191,
    MARC: "ci",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".hr",
    "UNTERM Arabic Formal": "جمهورية كرواتيا",
    "UNTERM Arabic Short": "كرواتيا",
    "UNTERM Chinese Formal": "克罗地亚共和国",
    "UNTERM Chinese Short": "克罗地亚",
    "UNTERM English Formal": "the Republic of Croatia",
    "UNTERM English Short": "Croatia",
    "UNTERM French Formal": "la République de Croatie",
    "UNTERM French Short": "Croatie (la)",
    "UNTERM Russian Formal": "Республика Хорватия",
    "UNTERM Russian Short": "Хорватия",
    "UNTERM Spanish Formal": "la República de Croacia",
    "UNTERM Spanish Short": "Croacia",
    WMO: "RH",
    is_independent: "Yes",
    official_name_ar: "كرواتيا",
    official_name_cn: "克罗地亚",
    official_name_en: "Croatia",
    official_name_es: "Croacia",
    official_name_fr: "Croatie",
    official_name_ru: "Хорватия",
  },
  {
    "CLDR display name": "Cuba",
    Capital: "Havana",
    Continent: "NA",
    DS: "C",
    "Developed / Developing Countries": "Developing",
    Dial: "53",
    EDGAR: "G3",
    FIFA: "CUB",
    FIPS: "CU",
    GAUL: "63",
    "Geoname ID": 3562981,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CUB",
    "ISO3166-1-Alpha-2": "CU",
    "ISO3166-1-Alpha-3": "CUB",
    "ISO3166-1-numeric": "192",
    "ISO4217-currency_alphabetic_code": "CUP,CUC",
    "ISO4217-currency_country_name": "CUBA",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Cuban Peso,Peso Convertible",
    "ISO4217-currency_numeric_code": "192,931",
    ITU: "CUB",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-CU,pap",
    "Least Developed Countries (LDC)": null,
    M49: 192,
    MARC: "cu",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".cu",
    "UNTERM Arabic Formal": "جمهورية كوبا",
    "UNTERM Arabic Short": "كوبا",
    "UNTERM Chinese Formal": "古巴共和国",
    "UNTERM Chinese Short": "古巴",
    "UNTERM English Formal": "the Republic of Cuba",
    "UNTERM English Short": "Cuba",
    "UNTERM French Formal": "la République de Cuba",
    "UNTERM French Short": "Cuba [fém.]",
    "UNTERM Russian Formal": "Республика Куба",
    "UNTERM Russian Short": "Куба",
    "UNTERM Spanish Formal": "la República de Cuba",
    "UNTERM Spanish Short": "Cuba",
    WMO: "CU",
    is_independent: "Yes",
    official_name_ar: "كوبا",
    official_name_cn: "古巴",
    official_name_en: "Cuba",
    official_name_es: "Cuba",
    official_name_fr: "Cuba",
    official_name_ru: "Куба",
  },
  {
    "CLDR display name": "Curaçao",
    Capital: "Willemstad",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "599",
    EDGAR: null,
    FIFA: null,
    FIPS: "UC",
    GAUL: null,
    "Geoname ID": 7626836,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "CW",
    "ISO3166-1-Alpha-3": "CUW",
    "ISO3166-1-numeric": "531",
    "ISO4217-currency_alphabetic_code": "ANG",
    "ISO4217-currency_country_name": "CURAÇAO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Netherlands Antillean Guilder",
    "ISO4217-currency_numeric_code": "532",
    ITU: null,
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl,pap",
    "Least Developed Countries (LDC)": null,
    M49: 531,
    MARC: "co",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".cw",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of NL",
    official_name_ar: "كوراساو",
    official_name_cn: "库拉索",
    official_name_en: "Curaçao",
    official_name_es: "Curaçao",
    official_name_fr: "Curaçao",
    official_name_ru: "Кюрасао",
  },
  {
    "CLDR display name": "Cyprus",
    Capital: "Nicosia",
    Continent: "EU",
    DS: "CY",
    "Developed / Developing Countries": "Developed",
    Dial: "357",
    EDGAR: "G4",
    FIFA: "CYP",
    FIPS: "CY",
    GAUL: "64",
    "Geoname ID": 146669,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CYP",
    "ISO3166-1-Alpha-2": "CY",
    "ISO3166-1-Alpha-3": "CYP",
    "ISO3166-1-numeric": "196",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "CYPRUS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "CYP",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "el-CY,tr-CY,en",
    "Least Developed Countries (LDC)": null,
    M49: 196,
    MARC: "cy",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".cy",
    "UNTERM Arabic Formal": "جمهورية قبرص",
    "UNTERM Arabic Short": "قبرص",
    "UNTERM Chinese Formal": "塞浦路斯共和国",
    "UNTERM Chinese Short": "塞浦路斯",
    "UNTERM English Formal": "the Republic of Cyprus",
    "UNTERM English Short": "Cyprus",
    "UNTERM French Formal": "la République de Chypre",
    "UNTERM French Short": "Chypre [fém.]",
    "UNTERM Russian Formal": "Республика Кипр",
    "UNTERM Russian Short": "Кипр",
    "UNTERM Spanish Formal": "la República de Chipre",
    "UNTERM Spanish Short": "Chipre",
    WMO: "CY",
    is_independent: "Yes",
    official_name_ar: "قبرص",
    official_name_cn: "塞浦路斯",
    official_name_en: "Cyprus",
    official_name_es: "Chipre",
    official_name_fr: "Chypre",
    official_name_ru: "Кипр",
  },
  {
    "CLDR display name": "Czechia",
    Capital: "Prague",
    Continent: "EU",
    DS: "CZ",
    "Developed / Developing Countries": "Developed",
    Dial: "420",
    EDGAR: null,
    FIFA: "CZE",
    FIPS: "EZ",
    GAUL: "65",
    "Geoname ID": 3077311,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CZE",
    "ISO3166-1-Alpha-2": "CZ",
    "ISO3166-1-Alpha-3": "CZE",
    "ISO3166-1-numeric": "203",
    "ISO4217-currency_alphabetic_code": "CZK",
    "ISO4217-currency_country_name": "CZECHIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Czech Koruna",
    "ISO4217-currency_numeric_code": "203",
    ITU: "CZE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "cs,sk",
    "Least Developed Countries (LDC)": null,
    M49: 203,
    MARC: "xr",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".cz",
    "UNTERM Arabic Formal": "الجمهورية التشيكية",
    "UNTERM Arabic Short": "الجمهورية التشيكية",
    "UNTERM Chinese Formal": "捷克共和国",
    "UNTERM Chinese Short": "捷克共和国",
    "UNTERM English Formal": "the Czech Republic",
    "UNTERM English Short": "Czech Republic (the)",
    "UNTERM French Formal": "la République tchèque",
    "UNTERM French Short": "République tchèque (la)",
    "UNTERM Russian Formal": "Чешская Республика",
    "UNTERM Russian Short": "Чешская Республика",
    "UNTERM Spanish Formal": "la República Checa",
    "UNTERM Spanish Short": "República Checa (la)",
    WMO: "CZ",
    is_independent: "Yes",
    official_name_ar: "تشيكيا",
    official_name_cn: "捷克",
    official_name_en: "Czechia",
    official_name_es: "Chequia",
    official_name_fr: "Tchéquie",
    official_name_ru: "Чехия",
  },
  {
    "CLDR display name": "Côte d’Ivoire",
    Capital: "Yamoussoukro",
    Continent: "AF",
    DS: "CI",
    "Developed / Developing Countries": "Developing",
    Dial: "225",
    EDGAR: null,
    FIFA: "CIV",
    FIPS: "IV",
    GAUL: "66",
    "Geoname ID": 2287781,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "CIV",
    "ISO3166-1-Alpha-2": "CI",
    "ISO3166-1-Alpha-3": "CIV",
    "ISO3166-1-numeric": "384",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "CÔTE D'IVOIRE",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "CTI",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-CI",
    "Least Developed Countries (LDC)": null,
    M49: 384,
    MARC: "iv",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ci",
    "UNTERM Arabic Formal": "جمهورية كوت ديفوار",
    "UNTERM Arabic Short": "كوت ديفوار",
    "UNTERM Chinese Formal": "科特迪瓦共和国",
    "UNTERM Chinese Short": "科特迪瓦",
    "UNTERM English Formal": "the Republic of Côte d'Ivoire",
    "UNTERM English Short": "Côte d'Ivoire",
    "UNTERM French Formal": "la République de Côte d'Ivoire",
    "UNTERM French Short": "Côte d'Ivoire (la)",
    "UNTERM Russian Formal": "Республика Кот-д'Ивуар",
    "UNTERM Russian Short": "Кот-д'Ивуар",
    "UNTERM Spanish Formal": "la República de Côte d'Ivoire",
    "UNTERM Spanish Short": "Côte d'Ivoire",
    WMO: "IV",
    is_independent: "Yes",
    official_name_ar: "كوت ديفوار",
    official_name_cn: "科特迪瓦",
    official_name_en: "Côte d'Ivoire",
    official_name_es: "Côte d'Ivoire",
    official_name_fr: "Côte d'Ivoire",
    official_name_ru: "Кот-д'Ивуар",
  },
  {
    "CLDR display name": "North Korea",
    Capital: "Pyongyang",
    Continent: "AS",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "850",
    EDGAR: null,
    FIFA: "PRK",
    FIPS: "KN",
    GAUL: "67",
    "Geoname ID": 1873107,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PRK",
    "ISO3166-1-Alpha-2": "KP",
    "ISO3166-1-Alpha-3": "PRK",
    "ISO3166-1-numeric": "408",
    "ISO4217-currency_alphabetic_code": "KPW",
    "ISO4217-currency_country_name":
      "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "North Korean Won",
    "ISO4217-currency_numeric_code": "408",
    ITU: "KRE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ko-KP",
    "Least Developed Countries (LDC)": null,
    M49: 408,
    MARC: "kn",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".kp",
    "UNTERM Arabic Formal": "جمهورية كوريا الشعبية الديمقراطية",
    "UNTERM Arabic Short": "جمهورية كوريا الشعبية الديمقراطية",
    "UNTERM Chinese Formal": "朝鲜民主主义人民共和国",
    "UNTERM Chinese Short": "朝鲜民主主义人民共和国",
    "UNTERM English Formal": "the Democratic People's Republic of Korea",
    "UNTERM English Short": "Democratic People's Republic of Korea (the)",
    "UNTERM French Formal": "la République populaire démocratique de Corée",
    "UNTERM French Short": "République populaire démocratique de Corée (la)",
    "UNTERM Russian Formal": "Корейская Народно-Демократическая Республика",
    "UNTERM Russian Short": "Корейская Народно-Демократическая Республика",
    "UNTERM Spanish Formal": "la República Popular Democrática de Corea",
    "UNTERM Spanish Short": "República Popular Democrática de Corea (la)",
    WMO: "KR",
    is_independent: "Yes",
    official_name_ar: "جمهورية كوريا الشعبية الديمقراطية",
    official_name_cn: "朝鲜民主主义人民共和国",
    official_name_en: "Democratic People's Republic of Korea",
    official_name_es: "República Popular Democrática de Corea",
    official_name_fr: "République populaire démocratique de Corée",
    official_name_ru: "Корейская Народно-Демократическая Республика",
  },
  {
    "CLDR display name": "Congo - Kinshasa",
    Capital: "Kinshasa",
    Continent: "AF",
    DS: "ZRE",
    "Developed / Developing Countries": "Developing",
    Dial: "243",
    EDGAR: null,
    FIFA: "COD",
    FIPS: "CG",
    GAUL: "68",
    "Geoname ID": 203312,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "COD",
    "ISO3166-1-Alpha-2": "CD",
    "ISO3166-1-Alpha-3": "COD",
    "ISO3166-1-numeric": "180",
    "ISO4217-currency_alphabetic_code": "CDF",
    "ISO4217-currency_country_name": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Congolese Franc",
    "ISO4217-currency_numeric_code": "976",
    ITU: "COD",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-CD,ln,ktu,kg,sw,lua",
    "Least Developed Countries (LDC)": "x",
    M49: 180,
    MARC: "cg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".cd",
    "UNTERM Arabic Formal": "جمهورية الكونغو الديمقراطية",
    "UNTERM Arabic Short": "جمهورية الكونغو الديمقراطية",
    "UNTERM Chinese Formal": "刚果民主共和国",
    "UNTERM Chinese Short": "刚果民主共和国",
    "UNTERM English Formal": "the Democratic Republic of the Congo",
    "UNTERM English Short": "Democratic Republic of the Congo (the)",
    "UNTERM French Formal": "la République démocratique du Congo",
    "UNTERM French Short": "République démocratique du Congo (la)",
    "UNTERM Russian Formal": "Демократическая Республика Конго",
    "UNTERM Russian Short": "Демократическая Республика Конго",
    "UNTERM Spanish Formal": "la República Democrática del Congo",
    "UNTERM Spanish Short": "República Democrática del Congo (la)",
    WMO: "ZR",
    is_independent: "Yes",
    official_name_ar: "جمهورية الكونغو الديمقراطية",
    official_name_cn: "刚果民主共和国",
    official_name_en: "Democratic Republic of the Congo",
    official_name_es: "República Democrática del Congo",
    official_name_fr: "République démocratique du Congo",
    official_name_ru: "Демократическая Республика Конго",
  },
  {
    "CLDR display name": "Denmark",
    Capital: "Copenhagen",
    Continent: "EU",
    DS: "DK",
    "Developed / Developing Countries": "Developed",
    Dial: "45",
    EDGAR: "G7",
    FIFA: "DEN",
    FIPS: "DA",
    GAUL: "69",
    "Geoname ID": 2623032,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "DEN",
    "ISO3166-1-Alpha-2": "DK",
    "ISO3166-1-Alpha-3": "DNK",
    "ISO3166-1-numeric": "208",
    "ISO4217-currency_alphabetic_code": "DKK",
    "ISO4217-currency_country_name": "DENMARK",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Danish Krone",
    "ISO4217-currency_numeric_code": "208",
    ITU: "DNK",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "da-DK,en,fo,de-DK",
    "Least Developed Countries (LDC)": null,
    M49: 208,
    MARC: "dk",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".dk",
    "UNTERM Arabic Formal": "مملكة الدانمرك",
    "UNTERM Arabic Short": "الدانمرك",
    "UNTERM Chinese Formal": "丹麦王国",
    "UNTERM Chinese Short": "丹麦",
    "UNTERM English Formal": "the Kingdom of Denmark",
    "UNTERM English Short": "Denmark",
    "UNTERM French Formal": "le Royaume du Danemark",
    "UNTERM French Short": "Danemark (le)",
    "UNTERM Russian Formal": "Королевство Дания",
    "UNTERM Russian Short": "Дания",
    "UNTERM Spanish Formal": "el Reino de Dinamarca",
    "UNTERM Spanish Short": "Dinamarca",
    WMO: "DN",
    is_independent: "Yes",
    official_name_ar: "الدانمرك",
    official_name_cn: "丹麦",
    official_name_en: "Denmark",
    official_name_es: "Dinamarca",
    official_name_fr: "Danemark",
    official_name_ru: "Дания",
  },
  {
    "CLDR display name": "Djibouti",
    Capital: "Djibouti",
    Continent: "AF",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "253",
    EDGAR: "1G",
    FIFA: "DJI",
    FIPS: "DJ",
    GAUL: "70",
    "Geoname ID": 223816,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "DJI",
    "ISO3166-1-Alpha-2": "DJ",
    "ISO3166-1-Alpha-3": "DJI",
    "ISO3166-1-numeric": "262",
    "ISO4217-currency_alphabetic_code": "DJF",
    "ISO4217-currency_country_name": "DJIBOUTI",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Djibouti Franc",
    "ISO4217-currency_numeric_code": "262",
    ITU: "DJI",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-DJ,ar,so-DJ,aa",
    "Least Developed Countries (LDC)": "x",
    M49: 262,
    MARC: "ft",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".dj",
    "UNTERM Arabic Formal": "جمهورية جيبوتي",
    "UNTERM Arabic Short": "جيبوتي",
    "UNTERM Chinese Formal": "吉布提共和国",
    "UNTERM Chinese Short": "吉布提",
    "UNTERM English Formal": "the Republic of Djibouti",
    "UNTERM English Short": "Djibouti",
    "UNTERM French Formal": "la République de Djibouti",
    "UNTERM French Short": "Djibouti [masc.]",
    "UNTERM Russian Formal": "Республика Джибути",
    "UNTERM Russian Short": "Джибути",
    "UNTERM Spanish Formal": "la República de Djibouti",
    "UNTERM Spanish Short": "Djibouti",
    WMO: "DJ",
    is_independent: "Yes",
    official_name_ar: "جيبوتي",
    official_name_cn: "吉布提",
    official_name_en: "Djibouti",
    official_name_es: "Djibouti",
    official_name_fr: "Djibouti",
    official_name_ru: "Джибути",
  },
  {
    "CLDR display name": "Dominica",
    Capital: "Roseau",
    Continent: "NA",
    DS: "WD",
    "Developed / Developing Countries": "Developing",
    Dial: "1-767",
    EDGAR: "G9",
    FIFA: "DMA",
    FIPS: "DO",
    GAUL: "71",
    "Geoname ID": 3575830,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "DMA",
    "ISO3166-1-Alpha-2": "DM",
    "ISO3166-1-Alpha-3": "DMA",
    "ISO3166-1-numeric": "212",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "DOMINICA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "DMA",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-DM",
    "Least Developed Countries (LDC)": null,
    M49: 212,
    MARC: "dq",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".dm",
    "UNTERM Arabic Formal": "كمنولث دومينيكا",
    "UNTERM Arabic Short": "دومينيكا",
    "UNTERM Chinese Formal": "多米尼克国",
    "UNTERM Chinese Short": "多米尼克",
    "UNTERM English Formal": "the Commonwealth of Dominica",
    "UNTERM English Short": "Dominica",
    "UNTERM French Formal": "le Commonwealth de Dominique",
    "UNTERM French Short": "Dominique (la)",
    "UNTERM Russian Formal": "Содружество Доминики",
    "UNTERM Russian Short": "Доминика",
    "UNTERM Spanish Formal": "el Commonwealth de Dominica",
    "UNTERM Spanish Short": "Dominica",
    WMO: "DO",
    is_independent: "Yes",
    official_name_ar: "دومينيكا",
    official_name_cn: "多米尼克",
    official_name_en: "Dominica",
    official_name_es: "Dominica",
    official_name_fr: "Dominique",
    official_name_ru: "Доминика",
  },
  {
    "CLDR display name": "Dominican Republic",
    Capital: "Santo Domingo",
    Continent: "NA",
    DS: "DOM",
    "Developed / Developing Countries": "Developing",
    Dial: "1-809,1-829,1-849",
    EDGAR: "G8",
    FIFA: "DOM",
    FIPS: "DR",
    GAUL: "72",
    "Geoname ID": 3508796,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "DOM",
    "ISO3166-1-Alpha-2": "DO",
    "ISO3166-1-Alpha-3": "DOM",
    "ISO3166-1-numeric": "214",
    "ISO4217-currency_alphabetic_code": "DOP",
    "ISO4217-currency_country_name": "DOMINICAN REPUBLIC",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Dominican Peso",
    "ISO4217-currency_numeric_code": "214",
    ITU: "DOM",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-DO",
    "Least Developed Countries (LDC)": null,
    M49: 214,
    MARC: "dr",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".do",
    "UNTERM Arabic Formal": "الجمهورية الدومينيكية",
    "UNTERM Arabic Short": "الجمهورية الدومينيكية",
    "UNTERM Chinese Formal": "多米尼加共和国",
    "UNTERM Chinese Short": "多米尼加",
    "UNTERM English Formal": "the Dominican Republic",
    "UNTERM English Short": "Dominican Republic (the)",
    "UNTERM French Formal": "la République dominicaine",
    "UNTERM French Short": "République dominicaine (la)",
    "UNTERM Russian Formal": "Доминиканская Республика",
    "UNTERM Russian Short": "Доминиканская Республика",
    "UNTERM Spanish Formal": "la República Dominicana",
    "UNTERM Spanish Short": "República Dominicana (la)",
    WMO: "DR",
    is_independent: "Yes",
    official_name_ar: "الجمهورية الدومينيكية",
    official_name_cn: "多米尼加",
    official_name_en: "Dominican Republic",
    official_name_es: "República Dominicana",
    official_name_fr: "République dominicaine",
    official_name_ru: "Доминиканская Республика",
  },
  {
    "CLDR display name": "Ecuador",
    Capital: "Quito",
    Continent: "SA",
    DS: "EC",
    "Developed / Developing Countries": "Developing",
    Dial: "593",
    EDGAR: "H1",
    FIFA: "ECU",
    FIPS: "EC",
    GAUL: "73",
    "Geoname ID": 3658394,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ECU",
    "ISO3166-1-Alpha-2": "EC",
    "ISO3166-1-Alpha-3": "ECU",
    "ISO3166-1-numeric": "218",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "ECUADOR",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "EQA",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-EC",
    "Least Developed Countries (LDC)": null,
    M49: 218,
    MARC: "ec",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ec",
    "UNTERM Arabic Formal": "جمهورية إكوادور",
    "UNTERM Arabic Short": "إكوادور",
    "UNTERM Chinese Formal": "厄瓜多尔共和国",
    "UNTERM Chinese Short": "厄瓜多尔",
    "UNTERM English Formal": "the Republic of Ecuador",
    "UNTERM English Short": "Ecuador",
    "UNTERM French Formal": "la République de l'Équateur",
    "UNTERM French Short": "Équateur (l') [masc.]",
    "UNTERM Russian Formal": "Республика Эквадор",
    "UNTERM Russian Short": "Эквадор",
    "UNTERM Spanish Formal": "la República del Ecuador",
    "UNTERM Spanish Short": "Ecuador (el)",
    WMO: "EQ",
    is_independent: "Yes",
    official_name_ar: "إكوادور",
    official_name_cn: "厄瓜多尔",
    official_name_en: "Ecuador",
    official_name_es: "Ecuador",
    official_name_fr: "Équateur",
    official_name_ru: "Эквадор",
  },
  {
    "CLDR display name": "Egypt",
    Capital: "Cairo",
    Continent: "AF",
    DS: "ET",
    "Developed / Developing Countries": "Developing",
    Dial: "20",
    EDGAR: "H2",
    FIFA: "EGY",
    FIPS: "EG",
    GAUL: "40765",
    "Geoname ID": 357994,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "EGY",
    "ISO3166-1-Alpha-2": "EG",
    "ISO3166-1-Alpha-3": "EGY",
    "ISO3166-1-numeric": "818",
    "ISO4217-currency_alphabetic_code": "EGP",
    "ISO4217-currency_country_name": "EGYPT",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Egyptian Pound",
    "ISO4217-currency_numeric_code": "818",
    ITU: "EGY",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-EG,en,fr",
    "Least Developed Countries (LDC)": null,
    M49: 818,
    MARC: "ua",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".eg",
    "UNTERM Arabic Formal": "جمهورية مصر العربية",
    "UNTERM Arabic Short": "مصر",
    "UNTERM Chinese Formal": "阿拉伯埃及共和国",
    "UNTERM Chinese Short": "埃及",
    "UNTERM English Formal": "the Arab Republic of Egypt",
    "UNTERM English Short": "Egypt",
    "UNTERM French Formal": "la République arabe d'Égypte",
    "UNTERM French Short": "Égypte (l') [fém.]",
    "UNTERM Russian Formal": "Арабская Республика Египет",
    "UNTERM Russian Short": "Египет",
    "UNTERM Spanish Formal": "la República Árabe de Egipto",
    "UNTERM Spanish Short": "Egipto",
    WMO: "EG",
    is_independent: "Yes",
    official_name_ar: "مصر",
    official_name_cn: "埃及",
    official_name_en: "Egypt",
    official_name_es: "Egipto",
    official_name_fr: "Égypte",
    official_name_ru: "Египет",
  },
  {
    "CLDR display name": "El Salvador",
    Capital: "San Salvador",
    Continent: "NA",
    DS: "ES",
    "Developed / Developing Countries": "Developing",
    Dial: "503",
    EDGAR: "H3",
    FIFA: "SLV",
    FIPS: "ES",
    GAUL: "75",
    "Geoname ID": 3585968,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ESA",
    "ISO3166-1-Alpha-2": "SV",
    "ISO3166-1-Alpha-3": "SLV",
    "ISO3166-1-numeric": "222",
    "ISO4217-currency_alphabetic_code": "SVC,USD",
    "ISO4217-currency_country_name": "EL SALVADOR",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "El Salvador Colon,US Dollar",
    "ISO4217-currency_numeric_code": "222,840",
    ITU: "SLV",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-SV",
    "Least Developed Countries (LDC)": null,
    M49: 222,
    MARC: "es",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".sv",
    "UNTERM Arabic Formal": "جمهورية السلفادور",
    "UNTERM Arabic Short": "السلفادور",
    "UNTERM Chinese Formal": "萨尔瓦多共和国",
    "UNTERM Chinese Short": "萨尔瓦多",
    "UNTERM English Formal": "the Republic of El Salvador",
    "UNTERM English Short": "El Salvador",
    "UNTERM French Formal": "la République d'El Salvador",
    "UNTERM French Short": "El Salvador [masc.]",
    "UNTERM Russian Formal": "Республика Эль-Сальвадор",
    "UNTERM Russian Short": "Сальвадор",
    "UNTERM Spanish Formal": "la República de El Salvador",
    "UNTERM Spanish Short": "El Salvador",
    WMO: "ES",
    is_independent: "Yes",
    official_name_ar: "السلفادور",
    official_name_cn: "萨尔瓦多",
    official_name_en: "El Salvador",
    official_name_es: "El Salvador",
    official_name_fr: "El Salvador",
    official_name_ru: "Сальвадор",
  },
  {
    "CLDR display name": "Equatorial Guinea",
    Capital: "Malabo",
    Continent: "AF",
    DS: "EQ",
    "Developed / Developing Countries": "Developing",
    Dial: "240",
    EDGAR: "H4",
    FIFA: "EQG",
    FIPS: "EK",
    GAUL: "76",
    "Geoname ID": 2309096,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GEQ",
    "ISO3166-1-Alpha-2": "GQ",
    "ISO3166-1-Alpha-3": "GNQ",
    "ISO3166-1-numeric": "226",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "EQUATORIAL GUINEA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "GNE",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-GQ,fr",
    "Least Developed Countries (LDC)": null,
    M49: 226,
    MARC: "eg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".gq",
    "UNTERM Arabic Formal": "جمهورية غينيا الاستوائية",
    "UNTERM Arabic Short": "غينيا الاستوائية",
    "UNTERM Chinese Formal": "赤道几内亚共和国",
    "UNTERM Chinese Short": "赤道几内亚",
    "UNTERM English Formal": "the Republic of Equatorial Guinea",
    "UNTERM English Short": "Equatorial Guinea",
    "UNTERM French Formal": "la République de Guinée équatoriale",
    "UNTERM French Short": "Guinée équatoriale (la)",
    "UNTERM Russian Formal": "Республика Экваториальная Гвинея",
    "UNTERM Russian Short": "Экваториальная Гвинея",
    "UNTERM Spanish Formal": "la República de Guinea Ecuatorial",
    "UNTERM Spanish Short": "Guinea Ecuatorial",
    WMO: "GQ",
    is_independent: "Yes",
    official_name_ar: "غينيا الاستوائية",
    official_name_cn: "赤道几内亚",
    official_name_en: "Equatorial Guinea",
    official_name_es: "Guinea Ecuatorial",
    official_name_fr: "Guinée équatoriale",
    official_name_ru: "Экваториальная Гвинея",
  },
  {
    "CLDR display name": "Eritrea",
    Capital: "Asmara",
    Continent: "AF",
    DS: "ER",
    "Developed / Developing Countries": "Developing",
    Dial: "291",
    EDGAR: "1J",
    FIFA: "ERI",
    FIPS: "ER",
    GAUL: "77",
    "Geoname ID": 338010,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ERI",
    "ISO3166-1-Alpha-2": "ER",
    "ISO3166-1-Alpha-3": "ERI",
    "ISO3166-1-numeric": "232",
    "ISO4217-currency_alphabetic_code": "ERN",
    "ISO4217-currency_country_name": "ERITREA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Nakfa",
    "ISO4217-currency_numeric_code": "232",
    ITU: "ERI",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "aa-ER,ar,tig,kun,ti-ER",
    "Least Developed Countries (LDC)": "x",
    M49: 232,
    MARC: "ea",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".er",
    "UNTERM Arabic Formal": "دولة إريتريا",
    "UNTERM Arabic Short": "إريتريا",
    "UNTERM Chinese Formal": "厄立特里亚国",
    "UNTERM Chinese Short": "厄立特里亚",
    "UNTERM English Formal": "the State of Eritrea",
    "UNTERM English Short": "Eritrea",
    "UNTERM French Formal": "l'État d'Érythrée",
    "UNTERM French Short": "Érythrée (l') [fém.]",
    "UNTERM Russian Formal": "Государство Эритрея",
    "UNTERM Russian Short": "Эритрея",
    "UNTERM Spanish Formal": "el Estado de Eritrea",
    "UNTERM Spanish Short": "Eritrea",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "إريتريا",
    official_name_cn: "厄立特里亚",
    official_name_en: "Eritrea",
    official_name_es: "Eritrea",
    official_name_fr: "Érythrée",
    official_name_ru: "Эритрея",
  },
  {
    "CLDR display name": "Estonia",
    Capital: "Tallinn",
    Continent: "EU",
    DS: "EST",
    "Developed / Developing Countries": "Developed",
    Dial: "372",
    EDGAR: "1H",
    FIFA: "EST",
    FIPS: "EN",
    GAUL: "78",
    "Geoname ID": 453733,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "EST",
    "ISO3166-1-Alpha-2": "EE",
    "ISO3166-1-Alpha-3": "EST",
    "ISO3166-1-numeric": "233",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "ESTONIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "EST",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "et,ru",
    "Least Developed Countries (LDC)": null,
    M49: 233,
    MARC: "er",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".ee",
    "UNTERM Arabic Formal": "جمهورية إستونيا",
    "UNTERM Arabic Short": "إستونيا",
    "UNTERM Chinese Formal": "爱沙尼亚共和国",
    "UNTERM Chinese Short": "爱沙尼亚",
    "UNTERM English Formal": "the Republic of Estonia",
    "UNTERM English Short": "Estonia",
    "UNTERM French Formal": "la République d'Estonie",
    "UNTERM French Short": "Estonie (l') [fém.]",
    "UNTERM Russian Formal": "Эстонская Республика",
    "UNTERM Russian Short": "Эстония",
    "UNTERM Spanish Formal": "la República de Estonia",
    "UNTERM Spanish Short": "Estonia",
    WMO: "EO",
    is_independent: "Yes",
    official_name_ar: "إستونيا",
    official_name_cn: "爱沙尼亚",
    official_name_en: "Estonia",
    official_name_es: "Estonia",
    official_name_fr: "Estonie",
    official_name_ru: "Эстония",
  },
  {
    "CLDR display name": "Eswatini",
    Capital: "Mbabane",
    Continent: "AF",
    DS: "SD",
    "Developed / Developing Countries": "Developing",
    Dial: "268",
    EDGAR: null,
    FIFA: "SWZ",
    FIPS: "WZ",
    GAUL: "235",
    "Geoname ID": 934841,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SWZ",
    "ISO3166-1-Alpha-2": "SZ",
    "ISO3166-1-Alpha-3": "SWZ",
    "ISO3166-1-numeric": "748",
    "ISO4217-currency_alphabetic_code": "SZL",
    "ISO4217-currency_country_name": "ESWATINI",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lilangeni",
    "ISO4217-currency_numeric_code": "748",
    ITU: "SWZ",
    "Intermediate Region Code": "18",
    "Intermediate Region Name": "Southern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-SZ,ss-SZ",
    "Least Developed Countries (LDC)": null,
    M49: 748,
    MARC: "sq",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".sz",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "SV",
    is_independent: "Yes",
    official_name_ar: "إسواتيني",
    official_name_cn: "斯威士兰",
    official_name_en: "Eswatini",
    official_name_es: "Eswatini",
    official_name_fr: "Eswatini",
    official_name_ru: "Эсватини",
  },
  {
    "CLDR display name": "Ethiopia",
    Capital: "Addis Ababa",
    Continent: "AF",
    DS: "ETH",
    "Developed / Developing Countries": "Developing",
    Dial: "251",
    EDGAR: "H5",
    FIFA: "ETH",
    FIPS: "ET",
    GAUL: "79",
    "Geoname ID": 337996,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ETH",
    "ISO3166-1-Alpha-2": "ET",
    "ISO3166-1-Alpha-3": "ETH",
    "ISO3166-1-numeric": "231",
    "ISO4217-currency_alphabetic_code": "ETB",
    "ISO4217-currency_country_name": "ETHIOPIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Ethiopian Birr",
    "ISO4217-currency_numeric_code": "230",
    ITU: "ETH",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "am,en-ET,om-ET,ti-ET,so-ET,sid",
    "Least Developed Countries (LDC)": "x",
    M49: 231,
    MARC: "et",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".et",
    "UNTERM Arabic Formal": "جمهورية إثيوبيا الديمقراطية الاتحادية",
    "UNTERM Arabic Short": "إثيوبيا",
    "UNTERM Chinese Formal": "埃塞俄比亚联邦民主共和国",
    "UNTERM Chinese Short": "埃塞俄比亚",
    "UNTERM English Formal": "the Federal Democratic Republic of Ethiopia",
    "UNTERM English Short": "Ethiopia",
    "UNTERM French Formal": "la République fédérale démocratique d'Éthiopie",
    "UNTERM French Short": "Éthiopie (l') [fém.]",
    "UNTERM Russian Formal": "Федеративная Демократическая Республика Эфиопия",
    "UNTERM Russian Short": "Эфиопия",
    "UNTERM Spanish Formal": "la República Democrática Federal de Etiopía",
    "UNTERM Spanish Short": "Etiopía",
    WMO: "ET",
    is_independent: "Yes",
    official_name_ar: "إثيوبيا",
    official_name_cn: "埃塞俄比亚",
    official_name_en: "Ethiopia",
    official_name_es: "Etiopía",
    official_name_fr: "Éthiopie",
    official_name_ru: "Эфиопия",
  },
  {
    "CLDR display name": "Falkland Islands",
    Capital: "Stanley",
    Continent: "SA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "500",
    EDGAR: null,
    FIFA: "FLK",
    FIPS: "FK",
    GAUL: "81",
    "Geoname ID": 3474414,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FLK",
    "ISO3166-1-Alpha-2": "FK",
    "ISO3166-1-Alpha-3": "FLK",
    "ISO3166-1-numeric": "238",
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name": null,
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": null,
    "ISO4217-currency_numeric_code": null,
    ITU: "FLK",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-FK",
    "Least Developed Countries (LDC)": null,
    M49: 238,
    MARC: "fk",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".fk",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "FK",
    is_independent: "Territory of GB",
    official_name_ar: "جزر فوكلاند (مالفيناس)",
    official_name_cn: "福克兰群岛(马尔维纳斯)",
    official_name_en: "Falkland Islands (Malvinas)",
    official_name_es: "Islas Malvinas (Falkland)",
    official_name_fr: "Îles Falkland (Malvinas)",
    official_name_ru: "Фолклендские (Мальвинские) острова",
  },
  {
    "CLDR display name": "Faroe Islands",
    Capital: "Torshavn",
    Continent: "EU",
    DS: "FO",
    "Developed / Developing Countries": "Developed",
    Dial: "298",
    EDGAR: "H6",
    FIFA: "FRO",
    FIPS: "FO",
    GAUL: "82",
    "Geoname ID": 2622320,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FAR",
    "ISO3166-1-Alpha-2": "FO",
    "ISO3166-1-Alpha-3": "FRO",
    "ISO3166-1-numeric": "234",
    "ISO4217-currency_alphabetic_code": "DKK",
    "ISO4217-currency_country_name": "FAROE ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Danish Krone",
    "ISO4217-currency_numeric_code": "208",
    ITU: "FRO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fo,da-FO",
    "Least Developed Countries (LDC)": null,
    M49: 234,
    MARC: "fa",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".fo",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "FA",
    is_independent: "Part of DK",
    official_name_ar: "جزر فايرو",
    official_name_cn: "法罗群岛",
    official_name_en: "Faroe Islands",
    official_name_es: "Islas Feroe",
    official_name_fr: "Îles Féroé",
    official_name_ru: "Фарерские острова",
  },
  {
    "CLDR display name": "Fiji",
    Capital: "Suva",
    Continent: "OC",
    DS: "FJI",
    "Developed / Developing Countries": "Developing",
    Dial: "679",
    EDGAR: "H8",
    FIFA: "FIJ",
    FIPS: "FJ",
    GAUL: "83",
    "Geoname ID": 2205218,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FIJ",
    "ISO3166-1-Alpha-2": "FJ",
    "ISO3166-1-Alpha-3": "FJI",
    "ISO3166-1-numeric": "242",
    "ISO4217-currency_alphabetic_code": "FJD",
    "ISO4217-currency_country_name": "FIJI",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Fiji Dollar",
    "ISO4217-currency_numeric_code": "242",
    ITU: "FJI",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-FJ,fj",
    "Least Developed Countries (LDC)": null,
    M49: 242,
    MARC: "fj",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "54",
    "Sub-region Name": "Melanesia",
    TLD: ".fj",
    "UNTERM Arabic Formal": "جمهورية فيجي",
    "UNTERM Arabic Short": "فيجي",
    "UNTERM Chinese Formal": "斐济共和国",
    "UNTERM Chinese Short": "斐济",
    "UNTERM English Formal": "the Republic of Fiji",
    "UNTERM English Short": "Fiji",
    "UNTERM French Formal": "la République des Fidji",
    "UNTERM French Short": "Fidji (les) [fém.]",
    "UNTERM Russian Formal": "Республика  Фиджи",
    "UNTERM Russian Short": "Фиджи",
    "UNTERM Spanish Formal": "la República de Fiji",
    "UNTERM Spanish Short": "Fiji",
    WMO: "FJ",
    is_independent: "Yes",
    official_name_ar: "فيجي",
    official_name_cn: "斐济",
    official_name_en: "Fiji",
    official_name_es: "Fiji",
    official_name_fr: "Fidji",
    official_name_ru: "Фиджи",
  },
  {
    "CLDR display name": "Finland",
    Capital: "Helsinki",
    Continent: "EU",
    DS: "FIN",
    "Developed / Developing Countries": "Developed",
    Dial: "358",
    EDGAR: "H9",
    FIFA: "FIN",
    FIPS: "FI",
    GAUL: "84",
    "Geoname ID": 660013,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FIN",
    "ISO3166-1-Alpha-2": "FI",
    "ISO3166-1-Alpha-3": "FIN",
    "ISO3166-1-numeric": "246",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "FINLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "FIN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fi-FI,sv-FI,smn",
    "Least Developed Countries (LDC)": null,
    M49: 246,
    MARC: "fi",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".fi",
    "UNTERM Arabic Formal": "جمهورية فنلندا",
    "UNTERM Arabic Short": "فنلندا",
    "UNTERM Chinese Formal": "芬兰共和国",
    "UNTERM Chinese Short": "芬兰",
    "UNTERM English Formal": "the Republic of Finland",
    "UNTERM English Short": "Finland",
    "UNTERM French Formal": "la République de Finlande",
    "UNTERM French Short": "Finlande (la)",
    "UNTERM Russian Formal": "Финляндская Республика",
    "UNTERM Russian Short": "Финляндия",
    "UNTERM Spanish Formal": "la República de Finlandia",
    "UNTERM Spanish Short": "Finlandia",
    WMO: "FI",
    is_independent: "Yes",
    official_name_ar: "فنلندا",
    official_name_cn: "芬兰",
    official_name_en: "Finland",
    official_name_es: "Finlandia",
    official_name_fr: "Finlande",
    official_name_ru: "Финляндия",
  },
  {
    "CLDR display name": "France",
    Capital: "Paris",
    Continent: "EU",
    DS: "F",
    "Developed / Developing Countries": "Developed",
    Dial: "33",
    EDGAR: "I0",
    FIFA: "FRA",
    FIPS: "FR",
    GAUL: "85",
    "Geoname ID": 3017382,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FRA",
    "ISO3166-1-Alpha-2": "FR",
    "ISO3166-1-Alpha-3": "FRA",
    "ISO3166-1-numeric": "250",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "FRANCE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "F",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-FR,frp,br,co,ca,eu,oc",
    "Least Developed Countries (LDC)": null,
    M49: 250,
    MARC: "fr",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".fr",
    "UNTERM Arabic Formal": "الجمهورية الفرنسية",
    "UNTERM Arabic Short": "فرنسا",
    "UNTERM Chinese Formal": "法兰西共和国",
    "UNTERM Chinese Short": "法国",
    "UNTERM English Formal": "the French Republic",
    "UNTERM English Short": "France",
    "UNTERM French Formal": "la République française",
    "UNTERM French Short": "France (la)",
    "UNTERM Russian Formal": "Французская Республика",
    "UNTERM Russian Short": "Франция",
    "UNTERM Spanish Formal": "la República Francesa",
    "UNTERM Spanish Short": "Francia",
    WMO: "FR",
    is_independent: "Yes",
    official_name_ar: "فرنسا",
    official_name_cn: "法国",
    official_name_en: "France",
    official_name_es: "Francia",
    official_name_fr: "France",
    official_name_ru: "Франция",
  },
  {
    "CLDR display name": "French Guiana",
    Capital: "Cayenne",
    Continent: "SA",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "594",
    EDGAR: "I3",
    FIFA: "GUF",
    FIPS: "FG",
    GAUL: "86",
    "Geoname ID": 3381670,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FGU",
    "ISO3166-1-Alpha-2": "GF",
    "ISO3166-1-Alpha-3": "GUF",
    "ISO3166-1-numeric": "254",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "FRENCH GUIANA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "GUF",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-GF",
    "Least Developed Countries (LDC)": null,
    M49: 254,
    MARC: "fg",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gf",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "FG",
    is_independent: "Part of FR",
    official_name_ar: "غيانا الفرنسية",
    official_name_cn: "法属圭亚那",
    official_name_en: "French Guiana",
    official_name_es: "Guayana Francesa",
    official_name_fr: "Guyane française",
    official_name_ru: "Французская Гвиана",
  },
  {
    "CLDR display name": "French Polynesia",
    Capital: "Papeete",
    Continent: "OC",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "689",
    EDGAR: "I4",
    FIFA: "TAH",
    FIPS: "FP",
    GAUL: "87",
    "Geoname ID": 4030656,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FPO",
    "ISO3166-1-Alpha-2": "PF",
    "ISO3166-1-Alpha-3": "PYF",
    "ISO3166-1-numeric": "258",
    "ISO4217-currency_alphabetic_code": "XPF",
    "ISO4217-currency_country_name": "FRENCH POLYNESIA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFP Franc",
    "ISO4217-currency_numeric_code": "953",
    ITU: "OCE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-PF,ty",
    "Least Developed Countries (LDC)": null,
    M49: 258,
    MARC: "fp",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".pf",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "PF",
    is_independent: "Territory of FR",
    official_name_ar: "بولينيزيا الفرنسية",
    official_name_cn: "法属波利尼西亚",
    official_name_en: "French Polynesia",
    official_name_es: "Polinesia Francesa",
    official_name_fr: "Polynésie française",
    official_name_ru: "Французская Полинезия",
  },
  {
    "CLDR display name": "French Southern Territories",
    Capital: "Port-aux-Francais",
    Continent: "AN",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "262",
    EDGAR: "2C",
    FIFA: null,
    FIPS: "FS",
    GAUL: "88",
    "Geoname ID": 1546748,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "TF",
    "ISO3166-1-Alpha-3": "ATF",
    "ISO3166-1-numeric": "260",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "FRENCH SOUTHERN TERRITORIES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: null,
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr",
    "Least Developed Countries (LDC)": null,
    M49: 260,
    MARC: "fs",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".tf",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of FR",
    official_name_ar: "الأراضي الفرنسية الجنوبية الجنوبية",
    official_name_cn: "法属南方领地",
    official_name_en: "French Southern Territories",
    official_name_es: "Territorio de las Tierras Australes Francesas",
    official_name_fr: "Terres australes françaises",
    official_name_ru: "Южные земли (французская заморская территория)",
  },
  {
    "CLDR display name": "Gabon",
    Capital: "Libreville",
    Continent: "AF",
    DS: "G",
    "Developed / Developing Countries": "Developing",
    Dial: "241",
    EDGAR: "I5",
    FIFA: "GAB",
    FIPS: "GB",
    GAUL: "89",
    "Geoname ID": 2400553,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GAB",
    "ISO3166-1-Alpha-2": "GA",
    "ISO3166-1-Alpha-3": "GAB",
    "ISO3166-1-numeric": "266",
    "ISO4217-currency_alphabetic_code": "XAF",
    "ISO4217-currency_country_name": "GABON",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BEAC",
    "ISO4217-currency_numeric_code": "950",
    ITU: "GAB",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-GA",
    "Least Developed Countries (LDC)": null,
    M49: 266,
    MARC: "go",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ga",
    "UNTERM Arabic Formal": "جمهورية الغابون",
    "UNTERM Arabic Short": "غابون",
    "UNTERM Chinese Formal": "加蓬共和国",
    "UNTERM Chinese Short": "加蓬",
    "UNTERM English Formal": "the Gabonese Republic",
    "UNTERM English Short": "Gabon",
    "UNTERM French Formal": "la République gabonaise",
    "UNTERM French Short": "Gabon (le)",
    "UNTERM Russian Formal": "Габонская Республика",
    "UNTERM Russian Short": "Габон",
    "UNTERM Spanish Formal": "la República Gabonesa",
    "UNTERM Spanish Short": "Gabón (el)",
    WMO: "GO",
    is_independent: "Yes",
    official_name_ar: "غابون",
    official_name_cn: "加蓬",
    official_name_en: "Gabon",
    official_name_es: "Gabón",
    official_name_fr: "Gabon",
    official_name_ru: "Габон",
  },
  {
    "CLDR display name": "Gambia",
    Capital: "Banjul",
    Continent: "AF",
    DS: "WAG",
    "Developed / Developing Countries": "Developing",
    Dial: "220",
    EDGAR: "I6",
    FIFA: "GAM",
    FIPS: "GA",
    GAUL: "90",
    "Geoname ID": 2413451,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GAM",
    "ISO3166-1-Alpha-2": "GM",
    "ISO3166-1-Alpha-3": "GMB",
    "ISO3166-1-numeric": "270",
    "ISO4217-currency_alphabetic_code": "GMD",
    "ISO4217-currency_country_name": "GAMBIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Dalasi",
    "ISO4217-currency_numeric_code": "270",
    ITU: "GMB",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GM,mnk,wof,wo,ff",
    "Least Developed Countries (LDC)": "x",
    M49: 270,
    MARC: "gm",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".gm",
    "UNTERM Arabic Formal": "جمهورية غامبيا",
    "UNTERM Arabic Short": "غامبيا",
    "UNTERM Chinese Formal": "冈比亚共和国",
    "UNTERM Chinese Short": "冈比亚",
    "UNTERM English Formal": "the Republic of the Gambia",
    "UNTERM English Short": "Gambia (the)",
    "UNTERM French Formal": "la République de Gambie",
    "UNTERM French Short": "Gambie (la)",
    "UNTERM Russian Formal": "Республика Гамбия",
    "UNTERM Russian Short": "Гамбия",
    "UNTERM Spanish Formal": "la República de Gambia",
    "UNTERM Spanish Short": "Gambia",
    WMO: "GB",
    is_independent: "Yes",
    official_name_ar: "غامبيا",
    official_name_cn: "冈比亚",
    official_name_en: "Gambia",
    official_name_es: "Gambia",
    official_name_fr: "Gambie",
    official_name_ru: "Гамбия",
  },
  {
    "CLDR display name": "Georgia",
    Capital: "Tbilisi",
    Continent: "AS",
    DS: "GE",
    "Developed / Developing Countries": "Developing",
    Dial: "995",
    EDGAR: "2Q",
    FIFA: "GEO",
    FIPS: "GG",
    GAUL: "92",
    "Geoname ID": 614540,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GEO",
    "ISO3166-1-Alpha-2": "GE",
    "ISO3166-1-Alpha-3": "GEO",
    "ISO3166-1-numeric": "268",
    "ISO4217-currency_alphabetic_code": "GEL",
    "ISO4217-currency_country_name": "GEORGIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lari",
    "ISO4217-currency_numeric_code": "981",
    ITU: "GEO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ka,ru,hy,az",
    "Least Developed Countries (LDC)": null,
    M49: 268,
    MARC: "gs",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".ge",
    "UNTERM Arabic Formal": "جورجيا",
    "UNTERM Arabic Short": "جورجيا",
    "UNTERM Chinese Formal": "格鲁吉亚",
    "UNTERM Chinese Short": "格鲁吉亚",
    "UNTERM English Formal": "Georgia",
    "UNTERM English Short": "Georgia",
    "UNTERM French Formal": "la Géorgie",
    "UNTERM French Short": "Géorgie (la)",
    "UNTERM Russian Formal": "Грузия",
    "UNTERM Russian Short": "Грузия",
    "UNTERM Spanish Formal": "Georgia",
    "UNTERM Spanish Short": "Georgia",
    WMO: "GG",
    is_independent: "Yes",
    official_name_ar: "جورجيا",
    official_name_cn: "格鲁吉亚",
    official_name_en: "Georgia",
    official_name_es: "Georgia",
    official_name_fr: "Géorgie",
    official_name_ru: "Грузия",
  },
  {
    "CLDR display name": "Germany",
    Capital: "Berlin",
    Continent: "EU",
    DS: "D",
    "Developed / Developing Countries": "Developed",
    Dial: "49",
    EDGAR: "2M",
    FIFA: "GER",
    FIPS: "GM",
    GAUL: "93",
    "Geoname ID": 2921044,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GER",
    "ISO3166-1-Alpha-2": "DE",
    "ISO3166-1-Alpha-3": "DEU",
    "ISO3166-1-numeric": "276",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "GERMANY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "D",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "de",
    "Least Developed Countries (LDC)": null,
    M49: 276,
    MARC: "gw",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".de",
    "UNTERM Arabic Formal": "جمهورية ألمانيا الاتحادية",
    "UNTERM Arabic Short": "ألمانيا",
    "UNTERM Chinese Formal": "德意志联邦共和国",
    "UNTERM Chinese Short": "德国",
    "UNTERM English Formal": "the Federal Republic of Germany",
    "UNTERM English Short": "Germany",
    "UNTERM French Formal": "la République fédérale d'Allemagne",
    "UNTERM French Short": "Allemagne (l') [fém.]",
    "UNTERM Russian Formal": "Федеративная Республика Германия",
    "UNTERM Russian Short": "Германия",
    "UNTERM Spanish Formal": "la República Federal de Alemania",
    "UNTERM Spanish Short": "Alemania",
    WMO: "DL",
    is_independent: "Yes",
    official_name_ar: "ألمانيا",
    official_name_cn: "德国",
    official_name_en: "Germany",
    official_name_es: "Alemania",
    official_name_fr: "Allemagne",
    official_name_ru: "Германия",
  },
  {
    "CLDR display name": "Ghana",
    Capital: "Accra",
    Continent: "AF",
    DS: "GH",
    "Developed / Developing Countries": "Developing",
    Dial: "233",
    EDGAR: "J0",
    FIFA: "GHA",
    FIPS: "GH",
    GAUL: "94",
    "Geoname ID": 2300660,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GHA",
    "ISO3166-1-Alpha-2": "GH",
    "ISO3166-1-Alpha-3": "GHA",
    "ISO3166-1-numeric": "288",
    "ISO4217-currency_alphabetic_code": "GHS",
    "ISO4217-currency_country_name": "GHANA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Ghana Cedi",
    "ISO4217-currency_numeric_code": "936",
    ITU: "GHA",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GH,ak,ee,tw",
    "Least Developed Countries (LDC)": null,
    M49: 288,
    MARC: "gh",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".gh",
    "UNTERM Arabic Formal": "جمهورية غانا",
    "UNTERM Arabic Short": "غانا",
    "UNTERM Chinese Formal": "加纳共和国",
    "UNTERM Chinese Short": "加纳",
    "UNTERM English Formal": "the Republic of Ghana",
    "UNTERM English Short": "Ghana",
    "UNTERM French Formal": "la République du Ghana",
    "UNTERM French Short": "Ghana (le)",
    "UNTERM Russian Formal": "Республика Гана",
    "UNTERM Russian Short": "Гана",
    "UNTERM Spanish Formal": "la República de Ghana",
    "UNTERM Spanish Short": "Ghana",
    WMO: "GH",
    is_independent: "Yes",
    official_name_ar: "غانا",
    official_name_cn: "加纳",
    official_name_en: "Ghana",
    official_name_es: "Ghana",
    official_name_fr: "Ghana",
    official_name_ru: "Гана",
  },
  {
    "CLDR display name": "Gibraltar",
    Capital: "Gibraltar",
    Continent: "EU",
    DS: "GBZ",
    "Developed / Developing Countries": "Developed",
    Dial: "350",
    EDGAR: "J1",
    FIFA: "GBZ",
    FIPS: "GI",
    GAUL: "95",
    "Geoname ID": 2411586,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GIB",
    "ISO3166-1-Alpha-2": "GI",
    "ISO3166-1-Alpha-3": "GIB",
    "ISO3166-1-numeric": "292",
    "ISO4217-currency_alphabetic_code": "GIP",
    "ISO4217-currency_country_name": "GIBRALTAR",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Gibraltar Pound",
    "ISO4217-currency_numeric_code": "292",
    ITU: "GIB",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GI,es,it,pt",
    "Least Developed Countries (LDC)": null,
    M49: 292,
    MARC: "gi",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".gi",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "GI",
    is_independent: "Territory of GB",
    official_name_ar: "جبل طارق",
    official_name_cn: "直布罗陀",
    official_name_en: "Gibraltar",
    official_name_es: "Gibraltar",
    official_name_fr: "Gibraltar",
    official_name_ru: "Гибралтар",
  },
  {
    "CLDR display name": "Greece",
    Capital: "Athens",
    Continent: "EU",
    DS: "GR",
    "Developed / Developing Countries": "Developed",
    Dial: "30",
    EDGAR: "J3",
    FIFA: "GRE",
    FIPS: "GR",
    GAUL: "97",
    "Geoname ID": 390903,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GRE",
    "ISO3166-1-Alpha-2": "GR",
    "ISO3166-1-Alpha-3": "GRC",
    "ISO3166-1-numeric": "300",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "GREECE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "GRC",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "el-GR,en,fr",
    "Least Developed Countries (LDC)": null,
    M49: 300,
    MARC: "gr",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".gr",
    "UNTERM Arabic Formal": "الجمهورية الهيلينية",
    "UNTERM Arabic Short": "اليونان",
    "UNTERM Chinese Formal": "希腊共和国",
    "UNTERM Chinese Short": "希腊",
    "UNTERM English Formal": "the Hellenic Republic",
    "UNTERM English Short": "Greece",
    "UNTERM French Formal": "la République hellénique",
    "UNTERM French Short": "Grèce (la)",
    "UNTERM Russian Formal": "Греческая Республика",
    "UNTERM Russian Short": "Греция",
    "UNTERM Spanish Formal": "la República Helénica",
    "UNTERM Spanish Short": "Grecia",
    WMO: "GR",
    is_independent: "Yes",
    official_name_ar: "اليونان",
    official_name_cn: "希腊",
    official_name_en: "Greece",
    official_name_es: "Grecia",
    official_name_fr: "Grèce",
    official_name_ru: "Греция",
  },
  {
    "CLDR display name": "Greenland",
    Capital: "Nuuk",
    Continent: "NA",
    DS: "DK",
    "Developed / Developing Countries": "Developed",
    Dial: "299",
    EDGAR: "J4",
    FIFA: "GRL",
    FIPS: "GL",
    GAUL: "98",
    "Geoname ID": 3425505,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GRL",
    "ISO3166-1-Alpha-2": "GL",
    "ISO3166-1-Alpha-3": "GRL",
    "ISO3166-1-numeric": "304",
    "ISO4217-currency_alphabetic_code": "DKK",
    "ISO4217-currency_country_name": "GREENLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Danish Krone",
    "ISO4217-currency_numeric_code": "208",
    ITU: "GRL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "kl,da-GL,en",
    "Least Developed Countries (LDC)": null,
    M49: 304,
    MARC: "gl",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "21",
    "Sub-region Name": "Northern America",
    TLD: ".gl",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "GL",
    is_independent: "Part of DK",
    official_name_ar: "غرينلند",
    official_name_cn: "格陵兰",
    official_name_en: "Greenland",
    official_name_es: "Groenlandia",
    official_name_fr: "Groenland",
    official_name_ru: "Гренландия",
  },
  {
    "CLDR display name": "Grenada",
    Capital: "St. George's",
    Continent: "NA",
    DS: "WG",
    "Developed / Developing Countries": "Developing",
    Dial: "1-473",
    EDGAR: "J5",
    FIFA: "GRN",
    FIPS: "GJ",
    GAUL: "99",
    "Geoname ID": 3580239,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GRN",
    "ISO3166-1-Alpha-2": "GD",
    "ISO3166-1-Alpha-3": "GRD",
    "ISO3166-1-numeric": "308",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "GRENADA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "GRD",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GD",
    "Least Developed Countries (LDC)": null,
    M49: 308,
    MARC: "gd",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gd",
    "UNTERM Arabic Formal": "غرينادا",
    "UNTERM Arabic Short": "غرينادا",
    "UNTERM Chinese Formal": "格林纳达",
    "UNTERM Chinese Short": "格林纳达",
    "UNTERM English Formal": "Grenada",
    "UNTERM English Short": "Grenada",
    "UNTERM French Formal": "la Grenade",
    "UNTERM French Short": "Grenade (la)",
    "UNTERM Russian Formal": "Гренада",
    "UNTERM Russian Short": "Гренада",
    "UNTERM Spanish Formal": "Granada",
    "UNTERM Spanish Short": "Granada",
    WMO: "GD",
    is_independent: "Yes",
    official_name_ar: "غرينادا",
    official_name_cn: "格林纳达",
    official_name_en: "Grenada",
    official_name_es: "Granada",
    official_name_fr: "Grenade",
    official_name_ru: "Гренада",
  },
  {
    "CLDR display name": "Guadeloupe",
    Capital: "Basse-Terre",
    Continent: "NA",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "590",
    EDGAR: "J6",
    FIFA: "GLP",
    FIPS: "GP",
    GAUL: "100",
    "Geoname ID": 3579143,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GUD",
    "ISO3166-1-Alpha-2": "GP",
    "ISO3166-1-Alpha-3": "GLP",
    "ISO3166-1-numeric": "312",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "GUADELOUPE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "GDL",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-GP",
    "Least Developed Countries (LDC)": null,
    M49: 312,
    MARC: "gp",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gp",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "MF",
    is_independent: "Part of FR",
    official_name_ar: "غواديلوب",
    official_name_cn: "瓜德罗普",
    official_name_en: "Guadeloupe",
    official_name_es: "Guadalupe",
    official_name_fr: "Guadeloupe",
    official_name_ru: "Гваделупа",
  },
  {
    "CLDR display name": "Guam",
    Capital: "Hagatna",
    Continent: "OC",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: "1-671",
    EDGAR: "GU",
    FIFA: "GUM",
    FIPS: "GQ",
    GAUL: "101",
    "Geoname ID": 4043988,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GUM",
    "ISO3166-1-Alpha-2": "GU",
    "ISO3166-1-Alpha-3": "GUM",
    "ISO3166-1-numeric": "316",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "GUAM",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "GUM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GU,ch-GU",
    "Least Developed Countries (LDC)": null,
    M49: 316,
    MARC: "gu",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".gu",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "GM",
    is_independent: "Territory of US",
    official_name_ar: "غوام",
    official_name_cn: "关岛",
    official_name_en: "Guam",
    official_name_es: "Guam",
    official_name_fr: "Guam",
    official_name_ru: "Гуам",
  },
  {
    "CLDR display name": "Guatemala",
    Capital: "Guatemala City",
    Continent: "NA",
    DS: "GCA",
    "Developed / Developing Countries": "Developing",
    Dial: "502",
    EDGAR: "J8",
    FIFA: "GUA",
    FIPS: "GT",
    GAUL: "103",
    "Geoname ID": 3595528,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GUA",
    "ISO3166-1-Alpha-2": "GT",
    "ISO3166-1-Alpha-3": "GTM",
    "ISO3166-1-numeric": "320",
    "ISO4217-currency_alphabetic_code": "GTQ",
    "ISO4217-currency_country_name": "GUATEMALA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Quetzal",
    "ISO4217-currency_numeric_code": "320",
    ITU: "GTM",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-GT",
    "Least Developed Countries (LDC)": null,
    M49: 320,
    MARC: "gt",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gt",
    "UNTERM Arabic Formal": "جمهورية غواتيمالا",
    "UNTERM Arabic Short": "غواتيمالا",
    "UNTERM Chinese Formal": "危地马拉共和国",
    "UNTERM Chinese Short": "危地马拉",
    "UNTERM English Formal": "the Republic of Guatemala",
    "UNTERM English Short": "Guatemala",
    "UNTERM French Formal": "la République du Guatemala",
    "UNTERM French Short": "Guatemala (le)",
    "UNTERM Russian Formal": "Республика Гватемала",
    "UNTERM Russian Short": "Гватемала",
    "UNTERM Spanish Formal": "la República de Guatemala",
    "UNTERM Spanish Short": "Guatemala",
    WMO: "GU",
    is_independent: "Yes",
    official_name_ar: "غواتيمالا",
    official_name_cn: "危地马拉",
    official_name_en: "Guatemala",
    official_name_es: "Guatemala",
    official_name_fr: "Guatemala",
    official_name_ru: "Гватемала",
  },
  {
    "CLDR display name": "Guernsey",
    Capital: "St Peter Port",
    Continent: "EU",
    DS: "GBG",
    "Developed / Developing Countries": "Developed",
    Dial: "44",
    EDGAR: "Y7",
    FIFA: "GBG",
    FIPS: "GK",
    GAUL: "104",
    "Geoname ID": 3042362,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "GG",
    "ISO3166-1-Alpha-3": "GGY",
    "ISO3166-1-numeric": "831",
    "ISO4217-currency_alphabetic_code": "GBP",
    "ISO4217-currency_country_name": "GUERNSEY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pound Sterling",
    "ISO4217-currency_numeric_code": "826",
    ITU: null,
    "Intermediate Region Code": "830",
    "Intermediate Region Name": "Channel Islands",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en,nrf",
    "Least Developed Countries (LDC)": null,
    M49: 831,
    MARC: "uik",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".gg",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Crown dependency of GB",
    official_name_ar: "غيرنزي",
    official_name_cn: "根西",
    official_name_en: "Guernsey",
    official_name_es: "Guernsey",
    official_name_fr: "Guernesey",
    official_name_ru: "Гернси",
  },
  {
    "CLDR display name": "Guinea",
    Capital: "Conakry",
    Continent: "AF",
    DS: "RG",
    "Developed / Developing Countries": "Developing",
    Dial: "224",
    EDGAR: "J9",
    FIFA: "GUI",
    FIPS: "GV",
    GAUL: "106",
    "Geoname ID": 2420477,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GUI",
    "ISO3166-1-Alpha-2": "GN",
    "ISO3166-1-Alpha-3": "GIN",
    "ISO3166-1-numeric": "324",
    "ISO4217-currency_alphabetic_code": "GNF",
    "ISO4217-currency_country_name": "GUINEA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Guinean Franc",
    "ISO4217-currency_numeric_code": "324",
    ITU: "GUI",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-GN",
    "Least Developed Countries (LDC)": "x",
    M49: 324,
    MARC: "gv",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".gn",
    "UNTERM Arabic Formal": "جمهورية غينيا",
    "UNTERM Arabic Short": "غينيا",
    "UNTERM Chinese Formal": "几内亚共和国",
    "UNTERM Chinese Short": "几内亚",
    "UNTERM English Formal": "the Republic of Guinea",
    "UNTERM English Short": "Guinea",
    "UNTERM French Formal": "la République de Guinée",
    "UNTERM French Short": "Guinée (la)",
    "UNTERM Russian Formal": "Гвинейская Республика",
    "UNTERM Russian Short": "Гвинея",
    "UNTERM Spanish Formal": "la República de Guinea",
    "UNTERM Spanish Short": "Guinea",
    WMO: "GN",
    is_independent: "Yes",
    official_name_ar: "غينيا",
    official_name_cn: "几内亚",
    official_name_en: "Guinea",
    official_name_es: "Guinea",
    official_name_fr: "Guinée",
    official_name_ru: "Гвинея",
  },
  {
    "CLDR display name": "Guinea-Bissau",
    Capital: "Bissau",
    Continent: "AF",
    DS: "GW",
    "Developed / Developing Countries": "Developing",
    Dial: "245",
    EDGAR: "S0",
    FIFA: "GNB",
    FIPS: "PU",
    GAUL: "105",
    "Geoname ID": 2372248,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GBS",
    "ISO3166-1-Alpha-2": "GW",
    "ISO3166-1-Alpha-3": "GNB",
    "ISO3166-1-numeric": "624",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "GUINEA-BISSAU",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "GNB",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-GW,pov",
    "Least Developed Countries (LDC)": "x",
    M49: 624,
    MARC: "pg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".gw",
    "UNTERM Arabic Formal": "جمهورية غينيا - بيساو",
    "UNTERM Arabic Short": "غينيا - بيساو",
    "UNTERM Chinese Formal": "几内亚比绍共和国",
    "UNTERM Chinese Short": "几内亚比绍",
    "UNTERM English Formal": "the Republic of Guinea-Bissau",
    "UNTERM English Short": "Guinea-Bissau",
    "UNTERM French Formal": "la République de Guinée-Bissau",
    "UNTERM French Short": "Guinée-Bissau (la)",
    "UNTERM Russian Formal": "Республика Гвинея-Бисау",
    "UNTERM Russian Short": "Гвинея-Бисау",
    "UNTERM Spanish Formal": "la República de Guinea-Bissau",
    "UNTERM Spanish Short": "Guinea-Bissau",
    WMO: "GW",
    is_independent: "Yes",
    official_name_ar: "غينيا - بيساو",
    official_name_cn: "几内亚比绍",
    official_name_en: "Guinea-Bissau",
    official_name_es: "Guinea-Bissau",
    official_name_fr: "Guinée-Bissau",
    official_name_ru: "Гвинея-Бисау",
  },
  {
    "CLDR display name": "Guyana",
    Capital: "Georgetown",
    Continent: "SA",
    DS: "GUY",
    "Developed / Developing Countries": "Developing",
    Dial: "592",
    EDGAR: "K0",
    FIFA: "GUY",
    FIPS: "GY",
    GAUL: "107",
    "Geoname ID": 3378535,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GUY",
    "ISO3166-1-Alpha-2": "GY",
    "ISO3166-1-Alpha-3": "GUY",
    "ISO3166-1-numeric": "328",
    "ISO4217-currency_alphabetic_code": "GYD",
    "ISO4217-currency_country_name": "GUYANA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Guyana Dollar",
    "ISO4217-currency_numeric_code": "328",
    ITU: "GUY",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GY",
    "Least Developed Countries (LDC)": null,
    M49: 328,
    MARC: "gy",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gy",
    "UNTERM Arabic Formal": "جمهورية غيانا",
    "UNTERM Arabic Short": "غيانا",
    "UNTERM Chinese Formal": "圭亚那共和国",
    "UNTERM Chinese Short": "圭亚那",
    "UNTERM English Formal": "the Republic of Guyana",
    "UNTERM English Short": "Guyana",
    "UNTERM French Formal": "la République du Guyana",
    "UNTERM French Short": "Guyana (le)",
    "UNTERM Russian Formal": "Республика Гайана",
    "UNTERM Russian Short": "Гайана",
    "UNTERM Spanish Formal": "la República de Guyana",
    "UNTERM Spanish Short": "Guyana",
    WMO: "GY",
    is_independent: "Yes",
    official_name_ar: "غيانا",
    official_name_cn: "圭亚那",
    official_name_en: "Guyana",
    official_name_es: "Guyana",
    official_name_fr: "Guyana",
    official_name_ru: "Гайана",
  },
  {
    "CLDR display name": "Haiti",
    Capital: "Port-au-Prince",
    Continent: "NA",
    DS: "RH",
    "Developed / Developing Countries": "Developing",
    Dial: "509",
    EDGAR: "K1",
    FIFA: "HAI",
    FIPS: "HA",
    GAUL: "108",
    "Geoname ID": 3723988,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "HAI",
    "ISO3166-1-Alpha-2": "HT",
    "ISO3166-1-Alpha-3": "HTI",
    "ISO3166-1-numeric": "332",
    "ISO4217-currency_alphabetic_code": "HTG,USD",
    "ISO4217-currency_country_name": "HAITI",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Gourde,US Dollar",
    "ISO4217-currency_numeric_code": "332,840",
    ITU: "HTI",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ht,fr-HT",
    "Least Developed Countries (LDC)": "x",
    M49: 332,
    MARC: "ht",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ht",
    "UNTERM Arabic Formal": "جمهورية هايتي",
    "UNTERM Arabic Short": "هايتي",
    "UNTERM Chinese Formal": "海地共和国",
    "UNTERM Chinese Short": "海地",
    "UNTERM English Formal": "the Republic of Haiti",
    "UNTERM English Short": "Haiti",
    "UNTERM French Formal": "la République d'Haïti",
    "UNTERM French Short": "Haïti [masc.]",
    "UNTERM Russian Formal": "Республика Гаити",
    "UNTERM Russian Short": "Гаити",
    "UNTERM Spanish Formal": "la República de Haití",
    "UNTERM Spanish Short": "Haití",
    WMO: "HA",
    is_independent: "Yes",
    official_name_ar: "هايتي",
    official_name_cn: "海地",
    official_name_en: "Haiti",
    official_name_es: "Haití",
    official_name_fr: "Haïti",
    official_name_ru: "Гаити",
  },
  {
    "CLDR display name": "Heard & McDonald Islands",
    Capital: null,
    Continent: "AN",
    DS: "AUS",
    "Developed / Developing Countries": "Developed",
    Dial: "672",
    EDGAR: "K4",
    FIFA: null,
    FIPS: "HM",
    GAUL: "109",
    "Geoname ID": 1547314,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "HM",
    "ISO3166-1-Alpha-3": "HMD",
    "ISO3166-1-numeric": "334",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "HEARD ISLAND AND MCDONALD ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: null,
    "Least Developed Countries (LDC)": null,
    M49: 334,
    MARC: "hm",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".hm",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of AU",
    official_name_ar: "جزيرة هيرد وجزر ماكدونالد",
    official_name_cn: "赫德岛和麦克唐纳岛",
    official_name_en: "Heard Island and McDonald Islands",
    official_name_es: "Islas Heard y McDonald",
    official_name_fr: "Île Heard-et-Îles MacDonald",
    official_name_ru: "Остров Херд и острова Макдональд",
  },
  {
    "CLDR display name": "Vatican City",
    Capital: "Vatican City",
    Continent: "EU",
    DS: "V",
    "Developed / Developing Countries": "Developed",
    Dial: "39-06",
    EDGAR: null,
    FIFA: "VAT",
    FIPS: "VT",
    GAUL: "110",
    "Geoname ID": 3164670,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "VA",
    "ISO3166-1-Alpha-3": "VAT",
    "ISO3166-1-numeric": "336",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "HOLY SEE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "CVA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "la,it,fr",
    "Least Developed Countries (LDC)": null,
    M49: 336,
    MARC: "vc",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".va",
    "UNTERM Arabic Formal": "الكرسي الرسولي",
    "UNTERM Arabic Short": "الكرسي الرسولي  *",
    "UNTERM Chinese Formal": "教廷",
    "UNTERM Chinese Short": "教廷  *",
    "UNTERM English Formal": "the Holy See",
    "UNTERM English Short": "Holy See (the)  *",
    "UNTERM French Formal": "le Saint-Siège",
    "UNTERM French Short": "Saint-Siège (le)  *",
    "UNTERM Russian Formal": "Святой Престол",
    "UNTERM Russian Short": "Святой Престол  *",
    "UNTERM Spanish Formal": "la Santa Sede",
    "UNTERM Spanish Short": "Santa Sede (la)  *",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "الكرسي الرسولي",
    official_name_cn: "教廷",
    official_name_en: "Holy See",
    official_name_es: "Santa Sede",
    official_name_fr: "Saint-Siège",
    official_name_ru: "Святой Престол",
  },
  {
    "CLDR display name": "Honduras",
    Capital: "Tegucigalpa",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "504",
    EDGAR: "K2",
    FIFA: "HON",
    FIPS: "HO",
    GAUL: "111",
    "Geoname ID": 3608932,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "HON",
    "ISO3166-1-Alpha-2": "HN",
    "ISO3166-1-Alpha-3": "HND",
    "ISO3166-1-numeric": "340",
    "ISO4217-currency_alphabetic_code": "HNL",
    "ISO4217-currency_country_name": "HONDURAS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lempira",
    "ISO4217-currency_numeric_code": "340",
    ITU: "HND",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-HN,cab,miq",
    "Least Developed Countries (LDC)": null,
    M49: 340,
    MARC: "ho",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".hn",
    "UNTERM Arabic Formal": "جمهورية هندوراس",
    "UNTERM Arabic Short": "هندوراس",
    "UNTERM Chinese Formal": "洪都拉斯共和国",
    "UNTERM Chinese Short": "洪都拉斯",
    "UNTERM English Formal": "the Republic of Honduras",
    "UNTERM English Short": "Honduras",
    "UNTERM French Formal": "la République du Honduras",
    "UNTERM French Short": "Honduras (le)",
    "UNTERM Russian Formal": "Республика Гондурас",
    "UNTERM Russian Short": "Гондурас",
    "UNTERM Spanish Formal": "la República de Honduras",
    "UNTERM Spanish Short": "Honduras",
    WMO: "HO",
    is_independent: "Yes",
    official_name_ar: "هندوراس",
    official_name_cn: "洪都拉斯",
    official_name_en: "Honduras",
    official_name_es: "Honduras",
    official_name_fr: "Honduras",
    official_name_ru: "Гондурас",
  },
  {
    "CLDR display name": "Hungary",
    Capital: "Budapest",
    Continent: "EU",
    DS: "H",
    "Developed / Developing Countries": "Developed",
    Dial: "36",
    EDGAR: "K5",
    FIFA: "HUN",
    FIPS: "HU",
    GAUL: "113",
    "Geoname ID": 719819,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "HUN",
    "ISO3166-1-Alpha-2": "HU",
    "ISO3166-1-Alpha-3": "HUN",
    "ISO3166-1-numeric": "348",
    "ISO4217-currency_alphabetic_code": "HUF",
    "ISO4217-currency_country_name": "HUNGARY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Forint",
    "ISO4217-currency_numeric_code": "348",
    ITU: "HNG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "hu-HU",
    "Least Developed Countries (LDC)": null,
    M49: 348,
    MARC: "hu",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".hu",
    "UNTERM Arabic Formal": "هنغاريا",
    "UNTERM Arabic Short": "هنغاريا",
    "UNTERM Chinese Formal": "匈牙利",
    "UNTERM Chinese Short": "匈牙利",
    "UNTERM English Formal": "Hungary",
    "UNTERM English Short": "Hungary",
    "UNTERM French Formal": "la Hongrie",
    "UNTERM French Short": "Hongrie (la)",
    "UNTERM Russian Formal": "Венгрия",
    "UNTERM Russian Short": "Венгрия",
    "UNTERM Spanish Formal": "Hungría",
    "UNTERM Spanish Short": "Hungría",
    WMO: "HU",
    is_independent: "Yes",
    official_name_ar: "هنغاريا",
    official_name_cn: "匈牙利",
    official_name_en: "Hungary",
    official_name_es: "Hungría",
    official_name_fr: "Hongrie",
    official_name_ru: "Венгрия",
  },
  {
    "CLDR display name": "Iceland",
    Capital: "Reykjavik",
    Continent: "EU",
    DS: "IS",
    "Developed / Developing Countries": "Developed",
    Dial: "354",
    EDGAR: "K6",
    FIFA: "ISL",
    FIPS: "IC",
    GAUL: "114",
    "Geoname ID": 2629691,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ISL",
    "ISO3166-1-Alpha-2": "IS",
    "ISO3166-1-Alpha-3": "ISL",
    "ISO3166-1-numeric": "352",
    "ISO4217-currency_alphabetic_code": "ISK",
    "ISO4217-currency_country_name": "ICELAND",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Iceland Krona",
    "ISO4217-currency_numeric_code": "352",
    ITU: "ISL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "is,en,de,da,sv,no",
    "Least Developed Countries (LDC)": null,
    M49: 352,
    MARC: "ic",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".is",
    "UNTERM Arabic Formal": "جمهورية آيسلندا",
    "UNTERM Arabic Short": "آيسلندا",
    "UNTERM Chinese Formal": "冰岛共和国",
    "UNTERM Chinese Short": "冰岛",
    "UNTERM English Formal": "the Republic of Iceland",
    "UNTERM English Short": "Iceland",
    "UNTERM French Formal": "la République d'Islande",
    "UNTERM French Short": "Islande (l') [fém.]",
    "UNTERM Russian Formal": "Республика Исландия",
    "UNTERM Russian Short": "Исландия",
    "UNTERM Spanish Formal": "la República de Islandia",
    "UNTERM Spanish Short": "Islandia",
    WMO: "IL",
    is_independent: "Yes",
    official_name_ar: "آيسلندا",
    official_name_cn: "冰岛",
    official_name_en: "Iceland",
    official_name_es: "Islandia",
    official_name_fr: "Islande",
    official_name_ru: "Исландия",
  },
  {
    "CLDR display name": "India",
    Capital: "New Delhi",
    Continent: "AS",
    DS: "IND",
    "Developed / Developing Countries": "Developing",
    Dial: "91",
    EDGAR: "K7",
    FIFA: "IND",
    FIPS: "IN",
    GAUL: "115",
    "Geoname ID": 1269750,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "IND",
    "ISO3166-1-Alpha-2": "IN",
    "ISO3166-1-Alpha-3": "IND",
    "ISO3166-1-numeric": "356",
    "ISO4217-currency_alphabetic_code": "INR",
    "ISO4217-currency_country_name": "INDIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Indian Rupee",
    "ISO4217-currency_numeric_code": "356",
    ITU: "IND",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    "Least Developed Countries (LDC)": null,
    M49: 356,
    MARC: "ii",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".in",
    "UNTERM Arabic Formal": "جمهورية الهند",
    "UNTERM Arabic Short": "الهند",
    "UNTERM Chinese Formal": "印度共和国",
    "UNTERM Chinese Short": "印度",
    "UNTERM English Formal": "the Republic of India",
    "UNTERM English Short": "India",
    "UNTERM French Formal": "la République de l'Inde",
    "UNTERM French Short": "Inde (l') [fém.]",
    "UNTERM Russian Formal": "Республика Индия",
    "UNTERM Russian Short": "Индия",
    "UNTERM Spanish Formal": "la República de la India",
    "UNTERM Spanish Short": "India (la)",
    WMO: "IN",
    is_independent: "Yes",
    official_name_ar: "الهند",
    official_name_cn: "印度",
    official_name_en: "India",
    official_name_es: "India",
    official_name_fr: "Inde",
    official_name_ru: "Индия",
  },
  {
    "CLDR display name": "Indonesia",
    Capital: "Jakarta",
    Continent: "AS",
    DS: "RI",
    "Developed / Developing Countries": "Developing",
    Dial: "62",
    EDGAR: "K8",
    FIFA: "IDN",
    FIPS: "ID",
    GAUL: "116",
    "Geoname ID": 1643084,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "INA",
    "ISO3166-1-Alpha-2": "ID",
    "ISO3166-1-Alpha-3": "IDN",
    "ISO3166-1-numeric": "360",
    "ISO4217-currency_alphabetic_code": "IDR",
    "ISO4217-currency_country_name": "INDONESIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Rupiah",
    "ISO4217-currency_numeric_code": "360",
    ITU: "INS",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "id,en,nl,jv",
    "Least Developed Countries (LDC)": null,
    M49: 360,
    MARC: "io",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".id",
    "UNTERM Arabic Formal": "جمهورية إندونيسيا",
    "UNTERM Arabic Short": "إندونيسيا",
    "UNTERM Chinese Formal": "印度尼西亚共和国",
    "UNTERM Chinese Short": "印度尼西亚",
    "UNTERM English Formal": "the Republic of Indonesia",
    "UNTERM English Short": "Indonesia",
    "UNTERM French Formal": "la République d'Indonésie",
    "UNTERM French Short": "Indonésie (l') [fém.]",
    "UNTERM Russian Formal": "Республика Индонезия",
    "UNTERM Russian Short": "Индонезия",
    "UNTERM Spanish Formal": "la República de Indonesia",
    "UNTERM Spanish Short": "Indonesia",
    WMO: "ID",
    is_independent: "Yes",
    official_name_ar: "إندونيسيا",
    official_name_cn: "印度尼西亚",
    official_name_en: "Indonesia",
    official_name_es: "Indonesia",
    official_name_fr: "Indonésie",
    official_name_ru: "Индонезия",
  },
  {
    "CLDR display name": "Iran",
    Capital: "Tehran",
    Continent: "AS",
    DS: "IR",
    "Developed / Developing Countries": "Developing",
    Dial: "98",
    EDGAR: null,
    FIFA: "IRN",
    FIPS: "IR",
    GAUL: "117",
    "Geoname ID": 130758,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "IRI",
    "ISO3166-1-Alpha-2": "IR",
    "ISO3166-1-Alpha-3": "IRN",
    "ISO3166-1-numeric": "364",
    "ISO4217-currency_alphabetic_code": "IRR",
    "ISO4217-currency_country_name": "IRAN (ISLAMIC REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Iranian Rial",
    "ISO4217-currency_numeric_code": "364",
    ITU: "IRN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fa-IR,ku",
    "Least Developed Countries (LDC)": null,
    M49: 364,
    MARC: "ir",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".ir",
    "UNTERM Arabic Formal": "جمهورية إيران الإسلامية",
    "UNTERM Arabic Short": "إيران (جمهورية - الإسلامية)",
    "UNTERM Chinese Formal": "伊朗伊斯兰共和国",
    "UNTERM Chinese Short": "伊朗（伊斯兰共和国）",
    "UNTERM English Formal": "the Islamic Republic of Iran",
    "UNTERM English Short": "Iran (Islamic Republic of)",
    "UNTERM French Formal": "la République islamique d'Iran",
    "UNTERM French Short": "Iran (République islamique d')",
    "UNTERM Russian Formal": "Исламская Республика Иран",
    "UNTERM Russian Short": "Иран (Исламская Республика)",
    "UNTERM Spanish Formal": "la República Islámica del Irán",
    "UNTERM Spanish Short": "Irán (República Islámica del)",
    WMO: "IR",
    is_independent: "Yes",
    official_name_ar: "إيران (جمهورية - الإسلامية)",
    official_name_cn: "伊朗(伊斯兰共和国)",
    official_name_en: "Iran (Islamic Republic of)",
    official_name_es: "Irán (República Islámica del)",
    official_name_fr: "Iran (République islamique d')",
    official_name_ru: "Иран (Исламская Республика)",
  },
  {
    "CLDR display name": "Iraq",
    Capital: "Baghdad",
    Continent: "AS",
    DS: "IRQ",
    "Developed / Developing Countries": "Developing",
    Dial: "964",
    EDGAR: "L0",
    FIFA: "IRQ",
    FIPS: "IZ",
    GAUL: "118",
    "Geoname ID": 99237,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "IRQ",
    "ISO3166-1-Alpha-2": "IQ",
    "ISO3166-1-Alpha-3": "IRQ",
    "ISO3166-1-numeric": "368",
    "ISO4217-currency_alphabetic_code": "IQD",
    "ISO4217-currency_country_name": "IRAQ",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Iraqi Dinar",
    "ISO4217-currency_numeric_code": "368",
    ITU: "IRQ",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-IQ,ku,hy",
    "Least Developed Countries (LDC)": null,
    M49: 368,
    MARC: "iq",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".iq",
    "UNTERM Arabic Formal": "جمهورية العراق",
    "UNTERM Arabic Short": "العراق",
    "UNTERM Chinese Formal": "伊拉克共和国",
    "UNTERM Chinese Short": "伊拉克",
    "UNTERM English Formal": "the Republic of Iraq",
    "UNTERM English Short": "Iraq",
    "UNTERM French Formal": "la République d'Iraq",
    "UNTERM French Short": "Iraq (l') [masc.]",
    "UNTERM Russian Formal": "Республика Ирак",
    "UNTERM Russian Short": "Ирак",
    "UNTERM Spanish Formal": "la República del Iraq",
    "UNTERM Spanish Short": "Iraq (el)",
    WMO: "IQ",
    is_independent: "Yes",
    official_name_ar: "العراق",
    official_name_cn: "伊拉克",
    official_name_en: "Iraq",
    official_name_es: "Iraq",
    official_name_fr: "Iraq",
    official_name_ru: "Ирак",
  },
  {
    "CLDR display name": "Ireland",
    Capital: "Dublin",
    Continent: "EU",
    DS: "IRL",
    "Developed / Developing Countries": "Developed",
    Dial: "353",
    EDGAR: "L2",
    FIFA: "IRL",
    FIPS: "EI",
    GAUL: "119",
    "Geoname ID": 2963597,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "IRL",
    "ISO3166-1-Alpha-2": "IE",
    "ISO3166-1-Alpha-3": "IRL",
    "ISO3166-1-numeric": "372",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "IRELAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "IRL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-IE,ga-IE",
    "Least Developed Countries (LDC)": null,
    M49: 372,
    MARC: "ie",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".ie",
    "UNTERM Arabic Formal": "آيرلندا",
    "UNTERM Arabic Short": "آيرلندا",
    "UNTERM Chinese Formal": "爱尔兰",
    "UNTERM Chinese Short": "爱尔兰",
    "UNTERM English Formal": "Ireland",
    "UNTERM English Short": "Ireland",
    "UNTERM French Formal": "l'Irlande",
    "UNTERM French Short": "Irlande (l') [fém.]",
    "UNTERM Russian Formal": "Ирландия",
    "UNTERM Russian Short": "Ирландия",
    "UNTERM Spanish Formal": "Irlanda",
    "UNTERM Spanish Short": "Irlanda",
    WMO: "IE",
    is_independent: "Yes",
    official_name_ar: "آيرلندا",
    official_name_cn: "爱尔兰",
    official_name_en: "Ireland",
    official_name_es: "Irlanda",
    official_name_fr: "Irlande",
    official_name_ru: "Ирландия",
  },
  {
    "CLDR display name": "Isle of Man",
    Capital: "Douglas",
    Continent: "EU",
    DS: "GBM",
    "Developed / Developing Countries": "Developed",
    Dial: "44",
    EDGAR: "Y8",
    FIFA: "GBM",
    FIPS: "IM",
    GAUL: "120",
    "Geoname ID": 3042225,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "IM",
    "ISO3166-1-Alpha-3": "IMN",
    "ISO3166-1-numeric": "833",
    "ISO4217-currency_alphabetic_code": "GBP",
    "ISO4217-currency_country_name": "ISLE OF MAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pound Sterling",
    "ISO4217-currency_numeric_code": "826",
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en,gv",
    "Least Developed Countries (LDC)": null,
    M49: 833,
    MARC: "uik",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".im",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Crown dependency of GB",
    official_name_ar: "جزيرة مان",
    official_name_cn: "马恩岛",
    official_name_en: "Isle of Man",
    official_name_es: "Isla de Man",
    official_name_fr: "Île de Man",
    official_name_ru: "Остров Мэн",
  },
  {
    "CLDR display name": "Israel",
    Capital: "Jerusalem",
    Continent: "AS",
    DS: "IL",
    "Developed / Developing Countries": "Developed",
    Dial: "972",
    EDGAR: "L3",
    FIFA: "ISR",
    FIPS: "IS",
    GAUL: "121",
    "Geoname ID": 294640,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ISR",
    "ISO3166-1-Alpha-2": "IL",
    "ISO3166-1-Alpha-3": "ISR",
    "ISO3166-1-numeric": "376",
    "ISO4217-currency_alphabetic_code": "ILS",
    "ISO4217-currency_country_name": "ISRAEL",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Israeli Sheqel",
    "ISO4217-currency_numeric_code": "376",
    ITU: "ISR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "he,ar-IL,en-IL,",
    "Least Developed Countries (LDC)": null,
    M49: 376,
    MARC: "is",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".il",
    "UNTERM Arabic Formal": "دولة إسرائيل",
    "UNTERM Arabic Short": "إسرائيل",
    "UNTERM Chinese Formal": "以色列国",
    "UNTERM Chinese Short": "以色列",
    "UNTERM English Formal": "the State of Israel",
    "UNTERM English Short": "Israel",
    "UNTERM French Formal": "l'État d'Israël",
    "UNTERM French Short": "Israël [masc.]",
    "UNTERM Russian Formal": "Государство Израиль",
    "UNTERM Russian Short": "Израиль",
    "UNTERM Spanish Formal": "el Estado de Israel",
    "UNTERM Spanish Short": "Israel",
    WMO: "IS",
    is_independent: "Yes",
    official_name_ar: "إسرائيل",
    official_name_cn: "以色列",
    official_name_en: "Israel",
    official_name_es: "Israel",
    official_name_fr: "Israël",
    official_name_ru: "Израиль",
  },
  {
    "CLDR display name": "Italy",
    Capital: "Rome",
    Continent: "EU",
    DS: "I",
    "Developed / Developing Countries": "Developed",
    Dial: "39",
    EDGAR: "L6",
    FIFA: "ITA",
    FIPS: "IT",
    GAUL: "122",
    "Geoname ID": 3175395,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ITA",
    "ISO3166-1-Alpha-2": "IT",
    "ISO3166-1-Alpha-3": "ITA",
    "ISO3166-1-numeric": "380",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "ITALY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "I",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "it-IT,de-IT,fr-IT,sc,ca,co,sl",
    "Least Developed Countries (LDC)": null,
    M49: 380,
    MARC: "it",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".it",
    "UNTERM Arabic Formal": "جمهورية إيطاليا",
    "UNTERM Arabic Short": "إيطاليا",
    "UNTERM Chinese Formal": "意大利共和国",
    "UNTERM Chinese Short": "意大利",
    "UNTERM English Formal": "the Republic of Italy",
    "UNTERM English Short": "Italy",
    "UNTERM French Formal": "la République italienne",
    "UNTERM French Short": "Italie (l') [fém.]",
    "UNTERM Russian Formal": "Итальянская Республика",
    "UNTERM Russian Short": "Италия",
    "UNTERM Spanish Formal": "la República Italiana",
    "UNTERM Spanish Short": "Italia",
    WMO: "IY",
    is_independent: "Yes",
    official_name_ar: "إيطاليا",
    official_name_cn: "意大利",
    official_name_en: "Italy",
    official_name_es: "Italia",
    official_name_fr: "Italie",
    official_name_ru: "Италия",
  },
  {
    "CLDR display name": "Jamaica",
    Capital: "Kingston",
    Continent: "NA",
    DS: "JA",
    "Developed / Developing Countries": "Developing",
    Dial: "1-876",
    EDGAR: "L8",
    FIFA: "JAM",
    FIPS: "JM",
    GAUL: "123",
    "Geoname ID": 3489940,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "JAM",
    "ISO3166-1-Alpha-2": "JM",
    "ISO3166-1-Alpha-3": "JAM",
    "ISO3166-1-numeric": "388",
    "ISO4217-currency_alphabetic_code": "JMD",
    "ISO4217-currency_country_name": "JAMAICA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Jamaican Dollar",
    "ISO4217-currency_numeric_code": "388",
    ITU: "JMC",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-JM",
    "Least Developed Countries (LDC)": null,
    M49: 388,
    MARC: "jm",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".jm",
    "UNTERM Arabic Formal": "جامايكا",
    "UNTERM Arabic Short": "جامايكا",
    "UNTERM Chinese Formal": "牙买加",
    "UNTERM Chinese Short": "牙买加",
    "UNTERM English Formal": "Jamaica",
    "UNTERM English Short": "Jamaica",
    "UNTERM French Formal": "la Jamaïque",
    "UNTERM French Short": "Jamaïque (la)",
    "UNTERM Russian Formal": "Ямайка",
    "UNTERM Russian Short": "Ямайка",
    "UNTERM Spanish Formal": "Jamaica",
    "UNTERM Spanish Short": "Jamaica",
    WMO: "JM",
    is_independent: "Yes",
    official_name_ar: "جامايكا",
    official_name_cn: "牙买加",
    official_name_en: "Jamaica",
    official_name_es: "Jamaica",
    official_name_fr: "Jamaïque",
    official_name_ru: "Ямайка",
  },
  {
    "CLDR display name": "Japan",
    Capital: "Tokyo",
    Continent: "AS",
    DS: "J",
    "Developed / Developing Countries": "Developed",
    Dial: "81",
    EDGAR: "M0",
    FIFA: "JPN",
    FIPS: "JA",
    GAUL: "126",
    "Geoname ID": 1861060,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "JPN",
    "ISO3166-1-Alpha-2": "JP",
    "ISO3166-1-Alpha-3": "JPN",
    "ISO3166-1-numeric": "392",
    "ISO4217-currency_alphabetic_code": "JPY",
    "ISO4217-currency_country_name": "JAPAN",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Yen",
    "ISO4217-currency_numeric_code": "392",
    ITU: "J",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ja",
    "Least Developed Countries (LDC)": null,
    M49: 392,
    MARC: "ja",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".jp",
    "UNTERM Arabic Formal": "اليابان",
    "UNTERM Arabic Short": "اليابان",
    "UNTERM Chinese Formal": "日本国",
    "UNTERM Chinese Short": "日本",
    "UNTERM English Formal": "Japan",
    "UNTERM English Short": "Japan",
    "UNTERM French Formal": "le Japon",
    "UNTERM French Short": "Japon (le)",
    "UNTERM Russian Formal": "Япония",
    "UNTERM Russian Short": "Япония",
    "UNTERM Spanish Formal": "el Japón",
    "UNTERM Spanish Short": "Japón (el)",
    WMO: "JP",
    is_independent: "Yes",
    official_name_ar: "اليابان",
    official_name_cn: "日本",
    official_name_en: "Japan",
    official_name_es: "Japón",
    official_name_fr: "Japon",
    official_name_ru: "Япония",
  },
  {
    "CLDR display name": "Jersey",
    Capital: "Saint Helier",
    Continent: "EU",
    DS: "GBJ",
    "Developed / Developing Countries": "Developed",
    Dial: "44",
    EDGAR: "Y9",
    FIFA: "GBJ",
    FIPS: "JE",
    GAUL: "128",
    "Geoname ID": 3042142,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "JE",
    "ISO3166-1-Alpha-3": "JEY",
    "ISO3166-1-numeric": "832",
    "ISO4217-currency_alphabetic_code": "GBP",
    "ISO4217-currency_country_name": "JERSEY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pound Sterling",
    "ISO4217-currency_numeric_code": "826",
    ITU: null,
    "Intermediate Region Code": "830",
    "Intermediate Region Name": "Channel Islands",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en,fr,nrf",
    "Least Developed Countries (LDC)": null,
    M49: 832,
    MARC: "uik",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".je",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Crown dependency of GB",
    official_name_ar: "جيرزي",
    official_name_cn: "泽西",
    official_name_en: "Jersey",
    official_name_es: "Jersey",
    official_name_fr: "Jersey",
    official_name_ru: "Джерси",
  },
  {
    "CLDR display name": "Jordan",
    Capital: "Amman",
    Continent: "AS",
    DS: "HKJ",
    "Developed / Developing Countries": "Developing",
    Dial: "962",
    EDGAR: "M2",
    FIFA: "JOR",
    FIPS: "JO",
    GAUL: "130",
    "Geoname ID": 248816,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "JOR",
    "ISO3166-1-Alpha-2": "JO",
    "ISO3166-1-Alpha-3": "JOR",
    "ISO3166-1-numeric": "400",
    "ISO4217-currency_alphabetic_code": "JOD",
    "ISO4217-currency_country_name": "JORDAN",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Jordanian Dinar",
    "ISO4217-currency_numeric_code": "400",
    ITU: "JOR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-JO,en",
    "Least Developed Countries (LDC)": null,
    M49: 400,
    MARC: "jo",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".jo",
    "UNTERM Arabic Formal": "المملكة الأردنية الهاشمية",
    "UNTERM Arabic Short": "الأردن",
    "UNTERM Chinese Formal": "约旦哈希姆王国",
    "UNTERM Chinese Short": "约旦",
    "UNTERM English Formal": "the Hashemite Kingdom of Jordan",
    "UNTERM English Short": "Jordan",
    "UNTERM French Formal": "le Royaume hachémite de Jordanie",
    "UNTERM French Short": "Jordanie (la)",
    "UNTERM Russian Formal": "Иорданское Хашимитское Королевство",
    "UNTERM Russian Short": "Иордания",
    "UNTERM Spanish Formal": "el Reino Hachemita de Jordania",
    "UNTERM Spanish Short": "Jordania",
    WMO: "JD",
    is_independent: "Yes",
    official_name_ar: "الأردن",
    official_name_cn: "约旦",
    official_name_en: "Jordan",
    official_name_es: "Jordania",
    official_name_fr: "Jordanie",
    official_name_ru: "Иордания",
  },
  {
    "CLDR display name": "Kazakhstan",
    Capital: "Astana",
    Continent: "AS",
    DS: "KZ",
    "Developed / Developing Countries": "Developing",
    Dial: "7",
    EDGAR: null,
    FIFA: "KAZ",
    FIPS: "KZ",
    GAUL: "132",
    "Geoname ID": 1522867,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KAZ",
    "ISO3166-1-Alpha-2": "KZ",
    "ISO3166-1-Alpha-3": "KAZ",
    "ISO3166-1-numeric": "398",
    "ISO4217-currency_alphabetic_code": "KZT",
    "ISO4217-currency_country_name": "KAZAKHSTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Tenge",
    "ISO4217-currency_numeric_code": "398",
    ITU: "KAZ",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "kk,ru",
    "Least Developed Countries (LDC)": null,
    M49: 398,
    MARC: "kz",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "143",
    "Sub-region Name": "Central Asia",
    TLD: ".kz",
    "UNTERM Arabic Formal": "جمهورية كازاخستان",
    "UNTERM Arabic Short": "كازاخستان",
    "UNTERM Chinese Formal": "哈萨克斯坦共和国",
    "UNTERM Chinese Short": "哈萨克斯坦",
    "UNTERM English Formal": "the Republic of Kazakhstan",
    "UNTERM English Short": "Kazakhstan",
    "UNTERM French Formal": "la République du Kazakhstan",
    "UNTERM French Short": "Kazakhstan (le)",
    "UNTERM Russian Formal": "Республика Казахстан",
    "UNTERM Russian Short": "Казахстан",
    "UNTERM Spanish Formal": "la República de Kazajstán",
    "UNTERM Spanish Short": "Kazajstán",
    WMO: "KZ",
    is_independent: "Yes",
    official_name_ar: "كازاخستان",
    official_name_cn: "哈萨克斯坦",
    official_name_en: "Kazakhstan",
    official_name_es: "Kazajstán",
    official_name_fr: "Kazakhstan",
    official_name_ru: "Казахстан",
  },
  {
    "CLDR display name": "Kenya",
    Capital: "Nairobi",
    Continent: "AF",
    DS: "EAK",
    "Developed / Developing Countries": "Developing",
    Dial: "254",
    EDGAR: "M3",
    FIFA: "KEN",
    FIPS: "KE",
    GAUL: "133",
    "Geoname ID": 192950,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KEN",
    "ISO3166-1-Alpha-2": "KE",
    "ISO3166-1-Alpha-3": "KEN",
    "ISO3166-1-numeric": "404",
    "ISO4217-currency_alphabetic_code": "KES",
    "ISO4217-currency_country_name": "KENYA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Kenyan Shilling",
    "ISO4217-currency_numeric_code": "404",
    ITU: "KEN",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-KE,sw-KE",
    "Least Developed Countries (LDC)": null,
    M49: 404,
    MARC: "ke",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ke",
    "UNTERM Arabic Formal": "جمهورية كينيا",
    "UNTERM Arabic Short": "كينيا",
    "UNTERM Chinese Formal": "肯尼亚共和国",
    "UNTERM Chinese Short": "肯尼亚",
    "UNTERM English Formal": "the Republic of Kenya",
    "UNTERM English Short": "Kenya",
    "UNTERM French Formal": "la République du Kenya",
    "UNTERM French Short": "Kenya (le)",
    "UNTERM Russian Formal": "Республика Кения",
    "UNTERM Russian Short": "Кения",
    "UNTERM Spanish Formal": "la República de Kenya",
    "UNTERM Spanish Short": "Kenya",
    WMO: "KN",
    is_independent: "Yes",
    official_name_ar: "كينيا",
    official_name_cn: "肯尼亚",
    official_name_en: "Kenya",
    official_name_es: "Kenya",
    official_name_fr: "Kenya",
    official_name_ru: "Кения",
  },
  {
    "CLDR display name": "Kiribati",
    Capital: "Tarawa",
    Continent: "OC",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "686",
    EDGAR: "J2",
    FIFA: "KIR",
    FIPS: "KR",
    GAUL: "135",
    "Geoname ID": 4030945,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KIR",
    "ISO3166-1-Alpha-2": "KI",
    "ISO3166-1-Alpha-3": "KIR",
    "ISO3166-1-numeric": "296",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "KIRIBATI",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "KIR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-KI,gil",
    "Least Developed Countries (LDC)": "x",
    M49: 296,
    MARC: "gb",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".ki",
    "UNTERM Arabic Formal": "جمهورية كيريباس",
    "UNTERM Arabic Short": "كيريباس",
    "UNTERM Chinese Formal": "基里巴斯共和国",
    "UNTERM Chinese Short": "基里巴斯",
    "UNTERM English Formal": "the Republic of Kiribati",
    "UNTERM English Short": "Kiribati",
    "UNTERM French Formal": "la République de Kiribati",
    "UNTERM French Short": "Kiribati [fém.]",
    "UNTERM Russian Formal": "Республика Кирибати",
    "UNTERM Russian Short": "Кирибати",
    "UNTERM Spanish Formal": "la República de Kiribati",
    "UNTERM Spanish Short": "Kiribati",
    WMO: "KB",
    is_independent: "Yes",
    official_name_ar: "كيريباس",
    official_name_cn: "基里巴斯",
    official_name_en: "Kiribati",
    official_name_es: "Kiribati",
    official_name_fr: "Kiribati",
    official_name_ru: "Кирибати",
  },
  {
    "CLDR display name": "Kuwait",
    Capital: "Kuwait City",
    Continent: "AS",
    DS: "KWT",
    "Developed / Developing Countries": "Developing",
    Dial: "965",
    EDGAR: "M6",
    FIFA: "KUW",
    FIPS: "KU",
    GAUL: "137",
    "Geoname ID": 285570,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KUW",
    "ISO3166-1-Alpha-2": "KW",
    "ISO3166-1-Alpha-3": "KWT",
    "ISO3166-1-numeric": "414",
    "ISO4217-currency_alphabetic_code": "KWD",
    "ISO4217-currency_country_name": "KUWAIT",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Kuwaiti Dinar",
    "ISO4217-currency_numeric_code": "414",
    ITU: "KWT",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-KW,en",
    "Least Developed Countries (LDC)": null,
    M49: 414,
    MARC: "ku",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".kw",
    "UNTERM Arabic Formal": "دولة الكويت",
    "UNTERM Arabic Short": "الكويت",
    "UNTERM Chinese Formal": "科威特国",
    "UNTERM Chinese Short": "科威特",
    "UNTERM English Formal": "the State of Kuwait",
    "UNTERM English Short": "Kuwait",
    "UNTERM French Formal": "l'État du Koweït",
    "UNTERM French Short": "Koweït (le)",
    "UNTERM Russian Formal": "Государство Кувейт",
    "UNTERM Russian Short": "Кувейт",
    "UNTERM Spanish Formal": "el Estado de Kuwait",
    "UNTERM Spanish Short": "Kuwait",
    WMO: "KW",
    is_independent: "Yes",
    official_name_ar: "الكويت",
    official_name_cn: "科威特",
    official_name_en: "Kuwait",
    official_name_es: "Kuwait",
    official_name_fr: "Koweït",
    official_name_ru: "Кувейт",
  },
  {
    "CLDR display name": "Kyrgyzstan",
    Capital: "Bishkek",
    Continent: "AS",
    DS: "KS",
    "Developed / Developing Countries": "Developing",
    Dial: "996",
    EDGAR: "1N",
    FIFA: "KGZ",
    FIPS: "KG",
    GAUL: "138",
    "Geoname ID": 1527747,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KGZ",
    "ISO3166-1-Alpha-2": "KG",
    "ISO3166-1-Alpha-3": "KGZ",
    "ISO3166-1-numeric": "417",
    "ISO4217-currency_alphabetic_code": "KGS",
    "ISO4217-currency_country_name": "KYRGYZSTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Som",
    "ISO4217-currency_numeric_code": "417",
    ITU: "KGZ",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "ky,uz,ru",
    "Least Developed Countries (LDC)": null,
    M49: 417,
    MARC: "kg",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "143",
    "Sub-region Name": "Central Asia",
    TLD: ".kg",
    "UNTERM Arabic Formal": "جمهورية قيرغيزستان",
    "UNTERM Arabic Short": "قيرغيزستان",
    "UNTERM Chinese Formal": "吉尔吉斯共和国",
    "UNTERM Chinese Short": "吉尔吉斯斯坦",
    "UNTERM English Formal": "the Kyrgyz Republic",
    "UNTERM English Short": "Kyrgyzstan",
    "UNTERM French Formal": "la République kirghize",
    "UNTERM French Short": "Kirghizistan (le)",
    "UNTERM Russian Formal": "Кыргызская Республика",
    "UNTERM Russian Short": "Кыргызстан",
    "UNTERM Spanish Formal": "la República Kirguisa",
    "UNTERM Spanish Short": "Kirguistán",
    WMO: "KG",
    is_independent: "Yes",
    official_name_ar: "قيرغيزستان",
    official_name_cn: "吉尔吉斯斯坦",
    official_name_en: "Kyrgyzstan",
    official_name_es: "Kirguistán",
    official_name_fr: "Kirghizistan",
    official_name_ru: "Кыргызстан",
  },
  {
    "CLDR display name": "Laos",
    Capital: "Vientiane",
    Continent: "AS",
    DS: "LAO",
    "Developed / Developing Countries": "Developing",
    Dial: "856",
    EDGAR: null,
    FIFA: "LAO",
    FIPS: "LA",
    GAUL: "139",
    "Geoname ID": 1655842,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LAO",
    "ISO3166-1-Alpha-2": "LA",
    "ISO3166-1-Alpha-3": "LAO",
    "ISO3166-1-numeric": "418",
    "ISO4217-currency_alphabetic_code": "LAK",
    "ISO4217-currency_country_name": "LAO PEOPLE’S DEMOCRATIC REPUBLIC",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lao Kip",
    "ISO4217-currency_numeric_code": "418",
    ITU: "LAO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "lo,fr,en",
    "Least Developed Countries (LDC)": "x",
    M49: 418,
    MARC: "ls",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".la",
    "UNTERM Arabic Formal": "جمهورية لاو الديمقراطية الشعبية",
    "UNTERM Arabic Short": "جمهورية لاو الديمقراطية الشعبية",
    "UNTERM Chinese Formal": "老挝人民民主共和国",
    "UNTERM Chinese Short": "老挝人民民主共和国",
    "UNTERM English Formal": "the Lao People's Democratic Republic",
    "UNTERM English Short": "Lao People's Democratic Republic (the)",
    "UNTERM French Formal": "la République démocratique populaire lao",
    "UNTERM French Short": "République démocratique populaire lao (la)",
    "UNTERM Russian Formal": "Лаосская Народно-Демократическая Республика",
    "UNTERM Russian Short": "Лаосская Народно-Демократическая Республика",
    "UNTERM Spanish Formal": "la República Democrática Popular Lao",
    "UNTERM Spanish Short": "República Democrática Popular Lao (la)",
    WMO: "LA",
    is_independent: "Yes",
    official_name_ar: "جمهورية لاو الديمقراطية الشعبية",
    official_name_cn: "老挝人民民主共和国",
    official_name_en: "Lao People's Democratic Republic",
    official_name_es: "República Democrática Popular Lao",
    official_name_fr: "République démocratique populaire lao",
    official_name_ru: "Лаосская Народно-Демократическая Республика",
  },
  {
    "CLDR display name": "Latvia",
    Capital: "Riga",
    Continent: "EU",
    DS: "LV",
    "Developed / Developing Countries": "Developed",
    Dial: "371",
    EDGAR: "1R",
    FIFA: "LVA",
    FIPS: "LG",
    GAUL: "140",
    "Geoname ID": 458258,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LAT",
    "ISO3166-1-Alpha-2": "LV",
    "ISO3166-1-Alpha-3": "LVA",
    "ISO3166-1-numeric": "428",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "LATVIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "LVA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "lv,ru,lt",
    "Least Developed Countries (LDC)": null,
    M49: 428,
    MARC: "lv",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".lv",
    "UNTERM Arabic Formal": "جمهورية لاتفيا",
    "UNTERM Arabic Short": "لاتفيا",
    "UNTERM Chinese Formal": "拉脱维亚共和国",
    "UNTERM Chinese Short": "拉脱维亚",
    "UNTERM English Formal": "the Republic of Latvia",
    "UNTERM English Short": "Latvia",
    "UNTERM French Formal": "la République de Lettonie",
    "UNTERM French Short": "Lettonie (la)",
    "UNTERM Russian Formal": "Латвийская Республика",
    "UNTERM Russian Short": "Латвия",
    "UNTERM Spanish Formal": "la República de Letonia",
    "UNTERM Spanish Short": "Letonia",
    WMO: "LV",
    is_independent: "Yes",
    official_name_ar: "لاتفيا",
    official_name_cn: "拉脱维亚",
    official_name_en: "Latvia",
    official_name_es: "Letonia",
    official_name_fr: "Lettonie",
    official_name_ru: "Латвия",
  },
  {
    "CLDR display name": "Lebanon",
    Capital: "Beirut",
    Continent: "AS",
    DS: "RL",
    "Developed / Developing Countries": "Developing",
    Dial: "961",
    EDGAR: "M8",
    FIFA: "LIB",
    FIPS: "LE",
    GAUL: "141",
    "Geoname ID": 272103,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LIB",
    "ISO3166-1-Alpha-2": "LB",
    "ISO3166-1-Alpha-3": "LBN",
    "ISO3166-1-numeric": "422",
    "ISO4217-currency_alphabetic_code": "LBP",
    "ISO4217-currency_country_name": "LEBANON",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Lebanese Pound",
    "ISO4217-currency_numeric_code": "422",
    ITU: "LBN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-LB,fr-LB,en,hy",
    "Least Developed Countries (LDC)": null,
    M49: 422,
    MARC: "le",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".lb",
    "UNTERM Arabic Formal": "الجمهورية اللبنانية",
    "UNTERM Arabic Short": "لبنان",
    "UNTERM Chinese Formal": "黎巴嫩共和国",
    "UNTERM Chinese Short": "黎巴嫩",
    "UNTERM English Formal": "the Lebanese Republic",
    "UNTERM English Short": "Lebanon",
    "UNTERM French Formal": "la République libanaise",
    "UNTERM French Short": "Liban (le)",
    "UNTERM Russian Formal": "Ливанская Республика",
    "UNTERM Russian Short": "Ливан",
    "UNTERM Spanish Formal": "la República Libanesa",
    "UNTERM Spanish Short": "Líbano (el)",
    WMO: "LB",
    is_independent: "Yes",
    official_name_ar: "لبنان",
    official_name_cn: "黎巴嫩",
    official_name_en: "Lebanon",
    official_name_es: "Líbano",
    official_name_fr: "Liban",
    official_name_ru: "Ливан",
  },
  {
    "CLDR display name": "Lesotho",
    Capital: "Maseru",
    Continent: "AF",
    DS: "LS",
    "Developed / Developing Countries": "Developing",
    Dial: "266",
    EDGAR: "M9",
    FIFA: "LES",
    FIPS: "LT",
    GAUL: "142",
    "Geoname ID": 932692,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LES",
    "ISO3166-1-Alpha-2": "LS",
    "ISO3166-1-Alpha-3": "LSO",
    "ISO3166-1-numeric": "426",
    "ISO4217-currency_alphabetic_code": "LSL,ZAR",
    "ISO4217-currency_country_name": "LESOTHO",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Loti,Rand",
    "ISO4217-currency_numeric_code": "426,710",
    ITU: "LSO",
    "Intermediate Region Code": "18",
    "Intermediate Region Name": "Southern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-LS,st,zu,xh",
    "Least Developed Countries (LDC)": "x",
    M49: 426,
    MARC: "lo",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ls",
    "UNTERM Arabic Formal": "مملكة ليسوتو",
    "UNTERM Arabic Short": "ليسوتو",
    "UNTERM Chinese Formal": "莱索托王国",
    "UNTERM Chinese Short": "莱索托",
    "UNTERM English Formal": "the Kingdom of Lesotho",
    "UNTERM English Short": "Lesotho",
    "UNTERM French Formal": "le Royaume du Lesotho",
    "UNTERM French Short": "Lesotho (le)",
    "UNTERM Russian Formal": "Королевство Лесото",
    "UNTERM Russian Short": "Лесото",
    "UNTERM Spanish Formal": "el Reino de Lesotho",
    "UNTERM Spanish Short": "Lesotho",
    WMO: "LS",
    is_independent: "Yes",
    official_name_ar: "ليسوتو",
    official_name_cn: "莱索托",
    official_name_en: "Lesotho",
    official_name_es: "Lesotho",
    official_name_fr: "Lesotho",
    official_name_ru: "Лесото",
  },
  {
    "CLDR display name": "Liberia",
    Capital: "Monrovia",
    Continent: "AF",
    DS: "LB",
    "Developed / Developing Countries": "Developing",
    Dial: "231",
    EDGAR: "N0",
    FIFA: "LBR",
    FIPS: "LI",
    GAUL: "144",
    "Geoname ID": 2275384,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LBR",
    "ISO3166-1-Alpha-2": "LR",
    "ISO3166-1-Alpha-3": "LBR",
    "ISO3166-1-numeric": "430",
    "ISO4217-currency_alphabetic_code": "LRD",
    "ISO4217-currency_country_name": "LIBERIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Liberian Dollar",
    "ISO4217-currency_numeric_code": "430",
    ITU: "LBR",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-LR",
    "Least Developed Countries (LDC)": "x",
    M49: 430,
    MARC: "lb",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".lr",
    "UNTERM Arabic Formal": "جمهورية ليبريا",
    "UNTERM Arabic Short": "ليبريا",
    "UNTERM Chinese Formal": "利比里亚共和国",
    "UNTERM Chinese Short": "利比里亚",
    "UNTERM English Formal": "the Republic of Liberia",
    "UNTERM English Short": "Liberia",
    "UNTERM French Formal": "la République du Libéria",
    "UNTERM French Short": "Libéria (le)",
    "UNTERM Russian Formal": "Республика Либерия",
    "UNTERM Russian Short": "Либерия",
    "UNTERM Spanish Formal": "la República de Liberia",
    "UNTERM Spanish Short": "Liberia",
    WMO: "LI",
    is_independent: "Yes",
    official_name_ar: "ليبريا",
    official_name_cn: "利比里亚",
    official_name_en: "Liberia",
    official_name_es: "Liberia",
    official_name_fr: "Libéria",
    official_name_ru: "Либерия",
  },
  {
    "CLDR display name": "Libya",
    Capital: "Tripoli",
    Continent: "AF",
    DS: "LAR",
    "Developed / Developing Countries": "Developing",
    Dial: "218",
    EDGAR: null,
    FIFA: "LBY",
    FIPS: "LY",
    GAUL: "145",
    "Geoname ID": 2215636,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LBA",
    "ISO3166-1-Alpha-2": "LY",
    "ISO3166-1-Alpha-3": "LBY",
    "ISO3166-1-numeric": "434",
    "ISO4217-currency_alphabetic_code": "LYD",
    "ISO4217-currency_country_name": "LIBYA",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Libyan Dinar",
    "ISO4217-currency_numeric_code": "434",
    ITU: "LBY",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-LY,it,en",
    "Least Developed Countries (LDC)": null,
    M49: 434,
    MARC: "ly",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".ly",
    "UNTERM Arabic Formal": "ليبيا",
    "UNTERM Arabic Short": "ليبيا",
    "UNTERM Chinese Formal": "利比亚",
    "UNTERM Chinese Short": "利比亚",
    "UNTERM English Formal": "Libya",
    "UNTERM English Short": "Libya",
    "UNTERM French Formal": "la Libye",
    "UNTERM French Short": "Libye (la)",
    "UNTERM Russian Formal": "Ливия",
    "UNTERM Russian Short": "Ливия",
    "UNTERM Spanish Formal": "Libia",
    "UNTERM Spanish Short": "Libia",
    WMO: "LY",
    is_independent: "Yes",
    official_name_ar: "ليبيا",
    official_name_cn: "利比亚",
    official_name_en: "Libya",
    official_name_es: "Libia",
    official_name_fr: "Libye",
    official_name_ru: "Ливия",
  },
  {
    "CLDR display name": "Liechtenstein",
    Capital: "Vaduz",
    Continent: "EU",
    DS: "FL",
    "Developed / Developing Countries": "Developed",
    Dial: "423",
    EDGAR: "N2",
    FIFA: "LIE",
    FIPS: "LS",
    GAUL: "146",
    "Geoname ID": 3042058,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LIE",
    "ISO3166-1-Alpha-2": "LI",
    "ISO3166-1-Alpha-3": "LIE",
    "ISO3166-1-numeric": "438",
    "ISO4217-currency_alphabetic_code": "CHF",
    "ISO4217-currency_country_name": "LIECHTENSTEIN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Swiss Franc",
    "ISO4217-currency_numeric_code": "756",
    ITU: "LIE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "de-LI",
    "Least Developed Countries (LDC)": null,
    M49: 438,
    MARC: "lh",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".li",
    "UNTERM Arabic Formal": "إمارة ليختنشتاين",
    "UNTERM Arabic Short": "ليختنشتاين",
    "UNTERM Chinese Formal": "列支敦士登公国",
    "UNTERM Chinese Short": "列支敦士登",
    "UNTERM English Formal": "the Principality of Liechtenstein",
    "UNTERM English Short": "Liechtenstein",
    "UNTERM French Formal": "la Principauté du Liechtenstein",
    "UNTERM French Short": "Liechtenstein (le)",
    "UNTERM Russian Formal": "Княжество Лихтенштейн",
    "UNTERM Russian Short": "Лихтенштейн",
    "UNTERM Spanish Formal": "el Principado de Liechtenstein",
    "UNTERM Spanish Short": "Liechtenstein",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "ليختنشتاين",
    official_name_cn: "列支敦士登",
    official_name_en: "Liechtenstein",
    official_name_es: "Liechtenstein",
    official_name_fr: "Liechtenstein",
    official_name_ru: "Лихтенштейн",
  },
  {
    "CLDR display name": "Lithuania",
    Capital: "Vilnius",
    Continent: "EU",
    DS: "LT",
    "Developed / Developing Countries": "Developed",
    Dial: "370",
    EDGAR: "1Q",
    FIFA: "LTU",
    FIPS: "LH",
    GAUL: "147",
    "Geoname ID": 597427,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LTU",
    "ISO3166-1-Alpha-2": "LT",
    "ISO3166-1-Alpha-3": "LTU",
    "ISO3166-1-numeric": "440",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "LITHUANIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "LTU",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "lt,ru,pl",
    "Least Developed Countries (LDC)": null,
    M49: 440,
    MARC: "li",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".lt",
    "UNTERM Arabic Formal": "جمهورية ليتوانيا",
    "UNTERM Arabic Short": "ليتوانيا",
    "UNTERM Chinese Formal": "立陶宛共和国",
    "UNTERM Chinese Short": "立陶宛",
    "UNTERM English Formal": "the Republic of Lithuania",
    "UNTERM English Short": "Lithuania",
    "UNTERM French Formal": "la République de Lituanie",
    "UNTERM French Short": "Lituanie (la)",
    "UNTERM Russian Formal": "Литовская Республика",
    "UNTERM Russian Short": "Литва",
    "UNTERM Spanish Formal": "la República de Lituania",
    "UNTERM Spanish Short": "Lituania",
    WMO: "LT",
    is_independent: "Yes",
    official_name_ar: "ليتوانيا",
    official_name_cn: "立陶宛",
    official_name_en: "Lithuania",
    official_name_es: "Lituania",
    official_name_fr: "Lituanie",
    official_name_ru: "Литва",
  },
  {
    "CLDR display name": "Luxembourg",
    Capital: "Luxembourg",
    Continent: "EU",
    DS: "L",
    "Developed / Developing Countries": "Developed",
    Dial: "352",
    EDGAR: "N4",
    FIFA: "LUX",
    FIPS: "LU",
    GAUL: "148",
    "Geoname ID": 2960313,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LUX",
    "ISO3166-1-Alpha-2": "LU",
    "ISO3166-1-Alpha-3": "LUX",
    "ISO3166-1-numeric": "442",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "LUXEMBOURG",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "LUX",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "lb,de-LU,fr-LU",
    "Least Developed Countries (LDC)": null,
    M49: 442,
    MARC: "lu",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".lu",
    "UNTERM Arabic Formal": "دوقية لكسمبرغ الكبرى",
    "UNTERM Arabic Short": "لكسمبرغ",
    "UNTERM Chinese Formal": "卢森堡大公国",
    "UNTERM Chinese Short": "卢森堡",
    "UNTERM English Formal": "the Grand Duchy of Luxembourg",
    "UNTERM English Short": "Luxembourg",
    "UNTERM French Formal": "le Grand-Duché de Luxembourg",
    "UNTERM French Short": "Luxembourg (le)",
    "UNTERM Russian Formal": "Великое Герцогство Люксембург",
    "UNTERM Russian Short": "Люксембург",
    "UNTERM Spanish Formal": "el Gran Ducado de Luxemburgo",
    "UNTERM Spanish Short": "Luxemburgo",
    WMO: "BX",
    is_independent: "Yes",
    official_name_ar: "لكسمبرغ",
    official_name_cn: "卢森堡",
    official_name_en: "Luxembourg",
    official_name_es: "Luxemburgo",
    official_name_fr: "Luxembourg",
    official_name_ru: "Люксембург",
  },
  {
    "CLDR display name": "Madagascar",
    Capital: "Antananarivo",
    Continent: "AF",
    DS: "RM",
    "Developed / Developing Countries": "Developing",
    Dial: "261",
    EDGAR: "N6",
    FIFA: "MAD",
    FIPS: "MA",
    GAUL: "150",
    "Geoname ID": 1062947,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAD",
    "ISO3166-1-Alpha-2": "MG",
    "ISO3166-1-Alpha-3": "MDG",
    "ISO3166-1-numeric": "450",
    "ISO4217-currency_alphabetic_code": "MGA",
    "ISO4217-currency_country_name": "MADAGASCAR",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Malagasy Ariary",
    "ISO4217-currency_numeric_code": "969",
    ITU: "MDG",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-MG,mg",
    "Least Developed Countries (LDC)": "x",
    M49: 450,
    MARC: "mg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".mg",
    "UNTERM Arabic Formal": "جمهورية مدغشقر",
    "UNTERM Arabic Short": "مدغشقر",
    "UNTERM Chinese Formal": "马达加斯加共和国",
    "UNTERM Chinese Short": "马达加斯加",
    "UNTERM English Formal": "the Republic of Madagascar",
    "UNTERM English Short": "Madagascar",
    "UNTERM French Formal": "la République de Madagascar",
    "UNTERM French Short": "Madagascar [fém.]",
    "UNTERM Russian Formal": "Республика Мадагаскар",
    "UNTERM Russian Short": "Мадагаскар",
    "UNTERM Spanish Formal": "la República de Madagascar",
    "UNTERM Spanish Short": "Madagascar",
    WMO: "MG",
    is_independent: "Yes",
    official_name_ar: "مدغشقر",
    official_name_cn: "马达加斯加",
    official_name_en: "Madagascar",
    official_name_es: "Madagascar",
    official_name_fr: "Madagascar",
    official_name_ru: "Мадагаскар",
  },
  {
    "CLDR display name": "Malawi",
    Capital: "Lilongwe",
    Continent: "AF",
    DS: "MW",
    "Developed / Developing Countries": "Developing",
    Dial: "265",
    EDGAR: "N7",
    FIFA: "MWI",
    FIPS: "MI",
    GAUL: "152",
    "Geoname ID": 927384,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAW",
    "ISO3166-1-Alpha-2": "MW",
    "ISO3166-1-Alpha-3": "MWI",
    "ISO3166-1-numeric": "454",
    "ISO4217-currency_alphabetic_code": "MWK",
    "ISO4217-currency_country_name": "MALAWI",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Malawi Kwacha",
    "ISO4217-currency_numeric_code": "454",
    ITU: "MWI",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "ny,yao,tum,swk",
    "Least Developed Countries (LDC)": "x",
    M49: 454,
    MARC: "mw",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".mw",
    "UNTERM Arabic Formal": "جمهورية ملاوي",
    "UNTERM Arabic Short": "ملاوي",
    "UNTERM Chinese Formal": "马拉维共和国",
    "UNTERM Chinese Short": "马拉维",
    "UNTERM English Formal": "the Republic of Malawi",
    "UNTERM English Short": "Malawi",
    "UNTERM French Formal": "la République du Malawi",
    "UNTERM French Short": "Malawi (le)",
    "UNTERM Russian Formal": "Республика Малави",
    "UNTERM Russian Short": "Малави",
    "UNTERM Spanish Formal": "la República de Malawi",
    "UNTERM Spanish Short": "Malawi",
    WMO: "MW",
    is_independent: "Yes",
    official_name_ar: "ملاوي",
    official_name_cn: "马拉维",
    official_name_en: "Malawi",
    official_name_es: "Malawi",
    official_name_fr: "Malawi",
    official_name_ru: "Малави",
  },
  {
    "CLDR display name": "Malaysia",
    Capital: "Kuala Lumpur",
    Continent: "AS",
    DS: "MAL",
    "Developed / Developing Countries": "Developing",
    Dial: "60",
    EDGAR: "N8",
    FIFA: "MAS",
    FIPS: "MY",
    GAUL: "153",
    "Geoname ID": 1733045,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAS",
    "ISO3166-1-Alpha-2": "MY",
    "ISO3166-1-Alpha-3": "MYS",
    "ISO3166-1-numeric": "458",
    "ISO4217-currency_alphabetic_code": "MYR",
    "ISO4217-currency_country_name": "MALAYSIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Malaysian Ringgit",
    "ISO4217-currency_numeric_code": "458",
    ITU: "MLA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ms-MY,en,zh,ta,te,ml,pa,th",
    "Least Developed Countries (LDC)": null,
    M49: 458,
    MARC: "my",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".my",
    "UNTERM Arabic Formal": "ماليزيا",
    "UNTERM Arabic Short": "ماليزيا",
    "UNTERM Chinese Formal": "马来西亚",
    "UNTERM Chinese Short": "马来西亚",
    "UNTERM English Formal": "Malaysia",
    "UNTERM English Short": "Malaysia",
    "UNTERM French Formal": "la Malaisie",
    "UNTERM French Short": "Malaisie (la)",
    "UNTERM Russian Formal": "Малайзия",
    "UNTERM Russian Short": "Малайзия",
    "UNTERM Spanish Formal": "Federación de Malasia",
    "UNTERM Spanish Short": "Malasia",
    WMO: "MS",
    is_independent: "Yes",
    official_name_ar: "ماليزيا",
    official_name_cn: "马来西亚",
    official_name_en: "Malaysia",
    official_name_es: "Malasia",
    official_name_fr: "Malaisie",
    official_name_ru: "Малайзия",
  },
  {
    "CLDR display name": "Maldives",
    Capital: "Male",
    Continent: "AS",
    DS: "MV",
    "Developed / Developing Countries": "Developing",
    Dial: "960",
    EDGAR: "N9",
    FIFA: "MDV",
    FIPS: "MV",
    GAUL: "154",
    "Geoname ID": 1282028,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MDV",
    "ISO3166-1-Alpha-2": "MV",
    "ISO3166-1-Alpha-3": "MDV",
    "ISO3166-1-numeric": "462",
    "ISO4217-currency_alphabetic_code": "MVR",
    "ISO4217-currency_country_name": "MALDIVES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Rufiyaa",
    "ISO4217-currency_numeric_code": "462",
    ITU: "MLD",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "dv,en",
    "Least Developed Countries (LDC)": null,
    M49: 462,
    MARC: "xc",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".mv",
    "UNTERM Arabic Formal": "جمهورية ملديف",
    "UNTERM Arabic Short": "ملديف",
    "UNTERM Chinese Formal": "马尔代夫共和国",
    "UNTERM Chinese Short": "马尔代夫",
    "UNTERM English Formal": "the Republic of Maldives",
    "UNTERM English Short": "Maldives",
    "UNTERM French Formal": "la République des Maldives",
    "UNTERM French Short": "Maldives (les) [fém.]",
    "UNTERM Russian Formal": "Мальдивская Республика",
    "UNTERM Russian Short": "Мальдивские Острова",
    "UNTERM Spanish Formal": "la República de Maldivas",
    "UNTERM Spanish Short": "Maldivas",
    WMO: "MV",
    is_independent: "Yes",
    official_name_ar: "ملديف",
    official_name_cn: "马尔代夫",
    official_name_en: "Maldives",
    official_name_es: "Maldivas",
    official_name_fr: "Maldives",
    official_name_ru: "Мальдивские Острова",
  },
  {
    "CLDR display name": "Mali",
    Capital: "Bamako",
    Continent: "AF",
    DS: "RMM",
    "Developed / Developing Countries": "Developing",
    Dial: "223",
    EDGAR: "O0",
    FIFA: "MLI",
    FIPS: "ML",
    GAUL: "155",
    "Geoname ID": 2453866,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MLI",
    "ISO3166-1-Alpha-2": "ML",
    "ISO3166-1-Alpha-3": "MLI",
    "ISO3166-1-numeric": "466",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "MALI",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "MLI",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-ML,bm",
    "Least Developed Countries (LDC)": "x",
    M49: 466,
    MARC: "ml",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ml",
    "UNTERM Arabic Formal": "جمهورية مالي",
    "UNTERM Arabic Short": "مالي",
    "UNTERM Chinese Formal": "马里共和国",
    "UNTERM Chinese Short": "马里",
    "UNTERM English Formal": "the Republic of Mali",
    "UNTERM English Short": "Mali",
    "UNTERM French Formal": "la République du Mali",
    "UNTERM French Short": "Mali (le)",
    "UNTERM Russian Formal": "Республика Мали",
    "UNTERM Russian Short": "Мали",
    "UNTERM Spanish Formal": "la República de Malí",
    "UNTERM Spanish Short": "Malí",
    WMO: "MI",
    is_independent: "Yes",
    official_name_ar: "مالي",
    official_name_cn: "马里",
    official_name_en: "Mali",
    official_name_es: "Malí",
    official_name_fr: "Mali",
    official_name_ru: "Мали",
  },
  {
    "CLDR display name": "Malta",
    Capital: "Valletta",
    Continent: "EU",
    DS: "M",
    "Developed / Developing Countries": "Developed",
    Dial: "356",
    EDGAR: "O1",
    FIFA: "MLT",
    FIPS: "MT",
    GAUL: "156",
    "Geoname ID": 2562770,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MLT",
    "ISO3166-1-Alpha-2": "MT",
    "ISO3166-1-Alpha-3": "MLT",
    "ISO3166-1-numeric": "470",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "MALTA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "MLT",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "mt,en-MT",
    "Least Developed Countries (LDC)": null,
    M49: 470,
    MARC: "mm",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".mt",
    "UNTERM Arabic Formal": "جمهورية مالطة",
    "UNTERM Arabic Short": "مالطة",
    "UNTERM Chinese Formal": "马耳他共和国",
    "UNTERM Chinese Short": "马耳他",
    "UNTERM English Formal": "the Republic of Malta",
    "UNTERM English Short": "Malta",
    "UNTERM French Formal": "la République de Malte",
    "UNTERM French Short": "Malte [fém.]",
    "UNTERM Russian Formal": "Республика Мальта",
    "UNTERM Russian Short": "Мальта",
    "UNTERM Spanish Formal": "la República de Malta",
    "UNTERM Spanish Short": "Malta",
    WMO: "ML",
    is_independent: "Yes",
    official_name_ar: "مالطة",
    official_name_cn: "马耳他",
    official_name_en: "Malta",
    official_name_es: "Malta",
    official_name_fr: "Malte",
    official_name_ru: "Мальта",
  },
  {
    "CLDR display name": "Marshall Islands",
    Capital: "Majuro",
    Continent: "OC",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "692",
    EDGAR: "1T",
    FIFA: "MHL",
    FIPS: "RM",
    GAUL: "157",
    "Geoname ID": 2080185,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MSH",
    "ISO3166-1-Alpha-2": "MH",
    "ISO3166-1-Alpha-3": "MHL",
    "ISO3166-1-numeric": "584",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "MARSHALL ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "MHL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "mh,en-MH",
    "Least Developed Countries (LDC)": null,
    M49: 584,
    MARC: "xe",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".mh",
    "UNTERM Arabic Formal": "جمهورية جزر مارشال",
    "UNTERM Arabic Short": "جزر مارشال",
    "UNTERM Chinese Formal": "马绍尔群岛共和国",
    "UNTERM Chinese Short": "马绍尔群岛",
    "UNTERM English Formal": "the Republic of the Marshall Islands",
    "UNTERM English Short": "Marshall Islands (the)",
    "UNTERM French Formal": "la République des Îles Marshall",
    "UNTERM French Short": "Îles Marshall (les)",
    "UNTERM Russian Formal": "Республика Маршалловы Острова",
    "UNTERM Russian Short": "Маршалловы Острова",
    "UNTERM Spanish Formal": "la República de las Islas Marshall",
    "UNTERM Spanish Short": "Islas Marshall (las)",
    WMO: "MH",
    is_independent: "Yes",
    official_name_ar: "جزر مارشال",
    official_name_cn: "马绍尔群岛",
    official_name_en: "Marshall Islands",
    official_name_es: "Islas Marshall",
    official_name_fr: "Îles Marshall",
    official_name_ru: "Маршалловы Острова",
  },
  {
    "CLDR display name": "Martinique",
    Capital: "Fort-de-France",
    Continent: "NA",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "596",
    EDGAR: "O2",
    FIFA: "MTQ",
    FIPS: "MB",
    GAUL: "158",
    "Geoname ID": 3570311,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MRT",
    "ISO3166-1-Alpha-2": "MQ",
    "ISO3166-1-Alpha-3": "MTQ",
    "ISO3166-1-numeric": "474",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "MARTINIQUE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "MRT",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-MQ",
    "Least Developed Countries (LDC)": null,
    M49: 474,
    MARC: "mq",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".mq",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "MR",
    is_independent: "Part of FR",
    official_name_ar: "مارتينيك",
    official_name_cn: "马提尼克",
    official_name_en: "Martinique",
    official_name_es: "Martinica",
    official_name_fr: "Martinique",
    official_name_ru: "Мартиника",
  },
  {
    "CLDR display name": "Mauritania",
    Capital: "Nouakchott",
    Continent: "AF",
    DS: "RIM",
    "Developed / Developing Countries": "Developing",
    Dial: "222",
    EDGAR: "O3",
    FIFA: "MTN",
    FIPS: "MR",
    GAUL: "159",
    "Geoname ID": 2378080,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MTN",
    "ISO3166-1-Alpha-2": "MR",
    "ISO3166-1-Alpha-3": "MRT",
    "ISO3166-1-numeric": "478",
    "ISO4217-currency_alphabetic_code": "MRU",
    "ISO4217-currency_country_name": "MAURITANIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Ouguiya",
    "ISO4217-currency_numeric_code": "929",
    ITU: "MTN",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-MR,fuc,snk,fr,mey,wo",
    "Least Developed Countries (LDC)": "x",
    M49: 478,
    MARC: "mu",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".mr",
    "UNTERM Arabic Formal": "الجمهورية الإسلامية الموريتانية",
    "UNTERM Arabic Short": "موريتانيا",
    "UNTERM Chinese Formal": "毛里塔尼亚伊斯兰共和国",
    "UNTERM Chinese Short": "毛里塔尼亚",
    "UNTERM English Formal": "the Islamic Republic of Mauritania",
    "UNTERM English Short": "Mauritania",
    "UNTERM French Formal": "la République islamique de Mauritanie",
    "UNTERM French Short": "Mauritanie (la)",
    "UNTERM Russian Formal": "Исламская Республика Мавритания",
    "UNTERM Russian Short": "Мавритания",
    "UNTERM Spanish Formal": "la República Islámica de Mauritania",
    "UNTERM Spanish Short": "Mauritania",
    WMO: "MT",
    is_independent: "Yes",
    official_name_ar: "موريتانيا",
    official_name_cn: "毛里塔尼亚",
    official_name_en: "Mauritania",
    official_name_es: "Mauritania",
    official_name_fr: "Mauritanie",
    official_name_ru: "Мавритания",
  },
  {
    "CLDR display name": "Mauritius",
    Capital: "Port Louis",
    Continent: "AF",
    DS: "MS",
    "Developed / Developing Countries": "Developing",
    Dial: "230",
    EDGAR: "O4",
    FIFA: "MRI",
    FIPS: "MP",
    GAUL: "160",
    "Geoname ID": 934292,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MRI",
    "ISO3166-1-Alpha-2": "MU",
    "ISO3166-1-Alpha-3": "MUS",
    "ISO3166-1-numeric": "480",
    "ISO4217-currency_alphabetic_code": "MUR",
    "ISO4217-currency_country_name": "MAURITIUS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Mauritius Rupee",
    "ISO4217-currency_numeric_code": "480",
    ITU: "MAU",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-MU,bho,fr",
    "Least Developed Countries (LDC)": null,
    M49: 480,
    MARC: "mf",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".mu",
    "UNTERM Arabic Formal": "جمهورية موريشيوس",
    "UNTERM Arabic Short": "موريشيوس",
    "UNTERM Chinese Formal": "毛里求斯共和国",
    "UNTERM Chinese Short": "毛里求斯",
    "UNTERM English Formal": "the Republic of Mauritius",
    "UNTERM English Short": "Mauritius",
    "UNTERM French Formal": "la République de Maurice",
    "UNTERM French Short": "Maurice [fém.]",
    "UNTERM Russian Formal": "Республика Маврикий",
    "UNTERM Russian Short": "Маврикий",
    "UNTERM Spanish Formal": "la República de Mauricio",
    "UNTERM Spanish Short": "Mauricio",
    WMO: "MA",
    is_independent: "Yes",
    official_name_ar: "موريشيوس",
    official_name_cn: "毛里求斯",
    official_name_en: "Mauritius",
    official_name_es: "Mauricio",
    official_name_fr: "Maurice",
    official_name_ru: "Маврикий",
  },
  {
    "CLDR display name": "Mayotte",
    Capital: "Mamoudzou",
    Continent: "AF",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "262",
    EDGAR: "2P",
    FIFA: "MYT",
    FIPS: "MF",
    GAUL: "161",
    "Geoname ID": 1024031,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAY",
    "ISO3166-1-Alpha-2": "YT",
    "ISO3166-1-Alpha-3": "MYT",
    "ISO3166-1-numeric": "175",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "MAYOTTE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "MYT",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-YT",
    "Least Developed Countries (LDC)": null,
    M49: 175,
    MARC: "ot",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".yt",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of FR",
    official_name_ar: "جزيرة مايوت",
    official_name_cn: "马约特",
    official_name_en: "Mayotte",
    official_name_es: "Mayotte",
    official_name_fr: "Mayotte",
    official_name_ru: "Острове Майотта",
  },
  {
    "CLDR display name": "Mexico",
    Capital: "Mexico City",
    Continent: "NA",
    DS: "MEX",
    "Developed / Developing Countries": "Developing",
    Dial: "52",
    EDGAR: "O5",
    FIFA: "MEX",
    FIPS: "MX",
    GAUL: "162",
    "Geoname ID": 3996063,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MEX",
    "ISO3166-1-Alpha-2": "MX",
    "ISO3166-1-Alpha-3": "MEX",
    "ISO3166-1-numeric": "484",
    "ISO4217-currency_alphabetic_code": "MXN",
    "ISO4217-currency_country_name": "MEXICO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Mexican Peso",
    "ISO4217-currency_numeric_code": "484",
    ITU: "MEX",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-MX",
    "Least Developed Countries (LDC)": null,
    M49: 484,
    MARC: "mx",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".mx",
    "UNTERM Arabic Formal": "الولايات المتحدة المكسيكية",
    "UNTERM Arabic Short": "المكسيك",
    "UNTERM Chinese Formal": "墨西哥合众国",
    "UNTERM Chinese Short": "墨西哥",
    "UNTERM English Formal": "the United Mexican States",
    "UNTERM English Short": "Mexico",
    "UNTERM French Formal": "les États-Unis du Mexique",
    "UNTERM French Short": "Mexique (le)",
    "UNTERM Russian Formal": "Мексиканские Соединенные Штаты",
    "UNTERM Russian Short": "Мексика",
    "UNTERM Spanish Formal": "los Estados Unidos Mexicanos",
    "UNTERM Spanish Short": "México",
    WMO: "MX",
    is_independent: "Yes",
    official_name_ar: "المكسيك",
    official_name_cn: "墨西哥",
    official_name_en: "Mexico",
    official_name_es: "México",
    official_name_fr: "Mexique",
    official_name_ru: "Мексика",
  },
  {
    "CLDR display name": "Micronesia",
    Capital: "Palikir",
    Continent: "OC",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "691",
    EDGAR: null,
    FIFA: "FSM",
    FIPS: "FM",
    GAUL: "163",
    "Geoname ID": 2081918,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "FSM",
    "ISO3166-1-Alpha-2": "FM",
    "ISO3166-1-Alpha-3": "FSM",
    "ISO3166-1-numeric": "583",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "MICRONESIA (FEDERATED STATES OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "FSM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg",
    "Least Developed Countries (LDC)": null,
    M49: 583,
    MARC: "fm",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".fm",
    "UNTERM Arabic Formal": "ولايات ميكرونيزيا الموحدة",
    "UNTERM Arabic Short": "ميكرونيزيا (ولايات - الموحدة)",
    "UNTERM Chinese Formal": "密克罗尼西亚联邦",
    "UNTERM Chinese Short": "密克罗尼西亚（联邦）",
    "UNTERM English Formal": "the Federated States of Micronesia",
    "UNTERM English Short": "Micronesia (Federated States of)",
    "UNTERM French Formal": "les États fédérés de Micronésie",
    "UNTERM French Short": "Micronésie (États fédérés de)",
    "UNTERM Russian Formal": "Федеративные Штаты Микронезии",
    "UNTERM Russian Short": "Микронезия (Федеративные Штаты)",
    "UNTERM Spanish Formal": "los Estados Federados de Micronesia",
    "UNTERM Spanish Short": "Micronesia (Estados Federados de)",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "ميكرونيزيا (ولايات - الموحدة)",
    official_name_cn: "密克罗尼西亚(联邦)",
    official_name_en: "Micronesia (Federated States of)",
    official_name_es: "Micronesia (Estados Federados de)",
    official_name_fr: "Micronésie (États fédérés de)",
    official_name_ru: "Микронезия (Федеративные Штаты)",
  },
  {
    "CLDR display name": "Monaco",
    Capital: "Monaco",
    Continent: "EU",
    DS: "MC",
    "Developed / Developing Countries": "Developed",
    Dial: "377",
    EDGAR: "O9",
    FIFA: "MON",
    FIPS: "MN",
    GAUL: "166",
    "Geoname ID": 2993457,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MON",
    "ISO3166-1-Alpha-2": "MC",
    "ISO3166-1-Alpha-3": "MCO",
    "ISO3166-1-numeric": "492",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "MONACO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "MCO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-MC,en,it",
    "Least Developed Countries (LDC)": null,
    M49: 492,
    MARC: "mc",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".mc",
    "UNTERM Arabic Formal": "إمارة موناكو",
    "UNTERM Arabic Short": "موناكو",
    "UNTERM Chinese Formal": "摩纳哥公国",
    "UNTERM Chinese Short": "摩纳哥",
    "UNTERM English Formal": "the Principality of Monaco",
    "UNTERM English Short": "Monaco",
    "UNTERM French Formal": "la Principauté de Monaco",
    "UNTERM French Short": "Monaco [masc.]",
    "UNTERM Russian Formal": "Княжество Монако",
    "UNTERM Russian Short": "Монако",
    "UNTERM Spanish Formal": "el Principado de Mónaco",
    "UNTERM Spanish Short": "Mónaco",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "موناكو",
    official_name_cn: "摩纳哥",
    official_name_en: "Monaco",
    official_name_es: "Mónaco",
    official_name_fr: "Monaco",
    official_name_ru: "Монако",
  },
  {
    "CLDR display name": "Mongolia",
    Capital: "Ulan Bator",
    Continent: "AS",
    DS: "MGL",
    "Developed / Developing Countries": "Developing",
    Dial: "976",
    EDGAR: "P0",
    FIFA: "MNG",
    FIPS: "MG",
    GAUL: "167",
    "Geoname ID": 2029969,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MGL",
    "ISO3166-1-Alpha-2": "MN",
    "ISO3166-1-Alpha-3": "MNG",
    "ISO3166-1-numeric": "496",
    "ISO4217-currency_alphabetic_code": "MNT",
    "ISO4217-currency_country_name": "MONGOLIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Tugrik",
    "ISO4217-currency_numeric_code": "496",
    ITU: "MNG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "mn,ru",
    "Least Developed Countries (LDC)": null,
    M49: 496,
    MARC: "mp",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".mn",
    "UNTERM Arabic Formal": "منغوليا",
    "UNTERM Arabic Short": "منغوليا",
    "UNTERM Chinese Formal": "蒙古国",
    "UNTERM Chinese Short": "蒙古",
    "UNTERM English Formal": "Mongolia",
    "UNTERM English Short": "Mongolia",
    "UNTERM French Formal": "la Mongolie",
    "UNTERM French Short": "Mongolie (la)",
    "UNTERM Russian Formal": "Монголия",
    "UNTERM Russian Short": "Монголия",
    "UNTERM Spanish Formal": "Mongolia",
    "UNTERM Spanish Short": "Mongolia",
    WMO: "MO",
    is_independent: "Yes",
    official_name_ar: "منغوليا",
    official_name_cn: "蒙古",
    official_name_en: "Mongolia",
    official_name_es: "Mongolia",
    official_name_fr: "Mongolie",
    official_name_ru: "Монголия",
  },
  {
    "CLDR display name": "Montenegro",
    Capital: "Podgorica",
    Continent: "EU",
    DS: "MNE",
    "Developed / Developing Countries": "Developed",
    Dial: "382",
    EDGAR: "Z5",
    FIFA: "MNE",
    FIPS: "MJ",
    GAUL: "2647",
    "Geoname ID": 3194884,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MGO",
    "ISO3166-1-Alpha-2": "ME",
    "ISO3166-1-Alpha-3": "MNE",
    "ISO3166-1-numeric": "499",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "MONTENEGRO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "MNE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sr,hu,bs,sq,hr,rom",
    "Least Developed Countries (LDC)": null,
    M49: 499,
    MARC: "mo",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".me",
    "UNTERM Arabic Formal": "الجبل الأسود",
    "UNTERM Arabic Short": "الجبل الأسود",
    "UNTERM Chinese Formal": "黑山",
    "UNTERM Chinese Short": "黑山",
    "UNTERM English Formal": "Montenegro",
    "UNTERM English Short": "Montenegro",
    "UNTERM French Formal": "le Monténégro",
    "UNTERM French Short": "Monténégro (le)",
    "UNTERM Russian Formal": "Черногория",
    "UNTERM Russian Short": "Черногория",
    "UNTERM Spanish Formal": "Montenegro",
    "UNTERM Spanish Short": "Montenegro",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "الجبل الأسود",
    official_name_cn: "黑山",
    official_name_en: "Montenegro",
    official_name_es: "Montenegro",
    official_name_fr: "Monténégro",
    official_name_ru: "Черногория",
  },
  {
    "CLDR display name": "Montserrat",
    Capital: "Plymouth",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "1-664",
    EDGAR: "P1",
    FIFA: "MSR",
    FIPS: "MH",
    GAUL: "168",
    "Geoname ID": 3578097,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MNT",
    "ISO3166-1-Alpha-2": "MS",
    "ISO3166-1-Alpha-3": "MSR",
    "ISO3166-1-numeric": "500",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "MONTSERRAT",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "MSR",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-MS",
    "Least Developed Countries (LDC)": null,
    M49: 500,
    MARC: "mj",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ms",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of GB",
    official_name_ar: "مونتسيرات",
    official_name_cn: "蒙特塞拉特",
    official_name_en: "Montserrat",
    official_name_es: "Montserrat",
    official_name_fr: "Montserrat",
    official_name_ru: "Монтсеррат",
  },
  {
    "CLDR display name": "Morocco",
    Capital: "Rabat",
    Continent: "AF",
    DS: "MA",
    "Developed / Developing Countries": "Developing",
    Dial: "212",
    EDGAR: "P2",
    FIFA: "MAR",
    FIPS: "MO",
    GAUL: "169",
    "Geoname ID": 2542007,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MAR",
    "ISO3166-1-Alpha-2": "MA",
    "ISO3166-1-Alpha-3": "MAR",
    "ISO3166-1-numeric": "504",
    "ISO4217-currency_alphabetic_code": "MAD",
    "ISO4217-currency_country_name": "MOROCCO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Moroccan Dirham",
    "ISO4217-currency_numeric_code": "504",
    ITU: "MRC",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-MA,ber,fr",
    "Least Developed Countries (LDC)": null,
    M49: 504,
    MARC: "mr",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".ma",
    "UNTERM Arabic Formal": "المملكة المغربية",
    "UNTERM Arabic Short": "المغرب",
    "UNTERM Chinese Formal": "摩洛哥王国",
    "UNTERM Chinese Short": "摩洛哥",
    "UNTERM English Formal": "the Kingdom of Morocco",
    "UNTERM English Short": "Morocco",
    "UNTERM French Formal": "le Royaume du Maroc",
    "UNTERM French Short": "Maroc (le)",
    "UNTERM Russian Formal": "Королевство Марокко",
    "UNTERM Russian Short": "Марокко",
    "UNTERM Spanish Formal": "el Reino de Marruecos",
    "UNTERM Spanish Short": "Marruecos",
    WMO: "MC",
    is_independent: "Yes",
    official_name_ar: "المغرب",
    official_name_cn: "摩洛哥",
    official_name_en: "Morocco",
    official_name_es: "Marruecos",
    official_name_fr: "Maroc",
    official_name_ru: "Марокко",
  },
  {
    "CLDR display name": "Mozambique",
    Capital: "Maputo",
    Continent: "AF",
    DS: "MOC",
    "Developed / Developing Countries": "Developing",
    Dial: "258",
    EDGAR: "P3",
    FIFA: "MOZ",
    FIPS: "MZ",
    GAUL: "170",
    "Geoname ID": 1036973,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MOZ",
    "ISO3166-1-Alpha-2": "MZ",
    "ISO3166-1-Alpha-3": "MOZ",
    "ISO3166-1-numeric": "508",
    "ISO4217-currency_alphabetic_code": "MZN",
    "ISO4217-currency_country_name": "MOZAMBIQUE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Mozambique Metical",
    "ISO4217-currency_numeric_code": "943",
    ITU: "MOZ",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-MZ,vmw",
    "Least Developed Countries (LDC)": "x",
    M49: 508,
    MARC: "mz",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".mz",
    "UNTERM Arabic Formal": "جمهورية موزامبيق",
    "UNTERM Arabic Short": "موزامبيق",
    "UNTERM Chinese Formal": "莫桑比克共和国",
    "UNTERM Chinese Short": "莫桑比克",
    "UNTERM English Formal": "the Republic of Mozambique",
    "UNTERM English Short": "Mozambique",
    "UNTERM French Formal": "la République du Mozambique",
    "UNTERM French Short": "Mozambique (le)",
    "UNTERM Russian Formal": "Республика Мозамбик",
    "UNTERM Russian Short": "Мозамбик",
    "UNTERM Spanish Formal": "la República de Mozambique",
    "UNTERM Spanish Short": "Mozambique",
    WMO: "MZ",
    is_independent: "Yes",
    official_name_ar: "موزامبيق",
    official_name_cn: "莫桑比克",
    official_name_en: "Mozambique",
    official_name_es: "Mozambique",
    official_name_fr: "Mozambique",
    official_name_ru: "Мозамбик",
  },
  {
    "CLDR display name": "Myanmar",
    Capital: "Nay Pyi Taw",
    Continent: "AS",
    DS: "BUR",
    "Developed / Developing Countries": "Developing",
    Dial: "95",
    EDGAR: "E1",
    FIFA: "MYA",
    FIPS: "BM",
    GAUL: "171",
    "Geoname ID": 1327865,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MYA",
    "ISO3166-1-Alpha-2": "MM",
    "ISO3166-1-Alpha-3": "MMR",
    "ISO3166-1-numeric": "104",
    "ISO4217-currency_alphabetic_code": "MMK",
    "ISO4217-currency_country_name": "MYANMAR",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Kyat",
    "ISO4217-currency_numeric_code": "104",
    ITU: "MYA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "my",
    "Least Developed Countries (LDC)": "x",
    M49: 104,
    MARC: "br",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".mm",
    "UNTERM Arabic Formal": "جمهورية اتحاد ميانمار",
    "UNTERM Arabic Short": "ميانمار",
    "UNTERM Chinese Formal": "缅甸联邦共和国",
    "UNTERM Chinese Short": "缅甸",
    "UNTERM English Formal": "the Republic of the Union of Myanmar",
    "UNTERM English Short": "Myanmar",
    "UNTERM French Formal": "la République de l'Union du Myanmar",
    "UNTERM French Short": "Myanmar (le)",
    "UNTERM Russian Formal": "Республика Союз Мьянма",
    "UNTERM Russian Short": "Мьянма",
    "UNTERM Spanish Formal": "la República de la Unión de Myanmar",
    "UNTERM Spanish Short": "Myanmar",
    WMO: "BM",
    is_independent: "Yes",
    official_name_ar: "ميانمار",
    official_name_cn: "缅甸",
    official_name_en: "Myanmar",
    official_name_es: "Myanmar",
    official_name_fr: "Myanmar",
    official_name_ru: "Мьянма",
  },
  {
    "CLDR display name": "Namibia",
    Capital: "Windhoek",
    Continent: "AF",
    DS: "NAM",
    "Developed / Developing Countries": "Developing",
    Dial: "264",
    EDGAR: "T6",
    FIFA: "NAM",
    FIPS: "WA",
    GAUL: "172",
    "Geoname ID": 3355338,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NAM",
    "ISO3166-1-Alpha-2": "NA",
    "ISO3166-1-Alpha-3": "NAM",
    "ISO3166-1-numeric": "516",
    "ISO4217-currency_alphabetic_code": "NAD,ZAR",
    "ISO4217-currency_country_name": "NAMIBIA",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Namibia Dollar,Rand",
    "ISO4217-currency_numeric_code": "516,710",
    ITU: "NMB",
    "Intermediate Region Code": "18",
    "Intermediate Region Name": "Southern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-NA,af,de,hz,naq",
    "Least Developed Countries (LDC)": null,
    M49: 516,
    MARC: "sx",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".na",
    "UNTERM Arabic Formal": "جمهورية ناميبيا",
    "UNTERM Arabic Short": "ناميبيا",
    "UNTERM Chinese Formal": "纳米比亚共和国",
    "UNTERM Chinese Short": "纳米比亚",
    "UNTERM English Formal": "the Republic of Namibia",
    "UNTERM English Short": "Namibia",
    "UNTERM French Formal": "la République de Namibie",
    "UNTERM French Short": "Namibie (la)",
    "UNTERM Russian Formal": "Республика Намибия",
    "UNTERM Russian Short": "Намибия",
    "UNTERM Spanish Formal": "la República de Namibia",
    "UNTERM Spanish Short": "Namibia",
    WMO: "NM",
    is_independent: "Yes",
    official_name_ar: "ناميبيا",
    official_name_cn: "纳米比亚",
    official_name_en: "Namibia",
    official_name_es: "Namibia",
    official_name_fr: "Namibie",
    official_name_ru: "Намибия",
  },
  {
    "CLDR display name": "Nauru",
    Capital: "Yaren",
    Continent: "OC",
    DS: "NAU",
    "Developed / Developing Countries": "Developing",
    Dial: "674",
    EDGAR: "P5",
    FIFA: "NRU",
    FIPS: "NR",
    GAUL: "173",
    "Geoname ID": 2110425,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NRU",
    "ISO3166-1-Alpha-2": "NR",
    "ISO3166-1-Alpha-3": "NRU",
    "ISO3166-1-numeric": "520",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "NAURU",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "NRU",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "na,en-NR",
    "Least Developed Countries (LDC)": null,
    M49: 520,
    MARC: "nu",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".nr",
    "UNTERM Arabic Formal": "جمهورية ناورو",
    "UNTERM Arabic Short": "ناورو",
    "UNTERM Chinese Formal": "瑙鲁共和国",
    "UNTERM Chinese Short": "瑙鲁",
    "UNTERM English Formal": "the Republic of Nauru",
    "UNTERM English Short": "Nauru",
    "UNTERM French Formal": "la République de Nauru",
    "UNTERM French Short": "Nauru [fém.]",
    "UNTERM Russian Formal": "Республика Науру",
    "UNTERM Russian Short": "Науру",
    "UNTERM Spanish Formal": "la República de Nauru",
    "UNTERM Spanish Short": "Nauru",
    WMO: "NW",
    is_independent: "Yes",
    official_name_ar: "ناورو",
    official_name_cn: "瑙鲁",
    official_name_en: "Nauru",
    official_name_es: "Nauru",
    official_name_fr: "Nauru",
    official_name_ru: "Науру",
  },
  {
    "CLDR display name": "Nepal",
    Capital: "Kathmandu",
    Continent: "AS",
    DS: "NEP",
    "Developed / Developing Countries": "Developing",
    Dial: "977",
    EDGAR: "P6",
    FIFA: "NEP",
    FIPS: "NP",
    GAUL: "175",
    "Geoname ID": 1282988,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NEP",
    "ISO3166-1-Alpha-2": "NP",
    "ISO3166-1-Alpha-3": "NPL",
    "ISO3166-1-numeric": "524",
    "ISO4217-currency_alphabetic_code": "NPR",
    "ISO4217-currency_country_name": "NEPAL",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Nepalese Rupee",
    "ISO4217-currency_numeric_code": "524",
    ITU: "NPL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "ne,en",
    "Least Developed Countries (LDC)": "x",
    M49: 524,
    MARC: "np",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".np",
    "UNTERM Arabic Formal": "جمهورية نيبال الديمقراطية الاتحادية",
    "UNTERM Arabic Short": "نيبال",
    "UNTERM Chinese Formal": "尼泊尔联邦民主共和国",
    "UNTERM Chinese Short": "尼泊尔",
    "UNTERM English Formal": "the Federal Democratic Republic of Nepal",
    "UNTERM English Short": "Nepal",
    "UNTERM French Formal": "la République fédérale démocratique du Népal",
    "UNTERM French Short": "Népal (le)",
    "UNTERM Russian Formal": "Федеративная Демократическая Республика Непал",
    "UNTERM Russian Short": "Непал",
    "UNTERM Spanish Formal": "la República Democrática Federal de Nepal",
    "UNTERM Spanish Short": "Nepal",
    WMO: "NP",
    is_independent: "Yes",
    official_name_ar: "نيبال",
    official_name_cn: "尼泊尔",
    official_name_en: "Nepal",
    official_name_es: "Nepal",
    official_name_fr: "Népal",
    official_name_ru: "Непал",
  },
  {
    "CLDR display name": "Netherlands",
    Capital: "Amsterdam",
    Continent: "EU",
    DS: "NL",
    "Developed / Developing Countries": "Developed",
    Dial: "31",
    EDGAR: "P7",
    FIFA: "NED",
    FIPS: "NL",
    GAUL: "177",
    "Geoname ID": 2750405,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NED",
    "ISO3166-1-Alpha-2": "NL",
    "ISO3166-1-Alpha-3": "NLD",
    "ISO3166-1-numeric": "528",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "NETHERLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "HOL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl-NL,fy-NL",
    "Least Developed Countries (LDC)": null,
    M49: 528,
    MARC: "ne",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".nl",
    "UNTERM Arabic Formal": "مملكة هولندا",
    "UNTERM Arabic Short": "هولندا",
    "UNTERM Chinese Formal": "荷兰王国",
    "UNTERM Chinese Short": "荷兰",
    "UNTERM English Formal": "the Kingdom of the Netherlands",
    "UNTERM English Short": "Netherlands (the)",
    "UNTERM French Formal": "le Royaume des Pays-Bas",
    "UNTERM French Short": "Pays-Bas (les)",
    "UNTERM Russian Formal": "Королевство Нидерландов",
    "UNTERM Russian Short": "Нидерланды",
    "UNTERM Spanish Formal": "el Reino de los Países Bajos",
    "UNTERM Spanish Short": "Países Bajos (los)",
    WMO: "NL",
    is_independent: "Yes",
    official_name_ar: "هولندا",
    official_name_cn: "荷兰",
    official_name_en: "Netherlands",
    official_name_es: "Países Bajos",
    official_name_fr: "Pays-Bas",
    official_name_ru: "Нидерланды",
  },
  {
    "CLDR display name": "New Caledonia",
    Capital: "Noumea",
    Continent: "OC",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "687",
    EDGAR: "1W",
    FIFA: "NCL",
    FIPS: "NC",
    GAUL: "178",
    "Geoname ID": 2139685,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NCD",
    "ISO3166-1-Alpha-2": "NC",
    "ISO3166-1-Alpha-3": "NCL",
    "ISO3166-1-numeric": "540",
    "ISO4217-currency_alphabetic_code": "XPF",
    "ISO4217-currency_country_name": "NEW CALEDONIA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFP Franc",
    "ISO4217-currency_numeric_code": "953",
    ITU: "NCL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-NC",
    "Least Developed Countries (LDC)": null,
    M49: 540,
    MARC: "nl",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "54",
    "Sub-region Name": "Melanesia",
    TLD: ".nc",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "NC",
    is_independent: "Territory of FR",
    official_name_ar: "كاليدونيا الجديدة",
    official_name_cn: "新喀里多尼亚",
    official_name_en: "New Caledonia",
    official_name_es: "Nueva Caledonia",
    official_name_fr: "Nouvelle-Calédonie",
    official_name_ru: "Новая Каледония",
  },
  {
    "CLDR display name": "New Zealand",
    Capital: "Wellington",
    Continent: "OC",
    DS: "NZ",
    "Developed / Developing Countries": "Developed",
    Dial: "64",
    EDGAR: "Q2",
    FIFA: "NZL",
    FIPS: "NZ",
    GAUL: "179",
    "Geoname ID": 2186224,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NZL",
    "ISO3166-1-Alpha-2": "NZ",
    "ISO3166-1-Alpha-3": "NZL",
    "ISO3166-1-numeric": "554",
    "ISO4217-currency_alphabetic_code": "NZD",
    "ISO4217-currency_country_name": "NEW ZEALAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Zealand Dollar",
    "ISO4217-currency_numeric_code": "554",
    ITU: "NZL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-NZ,mi",
    "Least Developed Countries (LDC)": null,
    M49: 554,
    MARC: "nz",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".nz",
    "UNTERM Arabic Formal": "نيوزيلندا",
    "UNTERM Arabic Short": "نيوزيلندا",
    "UNTERM Chinese Formal": "新西兰",
    "UNTERM Chinese Short": "新西兰",
    "UNTERM English Formal": "New Zealand",
    "UNTERM English Short": "New Zealand",
    "UNTERM French Formal": "la Nouvelle-Zélande",
    "UNTERM French Short": "Nouvelle-Zélande (la)",
    "UNTERM Russian Formal": "Новая Зеландия",
    "UNTERM Russian Short": "Новая Зеландия",
    "UNTERM Spanish Formal": "Nueva Zelandia",
    "UNTERM Spanish Short": "Nueva Zelandia",
    WMO: "NZ",
    is_independent: "Yes",
    official_name_ar: "نيوزيلندا",
    official_name_cn: "新西兰",
    official_name_en: "New Zealand",
    official_name_es: "Nueva Zelandia",
    official_name_fr: "Nouvelle-Zélande",
    official_name_ru: "Новая Зеландия",
  },
  {
    "CLDR display name": "Nicaragua",
    Capital: "Managua",
    Continent: "NA",
    DS: "NIC",
    "Developed / Developing Countries": "Developing",
    Dial: "505",
    EDGAR: "Q3",
    FIFA: "NCA",
    FIPS: "NU",
    GAUL: "180",
    "Geoname ID": 3617476,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NCA",
    "ISO3166-1-Alpha-2": "NI",
    "ISO3166-1-Alpha-3": "NIC",
    "ISO3166-1-numeric": "558",
    "ISO4217-currency_alphabetic_code": "NIO",
    "ISO4217-currency_country_name": "NICARAGUA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Cordoba Oro",
    "ISO4217-currency_numeric_code": "558",
    ITU: "NCG",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-NI,en",
    "Least Developed Countries (LDC)": null,
    M49: 558,
    MARC: "nq",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ni",
    "UNTERM Arabic Formal": "جمهورية نيكاراغوا",
    "UNTERM Arabic Short": "نيكاراغوا",
    "UNTERM Chinese Formal": "尼加拉瓜共和国",
    "UNTERM Chinese Short": "尼加拉瓜",
    "UNTERM English Formal": "the Republic of Nicaragua",
    "UNTERM English Short": "Nicaragua",
    "UNTERM French Formal": "la République du Nicaragua",
    "UNTERM French Short": "Nicaragua (le)",
    "UNTERM Russian Formal": "Республика Никарагуа",
    "UNTERM Russian Short": "Никарагуа",
    "UNTERM Spanish Formal": "la República de Nicaragua",
    "UNTERM Spanish Short": "Nicaragua",
    WMO: "NK",
    is_independent: "Yes",
    official_name_ar: "نيكاراغوا",
    official_name_cn: "尼加拉瓜",
    official_name_en: "Nicaragua",
    official_name_es: "Nicaragua",
    official_name_fr: "Nicaragua",
    official_name_ru: "Никарагуа",
  },
  {
    "CLDR display name": "Niger",
    Capital: "Niamey",
    Continent: "AF",
    DS: "RN",
    "Developed / Developing Countries": "Developing",
    Dial: "227",
    EDGAR: "Q4",
    FIFA: "NIG",
    FIPS: "NG",
    GAUL: "181",
    "Geoname ID": 2440476,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NIG",
    "ISO3166-1-Alpha-2": "NE",
    "ISO3166-1-Alpha-3": "NER",
    "ISO3166-1-numeric": "562",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "NIGER",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "NGR",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "fr-NE,ha,kr,dje",
    "Least Developed Countries (LDC)": "x",
    M49: 562,
    MARC: "ng",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ne",
    "UNTERM Arabic Formal": "جمهورية النيجر",
    "UNTERM Arabic Short": "النيجر",
    "UNTERM Chinese Formal": "尼日尔共和国",
    "UNTERM Chinese Short": "尼日尔",
    "UNTERM English Formal": "the Republic of the Niger",
    "UNTERM English Short": "Niger (the)",
    "UNTERM French Formal": "la République du Niger",
    "UNTERM French Short": "Niger (le)",
    "UNTERM Russian Formal": "Республика Нигер",
    "UNTERM Russian Short": "Нигер",
    "UNTERM Spanish Formal": "la República del Níger",
    "UNTERM Spanish Short": "Níger (el)",
    WMO: "NR",
    is_independent: "Yes",
    official_name_ar: "النيجر",
    official_name_cn: "尼日尔",
    official_name_en: "Niger",
    official_name_es: "Níger",
    official_name_fr: "Niger",
    official_name_ru: "Нигер",
  },
  {
    "CLDR display name": "Nigeria",
    Capital: "Abuja",
    Continent: "AF",
    DS: "WAN",
    "Developed / Developing Countries": "Developing",
    Dial: "234",
    EDGAR: "Q5",
    FIFA: "NGA",
    FIPS: "NI",
    GAUL: "182",
    "Geoname ID": 2328926,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NGR",
    "ISO3166-1-Alpha-2": "NG",
    "ISO3166-1-Alpha-3": "NGA",
    "ISO3166-1-numeric": "566",
    "ISO4217-currency_alphabetic_code": "NGN",
    "ISO4217-currency_country_name": "NIGERIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Naira",
    "ISO4217-currency_numeric_code": "566",
    ITU: "NIG",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-NG,ha,yo,ig,ff",
    "Least Developed Countries (LDC)": null,
    M49: 566,
    MARC: "nr",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ng",
    "UNTERM Arabic Formal": "جمهورية نيجيريا الاتحادية",
    "UNTERM Arabic Short": "نيجيريا",
    "UNTERM Chinese Formal": "尼日利亚联邦共和国",
    "UNTERM Chinese Short": "尼日利亚",
    "UNTERM English Formal": "the Federal Republic of Nigeria",
    "UNTERM English Short": "Nigeria",
    "UNTERM French Formal": "la République fédérale du Nigéria",
    "UNTERM French Short": "Nigéria (le)",
    "UNTERM Russian Formal": "Федеративная Республика Нигерия",
    "UNTERM Russian Short": "Нигерия",
    "UNTERM Spanish Formal": "la República Federal de Nigeria",
    "UNTERM Spanish Short": "Nigeria",
    WMO: "NI",
    is_independent: "Yes",
    official_name_ar: "نيجيريا",
    official_name_cn: "尼日利亚",
    official_name_en: "Nigeria",
    official_name_es: "Nigeria",
    official_name_fr: "Nigéria",
    official_name_ru: "Нигерия",
  },
  {
    "CLDR display name": "Niue",
    Capital: "Alofi",
    Continent: "OC",
    DS: "NZ",
    "Developed / Developing Countries": "Developing",
    Dial: "683",
    EDGAR: "Q6",
    FIFA: "NIU",
    FIPS: "NE",
    GAUL: "183",
    "Geoname ID": 4036232,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NIU",
    "ISO3166-1-Alpha-2": "NU",
    "ISO3166-1-Alpha-3": "NIU",
    "ISO3166-1-numeric": "570",
    "ISO4217-currency_alphabetic_code": "NZD",
    "ISO4217-currency_country_name": "NIUE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Zealand Dollar",
    "ISO4217-currency_numeric_code": "554",
    ITU: "NIU",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "niu,en-NU",
    "Least Developed Countries (LDC)": null,
    M49: 570,
    MARC: "xh",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".nu",
    "UNTERM Arabic Formal": "نيوي",
    "UNTERM Arabic Short": "نيوي    **",
    "UNTERM Chinese Formal": "纽埃",
    "UNTERM Chinese Short": "纽埃    **",
    "UNTERM English Formal": "Niue",
    "UNTERM English Short": "Niue    **",
    "UNTERM French Formal": "Nioué",
    "UNTERM French Short": "Nioué    **",
    "UNTERM Russian Formal": "Ниуэ",
    "UNTERM Russian Short": "Ниуэ    **",
    "UNTERM Spanish Formal": "Niue",
    "UNTERM Spanish Short": "Niue    **",
    WMO: null,
    is_independent: "Associated with NZ",
    official_name_ar: "نيوي",
    official_name_cn: "纽埃",
    official_name_en: "Niue",
    official_name_es: "Niue",
    official_name_fr: "Nioué",
    official_name_ru: "Ниуэ",
  },
  {
    "CLDR display name": "Norfolk Island",
    Capital: "Kingston",
    Continent: "OC",
    DS: "AUS",
    "Developed / Developing Countries": "Developed",
    Dial: "672",
    EDGAR: "Q7",
    FIFA: "NFK",
    FIPS: "NF",
    GAUL: "184",
    "Geoname ID": 2155115,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NFI",
    "ISO3166-1-Alpha-2": "NF",
    "ISO3166-1-Alpha-3": "NFK",
    "ISO3166-1-numeric": "574",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "NORFOLK ISLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "NFK",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-NF",
    "Least Developed Countries (LDC)": null,
    M49: 574,
    MARC: "nx",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "53",
    "Sub-region Name": "Australia and New Zealand",
    TLD: ".nf",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "NF",
    is_independent: "Territory of AU",
    official_name_ar: "جزيرة نورفلك",
    official_name_cn: "诺福克岛",
    official_name_en: "Norfolk Island",
    official_name_es: "Islas Norfolk",
    official_name_fr: "Île Norfolk",
    official_name_ru: "Остров Норфолк",
  },
  {
    "CLDR display name": "Northern Mariana Islands",
    Capital: "Saipan",
    Continent: "OC",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: "1-670",
    EDGAR: "1V",
    FIFA: "NMI",
    FIPS: "CQ",
    GAUL: "185",
    "Geoname ID": 4041468,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NMA",
    "ISO3166-1-Alpha-2": "MP",
    "ISO3166-1-Alpha-3": "MNP",
    "ISO3166-1-numeric": "580",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "NORTHERN MARIANA ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "MRA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fil,tl,zh,ch-MP,en-MP",
    "Least Developed Countries (LDC)": null,
    M49: 580,
    MARC: "nw",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".mp",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "MY",
    is_independent: "Commonwealth of US",
    official_name_ar: "جزر ماريانا الشمالية",
    official_name_cn: "北马里亚纳群岛",
    official_name_en: "Northern Mariana Islands",
    official_name_es: "Islas Marianas Septentrionales",
    official_name_fr: "Îles Mariannes du Nord",
    official_name_ru: "Северные Марианские острова",
  },
  {
    "CLDR display name": "Norway",
    Capital: "Oslo",
    Continent: "EU",
    DS: "N",
    "Developed / Developing Countries": "Developed",
    Dial: "47",
    EDGAR: "Q8",
    FIFA: "NOR",
    FIPS: "NO",
    GAUL: "186",
    "Geoname ID": 3144096,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "NOR",
    "ISO3166-1-Alpha-2": "NO",
    "ISO3166-1-Alpha-3": "NOR",
    "ISO3166-1-numeric": "578",
    "ISO4217-currency_alphabetic_code": "NOK",
    "ISO4217-currency_country_name": "NORWAY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Norwegian Krone",
    "ISO4217-currency_numeric_code": "578",
    ITU: "NOR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "no,nb,nn,se,fi",
    "Least Developed Countries (LDC)": null,
    M49: 578,
    MARC: "no",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".no",
    "UNTERM Arabic Formal": "مملكة النرويج",
    "UNTERM Arabic Short": "النرويج",
    "UNTERM Chinese Formal": "挪威王国",
    "UNTERM Chinese Short": "挪威",
    "UNTERM English Formal": "the Kingdom of Norway",
    "UNTERM English Short": "Norway",
    "UNTERM French Formal": "le Royaume de Norvège",
    "UNTERM French Short": "Norvège (la)",
    "UNTERM Russian Formal": "Королевство Норвегия",
    "UNTERM Russian Short": "Норвегия",
    "UNTERM Spanish Formal": "el Reino de Noruega",
    "UNTERM Spanish Short": "Noruega",
    WMO: "NO",
    is_independent: "Yes",
    official_name_ar: "النرويج",
    official_name_cn: "挪威",
    official_name_en: "Norway",
    official_name_es: "Noruega",
    official_name_fr: "Norvège",
    official_name_ru: "Норвегия",
  },
  {
    "CLDR display name": "Oman",
    Capital: "Muscat",
    Continent: "AS",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "968",
    EDGAR: "P4",
    FIFA: "OMA",
    FIPS: "MU",
    GAUL: "187",
    "Geoname ID": 286963,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "OMA",
    "ISO3166-1-Alpha-2": "OM",
    "ISO3166-1-Alpha-3": "OMN",
    "ISO3166-1-numeric": "512",
    "ISO4217-currency_alphabetic_code": "OMR",
    "ISO4217-currency_country_name": "OMAN",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Rial Omani",
    "ISO4217-currency_numeric_code": "512",
    ITU: "OMA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-OM,en,bal,ur",
    "Least Developed Countries (LDC)": null,
    M49: 512,
    MARC: "mk",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".om",
    "UNTERM Arabic Formal": "سلطنة عمان",
    "UNTERM Arabic Short": "عمان",
    "UNTERM Chinese Formal": "阿曼苏丹国",
    "UNTERM Chinese Short": "阿曼",
    "UNTERM English Formal": "the Sultanate of Oman",
    "UNTERM English Short": "Oman",
    "UNTERM French Formal": "le Sultanat d'Oman",
    "UNTERM French Short": "Oman [masc.]",
    "UNTERM Russian Formal": "Султанат Оман",
    "UNTERM Russian Short": "Оман",
    "UNTERM Spanish Formal": "la Sultanía de Omán",
    "UNTERM Spanish Short": "Omán",
    WMO: "OM",
    is_independent: "Yes",
    official_name_ar: "عمان",
    official_name_cn: "阿曼",
    official_name_en: "Oman",
    official_name_es: "Omán",
    official_name_fr: "Oman",
    official_name_ru: "Оман",
  },
  {
    "CLDR display name": "Pakistan",
    Capital: "Islamabad",
    Continent: "AS",
    DS: "PK",
    "Developed / Developing Countries": "Developing",
    Dial: "92",
    EDGAR: "R0",
    FIFA: "PAK",
    FIPS: "PK",
    GAUL: "188",
    "Geoname ID": 1168579,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PAK",
    "ISO3166-1-Alpha-2": "PK",
    "ISO3166-1-Alpha-3": "PAK",
    "ISO3166-1-numeric": "586",
    "ISO4217-currency_alphabetic_code": "PKR",
    "ISO4217-currency_country_name": "PAKISTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pakistan Rupee",
    "ISO4217-currency_numeric_code": "586",
    ITU: "PAK",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ur-PK,en-PK,pa,sd,ps,brh",
    "Least Developed Countries (LDC)": null,
    M49: 586,
    MARC: "pk",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".pk",
    "UNTERM Arabic Formal": "جمهورية باكستان الإسلامية",
    "UNTERM Arabic Short": "باكستان",
    "UNTERM Chinese Formal": "巴基斯坦伊斯兰共和国",
    "UNTERM Chinese Short": "巴基斯坦",
    "UNTERM English Formal": "the Islamic Republic of Pakistan",
    "UNTERM English Short": "Pakistan",
    "UNTERM French Formal": "la République islamique du Pakistan",
    "UNTERM French Short": "Pakistan (le)",
    "UNTERM Russian Formal": "Исламская Республика Пакистан",
    "UNTERM Russian Short": "Пакистан",
    "UNTERM Spanish Formal": "la República Islámica del Pakistán",
    "UNTERM Spanish Short": "Pakistán (el)",
    WMO: "PK",
    is_independent: "Yes",
    official_name_ar: "باكستان",
    official_name_cn: "巴基斯坦",
    official_name_en: "Pakistan",
    official_name_es: "Pakistán",
    official_name_fr: "Pakistan",
    official_name_ru: "Пакистан",
  },
  {
    "CLDR display name": "Palau",
    Capital: "Melekeok",
    Continent: "OC",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "680",
    EDGAR: "1Y",
    FIFA: "PLW",
    FIPS: "PS",
    GAUL: "189",
    "Geoname ID": 1559582,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PLW",
    "ISO3166-1-Alpha-2": "PW",
    "ISO3166-1-Alpha-3": "PLW",
    "ISO3166-1-numeric": "585",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "PALAU",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "PLW",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pau,sov,en-PW,tox,ja,fil,zh",
    "Least Developed Countries (LDC)": null,
    M49: 585,
    MARC: "pw",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".pw",
    "UNTERM Arabic Formal": "جمهورية بالاو",
    "UNTERM Arabic Short": "بالاو",
    "UNTERM Chinese Formal": "帕劳共和国",
    "UNTERM Chinese Short": "帕劳",
    "UNTERM English Formal": "the Republic of Palau",
    "UNTERM English Short": "Palau",
    "UNTERM French Formal": "la République des Palaos",
    "UNTERM French Short": "Palaos (les)",
    "UNTERM Russian Formal": "Республика Палау",
    "UNTERM Russian Short": "Палау",
    "UNTERM Spanish Formal": "la República de Palau",
    "UNTERM Spanish Short": "Palau",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "بالاو",
    official_name_cn: "帕劳",
    official_name_en: "Palau",
    official_name_es: "Palau",
    official_name_fr: "Palaos",
    official_name_ru: "Палау",
  },
  {
    "CLDR display name": "Panama",
    Capital: "Panama City",
    Continent: "NA",
    DS: "PA",
    "Developed / Developing Countries": "Developing",
    Dial: "507",
    EDGAR: "R1",
    FIFA: "PAN",
    FIPS: "PM",
    GAUL: "191",
    "Geoname ID": 3703430,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PAN",
    "ISO3166-1-Alpha-2": "PA",
    "ISO3166-1-Alpha-3": "PAN",
    "ISO3166-1-numeric": "591",
    "ISO4217-currency_alphabetic_code": "PAB,USD",
    "ISO4217-currency_country_name": "PANAMA",
    "ISO4217-currency_minor_unit": "2,2",
    "ISO4217-currency_name": "Balboa,US Dollar",
    "ISO4217-currency_numeric_code": "590,840",
    ITU: "PNR",
    "Intermediate Region Code": "13",
    "Intermediate Region Name": "Central America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-PA,en",
    "Least Developed Countries (LDC)": null,
    M49: 591,
    MARC: "pn",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".pa",
    "UNTERM Arabic Formal": "جمهورية بنما",
    "UNTERM Arabic Short": "بنما",
    "UNTERM Chinese Formal": "巴拿马共和国",
    "UNTERM Chinese Short": "巴拿马",
    "UNTERM English Formal": "the Republic of Panama",
    "UNTERM English Short": "Panama",
    "UNTERM French Formal": "la République du Panama",
    "UNTERM French Short": "Panama (le)",
    "UNTERM Russian Formal": "Республика Панама",
    "UNTERM Russian Short": "Панама",
    "UNTERM Spanish Formal": "la República de Panamá",
    "UNTERM Spanish Short": "Panamá",
    WMO: "PM",
    is_independent: "Yes",
    official_name_ar: "بنما",
    official_name_cn: "巴拿马",
    official_name_en: "Panama",
    official_name_es: "Panamá",
    official_name_fr: "Panama",
    official_name_ru: "Панама",
  },
  {
    "CLDR display name": "Papua New Guinea",
    Capital: "Port Moresby",
    Continent: "OC",
    DS: "PNG",
    "Developed / Developing Countries": "Developing",
    Dial: "675",
    EDGAR: "R2",
    FIFA: "PNG",
    FIPS: "PP",
    GAUL: "192",
    "Geoname ID": 2088628,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PNG",
    "ISO3166-1-Alpha-2": "PG",
    "ISO3166-1-Alpha-3": "PNG",
    "ISO3166-1-numeric": "598",
    "ISO4217-currency_alphabetic_code": "PGK",
    "ISO4217-currency_country_name": "PAPUA NEW GUINEA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Kina",
    "ISO4217-currency_numeric_code": "598",
    ITU: "PNG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-PG,ho,meu,tpi",
    "Least Developed Countries (LDC)": null,
    M49: 598,
    MARC: "pp",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "54",
    "Sub-region Name": "Melanesia",
    TLD: ".pg",
    "UNTERM Arabic Formal": "دولة بابوا غينيا الجديدة المستقلة",
    "UNTERM Arabic Short": "بابوا غينيا الجديدة",
    "UNTERM Chinese Formal": "巴布亚新几内亚独立国",
    "UNTERM Chinese Short": "巴布亚新几内亚",
    "UNTERM English Formal": "Independent State of Papua New Guinea",
    "UNTERM English Short": "Papua New Guinea",
    "UNTERM French Formal": "l'État indépendant de Papouasie-Nouvelle-Guinée",
    "UNTERM French Short": "Papouasie-Nouvelle-Guinée (la)",
    "UNTERM Russian Formal": "Независимое государство Папуа-Новая Гвинея",
    "UNTERM Russian Short": "Папуа-Новая Гвинея",
    "UNTERM Spanish Formal": "Estado Independiente de Papua Nueva Guinea",
    "UNTERM Spanish Short": "Papua Nueva Guinea",
    WMO: "NG",
    is_independent: "Yes",
    official_name_ar: "بابوا غينيا الجديدة",
    official_name_cn: "巴布亚新几内亚",
    official_name_en: "Papua New Guinea",
    official_name_es: "Papua Nueva Guinea",
    official_name_fr: "Papouasie-Nouvelle-Guinée",
    official_name_ru: "Папуа-Новая Гвинея",
  },
  {
    "CLDR display name": "Paraguay",
    Capital: "Asuncion",
    Continent: "SA",
    DS: "PY",
    "Developed / Developing Countries": "Developing",
    Dial: "595",
    EDGAR: "R4",
    FIFA: "PAR",
    FIPS: "PA",
    GAUL: "194",
    "Geoname ID": 3437598,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PAR",
    "ISO3166-1-Alpha-2": "PY",
    "ISO3166-1-Alpha-3": "PRY",
    "ISO3166-1-numeric": "600",
    "ISO4217-currency_alphabetic_code": "PYG",
    "ISO4217-currency_country_name": "PARAGUAY",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Guarani",
    "ISO4217-currency_numeric_code": "600",
    ITU: "PRG",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "es-PY,gn",
    "Least Developed Countries (LDC)": null,
    M49: 600,
    MARC: "py",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".py",
    "UNTERM Arabic Formal": "جمهورية باراغواي",
    "UNTERM Arabic Short": "باراغواي",
    "UNTERM Chinese Formal": "巴拉圭共和国",
    "UNTERM Chinese Short": "巴拉圭",
    "UNTERM English Formal": "the Republic of Paraguay",
    "UNTERM English Short": "Paraguay",
    "UNTERM French Formal": "la République du Paraguay",
    "UNTERM French Short": "Paraguay (le)",
    "UNTERM Russian Formal": "Республика Парагвай",
    "UNTERM Russian Short": "Парагвай",
    "UNTERM Spanish Formal": "la República del Paraguay",
    "UNTERM Spanish Short": "Paraguay (el)",
    WMO: "PY",
    is_independent: "Yes",
    official_name_ar: "باراغواي",
    official_name_cn: "巴拉圭",
    official_name_en: "Paraguay",
    official_name_es: "Paraguay",
    official_name_fr: "Paraguay",
    official_name_ru: "Парагвай",
  },
  {
    "CLDR display name": "Peru",
    Capital: "Lima",
    Continent: "SA",
    DS: "PE",
    "Developed / Developing Countries": "Developing",
    Dial: "51",
    EDGAR: "R5",
    FIFA: "PER",
    FIPS: "PE",
    GAUL: "195",
    "Geoname ID": 3932488,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PER",
    "ISO3166-1-Alpha-2": "PE",
    "ISO3166-1-Alpha-3": "PER",
    "ISO3166-1-numeric": "604",
    "ISO4217-currency_alphabetic_code": "PEN",
    "ISO4217-currency_country_name": "PERU",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Sol",
    "ISO4217-currency_numeric_code": "604",
    ITU: "PRU",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-PE,qu,ay",
    "Least Developed Countries (LDC)": null,
    M49: 604,
    MARC: "pe",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".pe",
    "UNTERM Arabic Formal": "جمهورية بيرو",
    "UNTERM Arabic Short": "بيرو",
    "UNTERM Chinese Formal": "秘鲁共和国",
    "UNTERM Chinese Short": "秘鲁",
    "UNTERM English Formal": "the Republic of Peru",
    "UNTERM English Short": "Peru",
    "UNTERM French Formal": "la République du Pérou",
    "UNTERM French Short": "Pérou (le)",
    "UNTERM Russian Formal": "Республика Перу",
    "UNTERM Russian Short": "Перу",
    "UNTERM Spanish Formal": "la República del Perú",
    "UNTERM Spanish Short": "Perú (el)",
    WMO: "PR",
    is_independent: "Yes",
    official_name_ar: "بيرو",
    official_name_cn: "秘鲁",
    official_name_en: "Peru",
    official_name_es: "Perú",
    official_name_fr: "Pérou",
    official_name_ru: "Перу",
  },
  {
    "CLDR display name": "Philippines",
    Capital: "Manila",
    Continent: "AS",
    DS: "RP",
    "Developed / Developing Countries": "Developing",
    Dial: "63",
    EDGAR: "R6",
    FIFA: "PHI",
    FIPS: "RP",
    GAUL: "196",
    "Geoname ID": 1694008,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PHI",
    "ISO3166-1-Alpha-2": "PH",
    "ISO3166-1-Alpha-3": "PHL",
    "ISO3166-1-numeric": "608",
    "ISO4217-currency_alphabetic_code": "PHP",
    "ISO4217-currency_country_name": "PHILIPPINES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Philippine Peso",
    "ISO4217-currency_numeric_code": "608",
    ITU: "PHL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages:
      "tl,en-PH,fil,ceb,tgl,ilo,hil,war,pam,bik,bcl,pag,mrw,tsg,mdh,cbk,krj,sgd,msb,akl,ibg,yka,mta,abx",
    "Least Developed Countries (LDC)": null,
    M49: 608,
    MARC: "ph",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".ph",
    "UNTERM Arabic Formal": "جمهورية الفلبين",
    "UNTERM Arabic Short": "الفلبين",
    "UNTERM Chinese Formal": "菲律宾共和国",
    "UNTERM Chinese Short": "菲律宾",
    "UNTERM English Formal": "the Republic of the Philippines",
    "UNTERM English Short": "Philippines (the)",
    "UNTERM French Formal": "la République des Philippines",
    "UNTERM French Short": "Philippines (les) [fém.]",
    "UNTERM Russian Formal": "Республика Филиппины",
    "UNTERM Russian Short": "Филиппины",
    "UNTERM Spanish Formal": "la República de Filipinas",
    "UNTERM Spanish Short": "Filipinas",
    WMO: "PH",
    is_independent: "Yes",
    official_name_ar: "الفلبين",
    official_name_cn: "菲律宾",
    official_name_en: "Philippines",
    official_name_es: "Filipinas",
    official_name_fr: "Philippines",
    official_name_ru: "Филиппины",
  },
  {
    "CLDR display name": "Pitcairn Islands",
    Capital: "Adamstown",
    Continent: "OC",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "870",
    EDGAR: "R8",
    FIFA: "PCN",
    FIPS: "PC",
    GAUL: "197",
    "Geoname ID": 4030699,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "PN",
    "ISO3166-1-Alpha-3": "PCN",
    "ISO3166-1-numeric": "612",
    "ISO4217-currency_alphabetic_code": "NZD",
    "ISO4217-currency_country_name": "PITCAIRN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Zealand Dollar",
    "ISO4217-currency_numeric_code": "554",
    ITU: "PTC",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-PN",
    "Least Developed Countries (LDC)": null,
    M49: 612,
    MARC: "pc",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".pn",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "PT",
    is_independent: "Territory of GB",
    official_name_ar: "بيتكيرن",
    official_name_cn: "皮特凯恩",
    official_name_en: "Pitcairn",
    official_name_es: "Pitcairn",
    official_name_fr: "Pitcairn",
    official_name_ru: "Питкэрн",
  },
  {
    "CLDR display name": "Poland",
    Capital: "Warsaw",
    Continent: "EU",
    DS: "PL",
    "Developed / Developing Countries": "Developed",
    Dial: "48",
    EDGAR: "R9",
    FIFA: "POL",
    FIPS: "PL",
    GAUL: "198",
    "Geoname ID": 798544,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "POL",
    "ISO3166-1-Alpha-2": "PL",
    "ISO3166-1-Alpha-3": "POL",
    "ISO3166-1-numeric": "616",
    "ISO4217-currency_alphabetic_code": "PLN",
    "ISO4217-currency_country_name": "POLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Zloty",
    "ISO4217-currency_numeric_code": "985",
    ITU: "POL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pl",
    "Least Developed Countries (LDC)": null,
    M49: 616,
    MARC: "pl",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".pl",
    "UNTERM Arabic Formal": "جمهورية بولندا",
    "UNTERM Arabic Short": "بولندا",
    "UNTERM Chinese Formal": "波兰共和国",
    "UNTERM Chinese Short": "波兰",
    "UNTERM English Formal": "the Republic of Poland",
    "UNTERM English Short": "Poland",
    "UNTERM French Formal": "la République de Pologne",
    "UNTERM French Short": "Pologne (la)",
    "UNTERM Russian Formal": "Республика Польша",
    "UNTERM Russian Short": "Польша",
    "UNTERM Spanish Formal": "la República de Polonia",
    "UNTERM Spanish Short": "Polonia",
    WMO: "PL",
    is_independent: "Yes",
    official_name_ar: "بولندا",
    official_name_cn: "波兰",
    official_name_en: "Poland",
    official_name_es: "Polonia",
    official_name_fr: "Pologne",
    official_name_ru: "Польша",
  },
  {
    "CLDR display name": "Portugal",
    Capital: "Lisbon",
    Continent: "EU",
    DS: "P",
    "Developed / Developing Countries": "Developed",
    Dial: "351",
    EDGAR: "S1",
    FIFA: "POR",
    FIPS: "PO",
    GAUL: "199",
    "Geoname ID": 2264397,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "POR",
    "ISO3166-1-Alpha-2": "PT",
    "ISO3166-1-Alpha-3": "PRT",
    "ISO3166-1-numeric": "620",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "PORTUGAL",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "POR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-PT,mwl",
    "Least Developed Countries (LDC)": null,
    M49: 620,
    MARC: "po",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".pt",
    "UNTERM Arabic Formal": "جمهورية البرتغال",
    "UNTERM Arabic Short": "البرتغال",
    "UNTERM Chinese Formal": "葡萄牙共和国",
    "UNTERM Chinese Short": "葡萄牙",
    "UNTERM English Formal": "the Portuguese Republic",
    "UNTERM English Short": "Portugal",
    "UNTERM French Formal": "la République portugaise",
    "UNTERM French Short": "Portugal (le)",
    "UNTERM Russian Formal": "Португальская Республика",
    "UNTERM Russian Short": "Португалия",
    "UNTERM Spanish Formal": "la República Portuguesa",
    "UNTERM Spanish Short": "Portugal",
    WMO: "PO",
    is_independent: "Yes",
    official_name_ar: "البرتغال",
    official_name_cn: "葡萄牙",
    official_name_en: "Portugal",
    official_name_es: "Portugal",
    official_name_fr: "Portugal",
    official_name_ru: "Португалия",
  },
  {
    "CLDR display name": "Puerto Rico",
    Capital: "San Juan",
    Continent: "NA",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: "1",
    EDGAR: "PR",
    FIFA: "PUR",
    FIPS: "RQ",
    GAUL: "200",
    "Geoname ID": 4566966,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PUR",
    "ISO3166-1-Alpha-2": "PR",
    "ISO3166-1-Alpha-3": "PRI",
    "ISO3166-1-numeric": "630",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "PUERTO RICO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "PTR",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-PR,es-PR",
    "Least Developed Countries (LDC)": null,
    M49: 630,
    MARC: "pr",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".pr",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "PU",
    is_independent: "Commonwealth of US",
    official_name_ar: "بورتوريكو",
    official_name_cn: "波多黎各",
    official_name_en: "Puerto Rico",
    official_name_es: "Puerto Rico",
    official_name_fr: "Porto Rico",
    official_name_ru: "Пуэрто-Рико",
  },
  {
    "CLDR display name": "Qatar",
    Capital: "Doha",
    Continent: "AS",
    DS: "Q",
    "Developed / Developing Countries": "Developing",
    Dial: "974",
    EDGAR: "S3",
    FIFA: "QAT",
    FIPS: "QA",
    GAUL: "201",
    "Geoname ID": 289688,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "QAT",
    "ISO3166-1-Alpha-2": "QA",
    "ISO3166-1-Alpha-3": "QAT",
    "ISO3166-1-numeric": "634",
    "ISO4217-currency_alphabetic_code": "QAR",
    "ISO4217-currency_country_name": "QATAR",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Qatari Rial",
    "ISO4217-currency_numeric_code": "634",
    ITU: "QAT",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-QA,es",
    "Least Developed Countries (LDC)": null,
    M49: 634,
    MARC: "qa",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".qa",
    "UNTERM Arabic Formal": "دولة قطر",
    "UNTERM Arabic Short": "قطر",
    "UNTERM Chinese Formal": "卡塔尔国",
    "UNTERM Chinese Short": "卡塔尔",
    "UNTERM English Formal": "the State of Qatar",
    "UNTERM English Short": "Qatar",
    "UNTERM French Formal": "l'État du Qatar",
    "UNTERM French Short": "Qatar (le)",
    "UNTERM Russian Formal": "Государство Катар",
    "UNTERM Russian Short": "Катар",
    "UNTERM Spanish Formal": "el Estado de Qatar",
    "UNTERM Spanish Short": "Qatar",
    WMO: "QT",
    is_independent: "Yes",
    official_name_ar: "قطر",
    official_name_cn: "卡塔尔",
    official_name_en: "Qatar",
    official_name_es: "Qatar",
    official_name_fr: "Qatar",
    official_name_ru: "Катар",
  },
  {
    "CLDR display name": "South Korea",
    Capital: "Seoul",
    Continent: "AS",
    DS: "ROK",
    "Developed / Developing Countries": "Developing",
    Dial: "82",
    EDGAR: null,
    FIFA: "KOR",
    FIPS: "KS",
    GAUL: "202",
    "Geoname ID": 1835841,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KOR",
    "ISO3166-1-Alpha-2": "KR",
    "ISO3166-1-Alpha-3": "KOR",
    "ISO3166-1-numeric": "410",
    "ISO4217-currency_alphabetic_code": "KRW",
    "ISO4217-currency_country_name": "KOREA (THE REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Won",
    "ISO4217-currency_numeric_code": "410",
    ITU: "KOR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ko-KR,en",
    "Least Developed Countries (LDC)": null,
    M49: 410,
    MARC: "ko",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "30",
    "Sub-region Name": "Eastern Asia",
    TLD: ".kr",
    "UNTERM Arabic Formal": "جمهورية كوريا",
    "UNTERM Arabic Short": "جمهورية كوريا",
    "UNTERM Chinese Formal": "大韩民国",
    "UNTERM Chinese Short": "大韩民国",
    "UNTERM English Formal": "the Republic of Korea",
    "UNTERM English Short": "Republic of Korea (the)",
    "UNTERM French Formal": "la République de Corée",
    "UNTERM French Short": "République de Corée (la)",
    "UNTERM Russian Formal": "Республика Корея",
    "UNTERM Russian Short": "Республика Корея",
    "UNTERM Spanish Formal": "la República de Corea",
    "UNTERM Spanish Short": "República de Corea (la)",
    WMO: "KO",
    is_independent: "Yes",
    official_name_ar: "جمهورية كوريا",
    official_name_cn: "大韩民国",
    official_name_en: "Republic of Korea",
    official_name_es: "República de Corea",
    official_name_fr: "République de Corée",
    official_name_ru: "Республика Корея",
  },
  {
    "CLDR display name": "Moldova",
    Capital: "Chisinau",
    Continent: "EU",
    DS: "MD",
    "Developed / Developing Countries": "Developed",
    Dial: "373",
    EDGAR: null,
    FIFA: "MDA",
    FIPS: "MD",
    GAUL: "165",
    "Geoname ID": 617790,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MDA",
    "ISO3166-1-Alpha-2": "MD",
    "ISO3166-1-Alpha-3": "MDA",
    "ISO3166-1-numeric": "498",
    "ISO4217-currency_alphabetic_code": "MDL",
    "ISO4217-currency_country_name": "MOLDOVA (THE REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Moldovan Leu",
    "ISO4217-currency_numeric_code": "498",
    ITU: "MDA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "ro,ru,gag,tr",
    "Least Developed Countries (LDC)": null,
    M49: 498,
    MARC: "mv",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".md",
    "UNTERM Arabic Formal": "جمهورية مولدوفا",
    "UNTERM Arabic Short": "جمهورية مولدوفا",
    "UNTERM Chinese Formal": "摩尔多瓦共和国",
    "UNTERM Chinese Short": "摩尔多瓦共和国",
    "UNTERM English Formal": "the Republic of Moldova",
    "UNTERM English Short": "Republic of Moldova (the)",
    "UNTERM French Formal": "la République de Moldova",
    "UNTERM French Short": "République de Moldova (la)",
    "UNTERM Russian Formal": "Республика Молдова",
    "UNTERM Russian Short": "Республика Молдова",
    "UNTERM Spanish Formal": "la República de Moldova",
    "UNTERM Spanish Short": "la República de Moldova",
    WMO: "RM",
    is_independent: "Yes",
    official_name_ar: "جمهورية مولدوفا",
    official_name_cn: "摩尔多瓦共和国",
    official_name_en: "Republic of Moldova",
    official_name_es: "República de Moldova",
    official_name_fr: "République de Moldova",
    official_name_ru: "Республика Молдова",
  },
  {
    "CLDR display name": "Romania",
    Capital: "Bucharest",
    Continent: "EU",
    DS: "RO",
    "Developed / Developing Countries": "Developed",
    Dial: "40",
    EDGAR: "S5",
    FIFA: "ROU",
    FIPS: "RO",
    GAUL: "203",
    "Geoname ID": 798549,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ROU",
    "ISO3166-1-Alpha-2": "RO",
    "ISO3166-1-Alpha-3": "ROU",
    "ISO3166-1-numeric": "642",
    "ISO4217-currency_alphabetic_code": "RON",
    "ISO4217-currency_country_name": "ROMANIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Romanian Leu",
    "ISO4217-currency_numeric_code": "946",
    ITU: "ROU",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ro,hu,rom",
    "Least Developed Countries (LDC)": null,
    M49: 642,
    MARC: "rm",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".ro",
    "UNTERM Arabic Formal": "رومانيا",
    "UNTERM Arabic Short": "رومانيا",
    "UNTERM Chinese Formal": "罗马尼亚",
    "UNTERM Chinese Short": "罗马尼亚",
    "UNTERM English Formal": "Romania",
    "UNTERM English Short": "Romania",
    "UNTERM French Formal": "la Roumanie",
    "UNTERM French Short": "Roumanie (la)",
    "UNTERM Russian Formal": "Румыния",
    "UNTERM Russian Short": "Румыния",
    "UNTERM Spanish Formal": "Rumania",
    "UNTERM Spanish Short": "Rumania",
    WMO: "RO",
    is_independent: "Yes",
    official_name_ar: "رومانيا",
    official_name_cn: "罗马尼亚",
    official_name_en: "Romania",
    official_name_es: "Rumania",
    official_name_fr: "Roumanie",
    official_name_ru: "Румыния",
  },
  {
    "CLDR display name": "Russia",
    Capital: "Moscow",
    Continent: "EU",
    DS: "RUS",
    "Developed / Developing Countries": "Developed",
    Dial: "7",
    EDGAR: "1Z",
    FIFA: "RUS",
    FIPS: "RS",
    GAUL: "204",
    "Geoname ID": 2017370,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "RUS",
    "ISO3166-1-Alpha-2": "RU",
    "ISO3166-1-Alpha-3": "RUS",
    "ISO3166-1-numeric": "643",
    "ISO4217-currency_alphabetic_code": "RUB",
    "ISO4217-currency_country_name": "RUSSIAN FEDERATION",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Russian Ruble",
    "ISO4217-currency_numeric_code": "643",
    ITU: "RUS",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages:
      "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,av,sah,nog",
    "Least Developed Countries (LDC)": null,
    M49: 643,
    MARC: "ru",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".ru",
    "UNTERM Arabic Formal": "الاتحاد الروسي",
    "UNTERM Arabic Short": "الاتحاد الروسي",
    "UNTERM Chinese Formal": "俄罗斯联邦",
    "UNTERM Chinese Short": "俄罗斯联邦",
    "UNTERM English Formal": "the Russian Federation",
    "UNTERM English Short": "Russian Federation (the)",
    "UNTERM French Formal": "la Fédération de Russie",
    "UNTERM French Short": "Fédération de Russie (la)",
    "UNTERM Russian Formal": "Российская Федерация",
    "UNTERM Russian Short": "Российская Федерация",
    "UNTERM Spanish Formal": "la Federación de Rusia",
    "UNTERM Spanish Short": "Federación de Rusia (la)",
    WMO: "RS",
    is_independent: "Yes",
    official_name_ar: "الاتحاد الروسي",
    official_name_cn: "俄罗斯联邦",
    official_name_en: "Russian Federation",
    official_name_es: "Federación de Rusia",
    official_name_fr: "Fédération de Russie",
    official_name_ru: "Российская Федерация",
  },
  {
    "CLDR display name": "Rwanda",
    Capital: "Kigali",
    Continent: "AF",
    DS: "RWA",
    "Developed / Developing Countries": "Developing",
    Dial: "250",
    EDGAR: "S6",
    FIFA: "RWA",
    FIPS: "RW",
    GAUL: "205",
    "Geoname ID": 49518,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "RWA",
    "ISO3166-1-Alpha-2": "RW",
    "ISO3166-1-Alpha-3": "RWA",
    "ISO3166-1-numeric": "646",
    "ISO4217-currency_alphabetic_code": "RWF",
    "ISO4217-currency_country_name": "RWANDA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Rwanda Franc",
    "ISO4217-currency_numeric_code": "646",
    ITU: "RRW",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "rw,en-RW,fr-RW,sw",
    "Least Developed Countries (LDC)": "x",
    M49: 646,
    MARC: "rw",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".rw",
    "UNTERM Arabic Formal": "جمهورية رواندا",
    "UNTERM Arabic Short": "رواندا",
    "UNTERM Chinese Formal": "卢旺达共和国",
    "UNTERM Chinese Short": "卢旺达",
    "UNTERM English Formal": "the Republic of Rwanda",
    "UNTERM English Short": "Rwanda",
    "UNTERM French Formal": "la République du Rwanda",
    "UNTERM French Short": "Rwanda (le)",
    "UNTERM Russian Formal": "Республика Руанда",
    "UNTERM Russian Short": "Руанда",
    "UNTERM Spanish Formal": "la República de Rwanda",
    "UNTERM Spanish Short": "Rwanda",
    WMO: "RW",
    is_independent: "Yes",
    official_name_ar: "رواندا",
    official_name_cn: "卢旺达",
    official_name_en: "Rwanda",
    official_name_es: "Rwanda",
    official_name_fr: "Rwanda",
    official_name_ru: "Руанда",
  },
  {
    "CLDR display name": "Réunion",
    Capital: "Saint-Denis",
    Continent: "AF",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "262",
    EDGAR: null,
    FIFA: "REU",
    FIPS: "RE",
    GAUL: "206",
    "Geoname ID": 935317,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "REU",
    "ISO3166-1-Alpha-2": "RE",
    "ISO3166-1-Alpha-3": "REU",
    "ISO3166-1-numeric": "638",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "RÉUNION",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "REU",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-RE",
    "Least Developed Countries (LDC)": null,
    M49: 638,
    MARC: "re",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".re",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "RE",
    is_independent: "Part of FR",
    official_name_ar: "ريونيون",
    official_name_cn: "留尼汪",
    official_name_en: "Réunion",
    official_name_es: "Reunión",
    official_name_fr: "Réunion",
    official_name_ru: "Реюньон",
  },
  {
    "CLDR display name": "St. Barthélemy",
    Capital: "Gustavia",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "590",
    EDGAR: null,
    FIFA: null,
    FIPS: "TB",
    GAUL: null,
    "Geoname ID": 3578476,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "BL",
    "ISO3166-1-Alpha-3": "BLM",
    "ISO3166-1-numeric": "652",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SAINT BARTHÉLEMY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: null,
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr",
    "Least Developed Countries (LDC)": null,
    M49: 652,
    MARC: "sc",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gp",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of FR",
    official_name_ar: "سان بارتليمي",
    official_name_cn: "圣巴泰勒米",
    official_name_en: "Saint Barthélemy",
    official_name_es: "San Bartolomé",
    official_name_fr: "Saint-Barthélemy",
    official_name_ru: "Святой Варфоломей",
  },
  {
    "CLDR display name": "St. Helena",
    Capital: "Jamestown",
    Continent: "AF",
    DS: "SH",
    "Developed / Developing Countries": "Developing",
    Dial: "290",
    EDGAR: null,
    FIFA: "SHN",
    FIPS: "SH",
    GAUL: "207",
    "Geoname ID": 3370751,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "HEL",
    "ISO3166-1-Alpha-2": "SH",
    "ISO3166-1-Alpha-3": "SHN",
    "ISO3166-1-numeric": "654",
    "ISO4217-currency_alphabetic_code": "SHP",
    "ISO4217-currency_country_name":
      "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Saint Helena Pound",
    "ISO4217-currency_numeric_code": "654",
    ITU: "SHN",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-SH",
    "Least Developed Countries (LDC)": null,
    M49: 654,
    MARC: "xj",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".sh",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "HE",
    is_independent: "Territory of GB",
    official_name_ar: "سانت هيلانة",
    official_name_cn: "圣赫勒拿",
    official_name_en: "Saint Helena",
    official_name_es: "Santa Elena",
    official_name_fr: "Sainte-Hélène",
    official_name_ru: "Остров Святой Елены",
  },
  {
    "CLDR display name": "St. Kitts & Nevis",
    Capital: "Basseterre",
    Continent: "NA",
    DS: "KN",
    "Developed / Developing Countries": "Developing",
    Dial: "1-869",
    EDGAR: "U7",
    FIFA: "SKN",
    FIPS: "SC",
    GAUL: "208",
    "Geoname ID": 3575174,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SKN",
    "ISO3166-1-Alpha-2": "KN",
    "ISO3166-1-Alpha-3": "KNA",
    "ISO3166-1-numeric": "659",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "SAINT KITTS AND NEVIS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "KNA",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-KN",
    "Least Developed Countries (LDC)": null,
    M49: 659,
    MARC: "xd",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".kn",
    "UNTERM Arabic Formal": "سانت كيتس ونيفس",
    "UNTERM Arabic Short": "سانت كيتس ونيفس",
    "UNTERM Chinese Formal": "圣基茨和尼维斯",
    "UNTERM Chinese Short": "圣基茨和尼维斯",
    "UNTERM English Formal": "Saint Kitts and Nevis",
    "UNTERM English Short": "Saint Kitts and Nevis",
    "UNTERM French Formal": "Saint-Kitts-et-Nevis [masc.]",
    "UNTERM French Short": "Saint-Kitts-et-Nevis [masc.]",
    "UNTERM Russian Formal": "Сент-Китс и Невис",
    "UNTERM Russian Short": "Сент-Китс и Невис",
    "UNTERM Spanish Formal": "Saint Kitts y Nevis",
    "UNTERM Spanish Short": "Saint Kitts y Nevis",
    WMO: "AT",
    is_independent: "Yes",
    official_name_ar: "سانت كيتس ونيفس",
    official_name_cn: "圣基茨和尼维斯",
    official_name_en: "Saint Kitts and Nevis",
    official_name_es: "Saint Kitts y Nevis",
    official_name_fr: "Saint-Kitts-et-Nevis",
    official_name_ru: "Сент-Китс и Невис",
  },
  {
    "CLDR display name": "St. Lucia",
    Capital: "Castries",
    Continent: "NA",
    DS: "WL",
    "Developed / Developing Countries": "Developing",
    Dial: "1-758",
    EDGAR: "U9",
    FIFA: "LCA",
    FIPS: "ST",
    GAUL: "209",
    "Geoname ID": 3576468,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "LCA",
    "ISO3166-1-Alpha-2": "LC",
    "ISO3166-1-Alpha-3": "LCA",
    "ISO3166-1-numeric": "662",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "SAINT LUCIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "LCA",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-LC",
    "Least Developed Countries (LDC)": null,
    M49: 662,
    MARC: "xk",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".lc",
    "UNTERM Arabic Formal": "سانت لوسيا",
    "UNTERM Arabic Short": "سانت لوسيا",
    "UNTERM Chinese Formal": "圣卢西亚",
    "UNTERM Chinese Short": "圣卢西亚",
    "UNTERM English Formal": "Saint Lucia",
    "UNTERM English Short": "Saint Lucia",
    "UNTERM French Formal": "Sainte-Lucie [fém.]",
    "UNTERM French Short": "Sainte-Lucie [fém.]",
    "UNTERM Russian Formal": "Сент-Люсия",
    "UNTERM Russian Short": "Сент-Люсия",
    "UNTERM Spanish Formal": "Santa Lucía",
    "UNTERM Spanish Short": "Santa Lucía",
    WMO: "LC",
    is_independent: "Yes",
    official_name_ar: "سانت لوسيا",
    official_name_cn: "圣卢西亚",
    official_name_en: "Saint Lucia",
    official_name_es: "Santa Lucía",
    official_name_fr: "Sainte-Lucie",
    official_name_ru: "Сент-Люсия",
  },
  {
    "CLDR display name": "St. Martin",
    Capital: "Marigot",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "590",
    EDGAR: null,
    FIFA: null,
    FIPS: "RN",
    GAUL: null,
    "Geoname ID": 3578421,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "MF",
    "ISO3166-1-Alpha-3": "MAF",
    "ISO3166-1-numeric": "663",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SAINT MARTIN (FRENCH PART)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: null,
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr",
    "Least Developed Countries (LDC)": null,
    M49: 663,
    MARC: "st",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gp",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of FR",
    official_name_ar: "سان مارتن",
    official_name_cn: "圣马丁(法属)",
    official_name_en: "Saint Martin (French Part)",
    official_name_es: "San Martín (parte francesa)",
    official_name_fr: "Saint-Martin (partie française)",
    official_name_ru: "Сен-Мартен",
  },
  {
    "CLDR display name": "St. Pierre & Miquelon",
    Capital: "Saint-Pierre",
    Continent: "NA",
    DS: "F",
    "Developed / Developing Countries": "Developed",
    Dial: "508",
    EDGAR: "V0",
    FIFA: "SPM",
    FIPS: "SB",
    GAUL: "210",
    "Geoname ID": 3424932,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SPM",
    "ISO3166-1-Alpha-2": "PM",
    "ISO3166-1-Alpha-3": "SPM",
    "ISO3166-1-numeric": "666",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SAINT PIERRE AND MIQUELON",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "SPM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-PM",
    "Least Developed Countries (LDC)": null,
    M49: 666,
    MARC: "xl",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "21",
    "Sub-region Name": "Northern America",
    TLD: ".pm",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "FP",
    is_independent: "Part of FR",
    official_name_ar: "سان بيار وميكلون",
    official_name_cn: "圣皮埃尔和密克隆",
    official_name_en: "Saint Pierre and Miquelon",
    official_name_es: "Saint Pierre y Miquelon",
    official_name_fr: "Saint-Pierre-et-Miquelon",
    official_name_ru: "Сен-Пьер и Микелон",
  },
  {
    "CLDR display name": "St. Vincent & Grenadines",
    Capital: "Kingstown",
    Continent: "NA",
    DS: "WV",
    "Developed / Developing Countries": "Developing",
    Dial: "1-784",
    EDGAR: "V1",
    FIFA: "VIN",
    FIPS: "VC",
    GAUL: "211",
    "Geoname ID": 3577815,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "VIN",
    "ISO3166-1-Alpha-2": "VC",
    "ISO3166-1-Alpha-3": "VCT",
    "ISO3166-1-numeric": "670",
    "ISO4217-currency_alphabetic_code": "XCD",
    "ISO4217-currency_country_name": "SAINT VINCENT AND THE GRENADINES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "East Caribbean Dollar",
    "ISO4217-currency_numeric_code": "951",
    ITU: "VCT",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-VC,fr",
    "Least Developed Countries (LDC)": null,
    M49: 670,
    MARC: "xm",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".vc",
    "UNTERM Arabic Formal": "سانت فنسنت وجزر غرينادين",
    "UNTERM Arabic Short": "سانت فنسنت وجزر غرينادين",
    "UNTERM Chinese Formal": "圣文森特和格林纳丁斯",
    "UNTERM Chinese Short": "圣文森特和格林纳丁斯",
    "UNTERM English Formal": "Saint Vincent and the Grenadines",
    "UNTERM English Short": "Saint Vincent and the Grenadines",
    "UNTERM French Formal": "Saint-Vincent-et-les Grenadines [fém.]",
    "UNTERM French Short": "Saint-Vincent-et-les Grenadines [fém.]",
    "UNTERM Russian Formal": "Сент-Винсент и Гренадины",
    "UNTERM Russian Short": "Сент-Винсент и Гренадины",
    "UNTERM Spanish Formal": "San Vicente y las Granadinas",
    "UNTERM Spanish Short": "San Vicente y las Granadinas",
    WMO: "VG",
    is_independent: "Yes",
    official_name_ar: "سانت فنسنت وجزر غرينادين",
    official_name_cn: "圣文森特和格林纳丁斯",
    official_name_en: "Saint Vincent and the Grenadines",
    official_name_es: "San Vicente y las Granadinas",
    official_name_fr: "Saint-Vincent-et-les Grenadines",
    official_name_ru: "Сент-Винсент и Гренадины",
  },
  {
    "CLDR display name": "Samoa",
    Capital: "Apia",
    Continent: "OC",
    DS: "WS",
    "Developed / Developing Countries": "Developing",
    Dial: "685",
    EDGAR: "Y0",
    FIFA: "SAM",
    FIPS: "WS",
    GAUL: "212",
    "Geoname ID": 4034894,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SAM",
    "ISO3166-1-Alpha-2": "WS",
    "ISO3166-1-Alpha-3": "WSM",
    "ISO3166-1-numeric": "882",
    "ISO4217-currency_alphabetic_code": "WST",
    "ISO4217-currency_country_name": "SAMOA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Tala",
    "ISO4217-currency_numeric_code": "882",
    ITU: "SMO",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sm,en-WS",
    "Least Developed Countries (LDC)": null,
    M49: 882,
    MARC: "ws",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".ws",
    "UNTERM Arabic Formal": "دولة ساموا المستقلة",
    "UNTERM Arabic Short": "ساموا",
    "UNTERM Chinese Formal": "萨摩亚独立国",
    "UNTERM Chinese Short": "萨摩亚",
    "UNTERM English Formal": "the Independent State of Samoa",
    "UNTERM English Short": "Samoa",
    "UNTERM French Formal": "l'État indépendant du Samoa",
    "UNTERM French Short": "Samoa (le)",
    "UNTERM Russian Formal": "Независимое Государство Самоа",
    "UNTERM Russian Short": "Самоа",
    "UNTERM Spanish Formal": "el Estado Independiente de Samoa",
    "UNTERM Spanish Short": "Samoa",
    WMO: "ZM",
    is_independent: "Yes",
    official_name_ar: "ساموا",
    official_name_cn: "萨摩亚",
    official_name_en: "Samoa",
    official_name_es: "Samoa",
    official_name_fr: "Samoa",
    official_name_ru: "Самоа",
  },
  {
    "CLDR display name": "San Marino",
    Capital: "San Marino",
    Continent: "EU",
    DS: "RSM",
    "Developed / Developing Countries": "Developed",
    Dial: "378",
    EDGAR: "S8",
    FIFA: "SMR",
    FIPS: "SM",
    GAUL: "213",
    "Geoname ID": 3168068,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SMR",
    "ISO3166-1-Alpha-2": "SM",
    "ISO3166-1-Alpha-3": "SMR",
    "ISO3166-1-numeric": "674",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SAN MARINO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "SMR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "it-SM",
    "Least Developed Countries (LDC)": null,
    M49: 674,
    MARC: "sm",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".sm",
    "UNTERM Arabic Formal": "جمهورية سان مارينو",
    "UNTERM Arabic Short": "سان مارينو",
    "UNTERM Chinese Formal": "圣马力诺共和国",
    "UNTERM Chinese Short": "圣马力诺",
    "UNTERM English Formal": "the Republic of San Marino",
    "UNTERM English Short": "San Marino",
    "UNTERM French Formal": "la République de Saint-Marin",
    "UNTERM French Short": "Saint-Marin [masc.]",
    "UNTERM Russian Formal": "Республика Сан-Марино",
    "UNTERM Russian Short": "Сан-Марино",
    "UNTERM Spanish Formal": "la República de San Marino",
    "UNTERM Spanish Short": "San Marino",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "سان مارينو",
    official_name_cn: "圣马力诺",
    official_name_en: "San Marino",
    official_name_es: "San Marino",
    official_name_fr: "Saint-Marin",
    official_name_ru: "Сан-Марино",
  },
  {
    "CLDR display name": "São Tomé & Príncipe",
    Capital: "Sao Tome",
    Continent: "AF",
    DS: "ST",
    "Developed / Developing Countries": "Developing",
    Dial: "239",
    EDGAR: "S9",
    FIFA: "STP",
    FIPS: "TP",
    GAUL: "214",
    "Geoname ID": 2410758,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "STP",
    "ISO3166-1-Alpha-2": "ST",
    "ISO3166-1-Alpha-3": "STP",
    "ISO3166-1-numeric": "678",
    "ISO4217-currency_alphabetic_code": "STN",
    "ISO4217-currency_country_name": "SAO TOME AND PRINCIPE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Dobra",
    "ISO4217-currency_numeric_code": "930",
    ITU: "STP",
    "Intermediate Region Code": "17",
    "Intermediate Region Name": "Middle Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "pt-ST",
    "Least Developed Countries (LDC)": "x",
    M49: 678,
    MARC: "sf",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".st",
    "UNTERM Arabic Formal": "جمهورية سان تومي وبرينسيبي الديمقراطية",
    "UNTERM Arabic Short": "سان تومي وبرينسيبي",
    "UNTERM Chinese Formal": "圣多美和普林西比民主共和国",
    "UNTERM Chinese Short": "圣多美和普林西比",
    "UNTERM English Formal": "the Democratic Republic of Sao Tome and Principe",
    "UNTERM English Short": "Sao Tome and Principe",
    "UNTERM French Formal":
      "la République démocratique de Sao Tomé-et-Principe",
    "UNTERM French Short": "Sao Tomé-et-Principe [fém.]",
    "UNTERM Russian Formal": "Демократическая Республика Сан-Томе и Принсипи",
    "UNTERM Russian Short": "Сан-Томе и Принсипи",
    "UNTERM Spanish Formal":
      "la República Democrática de Santo Tomé y Príncipe",
    "UNTERM Spanish Short": "Santo Tomé y Príncipe",
    WMO: "TP",
    is_independent: "Yes",
    official_name_ar: "سان تومي وبرينسيبي",
    official_name_cn: "圣多美和普林西比",
    official_name_en: "Sao Tome and Principe",
    official_name_es: "Santo Tomé y Príncipe",
    official_name_fr: "Sao Tomé-et-Principe",
    official_name_ru: "Сан-Томе и Принсипи",
  },
  {
    "CLDR display name": null,
    Capital: null,
    Continent: null,
    DS: null,
    "Developed / Developing Countries": "Developed",
    Dial: null,
    EDGAR: null,
    FIFA: null,
    FIPS: null,
    GAUL: null,
    "Geoname ID": null,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": null,
    "ISO3166-1-Alpha-3": null,
    "ISO3166-1-numeric": null,
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name": null,
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": null,
    "ISO4217-currency_numeric_code": null,
    ITU: null,
    "Intermediate Region Code": "830",
    "Intermediate Region Name": "Channel Islands",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: null,
    "Least Developed Countries (LDC)": null,
    M49: 680,
    MARC: null,
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: null,
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: null,
    official_name_ar: "سارك",
    official_name_cn: "萨克",
    official_name_en: "Sark",
    official_name_es: "Sark",
    official_name_fr: "Sercq",
    official_name_ru: "Сарк",
  },
  {
    "CLDR display name": "Saudi Arabia",
    Capital: "Riyadh",
    Continent: "AS",
    DS: "SA",
    "Developed / Developing Countries": "Developing",
    Dial: "966",
    EDGAR: "T0",
    FIFA: "KSA",
    FIPS: "SA",
    GAUL: "215",
    "Geoname ID": 102358,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "KSA",
    "ISO3166-1-Alpha-2": "SA",
    "ISO3166-1-Alpha-3": "SAU",
    "ISO3166-1-numeric": "682",
    "ISO4217-currency_alphabetic_code": "SAR",
    "ISO4217-currency_country_name": "SAUDI ARABIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Saudi Riyal",
    "ISO4217-currency_numeric_code": "682",
    ITU: "ARS",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-SA",
    "Least Developed Countries (LDC)": null,
    M49: 682,
    MARC: "su",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".sa",
    "UNTERM Arabic Formal": "المملكة العربية السعودية",
    "UNTERM Arabic Short": "المملكة العربية السعودية",
    "UNTERM Chinese Formal": "沙特阿拉伯王国",
    "UNTERM Chinese Short": "沙特阿拉伯",
    "UNTERM English Formal": "the Kingdom of Saudi Arabia",
    "UNTERM English Short": "Saudi Arabia",
    "UNTERM French Formal": "le Royaume d'Arabie saoudite",
    "UNTERM French Short": "Arabie saoudite (l') [fém.]",
    "UNTERM Russian Formal": "Королевство Саудовская Аравия",
    "UNTERM Russian Short": "Саудовская Аравия",
    "UNTERM Spanish Formal": "el Reino de la Arabia Saudita",
    "UNTERM Spanish Short": "Arabia Saudita (la)",
    WMO: "SD",
    is_independent: "Yes",
    official_name_ar: "المملكة العربية السعودية",
    official_name_cn: "沙特阿拉伯",
    official_name_en: "Saudi Arabia",
    official_name_es: "Arabia Saudita",
    official_name_fr: "Arabie saoudite",
    official_name_ru: "Саудовская Аравия",
  },
  {
    "CLDR display name": "Senegal",
    Capital: "Dakar",
    Continent: "AF",
    DS: "SN",
    "Developed / Developing Countries": "Developing",
    Dial: "221",
    EDGAR: "T1",
    FIFA: "SEN",
    FIPS: "SG",
    GAUL: "217",
    "Geoname ID": 2245662,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SEN",
    "ISO3166-1-Alpha-2": "SN",
    "ISO3166-1-Alpha-3": "SEN",
    "ISO3166-1-numeric": "686",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "SENEGAL",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "SEN",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-SN,wo,fuc,mnk",
    "Least Developed Countries (LDC)": "x",
    M49: 686,
    MARC: "sg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".sn",
    "UNTERM Arabic Formal": "جمهورية السنغال",
    "UNTERM Arabic Short": "السنغال",
    "UNTERM Chinese Formal": "塞内加尔共和国",
    "UNTERM Chinese Short": "塞内加尔",
    "UNTERM English Formal": "the Republic of Senegal",
    "UNTERM English Short": "Senegal",
    "UNTERM French Formal": "la République du Sénégal",
    "UNTERM French Short": "Sénégal (le)",
    "UNTERM Russian Formal": "Республика Сенегал",
    "UNTERM Russian Short": "Сенегал",
    "UNTERM Spanish Formal": "la República del Senegal",
    "UNTERM Spanish Short": "Senegal (el)",
    WMO: "SG",
    is_independent: "Yes",
    official_name_ar: "السنغال",
    official_name_cn: "塞内加尔",
    official_name_en: "Senegal",
    official_name_es: "Senegal",
    official_name_fr: "Sénégal",
    official_name_ru: "Сенегал",
  },
  {
    "CLDR display name": "Serbia",
    Capital: "Belgrade",
    Continent: "EU",
    DS: "SRB",
    "Developed / Developing Countries": "Developed",
    Dial: "381",
    EDGAR: "Z2",
    FIFA: "SRB",
    FIPS: "RI,KV",
    GAUL: "2648",
    "Geoname ID": 6290252,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SRB",
    "ISO3166-1-Alpha-2": "RS",
    "ISO3166-1-Alpha-3": "SRB",
    "ISO3166-1-numeric": "688",
    "ISO4217-currency_alphabetic_code": "RSD",
    "ISO4217-currency_country_name": "SERBIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Serbian Dinar",
    "ISO4217-currency_numeric_code": "941",
    ITU: "SRB",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sr,hu,bs,rom",
    "Least Developed Countries (LDC)": null,
    M49: 688,
    MARC: "rb",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".rs",
    "UNTERM Arabic Formal": "جمهورية صربيا",
    "UNTERM Arabic Short": "صربيا",
    "UNTERM Chinese Formal": "塞尔维亚共和国",
    "UNTERM Chinese Short": "塞尔维亚",
    "UNTERM English Formal": "the Republic of Serbia",
    "UNTERM English Short": "Serbia",
    "UNTERM French Formal": "la République de Serbie",
    "UNTERM French Short": "Serbie (la)",
    "UNTERM Russian Formal": "Республика Сербия",
    "UNTERM Russian Short": "Сербия",
    "UNTERM Spanish Formal": "la República de Serbia",
    "UNTERM Spanish Short": "Serbia",
    WMO: "YG",
    is_independent: "Yes",
    official_name_ar: "صربيا",
    official_name_cn: "塞尔维亚",
    official_name_en: "Serbia",
    official_name_es: "Serbia",
    official_name_fr: "Serbie",
    official_name_ru: "Сербия",
  },
  {
    "CLDR display name": "Seychelles",
    Capital: "Victoria",
    Continent: "AF",
    DS: "SY",
    "Developed / Developing Countries": "Developing",
    Dial: "248",
    EDGAR: "T2",
    FIFA: "SEY",
    FIPS: "SE",
    GAUL: "220",
    "Geoname ID": 241170,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SEY",
    "ISO3166-1-Alpha-2": "SC",
    "ISO3166-1-Alpha-3": "SYC",
    "ISO3166-1-numeric": "690",
    "ISO4217-currency_alphabetic_code": "SCR",
    "ISO4217-currency_country_name": "SEYCHELLES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Seychelles Rupee",
    "ISO4217-currency_numeric_code": "690",
    ITU: "SEY",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-SC,fr-SC",
    "Least Developed Countries (LDC)": null,
    M49: 690,
    MARC: "se",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".sc",
    "UNTERM Arabic Formal": "جمهورية سيشيل",
    "UNTERM Arabic Short": "سيشيل",
    "UNTERM Chinese Formal": "塞舌尔共和国",
    "UNTERM Chinese Short": "塞舌尔",
    "UNTERM English Formal": "the Republic of Seychelles",
    "UNTERM English Short": "Seychelles",
    "UNTERM French Formal": "la République des Seychelles",
    "UNTERM French Short": "Seychelles (les) [fém.]",
    "UNTERM Russian Formal": "Республика Сейшельские Острова",
    "UNTERM Russian Short": "Сейшельские Острова",
    "UNTERM Spanish Formal": "la República de Seychelles",
    "UNTERM Spanish Short": "Seychelles",
    WMO: "SC",
    is_independent: "Yes",
    official_name_ar: "سيشيل",
    official_name_cn: "塞舌尔",
    official_name_en: "Seychelles",
    official_name_es: "Seychelles",
    official_name_fr: "Seychelles",
    official_name_ru: "Сейшельские Острова",
  },
  {
    "CLDR display name": "Sierra Leone",
    Capital: "Freetown",
    Continent: "AF",
    DS: "WAL",
    "Developed / Developing Countries": "Developing",
    Dial: "232",
    EDGAR: "T8",
    FIFA: "SLE",
    FIPS: "SL",
    GAUL: "221",
    "Geoname ID": 2403846,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SLE",
    "ISO3166-1-Alpha-2": "SL",
    "ISO3166-1-Alpha-3": "SLE",
    "ISO3166-1-numeric": "694",
    "ISO4217-currency_alphabetic_code": "SLL",
    "ISO4217-currency_country_name": "SIERRA LEONE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Leone",
    "ISO4217-currency_numeric_code": "694",
    ITU: "SRL",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-SL,men,tem",
    "Least Developed Countries (LDC)": "x",
    M49: 694,
    MARC: "sl",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".sl",
    "UNTERM Arabic Formal": "جمهورية سيراليون",
    "UNTERM Arabic Short": "سيراليون",
    "UNTERM Chinese Formal": "塞拉利昂共和国",
    "UNTERM Chinese Short": "塞拉利昂",
    "UNTERM English Formal": "the Republic of Sierra Leone",
    "UNTERM English Short": "Sierra Leone",
    "UNTERM French Formal": "la République de Sierra Leone",
    "UNTERM French Short": "Sierra Leone (la)",
    "UNTERM Russian Formal": "Республика Сьерра-Леоне",
    "UNTERM Russian Short": "Сьерра-Леоне",
    "UNTERM Spanish Formal": "la República de Sierra Leona",
    "UNTERM Spanish Short": "Sierra Leona",
    WMO: "SL",
    is_independent: "Yes",
    official_name_ar: "سيراليون",
    official_name_cn: "塞拉利昂",
    official_name_en: "Sierra Leone",
    official_name_es: "Sierra Leona",
    official_name_fr: "Sierra Leone",
    official_name_ru: "Сьерра-Леоне",
  },
  {
    "CLDR display name": "Singapore",
    Capital: "Singapore",
    Continent: "AS",
    DS: "SGP",
    "Developed / Developing Countries": "Developing",
    Dial: "65",
    EDGAR: "U0",
    FIFA: "SIN",
    FIPS: "SN",
    GAUL: "222",
    "Geoname ID": 1880251,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SIN",
    "ISO3166-1-Alpha-2": "SG",
    "ISO3166-1-Alpha-3": "SGP",
    "ISO3166-1-numeric": "702",
    "ISO4217-currency_alphabetic_code": "SGD",
    "ISO4217-currency_country_name": "SINGAPORE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Singapore Dollar",
    "ISO4217-currency_numeric_code": "702",
    ITU: "SNG",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "cmn,en-SG,ms-SG,ta-SG,zh-SG",
    "Least Developed Countries (LDC)": null,
    M49: 702,
    MARC: "si",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".sg",
    "UNTERM Arabic Formal": "جمهورية سنغافورة",
    "UNTERM Arabic Short": "سنغافورة",
    "UNTERM Chinese Formal": "新加坡共和国",
    "UNTERM Chinese Short": "新加坡",
    "UNTERM English Formal": "the Republic of Singapore",
    "UNTERM English Short": "Singapore",
    "UNTERM French Formal": "la République de Singapour",
    "UNTERM French Short": "Singapour [fém.]",
    "UNTERM Russian Formal": "Республика Сингапур",
    "UNTERM Russian Short": "Сингапур",
    "UNTERM Spanish Formal": "la República de Singapur",
    "UNTERM Spanish Short": "Singapur",
    WMO: "SR",
    is_independent: "Yes",
    official_name_ar: "سنغافورة",
    official_name_cn: "新加坡",
    official_name_en: "Singapore",
    official_name_es: "Singapur",
    official_name_fr: "Singapour",
    official_name_ru: "Сингапур",
  },
  {
    "CLDR display name": "Sint Maarten",
    Capital: "Philipsburg",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "1-721",
    EDGAR: null,
    FIFA: null,
    FIPS: "NN",
    GAUL: null,
    "Geoname ID": 7609695,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "SX",
    "ISO3166-1-Alpha-3": "SXM",
    "ISO3166-1-numeric": "534",
    "ISO4217-currency_alphabetic_code": "ANG",
    "ISO4217-currency_country_name": "SINT MAARTEN (DUTCH PART)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Netherlands Antillean Guilder",
    "ISO4217-currency_numeric_code": "532",
    ITU: null,
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl,en",
    "Least Developed Countries (LDC)": null,
    M49: 534,
    MARC: "sn",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".sx",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of NL",
    official_name_ar: "سانت مارتن",
    official_name_cn: "圣马丁(荷属)",
    official_name_en: "Sint Maarten (Dutch part)",
    official_name_es: "San Martín (parte holandés)",
    official_name_fr: "Saint-Martin (partie néerlandaise)",
    official_name_ru: "Синт-Мартен",
  },
  {
    "CLDR display name": "Slovakia",
    Capital: "Bratislava",
    Continent: "EU",
    DS: "SK",
    "Developed / Developing Countries": "Developed",
    Dial: "421",
    EDGAR: "2B",
    FIFA: "SVK",
    FIPS: "LO",
    GAUL: "223",
    "Geoname ID": 3057568,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SVK",
    "ISO3166-1-Alpha-2": "SK",
    "ISO3166-1-Alpha-3": "SVK",
    "ISO3166-1-numeric": "703",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SLOVAKIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "SVK",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sk,hu",
    "Least Developed Countries (LDC)": null,
    M49: 703,
    MARC: "xo",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".sk",
    "UNTERM Arabic Formal": "الجمهورية السلوفاكية",
    "UNTERM Arabic Short": "سلوفاكيا",
    "UNTERM Chinese Formal": "斯洛伐克共和国",
    "UNTERM Chinese Short": "斯洛伐克",
    "UNTERM English Formal": "the Slovak Republic",
    "UNTERM English Short": "Slovakia",
    "UNTERM French Formal": "la République slovaque",
    "UNTERM French Short": "Slovaquie (la)",
    "UNTERM Russian Formal": "Словацкая Республика",
    "UNTERM Russian Short": "Словакия",
    "UNTERM Spanish Formal": "la República Eslovaca",
    "UNTERM Spanish Short": "Eslovaquia",
    WMO: "SQ",
    is_independent: "Yes",
    official_name_ar: "سلوفاكيا",
    official_name_cn: "斯洛伐克",
    official_name_en: "Slovakia",
    official_name_es: "Eslovaquia",
    official_name_fr: "Slovaquie",
    official_name_ru: "Словакия",
  },
  {
    "CLDR display name": "Slovenia",
    Capital: "Ljubljana",
    Continent: "EU",
    DS: "SLO",
    "Developed / Developing Countries": "Developed",
    Dial: "386",
    EDGAR: "2A",
    FIFA: "SVN",
    FIPS: "SI",
    GAUL: "224",
    "Geoname ID": 3190538,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SLO",
    "ISO3166-1-Alpha-2": "SI",
    "ISO3166-1-Alpha-3": "SVN",
    "ISO3166-1-numeric": "705",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SLOVENIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "SVN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sl,sh",
    "Least Developed Countries (LDC)": null,
    M49: 705,
    MARC: "xv",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".si",
    "UNTERM Arabic Formal": "جمهورية سلوفينيا",
    "UNTERM Arabic Short": "سلوفينيا",
    "UNTERM Chinese Formal": "斯洛文尼亚共和国",
    "UNTERM Chinese Short": "斯洛文尼亚",
    "UNTERM English Formal": "the Republic of Slovenia",
    "UNTERM English Short": "Slovenia",
    "UNTERM French Formal": "la République de Slovénie",
    "UNTERM French Short": "Slovénie (la)",
    "UNTERM Russian Formal": "Республика Словения",
    "UNTERM Russian Short": "Словения",
    "UNTERM Spanish Formal": "la República de Eslovenia",
    "UNTERM Spanish Short": "Eslovenia",
    WMO: "LJ",
    is_independent: "Yes",
    official_name_ar: "سلوفينيا",
    official_name_cn: "斯洛文尼亚",
    official_name_en: "Slovenia",
    official_name_es: "Eslovenia",
    official_name_fr: "Slovénie",
    official_name_ru: "Словения",
  },
  {
    "CLDR display name": "Solomon Islands",
    Capital: "Honiara",
    Continent: "OC",
    DS: "SB",
    "Developed / Developing Countries": "Developing",
    Dial: "677",
    EDGAR: "D7",
    FIFA: "SOL",
    FIPS: "BP",
    GAUL: "225",
    "Geoname ID": 2103350,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SOL",
    "ISO3166-1-Alpha-2": "SB",
    "ISO3166-1-Alpha-3": "SLB",
    "ISO3166-1-numeric": "090",
    "ISO4217-currency_alphabetic_code": "SBD",
    "ISO4217-currency_country_name": "SOLOMON ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Solomon Islands Dollar",
    "ISO4217-currency_numeric_code": "090",
    ITU: "SLM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-SB,tpi",
    "Least Developed Countries (LDC)": "x",
    M49: 90,
    MARC: "bp",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "54",
    "Sub-region Name": "Melanesia",
    TLD: ".sb",
    "UNTERM Arabic Formal": "جزر سليمان",
    "UNTERM Arabic Short": "جزر سليمان",
    "UNTERM Chinese Formal": "所罗门群岛",
    "UNTERM Chinese Short": "所罗门群岛",
    "UNTERM English Formal": "Solomon Islands",
    "UNTERM English Short": "Solomon Islands",
    "UNTERM French Formal": "les Îles Salomon",
    "UNTERM French Short": "Îles Salomon (les)",
    "UNTERM Russian Formal": "Соломоновы Острова",
    "UNTERM Russian Short": "Соломоновы Острова",
    "UNTERM Spanish Formal": "las Islas Salomón",
    "UNTERM Spanish Short": "Islas Salomón (las)",
    WMO: "SO",
    is_independent: "Yes",
    official_name_ar: "جزر سليمان",
    official_name_cn: "所罗门群岛",
    official_name_en: "Solomon Islands",
    official_name_es: "Islas Salomón",
    official_name_fr: "Îles Salomon",
    official_name_ru: "Соломоновы Острова",
  },
  {
    "CLDR display name": "Somalia",
    Capital: "Mogadishu",
    Continent: "AF",
    DS: "SO",
    "Developed / Developing Countries": "Developing",
    Dial: "252",
    EDGAR: "U1",
    FIFA: "SOM",
    FIPS: "SO",
    GAUL: "226",
    "Geoname ID": 51537,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SOM",
    "ISO3166-1-Alpha-2": "SO",
    "ISO3166-1-Alpha-3": "SOM",
    "ISO3166-1-numeric": "706",
    "ISO4217-currency_alphabetic_code": "SOS",
    "ISO4217-currency_country_name": "SOMALIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Somali Shilling",
    "ISO4217-currency_numeric_code": "706",
    ITU: "SOM",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "so-SO,ar-SO,it,en-SO",
    "Least Developed Countries (LDC)": "x",
    M49: 706,
    MARC: "so",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".so",
    "UNTERM Arabic Formal": "جمهورية الصومال الاتحادية",
    "UNTERM Arabic Short": "الصومال",
    "UNTERM Chinese Formal": "索马里联邦共和国",
    "UNTERM Chinese Short": "索马里",
    "UNTERM English Formal": "the Federal Republic of Somalia",
    "UNTERM English Short": "Somalia",
    "UNTERM French Formal": "la République fédérale de Somalie",
    "UNTERM French Short": "Somalie (la)",
    "UNTERM Russian Formal": "Федеративная Республика Сомали",
    "UNTERM Russian Short": "Сомали",
    "UNTERM Spanish Formal": "la República Federal de Somalia",
    "UNTERM Spanish Short": "Somalia",
    WMO: "SI",
    is_independent: "Yes",
    official_name_ar: "الصومال",
    official_name_cn: "索马里",
    official_name_en: "Somalia",
    official_name_es: "Somalia",
    official_name_fr: "Somalie",
    official_name_ru: "Сомали",
  },
  {
    "CLDR display name": "South Africa",
    Capital: "Pretoria",
    Continent: "AF",
    DS: "ZA",
    "Developed / Developing Countries": "Developing",
    Dial: "27",
    EDGAR: "T3",
    FIFA: "RSA",
    FIPS: "SF",
    GAUL: "227",
    "Geoname ID": 953987,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "RSA",
    "ISO3166-1-Alpha-2": "ZA",
    "ISO3166-1-Alpha-3": "ZAF",
    "ISO3166-1-numeric": "710",
    "ISO4217-currency_alphabetic_code": "ZAR",
    "ISO4217-currency_country_name": "SOUTH AFRICA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Rand",
    "ISO4217-currency_numeric_code": "710",
    ITU: "AFS",
    "Intermediate Region Code": "18",
    "Intermediate Region Name": "Southern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr",
    "Least Developed Countries (LDC)": null,
    M49: 710,
    MARC: "sa",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".za",
    "UNTERM Arabic Formal": "جمهورية جنوب أفريقيا",
    "UNTERM Arabic Short": "جنوب أفريقيا",
    "UNTERM Chinese Formal": "南非共和国",
    "UNTERM Chinese Short": "南非",
    "UNTERM English Formal": "the Republic of South Africa",
    "UNTERM English Short": "South Africa",
    "UNTERM French Formal": "la République sud-africaine",
    "UNTERM French Short": "Afrique du Sud (l') [fém.]",
    "UNTERM Russian Formal": "Южно-Африканская Республика",
    "UNTERM Russian Short": "Южная Африка",
    "UNTERM Spanish Formal": "la República de Sudáfrica",
    "UNTERM Spanish Short": "Sudáfrica",
    WMO: "ZA",
    is_independent: "Yes",
    official_name_ar: "جنوب أفريقيا",
    official_name_cn: "南非",
    official_name_en: "South Africa",
    official_name_es: "Sudáfrica",
    official_name_fr: "Afrique du Sud",
    official_name_ru: "Южная Африка",
  },
  {
    "CLDR display name": "South Georgia & South Sandwich Islands",
    Capital: "Grytviken",
    Continent: "AN",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "500",
    EDGAR: "1L",
    FIFA: null,
    FIPS: "SX",
    GAUL: "228",
    "Geoname ID": 3474415,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "GS",
    "ISO3166-1-Alpha-3": "SGS",
    "ISO3166-1-numeric": "239",
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name":
      "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": "No universal currency",
    "ISO4217-currency_numeric_code": null,
    ITU: null,
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en",
    "Least Developed Countries (LDC)": null,
    M49: 239,
    MARC: "xs",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".gs",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territory of GB",
    official_name_ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    official_name_cn: "南乔治亚岛和南桑德韦奇岛",
    official_name_en: "South Georgia and the South Sandwich Islands",
    official_name_es: "Georgia del Sur y las Islas Sandwich del Sur",
    official_name_fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
    official_name_ru: "Южная Джорджия и Южные Сандвичевы острова",
  },
  {
    "CLDR display name": "South Sudan",
    Capital: "Juba",
    Continent: "AF",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "211",
    EDGAR: null,
    FIFA: null,
    FIPS: "OD",
    GAUL: null,
    "Geoname ID": 7909807,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "SS",
    "ISO3166-1-Alpha-3": "SSD",
    "ISO3166-1-numeric": "728",
    "ISO4217-currency_alphabetic_code": "SSP",
    "ISO4217-currency_country_name": "SOUTH SUDAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "South Sudanese Pound",
    "ISO4217-currency_numeric_code": "728",
    ITU: "SSD",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en",
    "Least Developed Countries (LDC)": "x",
    M49: 728,
    MARC: "sd",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: null,
    "UNTERM Arabic Formal": "جمهورية جنوب السودان",
    "UNTERM Arabic Short": "جنوب السودان",
    "UNTERM Chinese Formal": "南苏丹共和国",
    "UNTERM Chinese Short": "南苏丹",
    "UNTERM English Formal": "the Republic of South Sudan",
    "UNTERM English Short": "South Sudan",
    "UNTERM French Formal": "la République du Soudan du Sud",
    "UNTERM French Short": "Soudan du Sud (le)",
    "UNTERM Russian Formal": "Республика Южный Судан",
    "UNTERM Russian Short": "Южный Судан",
    "UNTERM Spanish Formal": "la República de Sudán del Sur",
    "UNTERM Spanish Short": "Sudán del Sur",
    WMO: null,
    is_independent: "Yes",
    official_name_ar: "جنوب السودان",
    official_name_cn: "南苏丹",
    official_name_en: "South Sudan",
    official_name_es: "Sudán del Sur",
    official_name_fr: "Soudan du Sud",
    official_name_ru: "Южный Судан",
  },
  {
    "CLDR display name": "Spain",
    Capital: "Madrid",
    Continent: "EU",
    DS: "E",
    "Developed / Developing Countries": "Developed",
    Dial: "34",
    EDGAR: "U3",
    FIFA: "ESP",
    FIPS: "SP",
    GAUL: "229",
    "Geoname ID": 2510769,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ESP",
    "ISO3166-1-Alpha-2": "ES",
    "ISO3166-1-Alpha-3": "ESP",
    "ISO3166-1-numeric": "724",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "SPAIN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: "E",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-ES,ca,gl,eu,oc",
    "Least Developed Countries (LDC)": null,
    M49: 724,
    MARC: "sp",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".es",
    "UNTERM Arabic Formal": "مملكة إسبانيا",
    "UNTERM Arabic Short": "إسبانيا",
    "UNTERM Chinese Formal": "西班牙王国",
    "UNTERM Chinese Short": "西班牙",
    "UNTERM English Formal": "the Kingdom of Spain",
    "UNTERM English Short": "Spain",
    "UNTERM French Formal": "le Royaume d'Espagne",
    "UNTERM French Short": "Espagne (l') [fém.]",
    "UNTERM Russian Formal": "Королевство Испания",
    "UNTERM Russian Short": "Испания",
    "UNTERM Spanish Formal": "el Reino de España",
    "UNTERM Spanish Short": "España",
    WMO: "SP",
    is_independent: "Yes",
    official_name_ar: "إسبانيا",
    official_name_cn: "西班牙",
    official_name_en: "Spain",
    official_name_es: "España",
    official_name_fr: "Espagne",
    official_name_ru: "Испания",
  },
  {
    "CLDR display name": "Sri Lanka",
    Capital: "Colombo",
    Continent: "AS",
    DS: "CL",
    "Developed / Developing Countries": "Developing",
    Dial: "94",
    EDGAR: "F1",
    FIFA: "SRI",
    FIPS: "CE",
    GAUL: "231",
    "Geoname ID": 1227603,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SRI",
    "ISO3166-1-Alpha-2": "LK",
    "ISO3166-1-Alpha-3": "LKA",
    "ISO3166-1-numeric": "144",
    "ISO4217-currency_alphabetic_code": "LKR",
    "ISO4217-currency_country_name": "SRI LANKA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Sri Lanka Rupee",
    "ISO4217-currency_numeric_code": "144",
    ITU: "CLN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "si,ta,en",
    "Least Developed Countries (LDC)": null,
    M49: 144,
    MARC: "ce",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "34",
    "Sub-region Name": "Southern Asia",
    TLD: ".lk",
    "UNTERM Arabic Formal": "جمهورية سري لانكا الاشتراكية الديمقراطية",
    "UNTERM Arabic Short": "سري لانكا",
    "UNTERM Chinese Formal": "斯里兰卡民主社会主义共和国",
    "UNTERM Chinese Short": "斯里兰卡",
    "UNTERM English Formal": "the Democratic Socialist Republic of Sri Lanka",
    "UNTERM English Short": "Sri Lanka",
    "UNTERM French Formal":
      "la République socialiste démocratique de Sri Lanka",
    "UNTERM French Short": "Sri Lanka [fém.]",
    "UNTERM Russian Formal":
      "Демократическая Социалистическая Республика Шри-Ланка",
    "UNTERM Russian Short": "Шри-Ланка",
    "UNTERM Spanish Formal": "la República Socialista Democrática de Sri Lanka",
    "UNTERM Spanish Short": "Sri Lanka",
    WMO: "SB",
    is_independent: "Yes",
    official_name_ar: "سري لانكا",
    official_name_cn: "斯里兰卡",
    official_name_en: "Sri Lanka",
    official_name_es: "Sri Lanka",
    official_name_fr: "Sri Lanka",
    official_name_ru: "Шри-Ланка",
  },
  {
    "CLDR display name": "Palestine",
    Capital: "East Jerusalem",
    Continent: "AS",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "970",
    EDGAR: null,
    FIFA: "PLE",
    FIPS: "GZ,WE",
    GAUL: "91,267",
    "Geoname ID": 6254930,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "PLE",
    "ISO3166-1-Alpha-2": "PS",
    "ISO3166-1-Alpha-3": "PSE",
    "ISO3166-1-numeric": "275",
    "ISO4217-currency_alphabetic_code": null,
    "ISO4217-currency_country_name": "PALESTINE, STATE OF",
    "ISO4217-currency_minor_unit": null,
    "ISO4217-currency_name": "No universal currency",
    "ISO4217-currency_numeric_code": null,
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-PS",
    "Least Developed Countries (LDC)": null,
    M49: 275,
    MARC: "gz,wj",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".ps",
    "UNTERM Arabic Formal": "دولة فلسطين",
    "UNTERM Arabic Short": "دولة فلسطين  *",
    "UNTERM Chinese Formal": "巴勒斯坦国",
    "UNTERM Chinese Short": "巴勒斯坦国  *",
    "UNTERM English Formal": "State of Palestine",
    "UNTERM English Short": "State of Palestine  *",
    "UNTERM French Formal": "État de Palestine",
    "UNTERM French Short": "État de Palestine  *",
    "UNTERM Russian Formal": "Государство Палестина",
    "UNTERM Russian Short": "Государство Палестина  *",
    "UNTERM Spanish Formal": "Estado  de Palestina",
    "UNTERM Spanish Short": "Estado  de Palestina  *",
    WMO: null,
    is_independent: "In contention",
    official_name_ar: "دولة فلسطين",
    official_name_cn: "巴勒斯坦国",
    official_name_en: "State of Palestine",
    official_name_es: "Estado de Palestina",
    official_name_fr: "État de Palestine",
    official_name_ru: "Государство Палестина",
  },
  {
    "CLDR display name": "Sudan",
    Capital: "Khartoum",
    Continent: "AF",
    DS: "SUD",
    "Developed / Developing Countries": "Developing",
    Dial: "249",
    EDGAR: "V2",
    FIFA: "SUD",
    FIPS: "SU",
    GAUL: "40764",
    "Geoname ID": 366755,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SUD",
    "ISO3166-1-Alpha-2": "SD",
    "ISO3166-1-Alpha-3": "SDN",
    "ISO3166-1-numeric": "729",
    "ISO4217-currency_alphabetic_code": "SDG",
    "ISO4217-currency_country_name": "SUDAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Sudanese Pound",
    "ISO4217-currency_numeric_code": "938",
    ITU: "SDN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-SD,en,fia",
    "Least Developed Countries (LDC)": "x",
    M49: 729,
    MARC: "sj",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".sd",
    "UNTERM Arabic Formal": "جمهورية السودان",
    "UNTERM Arabic Short": "السودان",
    "UNTERM Chinese Formal": "苏丹共和国",
    "UNTERM Chinese Short": "苏丹",
    "UNTERM English Formal": "the Republic of the Sudan",
    "UNTERM English Short": "Sudan (the)",
    "UNTERM French Formal": "la République du Soudan",
    "UNTERM French Short": "Soudan (le)",
    "UNTERM Russian Formal": "Республика Судан",
    "UNTERM Russian Short": "Судан",
    "UNTERM Spanish Formal": "la República del Sudán",
    "UNTERM Spanish Short": "Sudán (el)",
    WMO: "SU",
    is_independent: "Yes",
    official_name_ar: "السودان",
    official_name_cn: "苏丹",
    official_name_en: "Sudan",
    official_name_es: "Sudán",
    official_name_fr: "Soudan",
    official_name_ru: "Судан",
  },
  {
    "CLDR display name": "Suriname",
    Capital: "Paramaribo",
    Continent: "SA",
    DS: "SME",
    "Developed / Developing Countries": "Developing",
    Dial: "597",
    EDGAR: "V3",
    FIFA: "SUR",
    FIPS: "NS",
    GAUL: "233",
    "Geoname ID": 3382998,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SUR",
    "ISO3166-1-Alpha-2": "SR",
    "ISO3166-1-Alpha-3": "SUR",
    "ISO3166-1-numeric": "740",
    "ISO4217-currency_alphabetic_code": "SRD",
    "ISO4217-currency_country_name": "SURINAME",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Surinam Dollar",
    "ISO4217-currency_numeric_code": "968",
    ITU: "SUR",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "nl-SR,en,srn,hns,jv",
    "Least Developed Countries (LDC)": null,
    M49: 740,
    MARC: "sr",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".sr",
    "UNTERM Arabic Formal": "جمهورية سورينام",
    "UNTERM Arabic Short": "سورينام",
    "UNTERM Chinese Formal": "苏里南共和国",
    "UNTERM Chinese Short": "苏里南",
    "UNTERM English Formal": "the Republic of Suriname",
    "UNTERM English Short": "Suriname",
    "UNTERM French Formal": "la République du Suriname",
    "UNTERM French Short": "Suriname (le)",
    "UNTERM Russian Formal": "Республика Суринам",
    "UNTERM Russian Short": "Суринам",
    "UNTERM Spanish Formal": "la República de Suriname",
    "UNTERM Spanish Short": "Suriname",
    WMO: "SM",
    is_independent: "Yes",
    official_name_ar: "سورينام",
    official_name_cn: "苏里南",
    official_name_en: "Suriname",
    official_name_es: "Suriname",
    official_name_fr: "Suriname",
    official_name_ru: "Суринам",
  },
  {
    "CLDR display name": "Svalbard & Jan Mayen",
    Capital: "Longyearbyen",
    Continent: "EU",
    DS: null,
    "Developed / Developing Countries": "Developed",
    Dial: "47",
    EDGAR: "L9",
    FIFA: null,
    FIPS: "SV,JN",
    GAUL: "234",
    "Geoname ID": 607072,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "SJ",
    "ISO3166-1-Alpha-3": "SJM",
    "ISO3166-1-numeric": "744",
    "ISO4217-currency_alphabetic_code": "NOK",
    "ISO4217-currency_country_name": "SVALBARD AND JAN MAYEN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Norwegian Krone",
    "ISO4217-currency_numeric_code": "578",
    ITU: "NOR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "no,ru",
    "Least Developed Countries (LDC)": null,
    M49: 744,
    MARC: null,
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".sj",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "SZ",
    is_independent: "Territory of NO",
    official_name_ar: "جزر سفالبارد وجان ماين",
    official_name_cn: "斯瓦尔巴岛和扬马延岛",
    official_name_en: "Svalbard and Jan Mayen Islands",
    official_name_es: "Islas Svalbard y Jan Mayen",
    official_name_fr: "Îles Svalbard-et-Jan Mayen",
    official_name_ru: "Острова Свальбард и Ян-Майен",
  },
  {
    "CLDR display name": "Sweden",
    Capital: "Stockholm",
    Continent: "EU",
    DS: "S",
    "Developed / Developing Countries": "Developed",
    Dial: "46",
    EDGAR: "V7",
    FIFA: "SWE",
    FIPS: "SW",
    GAUL: "236",
    "Geoname ID": 2661886,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SWE",
    "ISO3166-1-Alpha-2": "SE",
    "ISO3166-1-Alpha-3": "SWE",
    "ISO3166-1-numeric": "752",
    "ISO4217-currency_alphabetic_code": "SEK",
    "ISO4217-currency_country_name": "SWEDEN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Swedish Krona",
    "ISO4217-currency_numeric_code": "752",
    ITU: "S",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sv-SE,se,sma,fi-SE",
    "Least Developed Countries (LDC)": null,
    M49: 752,
    MARC: "sw",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".se",
    "UNTERM Arabic Formal": "مملكة السويد",
    "UNTERM Arabic Short": "السويد",
    "UNTERM Chinese Formal": "瑞典王国",
    "UNTERM Chinese Short": "瑞典",
    "UNTERM English Formal": "the Kingdom of Sweden",
    "UNTERM English Short": "Sweden",
    "UNTERM French Formal": "le Royaume de Suède",
    "UNTERM French Short": "Suède (la)",
    "UNTERM Russian Formal": "Королевство Швеция",
    "UNTERM Russian Short": "Швеция",
    "UNTERM Spanish Formal": "el Reino de Suecia",
    "UNTERM Spanish Short": "Suecia",
    WMO: "SN",
    is_independent: "Yes",
    official_name_ar: "السويد",
    official_name_cn: "瑞典",
    official_name_en: "Sweden",
    official_name_es: "Suecia",
    official_name_fr: "Suède",
    official_name_ru: "Швеция",
  },
  {
    "CLDR display name": "Switzerland",
    Capital: "Bern",
    Continent: "EU",
    DS: "CH",
    "Developed / Developing Countries": "Developed",
    Dial: "41",
    EDGAR: "V8",
    FIFA: "SUI",
    FIPS: "SZ",
    GAUL: "237",
    "Geoname ID": 2658434,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SUI",
    "ISO3166-1-Alpha-2": "CH",
    "ISO3166-1-Alpha-3": "CHE",
    "ISO3166-1-numeric": "756",
    "ISO4217-currency_alphabetic_code": "CHF",
    "ISO4217-currency_country_name": "SWITZERLAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Swiss Franc",
    "ISO4217-currency_numeric_code": "756",
    ITU: "SUI",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "de-CH,fr-CH,it-CH,rm",
    "Least Developed Countries (LDC)": null,
    M49: 756,
    MARC: "sz",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "155",
    "Sub-region Name": "Western Europe",
    TLD: ".ch",
    "UNTERM Arabic Formal": "الاتحاد السويسري",
    "UNTERM Arabic Short": "سويسرا",
    "UNTERM Chinese Formal": "瑞士联邦",
    "UNTERM Chinese Short": "瑞士",
    "UNTERM English Formal": "the Swiss Confederation",
    "UNTERM English Short": "Switzerland",
    "UNTERM French Formal": "la Confédération suisse",
    "UNTERM French Short": "Suisse (la)",
    "UNTERM Russian Formal": "Швейцарская Конфедерация",
    "UNTERM Russian Short": "Швейцария",
    "UNTERM Spanish Formal": "la Confederación Suiza",
    "UNTERM Spanish Short": "Suiza",
    WMO: "SW",
    is_independent: "Yes",
    official_name_ar: "سويسرا",
    official_name_cn: "瑞士",
    official_name_en: "Switzerland",
    official_name_es: "Suiza",
    official_name_fr: "Suisse",
    official_name_ru: "Швейцария",
  },
  {
    "CLDR display name": "Syria",
    Capital: "Damascus",
    Continent: "AS",
    DS: "SYR",
    "Developed / Developing Countries": "Developing",
    Dial: "963",
    EDGAR: "V9",
    FIFA: "SYR",
    FIPS: "SY",
    GAUL: "238",
    "Geoname ID": 163843,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "SYR",
    "ISO3166-1-Alpha-2": "SY",
    "ISO3166-1-Alpha-3": "SYR",
    "ISO3166-1-numeric": "760",
    "ISO4217-currency_alphabetic_code": "SYP",
    "ISO4217-currency_country_name": "SYRIAN ARAB REPUBLIC",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Syrian Pound",
    "ISO4217-currency_numeric_code": "760",
    ITU: "SYR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-SY,ku,hy,arc,fr,en",
    "Least Developed Countries (LDC)": null,
    M49: 760,
    MARC: "sy",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".sy",
    "UNTERM Arabic Formal": "الجمهورية العربية السورية",
    "UNTERM Arabic Short": "الجمهورية العربية السورية",
    "UNTERM Chinese Formal": "阿拉伯叙利亚共和国",
    "UNTERM Chinese Short": "阿拉伯叙利亚共和国",
    "UNTERM English Formal": "the Syrian Arab Republic",
    "UNTERM English Short": "Syrian Arab Republic",
    "UNTERM French Formal": "la République arabe syrienne",
    "UNTERM French Short": "République arabe syrienne (la)",
    "UNTERM Russian Formal": "Сирийская Арабская Республика",
    "UNTERM Russian Short": "Сирийская Арабская Республика",
    "UNTERM Spanish Formal": "la República Árabe Siria",
    "UNTERM Spanish Short": "República Árabe Siria (la)",
    WMO: "SY",
    is_independent: "Yes",
    official_name_ar: "الجمهورية العربية السورية",
    official_name_cn: "阿拉伯叙利亚共和国",
    official_name_en: "Syrian Arab Republic",
    official_name_es: "República Árabe Siria",
    official_name_fr: "République arabe syrienne",
    official_name_ru: "Сирийская Арабская Республика",
  },
  {
    "CLDR display name": "Tajikistan",
    Capital: "Dushanbe",
    Continent: "AS",
    DS: "TJ",
    "Developed / Developing Countries": "Developing",
    Dial: "992",
    EDGAR: "2D",
    FIFA: "TJK",
    FIPS: "TI",
    GAUL: "239",
    "Geoname ID": 1220409,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TJK",
    "ISO3166-1-Alpha-2": "TJ",
    "ISO3166-1-Alpha-3": "TJK",
    "ISO3166-1-numeric": "762",
    "ISO4217-currency_alphabetic_code": "TJS",
    "ISO4217-currency_country_name": "TAJIKISTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Somoni",
    "ISO4217-currency_numeric_code": "972",
    ITU: "TJK",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "tg,ru",
    "Least Developed Countries (LDC)": null,
    M49: 762,
    MARC: "ta",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "143",
    "Sub-region Name": "Central Asia",
    TLD: ".tj",
    "UNTERM Arabic Formal": "جمهورية طاجيكستان",
    "UNTERM Arabic Short": "طاجيكستان",
    "UNTERM Chinese Formal": "塔吉克斯坦共和国",
    "UNTERM Chinese Short": "塔吉克斯坦",
    "UNTERM English Formal": "the Republic of Tajikistan",
    "UNTERM English Short": "Tajikistan",
    "UNTERM French Formal": "la République du Tadjikistan",
    "UNTERM French Short": "Tadjikistan (le)",
    "UNTERM Russian Formal": "Республика Таджикистан",
    "UNTERM Russian Short": "Таджикистан",
    "UNTERM Spanish Formal": "la República de Tayikistán",
    "UNTERM Spanish Short": "Tayikistán",
    WMO: "TA",
    is_independent: "Yes",
    official_name_ar: "طاجيكستان",
    official_name_cn: "塔吉克斯坦",
    official_name_en: "Tajikistan",
    official_name_es: "Tayikistán",
    official_name_fr: "Tadjikistan",
    official_name_ru: "Таджикистан",
  },
  {
    "CLDR display name": "Thailand",
    Capital: "Bangkok",
    Continent: "AS",
    DS: "T",
    "Developed / Developing Countries": "Developing",
    Dial: "66",
    EDGAR: "W1",
    FIFA: "THA",
    FIPS: "TH",
    GAUL: "240",
    "Geoname ID": 1605651,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "THA",
    "ISO3166-1-Alpha-2": "TH",
    "ISO3166-1-Alpha-3": "THA",
    "ISO3166-1-numeric": "764",
    "ISO4217-currency_alphabetic_code": "THB",
    "ISO4217-currency_country_name": "THAILAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Baht",
    "ISO4217-currency_numeric_code": "764",
    ITU: "THA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "th,en",
    "Least Developed Countries (LDC)": null,
    M49: 764,
    MARC: "th",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".th",
    "UNTERM Arabic Formal": "مملكة تايلند",
    "UNTERM Arabic Short": "تايلند",
    "UNTERM Chinese Formal": "泰王国",
    "UNTERM Chinese Short": "泰国",
    "UNTERM English Formal": "the Kingdom of Thailand",
    "UNTERM English Short": "Thailand",
    "UNTERM French Formal": "le Royaume de Thaïlande",
    "UNTERM French Short": "Thaïlande (la)",
    "UNTERM Russian Formal": "Королевство Таиланд",
    "UNTERM Russian Short": "Таиланд",
    "UNTERM Spanish Formal": "el Reino de Tailandia",
    "UNTERM Spanish Short": "Tailandia",
    WMO: "TH",
    is_independent: "Yes",
    official_name_ar: "تايلند",
    official_name_cn: "泰国",
    official_name_en: "Thailand",
    official_name_es: "Tailandia",
    official_name_fr: "Thaïlande",
    official_name_ru: "Таиланд",
  },
  {
    "CLDR display name": "North Macedonia",
    Capital: "Skopje",
    Continent: "EU",
    DS: "MK",
    "Developed / Developing Countries": "Developed",
    Dial: "389",
    EDGAR: null,
    FIFA: "MKD",
    FIPS: "MK",
    GAUL: "241",
    "Geoname ID": 718075,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "MKD",
    "ISO3166-1-Alpha-2": "MK",
    "ISO3166-1-Alpha-3": "MKD",
    "ISO3166-1-numeric": "807",
    "ISO4217-currency_alphabetic_code": "MKD",
    "ISO4217-currency_country_name":
      "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Denar",
    "ISO4217-currency_numeric_code": "807",
    ITU: "MKD",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "mk,sq,tr,rmm,sr",
    "Least Developed Countries (LDC)": null,
    M49: 807,
    MARC: "xn",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "39",
    "Sub-region Name": "Southern Europe",
    TLD: ".mk",
    "UNTERM Arabic Formal": "جمهورية مقدونيا اليوغوسلافية سابقاً",
    "UNTERM Arabic Short": "جمهورية مقدونيا اليوغوسلافية سابقاً",
    "UNTERM Chinese Formal": "前南斯拉夫的马其顿共和国",
    "UNTERM Chinese Short": "前南斯拉夫的马其顿共和国",
    "UNTERM English Formal": "the former Yugoslav Republic of Macedonia",
    "UNTERM English Short": "the former Yugoslav Republic of Macedonia",
    "UNTERM French Formal": "l'ex-République yougoslave de Macédoine [fém.]",
    "UNTERM French Short": "ex-République yougoslave de Macédoine (l') [fém.]",
    "UNTERM Russian Formal": "бывшая югославская Республика Македония",
    "UNTERM Russian Short": "бывшая югославская Республика Македония",
    "UNTERM Spanish Formal": "ex República Yugoslava de Macedonia (la)",
    "UNTERM Spanish Short": "ex República Yugoslava de Macedonia",
    WMO: "MJ",
    is_independent: "Yes",
    official_name_ar: "جمهورية مقدونيا اليوغوسلافية سابقاً",
    official_name_cn: "前南斯拉夫的马其顿共和国",
    official_name_en: "The former Yugoslav Republic of Macedonia",
    official_name_es: "ex República Yugoslava de Macedonia",
    official_name_fr: "ex-République yougoslave de Macédoine",
    official_name_ru: "бывшая югославская Республика Македония",
  },
  {
    "CLDR display name": "Timor-Leste",
    Capital: "Dili",
    Continent: "OC",
    DS: "RI",
    "Developed / Developing Countries": "Developing",
    Dial: "670",
    EDGAR: "Z3",
    FIFA: "TLS",
    FIPS: "TT",
    GAUL: "242",
    "Geoname ID": 1966436,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TLS",
    "ISO3166-1-Alpha-2": "TL",
    "ISO3166-1-Alpha-3": "TLS",
    "ISO3166-1-numeric": "626",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "TIMOR-LESTE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "TLS",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "tet,pt-TL,id,en",
    "Least Developed Countries (LDC)": "x",
    M49: 626,
    MARC: "em",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".tl",
    "UNTERM Arabic Formal": "جمهورية تيمور - ليشتي الديمقراطية",
    "UNTERM Arabic Short": "تيمور- ليشتي",
    "UNTERM Chinese Formal": "东帝汶民主共和国",
    "UNTERM Chinese Short": "东帝汶",
    "UNTERM English Formal": "the Democratic Republic of Timor-Leste",
    "UNTERM English Short": "Timor-Leste",
    "UNTERM French Formal": "la République démocratique du Timor-Leste",
    "UNTERM French Short": "Timor-Leste (le)",
    "UNTERM Russian Formal": "Демократическая Республика Тимор-Лешти",
    "UNTERM Russian Short": "Тимор-Лешти",
    "UNTERM Spanish Formal": "la República Democrática de Timor-Leste",
    "UNTERM Spanish Short": "Timor-Leste",
    WMO: "TM",
    is_independent: "Yes",
    official_name_ar: "تيمور- ليشتي",
    official_name_cn: "东帝汶",
    official_name_en: "Timor-Leste",
    official_name_es: "Timor-Leste",
    official_name_fr: "Timor-Leste",
    official_name_ru: "Тимор-Лешти",
  },
  {
    "CLDR display name": "Togo",
    Capital: "Lome",
    Continent: "AF",
    DS: "TG",
    "Developed / Developing Countries": "Developing",
    Dial: "228",
    EDGAR: "W2",
    FIFA: "TOG",
    FIPS: "TO",
    GAUL: "243",
    "Geoname ID": 2363686,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TOG",
    "ISO3166-1-Alpha-2": "TG",
    "ISO3166-1-Alpha-3": "TGO",
    "ISO3166-1-numeric": "768",
    "ISO4217-currency_alphabetic_code": "XOF",
    "ISO4217-currency_country_name": "TOGO",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFA Franc BCEAO",
    "ISO4217-currency_numeric_code": "952",
    ITU: "TGO",
    "Intermediate Region Code": "11",
    "Intermediate Region Name": "Western Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "fr-TG,ee,hna,kbp,dag,ha",
    "Least Developed Countries (LDC)": "x",
    M49: 768,
    MARC: "tg",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".tg",
    "UNTERM Arabic Formal": "جمهورية توغو",
    "UNTERM Arabic Short": "توغو",
    "UNTERM Chinese Formal": "多哥共和国",
    "UNTERM Chinese Short": "多哥",
    "UNTERM English Formal": "the Togolese Republic",
    "UNTERM English Short": "Togo",
    "UNTERM French Formal": "la République togolaise",
    "UNTERM French Short": "Togo (le)",
    "UNTERM Russian Formal": "Тоголезская Республика",
    "UNTERM Russian Short": "Того",
    "UNTERM Spanish Formal": "la República Togolesa",
    "UNTERM Spanish Short": "Togo (el)",
    WMO: "TG",
    is_independent: "Yes",
    official_name_ar: "توغو",
    official_name_cn: "多哥",
    official_name_en: "Togo",
    official_name_es: "Togo",
    official_name_fr: "Togo",
    official_name_ru: "Того",
  },
  {
    "CLDR display name": "Tokelau",
    Capital: null,
    Continent: "OC",
    DS: "NZ",
    "Developed / Developing Countries": "Developing",
    Dial: "690",
    EDGAR: "W3",
    FIFA: "TKL",
    FIPS: "TL",
    GAUL: "244",
    "Geoname ID": 4031074,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "TK",
    "ISO3166-1-Alpha-3": "TKL",
    "ISO3166-1-numeric": "772",
    "ISO4217-currency_alphabetic_code": "NZD",
    "ISO4217-currency_country_name": "TOKELAU",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "New Zealand Dollar",
    "ISO4217-currency_numeric_code": "554",
    ITU: "TKL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "tkl,en-TK",
    "Least Developed Countries (LDC)": null,
    M49: 772,
    MARC: "tl",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".tk",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "TK",
    is_independent: "Territory of NZ",
    official_name_ar: "توكيلاو",
    official_name_cn: "托克劳",
    official_name_en: "Tokelau",
    official_name_es: "Tokelau",
    official_name_fr: "Tokélaou",
    official_name_ru: "Токелау",
  },
  {
    "CLDR display name": "Tonga",
    Capital: "Nuku'alofa",
    Continent: "OC",
    DS: "TO",
    "Developed / Developing Countries": "Developing",
    Dial: "676",
    EDGAR: "W4",
    FIFA: "TGA",
    FIPS: "TN",
    GAUL: "245",
    "Geoname ID": 4032283,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TGA",
    "ISO3166-1-Alpha-2": "TO",
    "ISO3166-1-Alpha-3": "TON",
    "ISO3166-1-numeric": "776",
    "ISO4217-currency_alphabetic_code": "TOP",
    "ISO4217-currency_country_name": "TONGA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pa’anga",
    "ISO4217-currency_numeric_code": "776",
    ITU: "TON",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "to,en-TO",
    "Least Developed Countries (LDC)": null,
    M49: 776,
    MARC: "to",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".to",
    "UNTERM Arabic Formal": "مملكة تونغا",
    "UNTERM Arabic Short": "تونغا",
    "UNTERM Chinese Formal": "汤加王国",
    "UNTERM Chinese Short": "汤加",
    "UNTERM English Formal": "the Kingdom of Tonga",
    "UNTERM English Short": "Tonga",
    "UNTERM French Formal": "le Royaume des Tonga",
    "UNTERM French Short": "Tonga (les) [fém.]",
    "UNTERM Russian Formal": "Королевство Тонга",
    "UNTERM Russian Short": "Тонга",
    "UNTERM Spanish Formal": "el Reino de Tonga",
    "UNTERM Spanish Short": "Tonga",
    WMO: "TO",
    is_independent: "Yes",
    official_name_ar: "تونغا",
    official_name_cn: "汤加",
    official_name_en: "Tonga",
    official_name_es: "Tonga",
    official_name_fr: "Tonga",
    official_name_ru: "Тонга",
  },
  {
    "CLDR display name": "Trinidad & Tobago",
    Capital: "Port of Spain",
    Continent: "NA",
    DS: "TT",
    "Developed / Developing Countries": "Developing",
    Dial: "1-868",
    EDGAR: "W5",
    FIFA: "TRI",
    FIPS: "TD",
    GAUL: "246",
    "Geoname ID": 3573591,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TTO",
    "ISO3166-1-Alpha-2": "TT",
    "ISO3166-1-Alpha-3": "TTO",
    "ISO3166-1-numeric": "780",
    "ISO4217-currency_alphabetic_code": "TTD",
    "ISO4217-currency_country_name": "TRINIDAD AND TOBAGO",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Trinidad and Tobago Dollar",
    "ISO4217-currency_numeric_code": "780",
    ITU: "TRD",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-TT,hns,fr,es,zh",
    "Least Developed Countries (LDC)": null,
    M49: 780,
    MARC: "tr",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".tt",
    "UNTERM Arabic Formal": "جمهورية ترينيداد وتوباغو",
    "UNTERM Arabic Short": "ترينيداد وتوباغو",
    "UNTERM Chinese Formal": "特立尼达和多巴哥共和国",
    "UNTERM Chinese Short": "特立尼达和多巴哥",
    "UNTERM English Formal": "the Republic of Trinidad and Tobago",
    "UNTERM English Short": "Trinidad and Tobago",
    "UNTERM French Formal": "la République de Trinité-et-Tobago",
    "UNTERM French Short": "Trinité-et-Tobago (la)",
    "UNTERM Russian Formal": "Республика Тринидад и Тобаго",
    "UNTERM Russian Short": "Тринидад и Тобаго",
    "UNTERM Spanish Formal": "la República de Trinidad y Tabago",
    "UNTERM Spanish Short": "Trinidad y Tabago",
    WMO: "TD",
    is_independent: "Yes",
    official_name_ar: "ترينيداد وتوباغو",
    official_name_cn: "特立尼达和多巴哥",
    official_name_en: "Trinidad and Tobago",
    official_name_es: "Trinidad y Tabago",
    official_name_fr: "Trinité-et-Tobago",
    official_name_ru: "Тринидад и Тобаго",
  },
  {
    "CLDR display name": "Tunisia",
    Capital: "Tunis",
    Continent: "AF",
    DS: "TN",
    "Developed / Developing Countries": "Developing",
    Dial: "216",
    EDGAR: "W6",
    FIFA: "TUN",
    FIPS: "TS",
    GAUL: "248",
    "Geoname ID": 2464461,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TUN",
    "ISO3166-1-Alpha-2": "TN",
    "ISO3166-1-Alpha-3": "TUN",
    "ISO3166-1-numeric": "788",
    "ISO4217-currency_alphabetic_code": "TND",
    "ISO4217-currency_country_name": "TUNISIA",
    "ISO4217-currency_minor_unit": "3",
    "ISO4217-currency_name": "Tunisian Dinar",
    "ISO4217-currency_numeric_code": "788",
    ITU: "TUN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-TN,fr",
    "Least Developed Countries (LDC)": null,
    M49: 788,
    MARC: "ti",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".tn",
    "UNTERM Arabic Formal": "الجمهورية التونسية",
    "UNTERM Arabic Short": "تونس",
    "UNTERM Chinese Formal": "突尼斯共和国",
    "UNTERM Chinese Short": "突尼斯",
    "UNTERM English Formal": "the Republic of Tunisia",
    "UNTERM English Short": "Tunisia",
    "UNTERM French Formal": "la République tunisienne",
    "UNTERM French Short": "Tunisie (la)",
    "UNTERM Russian Formal": "Тунисская Республика",
    "UNTERM Russian Short": "Тунис",
    "UNTERM Spanish Formal": "la República de Túnez",
    "UNTERM Spanish Short": "Túnez",
    WMO: "TS",
    is_independent: "Yes",
    official_name_ar: "تونس",
    official_name_cn: "突尼斯",
    official_name_en: "Tunisia",
    official_name_es: "Túnez",
    official_name_fr: "Tunisie",
    official_name_ru: "Тунис",
  },
  {
    "CLDR display name": "Turkey",
    Capital: "Ankara",
    Continent: "AS",
    DS: "TR",
    "Developed / Developing Countries": "Developing",
    Dial: "90",
    EDGAR: "W8",
    FIFA: "TUR",
    FIPS: "TU",
    GAUL: "249",
    "Geoname ID": 298795,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TUR",
    "ISO3166-1-Alpha-2": "TR",
    "ISO3166-1-Alpha-3": "TUR",
    "ISO3166-1-numeric": "792",
    "ISO4217-currency_alphabetic_code": "TRY",
    "ISO4217-currency_country_name": "TURKEY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Turkish Lira",
    "ISO4217-currency_numeric_code": "949",
    ITU: "TUR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "tr-TR,ku,diq,az,av",
    "Least Developed Countries (LDC)": null,
    M49: 792,
    MARC: "tu",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".tr",
    "UNTERM Arabic Formal": "جمهورية تركيا",
    "UNTERM Arabic Short": "تركيا",
    "UNTERM Chinese Formal": "土耳其共和国",
    "UNTERM Chinese Short": "土耳其",
    "UNTERM English Formal": "the Republic of Turkey",
    "UNTERM English Short": "Turkey",
    "UNTERM French Formal": "la République turque",
    "UNTERM French Short": "Turquie (la)",
    "UNTERM Russian Formal": "Турецкая Республика",
    "UNTERM Russian Short": "Турция",
    "UNTERM Spanish Formal": "la República de Turquía",
    "UNTERM Spanish Short": "Turquía",
    WMO: "TU",
    is_independent: "Yes",
    official_name_ar: "تركيا",
    official_name_cn: "土耳其",
    official_name_en: "Turkey",
    official_name_es: "Turquía",
    official_name_fr: "Turquie",
    official_name_ru: "Турция",
  },
  {
    "CLDR display name": "Turkmenistan",
    Capital: "Ashgabat",
    Continent: "AS",
    DS: "TM",
    "Developed / Developing Countries": "Developing",
    Dial: "993",
    EDGAR: "2E",
    FIFA: "TKM",
    FIPS: "TX",
    GAUL: "250",
    "Geoname ID": 1218197,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TKM",
    "ISO3166-1-Alpha-2": "TM",
    "ISO3166-1-Alpha-3": "TKM",
    "ISO3166-1-numeric": "795",
    "ISO4217-currency_alphabetic_code": "TMT",
    "ISO4217-currency_country_name": "TURKMENISTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Turkmenistan New Manat",
    "ISO4217-currency_numeric_code": "934",
    ITU: "TKM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "tk,ru,uz",
    "Least Developed Countries (LDC)": null,
    M49: 795,
    MARC: "tk",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "143",
    "Sub-region Name": "Central Asia",
    TLD: ".tm",
    "UNTERM Arabic Formal": "تركمانستان",
    "UNTERM Arabic Short": "تركمانستان",
    "UNTERM Chinese Formal": "土库曼斯坦",
    "UNTERM Chinese Short": "土库曼斯坦",
    "UNTERM English Formal": "Turkmenistan",
    "UNTERM English Short": "Turkmenistan",
    "UNTERM French Formal": "le Turkménistan",
    "UNTERM French Short": "Turkménistan (le)",
    "UNTERM Russian Formal": "Туркменистан",
    "UNTERM Russian Short": "Туркменистан",
    "UNTERM Spanish Formal": "Turkmenistán",
    "UNTERM Spanish Short": "Turkmenistán",
    WMO: "TR",
    is_independent: "Yes",
    official_name_ar: "تركمانستان",
    official_name_cn: "土库曼斯坦",
    official_name_en: "Turkmenistan",
    official_name_es: "Turkmenistán",
    official_name_fr: "Turkménistan",
    official_name_ru: "Туркменистан",
  },
  {
    "CLDR display name": "Turks & Caicos Islands",
    Capital: "Cockburn Town",
    Continent: "NA",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "1-649",
    EDGAR: "W7",
    FIFA: "TCA",
    FIPS: "TK",
    GAUL: "251",
    "Geoname ID": 3576916,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TKS",
    "ISO3166-1-Alpha-2": "TC",
    "ISO3166-1-Alpha-3": "TCA",
    "ISO3166-1-numeric": "796",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "TURKS AND CAICOS ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "TCA",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-TC",
    "Least Developed Countries (LDC)": null,
    M49: 796,
    MARC: "tc",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".tc",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "TI",
    is_independent: "Territory of GB",
    official_name_ar: "جزر تركس وكايكوس",
    official_name_cn: "特克斯和凯科斯群岛",
    official_name_en: "Turks and Caicos Islands",
    official_name_es: "Islas Turcas y Caicos",
    official_name_fr: "Îles Turques-et-Caïques",
    official_name_ru: "Острова Тёркс и Кайкос",
  },
  {
    "CLDR display name": "Tuvalu",
    Capital: "Funafuti",
    Continent: "OC",
    DS: "TV",
    "Developed / Developing Countries": "Developing",
    Dial: "688",
    EDGAR: "2G",
    FIFA: "TUV",
    FIPS: "TV",
    GAUL: "252",
    "Geoname ID": 2110297,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TUV",
    "ISO3166-1-Alpha-2": "TV",
    "ISO3166-1-Alpha-3": "TUV",
    "ISO3166-1-numeric": "798",
    "ISO4217-currency_alphabetic_code": "AUD",
    "ISO4217-currency_country_name": "TUVALU",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Australian Dollar",
    "ISO4217-currency_numeric_code": "036",
    ITU: "TUV",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "tvl,en,sm,gil",
    "Least Developed Countries (LDC)": "x",
    M49: 798,
    MARC: "tv",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".tv",
    "UNTERM Arabic Formal": "توفالو",
    "UNTERM Arabic Short": "توفالو",
    "UNTERM Chinese Formal": "图瓦卢",
    "UNTERM Chinese Short": "图瓦卢",
    "UNTERM English Formal": "Tuvalu",
    "UNTERM English Short": "Tuvalu",
    "UNTERM French Formal": "Tuvalu (les) [masc.]",
    "UNTERM French Short": "Tuvalu (les) [masc.]",
    "UNTERM Russian Formal": "Тувалу",
    "UNTERM Russian Short": "Тувалу",
    "UNTERM Spanish Formal": "Tuvalu",
    "UNTERM Spanish Short": "Tuvalu",
    WMO: "TV",
    is_independent: "Yes",
    official_name_ar: "توفالو",
    official_name_cn: "图瓦卢",
    official_name_en: "Tuvalu",
    official_name_es: "Tuvalu",
    official_name_fr: "Tuvalu",
    official_name_ru: "Тувалу",
  },
  {
    "CLDR display name": "Uganda",
    Capital: "Kampala",
    Continent: "AF",
    DS: "EAU",
    "Developed / Developing Countries": "Developing",
    Dial: "256",
    EDGAR: "W9",
    FIFA: "UGA",
    FIPS: "UG",
    GAUL: "253",
    "Geoname ID": 226074,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "UGA",
    "ISO3166-1-Alpha-2": "UG",
    "ISO3166-1-Alpha-3": "UGA",
    "ISO3166-1-numeric": "800",
    "ISO4217-currency_alphabetic_code": "UGX",
    "ISO4217-currency_country_name": "UGANDA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Uganda Shilling",
    "ISO4217-currency_numeric_code": "800",
    ITU: "UGA",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-UG,lg,sw,ar",
    "Least Developed Countries (LDC)": "x",
    M49: 800,
    MARC: "ug",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".ug",
    "UNTERM Arabic Formal": "جمهورية أوغندا",
    "UNTERM Arabic Short": "أوغندا",
    "UNTERM Chinese Formal": "乌干达共和国",
    "UNTERM Chinese Short": "乌干达",
    "UNTERM English Formal": "the Republic of Uganda",
    "UNTERM English Short": "Uganda",
    "UNTERM French Formal": "la République de l'Ouganda",
    "UNTERM French Short": "Ouganda (l') [masc.]",
    "UNTERM Russian Formal": "Республика Уганда",
    "UNTERM Russian Short": "Уганда",
    "UNTERM Spanish Formal": "la República de Uganda",
    "UNTERM Spanish Short": "Uganda",
    WMO: "UG",
    is_independent: "Yes",
    official_name_ar: "أوغندا",
    official_name_cn: "乌干达",
    official_name_en: "Uganda",
    official_name_es: "Uganda",
    official_name_fr: "Ouganda",
    official_name_ru: "Уганда",
  },
  {
    "CLDR display name": "Ukraine",
    Capital: "Kyiv",
    Continent: "EU",
    DS: "UA",
    "Developed / Developing Countries": "Developed",
    Dial: "380",
    EDGAR: "2H",
    FIFA: "UKR",
    FIPS: "UP",
    GAUL: "254",
    "Geoname ID": 690791,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "UKR",
    "ISO3166-1-Alpha-2": "UA",
    "ISO3166-1-Alpha-3": "UKR",
    "ISO3166-1-numeric": "804",
    "ISO4217-currency_alphabetic_code": "UAH",
    "ISO4217-currency_country_name": "UKRAINE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Hryvnia",
    "ISO4217-currency_numeric_code": "980",
    ITU: "UKR",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "uk,ru-UA,rom,pl,hu",
    "Least Developed Countries (LDC)": null,
    M49: 804,
    MARC: "un",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "151",
    "Sub-region Name": "Eastern Europe",
    TLD: ".ua",
    "UNTERM Arabic Formal": "أوكرانيا",
    "UNTERM Arabic Short": "أوكرانيا",
    "UNTERM Chinese Formal": "乌克兰",
    "UNTERM Chinese Short": "乌克兰",
    "UNTERM English Formal": "Ukraine",
    "UNTERM English Short": "Ukraine",
    "UNTERM French Formal": "l'Ukraine",
    "UNTERM French Short": "Ukraine (l') [fém.]",
    "UNTERM Russian Formal": "Украина",
    "UNTERM Russian Short": "Украина",
    "UNTERM Spanish Formal": "Ucrania",
    "UNTERM Spanish Short": "Ucrania",
    WMO: "UR",
    is_independent: "Yes",
    official_name_ar: "أوكرانيا",
    official_name_cn: "乌克兰",
    official_name_en: "Ukraine",
    official_name_es: "Ucrania",
    official_name_fr: "Ukraine",
    official_name_ru: "Украина",
  },
  {
    "CLDR display name": "United Arab Emirates",
    Capital: "Abu Dhabi",
    Continent: "AS",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "971",
    EDGAR: "C0",
    FIFA: "UAE",
    FIPS: "AE",
    GAUL: "255",
    "Geoname ID": 290557,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "UAE",
    "ISO3166-1-Alpha-2": "AE",
    "ISO3166-1-Alpha-3": "ARE",
    "ISO3166-1-numeric": "784",
    "ISO4217-currency_alphabetic_code": "AED",
    "ISO4217-currency_country_name": "UNITED ARAB EMIRATES",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "UAE Dirham",
    "ISO4217-currency_numeric_code": "784",
    ITU: "UAE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-AE,fa,en,hi,ur",
    "Least Developed Countries (LDC)": null,
    M49: 784,
    MARC: "ts",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".ae",
    "UNTERM Arabic Formal": "الإمارات العربية المتحدة",
    "UNTERM Arabic Short": "الإمارات العربية المتحدة",
    "UNTERM Chinese Formal": "阿拉伯联合酋长国",
    "UNTERM Chinese Short": "阿拉伯联合酋长国",
    "UNTERM English Formal": "the United Arab Emirates",
    "UNTERM English Short": "United Arab Emirates (the)",
    "UNTERM French Formal": "les Émirats arabes unis",
    "UNTERM French Short": "Émirats arabes unis (les)",
    "UNTERM Russian Formal": "Объединенные Арабские Эмираты",
    "UNTERM Russian Short": "Объединенные Арабские Эмираты",
    "UNTERM Spanish Formal": "los Emiratos Árabes Unidos",
    "UNTERM Spanish Short": "Emiratos Árabes Unidos (los)",
    WMO: "ER",
    is_independent: "Yes",
    official_name_ar: "الإمارات العربية المتحدة",
    official_name_cn: "阿拉伯联合酋长国",
    official_name_en: "United Arab Emirates",
    official_name_es: "Emiratos Árabes Unidos",
    official_name_fr: "Émirats arabes unis",
    official_name_ru: "Объединенные Арабские Эмираты",
  },
  {
    "CLDR display name": "UK",
    Capital: "London",
    Continent: "EU",
    DS: "GB",
    "Developed / Developing Countries": "Developed",
    Dial: "44",
    EDGAR: null,
    FIFA: "ENG,NIR,SCO,WAL",
    FIPS: "UK",
    GAUL: "256",
    "Geoname ID": 2635167,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "GBR",
    "ISO3166-1-Alpha-2": "GB",
    "ISO3166-1-Alpha-3": "GBR",
    "ISO3166-1-numeric": "826",
    "ISO4217-currency_alphabetic_code": "GBP",
    "ISO4217-currency_country_name":
      "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Pound Sterling",
    "ISO4217-currency_numeric_code": "826",
    ITU: "G",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-GB,cy-GB,gd",
    "Least Developed Countries (LDC)": null,
    M49: 826,
    MARC: "xxk",
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".uk",
    "UNTERM Arabic Formal":
      "المملكة المتحدة لبريطانيا العظمى وآيرلندا الشمالية",
    "UNTERM Arabic Short": "المملكة المتحدة لبريطانيا العظمى وآيرلندا الشمالية",
    "UNTERM Chinese Formal": "大不列颠及北爱尔兰联合王国",
    "UNTERM Chinese Short": "大不列颠及北爱尔兰联合王国",
    "UNTERM English Formal":
      "the United Kingdom of Great Britain and Northern Ireland",
    "UNTERM English Short":
      "United Kingdom of Great Britain and Northern Ireland (the)",
    "UNTERM French Formal":
      "le Royaume-Uni de Grande- Bretagne et d'Irlande du Nord",
    "UNTERM French Short":
      "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    "UNTERM Russian Formal":
      "Соединенное Королевство Великобритании и Северной Ирландии",
    "UNTERM Russian Short":
      "Соединенное Королевство Великобритании и Северной Ирландии",
    "UNTERM Spanish Formal":
      "el Reino Unido de Gran Bretaña e Irlanda del Norte",
    "UNTERM Spanish Short":
      "Reino Unido de Gran Bretaña e Irlanda del Norte (el)",
    WMO: "UK",
    is_independent: "Yes",
    official_name_ar: "المملكة المتحدة لبريطانيا العظمى وآيرلندا الشمالية",
    official_name_cn: "大不列颠及北爱尔兰联合王国",
    official_name_en: "United Kingdom of Great Britain and Northern Ireland",
    official_name_es: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    official_name_fr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    official_name_ru:
      "Соединенное Королевство Великобритании и Северной Ирландии",
  },
  {
    "CLDR display name": "Tanzania",
    Capital: "Dodoma",
    Continent: "AF",
    DS: "EAT",
    "Developed / Developing Countries": "Developing",
    Dial: "255",
    EDGAR: "W0",
    FIFA: "TAN",
    FIPS: "TZ",
    GAUL: "257",
    "Geoname ID": 149590,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "TAN",
    "ISO3166-1-Alpha-2": "TZ",
    "ISO3166-1-Alpha-3": "TZA",
    "ISO3166-1-numeric": "834",
    "ISO4217-currency_alphabetic_code": "TZS",
    "ISO4217-currency_country_name": "TANZANIA, UNITED REPUBLIC OF",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Tanzanian Shilling",
    "ISO4217-currency_numeric_code": "834",
    ITU: "TZA",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sw-TZ,en,ar",
    "Least Developed Countries (LDC)": "x",
    M49: 834,
    MARC: "tz",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".tz",
    "UNTERM Arabic Formal": "جمهورية تنزانيا المتحدة",
    "UNTERM Arabic Short": "جمهورية تنزانيا المتحدة",
    "UNTERM Chinese Formal": "坦桑尼亚联合共和国",
    "UNTERM Chinese Short": "坦桑尼亚联合共和国",
    "UNTERM English Formal": "the United Republic of Tanzania",
    "UNTERM English Short": "United Republic of Tanzania (the)",
    "UNTERM French Formal": "la République-Unie de Tanzanie",
    "UNTERM French Short": "République-Unie de Tanzanie (la)",
    "UNTERM Russian Formal": "Объединенная Республика Танзания",
    "UNTERM Russian Short": "Объединенная Республика Танзания",
    "UNTERM Spanish Formal": "la República Unida de Tanzanía",
    "UNTERM Spanish Short": "República Unida de Tanzanía (la)",
    WMO: "TN",
    is_independent: "Yes",
    official_name_ar: "جمهورية تنزانيا المتحدة",
    official_name_cn: "坦桑尼亚联合共和国",
    official_name_en: "United Republic of Tanzania",
    official_name_es: "República Unida de Tanzanía",
    official_name_fr: "République-Unie de Tanzanie",
    official_name_ru: "Объединенная Республика Танзания",
  },
  {
    "CLDR display name": "U.S. Outlying Islands",
    Capital: null,
    Continent: "OC",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: null,
    EDGAR: "2J",
    FIFA: null,
    FIPS: "FQ,HQ,DQ,JQ,KQ,MQ,BQ,LQ,WQ",
    GAUL: null,
    "Geoname ID": 5854968,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "UM",
    "ISO3166-1-Alpha-3": "UMI",
    "ISO3166-1-numeric": "581",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "UNITED STATES MINOR OUTLYING ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-UM",
    "Least Developed Countries (LDC)": null,
    M49: 581,
    MARC: "ji,xf,wk,uc,up",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "57",
    "Sub-region Name": "Micronesia",
    TLD: ".um",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Territories of US",
    official_name_ar: "نائية التابعة للولايات المتحدة",
    official_name_cn: "美国本土外小岛屿",
    official_name_en: "United States Minor Outlying Islands",
    official_name_es: "Islas menores alejadas de Estados Unidos",
    official_name_fr: "Îles mineures éloignées des États-Unis",
    official_name_ru: "Внешние малые острова Coeдинeнныx Штaтoв",
  },
  {
    "CLDR display name": "U.S. Virgin Islands",
    Capital: "Charlotte Amalie",
    Continent: "NA",
    DS: "USA",
    "Developed / Developing Countries": "Developing",
    Dial: "1-340",
    EDGAR: null,
    FIFA: "VIR",
    FIPS: "VQ",
    GAUL: "258",
    "Geoname ID": 4796775,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ISV",
    "ISO3166-1-Alpha-2": "VI",
    "ISO3166-1-Alpha-3": "VIR",
    "ISO3166-1-numeric": "850",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "VIRGIN ISLANDS (U.S.)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "VIR",
    "Intermediate Region Code": "29",
    "Intermediate Region Name": "Caribbean",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-VI",
    "Least Developed Countries (LDC)": null,
    M49: 850,
    MARC: "vi",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".vi",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "VI",
    is_independent: "Territory of US",
    official_name_ar: "جزر فرجن التابعة للولايات المتحدة",
    official_name_cn: "美属维尔京群岛",
    official_name_en: "United States Virgin Islands",
    official_name_es: "Islas Vírgenes de los Estados Unidos",
    official_name_fr: "Îles Vierges américaines",
    official_name_ru: "Виргинские oстрова Coeдинeнныx Штaтoв",
  },
  {
    "CLDR display name": "US",
    Capital: "Washington",
    Continent: "NA",
    DS: "USA",
    "Developed / Developing Countries": "Developed",
    Dial: "1",
    EDGAR: null,
    FIFA: "USA",
    FIPS: "US",
    GAUL: "259",
    "Geoname ID": 6252001,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "USA",
    "ISO3166-1-Alpha-2": "US",
    "ISO3166-1-Alpha-3": "USA",
    "ISO3166-1-numeric": "840",
    "ISO4217-currency_alphabetic_code": "USD",
    "ISO4217-currency_country_name": "UNITED STATES OF AMERICA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "US Dollar",
    "ISO4217-currency_numeric_code": "840",
    ITU: "USA",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "en-US,es-US,haw,fr",
    "Least Developed Countries (LDC)": null,
    M49: 840,
    MARC: "xxu",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "21",
    "Sub-region Name": "Northern America",
    TLD: ".us",
    "UNTERM Arabic Formal": "الولايات المتحدة الأمريكية",
    "UNTERM Arabic Short": "الولايات المتحدة الأمريكية",
    "UNTERM Chinese Formal": "美利坚合众国",
    "UNTERM Chinese Short": "美利坚合众国",
    "UNTERM English Formal": "the United States of America",
    "UNTERM English Short": "United States of America (the)",
    "UNTERM French Formal": "les États-Unis d'Amérique",
    "UNTERM French Short": "États-Unis d'Amérique (les)",
    "UNTERM Russian Formal": "Соединенные Штаты Америки",
    "UNTERM Russian Short": "Соединенные Штаты Америки",
    "UNTERM Spanish Formal": "los Estados Unidos de América",
    "UNTERM Spanish Short": "Estados Unidos de América (los)",
    WMO: "US",
    is_independent: "Yes",
    official_name_ar: "الولايات المتحدة الأمريكية",
    official_name_cn: "美利坚合众国",
    official_name_en: "United States of America",
    official_name_es: "Estados Unidos de América",
    official_name_fr: "États-Unis d'Amérique",
    official_name_ru: "Соединенные Штаты Америки",
  },
  {
    "CLDR display name": "Uruguay",
    Capital: "Montevideo",
    Continent: "SA",
    DS: "ROU",
    "Developed / Developing Countries": "Developing",
    Dial: "598",
    EDGAR: "X3",
    FIFA: "URU",
    FIPS: "UY",
    GAUL: "260",
    "Geoname ID": 3439705,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "URU",
    "ISO3166-1-Alpha-2": "UY",
    "ISO3166-1-Alpha-3": "URY",
    "ISO3166-1-numeric": "858",
    "ISO4217-currency_alphabetic_code": "UYU",
    "ISO4217-currency_country_name": "URUGUAY",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Peso Uruguayo",
    "ISO4217-currency_numeric_code": "858",
    ITU: "URG",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-UY",
    "Least Developed Countries (LDC)": null,
    M49: 858,
    MARC: "uy",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".uy",
    "UNTERM Arabic Formal": "جمهورية أوروغواي الشرقية",
    "UNTERM Arabic Short": "أوروغواي",
    "UNTERM Chinese Formal": "乌拉圭东岸共和国",
    "UNTERM Chinese Short": "乌拉圭",
    "UNTERM English Formal": "the Eastern Republic of Uruguay",
    "UNTERM English Short": "Uruguay",
    "UNTERM French Formal": "la République orientale de l'Uruguay",
    "UNTERM French Short": "Uruguay (l') [masc.]",
    "UNTERM Russian Formal": "Восточная Республика Уругвай",
    "UNTERM Russian Short": "Уругвай",
    "UNTERM Spanish Formal": "la República Oriental del Uruguay",
    "UNTERM Spanish Short": "Uruguay (el)",
    WMO: "UY",
    is_independent: "Yes",
    official_name_ar: "أوروغواي",
    official_name_cn: "乌拉圭",
    official_name_en: "Uruguay",
    official_name_es: "Uruguay",
    official_name_fr: "Uruguay",
    official_name_ru: "Уругвай",
  },
  {
    "CLDR display name": "Uzbekistan",
    Capital: "Tashkent",
    Continent: "AS",
    DS: "UZ",
    "Developed / Developing Countries": "Developing",
    Dial: "998",
    EDGAR: "2K",
    FIFA: "UZB",
    FIPS: "UZ",
    GAUL: "261",
    "Geoname ID": 1512440,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "UZB",
    "ISO3166-1-Alpha-2": "UZ",
    "ISO3166-1-Alpha-3": "UZB",
    "ISO3166-1-numeric": "860",
    "ISO4217-currency_alphabetic_code": "UZS",
    "ISO4217-currency_country_name": "UZBEKISTAN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Uzbekistan Sum",
    "ISO4217-currency_numeric_code": "860",
    ITU: "UZB",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "uz,ru,tg",
    "Least Developed Countries (LDC)": null,
    M49: 860,
    MARC: "uz",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "143",
    "Sub-region Name": "Central Asia",
    TLD: ".uz",
    "UNTERM Arabic Formal": "جمهورية أوزبكستان",
    "UNTERM Arabic Short": "أوزبكستان",
    "UNTERM Chinese Formal": "乌兹别克斯坦共和国",
    "UNTERM Chinese Short": "乌兹别克斯坦",
    "UNTERM English Formal": "the Republic of Uzbekistan",
    "UNTERM English Short": "Uzbekistan",
    "UNTERM French Formal": "la République d'Ouzbékistan",
    "UNTERM French Short": "Ouzbékistan (l') [masc.]",
    "UNTERM Russian Formal": "Республика Узбекистан",
    "UNTERM Russian Short": "Узбекистан",
    "UNTERM Spanish Formal": "la República de Uzbekistán",
    "UNTERM Spanish Short": "Uzbekistán",
    WMO: "UZ",
    is_independent: "Yes",
    official_name_ar: "أوزبكستان",
    official_name_cn: "乌兹别克斯坦",
    official_name_en: "Uzbekistan",
    official_name_es: "Uzbekistán",
    official_name_fr: "Ouzbékistan",
    official_name_ru: "Узбекистан",
  },
  {
    "CLDR display name": "Vanuatu",
    Capital: "Port Vila",
    Continent: "OC",
    DS: "VU",
    "Developed / Developing Countries": "Developing",
    Dial: "678",
    EDGAR: "2L",
    FIFA: "VAN",
    FIPS: "NH",
    GAUL: "262",
    "Geoname ID": 2134431,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "VAN",
    "ISO3166-1-Alpha-2": "VU",
    "ISO3166-1-Alpha-3": "VUT",
    "ISO3166-1-numeric": "548",
    "ISO4217-currency_alphabetic_code": "VUV",
    "ISO4217-currency_country_name": "VANUATU",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Vatu",
    "ISO4217-currency_numeric_code": "548",
    ITU: "VUT",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "bi,en-VU,fr-VU",
    "Least Developed Countries (LDC)": "x",
    M49: 548,
    MARC: "nn",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": "x",
    "Sub-region Code": "54",
    "Sub-region Name": "Melanesia",
    TLD: ".vu",
    "UNTERM Arabic Formal": "جمهورية فانواتو",
    "UNTERM Arabic Short": "فانواتو",
    "UNTERM Chinese Formal": "瓦努阿图共和国",
    "UNTERM Chinese Short": "瓦努阿图",
    "UNTERM English Formal": "the Republic of Vanuatu",
    "UNTERM English Short": "Vanuatu",
    "UNTERM French Formal": "la République de Vanuatu",
    "UNTERM French Short": "Vanuatu (le) [masc.]",
    "UNTERM Russian Formal": "Республика Вануату",
    "UNTERM Russian Short": "Вануату",
    "UNTERM Spanish Formal": "la República de Vanuatu",
    "UNTERM Spanish Short": "Vanuatu",
    WMO: "NV",
    is_independent: "Yes",
    official_name_ar: "فانواتو",
    official_name_cn: "瓦努阿图",
    official_name_en: "Vanuatu",
    official_name_es: "Vanuatu",
    official_name_fr: "Vanuatu",
    official_name_ru: "Вануату",
  },
  {
    "CLDR display name": "Venezuela",
    Capital: "Caracas",
    Continent: "SA",
    DS: "YV",
    "Developed / Developing Countries": "Developing",
    Dial: "58",
    EDGAR: null,
    FIFA: "VEN",
    FIPS: "VE",
    GAUL: "263",
    "Geoname ID": 3625428,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "VEN",
    "ISO3166-1-Alpha-2": "VE",
    "ISO3166-1-Alpha-3": "VEN",
    "ISO3166-1-numeric": "862",
    "ISO4217-currency_alphabetic_code": "VES",
    "ISO4217-currency_country_name": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Bolívar",
    "ISO4217-currency_numeric_code": "937",
    ITU: "VEN",
    "Intermediate Region Code": "5",
    "Intermediate Region Name": "South America",
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "es-VE",
    "Least Developed Countries (LDC)": null,
    M49: 862,
    MARC: "ve",
    "Region Code": "19",
    "Region Name": "Americas",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "419",
    "Sub-region Name": "Latin America and the Caribbean",
    TLD: ".ve",
    "UNTERM Arabic Formal": "جمهورية فنزويلا البوليفارية",
    "UNTERM Arabic Short": "فنزويلا",
    "UNTERM Chinese Formal": "委内瑞拉玻利瓦尔共和国",
    "UNTERM Chinese Short": "委内瑞拉(玻利瓦尔共和国)",
    "UNTERM English Formal": "the Bolivarian Republic of Venezuela",
    "UNTERM English Short": "Venezuela (Bolivarian Republic of)",
    "UNTERM French Formal": "la République bolivarienne du Venezuela",
    "UNTERM French Short": "Venezuela (République bolivarienne du)",
    "UNTERM Russian Formal": "Боливарианская Республика Венесуэла",
    "UNTERM Russian Short": "Венесуэла (Боливарианская Республика)",
    "UNTERM Spanish Formal": "la República Bolivariana de Venezuela",
    "UNTERM Spanish Short": "Venezuela (República Bolivariana de)",
    WMO: "VN",
    is_independent: "Yes",
    official_name_ar: "فنزويلا (جمهورية - البوليفارية)",
    official_name_cn: "委内瑞拉(玻利瓦尔共和国)",
    official_name_en: "Venezuela (Bolivarian Republic of)",
    official_name_es: "Venezuela (República Bolivariana de)",
    official_name_fr: "Venezuela (République bolivarienne du)",
    official_name_ru: "Венесуэла (Боливарианская Республика)",
  },
  {
    "CLDR display name": "Vietnam",
    Capital: "Hanoi",
    Continent: "AS",
    DS: "VN",
    "Developed / Developing Countries": "Developing",
    Dial: "84",
    EDGAR: "Q1",
    FIFA: "VIE",
    FIPS: "VM",
    GAUL: "264",
    "Geoname ID": 1562822,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "VIE",
    "ISO3166-1-Alpha-2": "VN",
    "ISO3166-1-Alpha-3": "VNM",
    "ISO3166-1-numeric": "704",
    "ISO4217-currency_alphabetic_code": "VND",
    "ISO4217-currency_country_name": "VIET NAM",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "Dong",
    "ISO4217-currency_numeric_code": "704",
    ITU: "VTN",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "vi,en,fr,zh,km",
    "Least Developed Countries (LDC)": null,
    M49: 704,
    MARC: "vm",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "35",
    "Sub-region Name": "South-eastern Asia",
    TLD: ".vn",
    "UNTERM Arabic Formal": "جمهورية فييت نام الاشتراكية",
    "UNTERM Arabic Short": "فييت نام",
    "UNTERM Chinese Formal": "越南社会主义共和国",
    "UNTERM Chinese Short": "越南",
    "UNTERM English Formal": "the Socialist Republic of Viet Nam",
    "UNTERM English Short": "Viet Nam",
    "UNTERM French Formal": "la République socialiste du Viet Nam",
    "UNTERM French Short": "Viet Nam (le)",
    "UNTERM Russian Formal": "Социалистическая Республика Вьетнам",
    "UNTERM Russian Short": "Вьетнам",
    "UNTERM Spanish Formal": "la República Socialista de Viet Nam",
    "UNTERM Spanish Short": "Viet Nam",
    WMO: "VS",
    is_independent: "Yes",
    official_name_ar: "فييت نام",
    official_name_cn: "越南",
    official_name_en: "Viet Nam",
    official_name_es: "Viet Nam",
    official_name_fr: "Viet Nam",
    official_name_ru: "Вьетнам",
  },
  {
    "CLDR display name": "Wallis & Futuna",
    Capital: "Mata Utu",
    Continent: "OC",
    DS: "F",
    "Developed / Developing Countries": "Developing",
    Dial: "681",
    EDGAR: "X8",
    FIFA: "WLF",
    FIPS: "WF",
    GAUL: "266",
    "Geoname ID": 4034749,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "WAF",
    "ISO3166-1-Alpha-2": "WF",
    "ISO3166-1-Alpha-3": "WLF",
    "ISO3166-1-numeric": "876",
    "ISO4217-currency_alphabetic_code": "XPF",
    "ISO4217-currency_country_name": "WALLIS AND FUTUNA",
    "ISO4217-currency_minor_unit": "0",
    "ISO4217-currency_name": "CFP Franc",
    "ISO4217-currency_numeric_code": "953",
    ITU: "WAL",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "wls,fud,fr-WF",
    "Least Developed Countries (LDC)": null,
    M49: 876,
    MARC: "wf",
    "Region Code": "9",
    "Region Name": "Oceania",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "61",
    "Sub-region Name": "Polynesia",
    TLD: ".wf",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: "FW",
    is_independent: "Territory of FR",
    official_name_ar: "جزر واليس وفوتونا",
    official_name_cn: "瓦利斯群岛和富图纳群岛",
    official_name_en: "Wallis and Futuna Islands",
    official_name_es: "Islas Wallis y Futuna",
    official_name_fr: "Îles Wallis-et-Futuna",
    official_name_ru: "Острова Уоллис и Футуна",
  },
  {
    "CLDR display name": "Western Sahara",
    Capital: "El-Aaiun",
    Continent: "AF",
    DS: null,
    "Developed / Developing Countries": "Developing",
    Dial: "212",
    EDGAR: "U5",
    FIFA: "SAH",
    FIPS: "WI",
    GAUL: "268",
    "Geoname ID": 2461445,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "EH",
    "ISO3166-1-Alpha-3": "ESH",
    "ISO3166-1-numeric": "732",
    "ISO4217-currency_alphabetic_code": "MAD",
    "ISO4217-currency_country_name": "WESTERN SAHARA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Moroccan Dirham",
    "ISO4217-currency_numeric_code": "504",
    ITU: "AOE",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar,mey",
    "Least Developed Countries (LDC)": null,
    M49: 732,
    MARC: "ss",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "15",
    "Sub-region Name": "Northern Africa",
    TLD: ".eh",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "In contention",
    official_name_ar: "الصحراء الغربية",
    official_name_cn: "西撒哈拉",
    official_name_en: "Western Sahara",
    official_name_es: "Sáhara Occidental",
    official_name_fr: "Sahara occidental",
    official_name_ru: "Западная Сахара",
  },
  {
    "CLDR display name": "Yemen",
    Capital: "Sanaa",
    Continent: "AS",
    DS: "YAR",
    "Developed / Developing Countries": "Developing",
    Dial: "967",
    EDGAR: "T7",
    FIFA: "YEM",
    FIPS: "YM",
    GAUL: "269",
    "Geoname ID": 69543,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "YEM",
    "ISO3166-1-Alpha-2": "YE",
    "ISO3166-1-Alpha-3": "YEM",
    "ISO3166-1-numeric": "887",
    "ISO4217-currency_alphabetic_code": "YER",
    "ISO4217-currency_country_name": "YEMEN",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Yemeni Rial",
    "ISO4217-currency_numeric_code": "886",
    ITU: "YEM",
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "ar-YE",
    "Least Developed Countries (LDC)": "x",
    M49: 887,
    MARC: "ye",
    "Region Code": "142",
    "Region Name": "Asia",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "145",
    "Sub-region Name": "Western Asia",
    TLD: ".ye",
    "UNTERM Arabic Formal": "الجمهورية اليمنية",
    "UNTERM Arabic Short": "اليمن",
    "UNTERM Chinese Formal": "也门共和国",
    "UNTERM Chinese Short": "也门",
    "UNTERM English Formal": "the Republic of Yemen",
    "UNTERM English Short": "Yemen",
    "UNTERM French Formal": "la République du Yémen",
    "UNTERM French Short": "Yémen (le)",
    "UNTERM Russian Formal": "Йеменская Республика",
    "UNTERM Russian Short": "Йемен",
    "UNTERM Spanish Formal": "la República del Yemen",
    "UNTERM Spanish Short": "Yemen (el)",
    WMO: "YE",
    is_independent: "Yes",
    official_name_ar: "اليمن",
    official_name_cn: "也门",
    official_name_en: "Yemen",
    official_name_es: "Yemen",
    official_name_fr: "Yémen",
    official_name_ru: "Йемен",
  },
  {
    "CLDR display name": "Zambia",
    Capital: "Lusaka",
    Continent: "AF",
    DS: "Z",
    "Developed / Developing Countries": "Developing",
    Dial: "260",
    EDGAR: "Y4",
    FIFA: "ZAM",
    FIPS: "ZA",
    GAUL: "270",
    "Geoname ID": 895949,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ZAM",
    "ISO3166-1-Alpha-2": "ZM",
    "ISO3166-1-Alpha-3": "ZMB",
    "ISO3166-1-numeric": "894",
    "ISO4217-currency_alphabetic_code": "ZMW",
    "ISO4217-currency_country_name": "ZAMBIA",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Zambian Kwacha",
    "ISO4217-currency_numeric_code": "967",
    ITU: "ZMB",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-ZM,bem,loz,lun,lue,ny,toi",
    "Least Developed Countries (LDC)": "x",
    M49: 894,
    MARC: "za",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".zm",
    "UNTERM Arabic Formal": "جمهورية زامبيا",
    "UNTERM Arabic Short": "زامبيا",
    "UNTERM Chinese Formal": "赞比亚共和国",
    "UNTERM Chinese Short": "赞比亚",
    "UNTERM English Formal": "the Republic of Zambia",
    "UNTERM English Short": "Zambia",
    "UNTERM French Formal": "la République de Zambie",
    "UNTERM French Short": "Zambie (la)",
    "UNTERM Russian Formal": "Республика Замбия",
    "UNTERM Russian Short": "Замбия",
    "UNTERM Spanish Formal": "la República de Zambia",
    "UNTERM Spanish Short": "Zambia",
    WMO: "ZB",
    is_independent: "Yes",
    official_name_ar: "زامبيا",
    official_name_cn: "赞比亚",
    official_name_en: "Zambia",
    official_name_es: "Zambia",
    official_name_fr: "Zambie",
    official_name_ru: "Замбия",
  },
  {
    "CLDR display name": "Zimbabwe",
    Capital: "Harare",
    Continent: "AF",
    DS: "ZW",
    "Developed / Developing Countries": "Developing",
    Dial: "263",
    EDGAR: "Y5",
    FIFA: "ZIM",
    FIPS: "ZI",
    GAUL: "271",
    "Geoname ID": 878675,
    "Global Code": "True",
    "Global Name": "World",
    IOC: "ZIM",
    "ISO3166-1-Alpha-2": "ZW",
    "ISO3166-1-Alpha-3": "ZWE",
    "ISO3166-1-numeric": "716",
    "ISO4217-currency_alphabetic_code": "ZWL",
    "ISO4217-currency_country_name": "ZIMBABWE",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Zimbabwe Dollar",
    "ISO4217-currency_numeric_code": "932",
    ITU: "ZWE",
    "Intermediate Region Code": "14",
    "Intermediate Region Name": "Eastern Africa",
    "Land Locked Developing Countries (LLDC)": "x",
    Languages: "en-ZW,sn,nr,nd",
    "Least Developed Countries (LDC)": null,
    M49: 716,
    MARC: "rh",
    "Region Code": "2",
    "Region Name": "Africa",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "202",
    "Sub-region Name": "Sub-Saharan Africa",
    TLD: ".zw",
    "UNTERM Arabic Formal": "جمهورية زمبابوي",
    "UNTERM Arabic Short": "زمبابوي",
    "UNTERM Chinese Formal": "津巴布韦共和国",
    "UNTERM Chinese Short": "津巴布韦",
    "UNTERM English Formal": "the Republic of Zimbabwe",
    "UNTERM English Short": "Zimbabwe",
    "UNTERM French Formal": "la République du Zimbabwe",
    "UNTERM French Short": "Zimbabwe (le)",
    "UNTERM Russian Formal": "Республика Зимбабве",
    "UNTERM Russian Short": "Зимбабве",
    "UNTERM Spanish Formal": "la República de Zimbabwe",
    "UNTERM Spanish Short": "Zimbabwe",
    WMO: "ZW",
    is_independent: "Yes",
    official_name_ar: "زمبابوي",
    official_name_cn: "津巴布韦",
    official_name_en: "Zimbabwe",
    official_name_es: "Zimbabwe",
    official_name_fr: "Zimbabwe",
    official_name_ru: "Зимбабве",
  },
  {
    "CLDR display name": "Åland Islands",
    Capital: "Mariehamn",
    Continent: "EU",
    DS: "FIN",
    "Developed / Developing Countries": "Developed",
    Dial: "358",
    EDGAR: null,
    FIFA: "ALD",
    FIPS: null,
    GAUL: "1242",
    "Geoname ID": 661882,
    "Global Code": "True",
    "Global Name": "World",
    IOC: null,
    "ISO3166-1-Alpha-2": "AX",
    "ISO3166-1-Alpha-3": "ALA",
    "ISO3166-1-numeric": "248",
    "ISO4217-currency_alphabetic_code": "EUR",
    "ISO4217-currency_country_name": "ÅLAND ISLANDS",
    "ISO4217-currency_minor_unit": "2",
    "ISO4217-currency_name": "Euro",
    "ISO4217-currency_numeric_code": "978",
    ITU: null,
    "Intermediate Region Code": null,
    "Intermediate Region Name": null,
    "Land Locked Developing Countries (LLDC)": null,
    Languages: "sv-AX",
    "Least Developed Countries (LDC)": null,
    M49: 248,
    MARC: null,
    "Region Code": "150",
    "Region Name": "Europe",
    "Small Island Developing States (SIDS)": null,
    "Sub-region Code": "154",
    "Sub-region Name": "Northern Europe",
    TLD: ".ax",
    "UNTERM Arabic Formal": null,
    "UNTERM Arabic Short": null,
    "UNTERM Chinese Formal": null,
    "UNTERM Chinese Short": null,
    "UNTERM English Formal": null,
    "UNTERM English Short": null,
    "UNTERM French Formal": null,
    "UNTERM French Short": null,
    "UNTERM Russian Formal": null,
    "UNTERM Russian Short": null,
    "UNTERM Spanish Formal": null,
    "UNTERM Spanish Short": null,
    WMO: null,
    is_independent: "Part of FI",
    official_name_ar: "جزر ألاند",
    official_name_cn: "奥兰群岛",
    official_name_en: "Åland Islands",
    official_name_es: "Islas Åland",
    official_name_fr: "Îles d'Åland",
    official_name_ru: "Аландские острова",
  },
];
