/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./SubAccountAppModel";
import { SubAccountStepperModal } from "./StepperComponent";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { SteppperModal2 } from "./SubAccountStepperModal2";
import { SteppperModal3 } from "./SubAccount";
import { SteppperModal4 } from "./SubAccountStepperModal4";
import { SteppperModal5 } from "./StepperModal5";
import { KTIcon } from "../../../../_metronic/helpers";
import { subAccData, subAccData2 } from "./SubAccData";
import { SuccessConfirmation } from "../Modal/SuccessModalSub";
import { Modal as BootstrapModal } from "bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Api } from "../SubAccountListing";

interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  accountType: string;
  primaryFundId: string;
}

type Props = {
  subData: accDetails | undefined;
  passed: accDetails | undefined;
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

export const CreateSubAccountModal = ({
  subData,
  passed,
  show,
  handleClose,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  const [bodyData, setBodyData] = useState({});

  const navigate = useNavigate();

  // const handleSuccessConfirmation = () => {
  //   const modalElement = document.getElementById("success");
  //   const modal = new BootstrapModal(modalElement as Element);
  //   modal.show();
  // };
  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  subAccData.accountNumber = passed?.accountNumber || "";
  subAccData.accountType = Number(
    (String(passed?.primaryFundId || "").match(/-(\d+)-/) || [])[1] || 0
  );
  subAccData.currency = passed?.currency || "";
  subAccData.subAccountName = data.appBasic.appName;
  subAccData.iconCode = data.appIcon.icon;
  subAccData.goal = data.appBasic.appType;
  subAccData.goalLimit = Number(data.appLimit.limit);
  subAccData.creditCondition = data.appBasi.appType;
  subAccData.creditType = "FIXED";
  subAccData.creditFrequency = data.appFrequency.frequency;
  subAccData.frequencyValue = +data.appAmount.amount;

  subAccData2.accountNumber = passed?.accountNumber || "";
  subAccData2.accountType = 6 || 0;
  subAccData2.currency = passed?.currency || "";
  subAccData2.subAccountName = data.appBasic.appName;
  subAccData2.iconCode = data.appIcon.icon;
  subAccData2.goal = data.appBasic.appType;
  subAccData2.goalLimit = Number(data.appLimit.limit);
  subAccData2.creditCondition = data.appBasi.appType;
  subAccData2.creditType = "PERCENTAGE";
  subAccData2.creditValue = +data.appPercentage.percentage;

  useEffect(() => {
    if (data.appBasi.appType === "ON_CREDIT") {
      setBodyData(subAccData2);
    } else {
      setBodyData(subAccData);
    }
  }, [data.appBasi.appType, subAccData, subAccData2]);


  const resetData = {
   
    appLimit: { limit: "" },
    appIcon: { icon: "" },
    appPercentage: { percentage: "" },
    appFrequency: { frequency: "" },
    appAmount: { amount: "" },
  }

  const createSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/createSubAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ...bodyData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Api.api();
        handleClose();
        updateData(resetData)
        updateData({
          appBasic: {
            appName:"",
            appType: "OPEN",
          },
          appLimit: {
            limit: "",
          }
        })
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Sub Account had been created.",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Error!!",
          text: data?.status?.messageDescription,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appName) {
      return false;
    }
    return true;
  };

  const checkAppIcon = (): boolean => {
    if (!data.appIcon.icon) {
      return false;
    }
    return true;
  };

  const checkAppLimit = (): boolean => {
    if (data.appBasic.appType === "OPEN") {
      return true;
    }

    return false;
  };

  const checkAppLim = (): boolean => {
    if (!data.appLimit.limit) {
      return false;
    }

    return true;
  };

  const checkCredit = (): boolean => {
    if (!data.appPercentage.percentage) {
      return false;
    }
    return true;
  };

  const checkPercentage = (): boolean => {
    if (!data.appFrequency.frequency) {
      return false;
    }
    if (!data.appAmount.amount) {
      return false;
    }

    if (
      data.appBasic.appType === "SCHEDULED" &&
      +data.appAmount.amount !== 0 &&
      +data.appAmount.amount > +data.appLimit.limit
    ) {
      return false;
    }

    return true;
  };

  // const checkAppDataBase = (): boolean => {
  //     if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
  //         return false
  //     }

  //     return true
  // }

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.getCurrentStepIndex() === 4) {
      updateData({
        appBasi: {
          appName: data.appBasi.appName,
          appType: "SCHEDULED",
        },
        appAmount: {
          amount: "",
        },
        appPercentage: {
          percentage: "",
        },
        appFrequency: {
          frequency: "",
        },
      });
    }
    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppIcon()) {
        setHasError(true);
        return;
      }
    }
    if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "OPEN"
    ) {
      if (!checkAppLimit()) {
        setHasError(true);
        return;
      }
    }

    if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "SET_LIMIT"
    ) {
      if (!checkAppLim()) {
        setHasError(true);
        return;
      }
    }
    if (
      stepper.current.getCurrentStepIndex() === 4 &&
      data.appBasi.appType === "ON_CREDIT"
    ) {
      if (!checkCredit()) {
        setHasError(true);
        return;
      }
    }
    if (
      stepper.current.getCurrentStepIndex() === 4 &&
      data.appBasi.appType === "SCHEDULED"
    ) {
      if (!checkPercentage()) {
        setHasError(true);
        return;
      }
    }

    stepper.current.goNext();
  };

  const submit = () => {
    handleClose();
    createSubAccount();
  };

  const handleModalClose = () => {
    updateData(defaultCreateAppData);
    setHasError(false);
    handleClose();
  };
  return (
    <>
      {createPortal(
        <Modal
          id="kt_modal_create_app"
          tabIndex={-1}
          aria-hidden="true"
          dialogClassName="modal-dialog modal-dialog-centered mw-900px"
          show={show}
          onHide={handleModalClose}
          onEntered={loadStepper}

        >
          <div className="modal-header">
            <h2>Add Sub Account</h2>
            {/* begin::Close */}
            <div
              className="btn btn-sm btn-icon btn-active-color-primary text-light text-hover-primary"
              onClick={handleModalClose}
            >
              <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
            </div>
            {/* end::Close */}
          </div>

          <div
            className="modal-body py-lg-10 px-lg-10"
            style={{
              display: authority === 3 ? "flex" : "",
              minHeight: "300px",
              alignItems: authority === 3 ? "center" : "normal",
              justifyContent: authority === 3 ? "center" : "normal",
            }}
          >
            {authority === 3 ? (
              <div>
                <p className="fw-bold text-center text-danger">
                  You don't have access to this feature.
                </p>

                <p className="fw-bold text-center text-danger">
                  Please contact admin.
                </p>
              </div>
            ) : (
              <div
                ref={stepperRef}
                className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
                id="kt_modal_create_app_stepper"
              >
                <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                  {/* begin::Nav*/}
                  <div className="stepper-nav ps-lg-10">
                    {/* begin::Step 1*/}
                    <div
                      className="stepper-item current"
                      data-kt-stepper-element="nav"
                    >
                      {/* begin::Wrapper*/}
                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">1</span>
                        </div>
                        {/* end::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">
                            Enter name of account
                          </h3>

                          {/* <div className='stepper-desc'></div> */}
                        </div>
                        {/* end::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}
                      <div className="stepper-line h-40px"></div>
                      {/* end::Line*/}
                    </div>
                    {/* end::Step 1*/}

                    {/* begin::Step 2*/}
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      {/* begin::Wrapper*/}
                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">2</span>
                        </div>
                        {/* begin::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">Choose the image</h3>

                          {/* <div className='stepper-desc'>Define your app framework</div> */}
                        </div>
                        {/* begin::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}
                      <div className="stepper-line h-40px"></div>
                      {/* end::Line*/}
                    </div>
                    {/* end::Step 2*/}

                    {/* begin::Step 3*/}
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      {/* begin::Wrapper*/}
                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">3</span>
                        </div>
                        {/* end::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">Set your goal</h3>

                          {/* <div className='stepper-desc'>Select the app database type</div> */}
                        </div>
                        {/* end::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}
                      <div className="stepper-line h-40px"></div>
                      {/* end::Line*/}
                    </div>
                    {/* end::Step 3*/}

                    {/* begin::Step 4*/}
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      {/* begin::Wrapper*/}
                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">4</span>
                        </div>
                        {/* end::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">Configure rules</h3>

                          {/* <div className='stepper-desc'>Provide storage details</div> */}
                        </div>
                        {/* end::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}
                      {/* <div className='stepper-line h-40px'></div> */}
                      {/* end::Line*/}
                    </div>

                    <div className="stepper-item" data-kt-stepper-element="nav">
                      {/* begin::Wrapper*/}
                      <div className="stepper-line h-40px"></div>
                      <div className="stepper-wrapper">
                        {/* begin::Icon*/}
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">5</span>
                        </div>
                        {/* begin::Icon*/}

                        {/* begin::Label*/}
                        <div className="stepper-label">
                          <h3 className="stepper-title">Confirmation</h3>

                          {/* <div className='stepper-desc'>Define your app framework</div> */}
                        </div>
                        {/* begin::Label*/}
                      </div>
                      {/* end::Wrapper*/}

                      {/* begin::Line*/}

                      {/* end::Line*/}
                    </div>

                    {/* end::Step 4*/}

                    {/* begin::Step 5*/}
                    {/* <div className='stepper-item' data-kt-stepper-element='nav'> */}
                    {/* begin::Wrapper*/}
                    {/* <div className='stepper-wrapper'> */}
                    {/* begin::Icon*/}
                    {/* <div className='stepper-icon w-40px h-40px'> */}
                    {/* <i className='stepper-check fas fa-check'></i> */}
                    {/* <span className='stepper-number'>5</span> */}
                    {/* </div> */}
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    {/* <div className='stepper-label'> */}
                    {/* <h3 className='stepper-title'></h3> */}

                    {/* <div className='stepper-desc'>Review and Submit</div> */}
                    {/* </div> */}
                    {/* end::Label*/}
                    {/* </div> */}
                    {/* end::Wrapper*/}
                    {/* </div> */}
                    {/* end::Step 5*/}
                  </div>
                  {/* end::Nav*/}
                </div>

                <div className="flex-row-fluid py-lg-5 px-lg-15">
                  {/*begin::Form */}
                  <form noValidate id="kt_modal_create_app_form">
                    <SubAccountStepperModal
                      data={data}
                      updateData={updateData}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                    <SteppperModal2
                      data={data}
                      updateData={updateData}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                    <SteppperModal3
                      data={data}
                      updateData={updateData}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                    <SteppperModal4
                      data={data}
                      updateData={updateData}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                    <SteppperModal5 />

                    {/*begin::Actions */}
                    <div className="d-flex flex-stack pt-10">
                      <div className="me-2">
                        <button
                          type="button"
                          className="btn btn-lg btn-light-primary me-3"
                          data-kt-stepper-action="previous"
                          onClick={prevStep}
                        >
                          <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                          Previous
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-lg btn"
                          data-kt-stepper-action="submit"
                          onClick={submit}
                        >
                          Create{" "}
                          <KTIcon
                            iconName="arrow-right"
                            className="fs-3 ms-2 me-0"
                          />
                        </button>

                        <button
                          type="button"
                          className="btn btn-lg btn"
                          data-kt-stepper-action="next"
                          onClick={nextStep}
                        >
                          Next{" "}
                          <KTIcon
                            iconName="arrow-right"
                            className="fs-3 ms-1 me-0"
                          />
                        </button>
                      </div>
                    </div>
                    {/*end::Actions */}
                  </form>
                  {/*end::Form */}
                </div>
              </div>
            )}
          </div>
        </Modal>,
        modalsRoot
      )}
      <SuccessConfirmation />
    </>
  );
};
