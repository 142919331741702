import { useState, useEffect } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { AccountStmnt } from "../PaymentLinkDashboard";

export function PaymentDetailsModal({ Data }: { Data: AccountStmnt | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  // const getEmployeeDataa = async () => {

  //     try {
  //         const url = `${API_URL}/employee/getEmployeeByCode`;
  //         const headers = {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${token}`
  //           };
  //         const body = JSON.stringify({
  //             "employeeCode": Data?.employeeCode
  //         });

  //         const options = {
  //             method: 'POST',
  //             headers,
  //             body
  //         };

  //         let response = await fetch(url, options);
  //         let data = await response.json();

  //         setViewData(data.message)

  //     } catch (error) {
  //         console.log(error);
  //     }

  // }

  // useEffect(() => {
  //     getEmployeeDataa()
  // }, [Data])

  return (
    <>
      <div
        id="kt_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle"
        data-kt-drawer-close="#kt_modal_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card shadow p-10">
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Name</p>
              <p>{Data?.name}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Currency</p>
              <p>{Data?.currency}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Amount</p>
              <p>
                {Data?.amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Refrence Id</p>
              <p>{Data?.referenceId}</p>
            </div>

            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Created Date</p>
              <p>{Data?.createdDate}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Expiry Date</p>
              <p>{Data?.expiryDate}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Auto Reminder</p>
              <p>{Data?.autoReminder === "true" ? "Yes" : "No"}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Payment Link</p>
              <p>{Data?.paymentLink}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Notes</p>
              <p>{Data?.notes}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Description</p>
              <p>{Data?.description}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Status</p>
              <p
                className={`badge badge-${
                  Data?.status === "created"
                    ? "primary"
                    : Data?.status === "paid"
                    ? "success"
                    : Data?.status === "cancelled"
                    ? "danger"
                    : "secondary"
                }`}
              >
                {Data?.status === "created"
                  ? "Unpaid"
                  : Data?.status === "paid"
                  ? "Paid"
                  : Data?.status === "cancelled"
                  ? "Cancelled"
                  : "Expired"}{" "}
              </p>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
