/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../IAppModels";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "react-drag-drop-files";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
const fileTypes = ["xlsx"];

export const dataOutcome = {
  category: "",
  name: "",
  description: "",
  uploadType: "Upload",
  fileUploadId: "",
  cancel: false,
  fileExist: false,
};

const Step2 = ({ data, hasError, updateData }: StepProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [valid, setValid] = useState(false);
  const [dataList, setDataList] = useState([]);
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const handleFileRemove = (fileToRemove: any) => {
    setFile(null);
    setValid(false);
    updateData({
      fileData: {
        fileData: "",
      },
    });
    dataOutcome.fileExist = false;
  };

  const handleChange = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("bulkFile", uploadedFile);
      formData.append("channel", "WEB");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };
      console.log(requestOptions);

      const url = `${API_URL}/bulk/uploadAndValidateQRCodeFile`;
      // Send the POST request
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status?.statusCode === 0) {
            console.log(result.message);

            setValid(true);
            setDataList(result?.message.validationMsgList);
            dataOutcome.fileUploadId = result?.message?.fileUploadId;
            dataOutcome.fileExist = true;
          } else {
            Swal.fire({
              icon: "warning",
              title: "Failed",
              text: result?.status?.messageDescription,
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          }
        })
        .catch((error) => console.error("error", error));
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <div className="pb-5" data-kt-stepper-element="content">
      {data?.category?.category === "Manual" && (
        <div className="w-100">
          <div className="mb-5">
            <label className="form-label">Enter QR Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter QR Name"
              value={data.name.name}
              onChange={(e) =>
              {
                const value = e.target.value;
                // Regex to allow only alphanumeric characters
                const alphanumericValue = value.replace(/[^a-zA-Z0-9 @_]/g, '');
                updateData({
                  name: {
                    name:alphanumericValue,
                  },
                })
              }
              }
              maxLength={50}
            />
            {!data.name.name && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Name of the QR is required
                </div>
              </div>
            )}
          </div>
          <div className="mb-5">
            <label className="form-label">Enter description</label>
            <input
              type="text"
              className="form-control"
              placeholder="Description"
              value={data.description.description}
              onChange={(e) =>
                updateData({
                  description: {
                    description: e.target.value,
                  },
                })
              }
              maxLength={100}
            />
            {!data.description.description && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Description of the QR is required
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {data?.category?.category === "Batch" && (
        <div className="w-100  mb-20">
          <div className="d-flex flex-column ">
            <a
              href="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/link/qr_code.xlsx"
              className="p-2"
              download="Sample_File.xlsx"
            >
              Download Sample File
            </a>
            {file ? (
              <div>
                <div className="d-flex flex-column gap-5">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed #888",
                      borderRadius: "8px",
                      padding: "20px",
                    }}
                  >
                    <div
                      className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                      style={{ width: "250px" }}
                    >
                      <div className="d-flex flex-row">
                        <p className="fw-bold">{file.size}&nbsp;</p>
                        <span>KB</span>
                      </div>
                      <div className="text-container my-2">
                        <p
                          className="text-truncate"
                          style={{ maxWidth: "15rem" }}
                        >
                          {file.name}
                        </p>
                      </div>
                      <p
                        className="text-danger text-decoration-underline fw-bold cursor-pointer"
                        onClick={handleFileRemove}
                      >
                        Remove
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed #888",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCloudUploadAlt}
                      size="4x"
                      style={{ color: "#888" }}
                    />
                    <p style={{ marginTop: "10px", color: "#888" }}>
                      Drag & drop files here, or click to select files
                    </p>
                  </div>
                </FileUploader>
                {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )}
              </>
            )}
            {valid && (
              <div className="p-5">
                {dataList.map((item, index) => (
                  <div className="p-2" key={index}>
                    <div className="form-check form-check-custom form-check-solid form-check-sm">
                      <input
                        className="form-check-input bg-success"
                        type="checkbox"
                        value=""
                        id={`flexRadioLg${index}`}
                        checked={valid}
                        disabled={!valid}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadioLg${index}`}
                      >
                        {item}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { Step2 };
