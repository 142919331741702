/* eslint-disable jsx-a11y/anchor-is-valid */
import OTPInput from "react-otp-input";
import { StepProps } from "../IAppModels";
import { useEffect, useRef, useState } from "react";

const Step3 = ({
  data,
  updateData,
  hasError,
  txnPin,
  setTxnPin,
}: StepProps) => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  return (
    <>
      <div className="p-5 card shadow-sm" data-kt-stepper-element="content">
        {data.category.category === "Manual" ? (
          <div>
            <div className="text-center mb-5">
              <div className="d-flex justify-content-center position-relative">
                <i className="bi bi-check-circle text-success fs-5x"></i>
              </div>
            </div>
            <div>
              <p className="text-center fs-2">Confirmation</p>
            </div>
            <p className="text-center mb-3">
              Payment link has been delivered successfully
            </p>
            <div className="d-flex justify-content-center mb-3"></div>
          </div>
        ) : (
          <div className="w-100">
            <div className="text-center mt-3">
              <h4>Enter Your PIN</h4>
            </div>
            <div className="text-center mt-3">
              <p>Enter your PIN to confirm</p>
            </div>
            <form>
              <div className="d-flex flex-column text-center mt-5 justify-content-center">
                <OTPInput
                  // {...conversionformik?.getFieldProps("pin")}
                  value={otp.join("")}
                  onChange={(value: string) => {
                    value
                      .split("")
                      .forEach((char, index) => handleChange(char, index));
                  }}
                  numInputs={4}
                  containerStyle={{
                    justifyContent: "center",
                  }}
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      value={otp[index]}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => {
                        const pin = handleChange(e.target.value, index);
                        if (pin.length === 4) {
                          setTxnPin(pin);
                        } else {
                          setTxnPin("");
                        }
                      }}
                      onKeyDown={(e) => {
                        const pin = handleKeyDown(e, index);
                        setTxnPin("");
                      }}
                      type={show ? "password" : "text"}
                      autoFocus={true}
                      disabled={index !== activeInput}
                      onFocus={() => setActiveInput(index)}
                      className="text-center rounded mt-3 border border-1 border-dark"
                      style={{ height: "50px", width: "50px" }}
                    />
                  )}
                />
                <p
                  className="text-center text-decoration-underline cursor-pointer mb-0 mt-2"
                  onClick={() => setShow(!show)}
                >
                  {show ? "Show PIN" : "Hide PIN"}
                </p>
                {!txnPin && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      PIN is required
                    </div>
                  </div>
                )}
              </div>
            </form>
            <br />
          </div>
        )}
      </div>
    </>
  );
};

export { Step3 };
