/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
// import AuthhLayout from "../modules/auth";
import * as authHelper from "../../app/modules/auth/core/AuthHelpers";
import { enhancedFetch } from "../modules/auth/core/_requests";
import { InviteApplication } from "../modules/auth/components/InviteApplication";
import { InvitePassword } from "../modules/auth/components/InvitePassword";
import { InviteMPIN } from "../modules/auth/components/InviteMPIN";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { token, logout, setToken } = useAuth();
  const API_URL = process.env.REACT_APP_WEB_API_URL;


  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route index element={<Navigate to="/dashboard" />} />
          {token ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          <Route path="/team/verify/*" element={<InviteApplication />} />
          <Route path="/new-password" element={<InvitePassword />} />
          <Route path="/new-mpin" element={<InviteMPIN />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
