import { FC, useState } from "react";
import jsPDF from "jspdf";
import { TxnData } from "../../interface/Interface";
import Xfin from "../../../_metronic/assets/Icons/xfin.png";

interface TxnReceiptProps {
  data: TxnData;
}

type ReceiptData = {
  [key: string]: string;
};

export const BillPayReceipt: FC<TxnReceiptProps> = ({ data }) => {
  console.log(data);

  const handleDownloadClick = () => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 20;
        const lineHeight = 10;
        const keyWidth = 50; // Width for the keys

        doc.text("BillPay Receipt", 105, yPosition, { align: "center" });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");

        Object.entries(data?.receipt).forEach(([key, value]) => {
          const keyText = `${key}:`;
          const valueText = value.toString(); // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);
        doc.save("bill-pay-receipt.pdf");
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <div
        id="kt_receipt_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_ereceipt"
        data-kt-drawer-close="#kt_modal_receipt_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_receipt_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-dark p-0">E-Receipt</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body">
            {Object.entries(data?.receipt).map(([key, value]) => (
              <div key={key} className="d-flex justify-content-between mb-5">
                <div>
                  <p>{key}</p>
                </div>
                <div>
                  <p
                    className={`fw-bold badge badge-${
                      key === "Status" && String(value) === "Success"
                        ? "success"
                        : key === "Status" && String(value) === "Failed"
                        ? "danger"
                        : ""
                    }`}
                  >
                    {String(value)}
                  </p>
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn rounded"
                style={{
                  width: "380px",
                }}
                onClick={() => handleDownloadClick()}
              >
                Download E-Receipt
              </button>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
};
