import React, { FC, useEffect, useState } from "react";
import { Card } from "../../MyCard/MyCard";
import { StepProps } from "../IAppModels";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

const Step3 = ({ data, updateData, hasError, reset, setSkip }: StepProps) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [cards, setCards] = useState<Card[]>([]);
  const [selectedCards, setSelectedCards] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedCards([]);
    setSelectAll(false);
  }, [reset]);

  useEffect(() => {
    if (selectedCards.length > 0) setSkip(false);
    else setSkip(true);
  }, [selectedCards]);

  const handleMasterCheckboxChange = () => {
    const allTxnTypeKeys: string[] = cards.map((card) => card.cardNumber);
    setSelectAll(!selectAll);
    const updatedSelectedCards = selectAll ? [] : allTxnTypeKeys;

    setSelectedCards(updatedSelectedCards);

    updateData({
      card: {
        card: updatedSelectedCards,
      },
    });
  };

  const handleCheckboxChange = (key: string) => {
    const updatedSelectedTxnTypes = selectedCards.includes(key)
      ? selectedCards.filter((selectedCard) => selectedCard !== key)
      : [...selectedCards, key];

    const allSelected = selectedCards.length === updatedSelectedTxnTypes.length;
    setSelectAll(allSelected);
    setSelectedCards(updatedSelectedTxnTypes);

    updateData({
      txnType: {
        txnType: updatedSelectedTxnTypes,
      },
    });
  };

  const listCards = async () => {
    try {
      const url = `${API_URL}/card/listCards`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        const nonClosedCards = data?.results?.filter(
          (card: Card) => card?.status !== "Closed"
        );
        setCards(nonClosedCards);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listCards();
  }, []);

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <div className="d-flex flex-row justify-content-between align-items-center mb-10">
          <h2 className="fw-bolder text-dark">Card Details</h2>
          <span className="form-check form-check-custom form-check-solid me-5">
            <input
              className="form-check-input"
              type="checkbox"
              name="accountPlan"
              onChange={() => {
                handleMasterCheckboxChange();
              }}
              checked={selectAll}
            />
          </span>
        </div>

        <div
          className="mb-0 fv-row px-5"
          style={{ height: "330px", overflowY: "auto" }}
        >
          <div className="mb-0">
            {cards.map((card, index) => (
              <label
                className="d-flex flex-stack mb-5 cursor-pointer"
                key={index}
              >
                <span className="d-flex align-items-center me-2">
                  <span className="d-flex flex-column">
                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">
                      {card.cardName}
                    </span>
                    <span className="fs-6 fw-bold text-gray-400">
                      {card?.cardType === 1 ? "Debit Card" : "Prepaid Card"}
                    </span>
                  </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="accountPlan"
                    value={card.cardNumber}
                    onChange={() => {
                      const updatedSelectedCards = selectedCards.includes(
                        card.cardNumber
                      )
                        ? selectedCards.filter(
                            (selectedCard) => selectedCard !== card.cardNumber
                          )
                        : [...selectedCards, card.cardNumber];

                      setSelectedCards(updatedSelectedCards);

                      updateData({
                        card: {
                          card: updatedSelectedCards,
                        },
                      });
                      handleCheckboxChange(card.cardNumber);
                    }}
                    checked={selectedCards.includes(card.cardNumber)}
                  />
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step3 };
