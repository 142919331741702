export const TaxData = {
  employeeCode: "",
  basicMonthlySalary: "",
  basicPensionDeduction: "",
  travelAllowance: "",
  medicalDependants: "",
  otherAllowances: "",
  annualBonus: "",
};

export type Document = {
  tenantId: string;
  documentId: number;
  documentGuid: string;
  documentExtention: string;
  documentName: string;
  documentPath: string;
  fileUploadType: number;
  documentUploadedByType: number;
  documentStatus: number;
  referenceType: number;
  referenceId: string;
  documentTypes: number[];
  cloneDocument: boolean;
};

export type UpdatedDocument = {
  documentExtention: string;
  documentName: string;
  documentTypes: number[];
  documentUploadedByType: number;
  documentStatus: number;
  fileUploadType: number;
  documentData: string;
  tenantId: string;
};

export interface EmployeeData {
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: number;
  emergencyContactIsdCode: number;
  emergencyContactNo: number;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  location: string;
  designation: string;
  department: string;
  bankAccountNumber: string;
  bankCode: string;
  bankName: string;
  documents: (Document | UpdatedDocument)[];
}

export const employeeData: EmployeeData = {
  employeeCode: "",
  firstName: "",
  dob: "",
  address: "",
  isdCode: 91,
  phoneNumber: 0,
  emergencyContactIsdCode: 91,
  emergencyContactNo: 0,
  bloodGroup: "",
  email: "",
  joiningDate: "",
  location: "",
  designation: "",
  department: "",
  bankAccountNumber: "",
  bankCode: "",
  bankName: "",
  documents: [
    {
      tenantId: "eazy_bank",
      documentId: 0,
      documentGuid: "",
      documentExtention: "",
      documentName: "address proof",
      documentPath: "",
      fileUploadType: 1,
      documentUploadedByType: 1,
      documentStatus: 1,
      referenceType: 15,
      referenceId: "",
      documentTypes: [12],
      cloneDocument: false,
    },
    {
      tenantId: "eazy_bank",
      documentId: 0,
      documentGuid: "",
      documentExtention: "",
      documentName: "idproof",
      documentPath: "",
      fileUploadType: 1,
      documentUploadedByType: 1,
      documentStatus: 1,
      referenceType: 15,
      referenceId: "",
      documentTypes: [13],
      cloneDocument: false,
    },
  ],
};

export const taxResults = {
  basicMonthlySalary: 0,
  basicPensionDeduction: 0,
  travelAllowance: 0,
  medicalDependants: 0,
  otherAllowances: 0,
  annualBonus: 0,
  incomeSummary: {
    basicSalaryMonthly: "",
    travelAllowanceMonthly: "",
    otherAllowancesMonthly: "",
    grossPayMonthly: "",
    pensionMonthly: "",
    incomeTaxMonthly: "",
    uifMonthly: "",
    totalDeductionsMonthly: "",
    netPayMonthly: "",
    basicSalary: "",
    travelAllowance: "",
    otherAllowances: "",
    bonus: "",
    totalTaxableIncome: "",
    pension: "",
    taxableIncome: "",
    totalIncomeTax: "",
    taxRebate: "",
    medicalTaxCredits: "",
    annualTaxPayable: "",
  },
};
