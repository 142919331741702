import { useState, ChangeEvent, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { useAuth } from "../../modules/auth";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import salaryPng from "../../../_metronic/assets/Icons/imgpsh_fullsize_anim.png";
import { PayrollModal } from "./PayrollStepper/PayrollModal";
import { useNavigate } from "react-router-dom";
import { PayrollDetailsModal } from "./PayrollDetails/PayrollDetails";
import deleteIcon from "../../../_metronic/assets/Icons/Group.png";
import { DeleteConfirmation } from "./Modal/DeleteConfirmation";
import { DeactivateModal } from "./Modal/DeactivateModal";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import { EditPayrollModal } from "./PayrollEditStepper/EditPayrollModal";

interface MyData {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  empStatus: number;
  netPayMonthly: string;
}

interface statData {
  payrollGeneratedAmount: number;
  payrollPaidAmount: number;
  allCount: number;
}
export interface TaxResults {
  basicSalaryMonthly: string;
  travelAllowanceMonthly: string;
  otherAllowancesMonthly: string;
  grossPayMonthly: string;
  pensionMonthly: string;
  incomeTaxMonthly: string;
  uifMonthly: string;
  totalDeductionsMonthly: string;
  netPayMonthly: string;
  basicSalary: string;
  travelAllowance: string;
  otherAllowances: string;
  bonus: string;
  totalTaxableIncome: string;
  pension: string;
  taxableIncome: string;
  totalIncomeTax: string;
  taxRebate: string;
  medicalTaxCredits: string;
  annualTaxPayable: string;
}

export const Api = {
  api: (): void => {},
};

export default function PayrollDashboard() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<MyData[]>([]);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<MyData | null>(null);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [DeleteData, setDeleteData] = useState("");
  const [Activate, setActivate] = useState(0);
  const [empCode, setEmpCode] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("Active");
  const [statistics, setStatistics] = useState<statData | null>(null);
  const [incSummary, setIncSummary] = useState<TaxResults | null>(null);

  const datemodalRef = useRef<HTMLDivElement | null>(null);

  const handleSummaryClick = async (employeeCode: string) => {
    const modalElement = document.getElementById("summary");
    const modal = new Modal(modalElement as Element);
    modal.show();
    const getEmployeeDataa = async () => {
      try {
        const url = `${API_URL}/employee/getEmployeeByCode`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          employeeCode: employeeCode,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        setIncSummary(data?.message?.incomeSummary);
        // setViewData(data.message);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployeeDataa();
  };

  const [showEditCreateAppModal, setShowEditCreateAppModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const tooltipAddEmployee = (
    <Tooltip id="tooltip-add-money">Add Employee</Tooltip>
  );

  const tooltipPaysalary = (
    <Tooltip id="tooltip-arrow-right">Pay Salary</Tooltip>
  );

  const tooltipEdit = <Tooltip id="tooltip-add-money">Edit</Tooltip>;
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;
  const tooltipThreeDots = <Tooltip id="tooltip-three-dots">Settings</Tooltip>;

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = data?.filter((employee) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Active":
          isTabMatched = employee.empStatus === 1;
          break;
        case "Inactive":
          isTabMatched = employee.empStatus === 2;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const isSearchMatched =
        employee.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.employeeCode
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        employee.joiningDate.toString().includes(searchQuery.toLowerCase()) ||
        employee.netPayMonthly
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        employee.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [data, searchQuery, selectedTab]);

  // const PayRollURL = `${API_URL}/employee/getAllEmployees`

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const handleEditClick = (item: MyData) => {
    setSelectedData({ ...item });
    setShowEditCreateAppModal(true);
  };
  
  const handleActivateClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    const newEmpCode = clickedData.employeeCode;
    const newActivate = clickedData.empStatus;

    setEmpCode(newEmpCode);
    setActivate(newActivate);

    const ActivateItem = async () => {
      try {
        const url = `${API_URL}/employee/modifyEmployeeStatus`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          employeeCode: clickedData.employeeCode,
          empStatus: clickedData.empStatus === 1 ? 2 : 1,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        const isActive =
          clickedData.empStatus === 1 ? "DeActivated" : "Activated";
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation!!",
            text: "Employee " + isActive,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          PayeeData();
        }
      } catch (error) {
        console.log(error);
      }
    };

    ActivateItem();
  };

  const handleDeleteClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    setDeleteData(() => clickedData.employeeCode);

    const deleteItem = async () => {
      try {
        const url = `${API_URL}/employee/delete`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          employeeCode: clickedData.employeeCode,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation!!",
            text: "The selected employee has been deleted",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          PayeeData();
        }
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete the selected employee?",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      }
    });
  };

  const handleDetailsClick = (item:MyData) => {
    setSelectedData({ ...item });
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const filteredData = data?.filter((item) => item.empStatus == 1);
  const totalCount = filteredData?.length || 0;

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "Active") {
      const filteredData = data.filter((transaction) =>
        tab === "Active" ? transaction.empStatus === 1 : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Inactive") {
      const filteredData = data.filter((transaction) =>
        tab === "Inactive" ? transaction.empStatus === 2 : ""
      );
      setFilteredTransactions(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const PayeeData = async () => {
    try {
      const url = `${API_URL}/employee/getAllEmployees`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setData(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Api.api = PayeeData;
    PayeeData();
  }, []);

  const PayrollStatistics = async () => {
    try {
      const url = `${API_URL}/payroll/getPayrollStatistics`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setStatistics(data?.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    PayrollStatistics();
  }, []);

  return (
    <>
      <div>
        <h4 className="mb-4">Payroll Dashboard</h4>
        <div className="card shadow-sm p-5">
          <div className="d-flex gap-8">
            <div
              className="card shadow-sm d-flex flex-grow-1 p-3 bg-gray-300"
              style={{ maxWidth: "450px", minWidth: "450px" }}
            >
              <div className="card-body p-10" style={{ marginLeft: "20px" }}>
                <div className="d-flex flex-column gap-5 ">
                  <div>
                    <p
                      className="p-0 m-0 text-dark mb-5"
                      style={{ fontWeight: "bold" }}
                    >
                      Active Employees{" "}
                      <span
                        className="text-dark-200"
                        style={{ fontWeight: "normal" }}
                      ></span>
                    </p>
                    <h4
                      className="p-0 m-0 text-dark mb-2"
                      style={{ fontSize: "35px" }}
                    >
                      {totalCount}
                    </h4>
                  </div>
                  <div className="d-flex gap-5">
                    <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipAddEmployee}
                      >
                        <div
                          className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                          style={{ height: "30px", width: "30px" }}
                          onClick={() => setShowCreateAppModal(true)}
                        >
                          <span className="svg-icon svg-icon-1">
                            <i className="bi bi-plus-lg  text-light text-hover-dark"></i>
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Authorize>
                    <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipPaysalary}
                      >
                        <div
                          className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                          style={{ height: "30px", width: "30px" }}
                          onClick={() => {
                            navigate("/payroll-paysalary");
                          }}
                        >
                          <span className="svg-icon svg-icon-1">
                            <i className="bi bi-currency-dollar text-light text-hover-dark"></i>
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Authorize>
                    <Authorize hasAnyPermission={["MENU_PAYROLL|VIEW"]}>
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipThreeDots}
                      >
                        <div
                          className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                          style={{ height: "30px", width: "30px" }}
                          onClick={() => {
                            navigate("/payroll-settings");
                          }}
                        >
                          <span className="svg-icon svg-icon-1">
                            <i className="bi bi-three-dots text-light text-hover-dark"></i>
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Authorize>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card shadow-sm d-flex flex-grow-1"
              style={{ width: "100%" }}
            >
              <div className="card-body p-5 flex-grow-1 p-0 m-0">
                <div className="d-flex justify-content-between mb-3 flex-grow-1">
                  <h4 className="p-0 m-0">Payroll Life Time</h4>
                </div>
                <br />
                <div className="w-100 d-flex flex-row gap-7">
                  <div className=" card shadow-sm bg-info w-100  p-10 ">
                    <div className="d-flex justify-content-start">
                      <h1 className="text-light fs-2x fw-bolder">
                        {statistics?.allCount}
                      </h1>
                      <br />
                      <br />
                    </div>
                    <div className="d-flex justify-content-start">
                      <p className="text-light fs-7 "> Employees Till Date</p>
                    </div>
                  </div>
                  <div className=" card shadow-sm bg-primary w-100  p-10 ">
                    <div className="d-flex justify-content-start">
                      <h1 className="text-light fs-2x fw-bolder">
                        {statistics?.payrollPaidAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        <span className="fs-9">({baseCurrency})</span>
                      </h1>
                      <br />
                      <br />
                    </div>
                    <div className="d-flex justify-content-start">
                      <p className="text-light fs-7"> Payouts Till Date</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="card shadow">
              <div className="py-2 card p-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                      <li className="nav-item" key={1}>
                        <a
                          className="nav-link active text-active-gray-800"
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_1"
                          onClick={() => handleTabClick("Active")}
                        >
                          Active
                        </a>
                      </li>
                      <li className="nav-item" key={2}>
                        <a
                          className="nav-link text-active-gray-800"
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_2"
                          onClick={() => handleTabClick("Inactive")}
                        >
                          Inactive
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex align-items-center p-3 gap-10">
                    <div className="d-flex align-items-center  position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        value={searchQuery}
                        onChange={handleSearchChange}
                        type="text"
                        data-kt-docs-table-filter="search"
                        className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                        placeholder="Search employee"
                      />
                    </div>
                  </div>
                </div>

                {/* <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} /> */}

                <div className="tab-content " id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="kt_tab_pane_1"
                    role="tabpanel"
                  >
                    <>
                      <div className="card p-2">
                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                          <thead className="bg-gray-200">
                            <tr className="text-start text-dark-600 fw-bold fs-6  ">
                              <th className="min-w-100px p-5">ID</th>
                              <th className="min-w-100px p-5">Employee Name</th>

                              <th className="min-w-100px p-5 text-nowrap">
                                Date
                              </th>
                              <th className="min-w-100px p-5 text-nowrap">
                                Monthly Salary
                              </th>
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                              >
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                              </Authorize>
                              <Authorize
                                hasAnyPermission={[
                                  "MENU_PAYROLL|EDIT",
                                  "MENU_PAYROLL|DELETE",
                                ]}
                              >
                                <th className="min-w-100px p-5 text-nowrap">
                                  Action
                                </th>
                              </Authorize>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData && currentData.length > 0 ? (
                              currentData?.map((item, index) => {
                                // Initialize the checked state based on the condition
                                const isChecked = item.empStatus === 1;

                                if (isChecked) {
                                  return (
                                    <tr
                                      key={index}
                                      onMouseOver={(e) => {
                                        const target =
                                          e.currentTarget as HTMLElement;
                                        target.style.backgroundColor =
                                          "#f1f1f2";
                                        // target.style.transform =
                                        //   "translate3d(6px, -6px, 0)";
                                        // target.style.boxShadow =
                                        //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                      }}
                                      onMouseOut={(e) => {
                                        const target =
                                          e.currentTarget as HTMLElement;
                                        target.style.backgroundColor = "white";
                                        // target.style.transform = "none";
                                        // target.style.boxShadow = "none";
                                      }}
                                    >
                                      <td
                                        className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDetailsClick(item)
                                        }
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        data-bs-dismiss="click"
                                        data-bs-trigger="hover"
                                        id="kt_modal_toggle_payroll"
                                      >
                                        {item.employeeCode}
                                      </td>
                                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                        {item.firstName}
                                      </td>
                                      <td className="p-5 text-gray-600">
                                        {new Date(
                                          item.joiningDate
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </td>
                                      <td className="p-5 text-gray-600">
                                        {/* {item.netPayMonthly} */}
                                        {baseCurrency}{" "}
                                        {Number(
                                          item.netPayMonthly
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                        <i
                                          className="bi bi-info-circle ms-5 text-dark cursor-pointer"
                                          onClick={() => {
                                            handleSummaryClick(
                                              item.employeeCode
                                            );
                                          }}
                                        ></i>
                                      </td>
                                      <Authorize
                                        hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                                      >
                                        <td className="p-5 text-gray-600">
                                          <div className="form-check form-switch form-check-custom form-check-solid">
                                            <input
                                              className="form-check-input h-20px w-30px cursor-pointer"
                                              type="checkbox"
                                              id={`flexSwitchDefault-${index}`}
                                              checked={isChecked} // Use the initialized isChecked value
                                              style={{
                                                backgroundColor: "#0000FF",
                                              }}
                                              onChange={() => {
                                                handleActivateClick(index);
                                              }}
                                            />
                                          </div>
                                        </td>
                                      </Authorize>
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_PAYROLL|DELETE",
                                          "MENU_PAYROLL|EDIT",
                                        ]}
                                      >
                                        <td className="p-5 text-gray-600 p-2">
                                          <div className="d-flex gap-10">
                                            <Authorize
                                              hasAnyPermission={[
                                                "MENU_PAYROLL|EDIT",
                                              ]}
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={tooltipEdit}
                                              >
                                                <i
                                                  className="bi bi-pencil-fill cursor-pointer "
                                                  onClick={() =>
                                                    handleEditClick(item)
                                                  }
                                                  style={{ color: "#0000ff" }}
                                                ></i>
                                              </OverlayTrigger>
                                            </Authorize>
                                            <Authorize
                                              hasAnyPermission={[
                                                "MENU_PAYROLL|DELETE",
                                              ]}
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={tooltipDelete}
                                              >
                                                <i
                                                  className="bi bi-trash cursor-pointer "
                                                  onClick={() => {
                                                    handleDeleteClick(index);
                                                  }}
                                                  style={{ color: "#FF0000" }}
                                                ></i>
                                              </OverlayTrigger>
                                            </Authorize>
                                          </div>
                                        </td>
                                      </Authorize>
                                    </tr>
                                  );
                                } else {
                                  return null; // Skip rendering the row for other empStatus values
                                }
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                    {/* <TransferModal Data={selectedData} /> */}
                    {/* <DeleteModal /> */}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="kt_tab_pane_2"
                    role="tabpanel"
                  >
                    <>
                      <div className="card p-2">
                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                          <thead className="bg-gray-200">
                            <tr className="text-start text-dark-600 fw-bold fs-6  ">
                              <th className="min-w-100px p-5">ID</th>
                              <th className="min-w-100px p-5">Employee Name</th>

                              <th className="min-w-100px p-5 text-nowrap">
                                DOJ
                              </th>
                              <th className="min-w-100px p-5 text-nowrap">
                                Monthly Salary
                              </th>
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                              >
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                              </Authorize>
                              <Authorize
                                hasAnyPermission={[
                                  "MENU_PAYROLL|EDIT",
                                  "MENU_PAYROLL|DELETE",
                                ]}
                              >
                                <th className="min-w-100px p-5 text-nowrap">
                                  Action
                                </th>
                              </Authorize>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData && currentData.length > 0 ? (
                              currentData?.map((item, index) => {
                                if (item.empStatus === 2) {
                                  return (
                                    <tr
                                      key={index}
                                      onMouseOver={(e) => {
                                        const target =
                                          e.currentTarget as HTMLElement;
                                        target.style.backgroundColor =
                                          "#f1f1f2";
                                        // target.style.transform =
                                        //   "translate3d(6px, -6px, 0)";
                                        // target.style.boxShadow =
                                        //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                      }}
                                      onMouseOut={(e) => {
                                        const target =
                                          e.currentTarget as HTMLElement;
                                        target.style.backgroundColor = "white";
                                        // target.style.transform = "none";
                                        // target.style.boxShadow = "none";
                                      }}
                                    >
                                      <td
                                        className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDetailsClick(item)
                                        }
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        data-bs-dismiss="click"
                                        data-bs-trigger="hover"
                                        id="kt_modal_payroll_toggle"
                                      >
                                        {item.employeeCode}
                                      </td>
                                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                        {item.firstName}
                                      </td>
                                      <td className="p-5 text-gray-600">
                                        {new Date(
                                          item.joiningDate
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </td>
                                      <td className="p-5 text-gray-600">
                                        {baseCurrency}{" "}
                                        {Number(
                                          item.netPayMonthly
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </td>
                                      <Authorize
                                        hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                                      >
                                        <td className="p-5 text-gray-600">
                                          <div className="form-check form-switch form-check-custom form-check-solid">
                                            <input
                                              className="form-check-input h-20px w-30px cursor-pointer"
                                              type="checkbox"
                                              id="flexSwitchDefault"
                                              checked={!item.empStatus}
                                              onChange={() => {
                                                handleActivateClick(index);
                                              }}
                                            />
                                          </div>
                                        </td>
                                      </Authorize>
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_PAYROLL|DELETE",
                                          "MENU_PAYROLL|EDIT",
                                        ]}
                                      >
                                        <td className="p-5 text-gray-600 p-2">
                                          <div className="d-flex gap-10">
                                            <Authorize
                                              hasAnyPermission={[
                                                "MENU_PAYROLL|EDIT",
                                              ]}
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={tooltipEdit}
                                              >
                                                <i
                                                  className="bi bi-pencil-fill cursor-pointer "
                                                  onClick={() =>
                                                    handleEditClick(item)
                                                  }
                                                  style={{ color: "#0000ff" }}
                                                ></i>
                                              </OverlayTrigger>
                                            </Authorize>
                                            <Authorize
                                              hasAnyPermission={[
                                                "MENU_PAYROLL|DELETE",
                                              ]}
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={tooltipDelete}
                                              >
                                                <i
                                                  className="bi bi-trash cursor-pointer "
                                                  onClick={() => {
                                                    handleDeleteClick(index);
                                                  }}
                                                  style={{ color: "#FF0000" }}
                                                ></i>
                                              </OverlayTrigger>
                                            </Authorize>
                                          </div>
                                        </td>
                                      </Authorize>
                                    </tr>
                                  );
                                } else {
                                  return null; // Skip rendering the row for other empStatus values
                                }
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex={-1}
        id="summary"
        ref={datemodalRef}
          
      >
        <div
          className="modal-dialog "
          style={{ margin: "0 auto", marginTop: "3%" }}
        >
          <div className="modal-content ">
            <div className="modal-head d-flex flex-row align-items-center pt-4 pb-0 px-7 justify-content-between">
              <h4>Salary Breakup</h4>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary text-hover-primary"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div className="modal-body p-8">
              <div
                className="card shadow p-10"
                style={{
                  height: "610px",
                  overflow: "auto",
                  width: "450px",
                }}
              >
                {/* <h5>Earnings & Deductions</h5> */}

                <div className="p-5">
                  <div className="d-flex fw-bold justify-content-between">
                    <div className="w-50">
                      <p>Taxable Earnings</p>
                    </div>
                    <div>
                      <p>{baseCurrency}</p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Basic Salary</p>
                    </div>
                    <div>
                      <p>
                        {parseFloat(
                          incSummary?.basicSalary || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Travel Allowance (only 80%)</p>
                    </div>
                    <div>
                      <p>
                        {parseFloat(
                          incSummary?.travelAllowance || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p> Other Allowances</p>
                    </div>
                    <div>
                      <p>
                        {parseFloat(
                          incSummary?.otherAllowances || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Annual Bonus</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(incSummary?.bonus || "0")?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Total Taxable Earnings</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.totalTaxableIncome || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Less : Taxable Pension</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(incSummary?.pension || "0")?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex fw-bold justify-content-between">
                    <div className="w-50 ">
                      <p>Taxable Income</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.taxableIncome || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Taxable Taxation</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.totalIncomeTax || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Less : Rebate</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(incSummary?.taxRebate || "0")?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Less : Medical Tax Credits</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.medicalTaxCredits || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex fw-bold justify-content-between">
                    <div className="w-50">
                      <p>Annual Tax Payable</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.annualTaxPayable || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  {/* <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Income Tax Percentage</p>
                    </div>
                    <div>
                      <p>0.0%</p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" /> */}
                </div>

                <h4 className="ps-5">
                  {" "}
                  View your monthly salary calculation results
                </h4>
                <div className="p-5">
                  <div className="d-flex justify-content-between">
                    <div className="w-50 fw-bold">
                      <p>Earnings</p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Basic Salary</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.basicSalaryMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Travel Allowance</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.travelAllowanceMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Other Allowance</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.otherAllowancesMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex fw-bold justify-content-between m-0">
                    <div className="w-50 ">
                      <p>Gross Pay</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.grossPayMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-4" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 fw-bold">
                      <p>Deductions</p>
                    </div>
                    {/* <div>
                      <p>{baseCurrency}</p>
                    </div> */}
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Income Tax</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.incomeTaxMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Unemployment Insurance (UIF)</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.uifMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Pension Fund</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.pensionMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Total Deductions</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.totalDeductionsMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 mb-2" />
                  <div className="d-flex fw-bold justify-content-between">
                    <div className="w-50">
                      <p>Net Monthly Pay</p>
                    </div>
                    <div>
                      <p>
                        {parseInt(
                          incSummary?.netPayMonthly || "0"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <h4 className="ps-5"> View your annual bonus payment</h4>
                <div className="p-5">
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Annual Bonus</p>
                    </div>
                    <div>
                      <p>{incSummary?.bonus}</p>
                    </div>
                  </div> */}
                {/* <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Less Tax On Bonus</p>
                    </div>
                    <div>
                      <p>{incSummary?.bonus}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50">
                      <p>Less UIF On Bonus</p>
                    </div>
                    <div>
                      <p>{incSummary?.uifMonthly}</p>
                    </div>
                  </div> */}
                {/* <hr className="m-0 mb-2" />
                  <div className="d-flex fw-bold justify-content-between">
                    <div className="w-50">
                      <p>Net Annual Bonus</p>
                    </div>
                    <div>
                      <p>{incSummary?.bonus}</p>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PayrollDetailsModal Data={selectedData} />
      <PayrollModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
      <EditPayrollModal
        show={showEditCreateAppModal}
        handleClose={() => setShowEditCreateAppModal(false)}
        Data={selectedData}
      />
    </>
  );
}
