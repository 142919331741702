import { Link, useLocation, useNavigate } from "react-router-dom";
import forgotImg from "../../../../_metronic/assets/Icons/forgot.jpeg";
import PinInput from "react-pin-input";
import { useEffect, useRef, useState } from "react";
import { OtpResponse, VerifyOtpResponse } from "../../../interface/Interface";
import { enhancedFetch } from "../core/_requests";
import OTPInput from "react-otp-input";

const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
const token = tokenData ? tokenData.token : null;

export function OtpScreen() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState(false);
  const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);
  const [timer, setTimer] = useState(55);

  useEffect(() => {
    if (timer > 0) {
      const myInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [timer]);


  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 6) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }

    }
    else if (e.key === "Enter") {
      hanleVerify();
    }
    return pin;
  };

  const verifyOtp = async (
    mobile: string,
    otp: string,
    channel: string,
    otpType: string,
    isdCode?: number
  ): Promise<VerifyOtpResponse> => {
    try {
      const url = `${API_URL}/verifyOtp`;
      const headers = {
        "Content-Type": "application/json",
        tenant_id: "eazy_bank",
        app_id: appId,
      };

      const body = JSON.stringify({ mobile, isdCode, otp, channel, otpType });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setPin("");
      setOtp(["", "", "", "", "", ""]);
      setActiveInput(0);
      return data?.message as VerifyOtpResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  // const responseData: OtpResponse = location.state?.responseData
  const responseData: OtpResponse = (
    location.state as { responseData: OtpResponse }
  )?.responseData;
  const appId = (location.state as { appId: string })?.appId;

  const hanleVerify = async () => {
    // Reset any previous error message
    setPinErrorMessage(null);

    if (pin.length !== 6) {
      setPinErrorMessage("Please enter a complete 6-digit OTP.");
      return; // Don't proceed to the verification if PIN is incomplete
    }
    let response;
    try {
      response = await verifyOtp(
        responseData.mobile,
        pin,
        responseData.channel,
        responseData.otpType,
        responseData.isdCode
      );
    } catch (error) {
      console.log(error);
      setPinErrorMessage("Invalid  OTP.");
    }

    if (response && response.token) {
      navigate("newPasswordScreen", { state: { responseData: response } });
    } else {
      setPinErrorMessage("Invalid  OTP.");
    }
  };


  const handleResend = () => {
    setTimer(55);
  };

  return (
    <>
      <div className="" style={{ padding: "0px" }}>
        <div className="d-flex justify-content-center">
          <p
            className="p-0 mb-10 "
            style={{ fontWeight: "800", fontSize: "20px" }}
          >
            Forgot Password
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center ">
          <img
            src={forgotImg}
            className=""
            alt=""
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center mt-12 "
          style={{ fontSize: "16px" }}
        >
          <p>
            OTP has been sent to{" "}
            {responseData.isdCode + " " + responseData.mobile}
          </p>
        </div>
        <form action="" autoComplete="off">
          <div className="d-flex justify-content-center">
            <OTPInput
              value={otp.join("")}
              onChange={(value: string) => {
                value
                  .split("")
                  .forEach((char, index) => handleChange(char, index));
              }}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  value={otp[index]}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => {
                    handleChange(e.target.value, index);
                    setPinErrorMessage(null);
                    // conversionformik.setFieldValue("pin", pin);
                    // setPassword(pin);
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(e, index);
                    // conversionformik.setFieldValue("pin", pin);
                    // setPassword(pin);
                  }}
                  type={show ? "password" : "text"}
                  autoFocus={true}
                  disabled={index !== activeInput}
                  onFocus={() => setActiveInput(index)}
                  className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                  style={{ height: "50px", width: "50px" }}
                />
              )}
            />
          </div>
        </form>
        <p
          className="text-center cursor-pointer text-deoration-underline mt-2 mb-0"
          onClick={() => setShow(!show)}
        >
          {show ? "Show OTP" : "Hide OTP"}
        </p>
        {pinErrorMessage && (
          <p className="text-danger ms-7 mt-5">{pinErrorMessage}</p>
        )}
        {pinError && <p className="text-danger">Inavlid Pin</p>}

        <div className="mt-10 " style={{ textAlign: "center" }}>
          {timer === 0 ? (
            <span
              className="text-primary cursor-pointer"
              onClick={handleResend}
            >
              Resend
            </span>
          ) : (
            <span>
              {" "}
              Resend code in <span className="text-primary">
                {timer}
              </span>{" "}
              seconds
            </span>
          )}
        </div>
        <div className="mt-10">
          <button
            className="p-5 border-0 fs-4 w-100"
            style={{
              borderRadius: "50px",
              color: "#ffff",
              backgroundColor: "#246BFD",

              fontWeight: "bolder",
            }}
            onClick={() => {
              hanleVerify();
            }}
          >
            Verify
          </button>
        </div>
      </div>
    </>
  );
}
