import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Category } from "./VideoLibrary";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import VideoComponent from "./VideoComponent";

export const ApiData = {
  api: (): void => {},
};

const VideoListing = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [url, setUrl] = useState("");
  const [iframeVisible, setIframeVisible] = useState(false);
  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const imageModalRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const videos: Category = (location.state as { responseData: Category })
    ?.responseData;

  function handleModalClose() {
    clearVideoSrc();
    const modalElement = imageModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  }

  function clearVideoSrc() {
    setUrl("");
    setIframeVisible(false);
    if (videoRef.current) {
      videoRef.current.src = "";
    }
  }

  const handleViewClick = (url: string) => {
    clearVideoSrc(); // Clear the current video source
    setTimeout(() => {
      setUrl(url);
      const modalElement = document.getElementById("image_modal");
      const modal = new Modal(modalElement as Element);
      modal.show();
      setIframeVisible(true);
    }, 0); // Set a timeout of 0 to ensure the iframe src is cleared before setting the new URL
  };

  const videoCount = async (videoGuid: string) => {
    try {
      const url = `${API_URL}/videoLib/incrementVideoViewCount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        videoGuid: videoGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      console.log(data.status.statusCode);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <h4 className="mb-5">Video Library</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div className="icon card d-flex flex-column m-5 shadow-sm p-5">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <p className="fs-3 fw-bold">{videos?.categoryName}</p>
              </div>
              <div className="d-flex align-items-center">
                <p
                  className="text-primary cursor-pointer"
                  onClick={() => navigate("/helpdesk/video-library")}
                >
                  Go Back
                </p>
              </div>
            </div>

            <div className="d-flex flex-wrap justify-content-start">
              {videos?.videos?.length > 0 ? (
                <div className="row">
                  {videos?.videos?.map((video, index) => (
                    <VideoComponent
                      key={index}
                      video={video}
                      handleViewClick={handleViewClick}
                      videoCount={videoCount}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-center">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="image_modal"
        ref={imageModalRef}
          
        style={{ backdropFilter: " brightness(20%)" }}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered ">
          <div
            className="modal-content p-5"
            style={{
              width: "auto",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="d-flex flex-row justify-content-between">
              <label className="form-label fs-3">Video Player</label>
              <div
                className="btn btn-icon btn-sm btn-active-light-dark ms-2 "
                onClick={handleModalClose}
                style={{ borderRadius: "50%" }}
              >
                <span className="fs-2x mb-1 m-0 p-0">&times;</span>
              </div>
            </div>
            <div className="modal-body p-2">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <iframe
                  ref={videoRef}
                  width="1080"
                  height="560"
                  src={
                    iframeVisible && url
                      ? url.includes("youtube.com") || url.includes("youtu.be")
                        ? url.replace("/watch?v=", "/embed/") + "?autoplay=1"
                        : url + "?autoplay=1"
                      : ""
                  }
                  title="YouTube video player"
                  style={{ display: iframeVisible ? "block" : "none" }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoListing;
