import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FormCompletionContextType {
  completedForms: string[];
  markFormCompleted: (formName: string) => void;
  resetCompletedForms: () => void;
}

const FormCompletionContext = createContext<FormCompletionContextType>({
  completedForms: [],
  markFormCompleted: () => {},
  resetCompletedForms: () => {},
});

export const useFormCompletion = () => useContext(FormCompletionContext);

export const FormCompletionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [completedForms, setCompletedForms] = useState<string[]>([]);

  const markFormCompleted = (formName: string) => {
    if (!completedForms.includes(formName)) {
      setCompletedForms((prev) => [...prev, formName]);
    }
  };

  const resetCompletedForms = () => {
    setCompletedForms([]);
  };



  return (
    <FormCompletionContext.Provider value={{ completedForms, markFormCompleted,resetCompletedForms }}>
      {children}
    </FormCompletionContext.Provider>
  );
};
