import { useAuth } from "..";

const Authorize = (props: any) => {
  const { currentUser } = useAuth();
  //   const allPermissions = currentUser?.privileges;
  const allPermissions = sessionStorage.getItem("privileges");

  const { children, hasAnyPermission, notHasAnyPermission } = props;
  let tempPermissions = hasAnyPermission
    ? hasAnyPermission
    : notHasAnyPermission;
  const permissions = tempPermissions
    ? Array.isArray(tempPermissions)
      ? tempPermissions
      : tempPermissions.split(",")
    : tempPermissions;

  return permissions ? (
    hasAnyPermission ? (
      hasAnyPermissionF(allPermissions, permissions) ? (
        <>{children}</>
      ) : null
    ) : hasAnyPermissionF(allPermissions, permissions) ? null : (
      <>{children}</>
    )
  ) : (
    <>{children}</>
  );
};

const hasAnyPermissionF = (allPermissions: any, checkPermissions: any) => {
  //incomingPermissions is array
  return (
    allPermissions &&
    checkPermissions.some((permission: any) =>
      allPermissions.includes(permission)
    )
  );
};

export default Authorize;
