import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchNotifications } from "../../../firebase/NotificationSlice";
import { useLocation } from "react-router-dom";
import { defaultAlerts, defaultLogs } from "../../../_metronic/helpers";

const Communicationcenter: FC = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [notification, setNotifications] = useState<any[]>([]);
  const [show, setShow] = useState(null);

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const onClick = () => {
    btnRef.current?.setAttribute("data-kt-indicator", "on");
    dispatch(fetchNotifications({} as any) as any);
    setTimeout(() => {
      // Activate indicator
      btnRef.current?.removeAttribute("data-kt-indicator");
    }, 1000);
  };
  const dispatch = useDispatch();
  const { notifications, count } = useSelector(
    (state: RootState) => state.notificationLogs
  );

  const notificationGuid = (useLocation().state as { notificationGuid: any })
    ?.notificationGuid;

  useEffect(() => {

    if (notificationGuid) {
      const feeTabLink = document.querySelector('a[href="#kt_tab_pane_5"]');
      if (feeTabLink instanceof HTMLAnchorElement) {
        feeTabLink.click();
      }
    }
    setTimeout(() => {
      ExpandNotification(0, notificationGuid, 2);
    }, 200);
  }, [notificationGuid]);

  useEffect(() => {
    dispatch(fetchNotifications({} as any) as any);
  }, [dispatch]);

  const ExpandNotification = (
    index: any,
    notificationGuid: string,
    status: number
  ) => {
    setShow((prevIndex) => (prevIndex === index ? -1 : index));
    if (status === 2 && notificationGuid) {
      updateNotification({
        notificationGuid,
        status: 3,
      });
      dispatch(fetchNotifications({} as any) as any);
    }
  };
  const updateNotification = async ({
    notificationGuid,
    status,
    interactionRef,
  }: any) => {
    try {
      const url = `${API_URL}/notification/updateNotificationLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        notificationGuid: notificationGuid,
        notificationStatus: status,
        ...(interactionRef !== null &&
          interactionRef !== "" && { interactionReference: interactionRef }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      dispatch(fetchNotifications({} as any) as any);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleButtonClick = (
    code: string,
    action: string,
    notificationGuid: string,
    buttonId: string
  ) => {
    if (action === "HOME_PAGE") {
      window.location.href = "/dashboard";
    } else {
      window.open(action, "_blank");
    }

    updateNotification({
      notificationGuid,
      status: 3,
      interactionRef: buttonId,
    });
  };

  return (
    <>
      <h4 className="mb-4">Communication Center</h4>
      <div className="card shadow-sm p-5">
        <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 ms-8">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#kt_tab_pane_3"
            >
               <div className="position-relative">
                All
                <span
                  className="position-absolute translate-end badge rounded-pill text-light"
                  style={{
                    backgroundColor: "#f82659",
                    bottom: "8px",
                    fontSize: "10px",
                  }}
                >
                  {
                    notifications.filter(
                      (item) => item?.notificationStatus === 2
                    ).length
                  }
                </span>
              </div>

            </a>
           
          </li>
          <li className="nav-item">
            <a className="nav-link " data-bs-toggle="tab" href="#kt_tab_pane_4">
              Alerts
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link opacity-75 opacity-state-100  "
              data-bs-toggle="tab"
              href="#kt_tab_pane_5"
            >
              <div className="position-relative">
                Notifications
                <span
                  className="position-absolute translate-end badge rounded-pill text-light"
                  style={{
                    backgroundColor: "#f82659",
                    bottom: "8px",
                    fontSize: "10px",
                  }}
                >
                  {
                    notifications.filter(
                      (item) => item?.notificationStatus === 2
                    ).length
                  }
                </span>
              </div>
            </a>
          </li>
          {/* <li className="nav-item ms-5">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_6"
                        >
                            Logs
                        </a>
                    </li> */}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_3"
            role="tabpanel"
          >
            <div className="scroll-y px-8" >
              {notifications?.map((alert, index) => (
                <div
                  key={`alert${index}`}
                  className="d-flex card shadow-sm flex-row py-4 px-4 mt-3"
                >
                  <div
                    className="mb-0 ms-2 me-2 cursor-pointer w-100"
                    onClick={(e) => {
                      ExpandNotification(
                        index,
                        alert?.notificationDetails?.notificationGuid,
                        alert?.notificationStatus
                      );
                      e.preventDefault();
                    }}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center ">
                          {alert?.notificationStatus === 2 && (
                            <span className="bullet bullet-dot bg-primary h-6px w-6px p-0 m-0" />
                          )}

                          <p
                            className={`fs-6 text-gray-800 text-nowrap text-hover-primary  ${alert?.notificationStatus === 2
                                ? "fw-bolder ms-3"
                                : "ms-4"
                              } m-0 p-0`}
                          >
                            {alert?.notificationDetails?.title}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <span className="badge badge-light ms-50 fs-8">
                          {new Date(alert?.createdDate)
                            .toISOString()
                            .split("T")[0] ===
                            new Date().toISOString().split("T")[0]
                            ? new Date(alert?.createdDate).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )
                            : alert?.createdDateString.split(",")[0]}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div
                        style={{ maxWidth: "70%" }}
                        className="text-gray-400 fs-7 mt-2 ms-4"
                      >
                        {index === show ? (
                          <>
                            <div
                              className="m-0"
                              style={{ margin: 0 }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  `<style>p { margin: 0; }</style>` +
                                  alert?.notificationDetails?.body,
                              }}
                            ></div>
                            <img
                              src={alert?.notificationDetails?.mediaUrl}
                              alt=""
                              style={{ maxWidth: "100%", objectFit: "cover" }}
                            />
                            <div className="d-flex flex-row justify-content-start pt-2 gap-5">
                              {alert?.notificationDetails.buttons?.map(
                                (
                                  {
                                    buttonId,
                                    name,
                                    code,
                                    action,
                                    type,
                                  }: {
                                    buttonId: string;
                                    name: string;
                                    code: string;
                                    action: string;
                                    type: number;
                                  },
                                  index: number
                                ) => (
                                  <button
                                    key={buttonId}
                                    className={`btn ${index === 0
                                        ? "btn-primary"
                                        : index === 1
                                          ? "btn-primary"
                                          : "btn-secondary"
                                      } pt-1 pb-1`}
                                    onClick={() =>
                                      handleButtonClick(
                                        code,
                                        action,
                                        alert?.notificationDetails
                                          ?.notificationGuid,
                                        buttonId
                                      )
                                    }
                                  >
                                    {name}
                                  </button>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <span
                            className={`   ${alert?.notificationDetails?.body.length > 100
                                ? "d-flex flex-column"
                                : "d-flex flex-column text-truncate"
                              }`}
                            style={{ maxWidth: "600px" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                `<style>p { margin: 0; }</style>` +
                                (alert?.notificationDetails?.body || "").slice(
                                  0,
                                  100
                                ) +
                                "...",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            {/* <div className="scroll-y  px-8" >
              {defaultAlerts.map((alert, index) => (
                <div
                  key={`alert${index}`}
                  className="d-flex flex-row justify-content-between mt-4 ps-4 pe-4 py-4 card shadow-sm mb-2"
                >
                  <div className="d-flex align-items-center ">
                    <div>
                      {
                        <span className="bullet bullet-dot bg-primary h-6px w-6px mb-4 me-2 animation-blink" />
                      }
                    </div>

                    <div className="mb-0 ">
                      <a
                        href="#"
                        className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                      >
                        {alert.title}
                      </a>
                      <div className="text-gray-400 fs-7">
                        {alert.description}
                      </div>
                    </div>
                  </div>

                  <span
                    className="badge badge-light fs-8"
                    style={{ maxHeight: "20px" }}
                  >
                    {alert.time}
                  </span>
                </div>
              ))}
            </div> */}
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
            {/* 
                        <div className='mt-3 text-end me-5' style={{ height: "30px" }}>
                            <button
                                ref={btnRef}
                                onClick={() => {
                                    onClick();

                                }}
                                type="button"

                                id="kt_button_1"

                                style={{ padding: "3px 10px", backgroundColor: "white", border: "none", outline: "none" }}
                            >
                                <span className="indicator-label"><i className="bi bi-arrow-clockwise fs-2 text-primary"></i></span>
                                <span className="indicator-progress">
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div> */}

            <div className="scroll-y  px-8" >
              {notifications?.map((alert, index) => (
                <div
                  key={`alert${index}`}
                  className="d-flex card shadow-sm flex-row py-4 px-4 mt-3"
                >
                  <div
                    className="mb-0 ms-2 me-2 cursor-pointer w-100"
                    onClick={(e) => {
                      ExpandNotification(
                        index,
                        alert?.notificationDetails?.notificationGuid,
                        alert?.notificationStatus
                      );
                      e.preventDefault();
                    }}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center ">
                          {alert?.notificationStatus === 2 && (
                            <span className="bullet bullet-dot bg-primary h-6px w-6px p-0 m-0 " />
                          )}

                          <p
                            className={`fs-6 text-gray-800 text-nowrap text-hover-primary  ${alert?.notificationStatus === 2
                                ? "fw-bolder ms-3"
                                : "ms-4"
                              } m-0 p-0`}
                          >
                            {alert?.notificationDetails?.title}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <span className="badge badge-light ms-50 fs-8">
                          {new Date(alert?.createdDate)
                            .toISOString()
                            .split("T")[0] ===
                            new Date().toISOString().split("T")[0]
                            ? new Date(alert?.createdDate).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )
                            : alert?.createdDateString.split(",")[0]}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div
                        style={{ maxWidth: "70%" }}
                        className="text-gray-400 fs-7 mt-2 ms-4"
                      >
                        {index === show ? (
                          <>
                            <div
                              className="m-0"
                              style={{ margin: 0 }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  `<style>p { margin: 0; }</style>` +
                                  alert?.notificationDetails?.body,
                              }}
                            ></div>
                            <img
                              src={alert?.notificationDetails?.mediaUrl}
                              alt=""
                              style={{ maxWidth: "100%", objectFit: "cover" }}
                            />
                            <div className="d-flex flex-row justify-content-start pt-2 gap-5">
                              {alert?.notificationDetails.buttons?.map(
                                (
                                  {
                                    buttonId,
                                    name,
                                    code,
                                    action,
                                    type,
                                  }: {
                                    buttonId: string;
                                    name: string;
                                    code: string;
                                    action: string;
                                    type: number;
                                  },
                                  index: number
                                ) => (
                                  <button
                                    key={buttonId}
                                    className={`btn ${index === 0
                                        ? "btn-primary"
                                        : index === 1
                                          ? "btn-primary"
                                          : "btn-secondary"
                                      } pt-1 pb-1`}
                                    onClick={() =>
                                      handleButtonClick(
                                        code,
                                        action,
                                        alert?.notificationDetails
                                          ?.notificationGuid,
                                        buttonId
                                      )
                                    }
                                  >
                                    {name}
                                  </button>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <span
                            className={`   ${alert?.notificationDetails?.body.length > 100
                                ? "d-flex flex-column"
                                : "d-flex flex-column text-truncate"
                              }`}
                            style={{ maxWidth: "600px" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                `<style>p { margin: 0; }</style>` +
                                (alert?.notificationDetails?.body || "").slice(
                                  0,
                                  100
                                ) +
                                "...",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
            <div className="scroll-y px-8" >
              {defaultLogs.map((log, index) => (
                <div
                  key={`alert${index}`}
                  className="d-flex flex-row justify-content-between mt-4 ps-4 pe-4 py-4 card shadow-sm mb-2"
                >
                  <div className="d-flex align-items-center ">
                    <div>
                      {
                        <span className="bullet bullet-dot bg-primary h-6px w-6px mb-5 me-2 " />
                      }
                    </div>

                    <div className="mb-0 ms-1">
                      <a
                        href="#"
                        className={clsx(
                          "w-70px badge",
                          `badge-light-${log.state}`,
                          "me-4 fw-bolder "
                        )}
                      >
                        {log?.code}
                      </a>
                      <div className="text-gray-400 fs-7 mt-2">
                        {log?.message}
                      </div>
                    </div>
                  </div>

                  <span
                    className="badge badge-light fs-8"
                    style={{ maxHeight: "20px" }}
                  >
                    {log?.time}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Communicationcenter;