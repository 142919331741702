import React, { useEffect, useState } from "react";
import "@smile_identity/smart-camera-web";
import axios from "axios";
import CryptoJS from "crypto-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useAuth } from "../../core/Auth";
import { getAllData, uploadSelfieDoc } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";
import { converBase64ToImage } from 'convert-base64-to-image'

const SmileId = () => {
  const API_URL = process.env.REACT_APP_CALLBACK;
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false);

  const { regUser,setRegUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, [])


  console.log(regUser);

  const generateSignature = () => {
    const api_key = "1588d4cf-a8e6-469b-aca4-3a15cbe0a806";
    const partner_id = "2457";
    const timestamp = `${
      new Date().toISOString().split("T")[0]
    }T17:57:00.614879`;

    const hmac = CryptoJS.HmacSHA256(
      timestamp + partner_id + "sid_request",
      api_key
    );
    const signature = CryptoJS.enc.Base64.stringify(hmac);
    return signature;
  };

  const jsonData = {
    package_information: {
      apiVersion: {
        buildNumber: 0,
        majorVersion: 2,
        minorVersion: 0,
      },
    },
    id_info: {
      dob: regUser?.dob,
      country: "ZA",
      entered: true,
      id_type: "NATIONAL_ID",
      id_number: regUser?.idNumber,
      last_name: regUser?.firstName,
      first_name: regUser?.lastName,
      middle_name: "",
    },
    images: [],
  };

  const generateZipFile = () => {
    const zip = new JSZip();
    console.log(jsonData);
    const jsonString = JSON.stringify(jsonData, null, 2);
    console.log(jsonString);
    console.log(jsonData);
  
    // Add the JSON string directly to the zip file
    zip.file("info.json", jsonString);
  
    // Generate the zip file as a blob
    return zip.generateAsync({ type: "blob" });
  };
  

  const postContent = async (url: string) => {
    const zipBlob = await generateZipFile();
    const formData = new FormData();
    formData.append("file", zipBlob, "attachments.zip");
   
    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      if(response.status===200){
        setLoading(false)
     
        markFormCompleted("smile");
          navigate("/auth/signature")
      }

      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  useEffect(() => {
    const app = document.querySelector("smart-camera-web");

    const prepUpload = async () => {
      const data = {
        // callback_url: `${API_URL}/b2b/webhook/smileid/updateLivelinessCheck`,
        callback_url:"https://webhook.site/429605db-95e9-4585-b7ae-8a2e39d9a13f",
        model_parameters: {},
        partner_params: {
          job_id: (regUser?.appId ?? +new Date().getTime()).toString(),
          job_type: 1,
          user_id: regUser?.businessGuid,
          business_guid: regUser?.businessGuid,
          isd_code: regUser?.isdCode?.toString(),
          mobile_number: regUser?.mobile,
          tenant_id: regUser?.tenantId,
        },
        signature: generateSignature(),
        smile_client_id: "2457",
        source_sdk_version: "1.0.0",
        source_sdk: "rest_api",
        timestamp: `${new Date().toISOString().split("T")[0]}T17:57:00.614879`,
      };

      try {
        const response = await axios.post(
          "https://testapi.smileidentity.com/v1/upload",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.data?.code === "2202") {
          postContent(response?.data?.upload_url);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const converBase64ToImage = (base64: string, fileName: string): File | null => {
      try {
        // Remove data URL prefix if present
        const base64Data = base64.replace(/^data:image\/\w+;base64,/, '');
        const byteString = atob(base64Data);
        const mimeString = base64.match(/^data:(image\/\w+);base64,/)?.[1] || '';
    
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
    
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
    
        const blob = new Blob([ab], { type: mimeString });
        const file = new File([blob], fileName, { type: mimeString });
    
        return file;
      } catch (error) {
        console.error("Failed to convert base64 to image:", error);
        return null;
      }
    };
    
    const handleImagesComputed = async (e: any) => {
      try {
        const updData = e?.detail?.images?.map((item: any) => {
          return {
            ...item,
            file_name: ""
          };
        });
    
        const base64 = e?.detail?.images[0]?.image;
        const pathToSaveImage = 'selfie.png';
    
        // Convert base64 to File
        const file = converBase64ToImage(base64, pathToSaveImage);
        setLoading(true)
        if (file) {
          await uploadSelfieDoc(file, regUser?.token ?? '');
        } else {
          throw new Error("Failed to convert base64 to image.");
        }
    
        jsonData.images = updData;
    
        await prepUpload();
      } catch (error) {
        console.error(error);
      }
    };
    

    if (app) {
      app.addEventListener("imagesComputed", handleImagesComputed);
    }

    // Clean-up function
    return () => {
      if (app) {
        app.removeEventListener("imagesComputed", handleImagesComputed);
      }
    };
  }, []);

  return (
    <div>
      <smart-camera-web capture-id> </smart-camera-web>
      <div className="d-flex flex-row justify-content-center align-items-center mt-5">
      {loading ? <div className="spinner-border text-primary" role="status">
              <span className="" role="status" aria-hidden="true"></span>
            </div>:""}
            </div>
    </div>
  );
};

export default SmileId;
