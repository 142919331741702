import { useState, useEffect } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

interface Data {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  empStatus: number;
  netPayMonthly: string;
}

interface EmployeeData {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  empStatus: number;
  basicMonthlySalary: string;
  basicPensionDeduction: string;
  travelAllowance: string;
  medicalDependants: string;
  otherAllowances: string;
  annualBonus: string;
}

export function PayrollDetailsModal({ Data }: { Data: Data | null }) {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const [viewData, setViewData] = useState<EmployeeData | null>(null);

  const getEmployeeDataa = async () => {
    try {
      const url = `${API_URL}/employee/getEmployeeByCode`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        employeeCode: Data?.employeeCode,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setViewData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Data) {
      getEmployeeDataa();
    }
  }, [Data]);

  return (
    <>
      <div
        id="kt_modal_payroll"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_payroll"
        data-kt-drawer-close="#kt_modal_payroll_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_payroll_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card shadow p-10">
            <h4 className="text-center">Employee Data</h4>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Employee Name</p>
              <p>{viewData?.firstName}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Date of Birth</p>
              <p>
                {" "}
                {viewData?.dob
                  ? new Date(viewData.dob).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "Not available"}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Email</p>
              <p>{viewData?.email}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Phone</p>
              <p>
                {(viewData?.isdCode ?? "") +
                  " " +
                  (viewData?.phoneNumber ?? "")}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5 gap-20 text-end">
              <p className="fw-bolder ">Address</p>
              <p>
                {viewData?.address
                  ?.split(",")
                  .map((part) => part.trim())
                  .join("\n")}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Blood Group</p>
              <p>{viewData?.bloodGroup}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Emergency Contact</p>
              <p>
                +
                {(viewData?.emergencyContactIsdCode ?? "") +
                  "" +
                  (viewData?.emergencyContactNo ?? "")}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Date of Joining</p>
              <p>
                {" "}
                {viewData?.joiningDate
                  ? new Date(viewData.joiningDate).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "Not available"}
              </p>
            </div>
            <br />
            <hr />
            <br />
            <h4 className="text-center">Company Data</h4>

            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Employee Code</p>
              <p>{viewData?.employeeCode} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Employee ID</p>
              <p>{viewData?.employeeCode} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Designation</p>
              <p>{viewData?.designation} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Department</p>
              <p>{viewData?.department} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Location</p>
              <p>{viewData?.location} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Bank A/C Number</p>
              <p>{viewData?.bankAccountNumber} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Bank Name</p>
              <p>{viewData?.bankName} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Bank Identifier Code</p>
              <p>{viewData?.bankCode} </p>
            </div>
            <br />
            <hr />
            <br />
            <h4 className="text-center">Payroll Data</h4>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Basic Monthly Salary</p>
              <p>
                ZAR{" "}
                {Number(viewData?.basicMonthlySalary)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Pension Deduction</p>
              <p>
                ZAR{" "}
                {Number(viewData?.basicPensionDeduction)?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Travel Allowance</p>
              <p>
                ZAR{" "}
                {Number(viewData?.travelAllowance)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Medical Dependents</p>
              <p>{viewData?.medicalDependants} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Other Allowances</p>
              <p>
                ZAR{" "}
                {Number(viewData?.otherAllowances)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5 mb-5">
              <p className="fw-bolder ">Annual Bonus</p>
              <p>
                ZAR{" "}
                {Number(viewData?.annualBonus)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </p>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
