import { useState, useEffect } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

interface Data {
  name: string;
  description: string;
  fileUploadId: number;
  fileUploadGuid: string;
  jobScheduledTime: string;
  fileName: string;
  uploadedDate: string;
  successCount: number;
  failCount: number;
  totalCount: number;
  fileUploadStatus: number;
  fileUploadResponse: {
    sum: number;
  };
}

interface EmployeeData {
  tenantId: string;
  id: number;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  empStatus: number;
  basicMonthlySalary: string;
  basicPensionDeduction: string;
  travelAllowance: string;
  medicalDependants: string;
  otherAllowances: string;
  annualBonus: string;
}

export function BukDetailsModal({ Data }: { Data: Data | null }) {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const Download = async () => {
    try {
      const url = `${API_URL}/bulk/downloadLogFile`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        fileUploadId: Number(Data?.fileUploadId),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);

      if (response.ok) {
        // If the response status is OK (2xx), proceed to download the file.
        const blob = await response.blob();
        const filename = "downloaded_file.txt"; // You can set the desired filename here.

        // Create a Blob URL and trigger an automatic download.
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobUrl;
        a.download = filename;

        // Automatically trigger the download.
        document.body.appendChild(a);
        a.click();

        // Clean up the object URL after the download is initiated.
        window.URL.revokeObjectURL(blobUrl);
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Bulk pay Statement has been download to your device. Please check the download folder of your device",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        console.error("Failed to download file: ", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        id="kt_modal_bulk"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_bulk"
        data-kt-drawer-close="#kt_modal_bulk_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_bulk_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">
              Bulk Pay info
            </h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card shadow p-10">
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Name</p>
              <p>{Data?.name}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Description</p>
              <p>{Data?.description}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Refrence ID</p>
              <p>
                {Data?.fileUploadGuid
                  ? Data?.fileUploadGuid
                  : Data?.fileUploadId}
              </p>
            </div>
            {Data?.uploadedDate && (
              <>
                <div className="d-flex flex-row justify-content-between mt-5">
                  <p className="fw-bolder ">Creation Date</p>
                  <p>
                    {new Date(Data?.uploadedDate)?.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    |{" "}
                    {new Date(Data?.uploadedDate)?.toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                  </p>
                </div>
              </>
            )}
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Total Value</p>
              <p>
                ZAR{" "}
                {Data?.fileUploadResponse.sum.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            {Data?.jobScheduledTime && (
              <div className="d-flex flex-row justify-content-between mt-5">
                <p className="fw-bolder ">Execution Date</p>
                <p>
                  {new Date(Data?.jobScheduledTime).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            )}
            {Data?.jobScheduledTime && (
              <div className="d-flex flex-row justify-content-between mt-5">
                <p className="fw-bolder ">Execution Time</p>
                <p>
                  {new Date(Data?.jobScheduledTime).toLocaleTimeString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    }
                  )}
                </p>
              </div>
            )}

            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Record Count</p>
              <p>{Data?.totalCount}</p>
            </div>
            {Data?.fileUploadStatus === 3 && (
              <>
                <div className="d-flex flex-row justify-content-between mt-5">
                  <p className="fw-bolder ">Success Count</p>
                  <p>{Data?.successCount}</p>
                </div>
                <div className="d-flex flex-row justify-content-between mt-5">
                  <p className="fw-bolder ">Fail Count</p>
                  <p>{Data?.failCount}</p>
                </div>
              </>
            )}

            {/* <div className="d-flex flex-row justify-content-between mt-5">
                            <p className="fw-bolder ">Reference ID</p>
                            <p>{Data?.fileUploadId}</p>
                        </div> */}
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bolder ">Status</p>
              <p
                className={`badge badge-${
                  Data?.fileUploadStatus === 3
                    ? "primary"
                    : Data?.fileUploadStatus === 4
                    ? "secondary"
                    : Data?.fileUploadStatus === 5
                    ? "danger"
                    : Data?.fileUploadStatus === 2
                    ? "warning"
                    : ""
                }`}
              >
                {Data?.fileUploadStatus === 3
                  ? "Success"
                  : Data?.fileUploadStatus === 4
                  ? "Scheduled"
                  : Data?.fileUploadStatus === 5
                  ? "Cancelled"
                  : Data?.fileUploadStatus === 2
                  ? "File Processing"
                  : ""}
              </p>
            </div>

            <br />
            {Data?.fileUploadStatus === 3 && (
              <>
                <button className="btn" onClick={Download}>
                  View Logs
                </button>
              </>
            )}
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
