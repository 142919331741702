import { useEffect, useState, useRef } from "react";
import { Modal } from "bootstrap";
import ConfettiExplosion from "react-confetti-explosion";
import jsPDF from "jspdf";
import PinInput from "react-pin-input";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import Xfin from "../../../../_metronic/assets/Icons/xfin.png";
import OTPInput from "react-otp-input";
import { User } from "../../TransferMoney/TransferModal";
import { Carousel } from "react-bootstrap";
import Authorize from "../../../modules/auth/components/Authorize";
import { FallingLines } from "react-loader-spinner";

interface EmployeeData {
  tenantId: string;
  referenceId: string;
  payslipGuid: string;
  employeeCode: string;
  employeeName: string;
  month: string;
  year: number;
  basicSalary: number;
  travelAllowance: number;
  otherAllowances: number;
  grossPay: number;
  incomeTax: number;
  unemploymentInsurance: number;
  pensionFund: number;
  totalDeductions: number;
  netMonthlyPay: number;
  payslipStatus: number;
  createdDate: string;
  updatedDate: string;
}
interface SelectedEmployeeData {
  tenantId: string;
  referenceId: string;
  payslipGuid: string;
  employeeCode: string;
  employeeName: string;
  month: string;
  year: number;
  basicSalary: number;
  travelAllowance: number;
  otherAllowances: number;
  grossPay: number;
  incomeTax: number;
  unemploymentInsurance: number;
  pensionFund: number;
  totalDeductions: number;
  netMonthlyPay: number;
  payslipStatus: number;
  createdDate: string;
  updatedDate: string;
  receipt: SalarySlipResponse;
}
interface SearchEmployeeData {
  tenantId: string;
  referenceId: string;
  employeeGuid: string;
  employeeCode: string;
  firstName: string;
  dob: string;
  address: string;
  isdCode: number;
  phoneNumber: string;
  emergencyContactIsdCode: number;
  emergencyContactNo: string;
  bloodGroup: string;
  email: string;
  joiningDate: string;
  designation: string;
  department: string;
  location: string;
  bankAccountNumber: string;
  bankCode: string;
  bankName: string;
  empStatus: number;
  netPayMonthly: string;
}
interface Employee {
  employeeCode: string;
  netMonthlyPay: number;
}

interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}
interface Account {
  accountNumber: string;
  accountType: string;
  currency: string;
  balance: number;
}

type CombinedState = {
  employeeCode: string;
  month: string;
  year: number;
};

interface TransactionData {
  employeeName: string;
  month: string;
  year: number;
  employeeAccountNo: string;
  employeeAccountType: number;
  amount: number;
  paymentStatus: number;
  txnRefId: string;
  transactionDate: string;
  receipt: [];
}
interface Receipt {
  Amount: string;
  PayeeName: string;
  AccountNumber: string;
  Date: string;
  Time: string;
  Type: string;
  Status: string;
}

interface PaymentHistory {
  tenantId: string;
  businessGuid: string;
  paymentGuid: string;
  employeeName: string;
  employeeCode: string;
  month: string;
  year: number;
  businessAccountNo: string;
  employeeAccountNo: string;
  employeeAccountType: number;
  amount: number;
  paymentStatus: number;
  errorCode: string;
  createdBy: string;
  transactionDate: string;
  receipt: Receipt;
}
interface CollapsedState {
  [index: number]: boolean;
}

interface SalarySlipResponse {
  "Employee Code": string;
  "Employee Name": string;
  "Basic Salary": string;
  "Other Allowances": string;
  "Travel Allowances": string;
  "Gross Salary": string;
  "Income Tax": string;
  "Pension Fund": string;
  "Unemployment Insurance": string;
  "Total Deduction": string;
  "Net Payable Salary": string;
  "Salary Month": string;
  "Salary Year": number;
  Date: string;
  Time: string;
  Status: string;
  receipt: any;
}

let totalNetMonthlyPay = 0;

export default function PaySalary() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");
  let hasInsufficientBalance = false;
  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
    `${monthNames[currentDate.getMonth()]}-${currentDate.getFullYear()}`
  );

  const [empSearch, setEmpSearch] = useState("");
  const [paySlip, setPaySlip] = useState<SalarySlipResponse | null>(null);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const [codeValues, setCodeValues] = useState("");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Pay Now");
  const [approvers, setApprovers] = useState<User[]>([]);

  const [approverName, setApproverName] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [apperrMsg, setAppErrMsg] = useState(false);
  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
      });
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);

    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("account_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    const previousMonth = new Date(currentDate);
    previousMonth.setMonth(previousMonth.getMonth()); // Subtract 1 to get the previous month

    setSelectedMonth(
      Number(payGenDate) >= 4
        ? `${
            monthNames[(previousMonth.getMonth() + 1) % 12]
          }-${previousMonth.getFullYear()}`
        : `${
            monthNames[previousMonth.getMonth()]
          }-${previousMonth.getFullYear()}`
    );
  }, []);

  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [payGenDate, setPayGenDate] = useState("");

  const [combinedStates, setCombinedStates] = useState<CombinedState[]>([]);

  const [employeeData, setEmployeeData] = useState<EmployeeData[]>([]);

  const [searchEmpData, setSearchEmpData] = useState<SearchEmployeeData[]>([]);

  const [selectedEmployeeCodes, setSelectedEmployeeCodes] = useState<string[]>(
    []
  );

  const [selectedEmployeeInfo, setSelectedEmployeeInfo] = useState<
    Array<{ employeeCode: string; month: string; year: number }>
  >([]);
  const [totalNetMonthlyPay, setTotalNetMonthlyPay] = useState(0);

  const [selectedEmp, setSelectedEmp] = useState<string | null>(null);

  const [selectedEmpData, setSelectedData] = useState<SelectedEmployeeData[]>(
    []
  );

  const [selectedMonths, setSelectedMonths] = useState<{
    [employeeCode: string]: string[];
  }>({});
  const [selectedEmployeeMonthInfo, setSelectedEmployeeMonthInfo] = useState<
    Array<{ employeeCode: string; month: string; year: number }>
  >([]);

  const [totalNetPay, setTotalNetPay] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState<Account | null>(
    null
  );

  const [render, setRender] = useState(false);

  const [payAll, setPayAll] = useState(false);

  const [paySelected, setPaySelected] = useState(false);

  const [searchPay, setSearchPay] = useState(false);

  const [status, setStatus] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const [transaction, setTransaction] = useState<PaymentHistory[]>([]);
  const [collapsedState, setCollapsedState] = useState<{
    [key: string]: boolean;
  }>({});

  const [loadingIndexes, setLoadingIndexes] = useState<number[]>([]);

  const startLoading = (index: number) => {
    setLoadingIndexes((prevIndexes) => [...prevIndexes, index]);
  };

  const stopLoading = (index: number) => {
    setLoadingIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
  };

  // Example function to simulate loading for a specific index
  const simulateLoad = (index: number) => {
    startLoading(index);
    setTimeout(() => {
      stopLoading(index);
    }, 2000); // Simulate loading for 2 seconds
  };

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
 
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
        preConfirm: () => {
          window.history.back();
        },
      });
    }
  }, []);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (button === "Initiate") {
        if (!approverGuid) setAppErrMsg(true);
        if (!pin) setErrMsg("Please enter Pin");
        if (approverGuid && pin && selectedItemIndex) handlePaySalary();
      } else {
        if (!pin) setErrMsg("Please enter Pin");
        if (pin && selectedItemIndex) handlePaySalary();
      }
    }
  };

  const generatePDF = () => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();

        if (transaction.length > 0) {
          transaction.forEach((trans, index) => {
            if (index > 0) {
              // Add a new page for each transaction (except the first one)
              doc.addPage();
            }

            // Header
            doc.setFontSize(14);
            doc.setFont("bold");

            let yPosition = 20;
            const lineHeight = 10;
            const keyWidth = 50; // Width for the keys
            doc.text("Transaction Receipt", 105, yPosition, {
              align: "center",
            });

            yPosition += lineHeight * 2;
            doc.setFontSize(12);
            doc.setFont("normal");

            // Iterate over receipt properties and add them to the PDF with equal space
            for (const [key, value] of Object.entries(trans)) {
              const keyText = `${capitalizeFirstLetter(key)}:`;
              const valueText = `${value}`;

              // Align the keys and values
              doc.text(keyText, 20, yPosition);
              doc.text(valueText, 20 + keyWidth, yPosition);

              // Increase the yPosition for the next line
              yPosition += lineHeight;
            }
          });
        } else {
          // No Transactions Message
          doc.setFont("normal");
          doc.setFontSize(12);
          doc.text("No transactions found.", 105, 40, { align: "center" });
        }

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);
        // Save the PDF
        doc.save("Transaction.pdf");
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  const downloadPDF = (receipt: Receipt) => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 10;
        const lineHeight = 10;
        const keyWidth = 50;
        doc.text("Employee Payslip", 105, yPosition, { align: "center" });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");
        // Dynamically add data from the billData object
        Object.entries(receipt).forEach(([key, value], index) => {
          const keyText = `${capitalizeFirstLetter(key)}:`;
          const valueText = value.toString(); // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);

        doc.save(`${receipt.PayeeName}_Pay_Slip.pdf`);
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleItemClick = (account: Account) => {
    setSelectedItemIndex(account);
    setRender(true);
  };

  const handleToggleCollapse = (employeeCode: string) => {
    setCollapsedState((prev) => {
      const isCollapsed = !prev[employeeCode];
      // Update the state for the clicked employee code
      return {
        // ...prev,
        [employeeCode]: isCollapsed,
      };
    });
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   ApproverList(userDet?.userDetails?.zarLimit);
  // }, []);

  const totalSelectedNetMonthlyPay = selectedEmployeeCodes.reduce(
    (total, employeeCode) => {
      const selectedEmployee = employeeData.find(
        (item) => item.employeeCode === employeeCode
      );
      return total + (selectedEmployee ? selectedEmployee.netMonthlyPay : 0);
    },
    0
  );

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (button === "Initiate") {
        if (totalNetMonthlyPay && selectedTab === 1) {
          const amount = totalNetMonthlyPay.toString();
          ApproverList(amount);
        }
        if (totalNetPay && selectedTab === 3) {
          const amount = totalNetPay.toString();
          ApproverList(amount);
        }
        if (totalSelectedNetMonthlyPay && selectedTab === 2) {
          console.log(
            totalSelectedNetMonthlyPay,
            totalSelectedNetMonthlyPay.toString()
          );
          const amount = totalSelectedNetMonthlyPay.toString();
          ApproverList(amount);
        }
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [totalNetMonthlyPay, totalSelectedNetMonthlyPay, totalNetPay]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const resetState = () => {
    setSelectedEmp(null);
    setSelectedData([]);
    setSelectedMonths({});
    // setSearchEmpData([]);
    setSelectedEmployeeCodes([]);
    setSelectedEmployeeInfo([]);
    setSelectedEmployeeMonthInfo([]);
    setSelectedItemIndex(null);
  };
  //   useEffect(() => {
  //     // Handle the state change after the component has rendered
  //     if (selectedEmp) {
  //       handleToggleCollapse(selectedEmp);
  //     }
  //   }, [selectedEmp]);
  // console.log(selectedEmployeeMonthInfo);

  const handlePayNowClick = () => {
    setPayAll(true);
    if (employeeData) {
      // Filter employees with payslipStatus === 2
      const filteredEmployees = employeeData.filter(
        (employee) => employee.payslipStatus === 1
      );

      // Extract necessary information from filtered employees
      const updatedCombinedStates = filteredEmployees.map((employee) => ({
        employeeCode: employee.employeeCode,
        month: employee.month,
        year: employee.year,
      }));

      setCombinedStates(updatedCombinedStates);
    }
  };

  const handleTotalPay = () => {
    let total = 0;
    for (const employeeCode in selectedMonths) {
      selectedMonths[employeeCode].forEach((month) => {
        const payslipItem = selectedEmpData.find(
          (item) => item.employeeCode === employeeCode && item.month === month
        );

        if (payslipItem) {
          total += payslipItem.netMonthlyPay;
        }
      });
    }

    setTotalNetPay(total);
  };

  useEffect(() => {
    handleTotalPay();
  }, [selectedMonths]);

  const handleMonthCheckboxChange = (
    employeeCode: string,
    month: string,
    year: number
  ) => {
    setSearchPay(true);

    setSelectedMonths((prevSelectedMonths) => {
      if (prevSelectedMonths[employeeCode]?.includes(month)) {
        return {
          ...prevSelectedMonths,
          [employeeCode]: prevSelectedMonths[employeeCode].filter(
            (item) => item !== month
          ),
        };
      } else {
        return {
          ...prevSelectedMonths,
          [employeeCode]: [...(prevSelectedMonths[employeeCode] || []), month],
        };
      }
    });

    setSelectedEmployeeMonthInfo((prevSelectedInfo) => {
      // Check if the given employee and month are already selected
      const index = prevSelectedInfo.findIndex(
        (item) =>
          item.employeeCode === employeeCode &&
          item.month === month &&
          item.year === year
      );

      if (index !== -1) {
        // If selected, remove the entry
        return prevSelectedInfo.filter((_, i) => i !== index);
      } else {
        // If not selected, add the entry
        return [...prevSelectedInfo, { employeeCode, month, year }];
      }
    });
  };

  const generatePayrollDataList = (
    selectedMonths: Record<string, string[]>
  ) => {
    const payrollDataList: any[] = [];

    for (const employeeCode in selectedMonths) {
      const monthsForEmployee = selectedMonths[employeeCode];

      monthsForEmployee.forEach((month) => {
        const findYear = selectedEmpData.find(
          (employee) => employee.employeeCode === employeeCode
        );
        const year = findYear?.year;
        const payrollData = {
          month,
          year,
          employeeCode,
        };
        payrollDataList.push(payrollData);
      });
    }

    return payrollDataList;
  };

  const handleSelect = (selectedIndex: number, e: any) => {
    if (selectedIndex >= 0) {
      setSelectedIndex(selectedIndex);
    }
  };

  const payrollDataList = generatePayrollDataList(selectedMonths);

  useEffect(() => {
    let calculatedTotal = 0;

    if (employeeData) {
      // Filter employees with payslipStatus === 2
      const filteredEmployees = employeeData.filter(
        (employee) => employee.payslipStatus === 1
      );

      // Calculate total net monthly pay for filtered employees
      calculatedTotal = filteredEmployees.reduce(
        (total, item) => total + item.netMonthlyPay,
        0
      );
    }

    setTotalNetMonthlyPay(calculatedTotal);
  }, [employeeData]);

  const handleCheckboxChange = (
    employeeCode: string,
    month: string,
    year: number
  ) => {
    setPaySelected(true);
    if (selectedEmployeeCodes.includes(employeeCode)) {
      setSelectedEmployeeCodes(
        selectedEmployeeCodes.filter((code) => code !== employeeCode)
      );
      setSelectedEmployeeInfo(
        selectedEmployeeInfo.filter(
          (info) => info.employeeCode !== employeeCode
        )
      );
    } else {
      const employeeInfo = { employeeCode, month, year };
      setSelectedEmployeeCodes([...selectedEmployeeCodes, employeeCode]);
      setSelectedEmployeeInfo([...selectedEmployeeInfo, employeeInfo]);
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedMonth(selectedValue);
  };
  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        monthYear: selectedMonth,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDownload = async () => {
    try {
      // Ensure paySlip is not null before proceeding
      if (!paySlip) {
        console.error("Pay slip data is null.");
        return;
      }

      // Dynamically set the file name based on the employee name and salary slip details
      const fileName = `${paySlip["Employee Name"]}_Pay_Slip.pdf`;

      const img = new Image();
      img.src = Xfin;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          const imageData = canvas.toDataURL("image/png");

          // Create a new jsPDF instance
          const pdfDoc = new jsPDF();

          pdfDoc.setFontSize(14);
          pdfDoc.setFont("bold");

          let yPosition = 20;
          const lineHeight = 10;
          const keyWidth = 50; // Width for the keys

          // Add content to the PDF
          pdfDoc.text("Salary Slip", 105, yPosition, { align: "center" });

          yPosition += lineHeight * 2;
          pdfDoc.setFontSize(12);
          pdfDoc.setFont("normal");

          // Add salary slip data
          Object.keys(paySlip).forEach((key, index) => {
            const keyText = `${capitalizeFirstLetter(key)}:`;
            const valueText = (paySlip as any)[key].toString(); // Ensure the value is a string
            pdfDoc.text(keyText, 20, yPosition);
            pdfDoc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
            yPosition += lineHeight;
          });

          // Adding the image to the PDF
          const imageWidth = 40;
          const imageHeight = 12;
          const imageX = 20;
          const imageY = 10;

          pdfDoc.addImage(
            imageData,
            "PNG",
            imageX,
            imageY,
            imageWidth,
            imageHeight
          );

          // Save the PDF
          pdfDoc.save(fileName);
        } else {
          console.error("Unable to get 2D context");
        }
      };
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAppErrMsg(false);
    setApproverGuid(e.target.value);
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) setApproverName(appName.name);
  };

  const viewEmpPaySlip = async () => {
    try {
      const url = `${API_URL}/payroll/viewEmployeePayslip`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        monthYear: selectedMonth,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setEmployeeData(data.message.payrollDataList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    viewEmpPaySlip();
  }, [selectedMonth]);

  const searchEmployee = async () => {
    try {
      const url = `${API_URL}/employee/getAllEmployees`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        firstName: empSearch,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setSearchEmpData(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    searchEmployee();
  }, [empSearch]);

  const EmpPayslip = async () => {
    try {
      const url = `${API_URL}/payroll/viewEmployeePayslip`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        employeeCode: selectedEmp,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setSelectedData(data.message.payrollDataList);
      setPaySlip(data.message.payrollDataList[0].receipt);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    EmpPayslip();
  }, [selectedEmp]);

  const Pay = async () => {
    try {
      const url = `${API_URL}/payroll/payEmployeeSalary`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        accountNumber: selectedItemIndex?.accountNumber,
        accountType: selectedItemIndex?.accountType,
        currency: selectedItemIndex?.currency,
        transactionPIN: codeValues,
        amount: totalNetMonthlyPay.toString(),
        payrollDataList: combinedStates,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: "Salary Payment",
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        reset();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          reset();
          setTransaction(data?.message?.paymentHistory);
          handleEReceipt();
        }
      }
      // if (data?.message?.paymentHistory[0]?.receipt?.Status === "Success") {
      //   if (button === "Initiate") {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Confirmation",
      //       text: "Initiation successful",
      //     });
      //   } else {
      //     setStatus(true);
      //     setStatusCode("0");
      //     setTransaction(
      //       data?.message?.paymentHistory.map((item: any) => item.receipt)
      //     );
      //     getAccounts();
      //     viewEmpPaySlip();
      //   }
      // } else {
      //   setStatusCode("1");
      //   setActiveInput(0);
      //   setOtp(["", "", "", ""]);
      //   setPin("");

      //   const msg = data?.message?.paymentHistory[0]?.errorCode
      //     ? data?.message?.paymentHistory[0]?.errorCode
      //     : data?.status?.messageDescription;
      //   setErrMsg(msg);
      //   // Swal.fire({
      //   //   icon: "error",
      //   //   text: msg,
      //   // });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const PaySelected = async () => {
    try {
      const url = `${API_URL}/payroll/payEmployeeSalary`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        accountNumber: selectedItemIndex?.accountNumber,
        accountType: selectedItemIndex?.accountType,
        currency: selectedItemIndex?.currency,
        transactionPIN: codeValues,
        amount: totalSelectedNetMonthlyPay.toString(),
        payrollDataList: selectedEmployeeInfo,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: "Salary Payment",
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        reset();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          reset();
          setTransaction(data?.message?.paymentHistory);
          handleEReceipt();
        }
      }
      // if (data?.message?.paymentHistory[0]?.receipt?.Status === "Success") {
      //   if (button === "Initiate") {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Confirmation",
      //       text: "Initiation successful",
      //     });
      //   } else {
      //     handleEReceipt();
      //     setStatus(true);
      //     setStatusCode("0");
      //     setTransaction(
      //       data?.message?.paymentHistory.map((item: any) => item.receipt)
      //     );
      //     getAccounts();
      //     viewEmpPaySlip();
      //   }
      // } else {
      //   setStatusCode("1");
      //   setActiveInput(0);
      //   setOtp(["", "", "", ""]);
      //   setPin("");
      //   const msg = data?.message?.paymentHistory[0]?.errorCode
      //     ? data?.message?.paymentHistory[0]?.errorCode
      //     : data?.status?.messageDescription;
      //   setErrMsg(msg);
      //   // Swal.fire({
      //   //   icon: "error",
      //   //   title: "Error",
      //   //   text: msg,
      //   // });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const PaySearched = async () => {
    try {
      const url = `${API_URL}/payroll/payEmployeeSalary`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        accountNumber: selectedItemIndex?.accountNumber,
        accountType: selectedItemIndex?.accountType,
        currency: selectedItemIndex?.currency,
        transactionPIN: codeValues,
        amount: totalNetPay.toString(),
        payrollDataList: selectedEmployeeMonthInfo,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: "Salary Payment",
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        reset();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          reset();
          setTransaction(data?.message?.paymentHistory);
          handleEReceipt();
        }
      }
      // if (data?.message?.paymentHistory[0]?.receipt?.Status === "Success") {
      //   if (button === "Initiate") {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Confirmation",
      //       text: "Initiation successful",
      //     });
      //   } else {
      //     handleEReceipt();
      //     setStatus(true);
      //     setStatusCode("0");
      //     setTransaction(
      //       data?.message?.paymentHistory.map((item: any) => item.receipt)
      //     );
      //     getAccounts();
      //     viewEmpPaySlip();
      //     setSelectedEmp(null);
      //   }
      // } else {
      //   const msg = data?.message?.paymentHistory[0]?.errorCode
      //     ? data?.message?.paymentHistory[0]?.errorCode
      //     : data?.status?.messageDescription;
      //   setOtp(["", "", "", ""]);
      //   setPin("");
      //   setActiveInput(0);
      //   setStatusCode("1");
      //   setErrMsg(msg);
      //   // Swal.fire({
      //   //   icon: "error",
      //   //   title: "Error",
      //   //   text: errorMessage,
      //   // });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaySalary = () => {
    if (codeValues.length === 4 && payAll) {
      Pay();
    }
    if (codeValues.length === 4 && selectedEmployeeInfo && paySelected) {
      PaySelected();
    }
    if (codeValues.length === 4 && selectedEmployeeMonthInfo && searchPay) {
      PaySearched();
    }
  };

  const reset = () => {
    handleCloseModal();
    setSelectedEmployeeCodes([]);
    setTotalNetMonthlyPay(0);
    setTotalNetPay(0);
    setStatus(true);
    // getAccounts();
    viewEmpPaySlip();
    setSelectedEmp(null);
    setPin("");
    setOtp(["", "", "", ""]);
    setApproverGuid("");
    setApproverName("");
    setActiveInput(0);
    resetState();
    setStatus(false);
    setStatusCode("0");
    setRender(false);
    setSelectedItemIndex(null);
    setCodeValues("");
    setHoveredIndex(null);
    setErrMsg("");
  };
  const settings = async () => {
    try {
      const url = `${API_URL}/payrollSetting/loadPayrollSetting`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setPayGenDate(data?.message?.payslipGenerationDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
    settings();
  }, []);

  const downloadPdf = () => {
    handleDownload();
  };

  return (
    <>
      <style>
        {`
        .black-icon {
        color: black;
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
        margin-bottom: 60px; 
        filter: invert(100%);
        background-size: 20px 20px;
        }
      `}
      </style>
      <h4 className="mb-4">Pay Salary</h4>

      <div className="card shadow p-2">
        <div className="d-flex justify-content-between mt-2">
          <ul className="nav nav-tabs nav-line-tabs ms-5 fs-6 d-flex flex-nowrap">
            <li className="nav-item" key={1}>
              <a
                className="nav-link active text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
                onClick={() => {
                  setSelectedTab(1);
                  setPaySelected(false);
                  setSearchPay(false);
                }}
              >
                All
              </a>
            </li>
            <li className="nav-item" key={2}>
              <a
                className="nav-link text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
                onClick={() => {
                  setSelectedTab(2);
                  setPayAll(false);
                  setSearchPay(false);
                }}
              >
                Select
              </a>
            </li>
            <li className="nav-item" key={3}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
                onClick={() => {
                  setSelectedTab(3);
                  setPayAll(false);
                  setPaySelected(false);
                }}
              >
                Search
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <div className="d-flex flex-row justify-content-between p-5">
              <div className="d-flex flex-grow-1" style={{ maxWidth: "250px" }}>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-placeholder="Select an option"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="">Select a month</option>
                  {Array.from({ length: 12 }, (_, index) => {
                    const optionDate = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth() - index
                    );
                    // const isDisabled =
                    //   optionDate >= currentDate ||
                    //   (optionDate.getMonth() === currentDate.getMonth() &&
                    //     optionDate.getFullYear() ===
                    //       currentDate.getFullYear() &&
                    //     currentDate.getDate() < Number(payGenDate));

                    const displayMonth = monthNames[optionDate.getMonth()];
                    const displayYear = optionDate.getFullYear();

                    return (
                      <option
                        key={index}
                        value={`${displayMonth}-${displayYear}`}
                        // disabled={isDisabled}
                      >
                        {`${displayMonth} ${displayYear}`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                  {totalNetMonthlyPay ? (
                    <button
                      className="btn"
                      onClick={() => {
                        handlePayNowClick();
                        handleOpenModal();
                      }}
                    >
                      {button} {baseCurrency}{" "}
                      {totalNetMonthlyPay.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </button>
                  ) : (
                    <button
                      className="btn"
                      onClick={() => {
                        Swal.fire({
                          text: "No payroll generated",
                          confirmButtonText: "OK",
                          allowEscapeKey: true,
                          allowEnterKey: true,
                        });
                      }}
                    >
                      {button}
                    </button>
                  )}
                </Authorize>
              </div>
            </div>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
              {employeeData?.length > 0 ? (
                employeeData.map((item, index) => (
                  <div className="p-5" key={index}>
                    <div className="card shadow p-0 d-flex flex-row  ">
                      <div className="card-body p-5 ">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title">{item.employeeName}</h5>
                        </div>
                        <div className="d-flex justify-content-between p-0">
                          <div className="d-flex p-0">
                            <p
                              className="text-muted p-0 m-0"
                              style={{ fontSize: "12px" }}
                            >
                              Employee Id: {item.employeeCode}
                              {/* {new Date(item.createdDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center p-5">
                        <div>
                          <h4 className="card-text">
                            {item.payslipStatus === 1 && (
                              <>
                                <span className="text-danger">
                                  {baseCurrency}{" "}
                                  {item.netMonthlyPay.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </>
                            )}
                            {item.payslipStatus !== 1 && (
                              <>
                                <span className="text-primary">Paid</span>
                                <i
                                  className="bi bi-download fs-5 mx-5 text-primary cursor-pointer"
                                  onMouseOver={() => {
                                    setSelectedEmp(item.employeeCode);
                                  }}
                                  onClick={() => {
                                    downloadPdf();
                                  }}
                                ></i>
                              </>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data-message text-center fw-bold p-10">
                  Pay slip will be generated on {payGenDate} for this Month
                </div>
              )}
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <div className="d-flex flex-row justify-content-between p-5">
              <div className="d-flex flex-grow-1" style={{ maxWidth: "250px" }}>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-placeholder="Select an option"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="">Select a month</option>
                  {Array.from({ length: 12 }, (_, index) => {
                    const optionDate = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth() - index
                    );
                    // const isDisabled =
                    //   optionDate >= currentDate ||
                    //   (optionDate.getMonth() === currentDate.getMonth() &&
                    //     optionDate.getFullYear() ===
                    //       currentDate.getFullYear() &&
                    //     currentDate.getDate() < Number(payGenDate));

                    const displayMonth = monthNames[optionDate.getMonth()];
                    const displayYear = optionDate.getFullYear();

                    return (
                      <option
                        key={index}
                        value={`${displayMonth}-${displayYear}`}
                        // disabled={isDisabled}
                      >
                        {`${displayMonth} ${displayYear}`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                  {totalSelectedNetMonthlyPay ? (
                    <button
                      className="btn"
                      onClick={() => {
                        handleOpenModal();
                      }}
                    >
                      {button} {baseCurrency}{" "}
                      {totalSelectedNetMonthlyPay.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </button>
                  ) : (
                    <button
                      className="btn"
                      onClick={() => {
                        Swal.fire({
                          text: "Select a employee to pay",
                          confirmButtonText: "OK",
                          allowEscapeKey: true,
                          allowEnterKey: true,
                        });
                      }}
                    >
                      {button}
                    </button>
                  )}
                </Authorize>
              </div>
            </div>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
              {employeeData?.length > 0 ? (
                employeeData.map((item, index) => (
                  <div className="p-5" key={index}>
                    <div className="card shadow p-0 d-flex flex-row">
                      <div className="card-body p-5">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title">{item.employeeName}</h5>
                        </div>
                        <div className="d-flex justify-content-between p-0">
                          <div className="d-flex p-0">
                            <p
                              className="text-muted p-0 m-0"
                              style={{ fontSize: "12px" }}
                            >
                              Employee Id: {item.employeeCode}
                              {/* {new Date(item.createdDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex gap-5">
                          <div className="p-5">
                            <h4
                              className={`card-text ${
                                item.payslipStatus === 1
                                  ? "text-danger"
                                  : "text-primary"
                              }`}
                            >
                              {item.payslipStatus === 1
                                ? `${baseCurrency} ${item.netMonthlyPay.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}`
                                : "Paid"}
                            </h4>
                          </div>
                          <div className="p-5">
                            <div className="">
                              {item.payslipStatus === 1 ? (
                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    id={`checkbox_${item.employeeCode}`}
                                    checked={selectedEmployeeCodes.includes(
                                      item.employeeCode
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        item.employeeCode,
                                        item.month,
                                        item.year
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data-message text-center fw-bold p-10">
                  Pay slip will be generated on {payGenDate} for this Month
                </div>
              )}
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <div className="d-flex flex -row justify-content-between p-5">
              <div className="mb-2 d-flex flex-grow-1">
                <form
                  autoComplete="off"
                  style={{ maxWidth: "300px", position: "relative" }}
                >
                  <i
                    className="ki-duotone ki-magnifier fs-1 position-absolute"
                    style={{
                      top: "50%",
                      left: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    className="form-control ps-15"
                    placeholder="Search Employee"
                    value={empSearch}
                    onChange={(e) => setEmpSearch(e.target.value)}
                  />
                </form>
              </div>

              <div>
                <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                  {totalNetPay ? (
                    <button
                      className="btn"
                      onClick={() => {
                        handleOpenModal();
                      }}
                    >
                      {button} {baseCurrency}{" "}
                      {totalNetPay.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </button>
                  ) : (
                    <button
                      className="btn"
                      onClick={() => {
                        Swal.fire({
                          text: "Select a month to pay",
                          confirmButtonText: "OK",
                          allowEscapeKey: true,
                          allowEnterKey: true,
                        });
                      }}
                    >
                      {button}
                    </button>
                  )}
                </Authorize>
              </div>
            </div>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
              {searchEmpData?.map((item, index) => (
                <div className="p-5" key={index}>
                  <div
                    className={`card shadow p-0 d-flex flex-row ${
                      selectedEmp === item.employeeCode ? "selected" : ""
                    }`}
                    onClick={() =>
                      setSelectedEmp((prev) =>
                        prev === item.employeeCode ? null : item.employeeCode
                      )
                    }
                  >
                    <div
                      className="card-body p-5 d-flex flex-row justify-content-between cursor-pointer"
                      onClick={() => {
                        handleToggleCollapse(item.employeeCode);
                        simulateLoad(index);
                      }}
                    >
                      <div>
                        <div className="d-flex">
                          <h5 className="card-title">{item.firstName}</h5>
                        </div>
                        <div className="d-flex p-0">
                          <div className="d-flex p-0">
                            <p
                              className="text-muted p-0 m-0"
                              style={{ fontSize: "12px" }}
                            >
                              Employee Id: {item.employeeCode}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center me-5">
                        <FontAwesomeIcon
                          icon={
                            !collapsedState[item.employeeCode]
                              ? faChevronCircleDown
                              : faChevronCircleUp
                          }
                          className="ki-duotone ki-to-left fs-2"
                        />
                      </div>
                    </div>
                  </div>
                  {loadingIndexes.includes(index) ? (
                    <span className="d-flex flex-center p-3">
                      {" "}
                      <FallingLines color="#0c6dfd" />
                    </span>
                  ) : (
                    selectedEmp === item.employeeCode &&
                    selectedEmpData && (
                      <div
                        className={`mt-5 ${
                          collapsedState[item.employeeCode] ? "collapsed" : ""
                        }`}
                      >
                        {selectedEmpData.length > 0 ? (
                          selectedEmpData.map((innerItem, innerIndex) => (
                            <div className="" key={innerIndex}>
                              <div className="card shadow p-5 bg-secondary bg-hover-opacity-25 mb-5">
                                <div className="row">
                                  <div className="col">
                                    {innerItem.month} {innerItem.year}
                                  </div>
                                  <div className="col d-flex justify-content-end align-items-center">
                                    <div className="col d-flex justify-content-end me-10">
                                      {baseCurrency}{" "}
                                      {innerItem.netMonthlyPay.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </div>
                                    {innerItem.payslipStatus === 1 ? (
                                      <div className="form-check form-check-custom form-check-solid form-check-sm me-5">
                                        <input
                                          className="form-check-input cursor-pointer"
                                          style={{
                                            border: "1px solid #000",
                                            padding: "5px",
                                          }}
                                          type="checkbox"
                                          onChange={() =>
                                            handleMonthCheckboxChange(
                                              innerItem.employeeCode,
                                              innerItem.month,
                                              innerItem.year
                                            )
                                          }
                                          checked={(
                                            selectedMonths[
                                              innerItem.employeeCode
                                            ] || []
                                          ).includes(innerItem.month)}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        <p className="m-0 text-primary font-weight-bold">
                                          Paid
                                        </p>
                                        <i
                                          className="bi bi-download fs-5 mx-4 text-primary cursor-pointer"
                                          onMouseOver={() =>
                                            setPaySlip(
                                              selectedEmpData[innerIndex]
                                                ?.receipt
                                            )
                                          }
                                          onClick={() => handleDownload()}
                                        ></i>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-data-message text-center fw-bold">
                            Pay slip has not been generated yet
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="account_modal"
        ref={modalRef}
        data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-scrollable w-400px"
          style={{ margin: "0 auto", marginTop: "15%" }}
        >
          <div className="modal-content" style={{ padding: "10px" }}>
            {status && <ConfettiExplosion zIndex={2} />}
            <div className="modal-header">
              <h4
                className="modal-title text-nowrap"
                style={{ color: "#246bfd" }}
              >
                Complete Payment
              </h4>

              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPin("");
                  setOtp(["", "", "", ""]);
                  setApproverGuid("");
                  setApproverName("");
                  setActiveInput(0);
                  resetState();
                  setStatus(false);
                  setStatusCode("0");
                  setRender(false);
                  setSelectedItemIndex(null);
                  setCodeValues("");
                  setHoveredIndex(null);
                  setErrMsg("");
                }}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            {button !== "Initiate" && (
              <h4
                className="modal-title text-nowrap "
                style={{ color: "black", padding: "5px 22.5px" }}
              >
                Choose Account
              </h4>
            )}

            <div
              className="modal-body"
              style={{
                pointerEvents: authority === 3 ? "none" : "auto",
                opacity: authority === 3 ? 0.5 : 1,
              }}
            >
              {button === "Initiate" ? (
                <div className="mb-5">
                  <label className="form-label">Select Approver</label>
                  <select
                    id="approver"
                    name="approver"
                    className="form-select"
                    onChange={handleApprover}
                  >
                    <option value="">Select Approver</option>
                    {approvers.map((approv, key) => (
                      <option value={approv.userGuid}>{approv.name}</option>
                    ))}
                  </select>
                  {apperrMsg && (
                    <p className="text-danger">
                      Please select an approver to pay
                    </p>
                  )}
                </div>
              ) : null}

              {button === "Initiate" && (
                <label className="form-label">Select Account</label>
              )}
              {!render && (
                <>
                  {accounts
                    ?.filter((item) => item.currency !== "USD")
                    .map((item, index) => {
                      const isSelected =
                        `${item.accountNumber}-${item.accountType}-${item.currency}` ===
                        `${selectedItemIndex?.accountNumber}-${selectedItemIndex?.accountType}-${selectedItemIndex?.currency}`;

                      const cardClasses = `card shadow d-flex flex-column p-5 mb-5 cursor-pointer ${
                        isSelected ? "selected-card" : ""
                      } ${hoveredIndex === index ? "bg-secondary" : ""}`;

                      const handleCardClick = () => {
                        if (
                          selectedTab === 1 &&
                          item.balance > totalNetMonthlyPay
                        ) {
                          handleItemClick(item);
                        }
                        if (
                          selectedTab === 2 &&
                          item.balance > totalSelectedNetMonthlyPay
                        ) {
                          handleItemClick(item);
                        }
                        if (selectedTab === 3 && item.balance > totalNetPay) {
                          handleItemClick(item);
                        }
                      };

                      const handleCardHover = () => {
                        setHoveredIndex(index);
                      };

                      const handleCardLeave = () => {
                        setHoveredIndex(null);
                      };

                      if (selectedTab) {
                        if (
                          selectedTab === 1 &&
                          item.balance < totalNetMonthlyPay
                        ) {
                          hasInsufficientBalance = true;
                        }
                        if (
                          selectedTab === 2 &&
                          item.balance < totalSelectedNetMonthlyPay
                        ) {
                          hasInsufficientBalance = true;
                        }
                        if (selectedTab === 2 && item.balance < totalNetPay) {
                          hasInsufficientBalance = true;
                        }
                      }

                      return (
                        <div
                          key={index}
                          className={cardClasses}
                          onClick={handleCardClick}
                          style={{
                            border: isSelected
                              ? "1px solid #246bfd"
                              : "1px solid transparent",
                          }}
                          onMouseEnter={handleCardHover}
                          onMouseLeave={handleCardLeave}
                        >
                          <div className="d-flex flex-row justify-content-between">
                            <div>{item.accountNumber}</div>
                            <div>
                              {item.currency}&nbsp;
                              {item.balance.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                          <div>
                            {" "}
                            {hasInsufficientBalance && (
                              <p className="text-danger text-end m-0">
                                Insufficient balance!
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </>
              )}

              {selectedItemIndex && (
                <>
                  <div
                    className={`card shadow d-flex flex-row justify-content-between p-5 mb-5 `}
                    style={{
                      border: "1px solid #246bfd",
                    }}
                  >
                    <div>{selectedItemIndex.accountNumber}</div>
                    <div>
                      {selectedItemIndex.currency}&nbsp;
                      {selectedItemIndex.balance.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                  <hr />
                  <div className="card shadow d-flex flex-column justify-content-between p-2">
                    <div className="" style={{ marginLeft: "10px" }}>
                      Enter your PIN to confirm
                    </div>

                    <div
                      className="d-flex flex-no-wrap justify-content-around p-5"
                      style={{ marginRight: "10px" }}
                    >
                      <OTPInput
                        // {...field}
                        value={otp.join("")}
                        onChange={(value: string) => {
                          value
                            .split("")
                            .forEach((char, index) =>
                              handleChange(char, index)
                            );
                        }}
                        numInputs={4}
                        renderSeparator={<span>&nbsp;&nbsp;</span>}
                        renderInput={(props, index) => (
                          <input
                            {...props}
                            value={otp[index]}
                            ref={(el) => (inputRefs.current[index] = el)}
                            onChange={(e) => {
                              const pin = handleChange(e.target.value, index);
                              setCodeValues(pin);
                              setStatusCode("");
                              setErrMsg("");
                            }}
                            onKeyDown={(e) => {
                              const pin = handleKeyDown(e, index);
                              handleEnterKeyPress(e);
                              setCodeValues(pin);
                              setErrMsg("");
                            }}
                            type={show ? "password" : "text"}
                            autoFocus={true}
                            disabled={index !== activeInput}
                            onFocus={() => setActiveInput(index)}
                            className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                            style={{ height: "50px", width: "50px" }}
                          />
                        )}
                      />
                    </div>
                    <p
                      className="text-center cursor-pointer text-decoration-underline mb-0"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Show PIN" : "Hide PIN"}
                    </p>
                    {errMsg && (
                      <p className="text-danger text-center">{errMsg}</p>
                    )}
                  </div>
                  <hr />
                  <button
                    className="btn w-100"
                    onClick={() => {
                      if (button === "Initiate") {
                        if (!approverGuid) setAppErrMsg(true);
                        if (!pin) setErrMsg("Please enter Pin");
                        if (approverGuid && pin && selectedItemIndex)
                          handlePaySalary();
                      } else {
                        if (!pin) setErrMsg("Please enter Pin");
                        if (pin && selectedItemIndex) handlePaySalary();
                      }
                    }}
                  >
                    {button}
                  </button>
                  {/* {status && (
                    <div className="card shadow p-5 d-flex flex-row justify-content-between">
                      <div>Success</div>
                      <div>
                        <i className="bi bi-check2-circle fs-1 text-primary"></i>
                      </div>
                    </div>
                  )}
                  {!status && statusCode === "1" && (
                    <div className="card shadow p-5 d-flex flex-row justify-content-between">
                      <div>{errMsg}</div>
                      <div>
                        <i className="bi bi-x-circle fs-1 text-danger"></i>
                      </div>
                    </div>
                  )}
                  <br />

                  {status && (
                    <button className="btn w-100" onClick={generatePDF}>
                      Download E-Receipt
                    </button>
                  )} */}
                </>
              )}
              {authority === 3 && (
                <p className="text-danger text-center fw-bold">
                  You don't have the authority to perform this action
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="e_receipt_modal"
        // ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered w-400px">
          <div className="modal-content">
            <div className="modal-head d-flex flex-row justify-content-between align-items-center p-4 pb-0 mx-3 mb-3">
              <>
                {/* <i
                  className="bi bi-arrow-left-short fs-1 me-2"
                  onClick={() => {
                    handleDeleteCloseModal();
                    handleBack();
                  }}
                ></i> */}
                <p className="fs-3 pb-0 mt-4">Employee Pay Slips</p>
              </>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            {/* begin::Body */}
            <div className="modal-body p-0 py-10">
              <Carousel
                onSelect={handleSelect}
                activeIndex={selectedIndex}
                indicators={false}
                style={{ width: "400px", maxWidth: "400px" }}
                interval={null}
                wrap={false}
              >
                {transaction?.length > 0 ? (
                  transaction?.map((txn, index) => (
                    <Carousel.Item key={index} className="">
                      <div className="mx-5">
                        <div className="d-flex flex-column justify-content-center mx-10 mt-2">
                          <div className="card shadow-sm p-5 w-300px">
                            {Object.entries(txn?.receipt).map(
                              ([key, value]) => (
                                <div
                                  key={key}
                                  className="d-flex justify-content-between mb-2"
                                >
                                  <div>
                                    <p>{key}</p>
                                  </div>
                                  <div>
                                    <p
                                      className={`fw-bold badge badge-${
                                        key === "Status" && value === "Success"
                                          ? "success"
                                          : key === "Status" &&
                                            value === "Failed"
                                          ? "danger"
                                          : ""
                                      }`}
                                    >
                                      {String(value)}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-10">
                          <button
                            type="button"
                            className="btn rounded"
                            style={{
                              width: "300px",
                            }}
                            onClick={() => downloadPDF(txn.receipt)}
                          >
                            Download E-Receipt
                          </button>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))
                ) : (
                  <p className="text-center fs-4">No receipts available</p>
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
