import { AccountStmnt, StatisticsData } from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

export const getAccountStmnt = async (
  address: string | undefined,
  token: string,
  account: string,
  currency: string,
  stmntDuration: string,
  fromDate:string,
  toDate:string
): Promise<AccountStmnt[]> => {
 

  try {
    const url = `${address}/txnStmnt/getAccountStmnt`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      accountNumber: account,
      currency: currency,
      stmntType: "A",
      stmntDuration: stmntDuration,
      fromDate:fromDate,
      toDate:toDate
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.results as AccountStmnt[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStatistics = async (
  address: string | undefined,
  token: string,
  account: string,

  stmntDuration: string
): Promise<StatisticsData> => {
  

  try {
    const url = `${address}/txnStmnt/getStatistics`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      accountNumber: account,
      stmntType: "A",
      stmntDuration: stmntDuration,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data.message as StatisticsData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
