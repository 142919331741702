import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { enhancedFetch } from "../core/_requests";
import XLogo from "../../../../_metronic/assets/Icons/xfin.png";
import Swal from "sweetalert2";

export function InviteMPIN() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [otp2, setOtp2] = useState<string[]>(["", "", "", ""]);
  const [activeInput2, setActiveInput2] = useState(0);
  const inputRef2 = useRef<(HTMLInputElement | null)[]>([]);
  const [confirmPin, setConfirmPin] = useState("");
  const [confirmPinError, setConfirmPinError] = useState("");

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { userGuid, token } = location.state as {
    userGuid: string;
    token: string;
  };
  console.log(token, userGuid);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRef2.current[activeInput2]?.focus();
  }, [activeInput2]);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      setPinError("");
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleChange2 = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && confirmPin.length < 4) {
      setConfirmPinError("");
      const newOtp = [...otp2];
      newOtp[index] = value;
      setOtp2(newOtp);
      setConfirmPin(confirmPin + value);

      if (value && index < otp.length - 1) {
        setActiveInput2(index + 1);
      }
      return confirmPin + value;
    }
    return confirmPin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };
  const handleKeyDown2 = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp2[index] !== "") {
        const newOtp = [...otp2];
        newOtp[index] = "";
        setOtp2(newOtp);
        setConfirmPin(confirmPin.substring(0, confirmPin.length - 1));
        return confirmPin.substring(0, confirmPin.length - 1);
      } else if (index > 0) {
        setActiveInput2(index - 1);
        const newOtp = [...otp2];
        newOtp[index - 1] = "";
        setOtp2(newOtp);
        setConfirmPin(confirmPin.substring(0, confirmPin.length - 1));
        return confirmPin.substring(0, confirmPin.length - 1);
      }
    }
    return confirmPin;
  };
  const handleContinue = () => {
    // Check if both PIN and confirmation PIN are exactly 4 digits long and match
    if (pin.length === 4 && confirmPin.length === 4 && pin === confirmPin) {
      activateUser();
    } else {
      // Display an error if PINs do not match or are not 4 digits
      if (pin.length !== 4) {
        setPinError("PIN must be exactly 4 digits.");
      }

      if (confirmPin.length !== 4) {
        setConfirmPinError("Confirmation PIN must be exactly 4 digits.");
      } else {
        setConfirmPinError("Pin does not match");
      }
    }
  };

  const activateUser = async () => {
    try {
      const url = `${API_URL}/team/capturePin`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        userGuid: userGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await fetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your account created successfully",
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: "Go to Login",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/auth");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <style>
        {`
                .responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.responsive-card {
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-card {
    padding: 1rem;
    min-height: 200px;
  }
}
  `}
      </style>
      <div className="responsive-container">
        <div className="responsive-card">
          <img alt="Logo" src={XLogo} className="h-50px mb-20" />
          <div className="d-flex flex-column" style={{ gap: "100px" }}>
            <form autoComplete="off">
              <div className="mb-10">
                <h1 className="text-center mb-3">Create New PIN</h1>
                <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                  <div className="d-flex flex-row">
                    <OTPInput
                      // {...conversionformik?.getFieldProps("pin")}
                      value={otp.join("")}
                      onChange={(value: string) => {
                        value
                          .split("")
                          .forEach((char, index) => handleChange(char, index));
                      }}
                      numInputs={4}
                      renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          value={otp[index]}
                          ref={(el) => (inputRefs.current[index] = el)}
                          onChange={(e) => {
                            const pin = handleChange(e.target.value, index);
                          }}
                          onKeyDown={(e) => {
                            const pin = handleKeyDown(e, index);
                          }}
                          type={show ? "password" : "text"}
                          autoFocus={true}
                          disabled={index !== activeInput}
                          onFocus={() => setActiveInput(index)}
                          className="text-center rounded mt-3 border border-1 border-dark"
                          style={{ height: "50px", width: "50px" }}
                        />
                      )}
                    />
                  </div>
                  <p
                    className="text-center cursor-pointer text-decoration-underline mt-2 mb-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Show PIN" : "Hide PIN"}
                  </p>

                  {pinError && (
                    <p className="text-danger text-center">{pinError}</p>
                  )}
                </div>
              </div>
              <div className="">
                <h1 className="text-center mb-3">Confirm New PIN</h1>
                <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                  <OTPInput
                    // {...conversionformik?.getFieldProps("pin")}
                    value={otp2.join("")}
                    onChange={(value: string) => {
                      value
                        .split("")
                        .forEach((char, index) => handleChange2(char, index));
                    }}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        value={otp2[index]}
                        ref={(el) => (inputRef2.current[index] = el)}
                        onChange={(e) => {
                          const pin = handleChange2(e.target.value, index);
                        }}
                        onKeyDown={(e) => {
                          const pin = handleKeyDown2(e, index);
                        }}
                        type={show2 ? "password" : "text"}
                        autoFocus={true}
                        disabled={index !== activeInput2}
                        onFocus={() => setActiveInput2(index)}
                        className="text-center rounded my-3 border border-1 border-dark mx-auto"
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  />
                </div>
                <p
                  className="text-center cursor-pointer text-decoration-underline mb-0 mt-2"
                  onClick={() => setShow2(!show2)}
                >
                  {show2 ? "Show PIN" : "Hide PIN"}
                </p>
                {confirmPinError && (
                  <p className="text-danger text-center">{confirmPinError}</p>
                )}
                <button
                  className="btn rounded fs-4 mt-20 w-100"
                  type="button"
                  style={{
                    color: "#ffff",
                    backgroundColor: "#246BFD",
                    fontWeight: "bolder",
                  }}
                  onClick={handleContinue}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
