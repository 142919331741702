import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import PinInput from "react-pin-input";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Card } from "./MyCard";
import { Slider } from "@mui/material";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";

interface AddCardProps {
  listCards: (action: string) => Promise<void>;
  cardData: Card;
}
export const ChangeLimit: React.FC<AddCardProps> = ({
  listCards,
  cardData,
}) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const ModalRef = useRef<HTMLDivElement | null>(null);
  const PinModalRef = useRef<HTMLDivElement | null>(null);
  const limitFormikRef = useRef<FormikProps<any> | null>(null);
  let ele = useRef<PinInput | null>(null);
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [previousValue, setPreviousValue] = useState(
    cardData?.spendLimit?.toString()
  );
  const [newLimit, setNewLimit] = useState(previousValue);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const inputRefs2 = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  useEffect(() => {
    setPreviousValue(cardData?.spendLimit?.toString());
  }, [cardData?.spendLimit]);

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      setPreviousValue("");
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      // Assuming `updateData` is your function to update the state
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const changeLimit = async () => {
    try {
      const url = `${API_URL}/card/updateCardLimit`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        cardType: cardData.cardType,
        cardNumber: cardData.cardNumber,
        spendLimit: +newLimit,
        transactionPIN: pin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleCloseModal();
        listCards("");
        setPreviousValue(data?.message?.spendLimit?.toString());
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Expenditure limit has been updated",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        }).then((result) => {
          setPreviousValue(data?.message?.spendLimit?.toString());
        });
      }
      if (data.status.statusCode === 1) {
        setError(data?.status?.messageDescription);
        ele.current?.clear();
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleChangeLimitClose = () => {
    setPreviousValue(cardData?.spendLimit?.toString());
    limitFormikRef?.current?.resetForm();
    const modalElement = ModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleConfirmClick = async (newLimit: string) => {
    setNewLimit(newLimit);
    const modalElement = PinModalRef.current;
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    if (PinModalRef.current) {
      const modal = new Modal(PinModalRef.current);

      PinModalRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      PinModalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        PinModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleCloseModal = () => {
    setPin("");
    setError("");
    setActiveInput(0);
    setOtp(["", "", "", ""]);
    const modalElement = PinModalRef.current;
    if (modalElement) {
      ele.current?.clear();
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  return (
    <>
      <style>
        {`
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        `}
      </style>
      <div
        className="modal"
        tabIndex={-1}
        id="limit_modal"
        ref={ModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-header d-flex flex-row justify-content-between py-3">
              <h4 className="mb-0">Change Limit</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div
              className="modal-body p-12"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "200px",
                alignItems: authority === 3 ? "center" : "center",
                justifyContent: authority === 3 ? "center" : "center",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <label className="form-label">Current Limit</label>
                    <div>
                      {" "}
                      <span
                        className="fs-6 fw-bold text-gray-700"
                        style={{
                          position: "absolute",
                          padding: "12.5px",
                        }}
                      >
                        {baseCurrency}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control custom-input mb-5"
                      value={previousValue}
                      placeholder={baseCurrency?.toString()}
                      style={{ paddingLeft: "60px" }}
                      // To ensure numeric keyboard on mobile devices
                      max="100000"
                      pattern="[0-9]*"
                      onChange={(e) => {
                        handleAmountChange(e);
                      }}
                    />
                  </div>
                  <Slider
                    value={+previousValue}
                    // marks={amount}
                    min={0}
                    max={100000}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    onChange={(_, newValue) =>
                      setPreviousValue(newValue.toString())
                    }
                  />
                  <div className="d-flex justify-content-between">
                    <p className="m-0">{baseCurrency} 0</p>
                    <p className="m-0">{baseCurrency} 100,000</p>
                  </div>
                  <div className="d-flex flex-row mt-5">
                    <button
                      type="button"
                      className="btn rounded me-5"
                      style={{
                        width: "180px",
                      }}
                      onClick={() => {
                        handleConfirmClick(previousValue);
                        handleChangeLimitClose();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary rounded"
                      style={{
                        width: "180px",
                        color: "#246bfb",
                      }}
                      onClick={() => handleChangeLimitClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="validate_pin"
        ref={PinModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between align-items-center ">
                <h1
                  className="text-center m-0"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {" "}
                <Formik
                  innerRef={limitFormikRef}
                  initialValues={{
                    confirmPin: "",
                  }}
                  validationSchema={Yup.object({
                    confirmPin: Yup.string()
                      .matches(/^\d{4}$/, "PIN must be 4 digits")
                      .required("PIN is required"),
                  })}
                  onSubmit={(values, actions) => {
                    setPin(values.confirmPin);
                    actions.resetForm();
                    ele.current?.clear();
                    changeLimit();
                    // handleCloseModal();
                  }}
                >
                  {({ resetForm }) => (
                    <Form>
                      <div className="mb-7 mt-5">
                        <div className="flex-column">
                          <p className="text-center fs-5 mb-5">
                            Enter your PIN to confirm
                          </p>
                          <div className="d-flex justify-content-center">
                            <Field name="confirmPin">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <OTPInput
                                  {...field}
                                  value={otp.join("")}
                                  onChange={(value: string) => {
                                    value
                                      .split("")
                                      .forEach((char, index) =>
                                        handleChange(char, index)
                                      );
                                  }}
                                  numInputs={4}
                                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                                  renderInput={(props, index) => (
                                    <input
                                      {...props}
                                      value={otp[index]}
                                      ref={(el) =>
                                        (inputRefs.current[index] = el)
                                      }
                                      onChange={(e) => {
                                        const pin = handleChange(
                                          e.target.value,
                                          index
                                        );
                                        setError("");
                                        form.setFieldValue("confirmPin", pin);
                                      }}
                                      onKeyDown={(e) => {
                                        const pin = handleKeyDown(e, index);
                                        setError("");
                                        form.setFieldValue("confirmPin", pin);
                                      }}
                                      type={show ? "password" : "text"}
                                      autoFocus={true}
                                      disabled={index !== activeInput}
                                      onFocus={() => setActiveInput(index)}
                                      className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                      style={{ height: "50px", width: "50px" }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                          <p
                            className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                          <div className="mt-3 text-center">
                            <ErrorMessage
                              name="confirmPin"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          {error && (
                            <p className="text-center text-danger">{error}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between mb-5 gap-5">
                        <div>
                          <button
                            type="submit"
                            className="btn rounded"
                            style={{
                              width: "120px",
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-secondary rounded"
                            style={{
                              width: "120px",
                              color: "#246bfb",
                            }}
                            onClick={() => handleCloseModal()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
