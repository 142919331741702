import { Interface } from "readline";

interface Item {
  itemName: string;
  qty: number;
  rate: number;
  total: number;
}

export const InvData = {
  customerGuid: "",
  businessName: "",
  businessAddress: "",
  invoiceType: "",
  quotationType: "",
  subTotal: 0,
  discount: 0,
  tax: 0,
  shipping: 0,
  total: 0,
  termsAndConditions: "",
  notes: "",
  items: [] as Item[],
};
