import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { GraphData, StatisticsData } from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

type BardataProps = {
  account: string;
  currency: string;
  stmntDuration: string;
};

export const Statistics = ({
  account,
  currency,
  stmntDuration,
}: BardataProps) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [labels, setLabels] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  const [bardata, setBardata] = useState<StatisticsData>({
    userId: "",
    tenantId: "",
    accountNumber: "",
    referenceId: "",
    referenceType: 0,
    currency: "",
    stmntType: "",
    stmntDuration: 0,
    graphData: [],
    income: 0,
    expense: 0,
  });

  const getStatistics = async (
    account: string,
    stmntDuration: string
  ): Promise<StatisticsData> => {
    try {
      const url = `${API_URL}/txnStmnt/getStatistics`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        accountNumber: account,
        stmntType: "A",
        // currency: currency === "R" ? "SSP" : currency,
        stmntDuration: stmntDuration,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      return data.message as StatisticsData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getBarData = async () => {
    if (account && stmntDuration) {
      const bardata = await getStatistics(account, stmntDuration);

      const sortedGraphData = bardata?.graphData.sort(
        (a, b) => a.index - b.index
      );

      setBardata(bardata);
    }
  };

  useEffect(() => {
    getBarData();
  }, [account, stmntDuration, currency]);

  useEffect(() => {
    setLabels(bardata?.graphData?.map((entry) => entry.name));
    setGraphData(bardata?.graphData);
  }, [bardata]);

  const ExpenseValues = graphData?.map((day) => {
    const dataForD = day?.data?.find((entry) => entry.key === "D");
    return dataForD ? parseFloat(dataForD?.value) : 0;
  });

  const IncomeValues = graphData?.map((day) => {
    const dataForC = day?.data?.find((entry) => entry.key === "C");
    return dataForC ? parseFloat(dataForC.value) : 0;
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Expense",
        backgroundColor: "#E8EDFE",
        borderColor: "rgb(255, 99, 132)",
        borderRadius: 5,
        data: ExpenseValues,
      },
      {
        label: "Income",
        backgroundColor: "#184BFA",
        borderColor: "rgb(132, 99, 255)",
        borderRadius: 5,
        data: IncomeValues,
      },
    ],
  };

  const handleBarClick = (event: any, elements: any[]) => {
    if (elements.length > 0) {
      const datasetIndex = elements[0].datasetIndex;
      const index = elements[0].index;

      const updatedData = {
        ...data,
        datasets: data.datasets.map((dataset: any, i: number) => {
          if (i === datasetIndex) {
            // const backgroundColors = [...dataset.backgroundColor];
            // backgroundColors[index] = 'red'; // Set the new color for the clicked bar
            return {
              ...dataset,
              // backgroundColor: backgroundColors,
            };
          }
          return dataset;
        }),
      };

      // setData(updatedData);
    }
  };

  const options = {
    scales: {
      x: {
        display: true,
      },
      y: {
        beginAtZero: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    onClick: handleBarClick,
  };

  return (
    <>
      <div className="d-flex flex-grow-1">
        <Bar data={data} options={options} />
      </div>
    </>
  );
};
