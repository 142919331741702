import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { QRPaymentModal } from "./QRPaymentModal";
export interface QRPayment {
  paymentId: string;
  transactionDate: string;
  qrDescription: string;
  bankRefNo: string;
  sender: string;
  status: string;
  currency: string;
  amount: number;
}
const QrListing = () => {
  const location = useLocation();
  const Transactions = location.state as
    | { transactionDetails: QRPayment[] }
    | undefined;
  const [selectedPayment, setSelectedPayment] = useState<QRPayment>({
    paymentId: "",
    transactionDate: "",
    qrDescription: "",
    bankRefNo: "",
    sender: "",
    status: "",
    currency: "",
    amount: 0,
  });

  const handleRequestClick = (payment: QRPayment) => {
    setSelectedPayment(payment);

    const modalElement = document.getElementById("qrpayment_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  return (
    <>
      <h4>QR Transaction</h4>
      <div className="card shadow-sm p-5 mt-5">
        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
          <thead className="bg-gray-200">
            <tr className="text-start text-dark-600 fw-bold fs-6 ">
              <th className="min-w-100px p-5">Date</th>
              {/* <th className="min-w-100px p-5">Payment</th> */}
              <th className="min-w-100px p-5 text-nowrap">Reference No</th>
              <th className="min-w-100px p-5 text-nowrap">Sender Id</th>
              <th className="min-w-100px p-5 text-nowrap">Amount</th>
              <th className="min-w-100px p-5 text-nowrap">Action</th>
            </tr>
          </thead>

          <tbody>
            {Transactions && Transactions?.transactionDetails?.length > 0 ? (
              Transactions?.transactionDetails?.map((item, index) => (
                <tr
                  key={index}
                  data-index={index}
                  onMouseOver={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "#f1f1f2";
                    // target.style.transform =
                    //   "translate3d(6px, -6px, 0)";
                    // target.style.boxShadow =
                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                  }}
                  onMouseOut={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "white";
                    // target.style.transform = "none";
                    // target.style.boxShadow = "none";
                  }}
                  //   onClick={handleRequestClick}
                >
                  <td className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline">
                    {item?.transactionDate}
                  </td>
                  <td className="p-5 text-primary">{item.bankRefNo}</td>
                  <td className="p-5">{item.sender}</td>
                  <td className="p-5">{item.amount}</td>
                  <td className="p-5">
                    <i
                      className="bi bi-eye text-dark"
                      onClick={() => handleRequestClick(item)}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <QRPaymentModal {...selectedPayment} />
    </>
  );
};

export default QrListing;
