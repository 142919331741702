import {
  Accounts,
  BillerData,
  PayBill,
  PayBillData,
  PayBillResponse,
} from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

export interface BillerFavConsumer {
  billerName: string;
  billerType: string;
  customerId: string;
  customerName: string;
  dueAmount: number;
  billStatus: number;
  dueDate: string;
  currency: string;
}

export interface TransactionStatement {
  amount: number;
  txnId: string;
  txnStatus: number;
  accountNumber: string;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
  businessGuid: string;
  senderName: string;
  senderCustomerId: string;
  benefName: string;
  benefCustomerId: string;
}

export interface Message {
  billerFavConsumer: BillerFavConsumer[];
  transactionStmnts: TransactionStatement[];
}

export interface MenuItem {
  menuCode: string;
  menuName: string;
  menuIcon: string;
  menuIndex: number;
  isDashboardIcon: boolean;
}

export const getAccounts = async (
  address: string | undefined,
  token: string
): Promise<Accounts[]> => {
  try {
    const url = `${address}/transaction/getAccounts`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.results as Accounts[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBillDashboard = async (
  address: string | undefined,
  token: string
): Promise<Message> => {
  try {
    const url = `${address}/billpay/getBillPayDashboard`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message as Message;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBillPaymentMenu = async (
  address: string | undefined,
  token: string
): Promise<MenuItem[]> => {
  try {
    const url = `${address}/billpay/getBillPaymentMenu`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data?.message?.menus as MenuItem[];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBillers = async (
  address: string | undefined,
  token: string,
  billerType: string
): Promise<BillerData> => {
  try {
    const url = `${address}/billpay/getBillers`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      billerType: billerType,
      connectionType: "POSTPAID",
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message as BillerData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBillersData = async (
  address: string | undefined,
  token: string,
  billerType: string,
  billerNames: string[],
  customerId: string
): Promise<PayBillData> => {
  try {
    const url = `${address}/billpay/getCustomerIdDetail`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      billerType: billerType,
      billerNames: billerNames,
      customerIdBiller: customerId,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data.message as PayBillData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const payBill = async (
  address: string | undefined,
  token: string,
  payBill: PayBill
) => {
  try {
    const url = `${address}/billpay/payBill`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify(payBill);

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteCustomer = async (
  address: string | undefined,
  token: string,
  billerType: string,
  billerNames: string,
  customerIdBiller: string
) => {
  try {
    const url = `${address}/billpay/deleteCustomerDetail`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
      billerType: billerType,
      billerNames: [billerNames],
      customerIdBiller: customerIdBiller,
    });

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
